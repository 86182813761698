import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flex: 1
  },
  textHeader: {
    fontSize: 20,
    marginTop: 5
  }
});

export default styles;
