import React from "react";
import { Image, Text, View } from "react-native";
import { useLocation } from "react-router-dom";

import styles from "./styles";
import { Card } from "../../components";
import { IMAGES } from "../../configs";
import { convertDate } from "../../utils";

const currency = (num: number, idr: string) => {
  return `${idr} ${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

const star = (total: number) => {
  let img = <></>;
  for (let i = 0; i < total; i += 1) {
    img = (
      <>
        {img}
        <Image style={styles.star} source={IMAGES.star} />
      </>
    );
  }
  const sisa = 5 - total;
  for (let j = 0; j < sisa; j += 1) {
    img = (
      <>
        {img}
        <Image style={styles.star} source={IMAGES.stargrey} />
      </>
    );
  }
  return <View style={{ flexDirection: "row" }}>{img}</View>;
};

const HistoryDetail = () => {
  const location = useLocation();
  const { hotel, flight } = location!.state! as any;

  return (
    <View style={styles.container}>
      {hotel.length === 0 ? (
        <View style={styles.emptyData}>
          <Text style={styles.emptyDataText}>-</Text>
        </View>
      ) : (
        <Card cardStyle={styles.cardDescription}>
          <View>
            {hotel.map((item: any, index: number) => {
              return (
                <View key={index}>
                  <View style={[styles.desciption, { marginBottom: 2 }]}>
                    <Text style={styles.titleDescription}>Hotel</Text>
                    <View style={styles.detailDescription}>
                      {star(item.booking.data.star)}
                    </View>
                  </View>
                  <Text style={styles.titleHotel}>
                    {item.booking.data.hotel}
                  </Text>

                  <View style={[styles.desciption, styles.descriptionCity]}>
                    <Text style={styles.titleDescription}>City</Text>
                    <Text style={styles.detailDescription}>
                      {item.booking.data.city}
                    </Text>
                  </View>
                  <View style={[styles.desciption, styles.descriptionCity]}>
                    <Text style={[styles.titleDescription]}>Region</Text>
                    <Text style={styles.detailDescription}>
                      {item.booking.address}
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <View style={[styles.desciption, styles.descriptionCity]}>
                      <Text style={styles.titleDescription}>Check In</Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.descDate}>
                          {convertDate(item.booking.data.check_in, "ddd")}
                        </Text>
                        <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                          {convertDate(item.booking.data.check_in)}
                        </Text>
                        <Text style={styles.descDate}>
                          {convertDate(item.booking.data.check_in, "HH:mm")}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.desciption, styles.descriptionCity]}>
                      <Text style={[styles.titleDescription]}>Check Out</Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.descDate}>
                          {convertDate(item.booking.data.check_out, "ddd")}
                        </Text>
                        <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                          {convertDate(item.booking.data.check_out)}
                        </Text>
                        <Text style={styles.descDate}>
                          {convertDate(item.booking.data.check_out, "HH:mm")}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Facility</Text>
                    <Text style={styles.detailDescription}>
                      {item.facility}
                    </Text>
                  </View> */}
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Budget</Text>
                    <Text
                      style={[styles.detailDescription, { fontWeight: "bold" }]}
                    >
                      {currency(item.prediction_amount, "IDR")}
                    </Text>
                  </View>
                </View>
              );
            })}

            {flight.map((el: any, index: number) => {
              return (
                <View style={{ marginTop: 25 }} key={index}>
                  <View style={[styles.desciption, { marginBottom: 2 }]}>
                    <Text style={styles.titleDescription}>Airlines</Text>
                  </View>
                  <Text style={styles.titleFlight}>
                    {el.booking.data.departure.name}
                  </Text>

                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Flight 1</Text>
                    <View style={styles.detailDescription}>
                      <Text style={styles.detailDescriptionText}>
                        {`${el.booking.data.departure.origin} - ${el.booking.data.departure.destination}`}
                      </Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.descDate}>
                          {convertDate(
                            el.booking.data.departure.departed_at,
                            "ddd"
                          )}
                        </Text>
                        <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                          {convertDate(el.booking.data.departure.departed_at)}
                        </Text>
                        <Text style={[styles.descDate]}>
                          {convertDate(
                            el.booking.data.departure.departed_at,
                            "HH:mm"
                          )}
                        </Text>
                      </View>
                      <Text style={styles.detailDescriptionText}>
                        {el.booking.data.departure.class}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Total Cost</Text>
                    <Text
                      style={[styles.detailDescription, { fontWeight: "bold" }]}
                    >
                      {currency(el.prediction_amount, "IDR")}
                    </Text>
                  </View>
                </View>
              );
            })}

            {/* <View style={styles.totalContainer}>
              <Text style={styles.totalText}>Total Expense</Text>
              <Text style={styles.totalDetail}>
                {currency(item.total(), "IDR")}
              </Text>
            </View> */}
          </View>
        </Card>
      )}
    </View>
  );
};

export default HistoryDetail;
