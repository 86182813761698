import { Image, Text, View } from "react-native";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { IMAGES } from "../../../../../../configs";
import { Touchable } from "../../../../../../components";
import styles from "./styles";
import { deleteBill, resetBill } from "../../../../../../redux/actions";
import { Reducers } from "../../../../../../redux/types";

interface Props {
  billId: string;
  setShowDetail: (visible: boolean) => void;
  setShowConfirmDelete: (visible: boolean) => void;
}
const DeleteBill = ({ billId, setShowConfirmDelete, setShowDetail }: Props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const {
    cashAdvanceBill
    // tripState
  } = useSelector(
    (state: Reducers) => ({
      cashAdvanceBill: state.cashAdvanceBill,
      tripState: state.trip
    }),
    shallowEqual
  );
  return (
    <View
      style={{
        alignItems: "center",
        height: 455,
        justifyContent: "center"
      }}
    >
      <Text
        style={{
          fontSize: 18,
          fontFamily: "Poppins",
          fontWeight: "bold",
          color: "#ed5a28"
        }}
      >
        DELETE YOUR BILLS?
      </Text>
      <Image source={IMAGES.rejectModal} style={{ width: 60, height: 60 }} />
      <Text
        style={{
          fontFamily: "Poppins",
          color: "#666666",
          width: 271,
          textAlign: "center"
        }}
      >
        {"Are you sure you want to "}
        <Text style={{ fontWeight: "bold" }}>DELETE</Text>
        {" this bill?"}
      </Text>
      <div
        style={{
          position: "fixed",
          alignSelf: "center",
          bottom: 10,
          paddingTop: 5
        }}
      >
        <>
          <View style={styles.submit}>
            <Touchable
              onPress={() => {
                if (!isSubmit) {
                  setIsSubmit(!isSubmit);
                  dispatch(deleteBill(billId));
                }
              }}
            >
              <View style={styles.submitTextWrap}>
                <>
                  {isSubmit && cashAdvanceBill.isDoneDeleteBill ? (
                    <>
                      {setIsSubmit(!isSubmit)}
                      {setShowConfirmDelete(false)}
                      {setShowDetail(true)}
                      {dispatch(resetBill())}
                    </>
                  ) : (
                    <></>
                  )}
                </>
                <Text style={styles.submitText}>
                  {isSubmit ? "Loading" : "Yes, I'm sure!"}
                </Text>
              </View>
            </Touchable>
          </View>
          <View style={{ alignItems: "center" }}>
            <Touchable
              onPress={() => {
                setShowConfirmDelete(false);
                setShowDetail(true);
              }}
            >
              <Text
                style={{
                  fontFamily: "Poppins",
                  color: "#707070",
                  fontWeight: "bold",
                  fontSize: 14,
                  borderBottomWidth: 1,
                  borderColor: "#707070",
                  borderStyle: "solid"
                }}
              >
                CANCEL
              </Text>
            </Touchable>
          </View>
        </>
      </div>
    </View>
  );
};

export default DeleteBill;
