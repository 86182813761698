import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  formWrap: {
    paddingTop: 28,
    paddingLeft: 20,
    paddingRight: 18
  },
  rowWrap: {
    flexDirection: "row",
    paddingTop: 8
  },
  wrapLabelInput: { flex: 1, marginRight: 9 },
  inputWrap: {
    marginTop: 14,
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginBottom: 14
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#666666"
  },
  inputLine: {
    borderBottomWidth: 0.5,
    borderBottomColor: "#707070",
    borderStyle: "solid",
    height: 30,
    paddingLeft: 12
  },
  desc: {
    borderBottomWidth: 0.5,
    borderBottomColor: "#707070",
    borderStyle: "solid",
    height: 80,
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 12
  },
  rowWrapAmount: {
    paddingTop: 70,
    alignItems: "center",
    flexDirection: "column"
  },
  inputAmount: { textAlign: "center", fontSize: 24 },
  attachment: {
    paddingTop: 20
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey,
    marginLeft: 12
  },
  labelError: {
    color: COLORS.orange,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  row: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight,
    marginBottom: 12
  },
  error: {
    borderBottomColor: COLORS.orange
  },
  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginTop: 4,
    marginBottom: 2,
    outlineColor: "rgba(255,255,255,0)"
  },
  wrapSaveBtn: {
    position: "absolute",
    alignItems: "center",
    bottom: 24,
    alignSelf: "center"
  },
  wrapTwoInput: {
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default styles;
