import { StyleSheet } from "react-native";

const orange = "#ed5a28";
const styles = StyleSheet.create({
  descriptionCity: {
    marginBottom: 0,
    marginTop: 0
  },
  detailDescriptionText: {
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins",
    textTransform: "capitalize"
  },
  titleFlight: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    marginBottom: 6
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 30
  },
  noDataStyle: {
    fontSize: 14,
    fontFamily: "Poppins",
    alignSelf: "center",
    marginTop: 10
  },
  card: {
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginVertical: 10,
    marginHorizontal: 25
  },
  text: {
    color: "#999999",
    marginBottom: 5,
    fontFamily: "Poppins",
    fontSize: 11
  },
  textDesc: {
    color: "#666666",
    marginBottom: 5,
    fontFamily: "Poppins",
    fontSize: 11
  },
  row: {
    flexDirection: "row"
  },
  statusBox: {
    flexDirection: "row"
  },
  statusText: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 18,
    fontWeight: "bold"
  },
  orange: {
    fontFamily: "Poppins",
    color: orange,
    width: "50%"
  },
  costBox: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    width: "50%"
  },
  fab: {
    height: 87,
    width: 87
  },
  cardDescription: {
    marginTop: 9,
    paddingTop: 14,
    paddingBottom: 21,
    paddingHorizontal: 21,
    marginBottom: 15,
    marginHorizontal: 4
  },
  desciption: {
    flexDirection: "row"
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  },
  titleHotel: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  marginVertical: {
    marginVertical: 16
  },
  descDate: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  wrapButtonTrash: {
    height: 28,
    width: 28,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center"
  },
  textNoData: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11
  }
});

export default styles;
