/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import { getCashDetail, getMyProfile } from "../../../redux/actions";
import { copyWritings, heightPercent, permissionPage } from "../../../utils";
import { Fixed, Header } from "../../../components";
import { Reducers } from "../../../redux/types";

import CashDetail from "./CashDetail";
import Request from "./Request";
import Bill from "./Bill";
import History from "./History";

const Details = () => {
  const dispatch = useDispatch();
  const { cashState, auth } = useSelector(
    (state: Reducers) => ({
      cashState: state.cash,
      auth: state.auth
    }),
    shallowEqual
  );

  const match = useRouteMatch();
  const { id, type } = useParams() as any;
  const initialRoute = `${match.path.slice(0, -10)}/${id}/${type}`;
  const history = useHistory();
  const { pathname } = history.location;
  const [heightHeader, setHeightHeader] = useState(0);
  const { sprintf } = require("sprintf-js");

  const { t } = useTranslation();
  const paymentSelfView = permissionPage("payment-self-view");
  const paymentOthersView = permissionPage("payment-others-view");
  const invoiceSelfView = permissionPage("invoice-self-view");
  const invoiceOthersView = permissionPage("invoice-others-view");

  const { moderationReference } = useSelector((state: Reducers) => state.cash);
  const copText = {
    expense: copyWritings("expense"),
    invoice: copyWritings("invoice")
  };

  const [listPages, setListPages] = useState([
    {
      pathname: initialRoute,
      component: <CashDetail type={type} id={id} />,
      titleTab: t("cash.detail.detailTab"),
      exact: true,
      accessible: true
    },
    {
      pathname: `${initialRoute}/request`,
      component: <Request id={id} />,
      titleTab: t("cash.detail.requestTab"),
      exact: true,
      accessible: true
    },
    {
      pathname: `${initialRoute}/bill`,
      component: <Bill id={id} />,
      titleTab: copText.expense,
      exact: true,
      accessible:
        cashState.cashDetail &&
        cashState.cashDetail.status > 4 &&
        cashState.cashDetail?.djurneeInvoice?.type?.title === "Cash Advance" &&
        moment(new Date().toUTCString()) >
          moment(new Date(cashState.cashDetail.published_at).toUTCString())
    },
    {
      pathname: `${initialRoute}/finished`,
      component: <History id={id} />,
      titleTab: t("cash.detail.paymentTab"),
      exact: true,
      accessible:
        (paymentSelfView && type === "owned") ||
        (paymentOthersView && type !== "owned")
    }
  ]);

  useEffect(() => {
    if (cashState.cashDetail) {
      setListPages([
        {
          pathname: initialRoute,
          component: <CashDetail type={type} id={id} />,
          titleTab: t("cash.detail.detailTab"),
          exact: true,
          accessible: true
        },
        {
          pathname: `${initialRoute}/request`,
          component: <Request id={id} />,
          titleTab: t("cash.detail.requestTab"),
          exact: true,
          accessible: true
        },
        {
          pathname: `${initialRoute}/bill`,
          component: <Bill id={id} />,
          titleTab: copText.expense,
          exact: true,
          accessible:
            cashState.cashDetail &&
            cashState.cashDetail.status > 4 &&
            cashState.cashDetail?.djurneeInvoice?.type?.title ===
              "Cash Advance" &&
            moment(new Date().toUTCString()) >
              moment(new Date(cashState.cashDetail.published_at).toUTCString())
        },
        {
          pathname: `${initialRoute}/finished`,
          component: <History id={id} />,
          titleTab: t("cash.detail.disburseTab"),
          exact: true,
          accessible:
            (paymentSelfView && type === "owned") ||
            (paymentOthersView && type !== "owned")
        }
      ]);
    }
  }, [cashState.cashDetail]);

  useEffect(() => {
    dispatch(
      getCashDetail(id, res => {
        const ca = "Cash Advance";
        if (res !== ca) {
          setListPages(el => el.filter((_res, index) => index !== 2));
        }
      })
    );
    dispatch(getMyProfile());
  }, [dispatch]);

  const statusDesc = (
    status: number,
    page: string,
    total: any,
    total_paid: any
  ) => {
    let label = "";
    if (status === 1) {
      label = t("cash.status.draft");
    } else if (status === 2 && page === "owned") {
      label = t("cash.status.onVerification");
    } else if (status === 2) {
      label = t("cash.status.needApproval");
    } else if (status === 3) {
      label = t("cash.status.approved");
    } else if (status === 4) {
      label = t("cash.status.revise");
    } else if (
      status === 5 &&
      moderationReference &&
      moderationReference.length > 0 &&
      moderationReference[moderationReference.length - 1].submitted_current ===
        moderationReference[moderationReference.length - 1].stage_total &&
      moderationReference[moderationReference.length - 1].stage_current ===
        moderationReference[moderationReference.length - 1].stage_total &&
      (total_paid === 0 || total_paid === null)
    ) {
      label = t("cash.status.unpaid");
    } else if (
      status === 5 &&
      total_paid >= total &&
      moderationReference &&
      moderationReference.length > 0 &&
      moderationReference[moderationReference.length - 1].submitted_current <
        moderationReference[moderationReference.length - 1].stage_total
    ) {
      label = t("cash.status.needApproval");
    } else if (status === 5 && Number(total_paid) >= Number(total)) {
      label = t("cash.status.notSettled");
    } else if (status === 5) {
      label = t("cash.status.unpaid");
    } else if (status === 6) {
      label = t("cash.status.settled");
    } else if (status === 7) {
      label = t("cash.status.rejected");
    } else if (status === 8) {
      label = t("cash.status.expired");
    } else if (status === 9) {
      label = t("cash.status.rejectByFinance");
    }
    return label;
  };

  useEffect(() => {
    if (
      cashState.cashDetail &&
      cashState.cashDetail.creator.id === auth.uuid &&
      !invoiceSelfView
    ) {
      history.push("/");
    }
    if (
      cashState.cashDetail &&
      cashState.cashDetail.creator.id !== auth.uuid &&
      !invoiceOthersView
    ) {
      history.push("/");
    }
  }, [cashState.cashDetail]);

  const _handleUrlGoBack = () => {
    if (pathname.includes("approvable")) {
      return "/cash/approve";
    }
    if (pathname.includes("unpaid")) {
      return "/cash/disburse";
    }
    if (pathname.includes("collectable")) {
      return "/cash/collect";
    }
    if (pathname.includes("billable")) {
      return "/cash/billable";
    }
    return "/cash";
  };

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            goBack={() => history.push(_handleUrlGoBack())}
            textHeader={{ fontSize: 18 }}
            subTitleHeader={{ fontSize: 11 }}
            title={sprintf(t("cash.detail.invoiceRecord"), copText.invoice)}
            subtitle={cashState.cashDetail && cashState.cashDetail.number}
            label={statusDesc(
              cashState.cashDetail && cashState.cashDetail.status,
              "",
              cashState.cashDetail && cashState.cashDetail.total,
              cashState.cashDetail && cashState.cashDetail.total_paid
            )}
            textLabel={{ fontSize: 11 }}
          />
          <View
            style={[
              styles.container,
              cashState.isLoadingCashDetail && { opacity: 0 }
            ]}
          >
            <View style={styles.wrapTaskNav}>
              <View style={styles.taskNav}>
                {listPages.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.btnNav,
                      index === 0 &&
                        item.pathname === pathname &&
                        styles.activeFirst,
                      index === listPages.length - 1 &&
                        item.pathname === pathname &&
                        styles.activeLast,
                      index !== 0 &&
                        pathname.includes(item.pathname) &&
                        styles.active
                    ]}
                  >
                    <TouchableOpacity
                      style={[
                        {
                          height: "100%",
                          justifyContent: "center"
                        },
                        !item.accessible && { opacity: 0.25 }
                      ]}
                      activeOpacity={1}
                      onPress={() => history.push(item.pathname)}
                      disabled={!item.accessible}
                    >
                      <Text style={styles.btnText}>{item.titleTab}</Text>
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Fixed>
        <View
          style={{
            marginTop: heightHeader + 16,
            minHeight: heightPercent(90) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};

export default Details;
