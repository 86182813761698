import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  formWrap: {
    flex: 1,
    paddingTop: 24,
    paddingLeft: 20,
    paddingRight: 20
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  labelError: {
    color: COLORS.orange,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  row: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight,
    marginBottom: 12
  },
  selectError: {
    color: COLORS.orange,
    textAlign: "right"
  },
  rowSelect: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight
  },
  error: {
    borderBottomColor: COLORS.orange
  },
  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginTop: 4,
    marginBottom: 2,
    outlineColor: "rgba(255,255,255,0)"
  },
  wrapTwoInput: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  wrapSaveBtn: {
    position: "absolute",
    alignItems: "center",
    bottom: 24,
    alignSelf: "center"
  },
  disableValueText: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#666666"
  }
});

export default styles;
