import {
  GET_SOURCE_ERROR,
  GET_SOURCE_PENDING,
  GET_SOURCE_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, SourceState } from "../types";

const initialState: SourceState = {
  sources: [],
  isLoadingSource: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_SOURCE_PENDING:
      return { ...state, isLoadingSource: true };
    case GET_SOURCE_SUCCESS:
      return { ...state, isLoadingSource: false, sources: payload.data };
    case GET_SOURCE_ERROR:
      return { ...state, isLoadingSource: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
