import {
  GET_EXPENSE_LIST_APPROVAL_ERROR,
  GET_EXPENSE_LIST_APPROVAL_PENDING,
  GET_EXPENSE_LIST_APPROVAL_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, ExpenseListApprovalState } from "../types";

const initialState: ExpenseListApprovalState = {
  paramsGeneral: {
    per_page: 10,
    page: 1
  },
  isLoadingGeneral: false,
  loadMoreGeneral: true,
  listGeneral: []
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // General
    case GET_EXPENSE_LIST_APPROVAL_PENDING:
      return { ...state, isLoadingGeneral: !payload.loadMore };
    case GET_EXPENSE_LIST_APPROVAL_SUCCESS:
      return {
        ...state,
        isLoadingGeneral: false,
        listGeneral: !payload.loadMore
          ? payload.data
          : [...state.listGeneral, ...payload.data],
        paramsGeneral: {
          ...state.paramsGeneral,
          page: !payload.loadMore ? 2 : state.paramsGeneral.page + 1
        },
        loadMoreGeneral: !(
          payload.data.length < initialState.paramsGeneral.per_page
        )
      };
    case GET_EXPENSE_LIST_APPROVAL_ERROR:
      return { ...state, isLoadingGeneral: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
