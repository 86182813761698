import React, { ReactNode } from "react";
import { Text, View } from "react-native";

import styles from "./styles";

interface Props {
  active: boolean;
  title: string;
  subtitle: string;
  value: ReactNode;
  // closingTripData: any;
}

const CapsuleBudget = ({ active, title, subtitle, value }: Props) => {
  return (
    <View style={active ? styles.rectangular : styles.rectangularDisabled}>
      <View
        style={active ? styles.rectangularItem : styles.rectangularItemDisabled}
      >
        <Text
          style={
            active
              ? styles.rectangularItemText
              : styles.rectangularItemTextDisabled
          }
        >
          {title}
        </Text>
      </View>
      <View
        style={
          active ? styles.rectangularItemRed : styles.rectangularItemRedDisabled
        }
      >
        <Text
          style={
            active
              ? styles.rectangularItemTextRed
              : styles.rectangularItemTextRedDisabled
          }
        >
          {subtitle}
        </Text>
        {value}
      </View>
    </View>
  );
};

export default CapsuleBudget;
