import React, { useEffect, useState } from "react";
import { FlatList, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import {
  CardStatusColor,
  LoadingLoadMore,
  Modal,
  WrapContent,
  WrapList
} from "../../../components";
import styles from "./styles";
import { documentTitle } from "../../../utils";
import { Card } from "../components";
import ClosingTripNotes from "../../TripDetail/Trip/components/ClosingTripNotes";

// redux action & types
import { getTripOngoing, resetSearchTripValue } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
// import trip from "../../../redux/reducers/trip";

const Ongoing = () => {
  const [showClosingTripNotes, setShowClosingTripNotes] = useState(false);
  documentTitle("Trip - Ongoing");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const tripState = useSelector((state: Reducers) => state.trip);

  useEffect(() => {
    dispatch(getTripOngoing(false, tripState.searchValue));
  }, [dispatch, tripState.searchValue]);

  const _goToDetailTrip = (id: string, status: any) => {
    history.push(`/trip-detail/${id}?type=ongoing&status=${status}`, {
      labelStatus: 5
    });
    dispatch(resetSearchTripValue());
  };

  const [tripId, setTripId] = useState("");

  const _withAttach = (file: string, files: any[]) => {
    if (file || files.length > 0) {
      return true;
    }
    return false;
  };

  const _statusDesc = (status: any) => {
    let desc = "LOADING...";

    if (status === 1) {
      desc = t("activity.status.draft");
    } else if (status === 2) {
      desc = t("activity.status.needApproval");
    } else if (status === 3) {
      desc = t("activity.status.approved");
    } else if (status === 4) {
      desc = t("activity.status.revise");
    } else if (status === 5) {
      desc = t("activity.status.ongoing");
    } else if (status === 6) {
      desc = t("activity.status.finished");
    } else if (status === 7) {
      desc = t("activity.status.rejected");
    } else if (status === 8) {
      desc = t("cash.status.overdue");
    }

    return desc;
  };

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 5) {
      color = "#50b1b1";
    } else if (status === 8) {
      color = "#2B94FC";
    }
    return color;
  };

  return (
    <WrapContent isLoading={tripState.isLoadingListOngoing}>
      <WrapList
        isEmpty={tripState.listOngoing.length === 0}
        emptyComponent={() => <Text style={styles.keyData}>Kosong</Text>}
        onEndReached={() =>
          tripState.loadMoreOngoing &&
          dispatch(getTripOngoing(true, tripState.searchValue))
        }
      >
        <View>
          <FlatList
            style={{ paddingBottom: 15, paddingHorizontal: 25 }}
            data={tripState.listOngoing}
            keyExtractor={(item, index) => index.toString()}
            ListFooterComponent={() => (
              <LoadingLoadMore active={tripState.loadMoreOngoing} />
            )}
            renderItem={({ item, index }) => (
              <Card
                onPressNotif={() => {
                  setTripId(item.id);
                  setShowClosingTripNotes(!showClosingTripNotes);
                }}
                item={item}
                index={index}
                onPress={() => {
                  _goToDetailTrip(item.id, item.status);
                }}
                type="ongoing"
                pastDueDate={item.past_the_due_date}
                statusColor={_statusColor(item.status)}
                withStatus
                statusDesc={_statusDesc(item.status)}
                withAttch={_withAttach(item.file, item.files)}
              />
            )}
          />
          {showClosingTripNotes && (
            <Modal
              backdropColor="rgba(240,240,240, 0.5)"
              isVisible
              onBackdropPress={() =>
                setShowClosingTripNotes(!showClosingTripNotes)
              }
            >
              <ClosingTripNotes
                tripId={tripId}
                closeBtn={() => setShowClosingTripNotes(!showClosingTripNotes)}
              />
            </Modal>
          )}
        </View>
      </WrapList>
    </WrapContent>
  );
};
export default Ongoing;
