import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  containerMessage: {
    backgroundColor: "rgba(247, 226, 38, 0.40)",
    paddingHorizontal: 8,
    paddingBottom: 17,
    borderRadius: 7
  },
  iconStyle: {
    height: 50,
    width: 58,
    marginTop: 10,
    alignSelf: "center"
  },
  messageText: {
    fontSize: 11,
    textAlign: "center",
    fontFamily: "Poppins",
    color: "#666666"
  },
  containerInputMessage: {
    backgroundColor: "rgba(247, 226, 38, 0.40)",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    width: "100%"
  }
});

export default styles;
