import React from "react";
import { View } from "react-native";

import { KasbonNavigation } from "../../components";

import { KasbonContent } from "./components/KasbonContent/KasbonContent";

import styles from "./styles";

const KasbonDetail = () => {
  const hasCompany = localStorage.getItem("kasbon-setting");

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: hasCompany
            ? JSON.parse(hasCompany).hex_code
            : "white"
        }
      ]}
    >
      <KasbonNavigation />
      <View style={styles.content}>
        <KasbonContent />
      </View>
    </View>
  );
};
export default KasbonDetail;
