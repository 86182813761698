import React, { useEffect } from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

import Cookies from "js-cookie";
import { getBudgetRequest, getReimburse } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import { Card, WrapContent, WrapList } from "../../../../components";
import { IMAGES } from "../../../../configs";
import styles from "./styles";
import { maskedMoney, queryString } from "../../../../utils";

interface Props {
  id: string;
  search: string;
}

const Reimburse = ({ id, search }: Props) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const ExpenseState = useSelector((state: Reducers) => state.newExpenseTrip);
  const dataTrip = queryString(search);

  const _currency = (num: number, idr: string) => {
    return `${idr} ${Math.trunc(num)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
  };
  const lang = Cookies.get("locale") || "en";

  useEffect(() => {
    dispatch(getReimburse(id));
    dispatch(getBudgetRequest(id, lang));
  }, [dispatch]);

  // const _reimburse = () => {
  //   history.push(`/new-expense-reimburse-trip${search}`, {
  //     id,
  //     invoice_id:
  //       ExpenseState.listReimburse.length === 0
  //         ? null
  //         : ExpenseState.listReimburse[0].invoice_id
  //   });
  // };

  // const _deleteReimburse = (item: any) => {
  //   dispatch(deleteReimburse(item.id));
  // };

  return (
    <WrapContent isLoading={ExpenseState.isLoadingListReimburse}>
      <WrapList
        isEmpty={ExpenseState.listReimburse.length === 0}
        emptyComponent={() => (
          <>
            <Text
              style={{
                fontFamily: "Poppins",
                fontSize: 11,
                color: "#999999"
              }}
            >
              Empty
            </Text>
          </>
        )}
      >
        <FlatList
          style={{ paddingHorizontal: 20 }}
          data={ExpenseState.listReimburse}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Card
              key={index}
              cardStyle={{
                marginBottom: 15,
                marginTop: index === 0 ? 25 : 0
              }}
              statusColor="#50b1b1"
              statusText="Amount"
              statusDesc={_currency(item.total, "IDR")}
            >
              <View style={{ marginLeft: 21, marginVertical: 17 }}>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={[styles.text, { marginRight: 11 }]}>
                    Category
                  </Text>
                  <Text style={styles.textDesc}>{item.category}</Text>
                  <View style={{ marginTop: -5, marginRight: 12 }}>
                    {dataTrip.type !== "history" ? (
                      <TouchableOpacity style={styles.btnReport}>
                        <Image
                          style={{ width: 13, height: 16 }}
                          source={IMAGES.trashBin}
                        />
                      </TouchableOpacity>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.text}>Desc.</Text>
                  <Text style={styles.textDesc}>{item.description || "-"}</Text>
                </View>
              </View>
            </Card>
          )}
        />
      </WrapList>

      <div
        style={{
          position: "sticky",
          bottom: 0
        }}
      >
        <View style={styles.footer}>
          <Text style={styles.budgetReq}>Ongoing Expense</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.priceIdr}>IDR</Text>
            {typeof ExpenseState.listBudgetReq.Total !== "undefined" &&
            typeof ExpenseState.listBudgetReq.Total.IDR !== "undefined" ? (
              // eslint-disable-next-line dot-notation
              <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                {` ${maskedMoney(
                  ExpenseState.listBudgetReq.Reimburse.IDR || "-"
                )}`}
              </Text>
            ) : (
              <Text style={[styles.priceIdr, { fontWeight: "bold" }]}> -</Text>
            )}
          </View>
        </View>
      </div>
    </WrapContent>
  );
};

export default Reimburse;
