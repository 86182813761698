import React from "react";

import { Description } from "../components";
import { IMAGES } from "../../../configs";

const First = () => (
  <Description
    image={IMAGES.introFirst}
    title="Let's get started"
    desc="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
  />
);

export default First;
