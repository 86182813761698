import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Fixed,
  Header,
  Modal,
  Touchable,
  WrapContent
} from "../../components";
import { IMAGES } from "../../configs";
import styles from "./styles";
import { convertDate } from "../../utils";

import { getReportByUserId } from "../../redux/actions";
import { Reducers } from "../../redux/types";

const initAttachmentImg: any[] = [];
const initAttachmentDoc: any[] = [];

const Report = () => {
  const dispatch = useDispatch();

  const [modalIsOpen, setModalOpen] = useState(false);
  const [modalDetailIsOpen, setModalDetail] = useState(false);
  const [detailReport, setDetailReport] = useState({
    title: "",
    description: "",
    created_at: ""
  });
  const [attachmentImg, setAttachmentImg] = useState(initAttachmentImg);
  const [attachmentDoc, setAttachmentDoc] = useState(initAttachmentDoc);

  const [categories, setCategory] = useState([
    { category: "1 week", active: false, value: "this_week" },
    { category: "1 month", active: false, value: "this_month" },
    { category: "3 months", active: false, value: "three_month" },
    { category: "6 months", active: false, value: "six_month" },
    { category: "1 year", active: false, value: "one_year" }
  ]);

  const openDetailReportModal = async (report: any) => {
    setDetailReport(report);
    if (report.file[0] !== null) {
      report.file.map((item: any) => {
        if (
          item.extension === "jpg" ||
          item.extension === "jpeg" ||
          item.extension === "png"
        ) {
          setAttachmentImg(e => [...e, item]);
        } else {
          setAttachmentDoc(e => [...e, item]);
        }
      });
    }
    setModalDetail(true);
  };

  const closeModalDetail = async () => {
    await setAttachmentDoc(initAttachmentDoc);
    await setAttachmentImg(initAttachmentImg);
    await setModalDetail(false);
  };

  const [inputcategory, setInputcategory] = useState([]);

  const handlecategoryInput = async (index: number, category: string) => {
    const categoryData = [
      { category: "1 week", active: false, value: "this_week" },
      { category: "1 month", active: false, value: "this_month" },
      { category: "3 months", active: false, value: "three_month" },
      { category: "6 months", active: false, value: "six_month" },
      { category: "1 year", active: false, value: "one_year" }
    ];

    if (categoryData[index].active) {
      setInputcategory(inputcategory.filter(item => item !== category));
    } else {
      const input: any = [...inputcategory, category];
      setInputcategory(input);
    }
    categoryData[index].active = !categoryData[index].active;
    setCategory(categoryData);
    await dispatch(getReportByUserId(categoryData[index].value));
    await setModalOpen(false);
  };

  const handleClearFilter = async () => {
    const categoryData = [
      { category: " week", active: false, value: "this_week" },
      { category: " month", active: false, value: "this_month" },
      { category: "3 months", active: false, value: "three_month" },
      { category: "6 months", active: false, value: "six_month" },
      { category: " year", active: false, value: "one_year" }
    ];

    setCategory(categoryData);
    await dispatch(getReportByUserId(""));
    await setModalOpen(false);
  };

  const [height, setHeight] = useState(0);

  const render = (item: any, index: number) => {
    return (
      <View
        style={[styles.cardItem, item.active && { backgroundColor: "#50b1b1" }]}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 11
          }}
        >
          <Touchable onPress={() => handlecategoryInput(index, item.category)}>
            <Text
              style={
                item.active
                  ? [styles.textItem, { color: "#fff", fontWeight: "bold" }]
                  : styles.textItem
              }
            >
              {item.category}
            </Text>
          </Touchable>
        </View>
      </View>
    );
  };

  useEffect(() => {
    dispatch(getReportByUserId(""));
  }, [dispatch]);

  const _splitDate = (date: string) => convertDate(date, "dddd, DD MMMM YYYY");

  const listReport = useSelector((state: Reducers) => state.report);

  return (
    <WrapContent isLoading={listReport.isLoadingGetReportByIdUser}>
      <View>
        <Header title="Report" style={{ marginBottom: 23 }} />
        {listReport.listReportByIdUser.map((item, index) => {
          return (
            <Touchable onPress={() => openDetailReportModal(item)} key={index}>
              <Card cardStyle={styles.card} key={index}>
                <View>
                  <View style={[styles.header]}>
                    <Text style={styles.textHeader}>
                      {_splitDate(item.date)}
                    </Text>
                  </View>

                  <Text style={[styles.text]}>{item.description}</Text>
                  <Text style={[styles.textFile]}>
                    {item.file[0] === null
                      ? "0 files attached"
                      : `${item.file.length} files attached`}
                  </Text>
                </View>
              </Card>
            </Touchable>
          );
        })}
        <div
          style={{
            position: "sticky",
            bottom: 0,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          <Touchable onPress={() => setModalOpen(true)}>
            <Image
              resizeMode="contain"
              style={{ width: 62.6, height: 75.6 }}
              source={IMAGES.iconFilter}
            />
          </Touchable>
        </div>
        <Modal isVisible={modalIsOpen}>
          <View style={styles.content}>
            <View>
              <Card cardStyle={styles.cardStyle}>
                <>
                  <Text style={styles.title}>Filter</Text>
                  <FlatList
                    style={{ marginBottom: 20 }}
                    data={categories}
                    renderItem={({ item, index }) => render(item, index)}
                  />
                  <View style={styles.cardItem}>
                    <Touchable onPress={() => handleClearFilter()}>
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          paddingVertical: 11,
                          backgroundColor: "#e74c3c",
                          borderRadius: 20
                        }}
                      >
                        <Text
                          style={[
                            styles.textItem,
                            { color: "#fff", fontWeight: "bold" }
                          ]}
                        >
                          CLEAR
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                </>
              </Card>
            </View>
            <View style={styles.buttonClose}>
              <Touchable onPress={() => setModalOpen(false)}>
                <Text style={styles.textClose}>CLOSE</Text>
              </Touchable>
            </View>
          </View>
        </Modal>
        {modalDetailIsOpen && (
          <Modal
            isVisible={modalDetailIsOpen}
            isNotCenter
            withScroll
            footer={() => (
              <Fixed getHeight={value => setHeight(value)} position="bottom">
                <View style={{ alignItems: "center" }}>
                  <Touchable onPress={() => closeModalDetail()}>
                    <View style={styles.footer}>
                      <Text style={styles.btnClose}>CLOSE</Text>
                    </View>
                  </Touchable>
                </View>
              </Fixed>
            )}
          >
            <View style={[styles.cardModalStyle, { paddingBottom: height }]}>
              <View style={styles.insideModal}>
                <Text style={styles.titleReport}>{detailReport.title}</Text>
                <Text style={styles.dateReport}>
                  {convertDate(detailReport.created_at)}
                </Text>
                <Text style={styles.desc}>{detailReport.description}</Text>
                <Text style={styles.textImages}>IMAGES</Text>
                <View style={{ width: "100%" }}>
                  {attachmentImg.length > 0 ? (
                    attachmentImg.map((item: any, index: number) => {
                      return (
                        <Image
                          key={index}
                          resizeMode="cover"
                          style={{ width: "100%", height: 219 }}
                          source={{
                            uri: `${item.url}`
                          }}
                        />
                      );
                    })
                  ) : (
                    <Text>-</Text>
                  )}
                </View>
                <Text style={styles.textFiles}>FILES</Text>
                <View style={{ width: "100%", marginBottom: 25 }}>
                  {attachmentDoc.length > 0 ? (
                    attachmentDoc.map((item: any, index) => {
                      return (
                        <Card
                          key={index}
                          cardStyle={{ width: 160, marginTop: 12 }}
                        >
                          <Text
                            numberOfLines={1}
                            style={{
                              fontFamily: "Poppins",
                              fontSize: 11,
                              color: "#666666",
                              paddingVertical: 6,
                              marginHorizontal: 15
                            }}
                          >
                            {item.title}
                          </Text>
                        </Card>
                      );
                    })
                  ) : (
                    <Text>-</Text>
                  )}
                </View>
              </View>
            </View>
          </Modal>
        )}
      </View>
    </WrapContent>
  );
};

export default Report;
