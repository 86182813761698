import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";

import {
  emptyListCashAdvanceBill,
  // getPopulateCategory,
  getConfigUploadFile,
  getTripById,
  // storeListBillTempNewBill,
  postNewBill,
  resetDoneListCashAdvanceBill
} from "../../redux/actions";

import {
  FileUpload,
  Fixed,
  Header,
  Input,
  Message,
  Select,
  Touchable,
  Upload
} from "../../components";
import { getPolicies } from "../../utils";
import { Reducers } from "../../redux/types";
import styles from "./styles";

declare const alert: (param: any) => void;
// coba
const NewBill = () => {
  const dispatch = useDispatch();
  const { tripId } = useParams() as any;
  const history = useHistory();
  const {
    // newTripState,
    expenseData,
    cashAdvanceBill,
    auth
  } = useSelector(
    (state: Reducers) => ({
      // newTripState: state.newTrip,
      expenseData: state.tempNewBill.expenseDetail,
      cashAdvanceBill: state.cashAdvanceBill,
      auth: state.auth
    }),
    shallowEqual
  );
  const { tripData } = useSelector((state: Reducers) => state.trip);
  const [isSubmit, setIsSubmit] = useState(false);

  const [oneTimeEffect, setOneTimeEffect] = useState(true);
  const [heightInputPurpose, setHeightInputPurpose] = useState(0);
  const [form, setForm] = useState({
    description: "",
    isBillable: false,
    amount: "0",
    file: null,
    currency: "IDR",
    quantity: 1
  });
  const initialPolicy = getPolicies("expense-trip-ca-receipt");
  const [policy, setPolicy] = useState(initialPolicy);

  const [extension, setExtensions] = useState("");
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [heightFooter, setHeightFooter] = useState(0);

  const formatCurrency = (numb: any) => {
    const digit = parseInt(numb, 0);
    let additional = "";
    if (digit < 0) {
      additional = "-";
    }
    const digitString = digit.toString().replace("-", "").split("");
    let newDigit = "";
    let j = 1;
    for (let i = digitString.length - 1; i >= 0; i -= 1) {
      newDigit = `${digitString[i]}${newDigit}`;
      if (j % 3 === 0 && j !== digitString.length) {
        newDigit = `,${newDigit}`;
      }
      j += 1;
    }
    return `${additional}${newDigit}`;
  };

  const dateFormat = (date: string) => {
    const mo = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const dt = date.split(" ")[0].split("-");

    return `${parseInt(dt[2], 0)} ${mo[parseInt(dt[1], 0) - 1]} ${dt[0]}`;
  };

  const UploadDocument = (e: any) => {
    const file = e.target.files[0];
    const size = auth.myConfig.max_size / auth.myConfig.max_size;

    if (file.size <= auth.myConfig.max_size) {
      setImage(file);
      const extensions = e.target.files[0].name.split(".").pop();
      setExtensions(extensions);
      setName(e.target.files[0].name);
      setForm({ ...form, file });
    } else {
      alert(`file to big! maximum size allowed ${size}MB.`);
    }
  };
  const deleteFile = () => {
    setImage(null);
    setName(null);
    setForm({ ...form, file: null });
    setPolicy(initialPolicy);
  };
  const renderFile = () => {
    if (
      extension.toLowerCase() === "png" ||
      extension.toLowerCase() === "jpeg" ||
      extension.toLowerCase() === "jpg"
    ) {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    if (
      extension.toLowerCase() === "pdf" ||
      extension.toLowerCase() === "docx" ||
      extension.toLowerCase() === "xls" ||
      extension.toLowerCase() === "xlsx"
    ) {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    return null;
  };

  useEffect(() => {
    if (oneTimeEffect) {
      if (!tripId) {
        history.push("/trip/ongoing");
      } else {
        dispatch(getTripById(tripId));
        dispatch(getConfigUploadFile());
        if (!expenseData) {
          history.push(
            `/trip-detail/${tripId}/expense/cash-advance?type=ongoing`
          );
        }
      }
      setOneTimeEffect(false);
    }
  }, [dispatch, expenseData, history, oneTimeEffect, tripId]);

  useEffect(() => {
    if (form.file || form.file !== null) {
      setPolicy(false);
    }
  }, [form.file]);
  return (
    <View>
      <Header
        title="New Bill"
        subtitle={`Cash Advance${
          expenseData ? ` - ${expenseData.category}` : ""
        }`}
      />
      {tripData ? (
        <>
          <View style={[styles.newTripContent, { width: "100%" }]}>
            {policy && (
              <View style={{ marginBottom: 15 }}>
                <Message
                  message="According to our policy, You are required to submit a photo or
                  screenshot of the receipt. Violations will be recorded."
                />
              </View>
            )}
            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "48%" }}>
                <Text style={[styles.labelInput, { marginLeft: 14 }]}>
                  Category
                </Text>
                <View style={{ marginTop: 17, marginLeft: 14 }}>
                  <Text style={styles.textDatePeriod}>
                    {expenseData ? `${expenseData.category}` : ""}
                  </Text>
                </View>
              </View>
              <View style={{ width: "48%" }}>
                <Text style={[styles.labelInput, { marginLeft: 23 }]}>
                  Date Period
                </Text>
                <View style={{ marginTop: 17, marginLeft: 23 }}>
                  <Text style={styles.textDatePeriod}>
                    {`${dateFormat(tripData.started_at)}${
                      dateFormat(tripData.finished_at) !==
                      dateFormat(tripData.started_at)
                        ? `${
                            tripData.finished_at !== null
                              ? ` - ${dateFormat(tripData.finished_at)}`
                              : ""
                          }`
                        : ""
                    }`}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ marginTop: 18 }}>
              <Text style={[styles.labelInput, { marginHorizontal: 14 }]}>
                Description
              </Text>
              <Input
                value={form.description}
                placeholder="Enter Description"
                inputStyle={[
                  styles.inputStyle,
                  heightInputPurpose > 0 &&
                    form.description !== "" && { height: heightInputPurpose }
                ]}
                style={{ marginHorizontal: 14 }}
                onChangeText={text => {
                  setForm({ ...form, description: text });
                }}
                multiline
                onContentSizeChange={({ nativeEvent }) =>
                  setHeightInputPurpose(nativeEvent.contentSize.height)
                }
              />
            </View>
            <View
              style={{
                marginTop: 27.5,
                marginHorizontal: 14,
                flexDirection: "row"
              }}
            >
              <Switch
                onChange={() =>
                  setForm({ ...form, isBillable: !form.isBillable })
                }
                checked={form.isBillable}
                onColor="#50b1b1"
                onHandleColor="#fffff7"
                handleDiameter={19}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                height={19}
                width={34}
              />
              <Text style={[styles.textDatePeriod, { marginLeft: 1 }]}>
                Billable
              </Text>
            </View>
            <View style={{ marginTop: 41.5, alignItems: "center" }}>
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    color: "#666666"
                  }}
                >
                  Amount
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      borderBottomWidth: 0.5,
                      borderColor: "#E7E8E0",
                      borderStyle: "solid",
                      height: 36,
                      marginRight: 10.8,
                      width: 59
                    }}
                  >
                    <Select
                      listValue={[
                        { value: "IDR", label: "IDR" },
                        { value: "USD", label: "USD" }
                      ]}
                      value={form.currency}
                      onChange={e =>
                        setForm({ ...form, currency: e.target.value })
                      }
                    />
                  </View>
                  <Input
                    value={form.amount}
                    keyboardType="numeric"
                    placeholder="Enter Description"
                    inputStyle={[
                      styles.inputStyle,
                      { textAlign: "right", width: 141 }
                    ]}
                    onChangeText={text => {
                      let _text: any = text.split(",").join("");
                      if (text === "") {
                        _text = "0";
                      } else {
                        _text = _text.split("");
                        const re = /^[0-9]*[0-9]$/i;
                        if (text.split(",").join("").match(re)) {
                          if (_text[0] === "0" && _text.length > 1) {
                            _text[0] = "";
                          }
                          _text = _text.join("");
                        }
                      }
                      setForm({ ...form, amount: formatCurrency(_text) });
                    }}
                    multiline
                  />
                </View>
              </View>
            </View>
            <View style={{ marginBottom: heightFooter, marginHorizontal: 14 }}>
              <View style={{ marginVertical: 20 }}>
                {image != null ? <View>{renderFile()}</View> : null}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Upload
                  onChange={(e: any) => UploadDocument(e)}
                  extionsion={extension}
                  multiple={false}
                />
              </View>
            </View>
          </View>
          <Fixed getHeight={e => setHeightFooter(e)} position="bottom">
            <View
              style={{
                alignItems: "center"
              }}
            >
              {isSubmit ? (
                <>
                  {!cashAdvanceBill.isLoadingPostNewBill ? (
                    <>
                      {cashAdvanceBill.isSuccessPostNewBill ? (
                        <>
                          <>
                            {history.push(
                              // eslint-disable-next-line max-len
                              `/trip-detail/${tripId}/expense/cash-advance?type=ongoing&category_id=${expenseData.category_id}`
                            )}
                          </>
                          <>{dispatch(resetDoneListCashAdvanceBill())}</>
                        </>
                      ) : (
                        <>{setIsSubmit(false)}</>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <View style={styles.submit}>
                <Touchable
                  onPress={() => {
                    if (!isSubmit) {
                      setIsSubmit(true);
                      dispatch(emptyListCashAdvanceBill());
                      dispatch(
                        postNewBill({
                          title: "",
                          trip_id: tripId,
                          category_id: expenseData.category_id,
                          description: form.description,
                          billable: form.isBillable,
                          currency_code: form.currency,
                          amount: form.amount.split(",").join(""),
                          file: form.file,
                          date_now: false,
                          quantity: form.quantity
                        })
                      );
                    }
                  }}
                >
                  <View style={styles.submitTextWrap}>
                    <Text style={styles.submitText}>
                      {!isSubmit ? "Submit" : "Loading ..."}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </Fixed>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default NewBill;
