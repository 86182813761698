import { StyleSheet } from "react-native";
import { heightPercent, widthPercent } from "../../../utils";
import colors from "../../../configs/colors";

const styles = StyleSheet.create({
  container: {
    width: widthPercent(100) - 40,
    height: heightPercent(100),
    justifyContent: "flex-start"
  },
  cardStyle: {
    backgroundColor: colors.main,
    height: heightPercent(100) - 120,
    borderRadius: 25,
    marginTop: 50,
    paddingTop: 36
  },
  closeText: {
    fontFamily: "Poppins",
    fontSize: 18,
    color: "#707070",
    fontWeight: "bold"
  },
  buttonClose: {
    paddingLeft: 14,
    paddingTop: 23
  },
  noteHeader: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 18,
    color: "#666666",
    fontWeight: "bold",
    marginBottom: 35
  },
  noteDesc: {
    paddingLeft: 30,
    paddingRight: 20
  },
  noteTextDesc: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#707070"
  },
  buttonRevise: {
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    borderRadius: 30,
    // paddingVertical: 14,
    width: 180,
    height: 54,
    justifyContent: "center",
    alignItems: "center"
  },
  buttonReviseText: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ffffff"
  }
});

export default styles;
