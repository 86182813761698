import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  card: {
    borderRadius: 25,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1
  }
});

export default styles;
