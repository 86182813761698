import React, { ReactNode } from "react";
import { Text, View } from "react-native";

import styles from "./styles";
import { Card, Touchable } from "../../../components";
import { heightPercent } from "../../../utils";

interface Props {
  onPress?: () => void;
  onPressRevise?: () => void;
  children: ReactNode;
}

const ModalNoteRevise = ({ onPress, children, onPressRevise }: Props) => {
  return (
    <View style={styles.container}>
      <Card cardStyle={styles.cardStyle}>
        <View style={{ height: heightPercent(80.8) }}>
          <Text style={styles.noteHeader}>Notes</Text>
          <View style={styles.noteDesc}>
            <Text style={styles.noteTextDesc}>{children}</Text>
          </View>
          <View
            style={{ alignSelf: "center", position: "absolute", bottom: 60 }}
          >
            <Touchable onPress={onPressRevise}>
              <View style={styles.buttonRevise}>
                <Text style={styles.buttonReviseText}>RESOLVE</Text>
              </View>
            </Touchable>
          </View>
        </View>
      </Card>
      <View style={styles.buttonClose}>
        <Touchable onPress={onPress}>
          <Text style={styles.closeText}>Close</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default ModalNoteRevise;
