import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 16
  },
  card: {
    borderRadius: 12,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginBottom: 12
  },
  cardDesc: {
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 8
  },
  row: {
    flexDirection: "row",
    marginBottom: 4
  },
  col1: {
    width: "25%"
  },
  col2: {
    width: "70%"
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyLight
  },
  desc: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  footerCard: {
    backgroundColor: COLORS.green,
    paddingHorizontal: 16,
    paddingVertical: 6,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  textNom: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.white
  },
  wrapButtonTrash: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    position: "absolute",
    right: 8,
    top: 8
  },
  buttonTrash: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  wrapButtonCA: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    borderRadius: 16,
    marginBottom: 16
  },
  buttonCA: {
    paddingHorizontal: 12,
    flexDirection: "row",
    height: 28,
    alignItems: "center"
  },
  textAddCA: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: COLORS.white,
    fontSize: 14
  },
  iconPlus: {
    width: 12,
    height: 12,
    marginRight: 4
  },
  titleFooter: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  descFooter: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.greyMedium
  },
  wrapTextFooter: {
    marginBottom: 8,
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default styles;
