import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_REGION_PENDING = "GET_REGION_PENDING";
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS";
export const GET_REGION_ERRROR = "GET_REGION_ERROR";

export const getRegion = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();

    dispatch({ type: GET_REGION_PENDING });

    const res = await API.getRegion(auth.token);
    dispatch({ type: GET_REGION_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getRegion())));
      } else {
        dispatch({ type: GET_REGION_ERRROR, payload: { data: err.response } });
      }
    }
    dispatch({ type: GET_REGION_ERRROR });
  }
};
