import { StyleSheet } from "react-native";

import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    width: widthPercent(100),
    paddingTop: 130,
    paddingHorizontal: 58
  },
  wrapImage: {
    width: "100%",
    height: 230
  },
  image: {
    width: "100%",
    height: "100%"
  },
  wrapDesc: {
    marginTop: 48,
    marginBottom: 15,
    alignItems: "center"
  },
  textTitle: {
    fontSize: 20,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#3f7790"
  },
  textDesc: {
    fontSize: 13,
    color: "#8f8f8f",
    fontFamily: "Poppins",
    textAlign: "center",
    marginVertical: 14
  },
  buttonBack: {
    position: "absolute",
    left: 0,
    top: 0
  },
  iconBack: {
    width: 20,
    height: 20,
    tintColor: "#262261"
  }
});

export default styles;
