import {
  LOGOUT_SUCCESS,
  PUT_EDIT_TRIPS_ERROR,
  PUT_EDIT_TRIPS_PENDING,
  PUT_EDIT_TRIPS_SUCCESS
} from "../actions";
import { Action, NewTripState } from "../types";

const initialState: NewTripState = {
  isLoadingPopulateCategory: false,
  listPopulateCategory: [],
  isLoadingPopulateProject: false,
  listPopulateProject: [],
  isLoadingPostNewTrip: false,
  isLoadingEditTrip: false,
  isLoadingPutEditTrip: false,
  listPopulateChildProject: [],
  listDepartmentV1: []
};

export default (state = initialState, { type }: Action) => {
  switch (type) {
    // put newtrip
    case PUT_EDIT_TRIPS_PENDING:
      return { ...state, isLoadingPutEditTrip: true };
    case PUT_EDIT_TRIPS_SUCCESS:
      return { ...state, isLoadingPutEditTrip: false };
    case PUT_EDIT_TRIPS_ERROR:
      return { ...state, isLoadingPutEditTrip: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
