import { StyleSheet } from "react-native";

// import { heightPercent, widthPercent } from "../../utils";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#006230",
    flex: 1
  },
  content: {
    flex: 1,
    marginTop: 24
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  loadingText: {
    marginTop: 20
  }
});

export default styles;
