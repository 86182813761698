import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  insideCard: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 16,
    paddingVertical: 3
  },
  columnData: {
    width: "20%"
  },
  columnValue: {
    width: "68%"
  },
  keyData: {
    color: "#999999",
    fontSize: 11,
    fontFamily: "Poppins"
  },
  valueData: {
    color: "#666666",
    fontSize: 11,
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  notifPastDate: {
    height: 38,
    width: 38
  },
  positionAttch: {
    position: "absolute",
    right: 5,
    top: 2,
    zIndex: 1
  }
});

export default styles;
