import {
  FIRST_TIME_SUCCESS,
  GET_COMPANY_ADDRESS_ERROR,
  GET_COMPANY_ADDRESS_PENDING,
  GET_COMPANY_ADDRESS_SUCCESS,
  GET_CONFIG_SUCCESS,
  GET_MY_GENERAL_SETTING_ERROR,
  GET_MY_GENERAL_SETTING_PENDING,
  GET_MY_GENERAL_SETTING_SUCCESS,
  GET_MY_PERMISSION_ERROR,
  GET_MY_PERMISSION_PENDING,
  GET_MY_PERMISSION_SUCCESS,
  GET_SETTING_ERROR,
  GET_SETTING_PENDING,
  GET_SETTING_SUCCESS,
  LOGIN_ERROR,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, AuthState, Dispatch, GetState } from "../types";
import { API } from "../../configs";

const initialState: AuthState = {
  isLoadingGetMyPermission: false,
  myPermission: [],
  isLoading: false,
  token: "",
  firstTime: true,
  userId: "",
  email: "",
  password: "",
  uuid: "",
  isLoadingGetConfig: false,
  policies: [],
  myConfig: {},
  generalSetting: [],
  isLoadingGeneralSetting: false,
  companyAddress: [],
  isLoadingCompanyAddress: false,
  setting: {
    isLoading: false,
    list: []
  }
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case FIRST_TIME_SUCCESS:
      return { ...state, isLoading: false, firstTime: false };
    case LOGIN_PENDING:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: payload.data,
        userId: payload.userId,
        email: payload.email,
        password: payload.password,
        uuid: payload.uuid,
        policies: payload.policies
      };
    case LOGIN_ERROR:
      return { ...state, isLoading: false };
    case GET_MY_PERMISSION_PENDING:
      return { ...state, isLoadingGetMyPermission: true };
    case GET_MY_PERMISSION_SUCCESS:
      return {
        ...state,
        isLoadingGetMyPermission: false,
        myPermission: [...payload.data]
      };
    case GET_MY_PERMISSION_ERROR:
      return { ...state, isLoadingGetMyPermission: false };
    case GET_SETTING_PENDING:
      return { ...state, isLoading: true };
    case GET_SETTING_ERROR:
      return { ...state, isLoadingGeneralSetting: false };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        setting: {
          ...state.setting,
          isLoading: false,
          list: payload.data.filter((el: any) => el.id === "feature-dictionary")
        }
      };
    case GET_MY_GENERAL_SETTING_PENDING:
      return { ...state, isLoadingGeneralSetting: true };
    case GET_MY_GENERAL_SETTING_SUCCESS:
      return {
        ...state,
        isLoadingGeneralSetting: false,
        generalSetting: payload.data
      };
    case GET_MY_GENERAL_SETTING_ERROR:
      return { ...state, isLoadingGeneralSetting: false };

    case GET_COMPANY_ADDRESS_PENDING:
      return { ...state, isLoadingCompanyAddress: true };
    case GET_COMPANY_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingCompanyAddress: false,
        companyAddress: payload.data
      };
    case GET_COMPANY_ADDRESS_ERROR:
      return { ...state, isLoadingCompanyAddress: false };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: initialState.token,
        isLoading: initialState.isLoading,
        userId: initialState.userId,
        email: initialState.email,
        password: initialState.password,
        uuid: initialState.uuid
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        isLoadingGetConfig: false,
        myConfig: payload.data
      };
    default:
      return state;
  }
};

export const handleLogout = () => (dispatch: Dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

export const getMySettingGeneral = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    dispatch({ type: GET_SETTING_PENDING });
    const { auth } = getState();
    const res = await API.getMySettingGeneral(auth.token);
    dispatch({
      type: GET_SETTING_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SETTING_ERROR });
  }
};
