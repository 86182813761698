import React, { useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sprintf } from "sprintf-js";
import {
  Card,
  Confirmation,
  CustomText,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";
import { IMAGES } from "../../../configs";
import { Reducers } from "../../../redux/types";
import {
  deleteTripReport,
  getDateAvailableReport,
  getTripReport,
  putSubmitTripReport
} from "../../../redux/actions";
import styles from "./styles";
import {
  convertDate,
  copyWritings,
  documentTitle,
  heightPercent,
  queryString,
  textToLink
} from "../../../utils";
import ClosingTripNotes from "../Trip/components/ClosingTripNotes";

interface Props {
  id: string;
  top: number;
  search: string;
}

const Report = ({ id, top, search }: Props) => {
  const dataTrip = queryString(search);
  documentTitle("Trip Detail - Report");
  const [reportId, setReportId] = useState("");
  const [itemReport, setItemReport] = useState({
    id: "",
    date: ""
  });
  const [showClosingTripNotes, setShowClosingTripNotes] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoadingTripById, listTripReport } = useSelector(
    (state: Reducers) => state.trip
  );
  const editReportState = useSelector((state: Reducers) => state.report);
  const dispatch = useDispatch();

  const copTeks = {
    report: copyWritings("report")
  };

  useEffect(() => {
    dispatch(getTripReport(id));
    dispatch(getDateAvailableReport(id));
  }, [dispatch, id]);

  const _submitReport = () => {
    dispatch(putSubmitTripReport(reportId));
    setModalSubmit(false);
  };

  const _deleteReport = () => {
    dispatch(
      deleteTripReport(
        itemReport.id,
        convertDate(itemReport.date, '"YYYY-MM-DD"')
      )
    );
    setModalDelete(false);
  };

  const _formatDate = (date: string) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const d = new Date(date);
    const formattedDay = days[d.getDay()];
    const formattedDate = d.getDate();
    const formattedMonth = monthNames[d.getMonth()];
    const formattedYear = d.getFullYear();
    return `${formattedDay}, ${formattedDate} ${formattedMonth} ${formattedYear}`;
  };

  const _renderEmpty = () => {
    if (editReportState.listDateAvailableReport.length > 0) {
      return (
        <View>
          <Card
            containerStyle={styles.button}
            cardStyle={[styles.button2, styles.button3]}
            onPress={() =>
              history.push(`/tripdetail/newreport/${id}`, { reportId: "" })
            }
          >
            <Text style={[styles.buttonText, styles.buttonText2]}>
              {sprintf(t("activity.addReport"), copTeks.report)}
            </Text>
          </Card>
        </View>
      );
    }
    return (
      <View style={styles.centerText}>
        <CustomText type="bold" size={16}>
          Report not available
        </CustomText>
      </View>
    );
  };

  return (
    <View style={{ minHeight: heightPercent(100) - top }}>
      <WrapContent isLoading={isLoadingTripById}>
        <WrapList
          isEmpty={listTripReport.length === 0}
          emptyComponent={
            dataTrip.type !== "history" ? _renderEmpty : undefined
          }
        >
          {listTripReport.map((item, index) => {
            return (
              <Card cardStyle={styles.card} key={index}>
                <View>
                  <View
                    style={[
                      styles.header,
                      item.status === 2
                        ? { backgroundColor: "#50b1b1" }
                        : { backgroundColor: "#f5aa3f" }
                    ]}
                  >
                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row"
                      }}
                    >
                      <Text style={styles.textHeader}>
                        {_formatDate(item.date)}
                      </Text>
                      {item.status === 1 ? (
                        <View style={{ flexDirection: "row" }}>
                          {dataTrip.type !== "history" ? (
                            <>
                              <TouchableOpacity
                                style={styles.btnReport}
                                onPress={() => {
                                  setModalDelete(true);
                                  setItemReport(item);
                                }}
                              >
                                <Image
                                  style={{ width: 10, height: 14 }}
                                  source={IMAGES.trashBin}
                                />
                              </TouchableOpacity>

                              <TouchableOpacity
                                style={styles.btnReport}
                                onPress={() => {
                                  setModalSubmit(true);
                                  setReportId(item.id);
                                }}
                              >
                                <Image
                                  style={{ width: 12, height: 12 }}
                                  source={IMAGES.checkList}
                                />
                              </TouchableOpacity>
                            </>
                          ) : (
                            <></>
                          )}
                        </View>
                      ) : null}
                    </View>
                  </View>

                  <Touchable
                    onPress={() =>
                      history.push(`/tripdetail/newreport/${id}`, {
                        reportId: item.id
                      })
                    }
                  >
                    <View style={{ marginTop: 15 }}>
                      <Text style={[styles.text, { fontWeight: "bold" }]}>
                        {item.title || "-"}
                      </Text>
                      <Text
                        numberOfLines={5}
                        style={[styles.text, styles.posDesc]}
                      >
                        <span
                          dangerouslySetInnerHTML={textToLink(
                            item.description || "-"
                          )}
                        />
                      </Text>
                      <Text
                        style={[
                          styles.text,
                          {
                            fontWeight: "bold",
                            fontSize: 14,
                            marginBottom: 16
                          }
                        ]}
                      >
                        {item.file[0] === null
                          ? t("activity.zeroAttached")
                          : `${item.file.length} ${t(
                              "activity.filesAttached"
                            )}`}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </Card>
            );
          })}
        </WrapList>

        {editReportState.listDateAvailableReport.length > 0 && (
          <View style={{ alignItems: "center" }}>
            <Card
              containerStyle={styles.button}
              cardStyle={[styles.button2, styles.button3]}
              onPress={() =>
                history.push(`/tripdetail/newreport/${id}`, { reportId: "" })
              }
            >
              <Text style={[styles.buttonText, styles.buttonText2]}>
                {sprintf(t("activity.addReport"), copTeks.report)}
              </Text>
            </Card>
          </View>
        )}
      </WrapContent>

      {showClosingTripNotes && (
        <Modal
          isVisible
          onBackdropPress={() => setShowClosingTripNotes(!showClosingTripNotes)}
        >
          <ClosingTripNotes
            tripId={id}
            closeBtn={() => setShowClosingTripNotes(!showClosingTripNotes)}
          />
        </Modal>
      )}
      <Modal isVisible={modalSubmit} backdropColor="rgba(255,255,255, 0.9)">
        <Confirmation
          headerTitle={sprintf(t("activity.submitReport"), copTeks.report)}
          // eslint-disable-next-line max-len
          description={sprintf(
            t("activity.submitMsg"),
            copTeks.report,
            copTeks.report,
            copTeks.report,
            copTeks.report,
            copTeks.report
          )}
          actionTitle={t("activity.submitBtn")}
          cancelTitle={t("activity.cancel")}
          onCancel={() => setModalSubmit(false)}
          isLoading={editReportState.isLoadingPutSubmitTripReport}
          onAction={_submitReport}
        />
      </Modal>
      <Modal isVisible={modalDelete} backdropColor="rgba(255,255,255, 0.9)">
        <Confirmation
          headerTitle={t("cash.new.deleteReport")}
          // eslint-disable-next-line max-len
          description={t("cash.new.msg.deleteMsg")}
          actionTitle={t("cash.new.delete")}
          cancelTitle={t("activity.cancel")}
          onCancel={() => setModalDelete(false)}
          isLoading={editReportState.isLoadingDeleteTripReport}
          onAction={_deleteReport}
        />
      </Modal>
      {/* {modalDetailIsOpen && (
        <Modal
          isVisible={modalDetailIsOpen}
          isNotCenter
          withScroll
          footer={() => (
            <Fixed getHeight={value => setHeight(value)} position="bottom">
              <View style={{ alignItems: "center" }}>
                <Touchable onPress={() => closeModalDetail()}>
                  <View style={styles.footerModal}>
                    <Text style={styles.btnClose}>CLOSE</Text>
                  </View>
                </Touchable>
              </View>
            </Fixed>
          )}
        >
          <View style={[styles.cardModalStyle, { paddingBottom: height }]}>
            <View style={styles.insideModal}>
              <Text style={styles.titleReport}>{detailReport.title}</Text>
              <Text style={styles.dateReport}>
                {convertDate(detailReport.created_at, "DD MMMM YYYY")}
              </Text>
              <Text style={styles.desc}>
                <span
                  dangerouslySetInnerHTML={textToLink(detailReport.description)}
                />
              </Text>
              <Text style={styles.textImages}>IMAGES</Text>
              <View style={{ width: "100%" }}>
                {attachmentImg.length > 0 ? (
                  attachmentImg.map((item: any, index: number) => {
                    return (
                      <Image
                        key={index}
                        resizeMode="cover"
                        style={{ width: "100%", height: 219 }}
                        source={{
                          uri: `${item.url}`
                        }}
                      />
                    );
                  })
                ) : (
                  <Text>-</Text>
                )}
              </View>
              <Text style={styles.textFiles}>FILES</Text>
              <View style={{ width: "100%", marginBottom: 25 }}>
                {attachmentDoc.length > 0 ? (
                  attachmentDoc.map((item: any, index) => {
                    return (
                      <Card
                        key={index}
                        cardStyle={{ width: 160, marginTop: 12 }}
                      >
                        <Text
                          numberOfLines={1}
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 11,
                            color: "#666666",
                            paddingVertical: 6,
                            marginHorizontal: 15
                          }}
                        >
                          {item.title}
                        </Text>
                      </Card>
                    );
                  })
                ) : (
                  <Text>-</Text>
                )}
              </View>
            </View>
          </View>
        </Modal>
      )} */}
    </View>
  );
};

export default Report;
