import React, { useState } from "react";
import { Text, TextInput, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import "./style.css";
import {
  Button,
  FileUpload,
  Header,
  Touchable,
  Upload,
  WrapContent
} from "../../components";
import { postFeedBack } from "../../redux/actions";
import { Reducers } from "../../redux/types";

const FeedBack = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control, register, watch, errors } = useForm();
  const homeState = useSelector((state: Reducers) => state.home);
  const { t } = useTranslation();

  const [file, setFile]: any = useState(null);
  const [dataHelp] = useState([
    { value: "", label: t("feedback.select") },
    { value: "bug", label: t("feedback.error") },
    { value: "support", label: t("feedback.helpSupport") },
    { value: "feedback", label: t("feedback.feedback") },
    { value: "others", label: t("feedback.others") }
  ]);

  const _uploadFile = (e: any) => {
    const files = e.target.files[0];
    setFile(files);
  };

  const deleteFile = () => {
    setFile(null);
  };

  const _goToPreview = () => {
    if (file.title) {
      window.open(file.url, "_blank");
    } else {
      const go = URL.createObjectURL(file);
      window.open(go, "_blank");
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      file
    };
    dispatch(postFeedBack(form, () => history.goBack()));
  };

  return (
    <View style={styles.container}>
      <Header
        goBack={() => {
          history.goBack();
        }}
        title={t("feedback.helpSupport")}
        textHeader={{ fontSize: 18 }}
      />
      <WrapContent isLoading={false}>
        <View style={styles.content}>
          <View style={styles.row}>
            <Text style={[styles.font, styles.label]}>
              {t("feedback.title")}
            </Text>
            <Controller
              control={control}
              render={({ onChange, value }) => (
                <TextInput
                  style={[styles.font, styles.inputLine]}
                  onChangeText={text => onChange(text)}
                  value={value}
                />
              )}
              name="title"
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.title && (
              <Text style={styles.validateMessages}>{t("feedback.req")}</Text>
            )}
          </View>

          <View style={styles.row}>
            <Text style={[styles.font, styles.label]}>
              {t("feedback.type")}
            </Text>
            <View style={styles.card}>
              <select
                name="type"
                className="container-content-input-feedback"
                style={{
                  color: "#666666",
                  fontWeight: "bold",
                  borderRadius: 100,
                  borderBottomWidth: 0,
                  marginLeft: 8,
                  marginRight: 10,
                  opacity: watch("type") ? 1 : 0.6
                }}
                ref={register({ required: true })}
              >
                {dataHelp.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
              </select>
            </View>
            {errors.type && (
              <Text style={styles.validateMessages}>{t("feedback.req")}</Text>
            )}
          </View>
          <View style={styles.row}>
            <Text style={[styles.font, styles.label]}>
              {t("feedback.description")}
            </Text>
            <Controller
              control={control}
              render={({ onChange, value }) => (
                <TextInput
                  multiline
                  style={[styles.font, styles.textArea]}
                  onChangeText={text => onChange(text)}
                  value={value}
                />
              )}
              name="description"
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.description && (
              <Text style={styles.validateMessages}>{t("feedback.req")}</Text>
            )}
          </View>
          <View style={styles.wrapAttch}>
            <View style={{ marginBottom: 6 }}>
              {file && (
                <Touchable onPress={_goToPreview}>
                  <FileUpload
                    name={file.name}
                    style={{
                      marginTop: 6,
                      marginBottom: 6,
                      marginRight: 8,
                      width: "40%"
                    }}
                    deleteFile={e => {
                      deleteFile();
                      e.stopPropagation();
                    }}
                  />
                </Touchable>
              )}
            </View>
            <Upload
              style={{
                textAlign: "center",
                padding: 0,
                width: "48%",
                alignItems: "center",
                paddingBottom: 6,
                paddingTop: 6
              }}
              onChange={(el: any) => _uploadFile(el)}
            />
          </View>
          <View
            style={{
              position: "absolute",
              alignItems: "center",
              bottom: 24,
              alignSelf: "center"
            }}
          >
            <Button
              isLoading={homeState.isLoadingAddFeedback}
              title={t("feedback.send")}
              onPress={handleSubmit(_onSave)}
            />
          </View>
        </View>
      </WrapContent>
    </View>
  );
};

export default FeedBack;
