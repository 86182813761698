import React, { useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
// import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  Button,
  Container,
  Gradient,
  Input,
  Touchable
} from "../../components";
import { ICONS, IMAGES } from "../../configs";
import styles from "./styles";
import { documentTitle, heightPercent } from "../../utils";

// redux action & types
import { handleFirstTime, handleLogin } from "../../redux/actions";
import { Reducers } from "../../redux/types";

const Login = () => {
  documentTitle("djurnee - Login");
  const history = useHistory();
  const dispatch = useDispatch();
  const { authState, saveRouteState } = useSelector(
    (state: Reducers) => ({
      authState: state.auth,
      saveRouteState: state.saveRoute
    }),
    shallowEqual
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [remember, setRemember] = useState(false);

  useEffect(() => {
    dispatch(handleFirstTime());
  }, [dispatch]);

  return (
    <Gradient backgroundImage="linear-gradient(to bottom, #50b1b1 0%, #262261 121%)">
      <Container style={{ backgroundColor: "transparent" }}>
        <View
          style={{ height: heightPercent(140), maxHeight: heightPercent(110) }}
        >
          <Image
            source={IMAGES.bubble}
            style={styles.backgroundBubble}
            resizeMode="stretch"
          />
          <View style={styles.container}>
            <View style={styles.wrapButtonBack}>
              <Touchable onPress={() => history.goBack()}>
                <View style={styles.buttonBack}>
                  <Image
                    source={ICONS.iconBack}
                    style={styles.iconBack}
                    resizeMode="contain"
                  />
                </View>
              </Touchable>
            </View>
            <View style={styles.wrapTitle}>
              <Image source={IMAGES.title} style={styles.imageTitle} />
            </View>
            <View style={styles.wrapForm}>
              <Input
                borderColor="rgba(255,255,255,0.5)"
                textColor="#ffffff"
                placeholder="email"
                onChangeText={text => setEmail(text)}
                value={email}
                keyboardType="email-address"
              />
              <Input
                borderColor="rgba(255,255,255,0.5)"
                textColor="#ffffff"
                placeholder="password"
                onChangeText={text => setPassword(text)}
                value={password}
                securityText
                endEditing={({ nativeEvent }) => {
                  if (nativeEvent.key === "Enter") {
                    dispatch(
                      handleLogin(
                        () => {
                          history.push("/home");
                        },
                        email,
                        password,
                        // remember,
                        true,
                        true
                      )
                    );
                  }
                }}
              />
              {/* <View style={styles.wrapSwitch}>
                <Switch
                  onChange={() => setRemember(!remember)}
                  checked={remember}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={18}
                  width={32}
                />
                <Text style={styles.textRemember}>Remember me</Text>
              </View> */}
              <View style={styles.wrapButton}>
                <Button
                  title="LOGIN"
                  onPress={() =>
                    dispatch(
                      handleLogin(
                        (roles?: string) => {
                          if (roles === "personal-loan-user") {
                            history.push("/kasbon");
                          } else {
                            history.push(
                              saveRouteState.pathname !== ""
                                ? saveRouteState.pathname
                                : "/home"
                            );
                          }
                        },
                        email,
                        password,
                        // remember,
                        true,
                        true
                      )
                    )
                  }
                  isLoading={authState.isLoading}
                />
                <View style={styles.buttonForgot}>
                  <Touchable>
                    <Text style={styles.textForgot}>Forgot password?</Text>
                  </Touchable>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Container>
    </Gradient>
  );
};

export default Login;
