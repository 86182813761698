import React, { memo, useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import { getBudgetRequest, getPerDiem } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import { Card, WrapContent, WrapList } from "../../../../components";
import styles from "./styles";
import { maskedMoney } from "../../../../utils";

interface Props {
  id: string;
}

const Perdiem = ({ id }: Props) => {
  const dispatch = useDispatch();
  const ExpenseState = useSelector((state: Reducers) => state.newExpenseTrip);
  const lang = Cookies.get("locale") || "en";

  const _currency = (num: number, idr: string) => {
    return `${idr} ${Math.trunc(num)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
  };

  useEffect(() => {
    dispatch(getPerDiem(id));
    dispatch(getBudgetRequest(id, lang));
  }, [dispatch]);

  return (
    <WrapContent isLoading={ExpenseState.isLoadingListPerDiem}>
      <WrapList
        isEmpty={ExpenseState.listPerDiem.length === 0}
        emptyComponent={() => <Text style={styles.text}>Kosong</Text>}
      >
        <FlatList
          style={{ paddingHorizontal: 20 }}
          data={ExpenseState.listPerDiem}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Card
              key={index}
              cardStyle={{
                marginBottom: 15,
                marginTop: index === 0 ? 25 : 0
              }}
              statusColor="#50b1b1"
              statusText="Amount"
              statusDesc={_currency(item.total, "IDR")}
            >
              <View style={{ marginLeft: 21, marginVertical: 17 }}>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={styles.text}>Category</Text>
                  <Text style={styles.textDesc}>{item.title}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.text}>Desc.</Text>
                  <Text style={styles.textDesc}>{item.description || "-"}</Text>
                </View>
              </View>
            </Card>
          )}
        />
      </WrapList>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          paddingTop: 5
        }}
      >
        <View style={styles.footer}>
          <Text style={styles.budgetReq}>Budget Request</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.priceIdr}>IDR</Text>
            {typeof ExpenseState.listBudgetReq.Total !== "undefined" &&
            typeof ExpenseState.listBudgetReq.Total.IDR !== "undefined" ? (
              // eslint-disable-next-line dot-notation
              <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                {` ${maskedMoney(ExpenseState.listBudgetReq["Lump Sum"].IDR)}`}
              </Text>
            ) : (
              <Text style={[styles.priceIdr, { fontWeight: "bold" }]}> -</Text>
            )}
          </View>
        </View>
      </div>
    </WrapContent>
  );
};

export default memo(Perdiem);
