/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import moment from "moment";
import styles from "./styles";
import { Fixed, Message, Modal, WrapContent } from "../../../components";
import { Reducers } from "../../../redux/types";
import {
  copyWritings,
  documentTitle,
  heightPercent,
  queryString
} from "../../../utils";
import PerDiem from "./PerDiem";
import CashAdvance from "./CashAdvance";
import Reimburse from "./Reimburse";
import Bill from "./Bill";
import ClosingTripNotes from "../Trip/components/ClosingTripNotes";
import { getExpenseCashAdvance } from "../../../redux/actions";

interface Props {
  id: string;
  search: string;
  top: number;
  offsetTop: number;
  isBillApproval?: boolean;
}

const Expense = ({ id, search, top, offsetTop, isBillApproval }: Props) => {
  documentTitle("Trip Detail - Expense");
  const match = useRouteMatch();
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;
  const route = isBillApproval ? initialRoute : match.path;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [heightHeader, setHeightHeader] = useState(0);
  const [showClosingTripNotes, setShowClosingTripNotes] = useState(false);
  const { pathname } = history.location;
  const param = queryString(search);
  const { tripData, isLoadingTripById } = useSelector(
    (state: Reducers) => state.trip
  );

  const copTeks = {
    expense: copyWritings("expense"),
    cash_advance: copyWritings("cash_advance"),
    lump_sum: copyWritings("lump_sum"),
    reimburse: copyWritings("reimburse")
  };

  useEffect(() => {
    dispatch(getExpenseCashAdvance(id));
  }, [dispatch, id]);

  const listPages = [
    {
      pathname: `${route}`,
      titleTab: copTeks.lump_sum,
      component: <PerDiem id={id!} />,
      exact: true,
      accessible: true
    },
    {
      pathname: `${route}/cash-advance`,
      titleTab: copTeks.cash_advance,
      component: <CashAdvance id={id!} isBillApproval={isBillApproval} />,
      exact: true,
      accessible: true
    },
    {
      pathname: `${route}/bill`,
      titleTab: copTeks.expense,
      component: <Bill id={id!} isBillApproval={isBillApproval} />,
      exact: true,
      accessible: ["ongoing", "history", "bill"].includes(param.type)
    },
    {
      pathname: `${route}/reimburse`,
      titleTab: copTeks.reimburse,
      component: (
        <Reimburse id={id!} search={search} isBillApproval={isBillApproval} />
      ),
      exact: true,
      accessible: ["ongoing", "history", "bill"].includes(param.type)
    }
  ];

  const _warningExpiredTrip = () => {
    if (
      ["1", "4"].includes(param.status) &&
      moment(tripData && tripData.finished_at).format("YYYY-MM-DD HH:mm") <
        moment().format("YYYY-MM-DD HH:mm")
    ) {
      return true;
    }
    return false;
  };

  return (
    <Switch>
      <WrapContent isLoading={isLoadingTripById}>
        <Fixed
          getHeight={e => setHeightHeader(e)}
          customPosition={{ top: top - offsetTop }}
        >
          <View style={styles.bookingpDetailContent}>
            <View style={{ paddingTop: offsetTop - 2 }}>
              <View
                style={{
                  alignItems: "center"
                }}
              >
                <View
                  style={[
                    styles.taskNav,
                    isBillApproval && { borderRadius: 20 }
                  ]}
                >
                  {listPages.map((item, index, items) => (
                    <>
                      {item.accessible && (
                        <View
                          key={index}
                          style={[
                            styles.btnNav,
                            index === 1 && { width: 220 },
                            index === 0 &&
                              item.pathname === pathname &&
                              styles.activeFirst,
                            index === 0 &&
                              item.pathname === pathname &&
                              isBillApproval && { borderTopLeftRadius: 20 },
                            index ===
                              items.filter(e => e.accessible).length - 1 &&
                              item.pathname === pathname &&
                              styles.activeLast,
                            index ===
                              items.filter(e => e.accessible).length - 1 &&
                              item.pathname === pathname &&
                              isBillApproval && { borderTopRightRadius: 20 },
                            index !== 0 &&
                              pathname.includes(item.pathname) &&
                              styles.active
                          ]}
                        >
                          <TouchableOpacity
                            style={{ height: "100%", justifyContent: "center" }}
                            activeOpacity={1}
                            onPress={() => history.push(item.pathname + search)}
                          >
                            <Text numberOfLines={1} style={styles.btnText}>
                              {item.titleTab}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    </>
                  ))}
                </View>
              </View>
            </View>
          </View>
          {_warningExpiredTrip() && (
            <View
              style={{
                paddingHorizontal: 32,
                paddingTop: 12,
                backgroundColor: "#fffff7",
                marginTop: -0.5
              }}
            >
              <Message message={t("activity.detail.msg.tripMsg")} />
            </View>
          )}
        </Fixed>

        {showClosingTripNotes && (
          <Modal
            isVisible
            onBackdropPress={() =>
              setShowClosingTripNotes(!showClosingTripNotes)
            }
          >
            <ClosingTripNotes
              tripId={id}
              closeBtn={() => setShowClosingTripNotes(!showClosingTripNotes)}
            />
          </Modal>
        )}

        <View
          style={{
            marginTop: isBillApproval ? heightHeader : heightHeader - offsetTop,
            minHeight: heightPercent(100) - (heightHeader + top - offsetTop)
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} key={index} exact={item.exact}>
              {item.component}
            </Route>
          ))}
        </View>
      </WrapContent>
    </Switch>
  );
};

export default Expense;
