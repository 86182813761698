import { StyleSheet } from "react-native";
import { heightPercent } from "../../../../utils";

// import { heightPercent, widthPercent } from "../../utils";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundImage: "linear-gradient(180deg, #FFFFF7 0%, #FAEDE3 100%)",
    marginTop: -heightPercent(8),
    zIndex: -1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },

  content: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: heightPercent(8) + 20,
    marginBottom: 32
  },

  emptycontent: {
    flex: 1,
    marginTop: heightPercent(8) + 20,
    marginBottom: 32,
    height: "100%",
    alignSelf: "center",
    textAlign: "center"
  },

  remainingBalance: {
    fontSize: 12,
    color: "#939393"
  },

  scrollContent: {
    maxHeight: 500,
    marginBottom: 10,
    overflow: "scroll"
  },

  balance: {
    fontWeight: "700",
    color: "#343434",
    fontSize: 26,
    marginVertical: 4,
    lineHeight: 40,
    width: 200
  },

  newLoanBtn: {
    backgroundColor: "#FF9500",
    padding: 12,
    borderRadius: 12,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },

  addLoan: {
    height: 20,
    width: 20,
    marginRight: 8
  },

  btnText: {
    color: "white",
    fontSize: 12
  },

  label: {
    color: "#343434",
    fontSize: 16,
    marginBottom: 12
  },

  submitedKasbonContainer: {
    borderRadius: 12,
    marginBottom: 32,
    backgroundColor: "#FFFFF7"
  },

  kasbonStatus: {
    padding: 12,
    flexDirection: "row",
    justifyContent: "space-between"
  },

  containerLabel: {
    flexDirection: "row",
    justifyContent: "space-between"
  },

  status: {
    color: "#FFA629",
    fontWeight: "700",
    flex: 1,
    textAlign: "right"
  },

  containerHistory: {
    padding: 12,
    backgroundColor: "#FFFFF7",
    borderRadius: 12,
    marginBottom: 32
  },

  filterHistory: {
    backgroundColor: "#F2E7DA",
    width: 84
  },

  history: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 32
  },

  amount: {
    textAlign: "right",
    flex: 1,
    marginRight: 20
  }
});

export default styles;
