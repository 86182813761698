import { StyleSheet } from "react-native";

const font = "Poppins";
const color = "#999999";

const styles = StyleSheet.create({
  labelText: {
    width: 52,
    fontFamily: font,
    color,
    fontSize: 11,
    marginRight: 14
  },
  wrapValue: { flex: 3 },
  valueText: {
    fontFamily: font,
    color: "#666666",
    fontWeight: "bold",
    fontSize: 11
  },
  valueNumberText: { fontFamily: font, color, fontSize: 11 },
  row: { flexDirection: "row", marginTop: 11 },
  valueProjectText: {
    fontFamily: font,
    color: "#666666",
    fontWeight: "bold",
    fontSize: 14
  },
  valueCategoryText: {
    fontFamily: font,
    color: "#50b1b1",
    fontWeight: "bold",
    fontSize: 11
  },
  buttonNewBill: {
    backgroundImage:
      "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
    borderRadius: 100,
    width: 107,
    height: 28
  },
  buttonNewBillText: {
    color: "#FFF",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 11
  },
  buttonNewBillTextWrap: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  billText: {
    width: 52,
    marginRight: 14,
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#999999"
  },
  billPriceText: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#707070",
    fontWeight: "bold"
  },
  footer: {
    height: 74,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  footerRed: {
    height: 74,
    backgroundColor: "#ED5A28",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  footerPriceDescription: {
    fontFamily: font,
    fontSize: 11,
    color: "#fffff7"
  },
  footerPrice: {
    flexDirection: "row"
  },
  footerPriceCurrency: {
    fontFamily: font,
    fontSize: 18,
    color: "#fffff7"
  },
  footerPriceText: {
    fontFamily: font,
    fontWeight: "bold",
    fontSize: 18,
    color: "#fffff7"
  },
  footerOption: {},
  closeBill: {
    backgroundImage:
      "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
    borderRadius: 100,
    width: 180,
    height: 54,
    marginBottom: 20,
    alignSelf: "center"
  },
  closeBillTextWrap: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  closeBillText: {
    color: "#FFF",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18
  },
  cancel: {
    fontWeight: "bold",
    padding: 12,
    fontSize: 14,
    color: "#3f7790",
    fontFamily: "Poppins",
    textDecorationLine: "underline"
  }
});

export default styles;
