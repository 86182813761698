/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Header, TextArea } from "../../components";
import styles from "./styles";
import { postNewCashExpense } from "../../redux/actions";
import { Reducers } from "../../redux/types";
import { copyWritings, maskedMoney } from "../../utils";

const NewCashExpense = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const params = history.location.state as any;
  const { newCashExpense } = useSelector(
    (state: Reducers) => ({
      newCashExpense: state.newCashExpense,
      tripExpense: state.newExpenseTrip
    }),
    shallowEqual
  );

  const copTeks = {
    cash_advance: copyWritings("cash_advance")
  };

  const [form, setForm] = useState({
    trip_id: params.id,
    invoice_id: params.invoice_id,
    type_id: "Cash Advance",
    currency_code: "IDR",
    item_id: params.itemId,
    title: "",
    category_id: "",
    description: "",
    quantity: 1,
    amount: "",
    billable: false,
    currency_id: "",
    client_timezone: "Asia/Jakarta"
  });

  const [isError, setIsError] = useState(false);

  const _setForm = useCallback(
    (formName: "title" | "description" | "amount", formValue: any) => {
      setForm({ ...form, [formName]: formValue });
      if (isError) {
        setIsError(false);
      }
    },
    [form, isError]
  );

  useEffect(() => {
    if (params.item && params.item.amount) {
      setForm({
        ...form,
        title: params.item.title,
        description: params.item.description,
        amount: params.item.amount.split(".")[0]
      });
    }
  }, [params.item]);

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (params) {
      status = params.status;
    }

    if (status === 1) {
      desc = t("cash.status.draft");
    } else if (status === 2) {
      desc = t("cash.status.needApproval");
    } else if (status === 3) {
      desc = t("cash.status.approved");
    } else if (status === 4) {
      desc = t("cash.status.revise");
    } else if (status === 5) {
      desc = t("cash.status.ongoing");
    } else if (status === 6) {
      desc = t("cash.status.finished");
    } else if (status === 7) {
      desc = t("cash.status.reject");
    }

    return desc;
  };

  const _renderAlertError = useCallback(
    (isSHow: boolean) =>
      isSHow && (
        <Text style={[styles.label, styles.labelError]}>
          {t("cash.new.msg.req")}
        </Text>
      ),
    []
  );

  const _onSave = () => {
    if (form.title !== "" && form.amount !== "") {
      dispatch(postNewCashExpense(form, () => history.goBack(), params.itemId));
    } else {
      setIsError(true);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={sprintf(t("cash.new.addReport"), copTeks.cash_advance)}
        label={_statusDesc()}
        textHeader={{ fontSize: 18 }}
      />
      <View style={[styles.formWrap, { flex: 1 }]}>
        <View
          style={[styles.row, isError && form.title === "" && styles.error]}
        >
          <Text style={[styles.label, { marginLeft: 0 }]}>
            {t("cash.new.subject")}
          </Text>
          <TextInput
            style={[styles.textInput]}
            value={form.title}
            onChangeText={text => _setForm("title", text)}
          />
          {_renderAlertError(isError && form.title === "")}
        </View>

        <View style={styles.row}>
          <Text style={[styles.label, { marginLeft: 0 }]}>
            {t("cash.new.description")}
          </Text>
          <TextArea
            bold
            onChange={e => _setForm("description", e.target.value)}
            value={form.description}
            border={false}
          />
        </View>

        <View
          style={[
            styles.row,
            isError && form.amount === "" && styles.error,
            { width: "100%" }
          ]}
        >
          <Text style={[styles.label, { marginLeft: 0 }]}>
            {t("cash.new.amount")}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Text
              style={[
                styles.label,
                { fontSize: 14, width: "25%", marginLeft: 0 }
              ]}
            >
              IDR
            </Text>
            <TextInput
              style={[styles.textInput, { textAlign: "right", width: "75%" }]}
              onChangeText={text => _setForm("amount", text)}
              value={form.amount}
              keyboardType="numeric"
            />
          </View>
          {_renderAlertError(isError && form.amount === "")}
        </View>

        <View style={styles.wrapTwoInput}>
          <View style={[styles.row, { width: "45%", borderBottomWidth: 0 }]}>
            <Text style={[styles.label, { marginLeft: 0 }]}>
              {t("activity.new.total")}
            </Text>
          </View>

          <View
            style={[
              styles.row,
              {
                width: "45%",
                borderBottomWidth: 0,
                flexDirection: "row",
                justifyContent: "space-between"
              }
            ]}
          >
            <Text style={[styles.label, { fontSize: 14, width: "25%" }]}>
              IDR
            </Text>
            <Text
              style={[
                styles.label,
                {
                  fontSize: 14,
                  fontWeight: "bold",
                  width: "75%",
                  textAlign: "right"
                }
              ]}
            >
              {maskedMoney(Number(form.quantity) * Number(form.amount))}
            </Text>
          </View>
        </View>

        <View
          style={{
            paddingTop: 12,
            alignItems: "center",
            flex: 1
          }}
        >
          <View style={styles.wrapSaveBtn}>
            <Button
              title={`${
                params.itemId ? t("cash.new.save") : t("cash.new.add")
              }`}
              isLoading={newCashExpense.isLoadingPostNewCashExpense}
              style={{ paddingHorizontal: 10 }}
              onPress={_onSave}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default NewCashExpense;
