import {
  DELETE_BOOKING_BY_ID_ERROR,
  DELETE_BOOKING_BY_ID_PENDING,
  DELETE_BOOKING_BY_ID_SUCCESS,
  GET_INVOICE_BOOKING_LIST_ERROR,
  GET_INVOICE_BOOKING_LIST_PENDING,
  GET_INVOICE_BOOKING_LIST_SUCCESS,
  GET_LIST_BOOKING_FLIGHT_ERROR,
  GET_LIST_BOOKING_FLIGHT_PENDING,
  GET_LIST_BOOKING_FLIGHT_SUCCESS,
  GET_LIST_BOOKING_HOTEL_ERROR,
  GET_LIST_BOOKING_HOTEL_PENDING,
  GET_LIST_BOOKING_HOTEL_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, BookingState } from "../types";

const initialState: BookingState = {
  isLoadingListBookingHotel: false,
  listBookingHotel: [],
  isLoadingListBookingFlight: false,
  listBookingFlight: [],
  isLoadingDeleteBookingById: false,
  listInvoiceBooking: [],
  isLoadingListInvoiceBooking: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // get list booking hotel
    case GET_LIST_BOOKING_HOTEL_PENDING:
      return { ...state, isLoadingListBookingHotel: true };
    case GET_LIST_BOOKING_HOTEL_SUCCESS:
      return {
        ...state,
        isLoadingListBookingHotel: false,
        listBookingHotel: payload.data
      };
    case GET_LIST_BOOKING_HOTEL_ERROR:
      return { ...state, isLoadingListBookingHotel: false };

    // get list booking flight
    case GET_LIST_BOOKING_FLIGHT_PENDING:
      return { ...state, isLoadingListBookingFlight: true };
    case GET_LIST_BOOKING_FLIGHT_SUCCESS:
      return {
        ...state,
        isLoadingListBookingFlight: false,
        listBookingFlight: payload.data
      };
    case GET_LIST_BOOKING_FLIGHT_ERROR:
      return { ...state, isLoadingListBookingFlight: false };

    case GET_INVOICE_BOOKING_LIST_PENDING:
      return { ...state, isLoadingListInvoiceBooking: true };
    case GET_INVOICE_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        isLoadingListInvoiceBooking: false,
        listInvoiceBooking: payload.data
      };
    case GET_INVOICE_BOOKING_LIST_ERROR:
      return { ...state, isLoadingListInvoiceBooking: false };

    // delete booking
    case DELETE_BOOKING_BY_ID_PENDING:
      return { ...state, isLoadingDeleteBookingById: true };
    case DELETE_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingDeleteBookingById: false,
        listBookingFlight: state.listBookingFlight.filter(
          item => item.id !== payload.id
        ),
        listBookingHotel: state.listBookingHotel.filter(
          item => item.id !== payload.id
        )
      };
    case DELETE_BOOKING_BY_ID_ERROR:
      return { ...state, isLoadingDeleteBookingById: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
