import React from "react";
import { FlatList, Text, View } from "react-native";
import { Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Card,
  // Fixed,
  LoadingLoadMore,
  Touchable,
  WrapList
} from "../../../components";
import { COLORS } from "../../../configs";
import { maskedMoney, widthPercent } from "../../../utils";

import { getListLoan } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

import styles from "./styles";

const UserLoan = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loanState = useSelector((state: Reducers) => state.loan);
  const renderStatus = (statusNumber: number) => {
    if (statusNumber === 3) {
      return t("loan.status.approved");
    }
    if (statusNumber === 5) {
      return t("loan.status.approved");
    }
    if (statusNumber === 6) {
      return t("loan.status.paid");
    }
    return t("loan.status.finished");
  };
  return (
    <Switch>
      <View style={{ flex: 1, marginTop: -8, alignItems: "center" }}>
        <WrapList
          isEmpty={loanState.listLoan.length === 0}
          emptyComponent={() => (
            <Text style={styles.text}>{t("loan.empty")}</Text>
          )}
          onEndReached={() => {
            if (loanState.params.lastPage !== loanState.params.page) {
              dispatch(getListLoan(loanState.statusNumber, true));
            }
          }}
        >
          <FlatList
            style={{ marginHorizontal: 20 }}
            data={loanState.listLoan}
            keyExtractor={(item, index) => index.toString()}
            ListFooterComponent={
              <LoadingLoadMore active={loanState.isLoadingLoan} />
            }
            renderItem={({ item, index }) => (
              <Touchable
                onPress={() => history.push(`/loan-detail/${item.id}`)}
              >
                <Card
                  key={index}
                  cardStyle={{
                    width: widthPercent(90),
                    alignItems: "center",
                    marginTop: 10
                  }}
                  statusText="Status"
                  statusColor={COLORS.green}
                  statusDesc={renderStatus(item.status)}
                  statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                >
                  <View
                    style={{ width: widthPercent(80), paddingVertical: 20 }}
                  >
                    <View style={styles.infoLoanTextWrap}>
                      <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                        {t("loan.date")}
                      </Text>
                      <Text
                        style={[styles.txtInfoLoan, { fontWeight: "bold" }]}
                      >
                        {moment(item.updated_at).format("DD MMMM YYYY")}
                      </Text>
                    </View>
                    <View style={styles.infoLoanTextWrap}>
                      <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                        {t("loan.name")}
                      </Text>
                      <Text
                        style={[styles.txtInfoLoan, { fontWeight: "bold" }]}
                      >
                        {item.employee.name}
                      </Text>
                    </View>
                    <View style={styles.infoLoanTextWrap}>
                      <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                        {t("loan.position")}
                      </Text>
                      <Text
                        style={[styles.txtInfoLoan, { fontWeight: "bold" }]}
                      >
                        {item.employee.position_name}
                      </Text>
                    </View>
                    <View style={styles.infoLoanTextWrap}>
                      <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                        {t("loan.loanAmount")}
                      </Text>
                      <Text
                        style={[styles.txtInfoLoan, { fontWeight: "bold" }]}
                      >
                        {`IDR ${maskedMoney(item.amount)}`}
                      </Text>
                    </View>
                  </View>
                </Card>
              </Touchable>
            )}
          />
        </WrapList>
      </View>
    </Switch>
  );
};

export default UserLoan;
