import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  card: {
    borderRadius: 15,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: COLORS.main
  },
  status: {
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 31,
    paddingVertical: 13,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    width: "100%",
    alignItems: "center"
  },
  statusText: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "white",
    fontSize: 11
  },
  statusDesc: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "white"
  }
});
export default styles;
