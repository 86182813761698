import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapTextInput: {
    borderBottomWidth: 0.5,
    marginVertical: 5,
    borderBottomColor: "rgba(102, 102, 102, 0.5)",
    flexDirection: "row",
    alignItems: "center"
  },
  textInput: {
    paddingVertical: 5,
    color: "#000000"
  },
  wrapIcon: {
    flexDirection: "row",
    alignItems: "center",
    borderRightColor: "rgba(102, 102, 102, 0.5)",
    borderRightWidth: 0.5,
    marginRight: 24
  },
  iconIndonesia: {
    width: 20,
    height: 20
  },
  text62: {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666",
    marginHorizontal: 6
  }
});

export default styles;
