import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flex: 1,
    marginTop: 24
  },
  wrapCloseButton: {
    flex: 1,
    justifyContent: "flex-end"
  },
  poppins: {
    color: COLORS.greyMedium,
    fontFamily: "Poppins",
    fontSize: 14
  },
  bold: {
    fontWeight: "bold"
  },
  wrapContent: {
    alignItems: "center"
  },
  headerTitle: {
    fontSize: 16,
    color: COLORS.orange,
    marginBottom: 12,
    textAlign: "center",
    fontWeight: "bold"
  },
  wrapForm: {
    marginBottom: 12,
    width: "100%"
  },
  label: {
    fontSize: 11,
    color: COLORS.grey,
    fontFamily: "Poppins",
    marginLeft: 12
  },
  labelIdr: {
    fontSize: 18,
    color: COLORS.grey,
    fontFamily: "Poppins",
    marginLeft: 12
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: COLORS.grey,
    width: "100%"
  },
  inputLine: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 30,
    paddingLeft: 12
  },
  inputLineAmount: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 45,
    paddingLeft: 12,
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 18,
    fontSize: 18
  },
  errorMessages: {
    marginTop: 4,
    fontSize: 11,
    fontFamily: "Poppins",
    color: COLORS.danger
  },
  desc: {
    paddingLeft: 14,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 42,
    outlineColor: "rgba(255,255,255,0)"
  },
  wrapAmount: {
    flexDirection: "row",
    width: "100%"
  },
  wrapIdr: {
    width: "20%",
    height: 45,
    borderBottomWidth: 1,
    borderBottomColor: "#707070"
  },
  wrapAttch: {
    marginTop: 8,
    width: "100%"
  }
});

export default styles;
