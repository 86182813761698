/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, FlatList, Image, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Slide, ToastContainer, toast } from "react-toastify";
import styles from "./styles";
import {
  Card,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import {
  deleteCashBill,
  getBill,
  getCashDetail,
  putSettle
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import {
  convertDate,
  copyWritings,
  maskedMoney,
  permissionPage
} from "../../../../utils";
import { IMAGES } from "../../../../configs";
import { Confirmation } from "../../../../components/ModalComponents";

interface Props {
  id: string;
}

const Bill = ({ id }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  const { cashState, homeState } = useSelector(
    (state: Reducers) => ({
      cashState: state.cash,
      homeState: state.home
    }),
    shallowEqual
  );

  const copTeks = {
    expense: copyWritings("expense"),
    cash_advance: copyWritings("cash_advance"),
    collectable: copyWritings("collectable")
  };

  const selfEdit = permissionPage("expense-self-edit");
  // const othersEdit = permissionPage("expense-others-edit");

  const [modalDelete, setModalDelete] = useState(false);
  const [modalSettle, setModalSettle] = useState(false);
  const [idDelete, setIdDelete] = useState("");
  const [permissionList, setPermissionList] = useState({
    status: 0,
    expense: false,
    creator: "",
    user: ""
  });

  useEffect(() => {
    if (cashState.cashHistory && homeState.myProfile) {
      dispatch(getBill(id));
    }
  }, [dispatch, cashState.cashHistory, homeState.myProfile]);

  useEffect(() => {
    setPermissionList({
      ...permissionList,
      status: cashState.cashHistory && cashState.cashHistory.status,
      expense:
        cashState.cashHistory && cashState.cashHistory.djurneeInvoice.expense,
      creator: cashState.cashHistory && cashState.cashHistory.creator.id,
      user: homeState.myProfile && homeState.myProfile.id
    });
  }, [cashState.cashHistory, homeState.myProfile.id]);

  const _totalItem = () => {
    let total = 0;
    if (cashState.billList.length > 0) {
      cashState.billList.map(item => {
        total += Number(item.total);
      });
    }
    return total;
  };

  const _permissionCreate = useCallback(() => {
    if (
      selfEdit &&
      permissionList.creator === permissionList.user &&
      permissionList.expense &&
      permissionList.status === 5
    ) {
      return true;
    }
    return false;
  }, [
    permissionList.creator,
    permissionList.expense,
    permissionList.status,
    permissionList.user
  ]);

  const _onDelete = () => {
    dispatch(
      deleteCashBill(idDelete, (run, info) => {
        if (run === true) {
          setModalDelete(false);
          toast.success(t("cash.new.msg.deleteSuccess"), {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
          setTimeout(() => {
            dispatch(getBill(id));
          }, 2100);
        } else {
          toast.error(info, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _onSettle = () => {
    const form = {
      invoice_id: id,
      trip_user_id: "",
      client_timezone: "Asia/Jakarta"
    };
    dispatch(
      putSettle(form, (run, info) => {
        if (run === true) {
          setModalSettle(false);
          toast.success(t("cash.new.msg.toastSuccess"), {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
          setTimeout(() => {
            dispatch(getCashDetail(id));
            dispatch(getBill(id));
          }, 2100);
        } else {
          toast.error(info, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _renderCollect = () => {
    if (
      !permissionList.expense &&
      _totalItem() !== Number(cashState.cashHistory.total)
    ) {
      return (
        <View style={[styles.wrapTotal, { marginBottom: 4 }]}>
          <Text style={styles.totalLabel}>{copTeks.collectable}</Text>
          <Text style={[styles.amountText, { color: "#ED5A28" }]}>
            <Text style={{ fontWeight: "normal" }}>IDR </Text>
            {maskedMoney(Number(cashState.cashHistory.total) - _totalItem())}
          </Text>
        </View>
      );
    }
    if (
      cashState.cashHistory.total_paid >= cashState.cashHistory.total &&
      _totalItem() !== Number(cashState.cashHistory.total)
    ) {
      return (
        <View style={[styles.wrapTotal, { marginBottom: 4 }]}>
          <Text style={styles.totalLabel}>{copTeks.collectable}</Text>
          {cashState.isLoadingCashDetail ? (
            <ActivityIndicator color="#707070" size="small" />
          ) : (
            <Text style={[styles.amountText, { color: "#ED5A28" }]}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(Number(cashState.cashHistory.total) - _totalItem())}
            </Text>
          )}
        </View>
      );
    }
    return <></>;
  };

  const _goToPreview = (link: any) => {
    window.open(`${link}`, "_blank");
  };
  return (
    <WrapContent isLoading={cashState.isLoadingGetBill}>
      <ToastContainer limit={1} />
      <WrapList
        isEmpty={cashState.billList.length === 0}
        emptyComponent={() =>
          _permissionCreate() ? (
            <View style={styles.wrapButtonEmpty}>
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() =>
                  history.push(
                    `/newBill/general/${id}/${
                      cashState.cashHistory &&
                      cashState.cashHistory.djurneeInvoice.project.id
                    }`
                  )
                }
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {sprintf(t("cash.new.addNew"), copTeks.expense)}
                </Text>
              </Card>
              <Card
                onPress={() => setModalSettle(true)}
                containerStyle={styles.button}
                cardStyle={styles.button2}
              >
                <Text style={styles.buttonText}>{t("cash.new.settled")}</Text>
              </Card>
            </View>
          ) : (
            <Text style={styles.emptyData}>
              {sprintf(t("cash.new.msg.noBill"), copTeks.expense)}
            </Text>
          )
        }
      >
        <View style={styles.container}>
          <FlatList
            data={cashState.billList}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) =>
              _permissionCreate() ? (
                <Card
                  cardStyle={styles.cardStyle}
                  statusColor="#50b1b1"
                  statusTextLight={t("cash.new.qty")}
                  statusText={item ? item.quantity : "-"}
                  statusDescLight="IDR "
                  statusDesc={maskedMoney(item.total)}
                  statusDescStyle={{ fontSize: 14 }}
                  statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                  onPress={() => history.push(`/editbill/${id}/${item.id}`)}
                >
                  <View style={styles.wrapContentCard}>
                    <View style={styles.wrapCardText}>
                      <Text style={[styles.textLabel]}>
                        {t("cash.new.date")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        <Text style={{ fontWeight: "normal" }}>
                          {`${convertDate(item.date, "ddd")} `}
                        </Text>
                        {convertDate(item.date, "DD MMM YYYY")}
                      </Text>
                      <View style={styles.wrapIcon}>
                        {item.file && (
                          <Touchable
                            onPress={e => {
                              e.stopPropagation();
                              _goToPreview(
                                item.file && JSON.parse(item.file).url
                              );
                            }}
                          >
                            <View style={styles.wrapTrashIcon}>
                              <Image
                                source={IMAGES.clipAttchment}
                                style={{ width: 17, height: 20 }}
                              />
                            </View>
                          </Touchable>
                        )}
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            setModalDelete(true);
                            setIdDelete(item.id);
                          }}
                        >
                          <View style={styles.wrapTrashIcon}>
                            <Image
                              source={IMAGES.trashBin}
                              style={{ width: 12, height: 15 }}
                            />
                          </View>
                        </Touchable>
                      </View>
                    </View>

                    <View style={[styles.wrapCardText, { marginTop: -10 }]}>
                      <Text style={styles.textLabel}>{t("cash.new.type")}</Text>
                      <Text
                        numberOfLines={1}
                        style={[styles.textLabel, styles.textValue]}
                      >
                        {item.category ? item.category.title : "-"}
                      </Text>
                    </View>
                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.new.subject")}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        {item.title}
                      </Text>
                    </View>
                  </View>
                </Card>
              ) : (
                <View style={{ marginBottom: 12 }} key={index}>
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>{t("cash.new.date")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      <Text style={{ fontWeight: "normal" }}>
                        {`${convertDate(item.date, "ddd")} `}
                      </Text>
                      {convertDate(item.date, "DD MMM YYYY")}
                    </Text>
                  </View>
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>{t("cash.new.type")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.category ? item.category.title : "-"}
                    </Text>
                  </View>
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>
                      {t("cash.new.subject")}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.title}
                    </Text>
                  </View>
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>{t("cash.new.qty")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.quantity}
                    </Text>
                  </View>
                  <View style={styles.wrapAmountText}>
                    <Text style={styles.amountText}>
                      <Text style={{ fontWeight: "normal" }}>IDR </Text>
                      {maskedMoney(item.total)}
                    </Text>
                  </View>
                  <View style={styles.line} />
                </View>
              )
            }
          />
          {_permissionCreate() && (
            <View style={styles.wrapButton}>
              {Number(cashState.cashHistory.total) - _totalItem() > 0 ? (
                <Card
                  containerStyle={styles.button}
                  cardStyle={[styles.button2, styles.button3]}
                  onPress={() =>
                    history.push(
                      `/newBill/general/${id}/${
                        cashState.cashHistory &&
                        cashState.cashHistory.djurneeInvoice.project.id
                      }`
                    )
                  }
                >
                  <Text style={[styles.buttonText, styles.buttonText2]}>
                    {sprintf(t("cash.new.addNew"), copTeks.expense)}
                  </Text>
                </Card>
              ) : null}
              <Card
                onPress={() => setModalSettle(true)}
                containerStyle={styles.button}
                cardStyle={styles.button2}
              >
                <Text style={styles.buttonText}>{t("cash.new.settled")}</Text>
              </Card>
            </View>
          )}
          {_renderCollect()}
          <View style={styles.wrapTotal}>
            <Text style={styles.totalLabel}>
              {sprintf(t("cash.new.total"), copTeks.expense)}
            </Text>
            <Text style={styles.amountText}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(_totalItem())}
            </Text>
          </View>
        </View>
      </WrapList>
      <Modal isVisible={modalDelete} backdropColor="rgba(255,255,255, 0.98)">
        <Confirmation
          headerTitle={sprintf(t("cash.new.deleteCop"), copTeks.expense)}
          description={t("cash.new.msg.deleteBill")}
          actionTitle={t("cash.new.delete")}
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalDelete(false)}
          isLoading={cashState.isLoadingDeletBill}
          onAction={_onDelete}
        />
      </Modal>
      <Modal isVisible={modalSettle} backdropColor="rgba(255,255,255, 0.9)">
        <Confirmation
          headerTitle={sprintf(t("cash.new.settleCA"), copTeks.cash_advance)}
          // eslint-disable-next-line max-len
          description={sprintf(
            t("cash.new.msg.settleMsg"),
            copTeks.expense,
            copTeks.expense,
            copTeks.cash_advance
          )}
          actionTitle={t("cash.new.save")}
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalSettle(false)}
          isLoading={cashState.isLoadingPutSettle}
          onAction={_onSettle}
        />
      </Modal>
    </WrapContent>
  );
};

export default Bill;
