import React, { ReactNode } from "react";
import { Text, View } from "react-native";
import i18n from "i18next";

import styles from "./styles";
import { Card, Touchable } from "../../../components";
import { heightPercent } from "../../../utils";

interface Props {
  onPress?: () => void;
  onPressRevise?: () => void;
  children: ReactNode;
  resolveButton?: boolean;
  headerTitle?: string;
}

const ModalNoteRevise = ({
  onPress,
  children,
  onPressRevise,
  resolveButton,
  headerTitle
}: Props) => {
  return (
    <View style={styles.container}>
      <Card cardStyle={styles.cardStyle}>
        <View style={{ height: heightPercent(80.8) }}>
          <Text style={styles.noteHeader}>{headerTitle}</Text>
          <View style={styles.noteDesc}>
            <Text style={styles.noteTextDesc}>{children}</Text>
          </View>
          {resolveButton ? (
            <View
              style={{ alignSelf: "center", position: "absolute", bottom: 60 }}
            >
              <Touchable onPress={onPressRevise}>
                <View style={styles.buttonRevise}>
                  <Text style={styles.buttonReviseText}>
                    {i18n.t("activity.resolve")}
                  </Text>
                </View>
              </Touchable>
            </View>
          ) : null}
        </View>
      </Card>
      <View style={styles.buttonClose}>
        <Touchable onPress={onPress}>
          <Text style={styles.closeText}>{i18n.t("activity.close")}</Text>
        </Touchable>
      </View>
    </View>
  );
};

ModalNoteRevise.defaultProps = {
  headerTitle: "notes"
};
export default ModalNoteRevise;
