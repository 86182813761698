import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 16
  },
  flexDirection: {
    flexDirection: "row",
    marginBottom: 4
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyLight
  },
  widthLabel: {
    width: "30%"
  },
  font14bold: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: COLORS.grey
  },
  value: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    borderRadius: 12,
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  wrapButtonTrash: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginRight: -8,
    marginTop: 8
  },
  buttonTrash: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
