const images = {
  introFirst: require("../assets/images/introFirst.png"),
  introSecond: require("../assets/images/introSecond.png"),
  introThird: require("../assets/images/introThird.png"),
  successRegister: require("../assets/images/successRegister.png"),
  iconBack: require("../assets/icon/back.png"),
  iconBuilding: require("../assets/images/Group 1582@3x.png"),
  avatar: require("../assets/images/user.jpg"),
  iconAt: require("../assets/images/Group 1583@3x.png"),
  trip: require("../assets/images/trip.png"),
  expense: require("../assets/images/expense.png"),
  iconBooking: require("../assets/images/booking.png"),
  report: require("../assets/images/report.png"),
  bubble: require("../assets/images/Group 1579@3x.png"),
  title: require("../assets/images/Group 1570@3x.png"),
  iconPhone: require("../assets/images/Group 1580.png"),
  laugh: require("../assets/images/laugh@3x.png"),
  iconLock: require("../assets/images/Group 1581@3x.png"),
  history: require("../assets/images/Layer_1_65_@3x.png"),
  newTrip: require("../assets/images/Group 1499@3x.png"),
  indonesia: require("../assets/images/indonesia.png"),
  iconHome: require("../assets/images/home@3x.png"),
  iconAnalytics: require("../assets/images/analytics@3x.png"),
  plus: require("../assets/images/plus.png"),
  hotel: require("../assets/images/bell@3x.png"),
  flight: require("../assets/images/air-freight@3x.png"),
  newBookHotel: require("../assets/images/Group 1505@3x.png"),
  star: require("../assets/images/star.png"),
  stargrey: require("../assets/images/stargrey.png"),
  newExpense: require("../assets/images/Group 1512@3x.png"),
  check: require("../assets/images/Path 1849@3x.png"),
  cross: require("../assets/images/Group 1279@3x.png"),
  pencil: require("../assets/images/ic_create_24px@3x.png"),
  iconFilter: require("../assets/images/Group 1504@3x.png"),
  bgHeader: require("../assets/images/Group 1.png"),
  upcoming: require("../assets/images/Group 1311@3x.png"),
  ongoing: require("../assets/images/Group 1310@3x.png"),
  approval: require("../assets/images/Group 680@3x.png"),
  assignment: require("../assets/images/Group 681@3x.png"),
  generalExpnense: require("../assets/images/building@3x.png"),
  newAssignment: require("../assets/images/Group 1500@3x.png"),
  bookingFlight: require("../assets/images/Group 1509@3x.png"),
  selectedDate: require("../assets/images/Path 2661.png"),
  notesBg: require("../assets/images/Repeat Grid 1@3x.png"),
  newReport: require("../assets/images/Group 1515@3x.png"),
  approveModal: require("../assets/images/Group 2058@3x.png"),
  rejectModal: require("../assets/images/Group 2058orange@3x.png"),
  backButton: require("../assets/images/Path 309@3x.png"),
  trashBin: require("../assets/images/bin@3x.png"),
  checkList: require("../assets/images/checklist.png"),
  share: require("../assets/images/share@3x.png"),
  download: require("../assets/images/Group 1300@3x.png"),
  reviseAlert: require("../assets/images/reviseAlert.png"),
  pastDateNotif: require("../assets/images/pastDateNotif.png"),
  resolvePerson: require("../assets/images/resolvePerson.png"),
  closingTrip: require("../assets/images/Group 2248.png"),
  upComingTask: require("../assets/images/upcoming@3x.png"),
  assignTask: require("../assets/images/assignTasks@3x.png"),
  avatars: require("../assets/images/avatar@3x.png"),
  coverTask: require("../assets/images/taskCover@3x.png"),
  coverCash: require("../assets/images/cashCover@3x.png"),
  coverActivity: require("../assets/images/activityCover@3x.png"),
  cashRequest: require("../assets/images/cashRequest@3x.png"),
  cashBillable: require("../assets/images/cashBillable@3x.png"),
  cashApprove: require("../assets/images/cashApprove@3x.png"),
  cashCollect: require("../assets/images/cashCollect@3x.png"),
  cashDisburse: require("../assets/images/cashDisburse@3x.png"),
  clipAttchment: require("../assets/images/clip@3x.png"),
  assignTaskGrey: require("../assets/images/assignTaskGrey@3x.png"),
  feedback: require("../assets/images/feedback.png"),
  ask: require("../assets/images/ask.png"),
  moderationNotifIcon: require("../assets/images/moderationIcon.png"),
  phone: require("../assets/images/phone.png"),
  picAttach: require("../assets/images/picAttch.png"),
  document: require("../assets/images/document2.png"),
  mixed: require("../assets/images/mixed.png"),
  warn: require("../assets/images/warn.png"),
  actionPercent: require("../assets/images/percentBtn.png"),
  kasbonCard: require("../assets/images/kasbonCard.png"),
  kasbonLogo: require("../assets/images/kasbonLogo.png")
};

export default images;
