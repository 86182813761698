import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  pickerItem: {
    flex: 1,
    textAlign: "center",
    alignContent: "center",
    flexWrap: "wrap"
  }
});

export default styles;
