import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";

const SearchListPermissionPage = (slug: string) => {
  const authState = useSelector((state: Reducers) => state.auth);
  const res = authState.myPermission.filter((el: any) => el.includes(slug));
  return res;
};

export default SearchListPermissionPage;
