import React from "react";
import { Text, View } from "react-native";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./styles";
import { Touchable } from "../../../../components";
import Allowance from "./pages/Allowance";
import CashAdvance from "./pages/CashAdvance";

const Expense = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;

  const listPages = [
    {
      label: "Allowance",
      component: <Allowance />,
      exact: true,
      pathname: match.path
    },
    {
      label: "Cash Advance",
      component: <CashAdvance />,
      exact: false,
      pathname: `${match.path}/cashadvance`
    }
  ];

  return (
    <>
      <View style={styles.containerNav}>
        <View style={styles.wrapNav}>
          {listPages.map((item, index) => (
            <View key={index} style={{ flex: 1 }}>
              <Touchable onPress={() => history.push(item.pathname)}>
                <View
                  style={[
                    styles.buttonTab,
                    item.pathname === pathname && styles.activeButton
                  ]}
                >
                  <Text style={styles.labelTab}>{item.label}</Text>
                </View>
              </Touchable>
            </View>
          ))}
        </View>
      </View>
      <View style={{ paddingVertical: 16 }}>
        {listPages.map((item, index) => (
          <Route path={item.pathname} exact={item.exact} key={index}>
            {item.component}
          </Route>
        ))}
      </View>
    </>
  );
};

export default Expense;
