import React from "react";
import { Image, View } from "react-native";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { RoundBullet, Touchable } from "../../components";
import { Container } from "./components";
import { ICONS } from "../../configs";
import styles from "./styles";
import { documentTitle } from "../../utils";

// screens
import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import Verification from "./Verification";
import Company from "./Company";
import Success from "./Success";

const Register = () => {
  documentTitle("djurnee - Register");
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;
  const listPages = [
    {
      pathname: match.path,
      component: <Email next={`${match.path}/phone-number`} />
    },
    {
      pathname: `${match.path}/phone-number`,
      component: <PhoneNumber next={`${match.path}/verification`} />
    },
    {
      pathname: `${match.path}/verification`,
      component: <Verification next={`${match.path}/company`} />
    },
    {
      pathname: `${match.path}/company`,
      component: <Company next={`${match.path}/success`} />
    },
    {
      pathname: `${match.path}/success`,
      component: <Success />
    }
  ];

  return (
    <Switch>
      <Container>
        <View style={{ paddingVertical: 50 }}>
          <View style={styles.wrapButtonBack}>
            <Touchable onPress={() => history.goBack()}>
              <View style={styles.buttonBack}>
                <Image
                  source={ICONS.iconBack}
                  style={styles.iconBack}
                  resizeMode="contain"
                />
              </View>
            </Touchable>
          </View>
          <RoundBullet
            active={listPages.findIndex(e => e.pathname === pathname)}
            total={listPages.length}
          />
        </View>
        {listPages.map((item, index) => (
          <Route path={item.pathname} exact key={index}>
            {item.component}
          </Route>
        ))}
      </Container>
    </Switch>
  );
};

export default Register;
