import {
  POST_NEW_CASH_EXPENSE_ERROR,
  POST_NEW_CASH_EXPENSE_PENDING,
  POST_NEW_CASH_EXPENSE_SUCCESS
} from "../actions";
import { Action, NewCashExpense } from "../types";

const initialState: NewCashExpense = {
  isLoadingPostNewCashExpense: false,
  dataCashExpense: []
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case POST_NEW_CASH_EXPENSE_PENDING:
      return {
        ...state,
        isLoadingPostNewCashExpense: true,
        dataCashExpense: []
      };
    case POST_NEW_CASH_EXPENSE_SUCCESS:
      return {
        ...state,
        isLoadingPostNewCashExpense: false,
        dataCashExpense: payload.data
      };
    case POST_NEW_CASH_EXPENSE_ERROR:
      return {
        ...state,
        isLoadingPostNewCashExpense: false
      };
    default:
      return state;
  }
};
