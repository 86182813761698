import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_POLICIES_PENDING = "GET_POLICIES_PENDING";
export const GET_POLICIES_SUCCESS = "GET_POLICIES_SUCCESS";
export const GET_POLICIES_ERROR = "GET_POLICIES_ERROR";

export const getPolicies = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POLICIES_PENDING });

    const res = await API.getPolicies(auth.token);
    dispatch({
      type: GET_POLICIES_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPolicies())));
      } else {
        dispatch({
          type: GET_POLICIES_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_POLICIES_ERROR });
  }
};
