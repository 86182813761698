import React from "react";
import { Image, Text, View } from "react-native";

import styles from "./styles";
import Card from "../../../components/Card";
import { IMAGES } from "../../../configs";
import { documentTitle } from "../../../utils";

const flightData = [
  {
    airlines: "Garuda Indonesia",
    flight: [
      {
        name: "Flight 1",
        detail: "Soekarno Hatta - Ngurah Rai",
        day: "Mon ",
        date: "28 Dec 2018 ",
        time: "14:00",
        class: "Economy 43C"
      },
      {
        name: "Flight 2",
        detail: "Ngurah Rai - Soekarno Hatta",
        day: "Mon ",
        date: "30 Dec 2018 ",
        time: "14:00",
        class: "Economy 43C"
      }
    ],
    facility: "Luggage 25Kg, Snack, Food",
    total: "IDR 9.900.000"
  }
];

const hotelData = [
  {
    name: "Sheraton Hotel Jakarta Selatan",
    rating: 4,
    city: "Jakarta Selatan",
    region: "Kemang",
    checkin: {
      day: "Mon ",
      date: "28 Dec 2018 ",
      time: "14:00"
    },
    checkout: {
      day: "Wed ",
      date: "30 Dec 2018 ",
      time: "18:00"
    },
    facility: "Breakfast, Dinner, Meeting Room, Launch, Swimming Pool, Gym",
    budget: "IDR 5.000.000"
  }
];

const star = (total: number) => {
  let img = <></>;
  for (let i = 0; i < total; i += 1) {
    img = (
      <>
        {img}
        <Image style={styles.star} source={IMAGES.star} />
      </>
    );
  }
  const sisa = 5 - total;
  for (let j = 0; j < sisa; j += 1) {
    img = (
      <>
        {img}
        <Image style={styles.star} source={IMAGES.stargrey} />
      </>
    );
  }
  return <View style={{ flexDirection: "row" }}>{img}</View>;
};

const Booked = () => {
  documentTitle("Trip Detail - Booking");
  return (
    <View style={styles.bookingDetailContainer}>
      <View style={styles.bookingpDetailContent}>
        <Card cardStyle={[styles.cardDescription, { marginBottom: 15 }]}>
          <View>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>IF/XII/3/D</Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{ fontSize: 14, color: "#666666", fontWeight: "bold" }}
                >
                  Elnusa Webite & IOS
                </Text>
                <Text style={{ fontSize: 11, color: "#999999", marginTop: 4 }}>
                  Progress Meeting
                </Text>
              </View>
            </View>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Team</Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{ fontSize: 11, color: "#666666", fontWeight: "bold" }}
                >
                  Art Vandelay, ulululu
                </Text>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Date</Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{ fontSize: 11, color: "#666666", fontWeight: "bold" }}
                >
                  16 Dec 2019 - 20 Dec 2019
                </Text>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Method</Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{ fontSize: 11, color: "#666666", fontWeight: "bold" }}
                >
                  Cash Advance
                </Text>
              </View>
            </View>
          </View>
        </Card>

        {hotelData.map((item, index) => {
          return (
            <Card cardStyle={styles.cardDescription} key={index}>
              <View>
                <View style={[styles.desciption, { marginBottom: 2 }]}>
                  <Text style={styles.titleDescription}>Hotel</Text>
                  <View style={styles.detailDescription}>
                    {star(item.rating)}
                  </View>
                </View>
                <Text style={styles.titleHotel}>{item.name}</Text>

                <View style={[styles.desciption, styles.descriptionCity]}>
                  <Text style={styles.titleDescription}>City</Text>
                  <Text style={styles.detailDescription}>{item.city}</Text>
                </View>
                <View style={[styles.desciption, styles.descriptionCity]}>
                  <Text style={[styles.titleDescription]}>Region</Text>
                  <Text style={styles.detailDescription}>{item.region}</Text>
                </View>
                <View style={{ marginTop: 10 }}>
                  <View style={[styles.desciption, styles.descriptionCity]}>
                    <Text style={styles.titleDescription}>Check In</Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={styles.descDate}>{item.checkin.day}</Text>
                      <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                        {item.checkin.date}
                      </Text>
                      <Text style={styles.descDate}>{item.checkin.time}</Text>
                    </View>
                  </View>
                  <View style={[styles.desciption, styles.descriptionCity]}>
                    <Text style={[styles.titleDescription]}>Check Out</Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={styles.descDate}>{item.checkout.day}</Text>
                      <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                        {item.checkout.date}
                      </Text>
                      <Text style={styles.descDate}>{item.checkout.time}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Facility</Text>
                  <Text style={styles.detailDescription}>{item.facility}</Text>
                </View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Budget</Text>
                  <Text
                    style={[styles.detailDescription, { fontWeight: "bold" }]}
                  >
                    {item.budget}
                  </Text>
                </View>
              </View>
            </Card>
          );
        })}

        {flightData.map((item, index) => {
          return (
            <Card cardStyle={styles.cardDescription} key={index}>
              <View>
                <View style={[styles.desciption, { marginBottom: 2 }]}>
                  <Text style={styles.titleDescription}>Airlines</Text>
                </View>
                <Text style={styles.titleFlight}>{item.airlines}</Text>
                {item.flight.map((el, indexEl) => {
                  return (
                    <View style={styles.desciption} key={indexEl}>
                      <Text style={styles.titleDescription}>{el.name}</Text>
                      <View style={styles.detailDescription}>
                        <Text style={styles.detailDescriptionText}>
                          {el.detail}
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={styles.descDate}>{el.date}</Text>
                          <Text
                            style={[styles.descDate, { fontWeight: "bold" }]}
                          >
                            {item.flight[0].date}
                          </Text>
                          <Text style={styles.descDate}>{el.time}</Text>
                        </View>
                        <Text style={styles.detailDescriptionText}>
                          {el.class}
                        </Text>
                      </View>
                    </View>
                  );
                })}

                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Facility</Text>
                  <Text style={styles.detailDescription}>{item.facility}</Text>
                </View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Total Cost</Text>
                  <Text
                    style={[styles.detailDescription, { fontWeight: "bold" }]}
                  >
                    {item.total}
                  </Text>
                </View>
              </View>
            </Card>
          );
        })}
      </View>
    </View>
  );
};

export default Booked;
