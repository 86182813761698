import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";
import { Container } from "../components";

interface Props {
  privateMode?: boolean;
  withContainer?: boolean;
}

const CustomRoute = ({
  privateMode,
  children,
  withContainer,
  ...props
}: Partial<RouteProps & Props>) => {
  const auth = useSelector((state: Reducers) => state.auth);
  const isLogin = auth.token !== "";

  const renderPage = () => {
    if (privateMode) {
      return isLogin ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      );
    }

    return !isLogin ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    );
  };

  return withContainer ? (
    <Container>
      <Route {...props}>{renderPage}</Route>
    </Container>
  ) : (
    <Route {...props}>{renderPage}</Route>
  );
};

CustomRoute.defaultProps = {
  privateMode: true,
  withContainer: true
};

export default CustomRoute;
