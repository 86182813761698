import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import moment from "moment";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./styles";
import "./style.css";
import { Button, Card, Select } from "../..";
import { convertDate } from "../../../utils";
import { Reducers } from "../../../redux/types";
import {
  getDetailTask,
  getDetailTaskRemarks,
  putTaskRecurring,
  setModalRecurringTask
} from "../../../redux/actions";

interface Props {
  onCancel?: () => void;
}

const UpdateRecurringTask = ({ onCancel }: Props) => {
  const dispatch = useDispatch();
  const taskState = useSelector((state: Reducers) => state.task);
  const taskUuid = taskState.detailTaskData.id;
  const [form, setForm] = useState({
    // invoice_id: taskState.detailTaskData.id,
    assigned: 0,
    client_timezone: "Asia/Jakarta",
    finished_at: "",
    recurring: false,
    recurring_period: "",
    recurring_month: "",
    recurring_day: "",
    recurring_weekday: "",
    recurring_time: "",
    recurring_ended_at: "",
    recurring_history: null
  });

  const [month, setMonth]: any = useState([]);
  const [day, setDay]: any = useState([]);
  const [weekDay, setWeekDay]: any = useState([]);
  const [dataPeriode] = useState([
    { value: "4", label: "Yearly" },
    { value: "3", label: "Monthly" },
    { value: "2", label: "Weekly" },
    { value: "1", label: "Daily" }
  ]);

  const [endPeriodSwitch, setEndperiodSwitch] = useState(false);

  const generateMonth = () => {
    const months = moment.months();
    let index = 1;
    let result: any[] = [{ value: "", label: "Select Month" }];
    months.map(el => {
      result = [...result, { value: index, label: el }];
      index += 1;
    });
    setMonth((arr: any) => [...arr, ...result]);
  };

  const generateDay = () => {
    let index = 1;
    let result: any[] = [{ value: "", label: "Select Day" }];
    while (index <= 28) {
      result = [...result, { value: index, label: index }];
      index += 1;
    }
    setDay((arr: any) => [...arr, ...result]);
  };

  const generateWeekDay = () => {
    const weeks = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    let index = 1;
    let result: any[] = [{ value: "", label: "Select Weekday" }];
    weeks.map(el => {
      result = [...result, { value: index, label: el }];
      index += 1;
    });
    setWeekDay((arr: any) => [...arr, ...result]);
  };

  const getRecurringEnd = () => {
    if (
      taskState.detailTaskData &&
      taskState.detailTaskData.recurring_ended_at
    ) {
      return setEndperiodSwitch(true);
    }
    return setEndperiodSwitch(false);
  };

  useEffect(() => {
    generateMonth();
    generateDay();
    generateWeekDay();
    setForm({
      ...form,
      recurring: taskState.detailTaskData && taskState.detailTaskData.recurring,
      recurring_time:
        taskState.detailTaskData && taskState.detailTaskData.recurring_time
          ? taskState.detailTaskData.recurring_time
          : "",
      recurring_ended_at:
        taskState.detailTaskData && taskState.detailTaskData.recurring_ended_at
          ? moment(taskState.detailTaskData.recurring_ended_at).format(
              "YYYY-MM-DD"
            )
          : ""
    });
    getRecurringEnd();
  }, []);

  const _onSave = () => {
    let message = "";
    let success = false;
    if (form.recurring && !endPeriodSwitch) {
      form.recurring_ended_at = "";
    }

    if (
      form.recurring &&
      form.recurring_period === "1" &&
      !form.recurring_time
    ) {
      message = "Recurring time cannot be empty";
    } else if (
      form.recurring &&
      form.recurring_period === "2" &&
      !form.recurring_weekday
    ) {
      message = "Recurring weekday cannot be empty";
    } else if (
      form.recurring &&
      ["3", "4"].includes(form.recurring_period) &&
      !form.recurring_day
    ) {
      message = "Recurring day cannot be empty";
    } else if (
      form.recurring &&
      form.recurring_period === "4" &&
      !form.recurring_month
    ) {
      message = "Recurring month cannot be empty";
    } else if (
      form.recurring &&
      ["1", "2", "3", "4"].includes(form.recurring_period) &&
      !form.recurring_time
    ) {
      message = "Recurring time cannot be empty";
    } else {
      message = "Data Stored";
      success = true;
    }

    if (success && message) {
      dispatch(
        putTaskRecurring(
          taskUuid,
          form,
          (e: { success: boolean; message: string }) => {
            if (e.success === true) {
              toast.success(`${e.message.toUpperCase()}`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                transition: Slide,
                className: "assign-toast-reccur",
                closeButton: false,
                draggable: false
              });
              setTimeout(() => {
                dispatch(setModalRecurringTask(false));
                dispatch(getDetailTask(taskUuid));
                dispatch(getDetailTaskRemarks(taskUuid));
              }, 500);
            } else if (e.success === false) {
              toast.error(`${e.message}`, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                transition: Slide,
                className: "assign-toast-reccur",
                closeButton: false,
                draggable: false
              });
            }
          }
        )
      );
    } else {
      toast.error(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast-reccur",
        closeButton: false,
        draggable: false
      });
    }
  };

  return (
    <View style={styles.container}>
      <ToastContainer limit={1} />
      <View style={{ flex: 17 }}>
        <Card
          containerStyle={{ flex: 1 }}
          cardStyle={{ flex: 1, borderRadius: 12 }}
        >
          <View style={styles.wrapContent}>
            <View style={styles.wrapSwitch}>
              <Switch
                onChange={() =>
                  setForm({ ...form, recurring: !form.recurring })
                }
                checked={form.recurring}
                onColor="#50b1b1"
                onHandleColor="#fffff7"
                handleDiameter={19}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                height={19}
                width={34}
              />
              <Text
                style={[
                  styles.poppins,
                  styles.recurringLabel,
                  !form.recurring && styles.offRecuccing
                ]}
              >
                Recurring
              </Text>
            </View>
            <div className="wrap-card-recuring">
              {form.recurring && (
                <Card cardStyle={styles.recurringCard}>
                  <Select
                    listValue={dataPeriode}
                    defaultValue={() =>
                      setForm({
                        ...form,
                        recurring_period:
                          taskState.detailTaskData &&
                          taskState.detailTaskData.recurring_period
                            ? taskState.detailTaskData.recurring_period.toString()
                            : "4"
                      })
                    }
                    onChange={e =>
                      setForm({
                        ...form,
                        recurring_period: e.target.value
                      })
                    }
                    value={form.recurring_period}
                  />
                </Card>
              )}
              {form.recurring && form.recurring_period === "4" && (
                <View>
                  <Text style={[styles.poppins, styles.nextRecurLabel]}>
                    Month
                  </Text>
                  <Card cardStyle={styles.recurringCard}>
                    <Select
                      style={
                        form.recurring_month === ""
                          ? { opacity: 0.3 }
                          : { opacity: 1 }
                      }
                      listValue={month}
                      defaultValue={() =>
                        setForm({
                          ...form,
                          recurring_month:
                            taskState.detailTaskData &&
                            taskState.detailTaskData.recurring_month
                              ? taskState.detailTaskData.recurring_month.toString()
                              : ""
                        })
                      }
                      onChange={e =>
                        setForm({
                          ...form,
                          recurring_month: e.target.value
                        })
                      }
                      value={form.recurring_month}
                    />
                  </Card>
                </View>
              )}
              {form.recurring &&
                (form.recurring_period === "4" ||
                  form.recurring_period === "3") && (
                  <View>
                    <Text style={[styles.poppins, styles.nextRecurLabel]}>
                      Day
                    </Text>
                    <Card cardStyle={styles.recurringCard}>
                      <Select
                        style={
                          form.recurring_day === ""
                            ? { opacity: 0.3 }
                            : { opacity: 1 }
                        }
                        listValue={day}
                        defaultValue={() =>
                          setForm({
                            ...form,
                            recurring_day:
                              taskState.detailTaskData &&
                              taskState.detailTaskData.recurring_day
                                ? taskState.detailTaskData.recurring_day.toString()
                                : ""
                          })
                        }
                        onChange={e =>
                          setForm({
                            ...form,
                            recurring_day: e.target.value
                          })
                        }
                        value={form.recurring_day}
                      />
                    </Card>
                  </View>
                )}
              {form.recurring && form.recurring_period === "2" && (
                <View>
                  <Text style={[styles.poppins, styles.nextRecurLabel]}>
                    Weekday
                  </Text>
                  <Card cardStyle={styles.recurringCard}>
                    <Select
                      style={
                        form.recurring_weekday === ""
                          ? { opacity: 0.3 }
                          : { opacity: 1 }
                      }
                      listValue={weekDay}
                      defaultValue={() =>
                        setForm({
                          ...form,
                          recurring_weekday:
                            taskState.detailTaskData &&
                            taskState.detailTaskData.recurring_weekday
                              ? taskState.detailTaskData.recurring_weekday.toString()
                              : ""
                        })
                      }
                      onChange={e =>
                        setForm({
                          ...form,
                          recurring_weekday: e.target.value
                        })
                      }
                      value={form.recurring_weekday}
                    />
                  </Card>
                </View>
              )}
              {form.recurring &&
                ["4", "3", "2", "1"].includes(form.recurring_period) && (
                  <View>
                    <Text style={[styles.poppins, styles.nextRecurLabel]}>
                      Time
                    </Text>
                    <Card cardStyle={styles.recurringCard}>
                      <input
                        type="time"
                        style={{
                          borderWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#707070"
                        }}
                        onChange={e =>
                          setForm({ ...form, recurring_time: e.target.value })
                        }
                        value={form.recurring_time}
                      />
                    </Card>
                  </View>
                )}
            </div>
            <View style={{ alignItems: "center", marginBottom: 12 }}>
              <Text style={[styles.poppins, styles.nextRecurLabel]}>
                Next Reccurance
              </Text>
              {form.recurring &&
              taskState.detailTaskData &&
              taskState.detailTaskData.recurring_at ? (
                <Text style={styles.onRecurring}>
                  <Text style={{ fontWeight: "normal" }}>
                    {`${convertDate(
                      taskState.detailTaskData &&
                        taskState.detailTaskData.recurring_at,
                      "ddd"
                    )} `}
                  </Text>
                  {convertDate(
                    taskState.detailTaskData &&
                      taskState.detailTaskData.recurring_at,
                    "DD MMM YYYY"
                  )}
                  {` ${moment(
                    taskState.detailTaskData &&
                      taskState.detailTaskData.recurring_time,
                    "HH:mm:ss"
                  )
                    .add(7, "hours")
                    .format("HH:mm")}`}
                </Text>
              ) : (
                <Text style={[styles.poppins, styles.bold]}>-</Text>
              )}
            </View>
            <View>
              <Text style={[styles.poppins, styles.nextRecurLabel]}>
                History
              </Text>
              {form.recurring &&
              taskState.detailTaskData &&
              taskState.detailTaskData.recurring_history > 0 ? (
                taskState.detailTaskData.recurring_history.map(
                  (item: any, index: number) => (
                    <Text
                      key={index}
                      style={[
                        styles.onRecurring,
                        { marginBottom: 2, color: "#707070" }
                      ]}
                    >
                      <Text style={{ fontWeight: "normal" }}>
                        {`${convertDate(item, "ddd")} `}
                      </Text>
                      {convertDate(item, "DD MMM YYYY")}
                    </Text>
                  )
                )
              ) : (
                <Text style={[styles.poppins, styles.bold2]}>-</Text>
              )}
            </View>
            <View style={styles.wrapSwitch}>
              <Switch
                onChange={() => setEndperiodSwitch(!endPeriodSwitch)}
                checked={endPeriodSwitch}
                onColor="#50b1b1"
                onHandleColor="#fffff7"
                handleDiameter={19}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                height={19}
                width={34}
              />
              <Text
                style={[
                  styles.poppins,
                  styles.recurringLabel,
                  !form.recurring && styles.offRecuccing
                ]}
              >
                Recurring End
              </Text>
            </View>
            {endPeriodSwitch && (
              <View>
                <Text style={[styles.poppins, styles.nextRecurLabel]}>
                  Repeat task end
                </Text>
                <Card cardStyle={styles.recurringCard}>
                  <input
                    onChange={(e: any) => {
                      setForm({ ...form, recurring_ended_at: e.target.value });
                    }}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    type="date"
                    style={{ color: "#666666", fontWeight: "bold" }}
                    className="container-content-input"
                    max=""
                    value={form.recurring_ended_at}
                  />
                </Card>
              </View>
            )}
          </View>
        </Card>
        <View
          style={{
            position: "absolute",
            alignItems: "center",
            bottom: 24,
            alignSelf: "center"
          }}
        >
          <Button
            title="SAVE"
            onPress={_onSave}
            isLoading={taskState.isLoadingPutTask}
          />
        </View>
      </View>
      <View style={styles.wrapCloseButton}>
        <TouchableOpacity
          style={{
            width: 50,
            alignItems: "center"
          }}
          onPress={onCancel}
          activeOpacity={0.8}
        >
          <Text style={[styles.poppins, styles.bold]}>CLOSE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default UpdateRecurringTask;
