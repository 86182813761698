/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { ActivityIndicator, FlatList, Image, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";

import {
  getConfigUploadFile,
  getPopulateCategory,
  getPopulateProject,
  getRegion
} from "../../../../redux/actions";
import styles from "./styles";
import "./style.css";
import {
  Datepicker,
  FileUpload,
  Header,
  Input,
  Select,
  Touchable,
  Upload,
  UserPicker
} from "../../../../components";
import Card from "../../../NewTrip/components/Card";
import { ICONS, IMAGES } from "../../../../configs";
import { Reducers } from "../../../../redux/types";
import { validator } from "../../../../utils";

interface Employee {
  id: string;
  name: string;
  photo: string | null;
  title: string;
}

const NewAssignment = () => {
  const rules = {
    title: {
      label: "Title",
      rule: {
        required: true
      }
    },
    category_id: {
      label: "Category",
      rule: {
        required: true
      }
    },
    destination_location_id: {
      label: "Arrival",
      rule: {
        required: true
      }
    },
    finished_at_d: {
      label: "Date Arrival",
      rule: {
        required: true
      }
    },
    finished_at_t: {
      label: "Time Arrival",
      rule: {
        required: true
      }
    },
    origin_location_id: {
      label: "Departure",
      rule: {
        required: true
      }
    },
    project_id: {
      label: "Project",
      rule: {
        required: true
      }
    },
    started_at_d: {
      label: "Date Departure",
      rule: {
        required: true
      }
    },
    started_at_t: {
      label: "Time Departure",
      rule: {
        required: true
      }
    }
  };
  const [datepicker, setDatepicker] = useState([
    {
      date: "",
      dateTo: "",
      time: "",
      timeTo: "",
      departure: {
        location: "",
        id: ""
      },
      arrival: {
        location: "",
        id: ""
      }
    }
  ]);
  const typeForm: any = {
    category_id: "",
    description: "",
    destination_location_id: datepicker[0].arrival.id,
    finished_at_d: datepicker[0].dateTo,
    finished_at_t: datepicker[0].timeTo,
    origin_location_id: datepicker[0].departure.id,
    participants: "",
    project_id: "",
    self_booking: false,
    started_at_d: datepicker[0].date,
    started_at_t: datepicker[0].date,
    title: "",
    participants_data: [],
    file: null,
    client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
  const [form, setForm] = useState(typeForm);
  const dispatch = useDispatch();
  const [showUserPicker, setShowUserPicker] = useState(false);
  const trips = [
    {
      title: "Trip 1",
      cityFrom: "Jakarta",
      dateFrom: "20 January",
      cityTo: "Denpasar",
      dateTo: "21 January"
    }
  ];
  const { newTripState, regionState, auth } = useSelector(
    (state: Reducers) => ({
      newTripState: state.newTrip,
      regionState: state.region,
      auth: state.auth
    }),
    shallowEqual
  );

  const regionPopulate = regionState.region.map((e: any) => {
    return { value: e.id, label: e.name };
  });
  const populateCategory = newTripState.listPopulateCategory.map((e: any) => {
    return { value: e.id, label: e.title };
  });
  const populateProject = newTripState.listPopulateProject.map((e: any) => {
    return { value: e.id, label: e.title };
  });
  const getMonth = (m: number) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[m];
  };
  const dateFormated = (date: string) => {
    const dt = new Date(date);
    return `${dt.getDate()} ${getMonth(dt.getMonth())} `;
  };

  const typeEmployee: Employee[] = [];
  const [employees, setEmployees] = useState(typeEmployee);
  const [oneTimeEffect, setOneTimeEffect] = useState(true);
  const [countSubmit] = useState(0);
  const [heightInputSubject, setHeightInputSubject] = useState(0);
  const [heightInputPurpose, setHeightInputPurpose] = useState(0);
  const typeErrorForm: any = {};
  const [errorForm, setErrorForm] = useState(typeErrorForm);
  const [extension, setExtensions] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [showDatepicker, setShowDatepicker] = useState([false, false, false]);

  const validation = async (
    formValue: any,
    cb: (error: any, success: boolean) => void
  ) => {
    const _form: any = {};
    Object.keys(formValue).forEach((key: string) => {
      if (
        (key === "started_at_t" || key === "finished_at_t") &&
        formValue[key] !== ""
      ) {
        const value = formValue[key].split(" ")[1];
        _form[key] = value;
      } else {
        _form[key] = formValue[key];
      }
    });
    const validate = await validator.make(_form, rules);
    if (validate.fails()) {
      setErrorForm(validate.getMessages());
      cb(validate.getMessages(), false);
    } else {
      cb({}, true);
    }
  };
  const UploadDocument = async (e: any) => {
    const file = e.target.files[0];
    const size = auth.myConfig.max_size / auth.myConfig.max_size;

    if (file.size <= auth.myConfig.max_size) {
      setImage(file);
      const extensions = e.target.files[0].name.split(".").pop();
      setExtensions(extensions);
      setName(e.target.files[0].name);
      setForm({ ...form, file });
    } else {
      alert(`file to big! maximum size allowed ${size}MB.`);
    }
  };

  const deleteFile = async () => {
    await setImage(null);
    await setName(null);
  };

  const renderFile = () => {
    if (extension === "png" || extension === "jpeg" || extension === "jpg") {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    if (
      extension === "pdf" ||
      extension === "docx" ||
      extension === "xls" ||
      extension === "xlsx"
    ) {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    return null;
  };

  const renderHeaderDatepicker = (
    nav: number,
    setNav: (number: number) => void
  ) => {
    const totalTrip = 1;
    return (
      <View style={[styles.datePickerNavWrap]}>
        {nav !== totalTrip - 1 ? (
          <Touchable
            onPress={() => {
              setNav(nav - 1);
            }}
          >
            <View style={{ justifyContent: "center" }}>
              <Image
                source={ICONS.iconBack}
                resizeMode="contain"
                style={{
                  width: 30,
                  height: 30,
                  tintColor: "#ffffff"
                }}
              />
            </View>
          </Touchable>
        ) : (
          <View style={{ width: 30 }} />
        )}
        <View style={[styles.datePickerNav]}>
          <Text style={[{ color: "#fff", fontWeight: "bold" }]}>
            {`Trip ${nav + 1}`}
          </Text>
        </View>
        <View style={{ justifyContent: "center" }}>
          {nav !== totalTrip - 1 ? (
            <Touchable
              onPress={() => {
                setNav(nav + 1);
              }}
            >
              <View style={{ justifyContent: "center", height: "100%" }}>
                <Image
                  source={ICONS.iconNext}
                  resizeMode="contain"
                  style={{
                    width: 30,
                    height: 30,
                    tintColor: "#ffffff"
                  }}
                />
              </View>
            </Touchable>
          ) : (
            <Touchable>
              <View style={{ justifyContent: "center", height: "100%" }}>
                {/* <Image
                  source={IMAGES.plus}
                  resizeMode="contain"
                  style={{
                    width: 30,
                    height: 30,
                    marginRight: 10
                  }}
                /> */}

                <View
                  style={{
                    width: 30,
                    height: 30
                  }}
                />
              </View>
            </Touchable>
          )}
        </View>
      </View>
    );
  };

  useEffect(() => {
    if (oneTimeEffect) {
      dispatch(getPopulateCategory());
      dispatch(getPopulateProject());
      dispatch(getRegion());
      dispatch(getConfigUploadFile());
      setOneTimeEffect(false);
    }
  }, []);
  return (
    <View>
      <Header title="New Assignment" />
      <View style={styles.newTripContent}>
        <>
          {showUserPicker ? (
            <UserPicker
              value={employees}
              onBackdropPress={() => setShowUserPicker(false)}
              onChange={employee => setEmployees(employee)}
            />
          ) : (
            <></>
          )}
          {employees.length > 0 ? (
            <FlatList
              data={employees}
              keyExtractor={(item: { id: string }) => item.id}
              horizontal
              showsHorizontalScrollIndicator={false}
              renderItem={({ item, index }) => (
                <>
                  <View style={{ flexDirection: "row", marginBottom: 18 }}>
                    <View style={styles.imgContainer}>
                      <Image
                        source={
                          item.photo === null ? IMAGES.avatar : item.photo
                        }
                        style={styles.newTripFoto}
                      />
                      <Text
                        style={{
                          fontSize: 11,
                          color: "#666666",
                          fontWeight: "bold"
                        }}
                      >
                        {item.name}
                      </Text>
                    </View>
                  </View>
                  {employees.length - 1 === index ? (
                    <View style={{ marginHorizontal: 10 }}>
                      <Touchable onPress={() => setShowUserPicker(true)}>
                        <>
                          <Image
                            source={IMAGES.plus}
                            style={{ width: 60, height: 60 }}
                          />
                          <View style={{ alignItems: "center" }}>
                            <Text style={styles.buttonAssign}>Assign</Text>
                          </View>
                        </>
                      </Touchable>
                    </View>
                  ) : (
                    <></>
                  )}
                </>
              )}
            />
          ) : (
            <View style={{ marginHorizontal: 10, width: 60 }}>
              <Touchable onPress={() => setShowUserPicker(true)}>
                <>
                  <Image
                    source={IMAGES.plus}
                    style={{ width: 60, height: 60 }}
                  />
                  <View style={{ alignItems: "center" }}>
                    <Text style={styles.buttonAssign}>Assign</Text>
                  </View>
                </>
              </Touchable>
            </View>
          )}
          <View style={styles.inputContainer}>
            <Text style={styles.labelInput}>Subject</Text>
            <Input
              value={form.title}
              placeholder="Enter your subject"
              inputStyle={[
                styles.inputStyle,
                heightInputSubject > 0 &&
                  form.title !== "" && { height: heightInputSubject }
              ]}
              onChangeText={text => {
                if (countSubmit > 0) {
                  validation({ ...form, title: text }, (error: any) => {
                    setErrorForm(error);
                  });
                }
                setForm({ ...form, title: text });
              }}
              multiline
              onContentSizeChange={({ nativeEvent }) =>
                setHeightInputSubject(nativeEvent.contentSize.height)
              }
            />
            {typeof errorForm.title !== "undefined" ? (
              <View>
                <Text style={styles.errorAlert}>{errorForm.title}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          {trips.map((trip: any, indexTrip: number) => {
            return (
              <View
                key={indexTrip.toString()}
                style={{ zIndex: trip.length - indexTrip }}
              >
                <Datepicker
                  onChange={result => {
                    if (countSubmit > 0) {
                      validation(
                        {
                          ...form,
                          origin_location_id: result[0].departure.id,
                          destination_location_id: result[0].arrival.id,
                          finished_at_d: result[0].dateTo,
                          finished_at_t: `${result[0].dateTo} ${result[0].timeTo}`,
                          started_at_d: result[0].date,
                          started_at_t: `${result[0].date} ${result[0].time}`
                        },
                        (error: any) => {
                          setErrorForm(error);
                        }
                      );
                    }
                    setDatepicker(result);
                    setForm({
                      ...form,
                      origin_location_id: result[0].departure.id,
                      destination_location_id: result[0].arrival.id,
                      finished_at_d: result[0].dateTo,
                      finished_at_t: `${result[0].dateTo} ${result[0].timeTo}`,
                      started_at_d: result[0].date,
                      started_at_t: `${result[0].date} ${result[0].time}`
                    });
                  }}
                  validate
                  value={datepicker}
                  region={regionPopulate}
                  style={{ zIndex: 1 }}
                  travelingV2
                  visible={showDatepicker[indexTrip]}
                  onChangeVisible={e => {
                    const val = showDatepicker;
                    val[indexTrip] = e;
                    setShowDatepicker([...showDatepicker]);
                  }}
                  showNav={indexTrip}
                  head={renderHeaderDatepicker}
                />
                <View style={{ marginBottom: 21 }}>
                  <Touchable
                    onPress={() => {
                      const val = showDatepicker;
                      val[indexTrip] = !val[indexTrip];
                      setShowDatepicker([...showDatepicker]);
                    }}
                  >
                    <>
                      <div className="newbox">
                        <div>
                          <p className="city-from">
                            {datepicker[0].departure.location !== ""
                              ? datepicker[0].departure.location
                              : "Departure"}
                          </p>
                          <p className="date-from">
                            {datepicker[0].date === ""
                              ? "Date"
                              : dateFormated(datepicker[0].date)}
                          </p>
                          <p className="time-from">
                            {datepicker[0].date === ""
                              ? "Time"
                              : datepicker[0].time}
                          </p>
                        </div>
                        <div>
                          <p className="city-from city-to">
                            {datepicker[0].arrival.location !== ""
                              ? datepicker[0].arrival.location
                              : "Arrival"}
                          </p>
                          <p className="date-from date-to">
                            {datepicker[0].dateTo === ""
                              ? "Date"
                              : dateFormated(datepicker[0].dateTo)}
                          </p>
                          <p className="time-from">
                            {datepicker[0].date === ""
                              ? "Time"
                              : datepicker[0].timeTo}
                          </p>
                        </div>
                      </div>
                    </>
                  </Touchable>
                </View>
              </View>
            );
          })}
        </>

        <View style={styles.buttonMultiTrip}>
          <Touchable>
            <View style={{ alignItems: "center" }}>
              <Text style={{ color: "#ed5a28", marginLeft: 7 }}>
                + Multi Trip
              </Text>
            </View>
          </Touchable>
        </View>
        <View style={styles.containerListCard}>
          <View style={{ width: "48%" }}>
            <Text style={{ marginLeft: 14, fontSize: 14, color: "#666666" }}>
              Project
            </Text>
            <Card cardStyle={styles.cardMultiTrip}>
              {!newTripState.isLoadingPopulateProject &&
              newTripState.listPopulateProject.length > 0 ? (
                <Select
                  listValue={populateProject}
                  defaultValue={() =>
                    setForm({
                      ...form,
                      project_id: populateProject[0].value
                    })
                  }
                  onChange={e =>
                    setForm({
                      ...form,
                      project_id: e.target.value
                    })
                  }
                />
              ) : (
                <ActivityIndicator color="#344370" />
              )}
            </Card>
          </View>
          <View style={{ width: "48%" }}>
            <Text style={{ marginLeft: 14, fontSize: 14, color: "#666666" }}>
              Category
            </Text>
            <Card cardStyle={styles.cardMultiTrip}>
              {!newTripState.isLoadingPopulateCategory &&
              newTripState.listPopulateCategory.length > 0 ? (
                <Select
                  listValue={populateCategory}
                  defaultValue={() =>
                    setForm({
                      ...form,
                      category_id: populateCategory[0].value
                    })
                  }
                  onChange={e =>
                    setForm({
                      ...form,
                      category_id: e.target.value
                    })
                  }
                />
              ) : (
                <ActivityIndicator color="#344370" />
              )}
            </Card>
          </View>
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Purpose</Text>
          <Input
            value={form.description}
            placeholder="Enter your Purpose"
            inputStyle={[
              styles.inputStyle,
              heightInputPurpose > 0 &&
                form.description !== "" && { height: heightInputPurpose }
            ]}
            onChangeText={text => {
              if (countSubmit > 0) {
                validation({ ...form, description: text }, (error: any) => {
                  setErrorForm(error);
                });
              }
              setForm({ ...form, description: text });
            }}
            multiline
            onContentSizeChange={({ nativeEvent }) =>
              setHeightInputPurpose(nativeEvent.contentSize.height)
            }
          />
          {typeof errorForm.description !== "undefined" ? (
            <View>
              <Text style={styles.errorAlert}>{errorForm.description}</Text>
            </View>
          ) : (
            <></>
          )}
        </View>

        <View style={{ marginVertical: 20 }}>
          {image != null ? <View>{renderFile()}</View> : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 5
          }}
        >
          <Upload
            onChange={(e: any) => UploadDocument(e)}
            extionsion={extension}
            multiple={false}
          />
          <View style={styles.wrapSwitch}>
            <Switch
              onChange={() =>
                setForm({ ...form, self_booking: !form.self_booking })
              }
              checked={form.self_booking}
              onColor="#50b1b1"
              onHandleColor="#fffff7"
              handleDiameter={19}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
              height={19}
              width={34}
            />
            <Text style={styles.textSelfBooking}>Self-Booking</Text>
          </View>
        </View>
        <View style={styles.saveButtonContainer}>
          <View style={styles.buttonSave}>
            <Touchable>
              <View style={{ paddingVertical: 15, alignItems: "center" }}>
                <Text style={styles.buttonSaveText}>SAVE</Text>
              </View>
            </Touchable>
          </View>
          {/* <View style={[styles.buttonSave, styles.buttonSubmit]}>
            <Touchable>
              <View style={{ alignItems: "center", paddingVertical: 15 }}>
                <Text style={styles.buttonSaveText}>SUBMIT</Text>
              </View>
            </Touchable>
          </View> */}
        </View>
      </View>
    </View>
  );
};

export default NewAssignment;
