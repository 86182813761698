import React, { useState } from "react";
import { FlatList, Image, Text, View } from "react-native";

import styles from "./styles";
import { Card, Touchable } from "../../components";
import { IMAGES } from "../../configs";

const Detail = () => {
  let attachment = ["1", "2", "3", "4", "5"];
  const [assignDetail] = useState([
    {
      type: "trip",
      id: "ad50f1c8-811f-4606-9ad1-59d71d62fbdc",
      number: "TRP/DJN/I/2020/0033",
      title: "tuyul",
      description: null,
      trips: [
        {
          destination: "Bali",
          from: "Jakarta",
          started_at: "2020-01-08 07:00:00",
          finished_at: "2020-01-08 07:00:00"
        },
        {
          destination: "Bali",
          from: "Jakarta",
          started_at: "2020-01-08 07:00:00",
          finished_at: "2020-01-08 07:00:00"
        }
      ],
      file: null,
      status: 2,
      team: [
        { id: 1, name: "Rizky" },
        { id: 2, name: "Babang" },
        { id: 3, name: "Mirana" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" },
        { id: 4, name: "Alleria" }
      ],
      created_at: "2020-01-08T04:26:10.000000Z",
      updated_at: "2020-01-08T04:26:10.000000Z",
      category: {
        type: "trip_category",
        id: "a3315238-ae4a-4c67-8dbe-52548856662a",
        uuid: "a3315238-ae4a-4c67-8dbe-52548856662a",
        title: "Trade Show",
        description: null
      },
      project: {
        type: "project",
        id: "a5b65cd0-6ab8-4934-ab7c-10b56a624ac1",
        title: "Next project meeting: PT.nya klien A",
        description: null,
        started_at: "2019-09-30 18:00:00",
        due_at: "2020-01-01 18:00:00",
        finished_at: null,
        overdue: false,
        status: 4,
        created_at: "2020-01-06T09:52:57.000000Z",
        updated_at: "2020-01-06T09:52:57.000000Z"
      }
    }
  ]);
  attachment = [...attachment, "add"];
  return (
    <View>
      <View style={styles.assignDetail}>
        <View style={styles.rowDesc}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.title}>{assignDetail[0].number}</Text>
            <View style={styles.column}>
              <Text style={styles.desc}>{assignDetail[0].title}</Text>
              <Text style={styles.typeTrip}>
                {assignDetail[0].category.title}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.rowDesc}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.title}>Team</Text>
            <View style={styles.column}>
              <View style={[{ flexDirection: "row", flexWrap: "wrap" }]}>
                {assignDetail[0].team.map((team, index) => {
                  return (
                    <Text key={index} style={styles.desc}>
                      {`${team.name}, `}
                    </Text>
                  );
                })}
              </View>
              <Touchable>
                <Text style={styles.button}>+ New Team</Text>
              </Touchable>
            </View>
          </View>
        </View>
        <View style={styles.rowDesc}>
          {assignDetail[0].trips.map((trip, number) => {
            return (
              <View style={{ flexDirection: "row" }} key={number}>
                <Text style={styles.title}>{`Trip ${number + 1}`}</Text>
                <View style={styles.column}>
                  <Text style={styles.desc}>
                    {`${trip.from} - ${trip.destination}`}
                  </Text>
                  <Text style={styles.typeTrip}>{trip.started_at}</Text>
                </View>
              </View>
            );
          })}
        </View>
        <View style={styles.rowDesc}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.title}> </Text>
            <View style={styles.column}>
              <Touchable>
                <Text style={styles.button}>+ New Trip</Text>
              </Touchable>
            </View>
          </View>
        </View>
        <View style={styles.rowDesc}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.title}>Notes</Text>
            <View style={[styles.column, { paddingRight: 30 }]}>
              <Card>
                <Text style={styles.textnotes}>
                  asdhlasdhasdahdjkalsdhajkshjkhdlkasdhjahsdkhasdhjaksdhjsadhlkjshdjkashdkjhasdkjhasdkjhasdhkjashd
                </Text>
              </Card>
            </View>
          </View>
        </View>
      </View>
      <FlatList
        style={{ width: "100%" }}
        data={attachment}
        horizontal
        renderItem={({ item }) =>
          item === "add" ? (
            <View style={styles.attachment}>
              <Touchable>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: 109,
                    height: 109
                  }}
                >
                  <Image
                    source={IMAGES.plus}
                    style={{ width: 47, height: 61 }}
                    resizeMode="contain"
                  />
                </View>
              </Touchable>
            </View>
          ) : (
            <View style={styles.attachment}>
              <Text>{item}</Text>
            </View>
          )
        }
      />
      <Text style={[styles.statusText, { marginTop: 57 }]}>Status</Text>
      <Text style={styles.statusDesc}>PENDING</Text>
      <Text style={[styles.statusText, { marginTop: 8 }]}>
        {assignDetail[0].updated_at}
      </Text>
    </View>
  );
};

export default Detail;
