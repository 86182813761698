import React, { useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import Switch from "react-switch";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "i18next";

import { sprintf } from "sprintf-js";
import {
  getEmployee,
  getPosition,
  putModerateCash
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import styles from "./styles";
import "./style.css";
import Fixed from "../../Fixed";
import Card from "../../Card";
import { IMAGES } from "../../../configs";
import { TextArea } from "../..";
import { copyWritings } from "../../../utils";

interface Props {
  onCancel: () => void;
  tripId: string;
  isTripBill?: boolean;
}

const ModerateInvoice = ({ tripId, onCancel, isTripBill }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { listEmployee, position } = useSelector(
    (state: Reducers) => state.employee
  );
  const { tripState } = useSelector(
    (state: Reducers) => ({
      generalSetting: state.auth.generalSetting,
      tripState: state.trip
    }),
    shallowEqual
  );

  const tripModerationData = isTripBill
    ? tripState.tripBillModerationData
    : tripState.tripModerationData;

  const [addModeration, setAddModeration] = useState(false);
  const [moderationBy, setModerationBy] = useState("");
  const [error, setError] = useState(false);
  const _getDropdownData = (moderation: boolean) => {
    if (!moderation && position.length === 0 && listEmployee.length === 0) {
      dispatch(getPosition());
      dispatch(getEmployee());
    }
  };

  const copTeks = {
    employee: copyWritings("employee")
  };

  const [body, setBody]: any = useState({
    id: "",
    answer: "",
    position_id: "",
    employee_id: "",
    message: ""
  });

  useEffect(() => {
    if (tripModerationData) {
      setBody({
        ...body,
        id: tripModerationData?.[tripModerationData.length - 1]?.id
      });
    }
  }, [tripModerationData]);

  const _handleModerateCash = () => {
    if (moderationBy === "1") {
      setBody({ ...body, employee_id: null });
    } else {
      setBody({ ...body, position_id: null });
    }

    if (body.answer !== "1") {
      setBody({ ...body, employee_id: null, position_id: null });
    }

    if (body.answer) {
      dispatch(
        putModerateCash(body, tripId, () =>
          isTripBill ? history.push("/trip/bill") : history.push("/trip")
        )
      );
      onCancel();
    } else {
      setError(true);
    }
  };

  const handleAddModeration = () => {
    return (
      tripModerationData &&
      tripModerationData.length > 0 &&
      tripModerationData[tripModerationData.length - 1].stage_current ===
        tripModerationData[tripModerationData.length - 1].stage_total
    );
  };

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <View style={styles.containerContent}>
        <Text style={styles.title}>{i18n.t("moderation.yourResponse")}</Text>
        <View style={[styles.card, styles.containerDropdown]}>
          <select
            className="moderation-dropdown"
            onChange={e => {
              setBody({
                ...body,
                answer: e.target.value,
                employee_id: "",
                position_id: "",
                message: ""
              });
              setError(false);
            }}
          >
            <option selected hidden>
              {i18n.t("moderation.selectAnswer")}
            </option>
            <option value="1">{i18n.t("moderation.approve")}</option>
            {!isTripBill && (
              <option value="2">{i18n.t("moderation.needToRevise")}</option>
            )}
            <option value="3">{i18n.t("moderation.reject")}</option>
          </select>
        </View>
        {error && <Text style={styles.error}>Please select your answer</Text>}
        {body.answer !== "1" && body.answer !== "" ? (
          <View
            style={{
              width: "70%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text
              style={[
                styles.title,
                styles.textStyle,
                { marginVertical: 15, fontWeight: "normal" }
              ]}
            >
              {i18n.t("moderation.notes")}
            </Text>

            <TextArea
              bold
              defaultHeight="one-row"
              onChange={e => setBody({ ...body, message: e.target.value })}
              value={body.message}
            />
          </View>
        ) : null}
        {body.answer === "1" && handleAddModeration() ? (
          <View style={{ marginTop: 40 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Switch
                onChange={() => {
                  _getDropdownData(addModeration);
                  setAddModeration(!addModeration);
                  setModerationBy("");
                }}
                checked={addModeration}
                onColor="#50b1b1"
                onHandleColor="#fffff7"
                handleDiameter={19}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                height={19}
                width={34}
              />

              <Text style={styles.moderationText}>
                {i18n.t("moderation.addModeration")}
              </Text>
            </View>
            {addModeration ? (
              <View>
                <View
                  style={[
                    styles.card,
                    styles.containerDropdown,
                    { marginTop: 16, alignSelf: "center" }
                  ]}
                >
                  <select
                    className="moderation-dropdown"
                    onChange={e => {
                      setModerationBy(e.target.value);
                    }}
                  >
                    <option selected hidden>
                      {i18n.t("moderation.selectModerationBy")}
                    </option>
                    <option value="1">{i18n.t("moderation.position")}</option>
                    <option value="2">{copTeks.employee}</option>
                  </select>
                </View>
                {moderationBy ? (
                  <View style={{ width: "100%" }}>
                    {moderationBy === "1" ? (
                      <View
                        style={[
                          styles.card,
                          styles.containerDropdown,
                          { marginTop: 16 }
                        ]}
                      >
                        <select
                          onChange={e => {
                            setBody({
                              ...body,
                              position_id: e.target.value
                            });
                          }}
                          className="moderation-dropdown"
                        >
                          <option selected hidden>
                            {sprintf(
                              i18n.t("moderation.select"),
                              i18n.t("moderation.position")
                            )}
                          </option>
                          {position.length > 0 &&
                            position.map((item, index: number) => (
                              <option key={index} value={item.id}>
                                {item.attributes.title}
                              </option>
                            ))}
                        </select>
                      </View>
                    ) : (
                      <View
                        style={[
                          styles.wrapPersonList,
                          {
                            width: "90%",
                            marginTop: 20,
                            alignSelf: "center"
                          }
                        ]}
                      >
                        {listEmployee.map((item, index) => (
                          <View
                            key={index}
                            style={{ width: "50%", marginBottom: 8 }}
                          >
                            <Card
                              cardStyle={{
                                backgroundColor:
                                  body.employee_id === item.id
                                    ? "#50B1B1"
                                    : "white"
                              }}
                              onPress={() =>
                                setBody({ ...body, employee_id: item.id })
                              }
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center"
                                }}
                              >
                                <Image
                                  source={
                                    (item.attributes.avatar &&
                                      item.attributes.avatar.url) ||
                                    IMAGES.avatar
                                  }
                                  style={styles.imageStyle}
                                />
                                <View style={{ marginLeft: 4, width: "100%" }}>
                                  <Text
                                    style={[
                                      styles.textStyle,
                                      body.employee_id === item.id
                                        ? { color: "#fff" }
                                        : { color: "#707070" },
                                      { fontWeight: "bold" }
                                    ]}
                                    numberOfLines={1}
                                  >
                                    {item.attributes.name}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.textStyle,
                                      body.employee_id === item.id
                                        ? { color: "#fff" }
                                        : { color: "#707070" }
                                    ]}
                                    numberOfLines={1}
                                  >
                                    {item.attributes.position_name}
                                  </Text>
                                </View>
                              </View>
                            </Card>
                          </View>
                        ))}
                      </View>
                    )}
                  </View>
                ) : null}
              </View>
            ) : null}
          </View>
        ) : null}
      </View>
      <Fixed position="bottom">
        <View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TouchableOpacity
              onPress={() => {
                _handleModerateCash();
              }}
              style={styles.button}
            >
              <Text style={styles.buttonFont}>
                {i18n.t("moderation.submit")}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={onCancel}>
              <Text
                style={{
                  color: "#666666",
                  fontWeight: "bold",
                  marginBottom: 20,
                  fontFamily: "Poppins"
                }}
              >
                {i18n.t("moderation.cancel")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Fixed>
    </View>
  );
};

export default ModerateInvoice;
