import React, { useEffect } from "react";
import { FlatList, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  CardStatusColor,
  LoadingLoadMore,
  WrapContent,
  WrapList
} from "../../../components";
import { documentTitle } from "../../../utils";
import { Card } from "../components";
import styles from "./styles";

// redux action & types
import { getTripBill, resetSearchTripValue } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const Approval = () => {
  documentTitle("Trip - Approval");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const tripState = useSelector((state: Reducers) => state.trip);

  useEffect(() => {
    dispatch(getTripBill(false, tripState.searchValue));
  }, [dispatch, tripState.searchValue]);

  const _goToDetailTrip = (id: string, status: any) => {
    history.push(`/trip-bill-detail/${id}?type=bill&status=${status}`, {
      labelStatus: status
    });
    dispatch(resetSearchTripValue());
  };

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2) {
      color = "#F5AA3F";
    } else if (status === 4 || status === 7) {
      color = "#ed5a28";
    } else if (status === 8) {
      color = "#2B94FC";
    }
    return color;
  };

  const _statusDesc = (data: any) => {
    let desc = t("activity.status.draft");

    if (
      data.allModeration.length > 0 &&
      [1, 2, 3].includes(data.status) &&
      data.allModeration[0].submitted_current !==
        data.allModeration[0].stage_total
    ) {
      desc = `${t("activity.status.submitted")} ${
        data.allModeration[0].submitted_current
      } / ${data.allModeration[0].stage_total}`;
    } else if (data.status === 2) {
      desc = t("activity.status.submitted");
    } else if (data.status === 3) {
      desc = t("activity.status.approved");
    } else if (data.status === 4) {
      desc = t("activity.status.revise");
    } else if (data.status === 5) {
      desc = t("activity.status.ongoing");
    } else if (data.status === 6) {
      desc = t("activity.status.finished");
    } else if (data.status === 7) {
      desc = t("activity.status.reject");
    } else if (data.status === 8) {
      desc = t("cash.status.overdue");
    }
    return desc;
  };

  return (
    <WrapContent isLoading={tripState.isLoadingListBill}>
      <WrapList
        isEmpty={tripState.listBill.length === 0}
        emptyComponent={() => (
          <Text style={styles.keyData}>{t("cash.new.noData")}</Text>
        )}
        onEndReached={() =>
          tripState.loadMoreBill &&
          dispatch(getTripBill(true, tripState.searchValue))
        }
      >
        <FlatList
          style={{ paddingBottom: 10, paddingHorizontal: 25 }}
          data={tripState.listBill}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={() => (
            <LoadingLoadMore active={tripState.loadMoreBill} />
          )}
          renderItem={({ item, index }) => (
            <Card
              item={item}
              index={index}
              onPress={() => _goToDetailTrip(item.id, item.status)}
              statusColor={_statusColor(item.status)}
              withStatus
              statusDesc={_statusDesc(item)}
              type="approval"
            />
          )}
        />
      </WrapList>
    </WrapContent>
  );
};
export default Approval;
