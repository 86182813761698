import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    marginHorizontal: 20
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 22,
    paddingTop: 35,
    paddingBottom: 12,
    paddingRight: 4,
    borderRadius: 35
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  detailDescriptionCard: {
    width: "75%",
    paddingVertical: 2,
    paddingLeft: 13
  },
  detailDescriptionText: {
    fontSize: 11,
    color: "#666666",
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  amountContainer: {
    alignItems: "center"
  },
  amountTitle: {
    fontSize: 14,
    color: "#999999",
    fontFamily: "Poppins"
  },
  amount: {
    fontSize: 24,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666"
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    marginBottom: 23,
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 23
  }
});
export default styles;
