/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Image, ScrollView, Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import { Modal, Select, TextArea, Touchable } from "../../../../components";
import { ModalConfirmForm } from "../../../../components/ModalComponents";
import { COLORS, ICONS, IMAGES } from "../../../../configs";
import {
  convertDate,
  copyWritings,
  documentTitle,
  maskedMoney,
  queryString,
  toast
} from "../../../../utils";
import {
  deleteBookingById,
  getListBookingHotel,
  getTripById,
  handleFormAcitivy
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

const suiteHotel = [
  { label: "Any", value: "any" },
  { label: "Single", value: "single" },
  { label: "Double", value: "double" },
  { label: "Family", value: "family" }
];

const initForm = {
  started_at: "",
  finished_at: "",
  vendor_id: "",
  regency_id: "",
  star_id: 0,
  suite_id: suiteHotel[0].value,
  hotel: "",
  remarks: "",
  country_code: "ID"
};

interface Props {
  id?: string;
}

const Hotel = ({ id }: Props) => {
  documentTitle("Booking - Hotel");
  const dispatch = useDispatch();
  const { sprintf } = require("sprintf-js");
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = history.location;

  const [isSetDefault, setIsSetDefault] = useState(true);
  const [isError, setIsError] = useState(false);
  const [first, setFirst] = useState(true);
  const [valueModalConfirm, setValueModalConfirm]: any = useState();
  const [dateTime, setDateTime] = useState({
    start_d: "",
    start_t: "",
    finish_d: "",
    finish_t: ""
  });
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [modalDateTime, setModalDateTime] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [textLocation, setTextLocation] = useState("");
  const [form, setForm] = useState(initForm);
  const {
    policiesState,
    vendorState,
    regionState,
    tripState,
    sourceState,
    regencyState,
    bookingState
  } = useSelector(
    (state: Reducers) => ({
      policiesState: state.policies,
      vendorState: state.vendor,
      regionState: state.region,
      tripState: state.trip,
      sourceState: state.source,
      regencyState: state.regency,
      bookingState: state.booking
    }),
    shallowEqual
  );

  const _setDateTime = useCallback(
    (
      formName: "start_d" | "start_t" | "finish_d" | "finish_t",
      formValue: string
    ) => {
      setDateTime({ ...dateTime, [formName]: formValue });
    },
    [dateTime]
  );

  const _setForm = useCallback(
    (
      formName:
        | "vendor_id"
        | "regency_id"
        | "star_id"
        | "suite_id"
        | "hotel"
        | "remarks"
        | "started_at"
        | "finished_at"
        | "country_code",
      formValue: string | number
    ) => {
      setForm({ ...form, [formName]: formValue });
    },
    [form]
  );

  useEffect(() => {
    if (!tripState.tripData && id) {
      dispatch(getTripById(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      (first && vendorState.vendors[0]) ||
      (isSetDefault &&
        tripState.activity.data.new_activity &&
        tripState.activity.data.new_activity.trip &&
        tripState.activity.data.new_activity.trip.destination_country_code)
    ) {
      if (
        isSetDefault &&
        tripState.activity.data.new_activity &&
        tripState.activity.data.new_activity.trip &&
        tripState.activity.data.new_activity.trip.destination_country_code &&
        vendorState.vendors[0]
      ) {
        setForm({
          ...form,
          vendor_id: vendorState.vendors[0].id,
          country_code:
            tripState.activity.data.new_activity.trip.destination_country_code
        });
        setIsSetDefault(false);
      } else if (first && vendorState.vendors[0]) {
        setForm({
          ...form,
          vendor_id: vendorState.vendors[0].id
        });
        setFirst(false);
      }
    }
  }, [
    vendorState.vendors,
    first,
    tripState.activity.data.new_activity,
    isSetDefault,
    form
  ]);

  const _renderStar = useCallback(
    (value: number, disableButton = false) => {
      let result: any[] = [];
      for (let i = 1; i <= 5; i += 1) {
        result = [
          ...result,
          <View key={i} style={{ marginRight: 4 }}>
            <Touchable
              onPress={() => _setForm("star_id", form.star_id === i ? 0 : i)}
              disabled={disableButton}
            >
              <Image
                source={i <= value ? IMAGES.star : IMAGES.stargrey}
                style={styles.iconStar}
              />
            </Touchable>
          </View>
        ];
      }

      return result;
    },
    [_setForm, form.star_id]
  );

  const _getCityOriginTrip = useCallback(() => {
    if (
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.trip
    ) {
      const getIndex = regencyState.departureAirports.findIndex(
        e =>
          e.id === tripState.activity.data.new_activity.trip.origin_location_id
      );

      return getIndex >= 0
        ? regencyState.departureAirports[getIndex].attributes.city_name
        : "";
    }
    return "";
  }, [regencyState.departureAirports, tripState.activity.data.new_activity]);

  const _getIntownId = useCallback(() => {
    if (
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.trip
    ) {
      const getIndex = regionState.region.findIndex(
        e => e.name.toLowerCase() === textLocation.toLowerCase()
      );
      if (getIndex >= 0) {
        return (
          regionState.region[getIndex].id ===
          tripState.activity.data.new_activity.trip.origin_location_id
        );
      }

      return false;
    }
    if (id) {
      const getIndex = regionState.region.findIndex(
        e => e.name.toLowerCase() === textLocation.toLowerCase()
      );
      if (getIndex !== -1) {
        return true;
      }
    }

    return false;
  }, [tripState.activity.data.new_activity, regionState.region, textLocation]);

  const _getBudget = useCallback(
    (country: string) => {
      if (policiesState.isLoadingPolicies) {
        return "loading...";
      }

      _getIntownId();

      const days =
        moment(form.finished_at).diff(moment(form.started_at), "days") + 1;

      let policy = "allowance-hotel-perdiem-amount-international";
      if (id) {
        if (country !== tripState.tripData.origin_country_code) {
          policy = "allowance-hotel-perdiem-amount-international";
        } else if (
          country === tripState.tripData.origin_country_code &&
          tripState.tripData.origin_country_code === "ID" &&
          _getIntownId() &&
          tripState.tripData.origin_location.toLowerCase() !==
            textLocation.toLowerCase()
        ) {
          policy = "allowance-hotel-perdiem-amount-domestic";
        } else if (
          tripState.tripData.origin_country_code === "ID" &&
          _getIntownId() &&
          tripState.tripData.origin_location.toLowerCase() ===
            textLocation.toLowerCase()
        ) {
          policy = "allowance-hotel-perdiem-amount-in-town";
        } else if (
          tripState.tripData.origin_location.toLowerCase() ===
          textLocation.toLowerCase()
        ) {
          policy = "allowance-hotel-perdiem-amount-in-town";
        }
      } else if (
        country ===
        (tripState.activity.data.new_activity &&
          tripState.activity.data.new_activity.trip &&
          tripState.activity.data.new_activity.trip.origin_country_code)
      ) {
        policy = "allowance-hotel-perdiem-amount-domestic";
        if (
          tripState.activity.data.new_activity.trip.origin_country_code ===
            "ID" &&
          _getIntownId()
        ) {
          policy = "allowance-hotel-perdiem-amount-in-town";
        } else if (
          _getCityOriginTrip().toLowerCase() === textLocation.toLowerCase()
        ) {
          policy = "allowance-hotel-perdiem-amount-in-town";
        }
      }

      const getIndex = policiesState.policies.findIndex(el => el.id === policy);

      return (
        policiesState.policies[getIndex] &&
        maskedMoney(
          policiesState.policies[getIndex].attributes.value_object.value * days
        )
      );
    },
    [
      policiesState.isLoadingPolicies,
      policiesState.policies,
      form.started_at,
      form.finished_at,
      tripState.activity.data.new_activity,
      textLocation,
      _getCityOriginTrip,
      _getIntownId
    ]
  );

  const _getListCountry = useCallback(() => {
    let result = [{ label: "select", value: "" }];
    regencyState.countries.forEach(e => {
      result = [...result, { label: e.title, value: e.id }];
    });

    return result;
  }, [regencyState.countries]);

  const _getVendor = useCallback(() => {
    let result: any[] = [];
    vendorState.vendors.forEach(e => {
      result = [...result, { value: e.id, label: e.title }];
    });

    return result;
  }, [vendorState.vendors]);

  const _handleOpenModalConfirm = () => {
    if (
      form.started_at !== "" &&
      form.finished_at !== "" &&
      form.hotel !== "" &&
      (form.country_code === initForm.country_code
        ? form.regency_id !== ""
        : textLocation !== "")
    ) {
      const listCountry = _getListCountry();
      const listVendor = _getVendor();
      const listRegencies = regionState && regionState.region;
      const dataModal = [
        { title: t("activity.booking.stars"), value: String(form.star_id) },
        {
          title: "Check In",
          value: moment(form.started_at).format("DD MMMM YYYY")
        },
        {
          title: "Check Out",
          value: moment(form.finished_at).format("DD MMMM YYYY")
        },
        {
          title: t("activity.booking.suite"),
          value: String(form.suite_id)
        },
        {
          title: t("activity.booking.country"),
          value: listCountry.filter(
            (item: any) => item.value === form.country_code
          )[0].label
        },
        {
          title: t("activity.booking.location"),
          value:
            form.country_code === "ID"
              ? listRegencies.filter(
                  (item: any) => item.id === form.regency_id
                )[0].name
              : textLocation
        },
        {
          title: t("activity.booking.hotel"),
          value: form.hotel
        },
        {
          title: t("activity.booking.vendor"),
          value: listVendor.filter(
            (item: any) => item.value === form.vendor_id
          )[0].label
        },
        {
          title: t("activity.new.description"),
          value: form.remarks
        }
      ];
      setValueModalConfirm(dataModal);
      setModalSubmitForm(true);
    } else {
      setIsError(true);
    }
  };

  const _saveDateTime = useCallback(() => {
    if (
      dateTime.start_d !== "" &&
      dateTime.start_t !== "" &&
      dateTime.finish_d !== "" &&
      dateTime.finish_t !== ""
    ) {
      setModalDateTime(false);
      const splitedStart = dateTime.start_t.split(":");
      const splitedFinish = dateTime.finish_t.split(":");
      setForm({
        ...form,
        started_at: new Date(
          moment(dateTime.start_d)
            .set({
              hour: Number(splitedStart[0]),
              minute: Number(splitedStart[1])
            })
            .format("DD-MMMM-YYYY HH:mm")
        ).toUTCString(),
        finished_at: new Date(
          moment(dateTime.finish_d)
            .set({
              hour: Number(splitedFinish[0]),
              minute: Number(splitedFinish[1])
            })
            .format("DD-MMMM-YYYY HH:mm")
        ).toUTCString()
      });
      if (isError) {
        setIsError(false);
      }
    } else if (!isError) {
      setIsError(true);
    }
  }, [dateTime, form, isError]);

  const _handleOrder = useCallback(() => {
    if (
      form.started_at !== "" &&
      form.finished_at !== "" &&
      (form.country_code === initForm.country_code
        ? form.regency_id !== ""
        : textLocation !== "")
    ) {
      const getIndex = sourceState.sources.findIndex(
        item => item.attributes.title === "Hotel - Vendor"
      );
      const formBooking = {
        ...form,
        hotel: form.hotel !== "" ? form.hotel : "Any",
        star_id: form.star_id > 0 ? form.star_id : "any",
        source_id: sourceState.sources[getIndex].id,
        inhabitants: 1,
        quantity: 1,
        client_timezone: "Asia/Jakarta",
        invoices: [],
        trip_id: id || null,
        city: form.country_code !== initForm.country_code ? textLocation : "",
        regency_id:
          form.country_code === initForm.country_code ? form.regency_id : ""
      };
      if (tripState.activity.data.bookingHotels) {
        dispatch(
          handleFormAcitivy(
            "bookingHotels",
            [...tripState.activity.data.bookingHotels, formBooking],
            id,
            () => dispatch(getListBookingHotel(id || ""))
          )
        );
      } else {
        dispatch(
          handleFormAcitivy(
            "bookingHotels",
            [formBooking],
            id,
            id ? () => dispatch(getListBookingHotel(id || "")) : () => null
          )
        );
      }

      setForm({
        ...initForm,
        vendor_id: vendorState.vendors[0] && vendorState.vendors[0].id
      });
      setTextLocation("");
      if (isError) {
        setIsError(false);
      }
      setModalSubmitForm(false);
      toast.success(t("activity.booking.orderSuccess"));
    } else if (!isError) {
      setIsError(true);
    }
  }, [
    tripState.activity.data.bookingHotels,
    form,
    dispatch,
    sourceState.sources,
    vendorState.vendors,
    textLocation,
    isError
  ]);

  const _getLabelSuite = useCallback((value: string) => {
    const getIndex = suiteHotel.findIndex(e => e.value === value);
    return suiteHotel[getIndex] && suiteHotel[getIndex].label;
  }, []);

  const _handleDelete = useCallback(
    (index: number) => {
      if (id) {
        dispatch(deleteBookingById(bookingState.listBookingHotel[index].id));
      } else {
        dispatch(
          handleFormAcitivy(
            "bookingHotels",
            tripState.activity.data.bookingHotels.filter(
              (e: any, i: number) => i !== index
            )
          )
        );
      }

      toast.error(t("activity.booking.deleted"));
    },
    [
      tripState.activity.data.bookingHotels,
      bookingState.listBookingHotel,
      dispatch
    ]
  );

  const _renderError = useCallback(
    (isErrorParam: boolean) =>
      isErrorParam && (
        <Text
          style={[
            styles.labelInput,
            { marginLeft: 12, marginTop: 4, color: COLORS.orange }
          ]}
        >
          {t("activity.detail.msg.req")}
        </Text>
      ),
    []
  );

  const _getValueDefault = useCallback((result: any) => {
    if (result) {
      return result;
    }
    return "";
  }, []);

  const _openModal = useCallback(() => {
    setModalDateTime(true);
    if (id) {
      setDateTime({
        start_d: form.started_at
          ? convertDate(form.started_at, "YYYY-MM-DD")
          : _getValueDefault(
              convertDate(
                tripState.tripData && tripState.tripData.started_at,
                "YYYY-MM-DD"
              )
            ),
        start_t:
          dateTime.start_t ||
          _getValueDefault(
            convertDate(
              tripState.tripData && tripState.tripData.started_at,
              "HH:mm"
            )
          ),
        finish_d: form.finished_at
          ? convertDate(form.finished_at, "YYYY-MM-DD")
          : _getValueDefault(
              convertDate(
                tripState.tripData && tripState.tripData.finished_at,
                "YYYY-MM-DD"
              )
            ),
        finish_t:
          dateTime.finish_t ||
          _getValueDefault(
            convertDate(
              tripState.tripData && tripState.tripData.finished_at,
              "HH:mm"
            )
          )
      });
    } else {
      setDateTime({
        start_d: form.started_at
          ? convertDate(form.started_at, "YYYY-MM-DD")
          : _getValueDefault(
              tripState.activity.data.new_activity &&
                tripState.activity.data.new_activity.trip &&
                tripState.activity.data.new_activity.trip.started_at &&
                convertDate(
                  tripState.activity.data.new_activity.trip.started_at,
                  "YYYY-MM-DD"
                )
            ),
        start_t:
          dateTime.start_t ||
          _getValueDefault(
            tripState.activity.data.new_activity &&
              tripState.activity.data.new_activity.trip &&
              tripState.activity.data.new_activity.trip.started_at &&
              convertDate(
                tripState.activity.data.new_activity.trip.started_at,
                "HH:mm"
              )
          ),
        finish_d: form.finished_at
          ? convertDate(form.finished_at, "YYYY-MM-DD")
          : _getValueDefault(
              tripState.activity.data.new_activity &&
                tripState.activity.data.new_activity.trip &&
                tripState.activity.data.new_activity.trip.finished_at &&
                convertDate(
                  tripState.activity.data.new_activity.trip.finished_at,
                  "YYYY-MM-DD"
                )
            ),
        finish_t:
          dateTime.finish_t ||
          _getValueDefault(
            tripState.activity.data.new_activity &&
              tripState.activity.data.new_activity.trip &&
              tripState.activity.data.new_activity.trip.finished_at &&
              convertDate(
                tripState.activity.data.new_activity.trip.finished_at,
                "HH:mm"
              )
          )
      });
    }
  }, [
    form.started_at,
    form.finished_at,
    tripState.activity.data.new_activity,
    _getValueDefault
  ]);

  const params = queryString(search);

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={styles.container}>
        {id &&
          bookingState.listBookingHotel.map((item: any, index: number) => (
            <View
              style={[styles.row, index === 0 && { marginTop: 16 }]}
              key={index}
            >
              <Touchable
                onPress={() =>
                  history.replace(
                    `/newtrip/detailbooking/hotel/${index}/${
                      id || "hotel-detail"
                    }${search}`
                  )
                }
              >
                <View style={styles.cardCalendar}>
                  <View style={[styles.flexDirection, styles.rowList]}>
                    <Text style={styles.labelList}>
                      {t("activity.booking.stars")}
                    </Text>
                    <View style={[styles.flexDirection, { flex: 1 }]}>
                      {item.star_id === "any" ? (
                        <Text style={styles.valueList}>{item.star_id}</Text>
                      ) : (
                        _renderStar(
                          item.djurneeBooking.template
                            ? item.data.prediction_star
                            : item.data.star,
                          true
                        )
                      )}
                    </View>
                  </View>

                  <View style={[styles.flexDirection, styles.rowList]}>
                    <Text style={styles.labelList}>
                      {t("activity.booking.suite")}
                    </Text>
                    <View style={[styles.flexDirection, { flex: 1 }]}>
                      <Text style={styles.valueList}>
                        {item.djurneeBooking.template
                          ? item.data.prediction_suite
                          : item.data.suite}
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.flexDirection, styles.rowList]}>
                    <Text style={styles.labelList}>Check-in</Text>
                    <View style={[styles.flexDirection, { flex: 1 }]}>
                      <Text
                        style={[styles.valueList, { fontWeight: "normal" }]}
                      >
                        {convertDate(item.data.check_in, "ddd")}
                        <Text style={styles.valueList}>
                          {` ${convertDate(item.data.check_in, "DD MMM YYYY")}`}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.flexDirection, styles.rowList]}>
                    <Text style={styles.labelList}>Check-out</Text>
                    <View style={[styles.flexDirection, { flex: 1 }]}>
                      <Text
                        style={[styles.valueList, { fontWeight: "normal" }]}
                      >
                        {convertDate(item.data.check_out, "ddd")}
                        <Text style={styles.valueList}>
                          {` ${convertDate(
                            item.data.check_out,
                            "DD MMM YYYY"
                          )}`}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  {search &&
                    (params.status === "1" || params.status === "4") &&
                    new Date(
                      tripState.tripData && tripState.tripData.finished_at
                    ) > new Date() && (
                      <View style={styles.wrapButtonTrash}>
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            _handleDelete(index);
                          }}
                        >
                          <View style={styles.buttonTrash}>
                            <Image
                              source={ICONS.trash}
                              style={{ width: 12, height: 12 }}
                            />
                          </View>
                        </Touchable>
                      </View>
                    )}
                </View>
              </Touchable>
            </View>
          ))}
        {tripState.activity.data.bookingHotels &&
          tripState.activity.data.bookingHotels.map(
            (item: any, index: number) => (
              <View
                style={[styles.row, index === 0 && { marginTop: 16 }]}
                key={index}
              >
                <Touchable
                  onPress={() =>
                    history.replace(
                      `/newtrip/detailbooking/hotel/${index}/${
                        id || "hotel-detail"
                      }${search}`
                    )
                  }
                >
                  <View style={styles.cardCalendar}>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.stars")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        {item.star_id === "any" ? (
                          <Text style={styles.valueList}>{item.star_id}</Text>
                        ) : (
                          _renderStar(item.star_id, true)
                        )}
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.suite")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>
                          {_getLabelSuite(item.suite_id)}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>Check-in</Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {convertDate(item.started_at, "ddd")}
                          <Text style={styles.valueList}>
                            {` ${convertDate(item.started_at, "DD MMM YYYY")}`}
                          </Text>
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>Check-out</Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {convertDate(item.finished_at, "ddd")}
                          <Text style={styles.valueList}>
                            {` ${convertDate(item.finished_at, "DD MMM YYYY")}`}
                          </Text>
                        </Text>
                      </View>
                    </View>

                    <View style={styles.wrapButtonTrash}>
                      <Touchable
                        onPress={e => {
                          e.stopPropagation();
                          _handleDelete(index);
                        }}
                      >
                        <View style={styles.buttonTrash}>
                          <Image
                            source={ICONS.trash}
                            style={{ width: 12, height: 12 }}
                          />
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </Touchable>
              </View>
            )
          )}
        {(((search && (params.status === "1" || params.status === "4")) ||
          !search) &&
          tripState.tripData &&
          new Date(tripState.tripData.finished_at) > new Date()) ||
        !id ? (
          <View>
            <View style={[styles.row, { marginTop: 16 }]}>
              <View style={[styles.flexDirection, { paddingHorizontal: 12 }]}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.labelInput}>Check–in</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.labelInput}>Check–out</Text>
                </View>
              </View>
              <Touchable onPress={_openModal}>
                <View style={[styles.cardCalendar, styles.flexDirection]}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.labelDate}>
                      {form.started_at
                        ? convertDate(form.started_at, "DD MMMM YYYY")
                        : "-"}
                    </Text>
                    <Text style={styles.labelTime}>
                      {form.started_at ? dateTime.start_t : "-"}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={[styles.labelDate, styles.labelEndDate]}>
                      {form.finished_at
                        ? convertDate(form.finished_at, "DD MMMM YYYY")
                        : "-"}
                    </Text>
                    <Text style={styles.labelTime}>
                      {form.finished_at ? dateTime.finish_t : "-"}
                    </Text>
                  </View>
                </View>
              </Touchable>

              {_renderError(
                isError && (form.started_at === "" || form.finished_at === "")
              )}
            </View>

            <View style={[styles.row, styles.flexDirection]}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.stars")}
                  </Text>
                </View>
                <View
                  style={[
                    styles.card,
                    styles.flexDirection,
                    { justifyContent: "flex-start", alignItems: "center" }
                  ]}
                >
                  {_renderStar(form.star_id)}
                </View>
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.suite")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Select
                    listValue={suiteHotel}
                    style={{ width: "100%" }}
                    value={form.suite_id}
                    onChange={e => _setForm("suite_id", e.target.value)}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.row, styles.flexDirection, { zIndex: 1 }]}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.country")}
                  </Text>
                </View>
                <View style={[styles.card]}>
                  <Select
                    listValue={_getListCountry()}
                    style={{ width: "100%" }}
                    value={form.country_code}
                    onChange={e => {
                      setForm({
                        ...form,
                        country_code: e.target.value,
                        regency_id: ""
                      });
                      setTextLocation("");
                    }}
                  />
                </View>
                {_renderError(isError && form.country_code === "")}
              </View>
            </View>

            <View style={[styles.row, styles.flexDirection, { zIndex: 1 }]}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.location")}
                  </Text>
                </View>
                <View style={[styles.card]}>
                  <TextInput
                    style={styles.textInput}
                    value={textLocation}
                    onChangeText={e => setTextLocation(e)}
                    onFocus={() => setShowLocation(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowLocation(false);
                      }, 100);
                    }}
                  />
                  {showLocation && form.country_code === initForm.country_code && (
                    <View style={styles.containerAutoComplete}>
                      <View style={styles.cardAutoComplete}>
                        {regionState &&
                          regionState.region
                            .filter(e =>
                              e.name
                                .toUpperCase()
                                .includes(textLocation.toUpperCase())
                            )
                            .map((city: any, index: number) => (
                              <Touchable
                                key={index}
                                onPress={() => {
                                  _setForm("regency_id", city.id);
                                  setTextLocation(city.name);
                                }}
                                width="100%"
                              >
                                <View style={styles.buttonAutoComplete}>
                                  <Text style={{ marginHorizontal: 10 }}>
                                    {city.name}
                                  </Text>
                                </View>
                              </Touchable>
                            ))}
                      </View>
                    </View>
                  )}
                </View>
                {_renderError(
                  isError &&
                    (form.country_code === initForm.country_code
                      ? form.regency_id === ""
                      : textLocation === "")
                )}
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.hotel")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <TextInput
                    style={styles.textInput}
                    value={form.hotel}
                    onChangeText={e => _setForm("hotel", e)}
                  />
                </View>
                {_renderError(isError && !form.hotel)}
              </View>
            </View>

            <View style={[styles.row, styles.flexDirection]}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {copyWritings("vendor")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Select
                    listValue={_getVendor()}
                    style={{ width: "100%" }}
                    value={form.vendor_id}
                    onChange={e => _setForm("vendor_id", e.target.value)}
                  />
                </View>
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {sprintf(
                      t("activity.booking.budgetByPolicy"),
                      copyWritings("policy")
                    )}
                  </Text>
                </View>
                <View
                  style={[
                    styles.wrapLabeInput,
                    { flex: 1, justifyContent: "center" }
                  ]}
                >
                  <Text style={styles.labelPolicy}>
                    IDR
                    <Text style={[styles.labelPolicy, { fontWeight: "bold" }]}>
                      {` ${
                        form.started_at && form.finished_at && textLocation
                          ? _getBudget(form.country_code) || "-"
                          : "-"
                      }`}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.wrapLabeInput}>
                <Text style={styles.labelInput}>
                  {t("activity.new.description")}
                </Text>
              </View>
              <TextArea
                value={form.remarks}
                onChange={e => _setForm("remarks", e.target.value)}
                bold
                withPadding
              />
            </View>

            <View style={[styles.row, { alignItems: "center" }]}>
              <Text style={[styles.labelInput, { marginBottom: 12 }]}>
                {t("activity.booking.additionFees")}
              </Text>
              <View
                style={[
                  styles.wrapButton,
                  sourceState.isLoadingSource && { opacity: 0.5 }
                ]}
              >
                <Touchable
                  onPress={() => _handleOpenModalConfirm()}
                  disabled={sourceState.isLoadingSource}
                >
                  <View style={styles.button}>
                    <Text style={styles.textButton}>
                      {t("activity.booking.order")}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        ) : null}

        <Modal
          isVisible={modalSubmitForm}
          backdropColor="rgba(255,255,255, 0.9)"
        >
          <ModalConfirmForm
            data={valueModalConfirm}
            onPress={() => _handleOrder()}
            onCancel={() => setModalSubmitForm(false)}
            isLoading={false}
          />
        </Modal>

        <Modal
          isVisible={modalDateTime}
          onBackdropPress={() => {
            setModalDateTime(false);
            if (isError) {
              setIsError(false);
            }
          }}
          backdropColor="rgba(0, 0, 0, 0.5)"
        >
          <View style={[styles.card, styles.containerModal]}>
            <View>
              <Text
                style={[styles.labelInput, { marginBottom: 4, marginLeft: 12 }]}
              >
                Check-in
              </Text>
              <View style={styles.flexDirection}>
                <View style={{ flex: 1 }}>
                  <View style={styles.card}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666",
                        width: "100%"
                      }}
                      type="date"
                      className="input-date-new-trip"
                      onChange={e => _setDateTime("start_d", e.target.value)}
                      value={dateTime.start_d}
                      min={moment().format("YYYY-MM-DD")}
                      max={dateTime.finish_d}
                    />
                  </View>
                  {_renderError(isError && dateTime.start_d === "")}
                </View>

                <View style={{ flex: 1, marginLeft: 14 }}>
                  <View style={styles.card}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666"
                      }}
                      type="time"
                      className="input-date-new-trip"
                      onChange={e => _setDateTime("start_t", e.target.value)}
                      value={dateTime.start_t}
                    />
                  </View>
                  {_renderError(isError && dateTime.start_t === "")}
                </View>
              </View>
            </View>

            <View style={{ marginTop: 36 }}>
              <Text
                style={[styles.labelInput, { marginBottom: 4, marginLeft: 12 }]}
              >
                Check-out
              </Text>
              <View style={styles.flexDirection}>
                <View style={{ flex: 1 }}>
                  <View style={styles.card}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666",
                        width: "100%"
                      }}
                      type="date"
                      className="input-date-new-trip"
                      onChange={e => _setDateTime("finish_d", e.target.value)}
                      value={dateTime.finish_d}
                      min={
                        dateTime.start_d === ""
                          ? moment().format("YYYY-MM-DD")
                          : dateTime.start_d
                      }
                    />
                  </View>
                  {_renderError(isError && dateTime.finish_d === "")}
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <View style={styles.card}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666"
                      }}
                      type="time"
                      className="input-date-new-trip"
                      onChange={e => _setDateTime("finish_t", e.target.value)}
                      value={dateTime.finish_t}
                    />
                  </View>
                  {_renderError(isError && dateTime.finish_t === "")}
                </View>
              </View>
            </View>

            <View
              style={{ width: "100%", alignItems: "center", marginTop: 36 }}
            >
              <View style={styles.wrapButton}>
                <Touchable onPress={_saveDateTime}>
                  <View style={[styles.button, { height: 38 }]}>
                    <Text style={styles.textButton}>
                      {t("activity.new.save")}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
};

export default Hotel;
