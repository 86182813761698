import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import styles from "./styles";
import { Fixed, Header, Touchable, WrapContent } from "../../../components";
import { IMAGES } from "../../../configs";
import Hotel from "./Hotel";
import Flight from "./Flight";
import { Footer } from "../components";
import {
  getAirline,
  getListAirport,
  getListCountry,
  getPolicies,
  getRegion,
  getSource,
  getTermDataActivity,
  getVendor,
  postGenerateAllowance,
  postSubmitActivity,
  postTermDataActivity
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { copyWritings, toast } from "../../../utils";

const Booking = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = history.location;
  const listPages = [
    {
      label: "Hotel",
      component: <Hotel />,
      exact: true,
      pathname: match.path,
      icon: IMAGES.hotel
    },
    {
      label: t("activity.booking.flight"),
      component: <Flight />,
      exact: false,
      pathname: `${match.path}/flight`,
      icon: IMAGES.flight
    }
  ];

  const [heightHeader, setHeightHeader] = useState(0);
  const [heightFooter, setHeightFooter] = useState(0);
  const { tripState, sourceState } = useSelector(
    (state: Reducers) => ({ tripState: state.trip, sourceState: state.source }),
    shallowEqual
  );

  useEffect(() => {
    if (tripState.activity.getData) {
      dispatch(getTermDataActivity());
    }

    if (
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.trip
    ) {
      dispatch(postGenerateAllowance());
      dispatch(
        getListAirport(
          tripState.activity.data.new_activity.trip.origin_country_code,
          "departure"
        )
      );
    }
  }, [
    dispatch,
    tripState.activity.data.new_activity,
    tripState.activity.getData
  ]);

  useEffect(() => {
    dispatch(getVendor());
    dispatch(getSource());
    dispatch(getPolicies());
    dispatch(getRegion());
    dispatch(getAirline());
    dispatch(getListCountry());
  }, [dispatch]);

  const _handleSubmit = useCallback(() => {
    let isError = false;
    let bookings: any[] = [];
    if (tripState.activity.data.bookingHotels) {
      bookings = [...bookings, ...tripState.activity.data.bookingHotels];
    }
    if (tripState.activity.data.bookingFlights) {
      bookings = [...bookings, ...tripState.activity.data.bookingFlights];
    }
    bookings.forEach((e: any) => {
      if (e.invoices.length === 0) {
        isError = true;
      }
    });

    if (isError) {
      toast.error(t("activity.invoiceReq"));
    } else {
      dispatch(
        postSubmitActivity(e => {
          if (e) {
            history.push("/trip");
          }
        })
      );
    }
  }, [
    dispatch,
    history,
    tripState.activity.data.bookingHotels,
    tripState.activity.data.bookingFlights
  ]);

  return (
    <View style={styles.container}>
      <Fixed getHeight={e => setHeightHeader(e)}>
        <View style={styles.container}>
          <Header
            title={copyWritings("booking")}
            label={t("activity.status.draft")}
            goBack={() => history.push("/newtrip/newexpense")}
          />
          <View style={styles.containerNav}>
            <View style={styles.wrapNav}>
              {listPages.map((item, index) => (
                <View key={index} style={{ flex: 1 }}>
                  <Touchable onPress={() => history.push(item.pathname)}>
                    <View
                      style={[
                        styles.buttonTab,
                        item.pathname === pathname && styles.activeButton
                      ]}
                    >
                      <Image source={item.icon} style={styles.icon} />
                      <Text style={styles.labelTab}>{item.label}</Text>
                    </View>
                  </Touchable>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Fixed>

      <View style={{ marginTop: heightHeader, marginBottom: heightFooter }}>
        <WrapContent
          isLoading={
            tripState.activity.isLoading ||
            tripState.allowance.isLoading ||
            tripState.isLoadingSubmitAtivity
          }
        >
          {listPages.map((item, index) => (
            <Route key={index} path={item.pathname} exact={item.exact}>
              {item.component}
            </Route>
          ))}
        </WrapContent>
      </View>

      <Fixed getHeight={e => setHeightFooter(e)} position="bottom">
        <Footer
          // textNext="SUBMIT"
          onPressNext={tripState.activity.isLoading ? undefined : _handleSubmit}
          onPressSave={
            !tripState.activity.isLoading && !sourceState.isLoadingSource
              ? () => dispatch(postTermDataActivity())
              : undefined
          }
        />
      </Fixed>
    </View>
  );
};

export default Booking;
