import React, { useEffect } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import { Card, Touchable, WrapContent, WrapList } from "../../../components";
import { IMAGES } from "../../../configs";
import { Reducers } from "../../../redux/types";
import {
  deleteBookingById,
  getListBookingFlight
} from "../../../redux/actions";
import { convertDate, documentTitle, maskedMoney } from "../../../utils";

interface Props {
  bottom: number;
  id: string;
}

const Flight = ({ bottom, id }: Props) => {
  documentTitle("Booking - Flight");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getListBookingFlight(id));
  }, [dispatch]);
  const bookingState = useSelector((state: Reducers) => state.booking);

  const _splitDate = (date: string) =>
    convertDate(date, "ddd,DD MMM YYYY,hh:mm").split(",");

  const _delete = (idBook: string) => {
    dispatch(deleteBookingById(idBook));
  };

  return (
    <View style={{ marginHorizontal: 20, flex: 1 }}>
      <WrapContent isLoading={bookingState.isLoadingListBookingFlight}>
        <WrapList
          isEmpty={bookingState.listBookingFlight.length === 0}
          emptyComponent={() => (
            <Touchable
              onPress={() => history.push(`/new-booking-flight/${id}`)}
            >
              <Image
                resizeMode="contain"
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.bookingFlight}
              />
            </Touchable>
          )}
        >
          <FlatList
            data={bookingState.listBookingFlight}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <Card key={index.toString()} cardStyle={styles.cardDescription}>
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1 }}>
                      <View style={[styles.desciption, { marginBottom: 0 }]}>
                        <Text style={styles.titleDescription}>Airlines</Text>
                      </View>
                      <Text style={styles.titleHotel}>{item.data.name}</Text>
                    </View>

                    <View>
                      <Touchable onPress={() => _delete(item.id)}>
                        <View style={styles.wrapButtonTrash}>
                          <Image
                            source={IMAGES.trashBin}
                            style={{ width: 13, height: 16 }}
                          />
                        </View>
                      </Touchable>
                    </View>
                  </View>

                  <View style={styles.marginVertical}>
                    <View style={styles.desciption}>
                      <Text style={styles.titleDescription}>Flight 1</Text>
                      <View style={{ width: "75%" }}>
                        <Text style={[styles.descDate]}>
                          {`${item.data.origin} - ${item.data.destination}`}
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[styles.descDate]}>
                            {`${_splitDate(item.data.departure_date)[0]} `}
                          </Text>
                          <Text
                            style={[styles.descDate, { fontWeight: "bold" }]}
                          >
                            {`${_splitDate(item.data.departure_date)[1]} `}
                          </Text>
                          <Text style={[styles.descDate]}>
                            {item.data.departure_time_range}
                          </Text>
                        </View>
                        <Text style={[styles.descDate]}>{item.data.class}</Text>
                      </View>
                    </View>
                    {item.data["one-way-trip"] === false && (
                      <View style={[styles.desciption, { marginTop: 10 }]}>
                        <Text style={styles.titleDescription}>Flight 2</Text>
                        <View style={{ width: "75%" }}>
                          <Text style={[styles.descDate]}>
                            {`${item.data.destination} - ${item.data.origin}`}
                          </Text>
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.descDate]}>
                              {`${_splitDate(item.data.return_date)[0]} `}
                            </Text>
                            <Text
                              style={[styles.descDate, { fontWeight: "bold" }]}
                            >
                              {`${_splitDate(item.data.return_date)[1]} `}
                            </Text>
                            <Text style={[styles.descDate]}>
                              {item.data.return_time_range}
                            </Text>
                          </View>
                          <Text style={[styles.descDate]}>
                            {item.data.class}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>
                  {/* <View style={[styles.desciption, styles.marginVertical]}>
                    <Text style={styles.titleDescription}>Facility</Text>
                    <Text style={styles.detailDescription}>
                      {item.facility.map(
                        (itemFacility: string, indexFacility: number) =>
                          `${itemFacility}${
                            indexFacility !== item.facility.length - 1
                              ? ","
                              : ""
                          } `
                      )}
                    </Text>
                  </View> */}
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Total Cost</Text>
                    <Text
                      style={[styles.detailDescription, { fontWeight: "bold" }]}
                    >
                      {`IDR ${maskedMoney(
                        item.djurneeBooking.prediction_total
                      )}`}
                    </Text>
                  </View>
                </View>
              </Card>
            )}
          />
        </WrapList>

        {bookingState.listBookingFlight.length > 0 && (
          <div
            style={{
              position: "sticky",
              bottom,
              padding: 20,
              paddingTop: 5,
              alignSelf: "flex-end"
            }}
          >
            <Touchable
              onPress={() => history.push(`/new-booking-flight/${id}`)}
            >
              <Image
                resizeMode="contain"
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.bookingFlight}
              />
            </Touchable>
          </div>
        )}
      </WrapContent>
    </View>
  );
};

export default Flight;
