import React from "react";
import { Text, View } from "react-native";

import { Datepicker, Touchable } from "../../components";
import styles from "./styles";

const Coba = () => {
  return (
    <Datepicker
      value={[
        {
          date: "2020-01-01",
          time: "12:00",
          traveling: {
            departure: "jakarta",
            arrival: "denpasar"
          }
        },
        {
          date: "2020-01-03",
          time: "12:00",
          traveling: {
            departure: "depasar",
            arrival: "jakarta"
          }
        }
      ]}
      timepicker
      traveling
      head={(nav, setNav) => (
        <View style={styles.datePickerNavWrap}>
          <Touchable
            onPress={() => {
              if (setNav) setNav(0);
            }}
          >
            <Text>0</Text>
          </Touchable>
          <Touchable
            onPress={() => {
              if (setNav) setNav(1);
            }}
          >
            <Text>1</Text>
          </Touchable>
        </View>
      )}
    >
      <Text>nice</Text>
    </Datepicker>
  );
};

export default Coba;
