import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  tripDetailContainer: {
    flex: 1
  },
  tripDetailContent: {
    paddingHorizontal: 20
  },
  desciption: {
    flexDirection: "row",
    marginTop: 11,
    paddingLeft: 20
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%"
  },
  noteStyle: {
    paddingLeft: 22,
    paddingRight: 50,
    marginTop: 13,
    paddingTop: 18,
    paddingBottom: 12,
    marginBottom: 15
  },
  cardAttchmen: {
    overflow: "hidden",
    marginHorizontal: 10,
    marginBottom: 2
  },
  cardAttchmenAdd: {
    justifyContent: "center",
    paddingHorizontal: 25
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 100
  },
  modalStyle: {
    justifyContent: "flex-start"
  }
});

export default styles;
