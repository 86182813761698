import { Slide, toast } from "react-toastify";

const toastFunction = {
  success: (message: string) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  },
  error: (message: string) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  }
};

export default toastFunction;
