import React, { ChangeEvent } from "react";
import { autoGrowTextArea } from "../../utils";

import "./styles.css";

type TypeHeight = "one-row" | "two-row";
type TypeFontSize = "small" | "medium";

interface Props {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  placeholder?: string;
  withPadding?: boolean;
  withPaddingSm?: boolean;
  bold?: boolean;
  autoFocus?: boolean;
  defaultHeight?: TypeHeight;
  fontSize?: TypeFontSize;
  border?: boolean;
  disabled?: boolean;
}

const TextArea = ({
  value,
  onChange,
  placeholder,
  withPadding,
  withPaddingSm,
  bold,
  autoFocus,
  defaultHeight,
  fontSize,
  border,
  disabled
}: Props) => {
  return (
    <textarea
      disabled={disabled}
      className={`text-area-container ${withPadding && "pad-left"} ${
        withPaddingSm && "pad-leftSm"
      } ${bold && "bold-input"} ${defaultHeight === "one-row" && "one-row"} ${
        fontSize === "small" && "font-small"
      } ${border ? "text-area-container-border" : ""} ${
        disabled && "disabled-text-area"
      }`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      ref={e => {
        autoFocus && setTimeout(() => e && e.focus(), 100);
      }}
      onInput={e => autoGrowTextArea(e, defaultHeight === "one-row" ? 19 : 38)}
    />
  );
};

TextArea.defaultProps = {
  defaultHeight: "two-row",
  fontSize: "medium",
  border: true
};

export default TextArea;
