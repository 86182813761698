import { Dispatch, GetEmployeeV2Params, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_LIST_EMPLOYEE_PENDING = "GET_LIST_EMPLOYEE_PENDING";
export const GET_LIST_EMPLOYEE_SUCCESS = "GET_LIST_EMPLOYEE_SUCCESS";
export const GET_LIST_EMPLOYEE_ERROR = "GET_LIST_EMPLOYEE_ERROR";
export const REFRESH_LIST_EMPLOYEE = "REFRESH_LIST_EMPLOYEE";

export const GET_MY_FULL_PROFILE_PENDING = "GET_MY_FULL_PROFILE_PENDING";
export const GET_MY_FULL_PROFILE_SUCCESS = "GET_MY_FULL_PROFILE_SUCCESS";
export const GET_MY_FULL_PROFILE_ERROR = "GET_MY_FULL_PROFILE_ERROR";

export const GET_DEPARTMENT_PENDING = "GET_DEPARTMENT_PENDING";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";

export const GET_POSITION_PENDING = "GET_POSITION_PENDING";
export const GET_POSITION_SUCCESS = "GET_POSITION_SUCCESS";
export const GET_POSITION_ERROR = "GET_POSITION_ERROR";

export const EDIT_PROFILE_PENDING = "EDIT_PROFILE_PENDING";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const GET_EMPLOYEE_PENDING = "GET_EMPLOYEE_PENDING";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";
export const GET_REQUEST_EMPLOYEE_PENDING = "GET_REQUEST_EMPLOYEE_PENDING";
export const GET_REQUEST_EMPLOYEE_SUCCESS = "GET_REQUEST_EMPLOYEE_SUCCESS";
export const GET_REQUEST_EMPLOYEE_ERROR = "GET_REQUEST_EMPLOYEE_ERROR";

export const REFRESH_EMPLOYEE = "REFRESH_EMPLOYEE";

export const getEmployee = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LIST_EMPLOYEE_PENDING });

    const res = await API.getEmployee(auth.token);
    dispatch({
      type: GET_LIST_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getEmployee())));
      } else {
        dispatch({
          type: GET_LIST_EMPLOYEE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LIST_EMPLOYEE_ERROR });
  }
};

export const refreshEmployee = (list: any[]) => async (dispatch: Dispatch) => {
  dispatch({ type: REFRESH_LIST_EMPLOYEE, payload: { data: list } });
};

export const getMyFullProfile = (uuid: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MY_FULL_PROFILE_PENDING });

    const res = await API.getMyFullProfile(uuid, auth.token);
    dispatch({
      type: GET_MY_FULL_PROFILE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getMyFullProfile(uuid))));
      } else {
        dispatch({
          type: GET_MY_FULL_PROFILE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_MY_FULL_PROFILE_ERROR });
  }
};

export const getEmployeeV2 = (params: GetEmployeeV2Params = {}) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_EMPLOYEE_PENDING });

    const res = await API.getEmployeeV2(auth.token, {
      ...params,
      per_page: 20
    });
    dispatch({
      type: GET_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getEmployeeV2(params))));
      } else {
        dispatch({
          type: GET_EMPLOYEE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_EMPLOYEE_ERROR });
  }
};

export const getEmployeeExceptSelf = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_REQUEST_EMPLOYEE_PENDING });

    const res = await API.getEmployeeExceptSelf(auth.token);
    dispatch({
      type: GET_REQUEST_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getEmployeeExceptSelf())));
      } else {
        dispatch({
          type: GET_REQUEST_EMPLOYEE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_REQUEST_EMPLOYEE_ERROR });
  }
};

export const getDepartment = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DEPARTMENT_PENDING });

    const res = await API.getDepartment(auth.token);
    dispatch({
      type: GET_DEPARTMENT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDepartment())));
      } else {
        dispatch({
          type: GET_DEPARTMENT_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_DEPARTMENT_ERROR });
  }
};

export const getPosition = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POSITION_PENDING });

    const res = await API.getPosition(auth.token);
    dispatch({
      type: GET_POSITION_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPosition())));
      } else {
        dispatch({
          type: GET_POSITION_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_POSITION_ERROR });
  }
};

export const editProfile = (body: any, employeeId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: EDIT_PROFILE_PENDING });

    const res = await API.editProfile(body, employeeId, auth.token);
    dispatch({
      type: EDIT_PROFILE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(editProfile(body, employeeId))));
      } else {
        dispatch({
          type: EDIT_PROFILE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: EDIT_PROFILE_ERROR });
  }
};
