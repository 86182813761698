import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_VENDOR_PENDING = "GET_VENDOR_PENDING";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_ERROR = "GET_VENDOR_ERROR";

export const GET_VENDOR_DETAIL_PENDING = "GET_VENDOR_DETAIL_PENDING";
export const GET_VENDOR_DETAIL_SUCCESS = "GET_VENDOR_DETAIL_SUCCESS";
export const GET_VENDOR_DETAIL_ERROR = "GET_VENDOR_DETAIL_ERROR";

export const getVendor = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_VENDOR_PENDING });

    const res = await API.getVendor(auth.token);
    dispatch({
      type: GET_VENDOR_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getVendor())));
      } else {
        dispatch({
          type: GET_VENDOR_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_VENDOR_ERROR });
  }
};

export const getVendorDetail = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_VENDOR_DETAIL_PENDING });

    const res = await API.getVendorDetail(id, auth.token);
    dispatch({
      type: GET_VENDOR_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getVendorDetail(id))));
      } else {
        dispatch({
          type: GET_VENDOR_DETAIL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_VENDOR_DETAIL_ERROR });
  }
};
