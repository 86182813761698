import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    flex: 1
  },
  font: {
    fontSize: 11,
    color: COLORS.grey,
    fontFamily: "Poppins"
  },
  bold: {
    fontWeight: "bold"
  },
  fontMedium: {
    fontSize: 14,
    color: COLORS.grey,
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  wrapForm: {
    marginVertical: 6
  },
  wrapFormRow: {
    marginVertical: 6,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  label: {
    paddingLeft: 12
  },
  error: {
    borderBottomColor: COLORS.orange
  },
  labelError: {
    color: COLORS.orange,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  bottomError: {
    color: COLORS.orange,
    textAlign: "center"
  },
  cardForm: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    marginTop: 2
  },
  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginTop: 4,
    marginBottom: 2,
    outlineColor: "rgba(255,255,255,0)"
  },
  row: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight
  },
  green: {
    color: COLORS.green
  },
  orange: {
    color: COLORS.orange
  },
  wrapSaveBtn: {
    position: "absolute",
    alignItems: "center",
    bottom: 24,
    alignSelf: "center"
  },
  wrapWarnSymbol: {
    backgroundColor: COLORS.greyLight,
    height: 28,
    width: 28,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  symbol: {
    color: COLORS.white,
    fontSize: 20
  },
  light: {
    color: COLORS.greyLight
  },
  lightBold: {
    color: COLORS.greyLight,
    fontWeight: "bold"
  },
  wrapAttch: {
    marginTop: 8,
    width: "100%"
  }
});

export default styles;
