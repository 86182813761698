import React, { useCallback, useEffect } from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { IMAGES } from "../../../configs";
import styles from "./styles";
import "./styles.css";
import {
  copyWritings,
  documentTitle,
  permissionPage,
  searchListPermission
} from "../../../utils";
import {
  getCompanyAddress,
  getMenu,
  getMyPermission,
  getMySettingGeneral,
  setStatusLoan
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { Container, Touchable } from "../../../components";

const Home = () => {
  documentTitle("Home");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyPermission());
    dispatch(getMenu("general"));
    dispatch(getMySettingGeneral());
    dispatch(getCompanyAddress());
  }, [dispatch]);
  const { t } = useTranslation();

  const homeState = useSelector((state: Reducers) => state.home);

  const myPermissionSelfList = permissionPage("invoice-self-list");

  const tripSelfList = permissionPage("trip-self-list");
  const tripOwnedList = permissionPage("trip-owned-list");
  const tripOthersList = permissionPage("trip-others-list");
  const tripSelfView = permissionPage("trip-self-view");
  const tripOwnedView = permissionPage("trip-Owned-view");
  const tripOthersView = permissionPage("trip-others-view");
  const tripSelfDelete = permissionPage("trip-self-delete");
  const tripOwnedDelete = permissionPage("trip-owned-delete");
  const tripOthersDelete = permissionPage("trip-others-delete");
  const tripSelfEdit = permissionPage("trip-self-edit");
  const tripOwnedEdit = permissionPage("trip-owned-edit");
  const tripOthersEdit = permissionPage("trip-others-edit");
  const tripCreate = permissionPage("trip-create");
  const tripAssign = permissionPage("trip-assign");

  const copTeks = {
    cash: copyWritings("invoice"),
    task: copyWritings("task"),
    trip: copyWritings("trip")
  };

  const dataMenu: any = useCallback(
    (type: "Cash" | "Task" | "Cash Loan" | "Trip") => {
      let res = {};
      const data =
        homeState.listMenu &&
        homeState.listMenu.find(el => el.title === "ACTIVITY")?.childrens;
      if (type === "Cash") {
        res = data && data.find((item: any) => item.slugMobile === "Cash");
      }
      if (type === "Task") {
        res = data && data.find((item: any) => item.slugMobile === "Task");
      }
      if (type === "Trip") {
        res = data && data.find((item: any) => item.slugMobile === "Trip");
      }
      if (type === "Cash Loan") {
        res = data && data.find((item: any) => item.slugMobile === "Cash Loan");
      }
      return res;
    },
    [homeState.listMenu]
  );

  const SearchListMenu = (slug: string) => {
    const res = homeState?.listMenu?.filter((el: any) =>
      el.title.includes(slug)
    );
    return res;
  };

  const feature = [
    {
      title: copTeks.task,
      subtitle: t("home.desc_task"),
      detail: t("home.task_notif"),
      linkTo: "/task",
      cover: IMAGES.coverTask,
      notification: dataMenu("Task")?.notification,
      disable: searchListPermission("task-").length < 1,
      greyOut: false
    },
    {
      title: copTeks.trip,
      subtitle: t("home.desc_activity"),
      detail: t("home.activity_notif"),
      linkTo: "/trip",
      cover: IMAGES.coverActivity,
      notification: dataMenu("Trip")?.notification,
      disable: !(
        tripSelfList ||
        tripSelfView ||
        tripSelfEdit ||
        tripSelfDelete ||
        tripOthersList ||
        tripOthersView ||
        tripOthersEdit ||
        tripOthersDelete ||
        tripOwnedList ||
        tripOwnedView ||
        tripOwnedEdit ||
        tripOwnedDelete ||
        tripAssign ||
        tripCreate
      ),
      greyOut: false
    },
    {
      title: copTeks.cash,
      subtitle: t("home.desc_cash"),
      detail: t("home.cash_notif"),
      linkTo: myPermissionSelfList ? "/cash" : "cash/approve",
      cover: IMAGES.coverCash,
      notification: dataMenu("Cash")?.notification,
      disable: searchListPermission("invoice-").length < 1,
      greyOut: false
    },
    {
      title: t("home.loan"),
      subtitle: t("home.desc_loan"),
      detail: t("home.loan_notif"),
      linkTo: "/loan/user",
      cover: IMAGES.coverCash,
      notification: dataMenu("Cash Loan")?.notification,
      disable: SearchListMenu("PERSONAL LOAN")?.length < 1,
      greyOut: true
    }
  ];

  return (
    <Container style={styles.listWrapper}>
      <View style={styles.scrollView}>
        {feature.map((item, index) => (
          <>
            {!item.disable && (
              <View
                key={index}
                style={[styles.listItem, item.greyOut && { opacity: 0.4 }]}
                // style={[styles.listItem, index === 0 && { flexGrow: 1 }]}
              >
                <Touchable
                  onPress={e => {
                    e.stopPropagation();
                    if (item.linkTo === "/loan/user") {
                      dispatch(setStatusLoan(3));
                    }
                    history.push(item.linkTo);
                  }}
                  disabled={item.disable || item.greyOut}
                  width="100%"
                >
                  <View
                    style={{
                      flexDirection: "row",
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    {item.disable ? (
                      <View
                        style={{
                          backgroundColor: "#66666650",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          zIndex: 1
                        }}
                      />
                    ) : null}
                    {/* <Image
                      source={item.cover}
                      style={[styles.listItemImage, { zIndex: -1 }]}
                    /> */}
                    <>
                      <img src={item.cover} alt="" className="listItemImage" />
                    </>
                    <View style={[styles.wrapTitle]}>
                      <View>
                        <Text
                          style={[
                            styles.listItemTextTitle,
                            index === 0 && { marginTop: 45 }
                          ]}
                        >
                          {item.title}
                        </Text>
                        <Text style={styles.listItemTextSubtitle}>
                          {item.subtitle}
                        </Text>
                      </View>
                      {!homeState.isLoadingMenu ? (
                        <View style={{ flexDirection: "row" }}>
                          {item.notification > 0 ? (
                            <View style={styles.detailBubble}>
                              <Text style={styles.detailBubleText}>
                                {item.notification}
                              </Text>
                            </View>
                          ) : (
                            <Image
                              source={IMAGES.checkList}
                              style={{
                                width: 14,
                                height: 13,
                                marginRight: 5
                              }}
                            />
                          )}
                          <Text style={styles.detailText}>
                            {item.notification > 0
                              ? item.detail
                              : t("home.null_notif")}
                          </Text>
                        </View>
                      ) : (
                        <ActivityIndicator color="#666" size="small" />
                      )}
                    </View>
                  </View>
                </Touchable>
              </View>
            )}
          </>
        ))}
      </View>
    </Container>
  );
};

export default Home;
