import { StyleSheet } from "react-native";
import { widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20
  },
  inputStyle: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 2,
    marginLeft: -2
  },
  wrapInput: {
    marginBottom: 20
  },
  wrapRow: {
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  wrapInputRow: {
    flex: 1
  },
  wrapCurency: {
    width: "20%"
  },
  wrapBtn: {
    paddingHorizontal: 24
  },
  button: {
    borderRadius: 100,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",
    marginBottom: 24,
    width: widthPercent(100) - 48,
    height: 45
  }
});

export default styles;
