import {
  GET_POLICIES_ERROR,
  GET_POLICIES_PENDING,
  GET_POLICIES_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, PoliciesState } from "../types";

const initialState: PoliciesState = {
  policies: [],
  isLoadingPolicies: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_POLICIES_PENDING:
      return { ...state, isLoadingPolicies: true };
    case GET_POLICIES_SUCCESS:
      return { ...state, isLoadingPolicies: false, policies: payload.data };
    case GET_POLICIES_ERROR:
      return { ...state, isLoadingPolicies: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
