/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback } from "react";
import { Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Touchable, WrapContent } from "../../../../components";
import {
  convertDate,
  copyWritings,
  documentTitle,
  maskedMoney,
  toast
} from "../../../../utils";
import { ICONS } from "../../../../configs";
import styles from "./styles";
import { handleFormAcitivy } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

const CashAdvance = () => {
  documentTitle("New Expense - Cash Advance");
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const tripState = useSelector((state: Reducers) => state.trip);

  const _handleDelete = useCallback(
    (index: number) => {
      dispatch(
        handleFormAcitivy(
          "cash_advance",
          tripState.activity.data.cash_advance.filter(
            (e: any, i: number) => i !== index
          )
        )
      );
      toast.error(t("activity.booking.deleted"));
    },
    [tripState.activity.data, dispatch]
  );

  return (
    <WrapContent isLoading={tripState.activity.isLoading}>
      <View style={styles.container}>
        {tripState.activity.data.cash_advance &&
          tripState.activity.data.cash_advance.length > 0 &&
          tripState.activity.data.cash_advance.map(
            (item: any, index: number) => (
              <Touchable
                key={index}
                onPress={() => history.push(`/newtrip/newcashadvance/${index}`)}
              >
                <View style={styles.card}>
                  <View style={styles.cardDesc}>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.detail.date")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text style={styles.desc} numberOfLines={1}>
                          {(tripState.activity.data.new_activity &&
                            tripState.activity.data.new_activity.trip &&
                            tripState.activity.data.new_activity.trip
                              .started_at &&
                            convertDate(
                              tripState.activity.data.new_activity.trip
                                .started_at,
                              "DD MMM YYYY"
                            )) ||
                            "-"}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.new.subject")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text style={styles.desc} numberOfLines={1}>
                          {item.title}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.new.description")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text style={styles.desc} numberOfLines={1}>
                          {item.description}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={styles.footerCard}>
                    <Text style={styles.textNom}>
                      <Text style={{ fontWeight: "bold" }}> </Text>
                    </Text>
                    <Text style={styles.textNom}>
                      IDR
                      <Text style={{ fontWeight: "bold" }}>
                        {` ${maskedMoney(
                          Number(item.amount) * Number(item.quantity)
                        )}`}
                      </Text>
                    </Text>
                  </View>

                  <View style={styles.wrapButtonTrash}>
                    <Touchable
                      onPress={e => {
                        e.stopPropagation();
                        _handleDelete(index);
                      }}
                    >
                      <View style={styles.buttonTrash}>
                        <Image
                          source={ICONS.trash}
                          style={{ width: 12, height: 12 }}
                        />
                      </View>
                    </Touchable>
                  </View>
                </View>
              </Touchable>
            )
          )}

        <View style={{ alignItems: "center" }}>
          <View style={styles.wrapButtonCA}>
            <Touchable onPress={() => history.push("/newtrip/newcashadvance")}>
              <View style={styles.buttonCA}>
                <Image source={ICONS.plus} style={styles.iconPlus} />
                <Text style={styles.textAddCA}>
                  {sprintf(
                    t("activity.detail.newCashAdvance"),
                    copyWritings("cash_advance")
                  )}
                </Text>
              </View>
            </Touchable>
          </View>
        </View>

        <View style={{ paddingHorizontal: 16 }}>
          <View style={styles.wrapTextFooter}>
            <Text style={styles.titleFooter}>
              {sprintf(
                t("activity.detail.totalLumpSum"),
                copyWritings("cash_advance")
              )}
            </Text>
            <Text style={styles.descFooter}>
              IDR
              <Text style={{ fontWeight: "bold" }}>
                {` ${maskedMoney(
                  tripState.activity.data.cash_advance &&
                    tripState.activity.data.cash_advance.reduce(
                      (a: any, b: any) =>
                        a + Number(b.amount) * Number(b.quantity) || 0,
                      0
                    )
                )}`}
              </Text>
            </Text>
          </View>

          {/* <View style={styles.wrapTextFooter}>
            <Text style={styles.titleFooter}>Ongoing</Text>
            <Text style={styles.descFooter}>
              IDR
              <Text style={{ fontWeight: "bold" }}>
                {` ${maskedMoney(100000)}`}
              </Text>
            </Text>
          </View>

          <View style={styles.wrapTextFooter}>
            <Text style={styles.titleFooter}>Remaining</Text>
            <Text style={[styles.descFooter, { color: COLORS.green }]}>
              IDR
              <Text style={{ fontWeight: "bold" }}>
                {` ${maskedMoney(1000000)}`}
              </Text>
            </Text>
          </View> */}
        </View>
      </View>
    </WrapContent>
  );
};

export default CashAdvance;
