import { StyleSheet } from "react-native";
// import { widthPercent } from "../../../utils";

// import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  attributeTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#666666"
  },
  valueTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#666666",
    fontWeight: "bold",
    width: "60%"
  },
  containerContent: {
    flexDirection: "row",
    alignItems: "center",
    width: "80%"
  },
  boldOrange: {
    fontWeight: "bold",
    color: "#F05A28"
  },
  cardImage: {
    height: 60,
    width: 60,
    borderRadius: 100,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
