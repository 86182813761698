import React from "react";
import { ScrollView, Text, View } from "react-native";
import { ToastContainer } from "react-toastify";
import i18n from "i18next";

import Message from "../../Message";
import { Button, Touchable } from "../..";

import styles from "./styles";
import "./style.css";
import { formatMoney, widthPercent } from "../../../utils";

interface Props {
  onCancel?: () => void;
  onPress?: () => void;
  data: any[];
  isLoading: boolean;
  items?: any[];
}

const ModalConfirmForm = ({
  onCancel,
  onPress,
  data,
  isLoading,
  items
}: Props) => {
  const _renderAmount = () => {
    let total = 0;
    const list = items?.filter(item => item.tax_id === null);
    if (list && list?.length > 0) {
      list?.forEach(e => {
        total += Number(e.amount) * Number(e.quantity);
      });
    }
    return total;
  };

  const _renderTax = () => {
    let total = 0;
    const list = items?.filter(item => item.tax_id !== null);
    if (list && list?.length > 0) {
      list?.forEach(e => {
        total += Number(e.amount) * Number(e.quantity);
      });
    }
    return total;
  };

  const _renderTotal = () => {
    let total = 0;
    if (items && items?.length > 0) {
      items?.forEach(e => {
        total += Number(e.amount) * Number(e.quantity);
      });
    }
    return total;
  };

  const _renderContent = (title: string, value: any, index: number) => {
    if (Array.isArray(value[0]) && value[0].length > 0) {
      return (
        <View>
          <Text style={styles.font}>{title}</Text>
          <ScrollView
            style={{ flex: 1 }}
            horizontal
            showsHorizontalScrollIndicator={false}
          >
            <table
              style={{ flex: 1, minWidth: widthPercent(100) - 40 }}
              className="data-table"
            >
              <tbody>
                {value.map((e: any, i: number) =>
                  i === 0 ? (
                    <tr key={i}>
                      {e.map((item: any, num: number) => (
                        <th key={num}>{item}</th>
                      ))}
                    </tr>
                  ) : (
                    <tr key={i}>
                      {e.map((item: any, num: number) => (
                        <td key={num}>
                          <Text style={styles.font}>{item}</Text>
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </ScrollView>
        </View>
      );
    }
    if (Array.isArray(value)) {
      return (
        <View key={index} style={{ flexDirection: "row" }}>
          <Text numberOfLines={1} style={[styles.font, styles.label]}>
            {title}
          </Text>
          <Text style={[styles.font, styles.value]}>
            {`${Array.isArray(value) ? value.join(", ") : value || "-"}`}
          </Text>
        </View>
      );
    }
    return (
      <View key={index} style={{ flexDirection: "row" }}>
        <Text numberOfLines={1} style={[styles.font, styles.label]}>
          {title}
        </Text>
        <Text style={[styles.font, styles.value]}>{value}</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <ToastContainer limit={1} />
      <View style={styles.wrapHeaderTitle}>
        <Text style={styles.headerTitle}>{i18n.t("confirmSubmit")}</Text>
        <View style={styles.wrapMesaage}>
          <Message message={i18n.t("confirmMsg")} />
        </View>
      </View>
      <View style={styles.wrapContent}>
        {data &&
          data.map((item, index) =>
            _renderContent(item.title, item.value, index)
          )}
      </View>
      <View style={{ alignSelf: "flex-end" }}>
        {_renderAmount() > 0 && _renderTax() !== 0 && (
          <View style={{ flexDirection: "row" }}>
            <Text style={[styles.font, styles.totalTitle]}>Jumlah</Text>
            <Text style={[styles.font, { fontWeight: "bold" }]}>
              {formatMoney(_renderAmount())}
            </Text>
          </View>
        )}
        {_renderAmount() > 0 && _renderTax() !== 0 && (
          <View style={{ flexDirection: "row" }}>
            <Text style={[styles.font, styles.totalTitle]}>Tax</Text>
            <Text style={[styles.font, { fontWeight: "bold" }]}>
              {formatMoney(_renderTax())}
            </Text>
          </View>
        )}
        {_renderTotal() > 0 && (
          <View style={{ flexDirection: "row" }}>
            <Text style={[styles.font, styles.totalTitle]}>Total</Text>
            <Text style={[styles.font, { fontWeight: "bold" }]}>
              {formatMoney(_renderTotal())}
            </Text>
          </View>
        )}
      </View>
      <View
        style={{
          alignItems: "center",
          marginTop: 60
        }}
      >
        <View style={{ alignItems: "center" }}>
          <Button
            isLoading={isLoading}
            title={i18n.t("task.save")}
            style={{ marginBottom: 12 }}
            onPress={onPress}
            disabled={isLoading}
          />

          <View style={{ marginBottom: 24 }}>
            {!isLoading && (
              <Touchable onPress={onCancel}>
                <Text style={styles.cancelStyle}>{i18n.t("task.cancel")}</Text>
              </Touchable>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default ModalConfirmForm;
