import { StyleSheet } from "react-native";

import { heightPercent, widthPercent } from "../../utils";

const styles = StyleSheet.create({
  backgroundBubble: {
    width: "95%",
    height: "85%",
    marginTop: -heightPercent(10),
    marginLeft: -widthPercent(5),
    position: "absolute"
  },
  container: {
    position: "absolute",
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  imageTitle: {
    width: 180,
    height: 62
  },
  wrapTitle: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  wrapForm: {
    flex: 1.5,
    paddingHorizontal: 70,
    justifyContent: "center"
  },
  wrapButtonBack: {
    flexDirection: "row"
  },
  buttonBack: {
    padding: heightPercent(5)
  },
  iconBack: {
    width: 20,
    height: 20,
    tintColor: "#ffffff"
  },
  wrapSwitch: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 10
  },
  textRemember: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#ffffff",
    marginLeft: 5
  },
  wrapButton: {
    alignItems: "center",
    marginVertical: 44
  },
  wrapTextButton: {
    paddingVertical: 15,
    paddingHorizontal: 67
  },
  textButton: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ffffff"
  },
  buttonForgot: {
    marginTop: 12
  },
  textForgot: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#ffffff",
    textDecorationLine: "underline"
  }
});

export default styles;
