const openPreviewBase64 = (base64: string) => {
  const w: any = window.open("about:blank");
  const ext = base64.split(/[\\/;]+/)[1];

  if (w && (ext === "jpg" || ext === "jpeg" || ext === "png")) {
    w!.document.body.innerHTML = `<img src=${base64} width="100%" height="100%">`;
  } else {
    setTimeout(() => {
      w.document.body.appendChild(
        w.document.createElement("iframe")
      ).src = base64;
      w.document.getElementsByTagName("iframe")[0].style.width = "100%";
      w.document.getElementsByTagName("iframe")[0].style.height = "100%";
    }, 0);
  }
};

export default openPreviewBase64;
