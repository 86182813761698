import { Dimensions, StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const { height, width } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    height,
    width,
    paddingBottom: 20,
    paddingHorizontal: 40,
    paddingTop: 32
  },
  wrapCloseButton: {
    flex: 1,
    justifyContent: "flex-end"
  },
  poppins: {
    color: COLORS.greyMedium,
    fontFamily: "Poppins",
    fontSize: 14
  },
  bold: {
    fontWeight: "bold"
  },
  bold2: {
    fontWeight: "bold",
    textAlign: "center"
  },
  wrapContent: {
    alignItems: "center",
    marginTop: 80
  },
  wrapSwitch: {
    flexDirection: "row",
    marginBottom: 12,
    justifyContent: "center"
  },
  recurringLabel: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 8
  },
  offRecuccing: {
    opacity: 0.25
  },
  nextRecurLabel: {
    fontSize: 11,
    color: COLORS.greyLight,
    marginBottom: 4,
    textAlign: "center"
  },
  onRecurring: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.green,
    fontWeight: "bold"
  },
  recurringCard: {
    width: 180,
    paddingVertical: 4,
    alignItems: "center",
    textAlign: "center",
    marginBottom: 16
  },
  recurringTime: {
    borderWidth: 0
  }
});

export default styles;
