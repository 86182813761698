import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  content: {
    marginTop: 25,
    paddingHorizontal: 20
  },
  airlinesCard: {
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginTop: 8
  },
  airlinesWrap: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "space-between",
    marginBottom: 13
  },
  airlinesTitle: {
    marginLeft: 14,
    fontSize: 14,
    color: "#666666"
  },
  airlinesDesc: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#666666"
  },
  navigation: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    marginVertical: 20
  },
  btnNav: {
    width: "50%",
    height: "100%",
    borderRadius: 50,
    paddingVertical: 8
  },
  active: {
    borderRadius: 50,
    width: "50%",
    height: "100%",
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    paddingVertical: 8
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14
  },
  textTitleInput: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  wrapSelectVendor: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderStyle: "solid"
  },
  wrapVendor: {
    paddingHorizontal: 14
  },
  cardButton: {
    borderRadius: 40,
    marginTop: 10,
    marginBottom: 30,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  textButtonSubmit: {
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18
  }
});

export default styles;
