import React from "react";

import { Description } from "../components";
import { IMAGES } from "../../../configs";

const Third = () => (
  <Description
    image={IMAGES.introThird}
    title="List your schedule"
    desc="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
    back
  />
);

export default Third;
