import React, { useState } from "react";
import { Image, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./styles";
import { IMAGES } from "../../../configs";
import { Card, Fixed, Modal, Touchable } from "../../../components";
import { Reducers } from "../../../redux/types";
import Hotel from "./Hotel";
import Flight from "./Flight";
import NoteRevise from "../ModalNoteRevise";
import ModalResolve from "../ModalResolve";
import { documentTitle, heightPercent, queryString } from "../../../utils";

interface Props {
  id: string;
  search: string;
  top: number;
}

const Booking = ({ id, search, top }: Props) => {
  documentTitle("Trip Detail - Booking");
  const match = useRouteMatch();
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const [noteReviseOpen, setNoteReviseOpen] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const { pathname } = history.location;
  const dataTrip = queryString(search);
  const { tripData } = useSelector((state: Reducers) => state.trip);
  const listPages = [
    {
      pathname: `${match.path}`,
      component: <Hotel id={id!} />,
      exact: false
    },
    {
      pathname: `${match.path}/flight`,
      component: (
        <Flight
          id={id!}
          search={search}
          height={heightPercent(100) - (heightHeader + top)}
        />
      ),
      exact: false
    }
  ];

  const _openResolveModal = () => {
    setNoteReviseOpen(false);
    setResolveOpen(true);
  };

  return (
    <Switch>
      <View>
        <Fixed getHeight={e => setHeightHeader(e)} customPosition={{ top }}>
          <View style={styles.bookingpDetailContent}>
            <Card cardStyle={styles.cardDescription}>
              <View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Trip</Text>
                  <View style={styles.detailDescription}>
                    <Text style={[styles.titleDescription, { width: "100%" }]}>
                      IF/XII/3/D
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      Elnusa Webite & IOS
                    </Text>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#999999",
                        marginTop: 4,
                        fontFamily: "Poppins"
                      }}
                    >
                      Progress Meeting
                    </Text>
                  </View>
                </View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Team</Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      Art Vandelay, Rusty Shackeford
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    marginTop: 16,
                    paddingVertical: 2,
                    alignItems: "center"
                  }}
                >
                  <View style={styles.navigation}>
                    <View
                      style={
                        listPages[0].pathname === pathname ||
                        pathname.includes(`${listPages[0].pathname}/hotel`)
                          ? [styles.active]
                          : [styles.btnNav, { paddingRight: 20 }]
                      }
                    >
                      <Touchable
                        onPress={() =>
                          history.push(listPages[0].pathname + search)
                        }
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingVertical: 6
                          }}
                        >
                          <Text style={styles.textButton}>Hotel</Text>
                        </View>
                      </Touchable>
                    </View>

                    <View
                      style={
                        pathname.includes(listPages[1].pathname)
                          ? styles.active
                          : [styles.btnNav, { paddingLeft: 20 }]
                      }
                    >
                      <Touchable
                        onPress={() =>
                          history.push(listPages[1].pathname + search)
                        }
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingVertical: 6
                          }}
                        >
                          <Text style={styles.textButton}>Flight</Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </View>
            </Card>
          </View>
        </Fixed>
        {dataTrip.status === "4" && (
          <div
            style={{
              position: "fixed",
              zIndex: 1,
              paddingLeft: 20,
              paddingBottom: 20,
              alignSelf: "flex-start",
              bottom: 0
            }}
          >
            <Touchable onPress={() => setNoteReviseOpen(true)}>
              <Image
                style={{ width: 62.6, height: 62.6 }}
                source={IMAGES.reviseAlert}
              />
            </Touchable>
          </div>
        )}
        <Modal isVisible={noteReviseOpen} wrapStyle={styles.modalStyle}>
          <NoteRevise
            onPress={() => setNoteReviseOpen(false)}
            onPressRevise={() => _openResolveModal()}
          >
            <Text>
              {tripData !== null &&
                tripData.moderation !== null &&
                typeof tripData !== "undefined" &&
                typeof tripData.moderation !== "undefined" &&
                typeof tripData.moderation.remarks !== "undefined" &&
                tripData.moderation.remarks}
            </Text>
          </NoteRevise>
        </Modal>
        {resolveOpen && (
          <Modal isVisible={resolveOpen}>
            <ModalResolve
              cancel={() => setResolveOpen(false)}
              idTrip={`${id}`}
            />
          </Modal>
        )}

        <View style={styles.bookingpDetailContent}>
          <View
            style={{
              marginTop: heightHeader,
              minHeight: heightPercent(100) - (heightHeader + top)
            }}
          >
            {listPages.map((item, index) => (
              <Route path={item.pathname} exact={item.exact} key={index}>
                {item.component}
              </Route>
            ))}
          </View>
        </View>
      </View>
    </Switch>
  );
};
export default Booking;
