import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    marginTop: -15,
    borderRadius: 15,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginBottom: 24
  },
  tripDescBox: {
    marginHorizontal: 20,
    marginTop: 31,
    marginBottom: 20
  },
  btnReport: {
    width: 28,
    height: 28,
    backgroundColor: "white",
    marginRight: 8.4,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center"
  },
  bookingDetailContainer: {
    flex: 1
  },
  bookingpDetailContent: {
    paddingHorizontal: 20
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 12,
    paddingRight: 20
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "80%",
    fontSize: 11,
    color: "#999999"
  },
  detailDescription: {
    width: "75%"
  },
  fabStyle: {
    alignItems: "center",
    justifyContent: "center"
  },
  fabIcon: {
    width: 80,
    height: 80
  },
  footer: {
    flexDirection: "row",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "space-between",
    paddingVertical: 15
  },
  containAmount: {
    width: "50%",
    alignItems: "center"
  },
  titleAmount: {
    color: "white",
    fontSize: 11,
    marginBottom: 8
  },
  card: {
    marginHorizontal: 20,
    marginBottom: 30
  },
  header: {
    height: 44,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "center"
  },
  textHeader: {
    marginLeft: 21,
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "white"
  },
  text: {
    color: "#666666",
    marginLeft: 21,
    fontSize: 11,
    fontFamily: "Poppins"
  },
  posDesc: {
    marginTop: 20,
    marginBottom: 11,
    marginRight: 22
  },
  line: {
    borderBottomColor: "#ecf0f1",
    borderBottomWidth: 1
  }
});

export default styles;
