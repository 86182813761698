import React from "react";
import { Text, View } from "react-native";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { Button, RoundBullet, Touchable } from "../../components";
import styles from "./styles";
import { documentTitle } from "../../utils";

// screens
import First from "./First";
import Second from "./Second";
import Third from "./Third";

const Intro = () => {
  documentTitle("djurnee");
  const match = useRouteMatch();
  const history = useHistory();

  const { pathname } = history.location;
  const listPages = [
    {
      pathname: match.path,
      component: <First />
    },
    {
      pathname: `${match.path}/second`,
      component: <Second />
    },
    {
      pathname: `${match.path}/third`,
      component: <Third />
    }
  ];

  const navigate = (link: string) => {
    history.push(link);
  };

  return (
    <Switch>
      <View>
        {listPages.map((item, index) => (
          <Route path={item.pathname} exact key={index}>
            {item.component}
          </Route>
        ))}
        <RoundBullet
          active={listPages.findIndex(e => e.pathname === pathname)}
          total={listPages.length}
        />
        <View style={styles.wrapButton}>
          {listPages.findIndex(e => e.pathname === pathname) < 2 ? (
            <Button
              title="NEXT"
              onPress={() =>
                navigate(
                  listPages[
                    listPages.findIndex(e => e.pathname === pathname) + 1
                  ].pathname
                )
              }
            />
          ) : (
            <>
              <Button title="LOGIN" onPress={() => navigate("/login")} />

              <View style={styles.buttonSignup}>
                <Touchable onPress={() => navigate("/register")}>
                  <Text style={styles.textSignup}>or signup</Text>
                </Touchable>
              </View>
            </>
          )}
        </View>
      </View>
    </Switch>
  );
};
export default Intro;
