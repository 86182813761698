import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";
import { toast } from "../../utils";

export const GET_PER_DIEM_PENDING = "GET_PER_DIEM_PENDING";
export const GET_PER_DIEM_SUCCESS = "GET_PER_DIEM_SUCCESS";
export const GET_PER_DIEM_ERROR = "GET_PER_DIEM_ERROR";

export const GET_CASH_ADVANCE_PENDING = "GET_CASH_ADVANCE_PENDING";
export const GET_CASH_ADVANCE_SUCCESS = "GET_CASH_ADVANCE_SUCCESS";
export const GET_CASH_ADVANCE_ERROR = "GET_CASH_ADVANCE_ERROR";

export const GET_BUDGET_REQ_PENDING = "GET_BUDGET_REQ_PENDING";
export const GET_BUDGET_REQ_SUCCESS = "GET_BUDGET_REQ_SUCCESS";
export const GET_BUDGET_REQ_ERROR = "GET_BUDGET_REQ_ERROR";

export const GET_REIMBURSE_PENDING = "GET_REIMBURSE_PENDING";
export const GET_REIMBURSE_SUCCESS = "GET_REIMBURSE_SUCCESS";
export const GET_REIMBURSE_ERROR = "GET_REIMBURSE_ERROR";

export const GET_EXPENSE_CASH_ADVANCE_PENDING =
  "GET_EXPENSE_CASH_ADVANCE_PENDING";
export const GET_EXPENSE_CASH_ADVANCE_SUCCESS =
  "GET_EXPENSE_CASH_ADVANCE_SUCCESS";
export const GET_EXPENSE_CASH_ADVANCE_ERROR = "GET_EXPENSE_CASH_ADVANCE_ERROR";

export const DELETE_CASH_ADVANCE_PENDING = "DELETE_CASH_ADVANCE_PENDING";
export const DELETE_CASH_ADVANCE_SUCCESS = "DELETE_CASH_ADVANCE_SUCCESS";
export const DELETE_CASH_ADVANCE_ERROR = "DELETE_CASH_ADVANCE_ERROR";

export const GET_TRIP_EXPENSE_PENDING = "GET_TRIP_EXPENSE_PENDING";
export const GET_TRIP_EXPENSE_SUCCESS = "GET_TRIP_EXPENSE_SUCCESS";
export const GET_TRIP_EXPENSE_ERROR = "GET_TRIP_EXPENSE_ERROR";
export const GET_BILL_TRIP_PENDING = "GET_BILL_TRIP_PENDING";
export const GET_BILL_TRIP_SUCCESS = "GET_BILL_TRIP_SUCCESS";
export const GET_BILL_TRIP_ERROR = "GET_BILL_TRIP_ERROR";

export const POST_BILL_TRIP_PENDING = "POST_BILL_TRIP_PENDING";
export const POST_BILL_TRIP_SUCCESS = "POST_BILL_TRIP_SUCCESS";
export const POST_BILL_TRIP_ERROR = "POST_BILL_TRIP_ERROR";

export const getPerDiem = (params: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_PER_DIEM_PENDING });

    const res = await API.getNewExpenseTripPerDiem(params, auth.token);
    dispatch({
      type: GET_PER_DIEM_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPerDiem(params))));
      } else {
        dispatch({
          type: GET_PER_DIEM_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_PER_DIEM_ERROR });
  }
};

export const getCashAdvance = (params: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_CASH_ADVANCE_PENDING });

    const res = await API.getNewExpenseTripCashAdvance(params, auth.token);
    dispatch({
      type: GET_CASH_ADVANCE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCashAdvance(params))));
      } else {
        dispatch({
          type: GET_CASH_ADVANCE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_CASH_ADVANCE_ERROR });
  }
};

export const getBudgetRequest = (params: string, lang: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BUDGET_REQ_PENDING });

    const res = await API.getBudgetReq(params, auth.token, lang);
    dispatch({
      type: GET_BUDGET_REQ_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBudgetRequest(params, lang))));
      } else {
        dispatch({
          type: GET_BUDGET_REQ_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BUDGET_REQ_ERROR });
  }
};

export const getReimburse = (params: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_REIMBURSE_PENDING });

    const res = await API.getReimburse(params, auth.token);
    dispatch({
      type: GET_REIMBURSE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getReimburse(params))));
      } else {
        dispatch({
          type: GET_REIMBURSE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_REIMBURSE_ERROR });
  }
};

export const getExpenseCashAdvance = (params: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_EXPENSE_CASH_ADVANCE_PENDING });

    const res = await API.getNewExpenseCashAdvance(params, auth.token);
    dispatch({
      type: GET_EXPENSE_CASH_ADVANCE_SUCCESS,
      payload: { data: res.data.data[0] }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getExpenseCashAdvance(params))));
      } else {
        dispatch({
          type: GET_EXPENSE_CASH_ADVANCE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_EXPENSE_CASH_ADVANCE_ERROR });
  }
};

export const deleteCashAdvance = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_CASH_ADVANCE_PENDING });

    await API.deleteCashAdvance(id, auth.token);
    dispatch({
      type: DELETE_CASH_ADVANCE_SUCCESS,
      payload: { id }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteCashAdvance(id))));
      } else {
        dispatch({
          type: DELETE_CASH_ADVANCE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_CASH_ADVANCE_ERROR });
  }
};

export const getTripExpense = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TRIP_EXPENSE_PENDING });

    const res = await API.getTripExpense(id, auth.token);
    dispatch({
      type: GET_TRIP_EXPENSE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripExpense(id))));
      } else {
        dispatch({
          type: GET_TRIP_EXPENSE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_TRIP_EXPENSE_ERROR });
  }
};

export const getBillTrip = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BILL_TRIP_PENDING });

    const res = await API.getBillTrip(id, auth.token);
    dispatch({
      type: GET_BILL_TRIP_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBillTrip(id))));
      } else {
        dispatch({
          type: GET_BILL_TRIP_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BILL_TRIP_ERROR });
  }
};

export const postBillTrip = (body: any, cb: () => void, id?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_BILL_TRIP_PENDING });

    const formData = new FormData();

    Object.keys(body).map(attribute => {
      if (body[attribute] !== "") {
        formData.append(attribute, body[attribute]);
      } else {
        formData.append(attribute, "");
      }
    });
    id && formData.append("_method", "PUT");

    const res = await API.postBillTrip(formData, auth.token, id);
    dispatch({
      type: POST_BILL_TRIP_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
    toast.success(i18n.t("cash.new.msg.toastSuccess"));
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postBillTrip(body, cb, id))));
      } else {
        toast.error(err.response.data.message);
        dispatch({
          type: POST_BILL_TRIP_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_BILL_TRIP_ERROR });
  }
};
