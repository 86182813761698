import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";
import { heightPercent, widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    width: widthPercent(100),
    height: heightPercent(100),
    backgroundColor: COLORS.white,
    paddingHorizontal: 20
  },
  headerTitle: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18,
    color: COLORS.orange,
    textTransform: "uppercase"
  },
  wrapHeaderTitle: {
    alignItems: "center",
    marginTop: 40,
    marginBottom: 20
  },
  headerImage: {
    height: 58,
    width: 58,
    alignSelf: "center"
  },
  descStyle: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "Poppins",
    color: COLORS.grey,
    opacity: 0.8,
    marginTop: 4
  },
  wrapMesaage: {
    marginTop: 10
  },
  font: {
    fontFamily: "Poppins",
    color: COLORS.grey,
    fontSize: 12
  },
  wrapContent: {
    marginBottom: 20
  },
  label: {
    width: "28%",
    marginRight: 5,
    lineHeight: 22
  },
  value: {
    width: "72%",
    lineHeight: 22
  },
  cancelStyle: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: COLORS.greyMedium,
    fontWeight: "bold"
  },
  totalTitle: {
    fontWeight: "bold",
    minWidth: 80,
    marginBottom: 2
  }
});

export default styles;
