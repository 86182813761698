import {
  GET_DEPARTEMENT_V1_ERROR,
  GET_DEPARTEMENT_V1_PENDING,
  GET_DEPARTEMENT_V1_SUCCESS,
  GET_POPULATE_CATEGORY_ERROR,
  GET_POPULATE_CATEGORY_PENDING,
  GET_POPULATE_CATEGORY_SUCCESS,
  GET_POPULATE_CHILD_PROJECT_ERROR,
  GET_POPULATE_CHILD_PROJECT_PENDING,
  GET_POPULATE_CHILD_PROJECT_REMOVE,
  GET_POPULATE_CHILD_PROJECT_SUCCESS,
  GET_POPULATE_PROJECT_ERROR,
  GET_POPULATE_PROJECT_PENDING,
  GET_POPULATE_PROJECT_SUCCESS,
  LOGOUT_SUCCESS,
  POST_NEW_TRIP_ERROR,
  POST_NEW_TRIP_PENDING,
  POST_NEW_TRIP_SUCCESS,
  PUT_EDIT_TRIP_ERROR,
  PUT_EDIT_TRIP_PENDING,
  PUT_EDIT_TRIP_SUCCESS,
  PUT_NEW_TRIP_ERROR,
  PUT_NEW_TRIP_PENDING,
  PUT_NEW_TRIP_SUCCESS,
  RESET_POPULATE_CHILD_PROJECT
} from "../actions";
import { Action, NewTripState } from "../types";

const initialState: NewTripState = {
  isLoadingPopulateCategory: false,
  listPopulateCategory: [],
  isLoadingPopulateProject: false,
  isLoadingPopulateChildProject: false,
  listPopulateProject: [],
  listPopulateChildProject: [],
  isLoadingPostNewTrip: false,
  isLoadingEditTrip: false,
  isLoadingPutEditTrip: false,
  isLoadingDepartmentV1: false,
  listDepartmentV1: []
};

let listPopulateProject: any[];
let listPopulateCategory: any[];
let listPopulateChildProject: any[];
let listDepartmentV1: any[];

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // populate options category
    case GET_POPULATE_CATEGORY_PENDING:
      return {
        ...state,
        isLoadingPopulateCategory: true,
        listPopulateCategory: []
      };
    case GET_POPULATE_CATEGORY_SUCCESS:
      listPopulateCategory = [];
      payload.data.map((item: any) => {
        listPopulateCategory = [
          ...listPopulateCategory,
          { value: item.id, label: item.title || item.attributes.title }
        ];
      });
      return {
        ...state,
        isLoadingPopulateCategory: false,
        listPopulateCategory
      };
    case GET_POPULATE_CATEGORY_ERROR:
      return { ...state, isLoadingPopulateCategory: false };

    // populate option project
    case GET_POPULATE_PROJECT_PENDING:
      return {
        ...state,
        isLoadingPopulateProject: true,
        listPopulateProject: [],
        listPopulateChildProject: []
      };
    case GET_POPULATE_PROJECT_SUCCESS:
      listPopulateProject = [];
      payload.data.map((item: any) => {
        listPopulateProject = [
          ...listPopulateProject,
          { value: item.id, label: item.attributes.title }
        ];
      });
      return {
        ...state,
        isLoadingPopulateProject: false,
        listPopulateProject
      };
    case GET_POPULATE_PROJECT_ERROR:
      return { ...state, isLoadingPopulateProject: false };
    case GET_POPULATE_CHILD_PROJECT_PENDING:
      return {
        ...state,
        isLoadingPopulateChildProject: true
      };
    case GET_POPULATE_CHILD_PROJECT_SUCCESS:
      listPopulateChildProject = [...state.listPopulateChildProject];
      listPopulateChildProject[payload.index || 0] = payload.data.length
        ? payload.data.map((item: any) => ({
            value: item.id,
            label: item.attributes.title
          }))
        : [];

      for (
        let i = 0;
        i < listPopulateChildProject.length - (payload.index || 0) - 1;
        i += 1
      ) {
        listPopulateChildProject.pop();
      }

      listPopulateChildProject = listPopulateChildProject.filter(v => v.length);
      return {
        ...state,
        isLoadingPopulateChildProject: false,
        listPopulateChildProject
      };
    case GET_POPULATE_CHILD_PROJECT_REMOVE:
      listPopulateChildProject = [...state.listPopulateChildProject];
      for (
        let i = 0;
        i < listPopulateChildProject.length - (payload.index || 0) - 1;
        i += 1
      ) {
        listPopulateChildProject.pop();
      }

      listPopulateChildProject = listPopulateChildProject.filter(v => v.length);

      return {
        ...state,
        listPopulateChildProject
      };
    case GET_POPULATE_CHILD_PROJECT_ERROR:
      return { ...state, isLoadingPopulateChildProject: false };

    case RESET_POPULATE_CHILD_PROJECT:
      return {
        ...state,
        listPopulateChildProject: initialState.listPopulateChildProject
      };

    case GET_DEPARTEMENT_V1_PENDING:
      return {
        ...state,
        isLoadingDepartmentV1: true,
        listDepartmentV1: []
      };

    case GET_DEPARTEMENT_V1_SUCCESS:
      listDepartmentV1 = [{ value: "1", label: "All" }];
      payload.data.map((item: any) => {
        listDepartmentV1 = [
          ...listDepartmentV1,
          { value: item.attributes.uuid, label: item.attributes.title }
        ];
      });
      return {
        ...state,
        isLoadingDepartmentV1: false,
        listDepartmentV1
      };

    case GET_DEPARTEMENT_V1_ERROR:
      return { ...state, isLoadingDepartmentV1: false };

    // post newtrip
    case POST_NEW_TRIP_PENDING:
      return { ...state, isLoadingPostNewTrip: true };
    case POST_NEW_TRIP_SUCCESS:
      return { ...state, isLoadingPostNewTrip: false };
    case POST_NEW_TRIP_ERROR:
      return { ...state, isLoadingPostNewTrip: false };

    // put newtrip
    case PUT_NEW_TRIP_PENDING:
      return { ...state, isLoadingEditTrip: true };
    case PUT_NEW_TRIP_SUCCESS:
      return { ...state, isLoadingEditTrip: false };
    case PUT_NEW_TRIP_ERROR:
      return { ...state, isLoadingEditTrip: false };

    case PUT_EDIT_TRIP_PENDING:
      return { ...state, isLoadingPutEditTrip: true };
    case PUT_EDIT_TRIP_SUCCESS:
      return { ...state, isLoadingPutEditTrip: false };
    case PUT_EDIT_TRIP_ERROR:
      return { ...state, isLoadingPutEditTrip: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
