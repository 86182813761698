/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { WrapContent } from "../../../../components";
import styles from "./styles";
import {
  convertDate,
  copyWritings,
  documentTitle,
  maskedMoney
} from "../../../../utils";
import { Reducers } from "../../../../redux/types";

const Allowance = () => {
  documentTitle("New Expense - Allowance");

  const tripState = useSelector((state: Reducers) => state.trip);
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  return (
    <WrapContent
      isLoading={tripState.activity.isLoading || tripState.allowance.isLoading}
    >
      <View style={styles.content}>
        {tripState?.allowance?.list?.length > 0 ? (
          <>
            {tripState?.allowance?.list &&
              tripState?.allowance?.list.map((item, index) => (
                <View key={index} style={styles.row}>
                  <View style={styles.wrapDesc}>
                    <View style={styles.col1}>
                      <Text style={styles.title}>
                        {t("activity.detail.date")}
                      </Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.desc}>
                        {convertDate(item.started_at, "DD MMM YYYY")}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.wrapDesc}>
                    <View style={styles.col1}>
                      <Text style={styles.title}>
                        {t("activity.detail.type")}
                      </Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.desc}>{item.category_title}</Text>
                    </View>
                  </View>
                  <View style={styles.wrapDesc}>
                    <View style={styles.col1}>
                      <Text style={styles.title}>
                        {t("activity.detail.desc")}
                      </Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.desc}>{item.title}</Text>
                    </View>
                    <View style={styles.col3}>
                      <Text style={styles.nominal}>
                        IDR
                        <Text style={[styles.nominal, { fontWeight: "bold" }]}>
                          {` ${maskedMoney(item.price)}`}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
              ))}

            <View style={styles.wrapTotal}>
              <Text style={styles.titleTotal}>
                {sprintf(
                  t("activity.detail.totalLumpSum"),
                  copyWritings("lump_sum")
                )}
              </Text>
              <Text style={styles.nominal}>
                IDR
                <Text style={[styles.nominal, { fontWeight: "bold" }]}>
                  {` ${maskedMoney(
                    tripState.allowance.list.reduce(
                      (a, b) => a + (b.price || 0),
                      0
                    )
                  )}`}
                </Text>
              </Text>
            </View>
          </>
        ) : (
          <View>
            <Text style={styles.emptyList}>Allowance Not Found</Text>
          </View>
        )}
      </View>
    </WrapContent>
  );
};

export default Allowance;
