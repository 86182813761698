import React, { useState } from "react";
import {
  Image,
  ImageBackground,
  Text,
  TextStyle,
  View,
  ViewStyle
} from "react-native";
import { useHistory } from "react-router-dom";

import styles from "./styles";
import { COLORS, ICONS, IMAGES } from "../../configs";
import { Fixed, Gradient, Touchable } from "../index";

interface Props {
  title: string;
  subtitle?: string;
  style?: ViewStyle;
  titleHeader?: ViewStyle;
  textHeader?: TextStyle;
  subTitleHeader?: TextStyle;
  textLabel?: TextStyle;
  goBack?: () => void | boolean;
  label?: string;
  numberLineHeader?: number;
  notif?: string;
  notifBtn?: () => void;
  notifPastDueDate?: boolean;
  notifPastDueDateBtn?: () => void;
}

const Header = ({
  title,
  style,
  textHeader,
  titleHeader,
  subtitle,
  goBack,
  label,
  textLabel,
  subTitleHeader,
  numberLineHeader,
  notifBtn,
  notif,
  notifPastDueDate,
  notifPastDueDateBtn
}: Props) => {
  const history = useHistory();
  const [height, setHeight] = useState(0);

  return (
    <>
      <Fixed getHeight={value => setHeight(value)}>
        <View style={[styles.container, style]}>
          <Gradient
            backgroundImage="linear-gradient(to bottom, #50b1b1 0%, #262261 121%)"
            backgroundImageStyle={{
              flex: 1
            }}
          >
            <ImageBackground source={IMAGES.bgHeader} style={styles.bgHeader}>
              <View>
                {goBack ? (
                  <Touchable onPress={goBack}>
                    <View style={styles.buttonBack}>
                      <Image
                        source={ICONS.iconBack}
                        resizeMode="contain"
                        style={styles.iconBack}
                      />
                    </View>
                  </Touchable>
                ) : (
                  <Touchable onPress={() => history.goBack()}>
                    <View style={styles.buttonBack}>
                      <Image
                        source={ICONS.iconBack}
                        resizeMode="contain"
                        style={styles.iconBack}
                      />
                    </View>
                  </Touchable>
                )}
              </View>

              <View style={[styles.wrapTextHeader, titleHeader]}>
                <Text style={[styles.textHeader, textHeader]} numberOfLines={1}>
                  {title}
                </Text>
                <Text
                  style={[styles.textSubHeader, subTitleHeader]}
                  numberOfLines={numberLineHeader || 2}
                >
                  {subtitle}
                </Text>
              </View>

              {notif === "4" && notifBtn && (
                <Touchable
                  onPress={e => {
                    e.stopPropagation();
                    notifBtn();
                  }}
                >
                  <View style={[styles.wrapWarnIcon, { marginRight: 6 }]}>
                    <Image
                      source={IMAGES.warn}
                      style={{ width: 4, height: 16 }}
                    />
                  </View>
                </Touchable>
              )}
              {notifPastDueDate && (
                <Touchable
                  onPress={e => {
                    e.stopPropagation();
                    notifPastDueDateBtn && notifPastDueDateBtn();
                  }}
                >
                  <View style={[styles.wrapWarnIcon, { marginRight: 6 }]}>
                    <Image
                      source={IMAGES.pastDateNotif}
                      style={{ width: 50, height: 50 }}
                    />
                  </View>
                </Touchable>
              )}

              {label !== "" && (
                <View style={styles.wrapLabel}>
                  <Text style={[styles.textLabel, textLabel]}>{label}</Text>
                </View>
              )}
            </ImageBackground>
          </Gradient>
        </View>
      </Fixed>
      <View style={{ height, backgroundColor: COLORS.main }} />
    </>
  );
};

Header.defaultProps = {
  goBack: false,
  label: ""
};

export default Header;
