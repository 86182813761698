import { Dimensions, StyleSheet } from "react-native";

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    height,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20
  },
  title: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ed5a28"
  },
  buttonAttachmen: {
    marginTop: 31,
    backgroundImage: "linear-gradient(to bottom, #faaf40,#f05a28, #db3a26)",
    borderRadius: 30
  },
  image: {
    width: 58.3,
    height: 58.5
  },
  content: {
    fontFamily: "Poppins",
    marginTop: 23.4,
    paddingHorizontal: 46,
    textAlign: "center",
    fontSize: 14,
    color: "#666666"
  },
  buttonText: {
    fontWeight: "bold",
    color: "white",
    fontFamily: "Poppins",
    fontSize: 16
  },
  cancel: {
    padding: 10,
    fontSize: 16,
    color: "#707070",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  note: {
    backgroundColor: "white",
    paddingVertical: 9,
    paddingHorizontal: 14,
    width: "100%",
    borderRadius: 10,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    height: 159,
    marginTop: 12,
    lineHeight: 30,
    fontFamily: "Poppins"
  }
});

export default styles;
