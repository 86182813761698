import {
  GET_VENDOR_DETAIL_ERROR,
  GET_VENDOR_DETAIL_PENDING,
  GET_VENDOR_DETAIL_SUCCESS,
  GET_VENDOR_ERROR,
  GET_VENDOR_PENDING,
  GET_VENDOR_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, VendorState } from "../types";

const initialState: VendorState = {
  vendors: [],
  isLoadingVendor: false,
  vendorDetail: {},
  isLoadingDetailVendor: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_VENDOR_PENDING:
      return { ...state, isLoadingVendor: true };
    case GET_VENDOR_SUCCESS:
      return { ...state, isLoadingVendor: false, vendors: payload.data };
    case GET_VENDOR_ERROR:
      return { ...state, isLoadingVendor: false };

    case GET_VENDOR_DETAIL_PENDING:
      return { ...state, isLoadingDetailVendor: true };
    case GET_VENDOR_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingDetailVendor: false,
        vendorDetail: payload.data
      };
    case GET_VENDOR_DETAIL_ERROR:
      return {
        ...state,
        isLoadingDetailVendor: false,
        vendorDetail: initialState.vendorDetail
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
