/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Button,
  FileUpload,
  Header,
  Message,
  Select,
  TextArea,
  Touchable,
  Upload
} from "../../components";
import styles from "./styles";
import {
  getBudgetRemaining,
  getBudgetType,
  getPopulate,
  getTripById,
  postNewCashExpense
} from "../../redux/actions";
import { copyWritings, getPolicies, maskedMoney } from "../../utils";
import { Reducers } from "../../redux/types";

const NewReimburseTrip = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const params = history.location.state as any;
  const { sprintf } = require("sprintf-js");
  const [form, setForm]: any = useState({
    category_id: "",
    description: "",
    currency_code: "IDR",
    amount: "",
    billable: false,
    invoice_id: params.invoice_id,
    item_id: params.itemId,
    type_id: "Reimburse",
    budget_id: "",
    title: "",
    file: null,
    quantity: 1,
    trip_id: params.id,
    client_timezone: "Asia/Jakarta"
  });
  const initialPolicy = getPolicies("expense-trip-rm-receipt");
  const [policy, setPolicy] = useState(initialPolicy);
  const [isError, setIsError] = useState(false);

  const { newExpenseState, expenseState, newCashExpenseState } = useSelector(
    (state: Reducers) => ({
      newExpenseState: state.newExpense,
      expenseState: state.expense,
      newCashExpenseState: state.newCashExpense
    }),
    shallowEqual
  );

  const copTeks = {
    reimburse: copyWritings("reimburse"),
    policy: copyWritings("policy"),
    finance_category: copyWritings("finance_category")
  };

  const populateExpenseType = [
    {
      value: "",
      label: sprintf(t("cash.new.select"), copTeks.finance_category)
    },
    ...newExpenseState.listPopulate
  ];

  const _populateBudget = () => {
    let result: any = [];
    if (expenseState.budgetType.length > 0) {
      expenseState.budgetType.map((e: any) => {
        result = [...result, { value: e.id, label: e.attributes.title }];
      });
    } else {
      expenseState.budgetRemaining.map((e: any) => {
        result = [...result, { value: e.id, label: e.attributes.title }];
      });
    }
    return result;
  };

  const _setForm = useCallback(
    (
      formName:
        | "title"
        | "description"
        | "amount"
        | "budget_id"
        | "category_id"
        | "file",
      formValue: any
    ) => {
      setForm({ ...form, [formName]: formValue });
      if (isError) {
        setIsError(false);
      }
    },
    [form, isError]
  );

  useEffect(() => {
    dispatch(getTripById(params.id));
    dispatch(getPopulate("Reimburse"));
    if (params.project_id) {
      dispatch(getBudgetType(params.project_id));
      dispatch(getBudgetRemaining(params.project_id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (form.file || form.file !== null) {
      setPolicy(false);
    } else {
      setPolicy(initialPolicy);
    }
  }, [form.file]);

  useEffect(() => {
    if (params.item && params.item.amount) {
      setForm({
        ...form,
        title: params.item?.title || "",
        description: params.item?.description || "",
        amount: params.item?.amount.split(".")[0],
        category_id: params.item?.category_id || "",
        budget_id: params.item?.budget_id || "",
        file: params.item?.file && JSON.parse(params.item.file)
      });
    }
  }, [params.item]);

  const _uploadFile = (e: any) => {
    const files = e.target.files[0];
    _setForm("file", files);
  };

  const _goToPreview = () => {
    if (form.file.title) {
      window.open(form.file.url, "_blank");
    } else {
      const go = URL.createObjectURL(form.file);
      window.open(go, "_blank");
    }
  };

  const _deleteFile = () => {
    setForm({ ...form, file: null });
  };

  const _renderAlertError = useCallback(
    (isSHow: boolean) =>
      isSHow && (
        <Text style={[styles.label, styles.labelError]}>
          {t("cash.new.msg.req")}
        </Text>
      ),
    []
  );

  const _renderHeaderName = () => {
    if (!params.permissionCreate) {
      return sprintf(t("cash.new.reportDetail"), copTeks.reimburse);
    }
    if (params.itemId) {
      return sprintf(t("cash.new.editReport"), copTeks.reimburse);
    }
    return sprintf(t("cash.new.newBill"), copTeks.reimburse);
  };

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (params) {
      status = params.status;
    }

    if (status === 1) {
      desc = t("cash.status.draft");
    } else if (status === 2) {
      desc = t("cash.status.needApproval");
    } else if (status === 3) {
      desc = t("cash.status.approved");
    } else if (status === 4) {
      desc = t("cash.status.revise");
    } else if (status === 5) {
      desc = t("cash.status.ongoing");
    } else if (status === 6) {
      desc = t("cash.status.finished");
    } else if (status === 7) {
      desc = t("cash.status.rejected");
    } else if (status === 8) {
      desc = t("cash.status.overdue");
    }

    return desc;
  };

  const _onSubmit = () => {
    const body = {
      ...form,
      file: form.file && form.file.title ? "" : form.file || ""
    };
    if (form.title !== "" && form.amount !== "" && form.category_id !== "") {
      dispatch(postNewCashExpense(body, () => history.goBack(), params.itemId));
    } else {
      setIsError(true);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={_renderHeaderName()}
        label={_statusDesc()}
        textHeader={{ fontSize: 18 }}
      />
      <View style={styles.formWrap}>
        {params.permissionCreate && policy && (
          <View style={{ marginBottom: 16 }}>
            <Message
              message={sprintf(t("cash.new.msg.policyNewBill"), copTeks.policy)}
            />
          </View>
        )}
        <View
          style={[
            styles.row,
            isError && form.title === "" && styles.error,
            !params.permissionCreate && { borderBottomWidth: 0 }
          ]}
        >
          <Text style={styles.label}>{t("cash.new.subject")}</Text>
          {params.permissionCreate ? (
            <TextInput
              style={styles.textInput}
              onChangeText={e => _setForm("title", e)}
              value={form.title}
            />
          ) : (
            <Text style={styles.disableValueText}>{form.title}</Text>
          )}
          {_renderAlertError(isError && form.title === "")}
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "48%", marginBottom: 12 }}>
            <View
              style={[
                styles.rowSelect,
                isError && form.category_id === "" && styles.error,
                !params.permissionCreate && { borderBottomWidth: 0 }
              ]}
            >
              <Text style={styles.label}>{copTeks.finance_category}</Text>
              {params.permissionCreate ? (
                <Select
                  listValue={populateExpenseType}
                  onChange={e => _setForm("category_id", e.target.value)}
                  value={form.category_id}
                  style={
                    form.category_id
                      ? { opacity: 1, marginLeft: -4, marginTop: 4 }
                      : { opacity: 0.6, marginLeft: -4, marginTop: 4 }
                  }
                />
              ) : (
                <Text style={styles.disableValueText}>
                  {populateExpenseType.find(e => e.value === form.category_id)
                    ?.label || "-"}
                </Text>
              )}
            </View>
            <Text style={[styles.label, styles.selectError]}>
              {isError && form.category_id === "" && "*field required"}
            </Text>
          </View>

          <View style={{ width: "48%", marginBottom: 12 }}>
            <View
              style={[
                styles.rowSelect,
                !params.permissionCreate && { borderBottomWidth: 0 }
              ]}
            >
              <Text style={styles.label}>{t("cash.new.budgetAllocation")}</Text>
              {params.permissionCreate ? (
                <Select
                  listValue={_populateBudget()}
                  onChange={e => _setForm("budget_id", e.target.value)}
                  value={form.budget_id}
                  style={
                    form.budget_id
                      ? { opacity: 1, marginLeft: -4, marginTop: 4 }
                      : { opacity: 0.6, marginLeft: -4, marginTop: 4 }
                  }
                  emptyLabel={t("cash.new.selectBudget")}
                />
              ) : (
                <Text style={styles.disableValueText}>
                  {_populateBudget().find(
                    (e: any) => e.value === form.budget_id
                  )?.label || "-"}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View
          style={[
            styles.row,
            !params.permissionCreate && { borderBottomWidth: 0 }
          ]}
        >
          <Text style={styles.label}>{t("cash.new.description")}</Text>
          {params.permissionCreate ? (
            <TextArea
              bold
              onChange={e => _setForm("description", e.target.value)}
              value={form.description}
              border={false}
            />
          ) : (
            <Text style={styles.disableValueText}>
              {form.description || "-"}
            </Text>
          )}
        </View>

        <View
          style={[
            styles.row,
            isError && form.amount === "" && styles.error,
            !params.permissionCreate && { borderBottomWidth: 0 }
          ]}
        >
          <Text style={styles.label}>{t("cash.new.amount")}</Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Text style={[styles.label, { fontSize: 14, width: "25%" }]}>
              IDR
            </Text>
            {params.permissionCreate ? (
              <TextInput
                keyboardType="numeric"
                style={[styles.textInput, { textAlign: "right", width: "75%" }]}
                onChangeText={e => _setForm("amount", e)}
                value={form.amount}
              />
            ) : (
              <Text style={styles.disableValueText}>
                {maskedMoney(form.amount)}
              </Text>
            )}
          </View>
          {_renderAlertError(isError && form.amount === "")}
        </View>

        <View style={{ marginTop: 12 }}>
          <View style={{ marginBottom: 6 }}>
            {form.file && (
              <Touchable onPress={_goToPreview}>
                <FileUpload
                  name={form.file.title ? form.file.title : form.file.name}
                  style={{
                    marginTop: 6,
                    marginBottom: 6,
                    marginRight: 8,
                    width: "40%"
                  }}
                  hideDelete={params.itemId}
                  deleteFile={e => {
                    e.stopPropagation();
                    _deleteFile();
                  }}
                />
              </Touchable>
            )}
          </View>
          {params.permissionCreate && (
            <Upload
              style={{
                textAlign: "center",
                padding: 0,
                width: "48%",
                alignItems: "center",
                paddingBottom: 6,
                paddingTop: 6
              }}
              onChange={(e: any) => _uploadFile(e)}
            />
          )}
        </View>

        <View
          style={{
            paddingTop: 12,
            alignItems: "center",
            flex: 1
          }}
        >
          {params.permissionCreate && (
            <View style={styles.wrapSaveBtn}>
              <Button
                title={`${
                  params.itemId ? t("cash.new.save") : t("cash.new.add")
                }`}
                isLoading={newCashExpenseState.isLoadingPostNewCashExpense}
                style={{ paddingHorizontal: 10 }}
                onPress={_onSubmit}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default NewReimburseTrip;
