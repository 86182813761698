import React, { useState } from "react";
import { Image, View } from "react-native";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomText } from "..";
import { ICONS } from "../../configs";
import { handleLogout } from "../../redux/actions";
import Touchable from "../Touchable";

import styles from "./styles";

const KasbonNavigation = () => {
  const [heightFix, setHeight] = useState(0);
  const [widthFix, setWidth] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const hasCompany = localStorage.getItem("kasbon-setting");

  Image.getSize(
    hasCompany && JSON.parse(hasCompany).imageCompany.file.url,
    (height, width) => {
      setHeight((48 / height) * width);
      setWidth((48 / width) * height);
    },
    () => null
  );
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: hasCompany
            ? JSON.parse(hasCompany).hex_code
            : "white"
        }
      ]}
    >
      {hasCompany && (
        <View style={{ width: "40%" }}>
          <Touchable onPress={() => history.push("/home")}>
            <Image
              source={{
                uri: hasCompany && JSON.parse(hasCompany).imageCompany.file.url
              }}
              style={{
                height: heightFix,
                width: widthFix,
                maxWidth: 64,
                maxHeight: 64
              }}
              resizeMode="cover"
            />
          </Touchable>
        </View>
      )}

      <Touchable onPress={() => history.push("/home")}>
        <CustomText
          style={[
            styles.textKasbon,
            {
              color:
                hasCompany && JSON.parse(hasCompany).is_default_theme
                  ? "white"
                  : "#FF9500"
            }
          ]}
        >
          Kasbon
        </CustomText>
      </Touchable>
      <View style={styles.containerNavbtn}>
        <Touchable onPress={() => dispatch(handleLogout())}>
          <Image
            source={
              hasCompany && JSON.parse(hasCompany).is_default_theme
                ? ICONS.logoutKasbon
                : ICONS.logoutOrange
            }
            style={styles.imageNotification}
            resizeMode="contain"
          />
        </Touchable>
      </View>
    </View>
  );
};

export default KasbonNavigation;
