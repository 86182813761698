import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  submit: {
    backgroundImage:
      "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
    borderRadius: 100,
    width: 180,
    height: 54,
    marginBottom: 20,
    alignSelf: "center"
  },
  submitTextWrap: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  submitText: {
    color: "#FFF",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18
  }
});

export default styles;
