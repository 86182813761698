import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Cookies from "js-cookie";
import {
  Card,
  CardStatusColor,
  Fixed,
  Modal,
  WrapContent,
  WrapList
} from "../../../../components";

import styles from "./styles";
import { getBudgetRequest, getTripExpense } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { maskedMoney } from "../../../../utils";
import { ICONS } from "../../../../configs";
import ModerateTrip from "../../../../components/ModalComponents/ModerateTrip";

interface Props {
  id: string;
  top: number;
}

const Trip = ({ id, top }: Props) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [heighView, setHeightView] = useState(0);
  const [modalModeration, setModalModeration] = useState(false);
  const { t } = useTranslation();

  const { expenseState, tripData, tripState, uuid } = useSelector(
    (state: Reducers) => ({
      expenseState: state.newExpenseTrip,
      tripData: state.trip.tripData,
      tripState: state.trip,
      uuid: state.auth.uuid
    }),
    shallowEqual
  );

  const lang = Cookies.get("locale") || "en";

  const _convertData = useCallback(() => {
    let result = [] as any;
    Object.entries(expenseState.listBudgetReq).map((item: any) => {
      result = [...result, { name: item[0], value: item[1].IDR || 0 }];
    });
    if (expand) {
      return result;
    }
    return result.filter((res: any, i: number) => result.length - 1 === i);
  }, [expand, expenseState.listBudgetReq]);

  useEffect(() => {
    dispatch(getBudgetRequest(id, lang));
    dispatch(getTripExpense(id));
    _convertData();
  }, [dispatch, id]);

  const _canModerateTrip = useCallback(() => {
    let res = false;
    if (tripState.tripModerationData.length > 0) {
      res =
        tripState.tripModerationData &&
        tripState.tripModerationData[
          tripState.tripModerationData.length - 1
        ].users.filter((userData: any) => userData.user.id === uuid).length > 0;
    }

    if (tripData && tripData.status === 2 && res) {
      return true;
    }
    return false;
  }, [uuid, tripState.tripModerationData, tripData]);

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2 || status === 5) {
      color = "#F5AA3F";
    } else if (status === 4 || status === 7 || status === 9) {
      color = "#ed5a28";
    }
    return color;
  };

  const _statusDesc = (status: number, total: any, total_paid: any) => {
    let label = "";
    if (status === 1) {
      label = t("activity.status.draft");
    } else if (status === 2) {
      label = t("activity.status.onVerification");
    } else if (status === 3) {
      label = t("activity.status.approved");
    } else if (status === 4) {
      label = t("activity.status.revise");
    } else if (status === 5 && Number(total_paid) >= Number(total)) {
      label = t("activity.status.notSettled");
    } else if (status === 5) {
      label = t("activity.status.unpaid");
    } else if (status === 6) {
      label = t("activity.status.settled");
    } else if (status === 7) {
      label = t("activity.status.rejected");
    } else if (status === 8) {
      label = t("activity.status.expired");
    } else if (status === 9) {
      label = t("activity.status.rejectByFinance");
    }
    return label;
  };

  return (
    <WrapContent isLoading={expenseState.isLoadingListTripExpense}>
      <View style={styles.container}>
        <Fixed getHeight={e => setHeightView(e)} customPosition={{ top }}>
          <View style={{ backgroundColor: "#fffff7", paddingTop: 12 }}>
            <Card
              onPress={() => setExpand(!expand)}
              cardStyle={styles.cardHeader}
            >
              {_convertData().map((item: any, index: number) => (
                <View
                  key={index}
                  style={[
                    styles.wrapCardHeader,
                    _convertData().length - 1 === index && { marginBottom: -3 }
                  ]}
                >
                  <Text
                    style={[
                      styles.font,
                      { flex: 1.3 },
                      _convertData().length - 1 === index && styles.greenBold
                    ]}
                  >
                    {item.name}
                  </Text>
                  <View style={[styles.wrapCardHeader, { flex: 1 }]}>
                    <Text
                      style={[
                        styles.font,
                        styles.grey,
                        _convertData().length - 1 === index && styles.greenBold
                      ]}
                    >
                      IDR
                    </Text>
                    <Text
                      style={[
                        styles.font,
                        styles.greyBold,
                        _convertData().length - 1 === index && styles.greenBold
                      ]}
                    >
                      {maskedMoney(item.value, true)}
                    </Text>
                  </View>
                </View>
              ))}
            </Card>
          </View>
        </Fixed>
        <WrapList
          isEmpty={expenseState.listTripExpense.length === 0}
          emptyComponent={() => (
            <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
          )}
        >
          <FlatList
            style={{
              marginTop: heighView,
              paddingTop: 2
            }}
            data={expenseState.listTripExpense}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <Card
                cardStyle={styles.cardStyle}
                statusColor={_statusColor(item.status)}
                statusTextLight="Status"
                statusDesc={_statusDesc(
                  item.status,
                  item.total,
                  item.total_paid
                )}
                statusDescStyle={{ fontSize: 14 }}
                statusStyle={{
                  paddingVertical: 4,
                  paddingHorizontal: 16,
                  borderBottomLeftRadius: 12,
                  borderBottomRightRadius: 12
                }}
                key={index}
              >
                <View style={styles.wrapContentCard}>
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("activity.management.invoice")}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.number}
                    </Text>
                  </View>
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("activity.management.method")}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue]}
                    >
                      {`${item.type.title || "-"} - ${
                        item.djurneeInvoice.type.title || "-"
                      }`}
                    </Text>
                  </View>
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {" "}
                      {t("activity.detail.subject")}
                    </Text>
                    <View style={{ width: "60%" }}>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          { width: "100%" }
                        ]}
                      >
                        {item.purpose.split(" - ")[0]}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          { width: "100%" }
                        ]}
                      >
                        {item.purpose.split(" - ").slice(1).join(" - ")}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.wrapCardText}>
                    <Text style={[styles.textLabel]}>
                      {" "}
                      {t("activity.detail.date")}
                    </Text>
                    <View style={{ flexDirection: "row", width: "75%" }}>
                      <Text style={[styles.textLabels, styles.bold]}>
                        <Text style={{ fontWeight: "normal" }}>
                          {`${moment(item.published_at).format("ddd")} ` || "-"}
                        </Text>
                        {moment(item.published_at).format("DD MMM YYYY") || ""}
                      </Text>
                      <Text style={[styles.textLabels, styles.bold]}>
                        <Text style={{ fontWeight: "normal" }}>
                          {` - ${moment(item.due_at).format("ddd")} ` || "-"}
                        </Text>
                        {moment(item.due_at).format("DD MMM YYYY") || ""}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {" "}
                      {t("activity.invoice.requestedBy")}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.djurneeInvoice.user.name}
                    </Text>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {" "}
                      {t("activity.new.amount")}
                    </Text>
                    <View style={{ flexDirection: "row", width: "75%" }}>
                      <Text style={[styles.textLabels, styles.bold]}>
                        <Text style={{ fontWeight: "normal" }}>IDR</Text>
                        {` ${maskedMoney(item.amount)}`}
                      </Text>
                    </View>
                  </View>
                </View>
              </Card>
            )}
          />
          {_canModerateTrip() && (
            <View
              style={[
                styles.wrapBtnModeration,
                ((expenseState.listTripExpense.length > 1 && expand) ||
                  expenseState.listTripExpense.length > 2) && {
                  marginBottom: 20
                }
              ]}
            >
              <TouchableOpacity
                activeOpacity={0.8}
                style={styles.btnModeration}
                onPress={() => setModalModeration(true)}
              >
                <Image
                  source={ICONS.moderationIcon}
                  style={{ height: 28, width: 28 }}
                />
              </TouchableOpacity>
            </View>
          )}
        </WrapList>
      </View>
      {modalModeration && (
        <Modal
          isVisible={modalModeration}
          backdropColor="rgba(240,240,240, 0.5)"
        >
          {tripData && (
            <ModerateTrip
              tripId={tripData.id}
              onCancel={() => setModalModeration(false)}
            />
          )}
        </Modal>
      )}
    </WrapContent>
  );
};

export default Trip;
