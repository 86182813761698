const formatMoney = (value: number | string, emptyValue?: any) => {
  if (value) {
    return Intl.NumberFormat("id-ID", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(Number(value));
  }
  return emptyValue || "0";
};

export default formatMoney;
