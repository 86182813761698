import moment from "moment";
import React, { useState } from "react";
import { ActivityIndicator, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomText, Touchable } from "../../../../components";
import { postLoan } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { maskedMoney } from "../../../../utils";
import styles from "./style";

// screens

export const KasbonContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loanProfile, isLoadingPostLoan } = useSelector(
    (state: Reducers) => state.loan
  );
  const [balance, setBalance] = useState("");
  const formatMoneyInput = (angka: any) => {
    const number_string = angka.replace(/[^,\d]/g, "").toString();
    const split = number_string.split(",");
    const sisa = split[0].length % 3;
    let rupiah = split[0].substr(0, sisa);
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah;
    return rupiah;
  };

  const postLoanKasbon = () => {
    const body = {
      amount: balance.replace(/[.]/g, ""),
      currency_code: loanProfile[0].loanSource.currency_code,
      period: moment().format("YYYY-MM-DD"),
      source_id: loanProfile[0].loanSource.id,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    dispatch(
      postLoan(
        body,
        () => null,
        () => history.push("home")
      )
    );
  };
  return (
    <View style={styles.container}>
      <View style={{ margin: 20 }}>
        <CustomText style={styles.limitWithdrawal}>Penarikan Pagu</CustomText>
        <CustomText style={styles.label}>Sisa Saldo Pagu (IDR)</CustomText>
        <CustomText style={styles.amount}>
          {maskedMoney(
            Number(loanProfile && loanProfile[0].amount_max) -
              Number(loanProfile && loanProfile[0].amount_current)
          )}
        </CustomText>
        <CustomText style={styles.label}>Jumlah Penarikan (IDR)</CustomText>
        <View style={styles.wrapTextInput}>
          <TextInput
            style={styles.textInput}
            value={balance}
            onChangeText={e => setBalance(formatMoneyInput(e))}
          />
        </View>
        <ul>
          <li style={{ marginLeft: -20 }}>
            <CustomText>
              {`Minimum Penarikan Rp ${maskedMoney(
                loanProfile && loanProfile[0].minimum_request
              )},-`}
            </CustomText>
          </li>
          <li style={{ marginLeft: -20 }}>
            <CustomText>
              {`Maximum Penarikan Rp ${maskedMoney(
                loanProfile && loanProfile[0].maximum_request
              )},-`}
            </CustomText>
          </li>
          <li style={{ marginLeft: -20 }}>
            <CustomText>Penarikan akan diproses dalam 1x24 jam.</CustomText>
          </li>
        </ul>
      </View>
      <View style={styles.containerBtn}>
        <Touchable onPress={postLoanKasbon}>
          <View style={styles.btnSubmit}>
            <View>
              {isLoadingPostLoan ? (
                <ActivityIndicator color="#344370" />
              ) : (
                <CustomText style={styles.btnText}>Ajukan</CustomText>
              )}
            </View>
          </View>
        </Touchable>
        <Touchable onPress={() => history.goBack()}>
          <View style={styles.btnBack}>
            <View>
              {isLoadingPostLoan ? (
                <ActivityIndicator color="#344370" />
              ) : (
                <CustomText style={styles.btnBackText}>Kembali</CustomText>
              )}
            </View>
          </View>
        </Touchable>
      </View>
    </View>
  );
};
