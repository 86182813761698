import React, { ReactNode } from "react";
import { View, ViewStyle } from "react-native";

import styles from "./styles";

interface Props {
  children: ReactNode;
  style?: ViewStyle;
}

const Container = ({ children, style }: Props) => (
  <View style={styles.container}>
    <View style={[styles.wrapper, style]}>{children}</View>
  </View>
);

export default Container;
