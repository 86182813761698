import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16
  },
  wrapInputDateTime: {
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-between"
  },
  font: {
    fontFamily: "Poppins",
    color: "#666666"
  },
  label: {
    fontSize: 11
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginVertical: 2,
    borderRadius: 10
  },
  row: {
    marginBottom: 16
  },
  titleCard: {
    flexDirection: "row",
    alignItems: "center"
  },
  wrapStatus: {
    backgroundColor: COLORS.blue,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4
  },
  buttonUpload: {
    borderRadius: 100,
    paddingHorizontal: 16,
    height: 28,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  inputDesc: {
    borderBottomColor: "#fff7",
    borderBottomWidth: 1
  },
  wrapInputCash: {
    flexDirection: "row"
  },
  button: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  attributeTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#999999"
  },
  attachment: {
    width: "48%",
    height: 28,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    borderRadius: 100,
    backgroundColor: COLORS.main,
    justifyContent: "center",
    alignItems: "center"
  },
  wrapTooltip: {
    flex: 1,
    marginHorizontal: -8
  },
  wrapTooltipName: {
    flexDirection: "row"
  },
  textTooltips: {
    color: "#fff",
    fontSize: 11,
    marginBottom: -4
  },
  tooltipName: {
    color: "#fff",
    fontSize: 14,
    marginRight: 10
  }
});

export default styles;
