import {
  GET_KASBON_SETTING_ERROR,
  GET_KASBON_SETTING_PENDING,
  GET_KASBON_SETTING_SUCCESS,
  GET_LOAN_DETAIL_ERROR,
  GET_LOAN_DETAIL_PENDING,
  GET_LOAN_DETAIL_SUCCESS,
  GET_LOAN_ERROR,
  GET_LOAN_HISTORY_ERROR,
  GET_LOAN_HISTORY_PENDING,
  GET_LOAN_HISTORY_SUCCESS,
  GET_LOAN_PENDING,
  GET_LOAN_PROFILE_ERROR,
  GET_LOAN_PROFILE_PENDING,
  GET_LOAN_PROFILE_SUCCESS,
  GET_LOAN_SUBMITED_ERROR,
  GET_LOAN_SUBMITED_PENDING,
  GET_LOAN_SUBMITED_SUCCESS,
  GET_LOAN_SUCCESS,
  LOGOUT_SUCCESS,
  POST_LOAN_ERROR,
  POST_LOAN_PENDING,
  POST_LOAN_SUCCESS,
  SET_SORT,
  SET_STATUS_LOAN
} from "../actions";
import { Action, LoanState } from "../types";

const initialState: LoanState = {
  isLoadingLoan: false,
  listLoan: [],
  loadMore: true,
  loanDetail: null,
  loanProfile: null,
  isLoadingLoanDetail: false,
  isLoadingLoanProfile: false,
  isLoadingPostLoan: false,
  statusNumber: 3,
  totalData: 0,
  submitedLoan: [],
  isLoadingLoanHistory: false,
  loanHistory: [],
  isLoadingSubmitedLoan: false,
  kasbonSetting: null,
  isLoadingKasbonSetting: false,
  params: {
    per_page: 10,
    page: 1,
    sortOrder: "asc",
    lastPage: 2
  }
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_LOAN_PENDING:
      return { ...state, isLoadingLoan: !payload.data.loadMore };
    case GET_LOAN_SUCCESS:
      return {
        ...state,
        isLoadingLoan: false,
        totalData: payload.data.totalData,
        params: {
          ...state.params,
          page: !payload.data.loadMore ? 1 : state.params.page + 1,
          lastPage: payload.data.data.meta.pagination.lastPage
        },
        // loadMore: !(payload.data.data.length < state.params.per_page),
        listLoan: !payload.data.loadMore
          ? payload.data.data.data
          : [...state.listLoan, ...payload.data.data.data],
        loadMore: !(payload.data.data.length < initialState.params.per_page)
      };
    case GET_LOAN_ERROR:
      return { ...state, isLoadingLoan: false };

    case GET_LOAN_PROFILE_PENDING:
      return { ...state, isLoadingLoanProfile: true };
    case GET_LOAN_PROFILE_SUCCESS:
      return {
        ...state,
        isLoadingLoanProfile: false,
        loanProfile: payload.data
      };

    case GET_KASBON_SETTING_PENDING:
      return { ...state, isLoadingKasbonSetting: true };
    case GET_KASBON_SETTING_SUCCESS:
      return {
        ...state,
        isLoadingKasbonSetting: false,
        kasbonSetting: payload.data.data
      };
    case GET_KASBON_SETTING_ERROR:
      return { ...state, isLoadingKasbonSetting: false };

    case GET_LOAN_PROFILE_ERROR:
      return { ...state, isLoadingLoanProfile: false };

    case GET_LOAN_HISTORY_PENDING:
      return { ...state, isLoadingLoanHistory: true };
    case GET_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingLoanHistory: false,
        loanHistory: !payload.data.loadMore
          ? payload.data.data.data
          : [...state.loanHistory, ...payload.data.data.data],
        params: {
          ...state.params,
          page: payload.data.data.meta.pagination.current_page,
          lastPage: payload.data.data.meta.pagination.last_page
        }
      };
    case GET_LOAN_HISTORY_ERROR:
      return { ...state, isLoadingLoanHistory: false };

    case SET_SORT:
      return {
        ...state,
        params: {
          ...state.params,
          sortOrder: payload.data,
          page: 1
        }
      };

    case GET_LOAN_SUBMITED_PENDING:
      return { ...state, isLoadingSubmitedLoan: true };
    case GET_LOAN_SUBMITED_SUCCESS:
      return {
        ...state,
        isLoadingSubmitedLoan: false,
        submitedLoan: payload.data
      };
    case GET_LOAN_SUBMITED_ERROR:
      return { ...state, isLoadingSubmitedLoan: false };

    case GET_LOAN_DETAIL_PENDING:
      return { ...state, isLoadingLoanDetail: true };
    case GET_LOAN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingLoanDetail: false,
        loanDetail: payload
      };
    case GET_LOAN_DETAIL_ERROR:
      return { ...state, isLoadingLoanDetail: false };

    case POST_LOAN_PENDING:
      return { ...state, isLoadingPostLoan: true };
    case POST_LOAN_SUCCESS:
      return {
        ...state,
        isLoadingPostLoan: false
      };
    case POST_LOAN_ERROR:
      return { ...state, isLoadingPostLoan: false };

    case SET_STATUS_LOAN:
      return { ...state, statusNumber: payload.data };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
