import { StyleSheet } from "react-native";
import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  card: {
    borderRadius: 30,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    position: "relative",
    paddingHorizontal: 12
  },
  defaultSelect: {
    borderBottomWidth: 1,
    borderColor: COLORS.grey,
    position: "relative"
  }
});

export default styles;
