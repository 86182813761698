import { Dimensions, StyleSheet } from "react-native";

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    height,
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#3f7790",
    fontWeight: "bold"
  },
  buttonAttachmen: {
    width: 140,
    height: 54,
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #50b1b1, #262261)",
    borderRadius: 30
  },
  image: {
    width: 58.3,
    height: 58.5
  },
  content: {
    fontFamily: "Poppins",
    marginTop: 81.4,
    paddingHorizontal: 46,
    textAlign: "center",
    fontSize: 14,
    color: "#666666"
  },
  buttonText: {
    fontWeight: "bold",
    color: "white",
    fontFamily: "Poppins",
    fontSize: 16
  },
  cancel: {
    padding: 10,
    fontSize: 16,
    color: "#707070",
    fontFamily: "Poppins",
    fontWeight: "bold"
  }
});

export default styles;
