import React, { useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { getPerDiem } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

import { Card, WrapContent, WrapList } from "../../../components";
import styles from "./styles";
import { convertDate } from "../../../utils";

interface Props {
  id: string;
}
const PerDiem = ({ id }: Props) => {
  const dispatch = useDispatch();
  const NewExpenseTripState = useSelector(
    (state: Reducers) => state.newExpenseTrip
  );

  const _currency = (num: number, idr: string) => {
    return `${idr} ${Math.trunc(num)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
  };

  useEffect(() => {
    dispatch(getPerDiem(id));
  }, [dispatch]);

  return (
    <WrapContent isLoading={NewExpenseTripState.isLoadingListPerDiem}>
      <WrapList
        isEmpty={NewExpenseTripState.listPerDiem.length === 0}
        emptyComponent={() => <Text style={styles.text}>Kosong</Text>}
      >
        <FlatList
          style={{ paddingBottom: 10, paddingHorizontal: 25 }}
          data={NewExpenseTripState.listPerDiem}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Card
              key={index}
              cardStyle={{
                marginBottom: 15,
                marginTop: index === 0 ? 20 : 0
              }}
              statusColor="#50b1b1"
              statusText="Amount"
              statusDesc={_currency(item.total, "IDR")}
            >
              <View style={{ marginLeft: 22, marginVertical: 17 }}>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={styles.text}>Category</Text>
                  <Text style={styles.textDesc}>{item.title}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.text}>Date</Text>
                  <Text style={styles.textDesc}>
                    {`${convertDate(item.started_at)} - ${convertDate(
                      item.finished_at
                    )}`}
                  </Text>
                </View>
              </View>
            </Card>
          )}
        />
      </WrapList>
    </WrapContent>
  );
};

export default PerDiem;
