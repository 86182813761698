/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import { Reducers } from "../../redux/types";
import { copyWritings, heightPercent, permissionPage } from "../../utils";
import {
  BubbleNotif,
  Fixed,
  Header,
  Search,
  Touchable
} from "../../components";
import { IMAGES } from "../../configs";

import List from "./List";
import {
  getBubbleNotif,
  resetSearchCashValue,
  setSearchCashValue
} from "../../redux/actions";
import { debounce } from "../../utils/helpers";

const Cash = () => {
  const homeState = useSelector((state: Reducers) => state.home);
  const bubbleState = useSelector((state: Reducers) => state.bubbleNotif);
  const match = useRouteMatch();
  const { sprintf } = require("sprintf-js");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const myPermission = permissionPage("invoice-others-list");
  const myPermissionSelfList = permissionPage("invoice-self-list");
  const { pathname } = history.location;
  const [heightHeader, setHeightHeader] = useState(0);

  useEffect(() => {
    dispatch(getBubbleNotif("cash"));
    dispatch(resetSearchCashValue());
  }, [dispatch]);

  const _getBubleData = useCallback(
    (type: "my_expense" | "approval" | "collectable" | "disburse") => {
      if (bubbleState.dataBubble) {
        return bubbleState.dataBubble && bubbleState.dataBubble[type];
      }
      return false;
    },
    [bubbleState.dataBubble]
  );

  const [listPages, setListPages] = useState([
    {
      pathname: match.path,
      component: <List type="owned" />,
      iconTab: IMAGES.cashRequest,
      titleTab: t("cash.myRequestTab"),
      exact: true,
      accessible: myPermissionSelfList,
      bubbleData: _getBubleData("my_expense")
    },
    {
      pathname: `${match.path}/approve`,
      component: <List type="approvable" />,
      iconTab: IMAGES.cashApprove,
      titleTab: t("cash.approvalTab"),
      exact: true,
      accessible: true,
      bubbleData: _getBubleData("approval")
    },
    {
      pathname: `${match.path}/disburse`,
      component: <List type="unpaid" />,
      iconTab: IMAGES.cashDisburse,
      titleTab: t("cash.disburseTab"),
      exact: true,
      accessible: true,
      bubbleData: _getBubleData("disburse")
    },
    {
      pathname: `${match.path}/collect`,
      component: <List type="collectable" />,
      iconTab: IMAGES.cashCollect,
      titleTab: t("cash.collectTab"),
      exact: true,
      accessible: true,
      bubbleData: _getBubleData("collectable")
    },
    {
      pathname: `${match.path}/billable`,
      component: <List type="billable" />,
      iconTab: IMAGES.cashBillable,
      titleTab: t("cash.billableTab"),
      exact: true,
      accessible: true,
      bubbleData: false
    }
  ]);

  useEffect(() => {
    if (bubbleState.dataBubble) {
      setListPages([
        {
          pathname: match.path,
          component: <List type="owned" />,
          iconTab: IMAGES.cashRequest,
          titleTab: t("cash.myRequestTab"),
          exact: true,
          accessible: myPermissionSelfList,
          bubbleData: _getBubleData("my_expense")
        },
        {
          pathname: `${match.path}/approve`,
          component: <List type="approvable" />,
          iconTab: IMAGES.cashApprove,
          titleTab: t("cash.approvalTab"),
          exact: true,
          accessible: true,
          bubbleData: _getBubleData("approval")
        },
        {
          pathname: `${match.path}/disburse`,
          component: <List type="unpaid" />,
          iconTab: IMAGES.cashDisburse,
          titleTab: t("cash.disburseTab"),
          exact: true,
          accessible: true,
          bubbleData: _getBubleData("disburse")
        },
        {
          pathname: `${match.path}/collect`,
          component: <List type="collectable" />,
          iconTab: IMAGES.cashCollect,
          titleTab: t("cash.collectTab"),
          exact: true,
          accessible: true,
          bubbleData: _getBubleData("collectable")
        },
        {
          pathname: `${match.path}/billable`,
          component: <List type="billable" />,
          iconTab: IMAGES.cashBillable,
          titleTab: t("cash.billableTab"),
          exact: true,
          accessible: true,
          bubbleData: false
        }
      ]);
    }
  }, [bubbleState.dataBubble]);

  const _getHeighHeader = () => {
    return heightHeader + 16;
  };

  const _onChangeSearch = debounce((text: string) => {
    dispatch(setSearchCashValue(text));
  });

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            title={
              myPermission
                ? copyWritings("invoice")
                : sprintf(t("cash.detail.myRequest"), copyWritings("invoice"))
            }
            goBack={() => history.push("/home")}
          />
          <View
            style={[
              styles.container,
              homeState.isLoadingMyProfile && { opacity: 0 }
            ]}
          >
            <View style={styles.wrapTaskNav}>
              {myPermission ? (
                <View style={styles.taskNav}>
                  {listPages.map((item, index) => (
                    <View
                      key={index}
                      style={[
                        styles.btnNav,
                        index === 0 &&
                          item.pathname === pathname &&
                          styles.activeFirst,
                        index === 4 &&
                          item.pathname === pathname &&
                          styles.activeLast,
                        index !== 0 &&
                          pathname.includes(item.pathname) &&
                          styles.active
                      ]}
                    >
                      <Touchable
                        onPress={() => history.push(item.pathname)}
                        disabled={!item.accessible}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            flex: 1
                          }}
                        >
                          <Image
                            resizeMode="contain"
                            style={[
                              styles.image,
                              index === listPages.length - 1 && {
                                marginRight: 8
                              }
                            ]}
                            source={item.iconTab}
                          />
                          <Text
                            style={[
                              styles.btnText,
                              { color: item.accessible ? "white" : "#666666" }
                            ]}
                          >
                            {item.titleTab}
                          </Text>
                        </View>
                      </Touchable>
                      {item.bubbleData && (
                        <BubbleNotif
                          value={item.bubbleData.total}
                          message={item.bubbleData.message}
                        />
                      )}
                    </View>
                  ))}
                </View>
              ) : (
                <></>
              )}
            </View>
            <Search onChange={_onChangeSearch} />
          </View>
        </Fixed>
        <View
          style={{
            marginTop: _getHeighHeader(),
            minHeight: heightPercent(90) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};

export default Cash;
