import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getEmployee, refreshEmployee } from "../../redux/actions";
import { Reducers } from "../../redux/types";
import { Modal, Touchable } from "..";
import styles from "./styles";
import { IMAGES } from "../../configs";

interface Props {
  onBackdropPress: () => void;
  value: any[];
  onChange: (employee: any[]) => void;
}
const UserPicker = ({ value, onBackdropPress, onChange }: Props) => {
  const dispatch = useDispatch();
  const { employee, auth } = useSelector(
    (state: Reducers) => ({
      employee: state.employee,
      auth: state.auth
    }),
    shallowEqual
  );
  const typeSelectedEmployee: any = [];
  const [selectedEmployee, setSelectedEmployee] = useState(
    typeSelectedEmployee
  );
  // const [listEmployee, setListEmployee] = useState([
  //   {
  //     id: "1",
  //     name: "Branden",
  //     photo: "",
  //     title: "Assisten to the Regional Manager"
  //   },
  //   {
  //     id: "2",
  //     name: "Shawn",
  //     photo: "",
  //     title: "Talent Delivery Specialist"
  //   },
  //   {
  //     id: "3",
  //     name: "Susie",
  //     photo: "",
  //     title: "Quality Assurance"
  //   },
  //   {
  //     id: "4",
  //     name: "David",
  //     photo: "",
  //     title: "IT Specialist"
  //   },
  //   {
  //     id: "5",
  //     name: "Cicillia",
  //     photo: "",
  //     title: "Design Supervisor"
  //   },
  //   {
  //     id: "6",
  //     name: "Maria",
  //     photo: "",
  //     title: "Head of Operation"
  //   }
  // ]);
  const [oneTimeEffect, setOneTimeEffect] = useState(true);
  const isSelected = (id: string) => {
    let selected = false;
    const check = selectedEmployee.filter(
      (item: { id: string }) => item.id === id
    );
    if (check.length > 0) {
      selected = true;
    }
    return selected;
  };
  const onSelected = (id: string) => {
    if (isSelected(id)) {
      setSelectedEmployee([
        ...selectedEmployee.filter((item: { id: string }) => item.id !== id)
      ]);
      dispatch(refreshEmployee(employee.listEmployee));
    } else {
      const choose = employee.listEmployee.filter(
        (item: { id: string }) => item.id === id
      );
      if (choose.length > 0) {
        setSelectedEmployee([
          ...selectedEmployee,
          {
            id: choose[0].id,
            name: choose[0].attributes.name,
            title: choose[0].attributes.position_name,
            photo: choose[0].attributes.avatar
          }
        ]);
        dispatch(refreshEmployee(employee.listEmployee));
      }
    }
  };

  useEffect(() => {
    if (oneTimeEffect) {
      dispatch(getEmployee());
      setOneTimeEffect(false);
      setSelectedEmployee(value);
    }
  }, [oneTimeEffect, dispatch, value]);

  return (
    <View>
      <Modal
        isVisible
        onBackdropPress={() => {
          onChange(selectedEmployee);
          onBackdropPress();
        }}
      >
        <FlatList
          data={employee.listEmployee}
          keyExtractor={item => item.id}
          renderItem={({ item }) => (
            <>
              {auth.userId !== item.id ? (
                <View
                  style={[
                    isSelected(item.id)
                      ? styles.employeeActive
                      : styles.employee
                  ]}
                >
                  <Touchable onPress={() => onSelected(item.id)}>
                    <View style={{ flexDirection: "row" }}>
                      <Image
                        source={
                          item.attributes.avatar === null
                            ? IMAGES.avatar
                            : { uri: item.attributes.avatar }
                        }
                        style={styles.photo}
                      />
                      <View style={styles.description}>
                        <Text
                          style={
                            isSelected(item.id)
                              ? styles.nameActive
                              : styles.name
                          }
                        >
                          {`${item.attributes.name}`}
                        </Text>
                        <Text
                          style={
                            isSelected(item.id)
                              ? styles.titleActive
                              : styles.title
                          }
                        >
                          {item.attributes.position_name}
                        </Text>
                      </View>
                    </View>
                  </Touchable>
                </View>
              ) : (
                <></>
              )}
            </>
          )}
        />
      </Modal>
    </View>
  );
};

export default UserPicker;
