import React, {
  CSSProperties,
  ReactChild,
  ReactElement,
  useEffect
} from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import ReactDOM from "react-dom";
import { Approve, Reject, Revise } from "../ModalComponents";

import { Touchable } from "..";
import { SIZE } from "../../configs";

const portalRoot = document.getElementById("portal");

interface Props {
  moderationId?: string;
  cancelApprove?: () => void;
  screen?: string;
  children?: ReactChild;
  isVisible?: boolean;
  backdropColor?: string;
  onBackdropPress?: () => void;
  wrapStyle?: object;
  isNotCenter?: boolean;
  withScroll?: boolean;
  footer?: () => ReactElement;
}

const Portal = ({ children }: Props) => {
  return ReactDOM.createPortal(children, portalRoot!);
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%"
  },
  wrapContent: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%"
  },
  content: {
    width: "100%",
    maxWidth: SIZE.maxWidth,
    justifyContent: "center",
    alignItems: "center"
  }
});

interface StyleCSS {
  container: CSSProperties;
  containerWithScroll: CSSProperties;
}

const styleCSS: StyleCSS = {
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  containerWithScroll: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "scroll"
  }
};

const Modal = ({
  cancelApprove,
  children,
  isVisible,
  onBackdropPress,
  backdropColor,
  screen,
  moderationId,
  wrapStyle,
  isNotCenter,
  withScroll,
  footer
}: Props) => {
  const handleRender = () => {
    let component = null;
    if (screen === "approveProject") {
      component = (
        <Approve cancel={cancelApprove} moderationId={moderationId} />
      );
    } else if (screen === "rejectProject") {
      component = <Reject cancel={cancelApprove} moderationId={moderationId} />;
    } else if (screen === "reviseProject") {
      component = <Revise cancel={cancelApprove} moderationId={moderationId} />;
    } else {
      component = children;
    }
    return component;
  };

  useEffect(() => {
    if (isVisible) {
      document.getElementById("root")!.style.filter = "blur(10px)";
      document.getElementById("root")!.style.overflowX = "hidden";
      document.getElementById("root")!.style.overflowY = "auto";
    } else {
      document.getElementById("root")!.style.removeProperty("filter");
      document.getElementById("root")!.style.removeProperty("overflow");
    }

    return () => {
      document.getElementById("root")!.style.removeProperty("filter");
      document.getElementById("root")!.style.removeProperty("overflow");
    };
  });

  return isVisible ? (
    <Portal>
      <>
        <div
          style={withScroll ? styleCSS.containerWithScroll : styleCSS.container}
        >
          <View style={[styles.container, { backgroundColor: backdropColor }]}>
            <Touchable onPress={onBackdropPress}>
              <View
                style={[isNotCenter ? null : styles.wrapContent, wrapStyle]}
              >
                <div onClick={e => e.stopPropagation()}>
                  <View style={styles.content}>
                    <ScrollView style={{ borderRadius: 20 }}>
                      {handleRender()}
                    </ScrollView>
                  </View>
                </div>
              </View>
            </Touchable>
          </View>
        </div>
        {footer && footer!()}
      </>
    </Portal>
  ) : null;
};

Modal.defaultProps = {
  isVisible: false,
  backdropColor: "rgba(0,0,0,0)",
  withScroll: false
};

export default Modal;
