import React from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { ToastContainer } from "react-toastify";
import { Touchable } from "../..";

import { IMAGES } from "../../../configs";
import styles from "./styles";

interface Props {
  headerTitle: string;
  description: string;
  actionTitle: string;
  onCancel?: () => void;
  onAction?: () => void;
  isLoading?: boolean;
  cancelTitle?: string;
}

const Confirmation = ({
  onCancel,
  headerTitle,
  description,
  onAction,
  isLoading,
  actionTitle,
  cancelTitle
}: Props) => {
  return (
    <View style={styles.container}>
      <ToastContainer limit={1} />
      <View style={styles.wrapHeaderTitle}>
        <Text style={styles.headerTitle}>{headerTitle}</Text>
        <Image source={IMAGES.rejectModal} style={styles.headerImage} />
      </View>
      <Text style={styles.descStyle}>{description}</Text>
      <View style={styles.wrapButton}>
        <TouchableOpacity
          style={styles.actionButton}
          activeOpacity={0.8}
          onPress={onAction}
          disabled={isLoading}
        >
          {isLoading ? (
            <ActivityIndicator size="small" color="#606060" />
          ) : (
            <Text style={styles.actionText}>{actionTitle}</Text>
          )}
        </TouchableOpacity>
        <View style={styles.cancelButton}>
          <Touchable
            onPress={e => {
              e.stopPropagation();
              onCancel && onCancel();
            }}
          >
            <Text style={styles.cancelText}>{cancelTitle || "CANCEL"}</Text>
          </Touchable>
        </View>
      </View>
    </View>
  );
};

Confirmation.defaulProps = {
  cancelTitle: "CANCEL"
};

export default Confirmation;
