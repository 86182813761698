const icons = {
  iconBack: require("../assets/icon/back.png"),
  iconPerson: require("../assets/icon/person.png"),
  iconSetting: require("../assets/icon/setting.png"),
  iconChat: require("../assets/icon/chat.png"),
  iconNext: require("../assets/icon/next.png"),
  iconApproved: require("../assets/icon/Approved.png"),
  iconAssigned: require("../assets/icon/Assign.png"),
  iconNotes: require("../assets/icon/notes.png"),
  iconNotification: require("../assets/icon/notification.png"),
  iconDeleteFile: require("../assets/icon/deleteFile.png"),
  file: require("../assets/icon/file.png"),
  moderationIcon: require("../assets/icon/moderationIcon.png"),
  prevButton: require("../assets/icon/prevButton.png"),
  nextButton: require("../assets/icon/nextButton.png"),
  trash: require("../assets/icon/trash.png"),
  plus: require("../assets/icon/plus.png"),
  search: require("../assets/icon/search.png"),
  addLoan: require("../assets/icon/addLoan.png"),
  logoutKasbon: require("../assets/icon/logout-kasbon.png"),
  logoutOrange: require("../assets/icon/logout-orange.png")
};

export default icons;
