import React, { useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import {
  Card,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../../components";
import { convertDate, maskedMoney } from "../../../../../utils";

import { getListBookingFlight } from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";

interface Props {
  id: string;
  search: string;
}

const Flight = ({ id, search }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = history.location;

  useEffect(() => {
    dispatch(getListBookingFlight(id));
  }, [dispatch]);
  const bookingState = useSelector((state: Reducers) => state.booking);

  const _splitDate = (date: string) =>
    convertDate(date, "ddd,DD MMM YYYY,hh:mm").split(",");

  return (
    <WrapContent isLoading={bookingState.isLoadingListBookingFlight}>
      <WrapList
        isEmpty={bookingState.listBookingFlight.length === 0}
        emptyComponent={() => <Text style={styles.textNoData}>No Data</Text>}
      >
        <FlatList
          data={bookingState.listBookingFlight}
          keyExtractor={(item, index) => index.toString()}
          ListEmptyComponent={() => (
            <Text style={styles.noDataStyle}>No Data</Text>
          )}
          renderItem={({ item }) => (
            <Card cardStyle={styles.cardDescription}>
              <Touchable
                onPress={() => history.push(`${pathname}/${item.id}${search}`)}
              >
                <>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1 }}>
                      <View style={[styles.desciption, { marginBottom: 0 }]}>
                        <Text style={styles.titleDescription}>Airlines</Text>
                      </View>
                      <Text style={styles.titleHotel}>{item.data.name}</Text>
                    </View>
                  </View>

                  <View style={styles.marginVertical}>
                    <View style={styles.desciption}>
                      <Text style={styles.titleDescription}>Flight 1</Text>
                      <View style={{ width: "75%" }}>
                        <Text style={[styles.descDate]}>
                          {`${item.data.origin} - ${item.data.destination}`}
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[styles.descDate]}>
                            {`${_splitDate(item.data.check_in)[0]} `}
                          </Text>
                          <Text
                            style={[styles.descDate, { fontWeight: "bold" }]}
                          >
                            {`${_splitDate(item.data.check_in)[1]} `}
                          </Text>
                          <Text style={[styles.descDate]}>
                            {_splitDate(item.data.check_in)[2]}
                          </Text>
                        </View>
                        <Text style={[styles.descDate]}>{item.data.class}</Text>
                      </View>
                    </View>
                  </View>
                  {/* <View style={[styles.desciption, styles.marginVertical]}>
                    <Text style={styles.titleDescription}>Facility</Text>
                    <Text style={styles.detailDescription}>
                      {item.facility.map(
                        (itemFacility: string, indexFacility: number) =>
                          `${itemFacility}${
                            indexFacility !== item.facility.length - 1
                              ? ","
                              : ""
                          } `
                      )}
                    </Text>
                  </View> */}
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Total Cost</Text>
                    <Text
                      style={[styles.detailDescription, { fontWeight: "bold" }]}
                    >
                      {`IDR ${maskedMoney(
                        item.djurneeBooking.prediction_total
                      )}`}
                    </Text>
                  </View>
                </>
              </Touchable>
            </Card>
          )}
        />
      </WrapList>
    </WrapContent>
  );
};

export default Flight;
