/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Slide, toast } from "react-toastify";
import styles from "./styles";
import "./style.css";
import { ICONS, IMAGES } from "../../../configs";
import {
  deleteFileDetailTask,
  extendDaysDetailTask,
  getParticipants,
  postActivateTask,
  postDetailTaskRemarks,
  putTask,
  resetParticipants,
  saveAddParticipants,
  setModalRecurringTask,
  uploadFileDetailTask
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import {
  documentTitle,
  heightPercent,
  permissionPage,
  textToLink,
  widthPercent
} from "../../../utils";
import {
  Button,
  Card,
  Modal,
  Space,
  TextArea,
  Touchable
} from "../../../components";
import {
  AddParticipants,
  ModerateTaskRequest,
  UpdateRecurringTask
} from "../../../components/ModalComponents";

interface Props {
  id: string;
  userId: string;
  pic: string;
  typeList?: string;
}

const Detail = ({ id, userId, pic, typeList }: Props) => {
  documentTitle("Task Detail");
  const [openParticipant, setOpenParticipant] = useState(false);
  const [openRemarks, setOpenRemarks] = useState(false);
  const [requestTime, setRequestTime] = useState(false);
  const [extendDays, setExtendDays] = useState(null);
  const [errorFileUpload, setErrorFileUpload] = useState(false);
  const [remark, setRemark] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [heightDesc, setHeightDesc]: any = useState();
  const [coba, setCoba] = useState(false);
  const [modalApproval, setModalApproval] = useState(false);
  const [modalActivate, setModalActivate] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const permission = {
    selfEdit: permissionPage("task-self-edit"),
    assign: permissionPage("task-assign"),
    create: permissionPage("task-create")
  };

  const detailTask = useSelector((state: Reducers) => state.task)
    .detailTaskData;

  const {
    isLoadingDetailTaskData,
    isLoadingRemarks,
    isLoadingActivateTask
  } = useSelector((state: Reducers) => state.task);

  const userProfile = useSelector((state: Reducers) => state.home.myProfile);
  const auth = useSelector((state: Reducers) => state.auth);
  const detailTaskFull = useSelector((state: Reducers) => state.task);

  const employeeId =
    userProfile && userProfile.employee && userProfile.employee.id;

  const selfEdit = permissionPage("invoice-self-edit");

  useEffect(() => {
    dispatch(resetParticipants());
  }, [dispatch]);

  const _HandleExtendDays = (days: number | null, expectedLastDays: string) => {
    if (days !== null) {
      const lastDay = moment(expectedLastDays)
        .add(days, "days")
        .format("YYYY-MM-DD hh:mm:ss");
      dispatch(extendDaysDetailTask(id, days, lastDay));
    }
    setExtendDays(null);
    setRequestTime(false);
  };

  const _handlePostTaskRemark = () => {
    dispatch(postDetailTaskRemarks(id, remark));
    setRemark("");
    setOpenRemarks(false);
  };

  const _handleUploadFileDetailTask = (file: any) => {
    const fileSize = file.size;
    if (fileSize > auth.myConfig.max_size) {
      setErrorFileUpload(true);
    } else {
      dispatch(uploadFileDetailTask(id, file.name, file));
      setErrorFileUpload(false);
    }
  };

  const _handleDeleteFileDetailTask = (index: number, fileId: string) => {
    dispatch(deleteFileDetailTask(id, fileId, index));
  };

  const _goToPreview = (index: number) => {
    const files = detailTask.file_collection;
    if (
      files[index].file.extension === "pdf" ||
      files[index].file.extension === "docx" ||
      files[index].file.extension === "doc" ||
      files[index].file.extension === "xls" ||
      files[index].file.extension === "xlsx"
    ) {
      window.open(files[index].file.url);
    } else if (files[index].isNew) {
      if (
        files[index].file.file.type === "application/pdf" ||
        files[index].file.file.type === "application/vnd.ms-excel" ||
        files[index].file.file.type === ".doc" ||
        files[index].file.file.type === ".xls" ||
        files[index].file.file.type === ".xlsx" ||
        files[index].file.file.type === ".docx"
      ) {
        window.open(URL.createObjectURL(files[index].file.file));
      } else {
        const newTab = window.open();
        newTab!.document.body.innerHTML = `
      <div
        style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
      >
        <img src="${URL.createObjectURL(files[index].file.file)}">
      </div>`;
      }
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
      <div  
        style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
      >
        <img src="${files[index].file.url}">
      </div>`;
    }
  };

  const _getParticipants = useCallback((list: any[]) => {
    if (list.length > 1) {
      let result: any[] = [];
      list.map(e => {
        result = [...result, e.id];
        return true;
      });

      return result.join(",");
    }
    return list[0].id;
  }, []);

  const _checkAccess = useCallback(() => {
    let result = false;

    if (detailTask) {
      const index =
        detailTask.assignee &&
        detailTask.assignee.findIndex((item: any) => item.pic === true);
      result = detailTask.assignee[index].employee.id === auth.userId;
    }

    return (
      (detailTask &&
        detailTask.assigner &&
        auth.userId === detailTask.assigner.uuid) ||
      result
    );
  }, [detailTask, auth.userId]);

  const _handleRequestApproval = () => {
    setModalApproval(true);
  };

  const _handleActivateTask = () => {
    setModalActivate(true);
  };

  const _toEditTask = (taskId: string, recurring?: "recurring" | null) => {
    if (permission.assign || permission.create) {
      history.push(
        `/edit-task/${taskId}/${typeList}/${detailTask?.project?.id}/${recurring}`
      );
    } else {
      toast.error("CANNOT ACCESS TASK DETAIL", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      });
    }
  };

  const _activateTask = () => {
    dispatch(postActivateTask(String(id)));
    setModalActivate(false);
    history.push("/task");
  };

  useEffect(() => {
    if (heightDesc && heightDesc < 54) {
      setCoba(false);
    }
  }, [isLoadingDetailTaskData]);

  const _handleConditionRecurring = () => {
    return detailTask && detailTask.recurring === true;
  };

  const _recurringStatus = (recur: number) => {
    let result = "";
    if (recur === 4) {
      result = t("task.detail.yearly");
    } else if (recur === 3) {
      result = t("task.detail.monthly");
    } else if (recur === 2) {
      result = t("task.detail.weekly");
    } else if (recur === 1) {
      result = t("task.detail.daily");
    } else {
      result = "-";
    }
    return result;
  };

  return (
    <>
      {detailTask &&
      auth &&
      !isLoadingDetailTaskData &&
      !isLoadingRemarks &&
      !isLoadingActivateTask ? (
        <View style={styles.containerContent}>
          {!requestTime ? (
            <View style={styles.detailTask}>
              <View style={styles.contentWrapper}>
                <View style={{ width: "30%" }}>
                  <Text style={styles.attributeTask}>
                    {t("task.detail.taskName")}
                  </Text>
                </View>
                <View style={{ width: "70%" }}>
                  <Text style={styles.valueTask}>{detailTask.title}</Text>
                </View>
              </View>
              <View style={styles.contentWrapper}>
                <View style={{ width: "30%" }}>
                  <Text style={styles.attributeTask}>
                    {" "}
                    {t("task.detail.project")}
                  </Text>
                </View>
                <View style={{ width: "70%" }}>
                  <Text style={styles.valueTask}>
                    {detailTask.project ? detailTask.project.title : " - "}
                  </Text>
                </View>
              </View>
              <View style={styles.contentWrapper}>
                <View style={{ width: "30%" }}>
                  <Text style={styles.attributeTask}>
                    {" "}
                    {t("task.detail.assignedTo")}
                  </Text>
                </View>
                <View style={{ width: "70%" }}>
                  <Text style={styles.valueTask}>
                    {detailTask.assignee.map((assignee: any, index: number) =>
                      index === detailTask.assignee.length - 1
                        ? assignee.employee.name
                        : `${assignee.employee.name}, `
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.contentWrapper}>
                <View style={{ width: "30%" }}>
                  <Text style={styles.attributeTask}>
                    {" "}
                    {t("task.detail.assignBy")}
                  </Text>
                </View>
                <View style={{ width: "70%" }}>
                  <Text style={styles.valueTask}>
                    {detailTask.assigner && detailTask.assigner.name
                      ? detailTask.assigner.name
                      : "-"}
                  </Text>
                </View>
              </View>

              <View style={{ width: "100%" }}>
                <View style={styles.dateTaskWrapper}>
                  <View style={{ width: "30%" }}>
                    <Text style={styles.attributeTask}>
                      {" "}
                      {t("task.detail.starting")}
                    </Text>
                  </View>
                  <View style={{ width: "70%" }}>
                    <Text style={styles.attributeTask}>
                      {moment(detailTask.started_at).format("ddd ")}
                      <Text style={styles.valueTask}>
                        {moment(detailTask.started_at).format("DD MMM YYYY")}
                      </Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.dateTaskWrapper}>
                  <View style={{ width: "30%" }}>
                    <Text style={styles.attributeTask}>
                      {detailTask && detailTask.status === 6
                        ? t("task.detail.finished")
                        : t("task.detail.expected")}
                    </Text>
                  </View>
                  {detailTask && detailTask.status === 6 ? (
                    <View style={{ width: "70%" }}>
                      <View>
                        <Text style={styles.attributeTask}>
                          {moment(detailTask.finished_at).format("ddd ")}
                          <Text style={styles.valueTask}>
                            {moment(detailTask.finished_at).format(
                              "DD MMM YYYY"
                            )}
                          </Text>
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <View style={{ width: "70%" }}>
                      {detailTask.history_extends.map(
                        (historyExtend: any, index: number) => (
                          <View key={index}>
                            <Text style={styles.attributeTask}>
                              {moment(historyExtend).format("ddd ")}
                              <Text style={styles.valueTask}>
                                {moment(historyExtend).format("DD MMM YYYY")}
                              </Text>
                            </Text>
                          </View>
                        )
                      )}
                    </View>
                  )}
                </View>

                {detailTask &&
                  detailTask.status < 6 &&
                  detailTask.status !== 2 &&
                  detailTask.status !== 1 &&
                  detailTask.is_team &&
                  _checkAccess() && (
                    <TouchableOpacity
                      style={styles.addParticipant}
                      activeOpacity={0.8}
                      onPress={() => {
                        dispatch(getParticipants());
                        setTimeout(() => {
                          setOpenParticipant(true);
                        }, 70);
                      }}
                    >
                      <Image
                        source={IMAGES.plus}
                        style={styles.imagePlusAddParticipant}
                      />
                      <Text style={styles.attributeTask}>
                        {t("task.detail.participants")}
                      </Text>
                    </TouchableOpacity>
                  )}
              </View>
              {pic === userId &&
              detailTask &&
              detailTask.status < 6 &&
              detailTask.status !== 2 &&
              detailTask.status !== 1 &&
              permission.selfEdit ? (
                <TouchableOpacity
                  style={[styles.button, { marginTop: 8 }]}
                  onPress={() => setRequestTime(true)}
                >
                  <Text style={styles.textButton}>
                    {" "}
                    {t("task.detail.requestMoreTime")}
                  </Text>
                </TouchableOpacity>
              ) : null}
            </View>
          ) : (
            <View style={styles.extendsTaskWrapper}>
              <View style={{ flexDirection: "row" }}>
                <Text style={[{ marginVertical: 10 }, styles.attributeTask]}>
                  <Text>{t("task.detail.extend")}</Text>
                  <TextInput
                    onChangeText={(inputDays: any) => {
                      setExtendDays(inputDays);
                    }}
                    keyboardType="numeric"
                    placeholder={t("task.detail.howMany")}
                    style={styles.inputExtends}
                  />
                  {t("task.detail.days")}
                </Text>
              </View>
              <View style={styles.buttonWrapperExtends}>
                <TouchableOpacity
                  style={[styles.btnCancel, { marginTop: 8 }]}
                  onPress={() => setRequestTime(false)}
                >
                  <Text style={styles.textButton}>
                    {t("task.detail.cancel")}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.btnSubmit, { marginTop: 8 }]}
                  onPress={() =>
                    _HandleExtendDays(
                      extendDays,
                      detailTask.history_extends[
                        detailTask.history_extends.length - 1
                      ]
                    )
                  }
                >
                  <Text style={styles.textButton}>
                    {t("task.detail.submit")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
          <View style={[{ width: "100%", marginTop: 12 }, styles.card]}>
            <View style={styles.descriptionWrapper}>
              <View style={{ width: "30%" }}>
                <Text style={styles.attributeTask}>
                  {t("task.detail.description")}
                </Text>
              </View>
              <View style={{ width: "70%" }}>
                <Text
                  style={styles.attributeTask}
                  numberOfLines={showMore && coba ? undefined : 3}
                  onLayout={(e: any) => {
                    setHeightDesc(e.nativeEvent.layout.height);
                    if (!coba && e.nativeEvent.layout.height > 53) {
                      setCoba(true);
                    }
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={textToLink(detailTask.description)}
                  />
                </Text>
                {coba ? (
                  <Text
                    style={[
                      styles.attributeTask,
                      { color: "#50b1b1", marginTop: 20 }
                    ]}
                    onPress={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show Less" : "Show More"}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
          {detailTaskFull.isLoadingUploadFileDetailTask ||
          detailTaskFull.isLoadingDeleteFileDetailTask ? (
            <ActivityIndicator style={{ marginTop: 20 }} />
          ) : (
            <View style={styles.attachmentWrapper}>
              {detailTask.file_collection.map((file: any, index: number) => (
                <TouchableOpacity
                  onPress={() => _goToPreview(index)}
                  key={index}
                  style={[
                    styles.fileAttachment,
                    styles.card,
                    styles.attachmentFileWrapper
                  ]}
                >
                  <View style={{ width: "90%" }}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ width: "85%" }}>
                        <Text numberOfLines={1} style={styles.attributeTask}>
                          {file.file.title}
                        </Text>
                      </View>
                      {detailTask &&
                        detailTask.status < 6 &&
                        detailTask.status !== 2 &&
                        permission.selfEdit && (
                          <TouchableOpacity
                            style={styles.deleteFile}
                            onPress={(e: any) => {
                              e.stopPropagation();
                              _handleDeleteFileDetailTask(
                                index,
                                file.id ? file.id : file.file.id
                              );
                            }}
                          >
                            <Image
                              source={ICONS.iconDeleteFile}
                              resizeMode="contain"
                              style={{ width: 12, height: 12 }}
                            />
                          </TouchableOpacity>
                        )}
                    </View>
                  </View>
                </TouchableOpacity>
              ))}
              {detailTask &&
              detailTask.status < 6 &&
              detailTask.status !== 2 &&
              permission.selfEdit ? (
                <TouchableOpacity style={styles.button}>
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      fontSize: 11
                    }}
                  >
                    <input
                      accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                  application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      type="file"
                      onChange={(e: any) => {
                        _handleUploadFileDetailTask(e.target.files[0]);
                      }}
                      onClick={(event: any) => {
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = null;
                      }}
                    />
                    {t("task.detail.attachment")}
                  </label>
                </TouchableOpacity>
              ) : null}
            </View>
          )}
          {errorFileUpload ? (
            <View style={{ width: "100%", marginTop: 12 }}>
              <Text style={{ fontSize: 11, color: "red" }}>
                {`File size is too big on current upload, please upload another file (Maximum ${
                  auth.myConfig.max_size / 1000000
                } MB)`}
              </Text>
            </View>
          ) : null}
          {_handleConditionRecurring() ? (
            <View>
              <Text
                style={[
                  styles.poppins,
                  styles.requestLabel,
                  { marginVertical: 16 }
                ]}
              >
                {t("task.detail.repeatTask")}
              </Text>
              <Card
                disabled={
                  !(
                    selfEdit &&
                    detailTask.status <= 5 &&
                    detailTask.creator.id === auth.uuid
                  )
                }
                cardStyle={styles.recuringCard}
                onPress={() => dispatch(setModalRecurringTask(true))}
              >
                <Text style={[styles.poppins, styles.requestValue]}>
                  {_recurringStatus(detailTask && detailTask.recurring_period)}
                </Text>
              </Card>
            </View>
          ) : null}
          {detailTask && detailTask.status !== 1 && detailTask.status !== 2 ? (
            <>
              <Text style={[styles.valueTask, { marginVertical: 16 }]}>
                {t("task.detail.remarks")}
              </Text>
              {!openRemarks && detailTaskFull.remarks ? (
                <View
                  style={{ overflow: "scroll", minHeight: 50, maxHeight: 150 }}
                >
                  {detailTaskFull.remarks.comments.map(
                    (itemRemark: any, index: number) => (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          marginBottom: 12
                        }}
                        key={index}
                      >
                        <View style={[styles.card, styles.avatarContainer]}>
                          <Image
                            source={
                              itemRemark.creator.avatar
                                ? itemRemark.creator.avatar.url
                                : IMAGES.avatar
                            }
                            resizeMode="contain"
                            style={{ width: 32, height: 32, borderRadius: 100 }}
                          />
                        </View>
                        <View style={styles.remarksContainer}>
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row"
                            }}
                          >
                            <View style={{ width: "50%" }}>
                              <Text
                                style={[styles.valueTask, { marginLeft: 12 }]}
                              >
                                {itemRemark.creator.name}
                              </Text>
                            </View>
                            <View style={{ width: "50%" }}>
                              <Text
                                style={[
                                  { alignSelf: "flex-end" },
                                  styles.attributeTask
                                ]}
                              >
                                {moment(itemRemark.created_at).format("ddd ")}
                                <Text style={{ fontWeight: "bold" }}>
                                  {moment(itemRemark.created_at).format(
                                    "DD MMM YY "
                                  )}
                                </Text>
                                {moment(itemRemark.created_at).format("HH:mm")}
                              </Text>
                            </View>
                          </View>
                          <div
                            className="attributes-task"
                            dangerouslySetInnerHTML={textToLink(
                              itemRemark.content
                            )}
                            style={{ marginLeft: 12 }}
                          />
                        </View>
                      </View>
                    )
                  )}
                </View>
              ) : (
                <View style={{ width: "100%" }}>
                  {openRemarks ? (
                    <TextArea
                      onChange={e => setRemark(e.target.value)}
                      autoFocus
                      defaultHeight="one-row"
                    />
                  ) : null}
                </View>
              )}

              {openRemarks ? (
                <View style={styles.buttonRemarks}>
                  <TouchableOpacity
                    style={[styles.btnCancel, { marginTop: 8 }]}
                    onPress={() => setOpenRemarks(false)}
                  >
                    <Text style={styles.textButton}>
                      {t("task.detail.cancel")}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.btnSubmit, { marginTop: 8 }]}
                    onPress={() => _handlePostTaskRemark()}
                  >
                    <Text style={styles.textButton}>
                      {t("task.detail.send")}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View
                  style={
                    detailTask && detailTask.status < 6 && permission.selfEdit
                      ? [styles.button, { marginTop: 8, marginBottom: 20 }]
                      : null
                  }
                >
                  {detailTask &&
                  detailTask.status < 6 &&
                  detailTask.status !== 1 &&
                  detailTask.status !== 2 &&
                  permission.selfEdit ? (
                    <TouchableOpacity
                      style={[styles.button, { width: "100%", height: "100%" }]}
                      onPress={() => setOpenRemarks(true)}
                    >
                      <Text style={styles.textButton}>
                        {t("task.detail.addRemarks")}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                </View>
              )}
            </>
          ) : (
            <></>
          )}
          {detailTask &&
            detailTask.status === 2 &&
            detailTask.assigner.uuid === employeeId && (
              <View
                style={{
                  marginBottom: 40,
                  marginTop: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  width: widthPercent(100),
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <Touchable onPress={() => _handleRequestApproval()}>
                  <View style={styles.buttonRequestActions}>
                    <Text style={styles.buttonTextWhite}>
                      {t("task.detail.approval")}
                    </Text>
                  </View>
                </Touchable>
              </View>
            )}
          {(detailTask.status === 1 ||
            (detailTask.recurring && detailTask.status === 5) ||
            (detailTask.recurring && detailTask.status === 3)) && (
            <View
              style={{
                marginBottom: 40,
                marginTop: 40,
                justifyContent: "center",
                alignItems: "center",
                width: widthPercent(100),
                display: "flex",
                flexDirection: "row"
              }}
            >
              <View>
                <Touchable
                  onPress={() =>
                    _toEditTask(
                      detailTask.id,
                      detailTask.recurring ? "recurring" : null
                    )
                  }
                >
                  <View style={styles.buttonRequestActions}>
                    <Text style={styles.buttonTextWhite}>
                      {t("task.detail.edit")}
                    </Text>
                  </View>
                </Touchable>
                <Space height={8} />
                {detailTask.status === 1 && (
                  <Touchable onPress={() => _handleActivateTask()}>
                    <View style={styles.buttonRequestBlue}>
                      <Text style={styles.buttonTextWhite}>
                        {t("task.detail.submit")}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </View>
            </View>
          )}
        </View>
      ) : (
        <ActivityIndicator />
      )}

      <Modal isVisible={openParticipant} backdropColor="rgba(255,255,255, 0.9)">
        <AddParticipants
          isUpdate
          onPress={e => {
            dispatch(saveAddParticipants());
            e!.length > 0 &&
              dispatch(
                putTask(id, { participants: _getParticipants(e!) }, () =>
                  setOpenParticipant(false)
                )
              );
          }}
          onCancel={() => setOpenParticipant(false)}
        />
      </Modal>
      <Modal isVisible={modalApproval} backdropColor="rgba(255,255,255, 0.9)">
        <View>
          <ModerateTaskRequest
            taskId={detailTask.id}
            moderationId={
              detailTask && detailTask.moderation && detailTask.moderation.id
            }
            onCancel={() => setModalApproval(false)}
          />
        </View>
      </Modal>
      <Modal isVisible={modalActivate} backdropColor="rgba(255,255,255, 0.9)">
        <View
          style={{
            width: widthPercent(100),
            height: heightPercent(100)
          }}
        >
          <View style={styles.containerModal}>
            <Image
              source={IMAGES.rejectModal}
              style={{ height: 80, width: 80, marginBottom: 40 }}
            />
            <Text style={styles.textWarning}>
              {t("task.detail.textWarning")}
            </Text>
          </View>
          <View style={styles.btnModal}>
            <View style={{ alignItems: "center" }}>
              <Button
                title={t("task.detail.yes")}
                onPress={() => _activateTask()}
              />
              <TouchableOpacity
                style={{ marginTop: 17 }}
                onPress={() => setModalActivate(false)}
              >
                <Text style={styles.cancelStyle}>{t("task.detail.no")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        isVisible={detailTaskFull.setModalRecurring}
        backdropColor="rgba(255,255,255, 0.9)"
      >
        <UpdateRecurringTask
          onCancel={() => dispatch(setModalRecurringTask(false))}
        />
      </Modal>
    </>
  );
};
export default Detail;
