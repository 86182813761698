import { SAVE_ROUTE } from "../actions";
import { Action, SaveRouteState } from "../types";

const initialState: SaveRouteState = {
  pathname: ""
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case SAVE_ROUTE:
      return { ...state, pathname: payload.data };
    default:
      return state;
  }
};
