/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Text, View } from "react-native";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// import { toast } from "react-toastify";
import {
  CustomNotification,
  Fixed,
  Header,
  Modal,
  Touchable
} from "../../../components";
import styles from "./styles";
import { Footer } from "../components";
import {
  getPopulateCategory,
  getTermDataActivity,
  postSubmitActivity,
  postTermDataActivity
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { copyWritings } from "../../../utils";

// screen
import Allowance from "./Allowance";
import CashAdvance from "./CashAdvance";
import { IMAGES } from "../../../configs";

const NewExpense = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const { sprintf } = require("sprintf-js");
  const dispatch = useDispatch();
  const { pathname } = history.location;

  const [heighHeader, setHeightHeader] = useState(0);
  const [heighFooter, setHeightFooter] = useState(0);
  const [modal, setModal] = useState(false);
  const { tripState, newTripState } = useSelector(
    (state: Reducers) => ({
      tripState: state.trip,
      newTripState: state.newTrip
    }),
    shallowEqual
  );

  useEffect(() => {
    if (tripState.activity.getData) {
      dispatch(getTermDataActivity());
      dispatch(getPopulateCategory());
    }
  }, [dispatch, tripState.activity.getData]);

  const copText = {
    lump_sum: copyWritings("lump_sum"),
    cash_advance: copyWritings("cash_advance"),
    expense: copyWritings("expense"),
    trip: copyWritings("trip"),
    booking: copyWritings("booking")
  };

  const listPages = [
    {
      label: copText.lump_sum,
      component: <Allowance />,
      exact: true,
      pathname: match.path
    },
    {
      label: copText.cash_advance,
      component: <CashAdvance />,
      exact: false,
      pathname: `${match.path}/cashadvance`
    }
  ];

  const _getCategory = useCallback(() => {
    const index = newTripState.listPopulateCategory.findIndex(
      e =>
        e.value ===
        (tripState.activity.data.new_activity &&
          tripState.activity.data.new_activity.category_id)
    );

    return (
      newTripState.listPopulateCategory[index] &&
      newTripState.listPopulateCategory[index].value !== "" &&
      newTripState.listPopulateCategory[index].label
    );
  }, [newTripState.listPopulateCategory, tripState.activity.data.new_activity]);

  return (
    <>
      <Fixed getHeight={e => setHeightHeader(e)}>
        <View style={styles.header}>
          <Header
            title={String(sprintf(t("activity.new.newCop"), copText.expense))}
            subtitle={
              newTripState.isLoadingPopulateCategory
                ? "Loading..."
                : _getCategory()
            }
            label={t("activity.status.draft")}
            goBack={() => history.push("/newtrip")}
          />
          <View style={styles.containerNav}>
            <View style={styles.wrapNav}>
              {listPages.map((item, index) => (
                <View key={index} style={{ flex: 1 }}>
                  <Touchable onPress={() => history.push(item.pathname)}>
                    <View
                      style={[
                        styles.buttonTab,
                        item.pathname === pathname && styles.activeButton
                      ]}
                    >
                      <Text style={styles.labelTab}>{item.label}</Text>
                    </View>
                  </Touchable>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Fixed>
      <View
        style={[
          styles.content,
          { paddingTop: heighHeader, paddingBottom: heighFooter }
        ]}
      >
        {listPages.map((item, index) => (
          <Route path={item.pathname} exact={item.exact} key={index}>
            {item.component}
          </Route>
        ))}
      </View>
      <Fixed getHeight={e => setHeightFooter(e)} position="bottom">
        <Footer
          onPressSave={
            !tripState.activity.isLoading
              ? () => dispatch(postTermDataActivity())
              : undefined
          }
          onPressNext={
            !tripState.activity.isLoading && !tripState.allowance.isLoading
              ? () => setModal(true)
              : undefined
          }
        />
      </Fixed>
      <Modal isVisible={modal} onBackdropPress={() => setModal(false)}>
        <CustomNotification
          subButtonPress={() => history.push("/newtrip/booking")}
          closeBtn={() => setModal(false)}
          buttonPress={() =>
            dispatch(
              postSubmitActivity(e => {
                if (e) {
                  setModal(false);
                  history.push("/trip");
                } else {
                  setModal(false);
                  history.push("/trip");
                }
              })
            )
          }
          icon={IMAGES.ask}
          notificationTitle={t("activity.detail.submit")}
          desc={sprintf(
            t("activity.detail.submitMsg"),
            copText.trip,
            copText.booking,
            copText.booking,
            copText.booking
          )}
          textBtnAction={sprintf(t("activity.detail.submitTrip"), copText.trip)}
          isLoading={
            tripState.isLoadingSubmitAtivity || newTripState.isLoadingEditTrip
          }
          booking
        />
      </Modal>
    </>
  );
};

export default NewExpense;
