import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.main
  },
  navigation: {
    width: widthPercent(60),
    flexDirection: "row",
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  active: {
    width: "50%",
    height: "100%",
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)"
  },
  btnNav: {
    width: "50%",
    height: "100%",
    borderRadius: 50
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  containerNav: {
    paddingHorizontal: 20,
    marginTop: 16
  },
  wrapNav: {
    flexDirection: "row",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    borderRadius: 28,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  buttonTab: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 6
  },
  activeButton: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    flex: 1
  },
  labelTab: {
    fontFamily: "poppins",
    fontWeight: "bold",
    fontSize: 11,
    color: COLORS.white
  }
});

export default styles;
