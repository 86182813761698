import React from "react";
import { Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { IMAGES } from "../../../configs";
import { putResolveTrip } from "../../../redux/actions";
import styles from "./styles";
import { Touchable } from "../../../components";

interface Props {
  cancel?: () => void;
  idTrip?: string;
}

const ModalRevise = ({ cancel, idTrip }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handlePutResolveTrip = async () => {
    await dispatch(putResolveTrip(idTrip!));
    history.push("/trip");
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>RESOLVE YOUR PROJECT</Text>
      <Image
        resizeMode="contain"
        source={IMAGES.rejectModal}
        style={styles.image}
      />
      <Image
        resizeMode="contain"
        source={IMAGES.resolvePerson}
        style={{ height: 205.8, width: 160, marginRight: 50, marginTop: 10 }}
      />
      <View>
        <Text style={styles.content}>
          this action will
          <Text style={{ fontWeight: "bold" }}> send notification </Text>
          to your approver to be checked again and will turn your trip back to
          <Text style={{ fontWeight: "bold" }}> SUBMITTED.</Text>
        </Text>
      </View>
      <View style={styles.buttonAttachmen}>
        <Touchable onPress={() => handlePutResolveTrip()}>
          <View
            style={{
              width: 180,
              height: 54,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Text style={styles.buttonText}>RESOLVE</Text>
          </View>
        </Touchable>
      </View>
      <View style={{ marginTop: 17 }}>
        <Touchable onPress={cancel || (() => history.goBack())}>
          <Text style={styles.cancel}>CANCEL</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default ModalRevise;
