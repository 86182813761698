import React, { useState } from "react";
import { Image, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./styles";
import { IMAGES } from "../../../configs";
import {
  Card,
  Fixed,
  Modal,
  Touchable,
  WrapContent
} from "../../../components";
import { Reducers } from "../../../redux/types";
import {
  convertDate,
  documentTitle,
  heightPercent,
  queryString
} from "../../../utils";
import PerDiem from "./PerDiem";
import CashAdvance from "./CashAdvance";
import Reimburse from "./Reimburse";
import NoteRevise from "../ModalNoteRevise";
import ModalResolve from "../ModalResolve";

interface Props {
  id: string;
  search: string;
  top: number;
  offsetTop: number;
}

const Expense = ({ id, search, top, offsetTop }: Props) => {
  documentTitle("Trip Detail - Expense");
  const match = useRouteMatch();
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const [noteReviseOpen, setNoteReviseOpen] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const { pathname } = history.location;
  const dataTrip = queryString(search);
  const { tripData, isLoadingTripById } = useSelector(
    (state: Reducers) => state.trip
  );
  const listPages = [
    {
      pathname: `${match.path}`,
      component: <PerDiem id={id!} />,
      exact: true
    },
    {
      pathname: `${match.path}/cash-advance`,
      component: <CashAdvance id={id!} />,
      exact: true
    },
    {
      pathname: `${match.path}/reimburse`,
      component: <Reimburse id={id!} search={search} />,
      exact: true
    }
  ];

  const _openResolveModal = () => {
    setNoteReviseOpen(false);
    setResolveOpen(true);
  };

  const dateFormat = (start: string, end: string) => {
    return `${convertDate(start)} - ${convertDate(end)}`;
  };

  return (
    <Switch>
      <WrapContent isLoading={isLoadingTripById}>
        <Fixed
          getHeight={e => setHeightHeader(e)}
          customPosition={{ top: top - offsetTop }}
        >
          <View style={styles.bookingpDetailContent}>
            <Card
              cardStyle={[styles.cardDescription, { paddingTop: offsetTop }]}
            >
              <View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Code</Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#999999",
                        fontFamily: "Poppins"
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      {tripData ? tripData.number : "-"}
                    </Text>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#999999",
                        fontFamily: "Poppins"
                      }}
                    >
                      {tripData ? tripData.category.title : "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Team</Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      {tripData
                        ? tripData.users.map(
                            (data: any) => `${data.user.name}, `
                          )
                        : "-"}
                    </Text>
                  </View>
                </View>

                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>Date</Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      {tripData
                        ? dateFormat(tripData.started_at, tripData.finished_at)
                        : "-"}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    marginTop: 10,
                    alignItems: "center"
                  }}
                >
                  <View style={styles.navigation}>
                    <View
                      style={
                        listPages[0].pathname === pathname
                          ? [styles.active]
                          : [styles.btnNav]
                      }
                    >
                      <Touchable
                        onPress={() =>
                          history.push(listPages[0].pathname + search)
                        }
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingVertical: 6
                          }}
                        >
                          <Text style={styles.textButton}>Per Diem</Text>
                        </View>
                      </Touchable>
                    </View>

                    <View
                      style={
                        listPages[1].pathname === pathname
                          ? styles.active
                          : [styles.btnNav]
                      }
                    >
                      <Touchable
                        onPress={() =>
                          history.push(listPages[1].pathname + search)
                        }
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingVertical: 6
                          }}
                        >
                          <Text style={styles.textButton}>Cash Advance</Text>
                        </View>
                      </Touchable>
                    </View>
                    <View
                      style={
                        listPages[2].pathname === pathname
                          ? styles.active
                          : [styles.btnNav]
                      }
                    >
                      <Touchable
                        onPress={() =>
                          history.push(listPages[2].pathname + search)
                        }
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingVertical: 6
                          }}
                        >
                          <Text style={styles.textButton}>Reimburse</Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </View>
            </Card>
          </View>
        </Fixed>
        {dataTrip.status === "4" && (
          <div
            style={{
              position: "fixed",
              zIndex: 1,
              paddingLeft: 14,
              paddingBottom: 21.7,
              paddingTop: 5,
              alignSelf: "flex-start",
              bottom: 56
            }}
          >
            <Touchable onPress={() => setNoteReviseOpen(true)}>
              <Image
                style={{ width: 62.6, height: 62.6 }}
                source={IMAGES.reviseAlert}
              />
            </Touchable>
          </div>
        )}
        <Modal isVisible={noteReviseOpen} wrapStyle={styles.modalStyle}>
          <NoteRevise
            onPress={() => setNoteReviseOpen(false)}
            onPressRevise={() => _openResolveModal()}
          >
            <Text>
              {tripData !== null &&
                tripData.moderation !== null &&
                typeof tripData !== "undefined" &&
                typeof tripData.moderation !== "undefined" &&
                typeof tripData.moderation.remarks !== "undefined" &&
                tripData.moderation.remarks}
            </Text>
          </NoteRevise>
        </Modal>
        {resolveOpen && (
          <Modal isVisible={resolveOpen}>
            <ModalResolve
              cancel={() => setResolveOpen(false)}
              idTrip={`${id}`}
            />
          </Modal>
        )}

        <View
          style={{
            marginTop: heightHeader - offsetTop,
            minHeight: heightPercent(100) - (heightHeader + top - offsetTop)
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} key={index} exact={item.exact}>
              {item.component}
            </Route>
          ))}
        </View>
      </WrapContent>
    </Switch>
  );
};

export default Expense;
