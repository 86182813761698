import {
  GET_PROJECT_CA_ERROR,
  GET_PROJECT_CA_PENDING,
  GET_PROJECT_CA_SUCCESS,
  GET_PROJECT_ERROR,
  GET_PROJECT_PENDING,
  GET_PROJECT_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, ProjectState, SelectOptions } from "../types";

const initialState: ProjectState = {
  listProject: [],
  listCategory: [],
  isLoadingProject: false
};

let listProject: SelectOptions[];
let listCategory: SelectOptions[];

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_PROJECT_PENDING:
      return { ...state, isLoadingProject: true };
    case GET_PROJECT_SUCCESS:
      listProject = [];
      payload.data.map((item: any) => {
        listProject = [...listProject, { value: item.id, label: item.title }];
      });

      return {
        ...state,
        isLoadingProject: false,
        listProject
      };
    case GET_PROJECT_ERROR:
      return { ...state, isLoadingProject: false };
    case GET_PROJECT_CA_PENDING:
      return { ...state, isLoadingProject: true };
    case GET_PROJECT_CA_SUCCESS:
      listCategory = [];
      payload.data.map((item: any) => {
        listCategory = [
          ...listCategory,
          { value: item.uuid, label: item.title }
        ];
      });
      return { ...state, isLoadingProject: false, listCategory };
    case GET_PROJECT_CA_ERROR:
      return { ...state, isLoadingProject: false };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
