import { StyleSheet } from "react-native";
import { COLORS } from "../../../configs";
// import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  cardStyle: {
    marginBottom: 16
  },
  wrapContentCard: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 12
  },
  wrapCardText: {
    flexDirection: "row",
    marginBottom: 4
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "23%"
  },
  textValue: {
    width: "67%",
    marginRight: 7
  },
  textValueProject: {
    width: "100%"
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.grey
  },
  big: {
    fontSize: 14
  },
  wrapTrashIcon: {
    height: 26,
    width: 26,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center"
  },
  emptyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  }
});

export default styles;
