import React from "react";
import { Text, View } from "react-native";

import { documentTitle } from "../../../../../../utils";

const CashAdvance = () => {
  documentTitle("Detail Activity - Cash Advance");

  return (
    <View>
      <Text>CashAdvance</Text>
    </View>
  );
};

export default CashAdvance;
