/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { sprintf } from "sprintf-js";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import "./style.css";
import {
  Button,
  FileUpload,
  Header,
  Message,
  Space,
  TextArea,
  Touchable,
  Upload,
  WrapContent
} from "../../../components";
import { Reducers } from "../../../redux/types";
import {
  addCashItem,
  getBudgetRemaining,
  getBudgetType,
  getCashDetail,
  getCashItemById,
  getPopulate,
  resetItemCash
} from "../../../redux/actions";
import {
  copyWritings,
  getPolicies,
  permissionPage,
  queryString
} from "../../../utils";

const AddItemCash = () => {
  const { idInvoice } = useParams() as any;
  const history = useHistory();
  const { search } = history.location as any;
  const param = queryString(search);

  const { cashState, listPopulate, expenseState, auth } = useSelector(
    (state: Reducers) => ({
      cashState: state.cash,
      listPopulate: state.newExpense.listPopulate,
      expenseState: state.expense,
      auth: state.auth
    }),
    shallowEqual
  );

  const { t } = useTranslation();
  const wording = {
    policy: copyWritings("policy")
  };

  const invoiceOthersEdit = permissionPage("invoice-others-edit");
  const invoiceSelfEdit = permissionPage("invoice-self-edit");
  const policyTripRM = getPolicies("expense-trip-rm-receipt");
  const invoiceType = cashState.cashDetail?.djurneeInvoice?.type?.title;
  const projectClientId =
    cashState.cashDetail?.djurneeInvoice?.project?.client_id || 0;

  const { control, handleSubmit, errors, reset, watch, getValues } = useForm();
  const dispatch = useDispatch();

  const [file, setFile]: any = useState(null);
  const [budgetId, setBudgetId] = useState("");
  const [budgetRemaining, setBudgetRemaining] = useState({
    name: "",
    maxAmount: 0
  });

  const _findBudgetRemaining = (idBudget: string) => {
    if (idBudget && expenseState.budgetRemaining.length > 0) {
      const remaining: any = expenseState.budgetRemaining.find(
        e => e.id === idBudget
      );
      setBudgetId(idBudget);
      setBudgetRemaining({
        ...budgetRemaining,
        name: remaining?.attributes?.title,
        maxAmount: remaining?.attributes?.remaining
      });
    }
  };

  useEffect(() => {
    _findBudgetRemaining(budgetId);
  }, [budgetId, expenseState.budgetRemaining.length]);

  useEffect(() => {
    if (param.id_item) {
      dispatch(
        getCashItemById(param.id_item, dataItem => {
          const defaultValues: any = {};
          defaultValues.category_id = dataItem?.category?.id || "";
          defaultValues.title = dataItem?.item?.title;
          defaultValues.description = dataItem?.item?.description;
          defaultValues.amount = Number(dataItem?.item?.total);
          reset({ ...defaultValues });
          setBudgetId(dataItem?.budget?.budget?.id);
          setFile(JSON.parse(dataItem?.item?.file));
        })
      );
    }
    if (param.project_id) {
      dispatch(getBudgetType(param.project_id));
      dispatch(getBudgetRemaining(param.project_id));
    }
    dispatch(
      getPopulate(
        cashState.cashHistory && cashState.cashHistory.djurneeInvoice.type.title
      )
    );
    dispatch(getCashDetail(idInvoice));
  }, [dispatch, param.id_item, param.project_id]);

  useEffect(() => {
    if (
      (cashState.cashDetail &&
        cashState.cashDetail.creator.id === auth.uuid &&
        !invoiceSelfEdit) ||
      (((cashState.cashDetail &&
        cashState.cashDetail.creator.id !== auth.uuid) ||
        cashState.cashDetail === null) &&
        !invoiceOthersEdit)
    ) {
      history.push("/");
    }
  }, [cashState.cashDetail]);

  const statusDesc = (
    status: number,
    page: string,
    total: any,
    total_paid: any
  ) => {
    let label = "";
    if (status === 1) {
      label = "DRAFT";
    } else if (status === 2 && page === "owned") {
      label = "ON VERIFICATION";
    } else if (status === 2) {
      label = "NEED APPROVAL";
    } else if (status === 3) {
      label = "APPROVED";
    } else if (status === 4) {
      label = "REVISION";
    } else if (status === 5 && total_paid >= total) {
      label = "NOT SETTLED";
    } else if (status === 5) {
      label = "UNPAID";
    } else if (status === 6) {
      label = "SETTLED";
    } else if (status === 7) {
      label = "REJECTED";
    } else if (status === 8) {
      label = "EXPIRED";
    } else if (status === 9) {
      label = "REJECTED BY FINANCE";
    }
    return label;
  };

  const _uploadFile = (e: any) => {
    const files = e.target.files[0];
    setFile(files);
  };

  const deleteFile = () => {
    setFile(null);
  };

  const _goToPreview = () => {
    if (file.title) {
      window.open(file.url, "_blank");
    } else {
      const go = URL.createObjectURL(file);
      window.open(go, "_blank");
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      category_id: getValues("category_id") || "",
      invoice_id: idInvoice,
      currency_code: "IDR",
      item_id: param.id_item ? param.id_item : "",
      trip_id: "",
      type_id:
        cashState.cashHistory &&
        cashState.cashHistory.djurneeInvoice.type.title,
      quantity: 1,
      budget_id: budgetId || "",
      billable: false,
      file: file && file.title ? "" || "" : file || "",
      currency_id: cashState.cashDetail && cashState.cashDetail.currency.id,
      client_timezone: "Asia/Jakarta"
    };
    dispatch(
      addCashItem(form, (run, info) => {
        if (run === true) {
          toast.success(info, {
            position: "bottom-center",
            autoClose: 2300,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-toast-req",
            closeButton: false,
            draggable: false
          });
          setTimeout(() => {
            history.goBack();
            dispatch(resetItemCash());
          }, 2500);
        } else {
          toast.error(info, {
            position: "bottom-center",
            autoClose: 2500,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-toast-req",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        goBack={() => {
          history.goBack();
          dispatch(resetItemCash());
        }}
        title={
          param.is_tax === "true"
            ? "Request Tax Detail"
            : param
            ? "Request Detail"
            : "New Request"
        }
        textHeader={{ fontSize: 18 }}
        subTitleHeader={{ fontSize: 11 }}
        subtitle={cashState.cashDetail && cashState.cashDetail.number}
        label={statusDesc(
          cashState.cashDetail && cashState.cashDetail.status,
          "",
          cashState.cashDetail && cashState.cashDetail.total,
          cashState.cashDetail && cashState.cashDetail.total_paid
        )}
        textLabel={{ fontSize: 11 }}
      />
      <ToastContainer limit={1} />
      <WrapContent isLoading={cashState.isLoadingCashItemId}>
        <View style={styles.container}>
          {invoiceType === "Reimburse" && policyTripRM && !file && (
            <Message
              message={String(
                sprintf(t("cash.new.msg.policy-2"), wording.policy)
              )}
            />
          )}
          <Space height={20} />
          <View style={styles.wrapContent}>
            <View style={styles.wrapForm}>
              <Text style={styles.label}>Subject</Text>
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <TextInput
                    style={[styles.input, styles.inputLine]}
                    onChangeText={text => onChange(text)}
                    value={value}
                  />
                )}
                name="title"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.title && (
                <Text style={styles.errorMessages}>Subject is required.</Text>
              )}
            </View>
            {["Payment Request", "Reimburse"].includes(invoiceType) && (
              <View style={styles.wrapForm}>
                <Text style={styles.label}>Items</Text>
                {listPopulate.length > 0 ? (
                  <Controller
                    control={control}
                    render={({ onChange, value }) => (
                      <select
                        style={{ color: "#666666", fontWeight: "bold" }}
                        className="container-content-input"
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        disabled={param.is_tax === "true"}
                      >
                        <option selected hidden value="">
                          Select expense type
                        </option>
                        {listPopulate.map((expenseType: any, index: number) => (
                          <option key={index} value={expenseType.value}>
                            {expenseType.label}
                          </option>
                        ))}
                      </select>
                    )}
                    name="category_id"
                    rules={{ required: true }}
                  />
                ) : (
                  <select
                    className="container-content-input"
                    style={{ color: "#999999", fontWeight: "bold" }}
                  >
                    <option selected hidden value="">
                      Your policy didn`t allow to choose this.
                    </option>
                  </select>
                )}
              </View>
            )}

            {expenseState.budgetType.length > 0 &&
            ["Payment Request", "Reimburse"].includes(invoiceType) ? (
              <View style={styles.wrapForm}>
                <Text style={styles.label}>Budget Allocation</Text>
                <select
                  name="budget_id"
                  style={{ color: "#666666", fontWeight: "bold" }}
                  className="container-content-input"
                  value={budgetId}
                  onChange={e => _findBudgetRemaining(e.target.value)}
                >
                  <option selected hidden value="">
                    Select budget
                  </option>
                  {expenseState.budgetType.map((itemB: any, index: number) => (
                    <option key={index} value={itemB.id}>
                      {itemB.attributes.title}
                    </option>
                  ))}
                </select>
              </View>
            ) : invoiceType === "Reimburse" ? (
              <View style={styles.wrapForm}>
                <Text style={styles.label}>Budget Allocation</Text>
                <select
                  name="budget_id"
                  style={{ color: "#666666", fontWeight: "bold" }}
                  className="container-content-input"
                  value={budgetId}
                  onChange={e => _findBudgetRemaining(e.target.value)}
                >
                  <option selected hidden value="">
                    Select budget
                  </option>
                  {expenseState.budgetRemaining.map(
                    (itemB: any, index: number) => (
                      <option key={index} value={itemB.id}>
                        {itemB.attributes.title}
                      </option>
                    )
                  )}
                </select>
              </View>
            ) : (
              <></>
            )}
            {projectClientId &&
            budgetId &&
            watch("amount") > budgetRemaining.maxAmount ? (
              <View style={{ alignSelf: "flex-start", marginTop: -6 }}>
                <Message
                  inputMsg
                  message={sprintf(t("cash.detail.msg.message_info_budget"), {
                    budgetName: budgetRemaining.name,
                    budgetMaxAmount: budgetRemaining.maxAmount
                  })}
                />
                <Space height={12} />
              </View>
            ) : (
              <></>
            )}

            <View style={styles.wrapForm}>
              <Text style={styles.label}>Description</Text>
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <TextArea
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    bold
                    fontSize="small"
                    withPadding
                  />
                )}
                name="description"
                defaultValue=""
              />
            </View>
            <View style={styles.wrapAmount}>
              <View style={[styles.wrapForm, styles.wrapIdr]}>
                <Text style={styles.label}>Amount</Text>
                <Text style={styles.labelIdr}>IDR</Text>
              </View>
              <View style={[styles.wrapForm, { width: "80%" }]}>
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <TextInput
                      style={[styles.input, styles.inputLineAmount]}
                      onChangeText={text => onChange(text)}
                      value={value}
                      keyboardType="numeric"
                      editable={param.is_tax !== "true"}
                    />
                  )}
                  name="amount"
                  rules={{
                    required: true,
                    validate: value => {
                      if (param.is_tax !== "true") {
                        return value > 0;
                      }
                      return value;
                    }
                  }}
                  defaultValue=""
                />
                {errors.amount?.type === "required" && (
                  <Text style={styles.errorMessages}>Amount is required.</Text>
                )}
                {errors.amount?.type === "validate" &&
                  param.is_tax !== "true" && (
                    <Text style={styles.errorMessages}>
                      Amount value cannot be less than 1
                    </Text>
                  )}
              </View>
            </View>
            {["Payment Request", "Reimburse"].includes(
              cashState.cashDetail &&
                cashState.cashDetail.djurneeInvoice.type.title
            ) && (
              <View style={styles.wrapAttch}>
                <View style={{ marginBottom: 6 }}>
                  {file && (
                    <Touchable onPress={_goToPreview}>
                      <FileUpload
                        name={file.title ? file.title : file.name}
                        style={{
                          marginTop: 6,
                          marginBottom: 6,
                          marginRight: 8,
                          width: "40%"
                        }}
                        deleteFile={e => {
                          deleteFile();
                          e.stopPropagation();
                        }}
                      />
                    </Touchable>
                  )}
                </View>
                <Upload
                  style={{
                    textAlign: "center",
                    padding: 0,
                    width: "48%",
                    alignItems: "center",
                    paddingBottom: 6,
                    paddingTop: 6
                  }}
                  onChange={(el: any) => _uploadFile(el)}
                />
              </View>
            )}
          </View>
          <View
            style={{
              position: "absolute",
              alignItems: "center",
              bottom: 24,
              alignSelf: "center"
            }}
          >
            {listPopulate.length > 0 ? (
              <Button
                title={param.id_item ? "SAVE" : "ADD"}
                isLoading={cashState.isLoadingPutRecurring}
                onPress={handleSubmit(_onSave)}
              />
            ) : (
              <Button disabled title={param.id_item ? "SAVE" : "ADD"} />
            )}
          </View>
        </View>
      </WrapContent>
    </View>
  );
};

export default AddItemCash;
