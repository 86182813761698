import {
  GET_BOOKING_HOTEL_BY_ID_ERROR,
  GET_BOOKING_HOTEL_BY_ID_PENDING,
  GET_BOOKING_HOTEL_BY_ID_SUCCESS,
  LOGOUT_SUCCESS,
  POST_NEW_BOOKING_HOTEL_ERROR,
  POST_NEW_BOOKING_HOTEL_PENDING,
  POST_NEW_BOOKING_HOTEL_SUCCESS,
  PUT_EDIT_BOOKING_HOTEL_ERROR,
  PUT_EDIT_BOOKING_HOTEL_PENDING,
  PUT_EDIT_BOOKING_HOTEL_SUCCESS
} from "../actions";
import { Action, BookingHotel } from "../types";

const initialState: BookingHotel = {
  listBookingHotelById: { data: {} },
  isLoadingGetBookingHotelById: false,
  listBookingHotel: [],
  isLoadingBookingHotel: false,
  isLoadingPostNewBookingHotel: false,
  isLoadingPutEditBookingHotel: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // post new expense
    case PUT_EDIT_BOOKING_HOTEL_PENDING:
      return { ...state, isLoadingPutEditBookingHotel: true };
    case PUT_EDIT_BOOKING_HOTEL_SUCCESS:
      return {
        ...state,
        isLoadingPutEditBookingHotel: false
      };
    case PUT_EDIT_BOOKING_HOTEL_ERROR:
      return { ...state, isLoadingPutEditBookingHotel: false };
    case POST_NEW_BOOKING_HOTEL_PENDING:
      return { ...state, isLoadingPostNewBookingHotel: true };
    case POST_NEW_BOOKING_HOTEL_SUCCESS:
      return {
        ...state,
        isLoadingPostNewBookingHotel: false
      };
    case POST_NEW_BOOKING_HOTEL_ERROR:
      return { ...state, isLoadingBookingHotel: false };
    case GET_BOOKING_HOTEL_BY_ID_PENDING:
      return { ...state, isLoadingGetBookingHotelById: true };
    case GET_BOOKING_HOTEL_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingGetBookingHotelById: false,
        listBookingHotelById: payload.data
      };
    case GET_BOOKING_HOTEL_BY_ID_ERROR:
      return { ...state, isLoadingGetBookingHotelById: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
