/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "./styles";
import {
  convertDate,
  copyWritings,
  maskedMoney,
  toast
} from "../../../../utils";
import {
  Card,
  Confirmation,
  Modal,
  Space,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import { ICONS, IMAGES } from "../../../../configs";
import {
  deleteCashBill,
  getBillTrip,
  getTripBillModeration,
  putSettle,
  putSubmitBill
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import ModerateTrip from "../../../../components/ModalComponents/ModerateTrip";

interface Props {
  id: string;
  isBillApproval?: boolean;
}

const Bill = ({ id, isBillApproval }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const { expenseState, cashState, tripState, uuid } = useSelector(
    (state: Reducers) => ({
      expenseState: state.newExpenseTrip,
      cashState: state.cash,
      tripState: state.trip,
      uuid: state.auth.uuid
    }),
    shallowEqual
  );

  const [modalDeleteBill, setModalDeleteBIll] = useState(false);
  const [modalSettle, setModalSettle] = useState(false);
  const [idBill, setIdBill] = useState("");
  const [modalModerationBar, setModalModerationBar] = useState(false);
  const [modalModeration, setModalModeration] = useState(false);

  const tripUserId = tripState.tripData?.users?.[0]?.id;
  // const expenseModeration = tripState.tripData?.users?.[0]?.expense_moderation;
  const copTeks = {
    expense: copyWritings("expense"),
    cash_advance: copyWritings("cash_advance")
  };

  useEffect(() => {
    dispatch(getBillTrip(id));
    if (tripUserId) {
      dispatch(getTripBillModeration(tripUserId));
    }
  }, [dispatch, id, tripUserId]);

  const _totalItem = () => {
    if (expenseState.listBillTrip.length > 0) {
      return expenseState.listBillTrip.reduce((acc, current) => ({
        total: Number(acc.total) + Number(current.total)
      })).total;
    }
    return 0;
  };

  const _handleRenderStatusModeration = (status: number) => {
    if (status === 1) {
      return t("activity.status.waitingAnswer");
    }
    if (status === 2) {
      return t("activity.status.approved");
    }
    if (status === 3) {
      return t("activity.status.revise");
    }
    if (status === 4) {
      return t("activity.status.rejected");
    }
    return "";
  };

  const _canModerateTripBill = useCallback(() => {
    let res = false;
    if (tripState.tripBillModerationData.length > 0) {
      res =
        tripState.tripBillModerationData &&
        tripState.tripBillModerationData[
          tripState.tripBillModerationData.length - 1
        ].users.filter((userData: any) => userData.user.id === uuid).length > 0;
    }

    if (
      tripState.tripBillModerationData[
        tripState.tripBillModerationData.length - 1
      ]?.status === 1 &&
      tripState.tripData &&
      [5, 8].includes(tripState.tripData.status) &&
      res
    ) {
      return true;
    }
    return false;
  }, [uuid, tripState.tripBillModerationData, tripState.tripData]);

  const _goToPreview = (link: string) => {
    window.open(`${link}`, "_blank");
  };

  const _deleteBill = () => {
    dispatch(
      deleteCashBill(idBill, (run, info) => {
        if (run) {
          setModalDeleteBIll(false);
          toast.success(t("cash.new.msg.toastDelete"));
          dispatch(getBillTrip(id));
        } else {
          toast.error(info);
        }
      })
    );
  };

  const _permissionCreate = () => {
    const invoice = expenseState.dataInvoiceTrip;
    const tripdata = tripState?.tripData;
    if (
      invoice?.total_paid >= invoice?.total &&
      [5, 8].includes(tripdata.status) &&
      tripdata?.users?.[0]?.expense &&
      [1, 3].includes(tripdata?.users?.[0]?.expense_moderation) &&
      !isBillApproval
    ) {
      return true;
    }
    return false;
  };

  const _permissionSettle = () => {
    const invoice = expenseState.dataInvoiceTrip;
    const tripdata = tripState.tripData;
    if (
      invoice?.total_paid >= invoice?.total &&
      [5, 8].includes(tripdata?.status) &&
      tripdata?.users?.[0]?.expense &&
      [0, 3].includes(tripdata?.users?.[0]?.expense_moderation) &&
      !isBillApproval
    ) {
      return true;
    }
    return false;
  };

  const _permissionSubmit = () => {
    const invoice = expenseState.dataInvoiceTrip;
    const tripdata = tripState.tripData;
    if (
      invoice?.total_paid >= invoice?.total &&
      [5, 8].includes(tripdata?.status) &&
      tripdata?.users?.[0]?.expense &&
      tripdata?.users?.[0]?.expense_moderation === 1 &&
      !isBillApproval
    ) {
      return true;
    }
    return false;
  };

  const _caNotPaid = () => {
    const invoice = expenseState.dataInvoiceTrip;
    const tripdata = tripState.tripData;
    if (
      invoice?.total_paid < invoice?.total &&
      [5, 8].includes(tripdata?.status) &&
      tripdata?.users?.[0]?.expense &&
      !isBillApproval
    ) {
      return true;
    }
    return false;
  };

  const _settleBill = () => {
    const form = {
      invoice_id: "",
      trip_user_id: tripState.tripData && tripState.tripData.users[0].id,
      client_timezone: "Asia/Jakarta"
    };
    dispatch(
      putSettle(form, (run, info) => {
        if (run === true) {
          setModalSettle(false);
          toast.success("Data updated!");
          window.location.reload();
        } else {
          toast.error(info);
        }
      })
    );
  };

  const _submitBill = () => {
    const form = {
      invoice_id: "",
      trip_user_id: tripState.tripData && tripState.tripData.users[0].id,
      client_timezone: "Asia/Jakarta"
    };
    dispatch(
      putSubmitBill(form, (run, info) => {
        if (run === true) {
          setModalSettle(false);
          toast.success("Data updated!");
          window.location.reload();
        } else {
          toast.error(info);
        }
      })
    );
  };

  const _toFormBill = (item_id: string, file: any) => {
    history.push("/new-trip-bill", {
      trip_id: id,
      status: tripState.tripData && tripState.tripData.status,
      invoice_id:
        expenseState.dataInvoiceTrip !== null &&
        expenseState.dataInvoiceTrip.id,
      project_id:
        tripState.tripData?.project?.id || tripState.tripData?.project?.uuid,
      permissionCreate: _permissionCreate(),
      item_id,
      file
    });
  };

  return (
    <WrapContent isLoading={expenseState.isLoadingListReimburse}>
      <WrapList
        isEmpty={expenseState.listBillTrip.length === 0}
        emptyComponent={() =>
          _permissionCreate() || _permissionSettle() ? (
            <View
              style={[
                styles.wrapButtonEmpty,
                ((_permissionCreate() && _permissionSettle()) ||
                  (_permissionCreate() && _permissionSubmit())) && {
                  justifyContent: "space-between"
                }
              ]}
            >
              {_permissionSettle() && (
                <Card
                  onPress={() => setModalSettle(true)}
                  containerStyle={styles.button}
                  cardStyle={[styles.button2, styles.button3]}
                >
                  <Text style={[styles.buttonText, styles.buttonText2]}>
                    {t("cash.new.settled")}
                  </Text>
                </Card>
              )}
              {_permissionSubmit() && (
                <Card
                  onPress={() => setModalSettle(true)}
                  containerStyle={styles.button}
                  cardStyle={[styles.button2, styles.button3]}
                >
                  <Text style={[styles.buttonText, styles.buttonText2]}>
                    {t("cash.new.submit")}
                  </Text>
                </Card>
              )}
              {_permissionCreate() && (
                <Card
                  containerStyle={styles.button}
                  cardStyle={[styles.button2, styles.button3]}
                  onPress={() => _toFormBill("", "")}
                >
                  <Text style={[styles.buttonText, styles.buttonText2]}>
                    {sprintf(t("cash.new.addNew"), copTeks.expense)}
                  </Text>
                </Card>
              )}
            </View>
          ) : _caNotPaid() ? (
            <Text style={styles.emptyData}>Cash Advance has not been paid</Text>
          ) : tripUserId && tripState.tripBillModerationData.length > 0 ? (
            <Card
              containerStyle={styles.button}
              cardStyle={[styles.button2, styles.button3]}
              onPress={() => setModalModerationBar(true)}
            >
              <Text style={[styles.buttonText, styles.buttonText2]}>
                {`${copTeks.expense} Moderation`}
              </Text>
            </Card>
          ) : (
            <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
          )
        }
      >
        <View style={{ paddingHorizontal: 20 }}>
          <FlatList
            style={{
              marginTop: 11,
              paddingTop: 5
            }}
            data={expenseState.listBillTrip}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <Card
                cardStyle={styles.cardStyle}
                statusColor="#50b1b1"
                statusTextLight={t("cash.new.qty")}
                statusText={item ? ` ${item.quantity}` : "-"}
                statusDescLight="IDR "
                statusDesc={maskedMoney(item.total)}
                statusDescStyle={{ fontSize: 14 }}
                statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                onPress={() => _toFormBill(item.id, item.file)}
                key={index}
              >
                <View style={styles.wrapContentCard}>
                  <View style={[styles.wrapCardText]}>
                    <Text style={styles.textLabel}>
                      {t("cash.new.subject")}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.title || "-"}
                    </Text>
                  </View>

                  <View style={[styles.wrapCardText]}>
                    <Text style={[styles.textLabel]}>{t("cash.new.date")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      <Text style={{ fontWeight: "normal" }}>
                        {`${convertDate(item.date, "ddd")} ` || "-"}
                      </Text>
                      {convertDate(item.date, "DD MMM YYYY") || ""}
                    </Text>
                  </View>

                  <View style={[styles.wrapCardText]}>
                    <Text style={styles.textLabel}>{t("cash.new.type")}</Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue]}
                    >
                      {item.category.title || "-"}
                    </Text>
                  </View>

                  <View style={[styles.wrapCardText]}>
                    <Text style={styles.textLabel}>
                      {t("activity.detail.desc")}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue]}
                    >
                      {item.description || "-"}
                    </Text>
                  </View>
                  {item.file && (
                    <Touchable
                      onPress={e => {
                        e.stopPropagation();
                        _goToPreview(item.file && JSON.parse(item.file).url);
                      }}
                    >
                      <View
                        style={[
                          styles.wrapBtnImage,
                          { right: 40 },
                          !_permissionCreate() && { right: 8 }
                        ]}
                      >
                        <Image
                          source={IMAGES.clipAttchment}
                          style={{ width: 18, height: 18 }}
                        />
                      </View>
                    </Touchable>
                  )}

                  {_permissionCreate() && (
                    <Touchable
                      onPress={e => {
                        e.stopPropagation();
                        setIdBill(item.id);
                        setModalDeleteBIll(true);
                      }}
                    >
                      <View style={styles.wrapBtnImage}>
                        <Image
                          source={IMAGES.trashBin}
                          style={{ width: 13, height: 16 }}
                        />
                      </View>
                    </Touchable>
                  )}
                </View>
              </Card>
            )}
          />
          <View style={styles.wrapButtonAdd}>
            {_permissionCreate() && (
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => _toFormBill("", "")}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {sprintf(t("cash.new.addNew"), copTeks.expense)}
                </Text>
              </Card>
            )}
            {_permissionSubmit() && (
              <Card
                onPress={() => setModalSettle(true)}
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {t("cash.new.submit")}
                </Text>
              </Card>
            )}
            {_permissionSettle() && (
              <Card
                onPress={() => setModalSettle(true)}
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {t("cash.new.settled")}
                </Text>
              </Card>
            )}
            {tripUserId && tripState.tripBillModerationData.length > 0 && (
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => setModalModerationBar(true)}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {`${copTeks.expense} Moderation`}
                </Text>
              </Card>
            )}
          </View>

          <View style={styles.wrapTotal}>
            <Text style={styles.totalLabel}>
              {sprintf(t("cash.new.total"), copTeks.expense)}
            </Text>
            <Text style={styles.amountText}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(_totalItem())}
            </Text>
          </View>
        </View>
      </WrapList>
      <Modal isVisible={modalDeleteBill} backdropColor="rgba(255,255,255, 0.9)">
        <Confirmation
          headerTitle={sprintf(t("cash.new.deleteCop"), copTeks.expense)}
          description={t("cash.new.msg.deleteBill")}
          actionTitle={t("cash.new.delete")}
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalDeleteBIll(false)}
          isLoading={cashState.isLoadingDeletBill}
          onAction={_deleteBill}
        />
      </Modal>
      <Modal isVisible={modalSettle} backdropColor="rgba(255,255,255, 0.9)">
        <Confirmation
          headerTitle={
            _permissionSubmit()
              ? "SUBMIT CASH ADVANCE"
              : sprintf(t("cash.new.settleCA"), copTeks.cash_advance)
          }
          // eslint-disable-next-line max-len
          description={sprintf(
            t("cash.new.msg.settleMsg"),
            copTeks.expense,
            copTeks.expense,
            copTeks.cash_advance
          )}
          actionTitle={
            _permissionSubmit() ? "SUBMIT" : t("cash.new.settled").toUpperCase()
          }
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalSettle(false)}
          isLoading={
            _permissionSubmit()
              ? cashState.isLoadingSubmitBill
              : cashState.isLoadingDeletBill
          }
          onAction={_permissionSubmit() ? _submitBill : _settleBill}
        />
      </Modal>
      <Modal
        isVisible={modalModerationBar}
        backdropColor="rgba(255,255,255, 0.5)"
        onBackdropPress={() => setModalModerationBar(false)}
      >
        <View style={styles.containerModeration}>
          <Card containerStyle={{ flex: 1 }} cardStyle={{ flex: 1 }}>
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => {
                setTimeout(() => {
                  setModalModerationBar(false);
                }, 200);
              }}
              style={{ alignSelf: "flex-end", marginRight: 12, marginTop: 12 }}
            >
              <Image source={IMAGES.cross} style={{ height: 20, width: 20 }} />
            </TouchableOpacity>
            <Text
              style={styles.headerTitle}
              onPress={() => setModalModerationBar(false)}
            >
              {`${copTeks.expense} Moderation`}
            </Text>
            <Space height={20} />
            {tripState.tripBillModerationData.length > 0 && (
              <View style={styles.stepperContainer}>
                <View style={{ marginLeft: 18 }}>
                  <Text style={styles.stepperStatus}>
                    {t("activity.issued")}
                  </Text>
                  <Text style={[styles.stepperAssigner, { marginBottom: 8 }]}>
                    {tripState.tripBillModerationData?.[0]?.issuer?.name || "-"}
                  </Text>
                  <View style={styles.stepperItems}>
                    <View style={[styles.stepperCircle]} />
                    <View style={styles.stepperLine} />
                  </View>
                  <Text
                    style={[
                      styles.stepperAssigner,
                      { fontWeight: "bold", marginTop: 8 }
                    ]}
                  >
                    {moment(
                      new Date(tripState.tripData.created_at).toUTCString()
                    ).format("DD MMM YY")}
                  </Text>
                  <Text style={styles.stepperAssigner}>
                    {moment(
                      new Date(tripState.tripData.created_at).toUTCString()
                    ).format("HH:mm")}
                  </Text>
                </View>

                {tripState.tripBillModerationData &&
                  tripState.tripBillModerationData.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        paddingRight:
                          tripState.tripBillModerationData.length - 1 === index
                            ? 16
                            : 0
                      }}
                    >
                      <Text style={styles.stepperStatus}>
                        {_handleRenderStatusModeration(item.status)}
                      </Text>
                      <Text
                        style={[styles.stepperAssigner, { marginBottom: 8 }]}
                      >
                        {(item.moderator && item.moderator.name) ||
                          (item.users.length > 0 &&
                            item?.users?.[0]?.user?.position_name) ||
                          "-"}
                      </Text>
                      <View style={styles.stepperItems}>
                        <View style={[styles.stepperCircle]} />
                        {tripState.tripBillModerationData.length - 1 !==
                          index && <View style={styles.stepperLine} />}
                      </View>
                      <Text
                        style={[
                          styles.stepperAssigner,
                          { fontWeight: "bold", marginTop: 8 }
                        ]}
                      >
                        {item.moderator &&
                          moment(
                            new Date(item.updated_at).toUTCString()
                          ).format("DD MMM YY")}
                      </Text>
                      <Text style={styles.stepperAssigner}>
                        {item.moderator &&
                          moment(
                            new Date(item.updated_at).toUTCString()
                          ).format("HH:mm")}
                      </Text>
                    </View>
                  ))}
              </View>
            )}
            <Space height={40} />
            {_canModerateTripBill() && (
              <View style={styles.wrapBtnModeration}>
                <TouchableOpacity
                  activeOpacity={0.8}
                  style={styles.btnModeration}
                  onPress={() => setModalModeration(true)}
                >
                  <Image
                    source={ICONS.moderationIcon}
                    style={{ height: 28, width: 28 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </Card>
        </View>
      </Modal>
      {modalModeration && (
        <Modal
          isVisible={modalModeration}
          backdropColor="rgba(240,240,240, 0.5)"
        >
          {tripState.tripData && (
            <ModerateTrip
              tripId={tripUserId}
              onCancel={() => setModalModeration(false)}
              isTripBill
            />
          )}
        </Modal>
      )}
    </WrapContent>
  );
};

export default Bill;
