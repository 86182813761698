import React, { memo } from "react";
import { Image, TextInput, View } from "react-native";
import { useTranslation } from "react-i18next";
import { COLORS, ICONS } from "../../configs";

import Space from "../Space";
import styles from "./styles";

interface Props {
  onChange: (e: any) => void;
}

const Component = ({ onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.card}>
      <Image source={ICONS.search} style={styles.iconStyle} />
      <Space width={8} />
      <TextInput
        style={styles.inputStyle}
        placeholder={t("search_something")}
        placeholderTextColor={COLORS.greyLight}
        onChangeText={onChange}
      />
    </View>
  );
};

Component.defaultProps = {};

export default memo(Component);
