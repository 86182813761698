import React, { useEffect } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// import styles from "./styles";
import { Card, Touchable, WrapContent, WrapList } from "../../../components";
import styles from "./styles";
import { IMAGES } from "../../../configs";
import { convertDate, documentTitle, maskedMoney } from "../../../utils";

// Redux
import { Reducers } from "../../../redux/types";
import { deleteBookingById, getListBookingHotel } from "../../../redux/actions";

interface Props {
  bottom: number;
  id: string;
}

const Hotel = ({ bottom, id }: Props) => {
  documentTitle("Booking - Hotel");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getListBookingHotel(id));
  }, [dispatch]);

  const _deleteBookingHotel = (bookingId: string) => {
    dispatch(deleteBookingById(bookingId));
  };

  const bookingState = useSelector((state: Reducers) => state.booking);

  const _star = (total: number) => {
    let img = <></>;
    for (let i = 0; i < total; i += 1) {
      img = (
        <>
          {img}
          <Image style={styles.star} source={IMAGES.star} />
        </>
      );
    }
    const sisa = 5 - total;
    for (let j = 0; j < sisa; j += 1) {
      img = (
        <>
          {img}
          <Image style={styles.star} source={IMAGES.stargrey} />
        </>
      );
    }
    return <View style={{ flexDirection: "row" }}>{img}</View>;
  };

  const _splitDate = (date: string) =>
    convertDate(date, "ddd,DD MMM YYYY,hh:mm").split(",");

  return (
    <View style={{ marginHorizontal: 20, flex: 1 }}>
      <WrapContent isLoading={bookingState.isLoadingListBookingHotel}>
        <WrapList
          isEmpty={bookingState.listBookingHotel.length === 0}
          emptyComponent={() => (
            <Touchable onPress={() => history.push(`/booking/hotel/${id}`)}>
              <Image
                resizeMode="contain"
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.newBookHotel}
              />
            </Touchable>
          )}
        >
          <FlatList
            data={bookingState.listBookingHotel}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <Card key={index.toString()} cardStyle={styles.cardDescription}>
                <View>
                  <View style={[styles.desciption, { marginBottom: 0 }]}>
                    <Text style={styles.titleDescription}>Hotel</Text>
                    <View style={styles.detailDescription}>
                      {_star(item.data.prediction_star)}
                    </View>
                    <Touchable onPress={() => _deleteBookingHotel(item.id)}>
                      <View
                        style={{
                          height: 28,
                          width: 28,
                          borderRadius: 20,
                          shadowColor: "#ddd",
                          shadowOffset: { width: 0, height: 2 },
                          shadowOpacity: 1,
                          shadowRadius: 5,
                          elevation: 2,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Image
                          source={IMAGES.trashBin}
                          style={{ width: 13, height: 16 }}
                        />
                      </View>
                    </Touchable>
                  </View>
                  <Text style={styles.titleHotel}>
                    {item.data.prediction_name}
                  </Text>

                  <View style={styles.marginVertical}>
                    <View style={styles.desciption}>
                      <Text style={styles.titleDescription}>City</Text>
                      <Text style={styles.detailDescription}>
                        {item.data.location}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.marginVertical}>
                    <View style={styles.desciption}>
                      <Text style={styles.titleDescription}>Check In</Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.descDate]}>
                          {`${_splitDate(item.data.check_in)[0]} `}
                        </Text>
                        <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                          {`${_splitDate(item.data.check_in)[1]} `}
                        </Text>
                        <Text style={[styles.descDate]}>
                          {_splitDate(item.data.check_in)[2]}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.desciption}>
                      <Text style={[styles.titleDescription]}>Check Out</Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.descDate]}>
                          {`${_splitDate(item.data.check_out)[0]} `}
                        </Text>
                        <Text style={[styles.descDate, { fontWeight: "bold" }]}>
                          {`${_splitDate(item.data.check_out)[1]} `}
                        </Text>
                        <Text style={[styles.descDate]}>
                          {_splitDate(item.data.check_out)[2]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* <View style={[styles.desciption, styles.marginVertical]}>
                    <Text style={styles.titleDescription}>Facility</Text>
                    <Text style={styles.detailDescription}>
                      {item.facility.map(
                        (itemFacility: string, indexFacility: number) =>
                          `${itemFacility}${
                            indexFacility !== item.facility.length - 1
                              ? ","
                              : ""
                          } `
                      )}
                    </Text>
                  </View> */}
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Budget</Text>
                    <Text
                      style={[styles.detailDescription, { fontWeight: "bold" }]}
                    >
                      {`IDR ${maskedMoney(
                        item.djurneeBooking.prediction_total
                      )}`}
                    </Text>
                  </View>
                </View>
              </Card>
            )}
          />
        </WrapList>

        {bookingState.listBookingHotel.length > 0 && (
          <div
            style={{
              position: "sticky",
              bottom,
              padding: 10,
              paddingTop: 5,
              alignSelf: "flex-end"
            }}
          >
            <Touchable onPress={() => history.push(`/booking/hotel/${id}`)}>
              <Image
                resizeMode="contain"
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.newBookHotel}
              />
            </Touchable>
          </div>
        )}
      </WrapContent>
    </View>
  );
};

export default Hotel;
