import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
// import { GET_SOURCE_ERROR } from "./source";

export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GET_MY_PERMISSION_PENDING = "GET_MY_PERMISSION_PENDING";
export const GET_MY_PERMISSION_SUCCESS = "GET_MY_PERMISSION_SUCCESS";
export const GET_MY_PERMISSION_ERROR = "GET_MY_PERMISSION_ERROR";

export const GET_MY_GENERAL_SETTING_PENDING = "GET_MY_GENERAL_SETTING_PENDING";
export const GET_MY_GENERAL_SETTING_SUCCESS = "GET_MY_GENERAL_SETTING_SUCCESS";
export const GET_MY_GENERAL_SETTING_ERROR = "GET_MY_GENERAL_SETTING_ERROR";

export const GET_COMPANY_ADDRESS_PENDING = "GET_COMPANY_ADDRESS_PENDING";
export const GET_COMPANY_ADDRESS_SUCCESS = "GET_COMPANY_ADDRESS_SUCCESS";
export const GET_COMPANY_ADDRESS_ERROR = "GET_COMPANY_ADDRESS_ERROR";

export const GET_SETTING_PENDING = "GET_SETTING_PENDING";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_ERROR = "GET_SETTING_ERROR";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const FIRST_TIME_SUCCESS = "FIRST_TIME_SUCCESS";

export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILED = "GET_CONFIG_FAILED";

declare const alert: (param: any) => void;

export const handleLogout = () => (dispatch: Dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

export const handleLogin = (
  cb: () => void,
  email?: string,
  password?: string,
  remember?: boolean,
  manual?: boolean
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: LOGIN_PENDING });

    const res = await API.postLogin({
      email: email || auth.email,
      password: password || auth.password
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        uuid: res.data.data.user_uuid,
        data: res.data.data.token,
        userId: res.data.data.employee,
        email: remember ? email : "",
        password: remember ? password : "",
        policies: res.data.data.policies
      }
    });
    cb();
  } catch (err) {
    if (err.response) {
      if (email || password || manual) {
        alert(err.response.data.message);
        dispatch({ type: LOGIN_ERROR, payload: { data: err.response.data } });
      } else {
        dispatch(handleLogout());
      }
    }
    dispatch({ type: LOGIN_ERROR });
  }
};

export const handleFirstTime = () => (dispatch: Dispatch) => {
  dispatch({ type: FIRST_TIME_SUCCESS });
};

export const getMySettingGeneral = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MY_GENERAL_SETTING_PENDING });

    const res = await API.getMySettingGeneral(auth.token);
    dispatch({
      type: GET_MY_GENERAL_SETTING_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getMySettingGeneral())));
      } else {
        dispatch({
          type: GET_MY_GENERAL_SETTING_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_MY_GENERAL_SETTING_ERROR });
  }
};

export const getSetting = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_SETTING_PENDING });

    const res = await API.getMySettingGeneral(auth.token);
    dispatch({
      type: GET_SETTING_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getSetting())));
      } else {
        dispatch({
          type: GET_SETTING_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_SETTING_ERROR });
  }
};

export const getCompanyAddress = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_COMPANY_ADDRESS_PENDING });

    const res = await API.getCompanyAddress(auth.token);
    dispatch({
      type: GET_COMPANY_ADDRESS_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCompanyAddress())));
      } else {
        dispatch({
          type: GET_COMPANY_ADDRESS_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_MY_GENERAL_SETTING_ERROR });
  }
};

export const getMyPermission = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MY_PERMISSION_PENDING });

    const res = await API.getMyPermission(auth.token);
    dispatch({
      type: GET_MY_PERMISSION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getMyPermission())));
      } else {
        dispatch({
          type: GET_MY_PERMISSION_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_MY_PERMISSION_ERROR });
  }
};

export const getConfigUploadFile = () => async (dispatch: Dispatch) => {
  const res = await API.getConfigSizeUpload();
  dispatch({ type: GET_CONFIG_SUCCESS, payload: { data: res.data } });
};
