import React from "react";
import { Image, ImageSourcePropType, Text, View } from "react-native";
import { useHistory } from "react-router-dom";

import styles from "./styles";
import { ICONS } from "../../../../configs";
import { Touchable } from "../../../../components";

interface Props {
  image: ImageSourcePropType;
  title: string;
  desc: string;
  back?: boolean;
}

const Description = ({ image, title, desc, back }: Props) => {
  const history = useHistory();

  return (
    <View style={styles.container}>
      {back && (
        <View style={styles.buttonBack}>
          <Touchable onPress={() => history.goBack()}>
            <View style={{ padding: 34 }}>
              <Image
                source={ICONS.iconBack}
                style={styles.iconBack}
                resizeMode="contain"
              />
            </View>
          </Touchable>
        </View>
      )}
      <View style={styles.wrapImage}>
        <Image style={styles.image} source={image} resizeMode="contain" />
      </View>
      <View style={styles.wrapDesc}>
        <Text style={styles.textTitle}>{title}</Text>
        <Text style={styles.textDesc}>{desc}</Text>
      </View>
    </View>
  );
};

Description.defaultProps = {
  back: false
};

export default Description;
