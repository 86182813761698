/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import {
  getAvailableDateTaskReport,
  getDetailTask,
  postTaskDetailReport
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

import { Fixed, Select } from "../../../components";
import styles from "./styles";
import { ICONS } from "../../../configs";

interface Props {
  id: string;
}

const AddReport = ({ id }: Props) => {
  const dispatch = useDispatch();

  const { uuid } = useSelector((state: Reducers) => state.auth);
  const listDate = useSelector((state: Reducers) => state.task)
    .availableDateTaskReport;
  const isLoading = useSelector((state: Reducers) => state.task)
    .isLoadingDetailTaskReportData;
  const auth = useSelector((state: Reducers) => state.auth);

  useEffect(() => {
    dispatch(getDetailTask(id));
    dispatch(getAvailableDateTaskReport(id, uuid));
  }, [dispatch]);

  let temp: any[] = [];
  const [files, setFiles]: any[] = useState([]);
  const [date, setDate]: any = useState(null);
  const [report, setReport] = useState("");
  const [errorFileUpload, setErrorFileUpload] = useState(false);
  const [errorDesc, setErrorDesc] = useState(false);
  const { t } = useTranslation();
  const _handleAddFile = (file: any) => {
    const fileSize = file.size;
    if (fileSize > auth.myConfig.max_size) {
      setErrorFileUpload(true);
    } else {
      temp = [...files, file];
      setFiles(temp);
      setErrorFileUpload(false);
    }
  };

  const _handleDeleteFile = (index: number) => {
    const handleDelete = [...files];

    handleDelete.splice(index, 1);
    setFiles(handleDelete);
  };

  const _handleAddReport = () => {
    if (report !== null && report !== "") {
      setErrorDesc(false);
      const body = {
        date: date || listDate[0].value,
        description: report,
        files
      };
      dispatch(postTaskDetailReport(id, uuid, body));
    } else {
      setErrorDesc(true);
    }
  };

  const _goToPreview = (index: number) => {
    if (
      files[index].type === "application/pdf" ||
      files[index].type === "application/vnd.ms-excel" ||
      files[index].type === ".doc" ||
      files[index].type === ".docx"
    ) {
      window.open(URL.createObjectURL(files[index]));
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
    <div
      style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
    >
      <img src="${URL.createObjectURL(files[index])}">
    </div>`;
    }
  };

  return (
    <View style={{ width: "100%" }}>
      {!isLoading ? (
        <View style={styles.container}>
          {listDate.length > 0 ? (
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "Poppins",
                  color: "#666666"
                }}
              >
                {t("task.report.date")}
              </Text>
              <View
                style={[
                  {
                    marginTop: 2,
                    width: 180,
                    height: 30,
                    borderRadius: 100,
                    justifyContent: "center"
                  },
                  styles.card
                ]}
              >
                <View style={{ marginHorizontal: 10 }}>
                  <Select
                    listValue={listDate}
                    onChange={(e: any) => {
                      setDate(e.target.value);
                    }}
                    moderation
                  />
                </View>
              </View>
              <Text
                style={{
                  fontSize: 11,
                  marginTop: 12,
                  fontFamily: "Poppins",
                  color: "#666666"
                }}
              >
                {t("task.report.report")}
              </Text>
              <View>
                <TextInput
                  onChange={(e: any) => setReport(e.target.value)}
                  multiline
                  numberOfLines={3}
                  style={{
                    borderColor: "#666666",
                    borderBottomWidth: 1,
                    height: 140,
                    color: "#666666",
                    width: "100%"
                  }}
                />
              </View>
              <View>
                {errorDesc ? (
                  <Text style={{ fontSize: 11, color: "red" }}>
                    {t("task.report.warningRequired")}
                  </Text>
                ) : null}
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: 12
                }}
              >
                {files.map((file: any, index: number) => (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles.fileAttachment,
                      styles.card,
                      {
                        borderRadius: 100,
                        alignItems: "center",
                        justifyContent: "center"
                      }
                    ]}
                    onPress={() => _goToPreview(index)}
                  >
                    <View style={{ width: "90%" }}>
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ width: "85%" }}>
                          <Text numberOfLines={1} style={styles.attributeTask}>
                            {file.name}
                          </Text>
                        </View>
                        <TouchableOpacity
                          style={{
                            width: "15%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                          onPress={e => {
                            e.stopPropagation();
                            _handleDeleteFile(index);
                          }}
                        >
                          <Image
                            source={ICONS.iconDeleteFile}
                            resizeMode="contain"
                            style={{ width: 12, height: 12 }}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))}
                <TouchableOpacity style={styles.button}>
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      fontSize: 11
                    }}
                  >
                    <input
                      type="file"
                      accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                    application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(e: any) => {
                        _handleAddFile(e.target.files[0]);
                      }}
                      onClick={(event: any) => {
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = null;
                      }}
                    />
                    {t("task.detail.attachment")}
                  </label>
                </TouchableOpacity>
                {errorFileUpload ? (
                  <View style={{ width: "100%", marginTop: 12 }}>
                    <Text style={{ fontSize: 11, color: "red" }}>
                      {`File size is too big on current upload, please upload another file (Maximum ${
                        auth.myConfig.max_size / 1000000
                      } MB)`}
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
          ) : null}

          <Fixed position="bottom">
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {listDate.length > 0 ? (
                <TouchableOpacity
                  style={[
                    styles.button,
                    { marginBottom: 24, width: 180, height: 54 }
                  ]}
                  onPress={() => {
                    _handleAddReport();
                  }}
                >
                  <Text style={[styles.textButton, { fontSize: 18 }]}>
                    {t("task.report.add")}
                  </Text>
                </TouchableOpacity>
              ) : null}
            </View>
          </Fixed>
        </View>
      ) : (
        <ActivityIndicator />
      )}
    </View>
  );
};
export default AddReport;
