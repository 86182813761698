import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  formContainer: {
    marginTop: 66,
    paddingLeft: 41,
    paddingRight: 42
  },
  labelInput: {
    fontSize: 8,
    fontFamily: "Poppins",
    color: "rgba(102, 102, 102, 0.5)"
  },
  inputStyle: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    fontFamily: "Poppins"
  },
  inputContainer: {
    marginBottom: 10
  },
  saveButton: {
    marginBottom: 50,
    marginTop: 20,
    alignItems: "center"
  }
});

export default styles;
