import React from "react";
import { Text, View } from "react-native";
import styles from "./styles";
import "./style.css";

import { Button, Header } from "../../../components";
import Card from "../../NewTrip/components/Card";

const Flight = () => (
  <View style={styles.flightContain}>
    <Header title="Trip 1" />
    <View style={{ paddingLeft: 36, paddingRight: 43 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginTop: 30,
          marginBottom: 9
        }}
      >
        <Text style={{ fontSize: 14, color: "rgba(102, 102, 102, 0.5)" }}>
          From
        </Text>
        <Text style={{ fontSize: 14, color: "rgba(102, 102, 102, 0.5)" }}>
          To
        </Text>
      </View>
      <Card cardStyle={styles.flightCardCity}>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
          <Text style={{ color: "#666666", fontSize: 14, fontWeight: "bold" }}>
            Jakarta
          </Text>
          <Text>&</Text>
          <Text
            style={{
              color: "rgba(102, 102, 102, 0.5)",
              fontSize: 14,
              fontWeight: "bold"
            }}
          >
            Bandung
          </Text>
        </View>
      </Card>
      <Card cardStyle={styles.cardTime}>
        <Text
          style={{
            color: "rgba(102, 102, 102, 0.5)",
            fontSize: 14,
            fontWeight: "bold"
          }}
        >
          Time
        </Text>
      </Card>
      <View style={styles.cardDateContainer}>
        <Card cardStyle={styles.cardDate}>
          <Text style={{ fontSize: 14, fontWeight: "bold", color: "#666666" }}>
            December
          </Text>
        </Card>
        <Card cardStyle={styles.cardDate}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "rgba(102, 102, 102, 0.5)"
            }}
          >
            Year
          </Text>
        </Card>
      </View>
      <View style={{ alignItems: "center", marginTop: 46 }}>
        <Button title="Save" />
      </View>
    </View>
  </View>
);

export default Flight;
