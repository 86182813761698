/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from "react";
import { FlatList, Image, Text, View } from "react-native";

import { Card, Header, Touchable } from "../../components";
import styles from "./styles";
import { IMAGES } from "../../configs";

const NewBookingHotelList = () => {
  const hotelList = [
    {
      name: "Sharaton Hotel Jakarta Selatan",
      rating: 5,
      checkin: "2018-12-28 14:00:00",
      checkout: "2019-01-30 12:00:00",
      facility: [
        "Breakfast",
        "Dinner",
        "Meeting Room",
        "Lunch",
        "Swimming Pool",
        "Gym"
      ],
      policy: true,
      price: {
        IDR: 3500000
      }
    },
    {
      name: "Fave Hotel Kemang Jakarta Sel ...",
      rating: 2,
      checkin: "2018-12-28 14:00:00",
      checkout: "2019-01-30 12:00:00",
      facility: [
        "Breakfast",
        "Dinner",
        "Meeting Room",
        "Lunch",
        "Swimming Pool",
        "Gym"
      ],
      policy: false,
      price: {
        IDR: 3500000
      }
    },
    {
      name: "Mulia Hotel Jakarta Selatan",
      rating: 4,
      checkin: "2018-12-28 14:00:00",
      checkout: "2019-01-30 12:00:00",
      facility: [
        "Breakfast",
        "Dinner",
        "Meeting Room",
        "Lunch",
        "Swimming Pool",
        "Gym"
      ],
      policy: false,
      price: {
        IDR: 3500000
      }
    },
    {
      name: "Fave Hotel Kemang Jakarta Sel ...",
      rating: 3,
      checkin: "2018-12-28 14:00:00",
      checkout: "2019-01-30 12:00:00",
      facility: [
        "Breakfast",
        "Dinner",
        "Meeting Room",
        "Lunch",
        "Swimming Pool",
        "Gym"
      ],
      policy: true,
      price: {
        IDR: 3500000
      }
    },
    {
      name: "Fave Hotel Kemang Jakarta Sel ...",
      rating: 3,
      checkin: "2018-12-28 14:00:00",
      checkout: "2019-01-30 12:00:00",
      facility: [
        "Breakfast",
        "Dinner",
        "Meeting Room",
        "Lunch",
        "Swimming Pool",
        "Gym"
      ],
      policy: true,
      price: {
        IDR: 3500000
      }
    },
    {
      name: "Fave Hotel Kemang Jakarta Sel ...",
      rating: 3,
      checkin: "2019-12-28 14:00:00",
      checkout: "2020-01-30 12:00:00",
      facility: [
        "Breakfast",
        "Dinner",
        "Meeting Room",
        "Lunch",
        "Swimming Pool",
        "Gym"
      ],
      policy: true,
      price: {
        IDR: 3500000
      }
    }
  ];

  const getMonth = (m: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    return months[m];
  };

  const getDay = (d: number) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[d];
  };

  const zeroNumber = (d: number) => {
    return d < 10 ? `0${d}` : d;
  };

  const dateFormated = (date: string) => {
    const dt = new Date(date);
    return (
      <>
        <Text>{`${getDay(dt.getDay())} `}</Text>
        <Text style={{ fontWeight: "bold" }}>
          {`${dt.getDate()} ${getMonth(dt.getMonth())} ${dt.getFullYear()} `}
        </Text>
        <Text>
          {`${zeroNumber(dt.getHours())}:${zeroNumber(dt.getMinutes())}`}
        </Text>
      </>
    );
  };
  const currency = (number: number) => {
    const num = number
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(".00", "")
      .split(",")
      .join(".");
    return num;
  };

  const getPrice = (price: Record<string, any>) => {
    let str = "";
    for (const i in price) {
      str = `${str + i} ${currency(price[i])}`;
    }
    return str;
  };

  const star = (total: number) => {
    let img = <></>;
    for (let i = 0; i < total; i += 1) {
      img = (
        <>
          {img}
          <Image style={styles.star} source={IMAGES.star} />
        </>
      );
    }
    const sisa = 5 - total;
    for (let j = 0; j < sisa; j += 1) {
      img = (
        <>
          {img}
          <Image style={styles.star} source={IMAGES.stargrey} />
        </>
      );
    }
    return <View style={{ flexDirection: "row" }}>{img}</View>;
  };

  return (
    <View>
      <Header title="New Booking" />
      <FlatList
        style={{ paddingBottom: 10, paddingLeft: 20, paddingRight: 20 }}
        data={hotelList}
        renderItem={({ item }) => (
          <Card
            cardStyle={styles.card}
            statusColor={item.policy ? "#50b1b1" : "#ed5a28"}
            statusText={item.policy ? "" : "Out of Policy"}
            statusDesc={getPrice(item.price)}
          >
            <View style={{ width: "100%", paddingTop: 14, paddingLeft: 21 }}>
              <View style={styles.rowItem}>
                <View style={styles.rowLabel}>
                  <Text style={styles.rowLabelText}>Hotel</Text>
                </View>
                <View style={styles.rowValue}>{star(item.rating)}</View>
              </View>
              <View style={styles.rowItem}>
                <View>
                  <Text style={styles.hotelName}>{item.name}</Text>
                </View>
              </View>
              <View style={styles.rowItem}>
                <View style={styles.rowLabel}>
                  <Text style={styles.rowLabelText}>Check In</Text>
                </View>
                <View style={styles.rowValue}>
                  <Text style={styles.rowValueText}>
                    {dateFormated(item.checkin)}
                  </Text>
                </View>
              </View>
              <View style={styles.rowItem}>
                <View style={styles.rowLabel}>
                  <Text style={styles.rowLabelText}>Check Out</Text>
                </View>
                <View style={styles.rowValue}>
                  <Text style={styles.rowValueText}>
                    {dateFormated(item.checkout)}
                  </Text>
                </View>
              </View>
              <View
                style={[styles.rowItem, { marginTop: 7, marginBottom: 15 }]}
              >
                <View style={styles.rowLabel}>
                  <Text style={styles.rowLabelText}>Facility</Text>
                </View>
                <View style={styles.rowValue}>
                  <Text style={styles.rowValueText}>
                    {item.facility.join(", ")}
                  </Text>
                </View>
              </View>
            </View>
          </Card>
        )}
      />
      <div
        style={{
          position: "sticky",
          bottom: 0,
          padding: 20,
          paddingTop: 5,
          alignSelf: "flex-end"
        }}
      >
        <Touchable>
          <Image
            style={{ width: 62.6, height: 75.6 }}
            source={IMAGES.iconFilter}
          />
        </Touchable>
      </div>
    </View>
  );
};

export default NewBookingHotelList;
