import { StyleSheet } from "react-native";
import { heightPercent } from "../../../../../utils";

const styles = StyleSheet.create({
  container: {
    width: 335,
    height: heightPercent(85),
    backgroundColor: "#fffff7",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 30,
    paddingTop: 42.9
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    color: "#666666"
  },
  subtitle: {
    color: "#999999",
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 11
  },
  rectangular: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 30,
    flexDirection: "row",
    alignSelf: "center",
    overflow: "hidden",
    marginBottom: 25,
    width: 290
  },
  rectangularItem: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    width: 92
  },
  rectangularItemText: {
    fontFamily: "Poppins",
    color: "#707070",
    fontSize: 10
  },
  rectangularItemRed: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    backgroundColor: "#ed5a28",
    width: 198
  },
  rectangularItemTextRed: {
    fontFamily: "Poppins",
    color: "#fffff7",
    fontSize: 10
  },
  rectangularDisabled: {
    borderRadius: 30,
    flexDirection: "row",
    alignSelf: "center",
    overflow: "hidden",
    marginBottom: 25,
    width: 290,
    borderColor: "#dddddd",
    borderStyle: "solid",
    borderWidth: 1
  },
  buttonClose: {
    paddingLeft: 14,
    paddingTop: 23
  },
  closeText: {
    fontFamily: "Poppins",
    fontSize: 18,
    color: "#707070",
    fontWeight: "bold"
  },
  rectangularItemDisabled: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    width: 92
  },
  rectangularItemTextDisabled: {
    fontFamily: "Poppins",
    color: "#707070",
    fontSize: 10
  },
  rectangularItemRedDisabled: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    backgroundColor: "#999999",
    width: 198
  },
  rectangularItemTextRedDisabled: {
    fontFamily: "Poppins",
    color: "#fffff7",
    fontSize: 10
  }
});

export default styles;
