import {
  GET_BUBBLE_ERROR,
  GET_BUBBLE_PENDING,
  GET_BUBBLE_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, BubbleNotifState } from "../types";

const initialState: BubbleNotifState = {
  isLoadingBubbleNotif: false,
  dataBubble: null
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // GET CASH BUBBLE
    case GET_BUBBLE_PENDING:
      return { ...state, isLoadingBubbleNotif: true };
    case GET_BUBBLE_SUCCESS:
      return {
        ...state,
        isLoadingBubbleNotif: false,
        dataBubble: payload.data
      };
    case GET_BUBBLE_ERROR:
      return { ...state, isLoadingBubbleNotif: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
