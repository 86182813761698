/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import "./style.css";
import {
  Button,
  Card,
  FileUpload,
  Fixed,
  Header,
  Select,
  TextArea,
  Touchable,
  Upload,
  WrapContent
} from "../../../../components";
import {
  getConfigUploadFile,
  getDateAvailableReport,
  getReportById,
  postNewReport,
  resetReportForm
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import {
  convertDate,
  copyWritings,
  documentTitle,
  heightPercent
} from "../../../../utils";

const NewReport = () => {
  documentTitle("Report - New Report");
  const dispatch = useDispatch();
  const { id } = useParams() as any;

  const { t } = useTranslation();
  const history = useHistory();
  const { sprintf } = require("sprintf-js");
  const { newReportState } = useSelector(
    (state: Reducers) => ({
      newReportState: state.report,
      auth: state.auth
    }),
    shallowEqual
  );
  const { state } = history.location as any;
  const [validasi, setValidasi] = useState({
    titleErr: "",
    descErr: "",
    isLoading: false
  });

  const [form, setForm]: any = useState({
    trip_id: `${id}`,
    date: "",
    title: "",
    description: "",
    file: null
  });
  const [heightFooter, setHeightFooter] = useState(0);
  const populateDateAvailable = newReportState.listDateAvailableReport.map(
    (e: any) => {
      return { value: e.id, label: convertDate(e.id) };
    }
  );

  useEffect(() => {
    async function getData() {
      const idTrip = await `${id}`;
      dispatch(getDateAvailableReport(idTrip));
      dispatch(getConfigUploadFile());
    }
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (state.reportId) {
      dispatch(getReportById(state.reportId));
    }
  }, [state.reportId]);

  useEffect(() => {
    if (state.reportId && newReportState.form) {
      setForm({
        ...form,
        title: newReportState.form.title,
        date: newReportState.form.date,
        description: newReportState.form.description,
        file: newReportState.form.file
      });
    } else {
      dispatch(resetReportForm());
    }
  }, [newReportState.form, state.reportId]);

  const validate = () => {
    let titleErr = "";
    let descErr = "";
    if (!form.title) {
      titleErr = "Subject is required!";
    }
    if (!form.description) {
      descErr = "Report is required!";
    }
    if (titleErr || descErr) {
      setValidasi({ ...validasi, titleErr, descErr });
      return false;
    }
    return true;
  };

  const onSave = () => {
    const isValid = validate();
    const body = {
      ...form,
      file: form.file && form.file.title ? "" || "" : form.file || ""
    };
    if (isValid) {
      setValidasi({ ...validasi, isLoading: true });
      dispatch(postNewReport(body, () => history.goBack(), state.reportId));
      setValidasi({ ...validasi, titleErr: "", descErr: "" });
    }
  };

  const _uploadFile = (e: any) => {
    const files = e.target.files[0];
    setForm({ ...form, file: files });
  };

  const _goToPreview = () => {
    if (form.file.title) {
      window.open(form.file.url, "_blank");
    } else {
      const go = URL.createObjectURL(form.file);
      window.open(go, "_blank");
    }
  };

  const _deleteFile = () => {
    setForm({ ...form, file: null });
  };

  const copTeks = {
    report: copyWritings("report")
  };

  const _setNameHeader = () => {
    const res = {
      headerName: "",
      buttonName: ""
    };
    if (newReportState.form.status > 1) {
      res.headerName = sprintf(t("cash.new.reportDetail"), copTeks.report);
    } else if (state.reportId) {
      res.buttonName = t("cash.new.save");
      res.headerName = sprintf(t("cash.new.editReport"), copTeks.report);
    } else {
      res.buttonName = t("cash.new.add");
      res.headerName = sprintf(t("cash.new.addReport"), copTeks.report);
    }
    return res;
  };

  return (
    <View style={{ height: heightPercent(100) }}>
      <Header title={_setNameHeader().headerName} />
      <WrapContent isLoading={newReportState.isLoadingGetReportById}>
        <View
          style={[styles.newReportContent, { marginBottom: heightFooter * 2 }]}
        >
          <View style={{ marginBottom: 8 }}>
            <View
              style={[
                styles.row,
                newReportState.form.status > 1 && { borderBottomWidth: 0 }
              ]}
            >
              <Text style={[styles.label, { marginLeft: 0 }]}>
                {t("cash.new.subject")}
              </Text>
              {newReportState.form.status < 2 ? (
                <TextInput
                  style={[styles.textInput]}
                  value={form.title}
                  onChangeText={text => {
                    setForm({ ...form, title: text });
                    setValidasi(
                      !text
                        ? { ...validasi, titleErr: "Subject is required!" }
                        : { ...validasi, titleErr: "" }
                    );
                  }}
                />
              ) : (
                <Text style={styles.input}>{form.title}</Text>
              )}
            </View>
            <Text style={styles.error}>{validasi.titleErr}</Text>
          </View>

          <View style={[styles.rowWrap, { zIndex: 1 }]}>
            <View style={[styles.wrapLabelInput, { flex: 0.5 }]}>
              <Text style={styles.label}>{t("cash.new.date")}</Text>
              {state.reportId ? (
                <Text style={[styles.input, { marginLeft: 12 }]}>
                  <Text style={{ fontWeight: "normal" }}>
                    {`${moment(form.date).format("ddd")} `}
                  </Text>
                  {moment(form.date).format("DD MMM YYYY")}
                </Text>
              ) : (
                <Card cardStyle={styles.cardMultiTrip}>
                  {!newReportState.isLoadingDateAvailableReport &&
                  newReportState.listDateAvailableReport.length > 0 ? (
                    <Select
                      listValue={populateDateAvailable}
                      defaultValue={() =>
                        setForm({
                          ...form,
                          date: populateDateAvailable[0].value
                        })
                      }
                      onChange={e =>
                        setForm({
                          ...form,
                          date: e.target.value
                        })
                      }
                    />
                  ) : (
                    <ActivityIndicator color="#666666" size="small" />
                  )}
                </Card>
              )}
            </View>
          </View>

          <View style={styles.wrapReport}>
            <Text style={styles.label}>{copTeks.report}</Text>
            {newReportState.form.status < 2 ? (
              <TextArea
                withPaddingSm
                bold
                onChange={(e: any) => {
                  setForm({ ...form, description: e.target.value });
                  setValidasi(
                    !e.target.value
                      ? { ...validasi, descErr: "Report is required!" }
                      : { ...validasi, descErr: "" }
                  );
                }}
                value={form.description}
              />
            ) : (
              <Text style={[styles.input, { marginLeft: 12 }]}>
                {form.description}
              </Text>
            )}
            <Text style={styles.error}>{validasi.descErr}</Text>
          </View>
          <View style={{ marginTop: 12 }}>
            <View style={{ marginBottom: 6 }}>
              {form.file && (
                <Touchable onPress={_goToPreview}>
                  <FileUpload
                    name={form.file.title ? form.file.title : form.file.name}
                    style={{
                      marginTop: 6,
                      marginBottom: 6,
                      marginRight: 8,
                      width: "40%"
                    }}
                    hideDelete={state.reportId}
                    deleteFile={e => {
                      e.stopPropagation();
                      _deleteFile();
                    }}
                  />
                </Touchable>
              )}
            </View>
            {newReportState.form.status < 2 && (
              <Upload
                style={{
                  textAlign: "center",
                  padding: 0,
                  width: "48%",
                  alignItems: "center",
                  paddingBottom: 6,
                  paddingTop: 6
                }}
                onChange={(e: any) => _uploadFile(e)}
              />
            )}
          </View>
        </View>
      </WrapContent>
      <Fixed
        getHeight={e => setHeightFooter(e)}
        customPosition={{ bottom: 30 }}
      >
        {newReportState.form.status < 2 && (
          <View style={styles.button}>
            <Button
              title={_setNameHeader().buttonName}
              onPress={() => onSave()}
              isLoading={newReportState.isLoadingPostNewReport}
            />
          </View>
        )}
      </Fixed>
    </View>
  );
};

export default NewReport;
