import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import styles from "./styles";
import { Reducers } from "../../redux/types";
import {
  getBubbleNotif,
  getMyPermission,
  resetSearchTaskValue,
  setSearchTaskValue
} from "../../redux/actions";
import { copyWritings, heightPercent, permissionPage } from "../../utils";
import {
  BubbleNotif,
  Fixed,
  Header,
  Search,
  Touchable
} from "../../components";
import { IMAGES } from "../../configs";

import List from "./List";
import { debounce } from "../../utils/helpers";

const Task = () => {
  const dispatch = useDispatch();
  const homeState = useSelector((state: Reducers) => state.home);
  const bubbleState = useSelector((state: Reducers) => state.bubbleNotif);
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;
  const [heightHeader, setHeightHeader] = useState(0);

  const { t } = useTranslation();

  const permission = {
    selfList: permissionPage("task-self-list"),
    otherList: permissionPage("task-others-list")
  };

  useEffect(() => {
    dispatch(getMyPermission());
    dispatch(getBubbleNotif("task"));
    dispatch(resetSearchTaskValue());
  }, [dispatch]);

  const _getBubleData = useCallback(
    (
      type:
        | "upcoming"
        | "ongoing"
        | "history"
        | "assigned"
        | "request"
        | "approval"
    ) => {
      if (bubbleState.dataBubble) {
        return bubbleState.dataBubble && bubbleState.dataBubble[type];
      }
      return false;
    },
    [bubbleState.dataBubble]
  );

  const [listPages, setListPages] = useState([
    {
      pathname: match.path,
      component: <List type="upcoming" />,
      iconTab: IMAGES.upComingTask,
      titleTab: t("task.upcomingTab"),
      exact: true,
      accessible: permission.selfList,
      bubbleData: _getBubleData("upcoming")
    },
    {
      pathname: `${match.path}/ongoing`,
      component: <List type="ongoing" />,
      iconTab: IMAGES.ongoing,
      titleTab: t("task.ongoingTab"),
      exact: true,
      accessible: permission.selfList,
      bubbleData: _getBubleData("ongoing")
    },
    {
      pathname: `${match.path}/finished`,
      component: <List type="finished" />,
      iconTab: IMAGES.history,
      titleTab: t("task.historyTab"),
      exact: true,
      accessible: permission.selfList,
      bubbleData: _getBubleData("history")
    },
    {
      pathname: `${match.path}/assigned`,
      component: <List type="assigned" />,
      iconTab: IMAGES.assignment,
      titleTab: t("task.assignTab"),
      exact: true,
      accessible: permission.otherList,
      bubbleData: _getBubleData("assigned")
    },
    {
      pathname: `${match.path}/myrequest`,
      component: <List type="myrequest" />,
      iconTab: IMAGES.cashBillable,
      titleTab: t("task.requestTab"),
      exact: true,
      accessible: permission.selfList,
      bubbleData: _getBubleData("request")
    },
    {
      pathname: `${match.path}/moderation`,
      component: <List type="moderation" />,
      iconTab: IMAGES.approval,
      titleTab: t("task.approvalTab"),
      exact: true,
      accessible: permission.selfList,
      bubbleData: _getBubleData("approval")
    }
  ]);

  useEffect(() => {
    if (bubbleState.dataBubble) {
      setListPages([
        {
          pathname: match.path,
          component: <List type="upcoming" />,
          iconTab: IMAGES.upComingTask,
          titleTab: t("task.upcomingTab"),
          exact: true,
          accessible: permission.selfList,
          bubbleData: _getBubleData("upcoming")
        },
        {
          pathname: `${match.path}/ongoing`,
          component: <List type="ongoing" />,
          iconTab: IMAGES.ongoing,
          titleTab: t("task.ongoingTab"),
          exact: true,
          accessible: permission.selfList,
          bubbleData: _getBubleData("ongoing")
        },
        {
          pathname: `${match.path}/finished`,
          component: <List type="finished" />,
          iconTab: IMAGES.history,
          titleTab: t("task.historyTab"),
          exact: true,
          accessible: permission.selfList,
          bubbleData: _getBubleData("history")
        },
        {
          pathname: `${match.path}/assigned`,
          component: <List type="assigned" />,
          iconTab: IMAGES.assignment,
          titleTab: t("task.assignTab"),
          exact: true,
          accessible: permission.otherList,
          bubbleData: _getBubleData("assigned")
        },
        {
          pathname: `${match.path}/myrequest`,
          component: <List type="myrequest" />,
          iconTab: IMAGES.cashBillable,
          titleTab: t("task.requestTab"),
          exact: true,
          accessible: permission.selfList,
          bubbleData: _getBubleData("request")
        },
        {
          pathname: `${match.path}/moderation`,
          component: <List type="moderation" />,
          iconTab: IMAGES.approval,
          titleTab: t("task.approvalTab"),
          exact: true,
          accessible: permission.selfList,
          bubbleData: _getBubleData("approval")
        }
      ]);
    }
  }, [bubbleState.dataBubble]);

  const _onChangeSearch = debounce((text: string) => {
    dispatch(setSearchTaskValue(text));
  });

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            title={copyWritings("task").toUpperCase()}
            goBack={() => history.push("/home")}
          />
          <View
            style={[
              styles.container,
              homeState.isLoadingMyProfile && { opacity: 0 }
            ]}
          >
            <View style={styles.wrapTaskNav}>
              <View style={styles.taskNav}>
                {listPages.map((item, index) => (
                  <>
                    {item.accessible && (
                      <View
                        key={index}
                        style={[
                          styles.btnNav,
                          index === 0 &&
                            item.pathname === pathname &&
                            styles.active,
                          index !== 0 &&
                            pathname.includes(item.pathname) &&
                            styles.active
                        ]}
                      >
                        <Touchable onPress={() => history.push(item.pathname)}>
                          <View
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                              flex: 1
                            }}
                          >
                            <Image
                              resizeMode="contain"
                              style={styles.image}
                              source={item.iconTab}
                            />
                            <Text style={styles.btnText}>{item.titleTab}</Text>
                          </View>
                        </Touchable>
                        {item.bubbleData && (
                          <BubbleNotif
                            value={item.bubbleData.total}
                            message={item.bubbleData.message}
                          />
                        )}
                      </View>
                    )}
                  </>
                ))}
              </View>
            </View>
            <Search onChange={_onChangeSearch} />
          </View>
        </Fixed>
        <View
          style={{
            marginTop: heightHeader + 16,
            minHeight: heightPercent(90) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};

export default Task;
