import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 24
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  labelError: {
    color: COLORS.orange,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  row: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight,
    marginBottom: 12
  },
  error: {
    borderBottomColor: COLORS.orange
  },
  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginTop: 4,
    marginBottom: 2,
    outlineColor: "rgba(255,255,255,0)"
  },
  wrapTwoInput: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  footer: {
    alignItems: "center",
    backgroundColor: COLORS.main
  },
  wrapButtonFoot: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    borderRadius: 28,
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    marginVertical: 24
  },
  buttonFoot: {
    width: 180,
    height: 54,
    justifyContent: "center",
    alignItems: "center"
  },
  textButtonFoot: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    color: COLORS.white
  }
});

export default styles;
