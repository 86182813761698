import {
  DELETE_CASH_BILL_ERROR,
  DELETE_CASH_BILL_PENDING,
  DELETE_CASH_BILL_SUCCESS,
  DELETE_CASH_ITEM_ERROR,
  DELETE_CASH_ITEM_PENDING,
  DELETE_CASH_ITEM_SUCCESS,
  GET_BILL_ERROR,
  GET_BILL_PENDING,
  GET_BILL_SUCCESS,
  GET_CASH_DETAIL_ERROR,
  GET_CASH_DETAIL_PENDING,
  GET_CASH_DETAIL_SUCCESS,
  GET_CASH_ITEM_BY_ID_ERROR,
  GET_CASH_ITEM_BY_ID_PENDING,
  GET_CASH_ITEM_BY_ID_SUCCESS,
  GET_CASH_LIST_ERROR,
  GET_CASH_LIST_PENDING,
  GET_CASH_LIST_SUCCESS,
  GET_MODERATION_REFERENCE_ERROR,
  GET_MODERATION_REFERENCE_PENDING,
  GET_MODERATION_REFERENCE_SUCCESS,
  GET_REQUEST_LIST_ERROR,
  GET_REQUEST_LIST_PENDING,
  GET_REQUEST_LIST_SUCCESS,
  PUT_RECURRING_ERROR,
  PUT_RECURRING_PENDING,
  PUT_RECURRING_SUCCESS,
  PUT_REISSUE_ERROR,
  PUT_REISSUE_PENDING,
  PUT_REISSUE_SUCCESS,
  PUT_SETTLE_ERROR,
  PUT_SETTLE_PENDING,
  PUT_SETTLE_SUCCESS,
  PUT_SUBMIT_BILL_ERROR,
  PUT_SUBMIT_BILL_PENDING,
  PUT_SUBMIT_BILL_SUCCESS,
  RESET_ITEM_CASH,
  RESET_SEARCH_CASH_VALUE,
  SET_MODAL_RECURRING,
  SET_SEARCH_CASH_VALUE,
  SUBMIT_ISSUE_ERROR,
  SUBMIT_ISSUE_PENDING,
  SUBMIT_ISSUE_SUCCESS
} from "../actions";
import { Action, CashState } from "../types";

const initialState: CashState = {
  paramCashList: {
    per_page: 10,
    page: 1
  },
  isLoadingCashList: false,
  loadMoreCashList: true,
  cashList: [],
  cashDetail: null,
  isLoadingCashDetail: false,
  isLoadingPutRecurring: false,
  setModalRecurring: false,
  moderationReference: null,
  isLoadingModerationReference: false,
  requestList: [],
  isLoadingRequestList: false,
  isLoadingGetBill: false,
  billList: [],
  cashHistory: {
    payments: [],
    total: "",
    total_paid: "",
    status: 0,
    djurneeInvoice: { trip: { id: "" }, type: { title: "" } },
    creator: { id: "" }
  },
  cashItemById: { id: "", attributes: { file: "" } },
  isLoadingCashItemId: false,
  isLoadingDeleteCashItem: false,
  isLoadingReissue: false,
  isLoadingDeletBill: false,
  isLoadingPutSettle: false,
  isLoadingSubmitIssue: false,
  searchValue: "",
  isLoadingSubmitBill: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // Get Cash List
    case GET_CASH_LIST_PENDING:
      return { ...state, isLoadingCashList: !payload.loadMore };
    case GET_CASH_LIST_SUCCESS:
      return {
        ...state,
        isLoadingCashList: false,
        cashList: !payload.loadMore
          ? payload.data
          : [...state.cashList, ...payload.data],
        paramCashList: {
          ...state.paramCashList,
          page: !payload.loadMore ? 2 : state.paramCashList.page + 1
        },
        loadMoreCashList: !(
          payload.data.length < initialState.paramCashList.per_page
        )
      };
    case GET_CASH_LIST_ERROR:
      return { ...state, isLoadingCashList: false };

    // get Detail cash
    case GET_CASH_DETAIL_PENDING:
      return { ...state, isLoadingCashDetail: true };
    case GET_CASH_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingCashDetail: false,
        cashDetail: payload.data,
        cashHistory: payload.data
      };
    case GET_CASH_DETAIL_ERROR:
      return { ...state, isLoadingCashDetail: false };

    // get moderation reference
    case GET_MODERATION_REFERENCE_PENDING:
      return { ...state, isLoadingModerationReference: true };
    case GET_MODERATION_REFERENCE_SUCCESS:
      return {
        ...state,
        isLoadingModerationReference: false,
        moderationReference: payload.data
      };
    case GET_MODERATION_REFERENCE_ERROR:
      return { ...state, isLoadingModerationReference: false };

    // put recurring
    case PUT_RECURRING_PENDING:
      return {
        ...state,
        isLoadingPutRecurring: true
      };
    case PUT_RECURRING_SUCCESS:
      return {
        ...state,
        isLoadingPutRecurring: false
      };
    case PUT_RECURRING_ERROR:
      return {
        ...state,
        isLoadingPutRecurring: false
      };

    case SET_MODAL_RECURRING:
      return { ...state, setModalRecurring: payload.data };

    // get Detail cash
    case GET_REQUEST_LIST_PENDING:
      return { ...state, isLoadingRequestList: true };
    case GET_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        isLoadingRequestList: false,
        requestList: payload.data
      };
    case GET_REQUEST_LIST_ERROR:
      return { ...state, isLoadingRequestList: false };

    // get bills
    case GET_BILL_PENDING:
      return { ...state, isLoadingGetBill: true };
    case GET_BILL_SUCCESS:
      return {
        ...state,
        isLoadingGetBill: false,
        billList: payload.data
      };
    case GET_BILL_ERROR:
      return { ...state, isLoadingGetBill: false };

    case GET_CASH_ITEM_BY_ID_PENDING:
      return { ...state, isLoadingCashItemId: true };
    case GET_CASH_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingCashItemId: false,
        cashItemById: payload.data
      };
    case GET_CASH_ITEM_BY_ID_ERROR:
      return { ...state, isLoadingCashItemId: false };

    case RESET_ITEM_CASH:
      return { ...state, cashItemById: initialState.cashItemById };

    // DELETE CASH ITEM
    case DELETE_CASH_ITEM_PENDING:
      return { ...state, isLoadingDeleteCashItem: true };
    case DELETE_CASH_ITEM_SUCCESS:
      return { ...state, isLoadingDeleteCashItem: false };
    case DELETE_CASH_ITEM_ERROR:
      return { ...state, isLoadingDeleteCashItem: false };

    // reissue item request
    case PUT_REISSUE_PENDING:
      return { ...state, isLoadingReissue: true };
    case PUT_REISSUE_SUCCESS:
      return { ...state, isLoadingReissue: false };
    case PUT_REISSUE_ERROR:
      return { ...state, isLoadingReissue: false };

    // DELETE BILL
    case DELETE_CASH_BILL_PENDING:
      return { ...state, isLoadingDeletBill: true };
    case DELETE_CASH_BILL_SUCCESS:
      return { ...state, isLoadingDeletBill: false };
    case DELETE_CASH_BILL_ERROR:
      return { ...state, isLoadingDeletBill: false };

    case PUT_SETTLE_PENDING:
      return { ...state, isLoadingPutSettle: true };
    case PUT_SETTLE_SUCCESS:
      return { ...state, isLoadingPutSettle: false };
    case PUT_SETTLE_ERROR:
      return { ...state, isLoadingPutSettle: false };

    case SUBMIT_ISSUE_PENDING:
      return { ...state, isLoadingSubmitIssue: true };
    case SUBMIT_ISSUE_SUCCESS:
      return { ...state, isLoadingSubmitIssue: false };
    case SUBMIT_ISSUE_ERROR:
      return { ...state, isLoadingSubmitIssue: false };

    case SET_SEARCH_CASH_VALUE:
      return { ...state, searchValue: payload.data };

    case RESET_SEARCH_CASH_VALUE:
      return { ...state, searchValue: initialState.searchValue };

    case PUT_SUBMIT_BILL_PENDING:
      return { ...state, isLoadingSubmitBill: true };
    case PUT_SUBMIT_BILL_SUCCESS:
      return { ...state, isLoadingSubmitBill: false };
    case PUT_SUBMIT_BILL_ERROR:
      return { ...state, isLoadingSubmitBill: false };

    default:
      return state;
  }
};
