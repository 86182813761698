/* eslint-disable no-param-reassign */
import moment from "moment";

import {
  DELETE_FILE_DETAIL_TASK_ERROR,
  DELETE_FILE_DETAIL_TASK_PENDING,
  DELETE_FILE_DETAIL_TASK_SUCCESS,
  EDIT_DETAIL_TASK_REPORT_ERROR,
  EDIT_DETAIL_TASK_REPORT_PENDING,
  EDIT_DETAIL_TASK_REPORT_SUCCESS,
  EXTENDS_DAYS_DETAIL_TASK_ERROR,
  EXTENDS_DAYS_DETAIL_TASK_PENDING,
  EXTENDS_DAYS_DETAIL_TASK_SUCCESS,
  GET_ADD_PARTICIPANT,
  GET_AVAILABLE_DATE_TASK_REPORT_ERROR,
  GET_AVAILABLE_DATE_TASK_REPORT_PENDING,
  GET_AVAILABLE_DATE_TASK_REPORT_SUCCESS,
  GET_DETAIL_TASK_ERROR,
  GET_DETAIL_TASK_PENDING,
  GET_DETAIL_TASK_REMARKS_ERROR,
  GET_DETAIL_TASK_REMARKS_PENDING,
  GET_DETAIL_TASK_REMARKS_SUCCESS,
  GET_DETAIL_TASK_REPORT_ERROR,
  GET_DETAIL_TASK_REPORT_PENDING,
  GET_DETAIL_TASK_REPORT_SUCCESS,
  GET_DETAIL_TASK_SUCCESS,
  GET_TASK_LIST_DROPDOWN_ERROR,
  GET_TASK_LIST_DROPDOWN_PENDING,
  GET_TASK_LIST_DROPDOWN_SUCCESS,
  GET_TASK_LIST_ERROR,
  GET_TASK_LIST_PENDING,
  GET_TASK_LIST_SUCCESS,
  LOGOUT_SUCCESS,
  POST_ACTIVATE_TASK_ERROR,
  POST_ACTIVATE_TASK_PENDING,
  POST_ACTIVATE_TASK_SUCCESS,
  POST_DETAIL_TASK_REMARKS_ERROR,
  POST_DETAIL_TASK_REMARKS_PENDING,
  POST_DETAIL_TASK_REMARKS_SUCCESS,
  POST_TASK_DETAIL_REPORT_ERROR,
  POST_TASK_DETAIL_REPORT_PENDING,
  POST_TASK_DETAIL_REPORT_SUCCESS,
  POST_TASK_ERROR,
  POST_TASK_PENDING,
  POST_TASK_REQUEST_ERROR,
  POST_TASK_REQUEST_PENDING,
  POST_TASK_REQUEST_SUCCESS,
  POST_TASK_SUCCESS,
  PUT_TASK_EDIT_ERROR,
  PUT_TASK_EDIT_PENDING,
  PUT_TASK_EDIT_RECURRING_ERROR,
  PUT_TASK_EDIT_RECURRING_PENDING,
  PUT_TASK_EDIT_RECURRING_SUCCESS,
  PUT_TASK_EDIT_SUCCESS,
  PUT_TASK_ERROR,
  PUT_TASK_PENDING,
  PUT_TASK_SUCCESS,
  RESET_DETAIL_TASK,
  RESET_PARTICIPANT,
  RESET_SEARCH_TASK_VALUE,
  SAVE_ADD_PARTICIPANT,
  SET_ADD_PARTICIPANT,
  SET_DELETE_PARTICIPANT,
  SET_MODAL_RECURRING_TASK,
  SET_SEARCH_TASK_VALUE,
  SET_TASK_DETAIL_REPORT_WANT_EDIT,
  SUBMIT_END_TASK_ERROR,
  SUBMIT_END_TASK_PENDING,
  SUBMIT_END_TASK_SUCCESS,
  SUBMIT_TASK_DETAIL_REPORT_ERROR,
  SUBMIT_TASK_DETAIL_REPORT_PENDING,
  SUBMIT_TASK_DETAIL_REPORT_SUCCESS,
  UPLOAD_FILE_DETAIL_TASK_ERROR,
  UPLOAD_FILE_DETAIL_TASK_PENDING,
  UPLOAD_FILE_DETAIL_TASK_REPORT_ERROR,
  UPLOAD_FILE_DETAIL_TASK_REPORT_PENDING,
  UPLOAD_FILE_DETAIL_TASK_REPORT_SUCCESS,
  UPLOAD_FILE_DETAIL_TASK_SUCCESS
} from "../actions";
import { Action, TaskState } from "../types";

const initialState: TaskState = {
  availableDateTaskReport: [],
  detailTaskData: null,
  detailTaskReportData: null,
  detailTaskWantEdit: null,
  paramTaskList: {
    per_page: 10,
    page: 1
  },
  isLoadingTaskList: false,
  loadMoreTaskList: true,
  taskList: [],
  remarks: null,
  isLoadingActivateTask: false,
  isLoadingAvailableDateTaskReport: false,
  isLoadingDetailTaskData: false,
  isLoadingDeleteFileDetailTask: false,
  isLoadingExtendDays: false,
  isLoadingUploadFileDetailTask: false,
  isLoadingRemarks: false,
  isLoadingDetailTaskReportData: false,
  isLoadingEndtask: false,
  isLoadingPostDetailTaskRemarks: false,
  isLoadingPostTask: false,
  isLoadingPostTaskRequest: false,
  participanList: [],
  saveParticipanList: [],
  isLoadingPutTask: false,
  taskListDropdown: [],
  isLoadingTaskListDropDown: false,
  setModalRecurring: false,
  searchValue: ""
};

export default (state = initialState, { type, payload }: Action) => {
  let formatedDate: any[] = [];
  switch (type) {
    // Get Task
    case GET_TASK_LIST_PENDING:
      return { ...state, isLoadingTaskList: !payload.loadMore };
    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        isLoadingTaskList: false,
        taskList: !payload.loadMore
          ? payload.data
          : [...state.taskList, ...payload.data],
        paramTaskList: {
          ...state.paramTaskList,
          page: !payload.loadMore ? 2 : state.paramTaskList.page + 1
        },
        loadMoreTaskList: !(
          payload.data.length < initialState.paramTaskList.per_page
        )
      };
    case GET_TASK_LIST_ERROR:
      return { ...state, isLoadingTaskList: false };

    // GET DETAIL TASK
    case GET_DETAIL_TASK_PENDING:
      return { ...state, isLoadingDetailTaskData: true };
    case GET_DETAIL_TASK_SUCCESS:
      return {
        ...state,
        isLoadingDetailTaskData: false,
        detailTaskData: payload.data,
        isLoadingDeleteFileDetailTask: false,
        isLoadingUploadFileDetailTask: false,
        saveParticipanList: payload.data.employee
      };
    case GET_DETAIL_TASK_ERROR:
      return { ...state, isLoadingDetailTaskData: false };

    // GET DETAIL TASK REPORT
    case GET_DETAIL_TASK_REPORT_PENDING:
      return { ...state, isLoadingDetailTaskReportData: true };
    case GET_DETAIL_TASK_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingDetailTaskReportData: false,
        detailTaskReportData: payload.data
      };
    case GET_DETAIL_TASK_REPORT_ERROR:
      return { ...state, isLoadingDetailTaskReportData: false };

    // GET REMARKS
    case GET_DETAIL_TASK_REMARKS_PENDING:
      return { ...state, isLoadingRemarks: true };
    case GET_DETAIL_TASK_REMARKS_SUCCESS:
      return {
        ...state,
        isLoadingRemarks: false,
        remarks: payload.data || { comments: [] }
      };
    case GET_DETAIL_TASK_REMARKS_ERROR:
      return { ...state, isLoadingRemarks: false };

    case RESET_DETAIL_TASK:
      return {
        ...state,
        saveParticipanList: initialState.participanList,
        detailTaskData: initialState.detailTaskData
      };

    // UPLOAD FILE DETAIL TASK
    case UPLOAD_FILE_DETAIL_TASK_PENDING:
      return { ...state, isLoadingUploadFileDetailTask: true };
    case UPLOAD_FILE_DETAIL_TASK_SUCCESS:
      return {
        ...state,
        isLoadingUploadFileDetailTask: false,
        detailTaskData: {
          ...state.detailTaskData,
          file_collection: [
            ...state.detailTaskData.file_collection,
            {
              file: {
                file: payload.data.file,
                title: payload.data.fileName,
                id: payload.data.res.data.data[0]
              },
              isNew: true
            }
          ]
        }
      };
    case UPLOAD_FILE_DETAIL_TASK_ERROR:
      return { ...state, isLoadingUploadFileDetailTask: false };

    case UPLOAD_FILE_DETAIL_TASK_REPORT_PENDING:
      return { ...state, isLoadingUploadFileDetailTask: true };
    case UPLOAD_FILE_DETAIL_TASK_REPORT_SUCCESS:
      return { ...state, isLoadingUploadFileDetailTask: false };
    case UPLOAD_FILE_DETAIL_TASK_REPORT_ERROR:
      return { ...state, isLoadingUploadFileDetailTask: false };

    // DELETE FILE DETAIL TASK
    case DELETE_FILE_DETAIL_TASK_PENDING:
      return { ...state, isLoadingDeleteFileDetailTask: true };
    case DELETE_FILE_DETAIL_TASK_SUCCESS:
      state.detailTaskData.file_collection.splice(payload.data, 1);
      return {
        ...state,
        isLoadingDeleteFileDetailTask: false
      };
    case DELETE_FILE_DETAIL_TASK_ERROR:
      return { ...state, isLoadingDeleteFileDetailTask: false };

    case SUBMIT_END_TASK_PENDING:
      return { ...state, isLoadingEndtask: true };
    case SUBMIT_END_TASK_SUCCESS:
      return {
        ...state,
        isLoadingEndtask: false
      };
    case SUBMIT_END_TASK_ERROR:
      return { ...state, isLoadingEndtask: false };

    case SUBMIT_TASK_DETAIL_REPORT_PENDING:
      return { ...state };
    case SUBMIT_TASK_DETAIL_REPORT_SUCCESS:
      state.detailTaskReportData[
        state.detailTaskReportData.findIndex(
          (data: any) => data.id === payload.data.id
        )
      ].status = 2;
      return {
        ...state
      };
    case SUBMIT_TASK_DETAIL_REPORT_ERROR:
      return { ...state };

    // Post task detail report
    case POST_TASK_DETAIL_REPORT_PENDING:
      return { ...state, isLoadingDetailTaskReportData: true };
    case POST_TASK_DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingDetailTaskReportData: false,
        detailTaskReportData: payload.data
      };
    case POST_TASK_DETAIL_REPORT_ERROR:
      return { ...state, isLoadingDetailTaskReportData: false };

    // DETAIL TASK REPORT WANT EDIT
    case SET_TASK_DETAIL_REPORT_WANT_EDIT:
      return { ...state, detailTaskWantEdit: payload.data };

    // GET AVAILABLE DATE TASK REPORT
    case GET_AVAILABLE_DATE_TASK_REPORT_PENDING:
      return { ...state, isLoadingAvailableDateTaskReport: true };
    case GET_AVAILABLE_DATE_TASK_REPORT_SUCCESS:
      payload.data.map((date: string) => {
        formatedDate = [
          ...formatedDate,
          { label: moment(date).format("ddd, DD MMMM YYYY"), value: date }
        ];
      });
      return {
        ...state,
        isLoadingAvailableDateTaskReport: false,
        availableDateTaskReport: [...formatedDate]
      };
    case GET_AVAILABLE_DATE_TASK_REPORT_ERROR:
      return { ...state, isLoadingAvailableDateTaskReport: false };

    // POST REMARKS
    case POST_DETAIL_TASK_REMARKS_PENDING:
      return { ...state, isLoadingPostDetailTaskRemarks: true };
    case POST_DETAIL_TASK_REMARKS_SUCCESS:
      return {
        ...state,
        remarks: {
          ...state.remarks,
          comments: [
            ...state.remarks.comments,
            {
              content: payload.data.content,
              created_at: payload.data.created_at,
              creator: payload.data.creator
            }
          ]
        },
        isLoadingPostDetailTaskRemarks: false
      };
    case POST_DETAIL_TASK_REMARKS_ERROR:
      return { ...state, isLoadingPostDetailTaskRemarks: false };

    case EDIT_DETAIL_TASK_REPORT_PENDING:
      return { ...state, isLoadingDetailTaskReportData: true };
    case EDIT_DETAIL_TASK_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingDetailTaskReportData: false,
        detailTaskReportData: payload.data
      };
    case EDIT_DETAIL_TASK_REPORT_ERROR:
      return { ...state, isLoadingDetailTaskReportData: false };

    // Post Task
    case POST_TASK_PENDING:
      return {
        ...state,
        isLoadingPostTask: true
      };
    case POST_TASK_SUCCESS:
      return {
        ...state,
        isLoadingPostTask: false
      };
    case POST_TASK_ERROR:
      return {
        ...state,
        isLoadingPostTask: false
      };

    // Post Task Request
    case POST_TASK_REQUEST_PENDING:
      return {
        ...state,
        isLoadingPostTaskRequest: true
      };
    case POST_TASK_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingPostTaskRequest: false
      };
    case POST_TASK_REQUEST_ERROR:
      return {
        ...state,
        isLoadingPostTaskRequest: false
      };

    // put task
    case PUT_TASK_PENDING:
      return {
        ...state,
        isLoadingPutTask: true
      };
    case PUT_TASK_SUCCESS:
      return {
        ...state,
        isLoadingPutTask: false
      };
    case PUT_TASK_ERROR:
      return {
        ...state,
        isLoadingPutTask: false
      };

    // put task edit
    case PUT_TASK_EDIT_PENDING:
      return {
        ...state,
        isLoadingPutTask: true
      };
    case PUT_TASK_EDIT_SUCCESS:
      return {
        ...state,
        isLoadingPutTask: false
      };
    case PUT_TASK_EDIT_ERROR:
      return {
        ...state,
        isLoadingPutTask: false
      };
    case PUT_TASK_EDIT_RECURRING_PENDING:
      return {
        ...state,
        isLoadingPutTask: true
      };
    case PUT_TASK_EDIT_RECURRING_SUCCESS:
      return {
        ...state,
        isLoadingPutTask: false
      };
    case PUT_TASK_EDIT_RECURRING_ERROR:
      return {
        ...state,
        isLoadingPutTask: false
      };

    case POST_ACTIVATE_TASK_PENDING:
      return {
        ...state,
        isLoadingActivateTask: true
      };
    case POST_ACTIVATE_TASK_SUCCESS:
      return {
        ...state,
        isLoadingActivateTask: false
      };
    case POST_ACTIVATE_TASK_ERROR:
      return {
        ...state,
        isLoadingActivateTask: false
      };

    case GET_TASK_LIST_DROPDOWN_PENDING:
      return {
        ...state,
        isLoadingTaskListDropDown: true
      };
    case GET_TASK_LIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        taskListDropdown: payload.data.data,
        isLoadingTaskListDropDown: false
      };
    case GET_TASK_LIST_DROPDOWN_ERROR:
      return {
        ...state,
        isLoadingTaskListDropDown: false
      };

    case SET_ADD_PARTICIPANT:
      return {
        ...state,
        participanList: [...state.participanList, payload.data]
      };

    case SET_DELETE_PARTICIPANT:
      return {
        ...state,
        participanList: state.participanList.filter(e => e.id !== payload.data)
      };

    case SAVE_ADD_PARTICIPANT:
      return {
        ...state,
        saveParticipanList: state.participanList,
        participanList: initialState.participanList
      };

    case GET_ADD_PARTICIPANT:
      return { ...state, participanList: state.saveParticipanList };

    case RESET_PARTICIPANT:
      return {
        ...state,
        saveParticipanList: initialState.participanList
      };

    case SET_MODAL_RECURRING_TASK:
      return { ...state, setModalRecurring: payload.data };

    // EXTENDS DAYS DETAIL TASK
    case EXTENDS_DAYS_DETAIL_TASK_PENDING:
      return { ...state, isLoadingExtendDays: true };
    case EXTENDS_DAYS_DETAIL_TASK_SUCCESS:
      return {
        ...state,
        isLoadingExtendDays: false,
        detailTaskData: {
          ...state.detailTaskData,
          history_extends: [
            ...state.detailTaskData.history_extends,
            payload.data
          ]
        }
      };
    case EXTENDS_DAYS_DETAIL_TASK_ERROR:
      return { ...state, isLoadingExtendDays: false };
    case LOGOUT_SUCCESS:
      return initialState;

    case SET_SEARCH_TASK_VALUE:
      return { ...state, searchValue: payload.data };

    case RESET_SEARCH_TASK_VALUE:
      return { ...state, searchValue: initialState.searchValue };

    default:
      return state;
  }
};
