/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Cookies from "js-cookie";
import {
  deleteCashItem,
  // deleteCashAdvance,
  emptyDetailBills,
  getBudgetRequest,
  getExpenseCashAdvance
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import {
  Card,
  Confirmation,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import styles from "./styles";
import {
  convertDate,
  copyWritings,
  maskedMoney,
  queryString,
  toast
} from "../../../../utils";
import ExpenseDetail from "./Components/ExpenseDetail";
import CloseBill from "./Components/CloseBill";
import DeleteBill from "./Components/DeleteBill";
import { IMAGES } from "../../../../configs";
import EditBills from "./EditBills";

interface Props {
  id: string;
  isBillApproval?: boolean;
}

const CashAdvance = ({ id, isBillApproval }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const { ExpenseState, cashState, tripState } = useSelector(
    (state: Reducers) => ({
      ExpenseState: state.newExpenseTrip,
      cashState: state.cash,
      tripState: state.trip
    }),
    shallowEqual
  );
  const history = useHistory();
  const lang = Cookies.get("locale") || "en";

  const { search } = history.location;
  const param = queryString(search);
  const [showEditBill, setShowEditBill] = useState(false);
  const typeDataEditBill: any = {};
  const [dataEditBill, setDataEditBill] = useState(typeDataEditBill);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteBillId, setDeleteBillId] = useState(null || "");
  const [idCA, setIdCA] = useState("");
  const [modalDeleteCA, setModalDeleteCA] = useState(false);

  useEffect(() => {
    dispatch(getBudgetRequest(id, lang));
    dispatch(getExpenseCashAdvance(id));
  }, [dispatch, id]);

  const [showDetail, setShowDetail] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const typeExpenseDetail: any = {};
  const [expenseDetail, setExpenseDetail] = useState(typeExpenseDetail);

  const copTeks = {
    cash_advance: copyWritings("cash_advance")
  };

  const _addCashExpense = (itemId: string, item: any) => {
    history.push("/new-cash-expense", {
      id,
      invoice_id: ExpenseState.dataInvoiceTrip?.id,
      status: tripState.tripData.status,
      itemId,
      item
    });
  };

  const _totalItem = () => {
    if (ExpenseState.listExpenseCashAdvance.length > 0) {
      return ExpenseState.listExpenseCashAdvance.reduce((acc, current) => ({
        total: Number(acc.total) + Number(current.total)
      })).total;
    }
    return 0;
  };

  const _permissionCreate = () => {
    if (
      ["1", "4"].includes(param.status) &&
      moment(tripState.tripData && tripState.tripData.finished_at).format(
        "YYYY-MM-DD HH:mm"
      ) > moment().format("YYYY-MM-DD HH:mm") &&
      !isBillApproval
    ) {
      return true;
    }
    return false;
  };

  // invoice_id
  // currency_id

  return (
    <WrapContent isLoading={ExpenseState.isLoadingListExpenseCashAdvance}>
      <WrapList
        isEmpty={ExpenseState.listExpenseCashAdvance.length === 0}
        emptyComponent={() =>
          _permissionCreate() ? (
            <View style={styles.wrapButtonEmpty}>
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => _addCashExpense("", {})}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {t("activity.detail.addCash")}
                </Text>
              </Card>
            </View>
          ) : (
            <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
          )
        }
      >
        <View style={{ paddingHorizontal: 20 }}>
          <FlatList
            style={{
              marginTop: 11,
              paddingTop: 5
            }}
            data={ExpenseState.listExpenseCashAdvance}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) =>
              _permissionCreate() ? (
                <Card
                  cardStyle={styles.cardStyle}
                  statusColor="#50b1b1"
                  statusTextLight={`${t("cash.new.qty")}: `}
                  statusText={item ? item.quantity : "-"}
                  statusDescLight="IDR "
                  statusDesc={maskedMoney(item.total)}
                  statusDescStyle={{ fontSize: 14 }}
                  statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                  onPress={() => _addCashExpense(item.id, item)}
                  key={index}
                >
                  <View style={styles.wrapContentCard}>
                    <View style={styles.wrapCardText}>
                      <Text style={[styles.textLabel]}>
                        {t("activity.detail.date")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        <Text style={{ fontWeight: "normal" }}>
                          {`${convertDate(item.created_at, "ddd")} ` || "-"}
                        </Text>
                        {convertDate(item.created_at, "DD MMM YYYY") || ""}
                      </Text>
                      <View style={styles.wrapIcon}>
                        {item.file && (
                          <Touchable
                            onPress={e => {
                              e.stopPropagation();
                            }}
                          >
                            <View style={styles.wrapTrashIcon}>
                              <Image
                                source={IMAGES.clipAttchment}
                                style={{ width: 17, height: 20 }}
                              />
                            </View>
                          </Touchable>
                        )}
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            setIdCA(item.id);
                            setModalDeleteCA(true);
                          }}
                        >
                          <View style={styles.wrapTrashIcon}>
                            <Image
                              source={IMAGES.trashBin}
                              style={{ width: 12, height: 15 }}
                            />
                          </View>
                        </Touchable>
                      </View>
                    </View>

                    <View style={[styles.wrapCardText, { marginTop: -7 }]}>
                      <Text style={styles.textLabel}>
                        {t("activity.detail.desc")}
                      </Text>
                      <View style={{ width: "60%" }}>
                        <Text
                          numberOfLines={1}
                          style={[
                            styles.textLabel,
                            styles.textValue,
                            styles.bold,
                            { width: "100%" }
                          ]}
                        >
                          {item.title || "-"}
                        </Text>
                        <Text
                          numberOfLines={1}
                          style={[
                            styles.textLabel,
                            styles.textValue,
                            { width: "100%", fontSize: 10, color: "#999999" }
                          ]}
                        >
                          {item.description || "-"}
                        </Text>
                      </View>
                    </View>
                  </View>
                </Card>
              ) : (
                <View
                  style={[
                    { marginBottom: 16 },
                    index === 0 && { marginTop: 16 }
                  ]}
                  key={index}
                >
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>
                      {t("activity.detail.date")}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item
                        ? convertDate(item.created_at, "ddd DD MMM YYYY") || ""
                        : "-"}
                    </Text>
                  </View>

                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>
                      {t("activity.detail.desc")}
                    </Text>
                    <View style={{ width: "60%" }}>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold,
                          { width: "100%" }
                        ]}
                      >
                        {item.title || "-"}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          { width: "100%", fontSize: 10, color: "#999999" }
                        ]}
                      >
                        {item.description || "-"}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>
                      {t("activity.new.qty")}
                    </Text>
                    <View style={{ width: "60%" }}>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        {item.quantity || "-"}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.wrapAmountText}>
                    <Text style={styles.amountText}>
                      <Text style={{ fontWeight: "normal" }}>IDR </Text>
                      {maskedMoney(item.total)}
                    </Text>
                  </View>
                  <View style={styles.line} />
                </View>
              )
            }
          />
          {_permissionCreate() && (
            <View style={styles.wrapButtonAdd}>
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => _addCashExpense("", {})}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {sprintf(t("cash.new.addNew"), copTeks.cash_advance)}
                </Text>
              </Card>
            </View>
          )}
          <View style={styles.wrapTotal}>
            <Text style={styles.totalLabel}>
              {sprintf(t("activity.total"), copTeks.cash_advance)}
            </Text>
            <Text style={styles.amountText}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(_totalItem())}
            </Text>
          </View>
        </View>
      </WrapList>

      <Modal isVisible={modalDeleteCA} backdropColor="rgba(255,255,255, 0.9)">
        <Confirmation
          headerTitle={sprintf(t("cash.new.deleteCop"), copTeks.cash_advance)}
          // eslint-disable-next-line max-len
          description={sprintf(
            t("cash.new.msg.deleteCA"),
            copTeks.cash_advance
          )}
          actionTitle={t("cash.new.delete")}
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalDeleteCA(false)}
          isLoading={cashState.isLoadingDeleteCashItem}
          onAction={() =>
            dispatch(
              deleteCashItem(idCA, (run, info) => {
                if (run) {
                  setModalDeleteCA(false);
                  toast.success(t("activity.deleteToast"));
                  dispatch(getExpenseCashAdvance(id));
                } else {
                  toast.error(info);
                }
              })
            )
          }
        />
      </Modal>

      {showDetail ? (
        <Modal isVisible onBackdropPress={() => setShowDetail(false)}>
          <ExpenseDetail
            onDelete={uuid => {
              setDeleteBillId(uuid);
              setShowConfirmDelete(true);
              setShowDetail(false);
            }}
            tripId={id}
            onClose={visible => setShowDetail(visible)}
            onConfirm={visible => {
              setShowConfirm(visible);
              setShowDetail(false);
            }}
            value={expenseDetail}
            param={param.type}
            onEditBill={(editBillsId, value, tripData) => {
              setShowEditBill(true);
              setDataEditBill({ editBillsId, value, tripData });
              setShowDetail(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {showConfirm ? (
        <Modal
          isVisible
          onBackdropPress={() => {
            setShowConfirm(false);
            setShowDetail(true);
          }}
        >
          <CloseBill
            setShowConfirm={bool => setShowConfirm(bool)}
            setShowDetail={bool => setShowDetail(bool)}
          />
        </Modal>
      ) : (
        <></>
      )}
      {showEditBill ? (
        <Modal
          isVisible
          onBackdropPress={() => {
            setExpenseDetail(dataEditBill.value);
            setShowDetail(true);
            setShowEditBill(false);
            dispatch(emptyDetailBills());
          }}
        >
          <EditBills
            billsId={dataEditBill.editBillsId}
            value={dataEditBill.value}
            detailExpense={dataEditBill.tripData}
            closeModal={() => {
              setExpenseDetail(dataEditBill.value);
              setShowDetail(true);
              setShowEditBill(false);
              dispatch(emptyDetailBills());
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {showConfirmDelete ? (
        <Modal
          isVisible
          onBackdropPress={() => {
            setShowConfirmDelete(false);
            setShowDetail(true);
          }}
        >
          <DeleteBill
            billId={deleteBillId}
            setShowConfirmDelete={bool => setShowConfirmDelete(bool)}
            setShowDetail={bool => setShowDetail(bool)}
          />
        </Modal>
      ) : (
        <></>
      )}
    </WrapContent>
  );
};

export default CashAdvance;
