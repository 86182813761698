/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Fixed, Header, Touchable } from "../../components";
import styles from "./styles";
import { queryString } from "../../utils";

import { getTripById } from "../../redux/actions";
import { Reducers } from "../../redux/types";

import Booking from "./Booking";
import Trip from "./Trip";
import Expense from "./Expense";
import Booked from "./Booked";
import Report from "./Report";

const TripDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const tripState = useSelector((state: Reducers) => state.trip);

  useEffect(() => {
    dispatch(getTripById(id));
  }, [dispatch, id]);

  const match = useRouteMatch();
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const [heightSubHeader, setHeightSubHeader] = useState(0);
  const { pathname, search, state } = history.location as any;
  const param = queryString(search);
  const listPages = [
    {
      pathname: initialRoute,
      component: <Trip id={id!} search={search} />,
      exact: true
    },
    {
      pathname: `${initialRoute}/expense`,
      component: (
        <Expense
          id={id!}
          search={search}
          top={heightHeader}
          offsetTop={heightSubHeader / 2}
        />
      ),
      exact: false
    },
    {
      pathname: `${initialRoute}/booking`,
      component: <Booking id={id!} search={search} top={heightHeader} />,
      exact: false
    },
    {
      pathname: `${initialRoute}/booked`,
      component: <Booked />,
      exact: true
    },
    {
      pathname: `${initialRoute}/report`,
      component: <Report id={id!} top={heightHeader} search={search} />,
      exact: true
    }
  ];

  const _checkTripType = (type: string) => {
    if (type === "ongoing") {
      history.push(listPages[3].pathname + search);
    } else {
      history.push(listPages[2].pathname + search);
    }
  };

  const _reportNav = (type: string) => {
    return type === "ongoing" || type === "history" ? (
      <View
        style={
          listPages[4].pathname === pathname
            ? [styles.active, { width: "25%" }]
            : [styles.btnNav, { width: "25%" }]
        }
      >
        <Touchable onPress={() => history.push(listPages[4].pathname + search)}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: 15
            }}
          >
            <Text style={styles.textButton}>Report</Text>
          </View>
        </Touchable>
      </View>
    ) : null;
  };

  const _handleGoBack = () => {
    history.push("/expense");
  };

  const _titleHeader = () => {
    let title = "Loading...";
    if (state) {
      title = state.titleHeader;
    } else if (tripState.tripData) {
      title = tripState.tripData.project.title;
    }
    return title;
  };

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (state) {
      status = state.labelStatus;
    } else if (tripState.tripData) {
      status = tripState.tripData.status;
    }

    if (status === 1) {
      desc = "DRAFT";
    } else if (status === 2) {
      desc = "SUBMITTED";
    } else if (status === 3) {
      desc = "APPROVED";
    } else if (status === 4) {
      desc = "REJECTED";
    } else if (status === 5) {
      desc = "ONGOING";
    } else if (status === 6) {
      desc = "FINISHED";
    } else if (status === 7) {
      desc = "CANCELLED";
    }

    return desc;
  };

  return (
    <Switch>
      <Container>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            title={_titleHeader()}
            textHeader={styles.textHeader}
            goBack={_handleGoBack}
            label={_statusDesc()}
          />
          <View
            style={styles.container}
            onLayout={e => setHeightSubHeader(e.nativeEvent.layout.height)}
          >
            <View style={styles.navigation}>
              <View
                style={
                  listPages[0].pathname === pathname
                    ? [
                        styles.active,
                        param.type !== "ongoing" && param.type !== "history"
                          ? { width: `${100 / 3}%` }
                          : { width: "25%" }
                      ]
                    : [
                        styles.btnNav,
                        param.type !== "ongoing" && param.type !== "history"
                          ? { width: `${100 / 3}%` }
                          : { width: "25%" }
                      ]
                }
              >
                <Touchable
                  onPress={() => history.push(listPages[0].pathname + search)}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      paddingVertical: 15
                    }}
                  >
                    <Text style={styles.textButton}>Trip</Text>
                  </View>
                </Touchable>
              </View>

              <View
                style={
                  pathname.includes(listPages[1].pathname)
                    ? [
                        styles.active,
                        param.type !== "ongoing" && param.type !== "history"
                          ? { width: `${100 / 3}%` }
                          : { width: "25%" }
                      ]
                    : [
                        styles.btnNav,
                        param.type !== "ongoing" && param.type !== "history"
                          ? { width: `${100 / 3}%` }
                          : { width: "25%" }
                      ]
                }
              >
                <Touchable
                  onPress={() => history.push(listPages[1].pathname + search)}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      paddingVertical: 15
                    }}
                  >
                    <Text style={styles.textButton}>Expense</Text>
                  </View>
                </Touchable>
              </View>

              <View
                style={
                  pathname.includes(listPages[2].pathname) ||
                  pathname.includes(listPages[3].pathname)
                    ? [
                        styles.active,
                        param.type !== "ongoing" && param.type !== "history"
                          ? { width: `${100 / 3}%` }
                          : { width: "25%" }
                      ]
                    : [
                        styles.btnNav,
                        param.type !== "ongoing" && param.type !== "history"
                          ? { width: `${100 / 3}%` }
                          : { width: "25%" }
                      ]
                }
              >
                <Touchable
                  onPress={() => {
                    _checkTripType(param.type);
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      paddingVertical: 15
                    }}
                  >
                    <Text style={styles.textButton}>Booking</Text>
                  </View>
                </Touchable>
              </View>
              {_reportNav(param.type)}
            </View>
          </View>
        </Fixed>

        <View style={{ zIndex: -1, marginTop: heightHeader }}>
          {listPages.map((item, index) => (
            <Route path={item.pathname} key={index} exact={item.exact}>
              {item.component}
            </Route>
          ))}
        </View>
      </Container>
    </Switch>
  );
};
export default TripDetail;
