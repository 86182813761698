/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-len */
import React, { useState } from "react";
import {
  FlatList,
  Image,
  ImageProps,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import moment from "moment";
import { Reducers } from "../../../redux/types";

import styles from "./styles";
import { IMAGES } from "../../../configs";
import Card from "../../NewTrip/components/Card";
import {
  Confirmation,
  Message,
  Modal,
  Touchable,
  WrapContent
} from "../../../components";
import {
  convertDate,
  copyWritings,
  documentTitle,
  queryString
} from "../../../utils";

import { deleteTrip } from "../../../redux/actions";

interface Item {
  image: ImageProps;
}

const dataAttc = [
  {
    image: IMAGES.avatar
  }
];

interface Props {
  id: string;
  search: string;
}

const Trip = ({ id, search }: Props) => {
  documentTitle("Trip Detail - Trip");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const param = queryString(search);
  const [moderationId, setModerationId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const { sprintf } = require("sprintf-js");

  const [modalDeleteActivity, setModalDeleteActivity] = useState(false);

  const [screenModal, setScreenModal] = useState("");
  const dataTrip = queryString(search);
  const { tripData, isLoadingTripById, isLoadingDeleteTrip } = useSelector(
    (state: Reducers) => state.trip
  );

  const _openModal = (screen: string, moderationIdTrip: string) => {
    setModerationId(moderationIdTrip);
    setModalOpen(true);
    setScreenModal(screen);
  };

  const copText = {
    project: copyWritings("project"),
    trip: copyWritings("trip"),
    booking: copyWritings("booking")
  };

  const _warningExpiredTrip = () => {
    if (
      ["1", "4"].includes(param.status) &&
      moment(tripData && tripData.finished_at).format("YYYY-MM-DD HH:mm") <
        moment().format("YYYY-MM-DD HH:mm")
    ) {
      return true;
    }
    return false;
  };

  const renderAttchment = (item: Item, index: number) => {
    let singleExt = null;
    if (tripData.file) {
      singleExt = JSON.parse(tripData.file).extension;
    }

    return (
      <View style={{ flexDirection: "row" }} key={index}>
        {tripData.files.length > 0 ? (
          tripData.files.map((itemFiles: any, indexFiles: number) => (
            <Card key={indexFiles} cardStyle={styles.cardAttchmen}>
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => window.open(itemFiles.file.url, "Detail Gambar")}
              >
                {["jpg", "png", "jpeg"].includes(itemFiles.file.extension) ? (
                  <Image
                    style={{ height: 90, width: 90 }}
                    source={{ uri: itemFiles.file.url }}
                  />
                ) : (
                  <View style={styles.attachmentFile}>
                    <Text style={[styles.font, styles.bold, { fontSize: 20 }]}>
                      {itemFiles.file.extension}
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
            </Card>
          ))
        ) : (
          <Card cardStyle={styles.cardAttchmen}>
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={() =>
                window.open(JSON.parse(tripData.file).url, "Detail Gambar")
              }
            >
              {["jpg", "png", "jpeg"].includes(singleExt) ? (
                <Image
                  style={{ height: 90, width: 90 }}
                  source={{ uri: JSON.parse(tripData.file).url }}
                />
              ) : (
                <View style={styles.attachmentFile}>
                  <Text style={[styles.font, styles.bold, { fontSize: 20 }]}>
                    {singleExt}
                  </Text>
                </View>
              )}
            </TouchableOpacity>
          </Card>
        )}
      </View>
    );
  };

  const _moderationStatus = (type: string, stage: string, approve: string) => {
    if (
      type === "approval" &&
      Number(stage) < Number(approve) &&
      !dataTrip.refer_up
    ) {
      return (
        <View style={styles.cardButtonContainer}>
          <Card cardStyle={styles.cardButton}>
            <Touchable
              onPress={() => _openModal("rejectProject", dataTrip.moderation)}
            >
              <Image source={IMAGES.cross} style={{ height: 24, width: 24 }} />
            </Touchable>
          </Card>
          <Card cardStyle={styles.cardButton}>
            <Touchable
              onPress={() => _openModal("reviseProject", dataTrip.moderation)}
            >
              <Image source={IMAGES.pencil} style={{ height: 24, width: 24 }} />
            </Touchable>
          </Card>
          <Card cardStyle={styles.cardButton}>
            <Touchable
              onPress={() => _openModal("approveProject", dataTrip.moderation)}
            >
              <Image source={IMAGES.check} style={{ height: 24, width: 24 }} />
            </Touchable>
          </Card>
        </View>
      );
    }
    return <></>;
  };

  return (
    <WrapContent isLoading={isLoadingTripById}>
      {tripData !== null && (
        <View style={styles.tripDetailContainer}>
          {_warningExpiredTrip() && (
            <View style={{ paddingHorizontal: 32, marginBottom: 12 }}>
              <Message message={t("activity.detail.msg.tripMsg")} />
            </View>
          )}
          <View style={styles.tripDetailContent}>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>{copText.project}</Text>
              <View style={styles.detailDescription}>
                <Text style={[styles.titleDescription, { width: "100%" }]}>
                  {tripData.number}
                </Text>
                <Text style={[styles.font, styles.bold, styles.font14]}>
                  {tripData.project ? tripData.project.title : "-"}
                </Text>
                <Text style={[styles.font, styles.light]}>
                  {tripData.category ? tripData.category.title : "-"}
                </Text>
              </View>

              {(dataTrip.status === "1" || dataTrip.status === "4") && (
                <Touchable
                  onPress={e => {
                    e.stopPropagation();
                    setModalDeleteActivity(true);
                  }}
                >
                  <View style={[styles.wrapTrashIcon, { marginLeft: 8 }]}>
                    <Image
                      source={IMAGES.trashBin}
                      style={{ width: 12, height: 15 }}
                    />
                  </View>
                </Touchable>
              )}
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>{copText.trip}</Text>
              <View style={styles.detailDescription}>
                <Text style={styles.font}>
                  {`${tripData.origin_location.toLowerCase()} - ${tripData.destination_location.toLowerCase()}`}
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.font]}>
                    {`${convertDate(tripData.started_at, "ddd")} `}
                  </Text>
                  <Text style={[styles.font, styles.bold]}>
                    {`${convertDate(tripData.started_at, "DD MMM YYYY")} - `}
                  </Text>
                  <Text style={[styles.font]}>
                    {`${convertDate(tripData.finished_at, "ddd")} `}
                  </Text>
                  <Text style={[styles.font, styles.bold]}>
                    {`${convertDate(tripData.finished_at, "DD MMM YYYY")} `}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>
                {t("task.detail.participants")}
              </Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#666666",
                    fontWeight: "bold",
                    fontFamily: "Poppins"
                  }}
                >
                  {tripData.users.map((item: any, index: number) => {
                    return `${item.user.name}${
                      index !== tripData.users.length - 1 ? ", " : ""
                    }`;
                  })}
                </Text>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>
                {sprintf(t("activity.detail.selfBooking"), copText.booking)}
              </Text>
              <View style={styles.detailDescription}>
                <Text
                  style={[
                    styles.font,
                    styles.bold,
                    tripData.djurneeTrip.self_booking
                      ? { color: "#50B1B1" }
                      : { color: "#ff6347" }
                  ]}
                >
                  {tripData.djurneeTrip.self_booking
                    ? t("activity.yes").toUpperCase()
                    : t("activity.no").toUpperCase()}
                </Text>
              </View>
            </View>

            <Card cardStyle={styles.noteStyle}>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.titleDescription, { width: "24%" }]}>
                  {t("activity.detail.subject")}
                </Text>
                <Text numberOfLines={4} style={[styles.font, { width: "75%" }]}>
                  {tripData.title ? tripData.title : " - "}
                </Text>
              </View>
            </Card>

            {tripData.file || tripData.files.length > 0 ? (
              <FlatList
                data={dataAttc}
                renderItem={({ item, index }) => renderAttchment(item, index)}
                keyExtractor={(item, index) => index.toString()}
                horizontal
                showsHorizontalScrollIndicator={false}
              />
            ) : (
              <></>
            )}
            <View style={{ alignItems: "center", marginTop: 20 }} />
            {_moderationStatus(dataTrip.type, dataTrip.stage, dataTrip.approve)}
          </View>
        </View>
      )}

      <Modal
        moderationId={moderationId}
        isVisible={modalOpen}
        screen={screenModal}
        cancelApprove={() => setModalOpen(false)}
      />

      <Modal
        isVisible={modalDeleteActivity}
        backdropColor="rgba(255,255,255, 0.9)"
      >
        <Confirmation
          headerTitle={sprintf(t("activity.deleteActivity"), copText.trip)}
          // eslint-disable-next-line max-len
          description={t("activity.deleteMsg")}
          actionTitle={t("activity.deleteBtn")}
          cancelTitle={t("activity.cancel")}
          onCancel={() => setModalDeleteActivity(false)}
          isLoading={isLoadingDeleteTrip}
          onAction={() => dispatch(deleteTrip(id, () => history.push("/trip")))}
        />
      </Modal>
    </WrapContent>
  );
};

export default Trip;
