/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import {
  Card,
  CardStatusColor,
  LoadingLoadMore,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";
import { getCashList, resetSearchCashValue } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { IMAGES } from "../../../configs";
import {
  copyWritings,
  maskedMoney,
  permissionPage,
  textToLink
} from "../../../utils";

interface Props {
  type: "owned" | "approvable" | "unpaid" | "collectable" | "billable";
}

const List = ({ type }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const cashState = useSelector((state: Reducers) => state.cash);
  const myPermission = permissionPage("invoice-create");
  const myPermissionSelfView = permissionPage("invoice-self-view");
  const myPermissionOthersView = permissionPage("invoice-others-view");
  const myPermissionOthersList = permissionPage("invoice-others-list");
  const myPermissionSelfList = permissionPage("invoice-self-list");

  const copTeks = {
    client: copyWritings("client"),
    employee: copyWritings("employee")
  };

  useEffect(() => {
    if (
      (type === "approvable" ||
        type === "unpaid" ||
        type === "collectable" ||
        type === "billable") &&
      !myPermissionOthersList
    ) {
      history.push("/");
    } else if (type === "owned" && !myPermissionSelfList) {
      history.push("/");
    } else {
      dispatch(getCashList(type, false, cashState.searchValue));
    }
  }, [dispatch, cashState.searchValue]);

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2 || status === 5) {
      color = "#F5AA3F";
    } else if (status === 4 || status === 7 || status === 9) {
      color = "#ed5a28";
    }
    return color;
  };

  const statusDesc = (
    status: number,
    page: string,
    total: any,
    total_paid: any,
    submitted_current?: number,
    stage_total?: number
  ) => {
    let label = "";
    if (status === 1) {
      label = t("cash.status.draft");
    } else if (status === 2 && page === "owned") {
      label = t("cash.status.onVerification");
    } else if (status === 2) {
      label = `${t(
        "cash.status.waitingAnswer"
      )} ${submitted_current}/${stage_total}`;
    } else if (status === 3) {
      label = t("cash.status.approved");
    } else if (status === 4) {
      label = t("cash.status.revise");
    } else if (
      status === 5 &&
      total_paid >= total &&
      submitted_current !== undefined &&
      stage_total !== undefined &&
      submitted_current < stage_total
    ) {
      label = `${t(
        "cash.status.waitingAnswer"
      )} ${submitted_current}/${stage_total}`;
    } else if (status === 5 && type === "approvable") {
      label = `${t(
        "cash.status.approved"
      )} ${submitted_current}/${stage_total}`;
    } else if (status === 5 && Number(total_paid) >= Number(total)) {
      label = t("cash.status.notSettled");
    } else if (status === 5) {
      label = t("cash.status.unpaid");
    } else if (status === 6) {
      label = t("cash.status.settled");
    } else if (status === 7) {
      label = t("cash.status.rejected");
    } else if (status === 8) {
      label = t("cash.status.expired");
    } else if (status === 9) {
      label = t("cash.status.rejectByFinance");
    }
    return label;
  };

  const handleMyPermissionView = () => {
    if (myPermissionSelfView || myPermissionOthersView) {
      return false;
    }
    return true;
  };

  const _datectTypeFile = (ext: any) => {
    if (ext.length === 0) {
      return <></>;
    }
    if (
      ext.every(
        (e: any) =>
          (e.file && JSON.parse(e.file).extension === "jpg") ||
          (e.file && JSON.parse(e.file).extension === "jpeg") ||
          (e.file && JSON.parse(e.file).extension === "png")
      )
    ) {
      return (
        <Image source={IMAGES.picAttach} style={{ height: 32, width: 32 }} />
      );
    }
    if (
      ext.every(
        (e: any) =>
          (e.file && JSON.parse(e.file).extension === "doc") ||
          (e.file && JSON.parse(e.file).extension === "docx") ||
          (e.file && JSON.parse(e.file).extension === "pdf") ||
          (e.file && JSON.parse(e.file).extension === "xlsx")
      )
    ) {
      return (
        <Image source={IMAGES.document} style={{ height: 32, width: 32 }} />
      );
    }
    if (
      ext.every(
        (e: any) =>
          (e.file && JSON.parse(e.file).extension === "doc") ||
          (e.file && JSON.parse(e.file).extension === "docx") ||
          (e.file && JSON.parse(e.file).extension === "pdf") ||
          (e.file && JSON.parse(e.file).extension === "xlsx") ||
          (e.file && JSON.parse(e.file).extension === "jpg") ||
          (e.file && JSON.parse(e.file).extension === "png") ||
          (e.file && JSON.parse(e.file).extension === "jpeg")
      )
    ) {
      return <Image source={IMAGES.mixed} style={{ height: 32, width: 32 }} />;
    }
    return <></>;
  };

  return (
    <WrapContent isLoading={cashState.isLoadingCashList}>
      <WrapList
        isEmpty={cashState.cashList.length === 0}
        emptyComponent={() =>
          type === "owned" ? (
            <View>
              {myPermission ? (
                <Touchable
                  onPress={() => {
                    history.push("/generalexpense/new");
                    dispatch(resetSearchCashValue());
                  }}
                >
                  <Image
                    resizeMode="contain"
                    style={{ width: 62.6, height: 75.6 }}
                    source={IMAGES.newExpense}
                  />
                </Touchable>
              ) : null}
            </View>
          ) : (
            <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
          )
        }
        onEndReached={() =>
          dispatch(getCashList(type, true, cashState.searchValue))
        }
      >
        <FlatList
          data={cashState.cashList}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={
            <LoadingLoadMore active={cashState.loadMoreCashList} />
          }
          renderItem={({ item, index }) => (
            <View style={styles.container}>
              <Card
                // disini
                disabled={handleMyPermissionView()}
                onPress={() => {
                  history.push(`/cash-details/${item.id}/${type}`);
                  dispatch(resetSearchCashValue());
                }}
                statusColor={
                  type === "unpaid" ? "#F5AA3F" : _statusColor(item.status)
                }
                cardStyle={[
                  styles.cardStyle,
                  { marginTop: index === 0 ? 2 : 0 }
                ]}
                statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                statusDesc={
                  type === "unpaid" &&
                  Number(item.total_paid) >= Number(item.total)
                    ? t("cash.status.paid")
                    : type === "unpaid"
                    ? maskedMoney(Number(item.total) - Number(item.total_paid))
                    : statusDesc(
                        item.status,
                        type,
                        item.total,
                        item.total_paid,
                        item.moderation && item.moderation.length > 0
                          ? item.moderation[0].submitted_current
                          : 0,
                        item.moderation && item.moderation.length > 0
                          ? item.moderation[0].stage_total
                          : 0
                      )
                }
                statusText={
                  type === "unpaid" ? t("cash.new.remaining") : "Status"
                }
                statusTextStyle={{ fontWeight: "normal" }}
              >
                <View style={styles.wrapContentCard}>
                  {type === "unpaid" && (
                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.detail.code")}
                      </Text>
                      <Text style={[styles.textLabel, styles.textValue]}>
                        {item.number || ""}
                      </Text>
                    </View>
                  )}
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {type === "owned"
                        ? t("cash.detail.invoice")
                        : type === "unpaid"
                        ? t("cash.detail.name")
                        : t("cash.detail.code")}
                    </Text>
                    <Text
                      style={[
                        styles.textLabel,
                        styles.textValue,
                        type === "unpaid" && styles.bold
                      ]}
                    >
                      {type === "unpaid"
                        ? item.djurneeInvoice.user.name
                        : item.number}
                    </Text>
                    <View>{_datectTypeFile(item.items ? item.items : [])}</View>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {type === "owned"
                        ? t("cash.detail.method")
                        : type === "approvable"
                        ? t("cash.detail.category")
                        : type === "unpaid"
                        ? t("cash.detail.dueDate")
                        : copTeks.client}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {type === "owned" || type === "approvable" ? (
                        `${item.djurneeInvoice.type.title}${
                          item.djurneeInvoice.recurring === true
                            ? " - Recurring"
                            : ""
                        }`
                      ) : type === "unpaid" ? (
                        <Text>
                          <Text style={{ fontWeight: "normal" }}>
                            {`${moment(item.due_at).format("ddd")} `}
                          </Text>
                          {`${moment(item.due_at).format("DD MMM YYYY")} `}
                        </Text>
                      ) : (
                        "-"
                      )}
                    </Text>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {type === "owned"
                        ? t("cash.detail.subject")
                        : type === "approvable"
                        ? t("cash.detail.desc")
                        : type === "unpaid"
                        ? t("cash.detail.category")
                        : copTeks.employee}
                    </Text>
                    <Text
                      style={[
                        styles.textLabel,
                        styles.textValue,
                        type === "unpaid" ||
                        type === "collectable" ||
                        type === "billable"
                          ? styles.bold
                          : styles.textValue
                      ]}
                    >
                      {type === "owned" ? (
                        item.purpose
                      ) : type === "approvable" ? (
                        <span
                          dangerouslySetInnerHTML={textToLink(item.purpose)}
                        />
                      ) : type === "unpaid" ? (
                        `${item.djurneeInvoice.type.title}${
                          item.djurneeInvoice.recurring === true
                            ? " - Recurring"
                            : ""
                        }`
                      ) : (
                        item.creator.name
                      )}
                    </Text>
                  </View>

                  {type === "owned" || type === "approvable" ? (
                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.detail.project")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        {item.djurneeInvoice.project
                          ? item.djurneeInvoice.project.title
                          : "-"}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}

                  {type === "collectable" || type === "billable" ? (
                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.detail.expired")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        <Text style={{ fontWeight: "normal" }}>
                          {`${moment(item.due_at).format("ddd")} `}
                        </Text>
                        {`${moment(item.due_at).format("DD MMM YYYY")} `}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}

                  {type === "owned" || type === "approvable" ? (
                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.detail.amount")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        <Text style={{ fontWeight: "normal" }}>IDR </Text>
                        {maskedMoney(item.total)}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}

                  {type === "collectable" || type === "billable" ? (
                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.detail.category")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        {/* {`${item.djurneeInvoice.type.title}${
                          item.djurneeInvoice.recurring === true
                            ? " - Recurring"
                            : ""
                        }`} */}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("cash.detail.remarks")}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.remarks || "-"}
                    </Text>
                  </View>
                  {/* {item.djurneeInvoice.recurring.length > 0 &&
                    item.djurneeInvoice.recurring === true && (
                      <>
                        <View style={styles.wrapCardText}>
                          <Text style={styles.textLabel}>
                            {t("cash.detail.recurringAt")}
                          </Text>
                          <Text
                            style={[
                              styles.textLabel,
                              styles.textValue,
                              styles.bold
                            ]}
                          >
                            <Text style={{ fontWeight: "normal" }}>
                              {`${moment(
                                item.djurneeInvoice.recurring_at
                              ).format("ddd")} `}
                            </Text>
                            {`${moment(item.djurneeInvoice.recurring_at).format(
                              "DD MMM YYYY"
                            )} `}
                          </Text>
                        </View>
                        <View style={styles.wrapCardText}>
                          <Text style={styles.textLabel}>
                            {t("cash.detail.recurringEnd")}
                          </Text>
                          <Text
                            style={[
                              styles.textLabel,
                              styles.textValue,
                              styles.bold
                            ]}
                          >
                            {item.djurneeInvoice.recurring_ended_at !== null ? (
                              <>
                                <Text style={{ fontWeight: "normal" }}>
                                  {`${moment(
                                    item.djurneeInvoice.recurring_ended_at
                                  ).format("ddd")} `}
                                </Text>
                                {`${moment(
                                  item.djurneeInvoice.recurring_ended_at
                                ).format("DD MMM YYYY")} `}
                              </>
                            ) : (
                              <Text style={{ fontWeight: "normal" }}> - </Text>
                            )}
                          </Text>
                        </View>
                      </>
                    )} */}
                </View>
              </Card>
            </View>
          )}
        />
      </WrapList>
      {cashState.cashList.length > 0 && type === "owned" && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            padding: 20,
            alignSelf: "flex-end"
          }}
        >
          {myPermission ? (
            <Touchable
              onPress={() => {
                history.push("/generalexpense/new");
                dispatch(resetSearchCashValue());
              }}
            >
              <Image
                resizeMode="contain"
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.newExpense}
              />
            </Touchable>
          ) : null}
        </div>
      )}
    </WrapContent>
  );
};

export default List;
