import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, TextInput, View } from "react-native";
import Switch from "react-switch";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import {
  Input,
  Modal,
  Select,
  TextArea,
  Touchable,
  WrapContent
} from "../../../../components";
import { ModalConfirmForm } from "../../../../components/ModalComponents";
import {
  convertDate,
  copyWritings,
  documentTitle,
  maskedMoney,
  queryString,
  toast,
  utcDate
} from "../../../../utils";
import { Reducers } from "../../../../redux/types";
import {
  deleteBookingById,
  getListAirport,
  getListBookingFlight,
  handleFormAcitivy
} from "../../../../redux/actions";
import { COLORS, ICONS, IMAGES } from "../../../../configs";

const selectClass = [
  { label: "Economy", value: "ECONOMY" },
  { label: "Business", value: "BUSINESS" },
  { label: "Any", value: "ANY" }
];

const selectTime = [
  { label: "Bebas", value: "any" },
  { label: "Pagi (05:00-9:59)", value: "05:00-9:59" },
  { label: "Siang (10:00-13:59)", value: "10:00-13:59" },
  { label: "Malam (14:00-04:59)", value: "14:00-04:59" }
];

const initFormLocation = {
  origin_country_code: "",
  origin_location_id: "",
  destination_country_code: "",
  destination_location_id: "",
  started_at: "",
  started_at_t: "",
  finished_at: "",
  finished_at_t: ""
};

const initForm = {
  is_round_trip: false,
  vendor_id: "",
  airline_id: "",
  class_id: selectClass[0].value,
  has_baggage: false,
  remarks: "",
  baggage: "",
  ...initFormLocation
};

interface Props {
  tripId?: string;
}
const Flight = ({ tripId }: Props) => {
  documentTitle("Booking - Flight");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = history.location;

  const [isError, setIsError] = useState(false);
  const [formOpenModal, setFromOpenModal] = useState(false);
  const [textLocationDeparture, setTextLocationDeparture] = useState("");
  const [textLocationReturn, setTextLocationReturn] = useState("");
  const [showLocationReturn, setShowLocationReturn] = useState(false);
  const [valueModalConfirm, setValueModalConfirm]: any = useState();
  const [showLocationDeparture, setShowLocationDeparture] = useState(false);
  const [modalDateTime, setModalDateTime] = useState(false);
  const [first, setFirst] = useState(true);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [form, setForm] = useState(initForm);
  const [formLocation, setFormLocation] = useState(initFormLocation);
  const {
    policiesState,
    sourceState,
    vendorState,
    newBookingFlightState,
    regencyState,
    tripState,
    bookingState,
    homeState
  } = useSelector(
    (state: Reducers) => ({
      policiesState: state.policies,
      sourceState: state.source,
      vendorState: state.vendor,
      newBookingFlightState: state.newBookingFlight,
      regencyState: state.regency,
      tripState: state.trip,
      bookingState: state.booking,
      homeState: state.home
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      first &&
      vendorState.vendors[0] &&
      newBookingFlightState.listAirline[0]
    ) {
      setForm({
        ...form,
        vendor_id: vendorState.vendors[0].id,
        airline_id: newBookingFlightState.listAirline[0].value
      });
      setFirst(false);
    }
  }, [vendorState.vendors, newBookingFlightState.listAirline, first, form]);

  const _setForm = useCallback(
    (
      formName:
        | "is_round_trip"
        | "vendor_id"
        | "airline_id"
        | "class_id"
        | "has_baggage"
        | "remarks"
        | "baggage",
      formValue: any
    ) => {
      setForm({ ...form, [formName]: formValue });
    },
    [form]
  );

  const _setFormLocation = useCallback(
    (
      formName:
        | "origin_country_code"
        | "origin_location_id"
        | "destination_country_code"
        | "destination_location_id"
        | "started_at"
        | "started_at_t"
        | "finished_at"
        | "finished_at_t",
      formValue: any
    ) => {
      if (formName === "origin_country_code") {
        setFormLocation({
          ...formLocation,
          [formName]: formValue,
          origin_location_id: ""
        });
        setTextLocationDeparture("");
      } else if (formName === "destination_country_code") {
        setFormLocation({
          ...formLocation,
          [formName]: formValue,
          destination_location_id: ""
        });
        setTextLocationReturn("");
      } else {
        setFormLocation({ ...formLocation, [formName]: formValue });
      }
    },
    [formLocation]
  );

  const _getBudget = useCallback(() => {
    if (policiesState.isLoadingPolicies) {
      return "loading...";
    }

    let policy = "allowance-flight-perdiem-amount-domestic";
    if (form.origin_country_code !== form.destination_country_code) {
      policy = "allowance-flight-perdiem-amount-international";
    }
    const getIndex = policiesState.policies.findIndex(el => el.id === policy);
    return (
      policiesState.policies[getIndex] &&
      maskedMoney(
        policiesState.policies[getIndex].attributes.value_object.value *
          (form.is_round_trip ? 2 : 1)
      )
    );
  }, [
    policiesState.isLoadingPolicies,
    policiesState.policies,
    form.origin_country_code,
    form.destination_country_code,
    form.is_round_trip
  ]);

  const _getVendor = useCallback(() => {
    let result: any[] = [];
    vendorState.vendors.forEach(e => {
      result = [...result, { value: e.id, label: e.title }];
    });

    return result;
  }, [vendorState.vendors]);

  const _getListCountry = useCallback(() => {
    let result = [{ label: "select", value: "" }];
    regencyState.countries.forEach(e => {
      result = [...result, { label: e.title, value: e.id }];
    });

    return result;
  }, [regencyState.countries]);

  const _handleSaveModal = useCallback(() => {
    if (
      formLocation.origin_country_code !== "" &&
      formLocation.origin_location_id !== "" &&
      formLocation.started_at !== "" &&
      formLocation.destination_country_code !== "" &&
      formLocation.destination_location_id !== "" &&
      (form.is_round_trip ? formLocation.finished_at !== "" : true)
    ) {
      setForm({
        ...form,
        ...formLocation,
        started_at: utcDate(
          `${formLocation.started_at} ${
            formLocation.started_at_t === "any"
              ? "00:00"
              : formLocation.started_at_t.slice(0, 5)
          }`
        ),
        finished_at: utcDate(
          `${formLocation.finished_at} ${
            formLocation.finished_at_t === "any"
              ? "00:00"
              : formLocation.finished_at_t.slice(0, 5)
          }`
        )
      });
      setModalDateTime(false);
      if (isError) {
        setIsError(false);
      }
    } else if (!isError) {
      setIsError(true);
    }
  }, [form, formLocation, isError]);

  const _getTitleAirport = useCallback(
    (type: "departure" | "return") => {
      if (type === "departure") {
        const getIndex = regencyState.departureAirports.findIndex(
          e => e.id === form.origin_location_id
        );
        const data = regencyState.departureAirports[getIndex];
        return getIndex >= 0
          ? `(${data.attributes.airport_code}) ${data.attributes.city_name}`
          : "-";
      }
      const getIndex = regencyState.arrivalAirports.findIndex(
        e => e.id === form.destination_location_id
      );
      const data = regencyState.arrivalAirports[getIndex];
      return getIndex >= 0
        ? `(${data.attributes.airport_code}) ${data.attributes.city_name}`
        : "-";
    },
    [
      regencyState.departureAirports,
      regencyState.arrivalAirports,
      form.origin_location_id,
      form.destination_location_id
    ]
  );

  useEffect(() => {
    if (formOpenModal) {
      if (form.origin_location_id) {
        setTextLocationDeparture(_getTitleAirport("departure"));
      } else {
        setTextLocationDeparture("");
      }

      if (form.destination_location_id) {
        setTextLocationReturn(_getTitleAirport("return"));
      } else {
        setTextLocationReturn("");
      }

      setFromOpenModal(false);
    }
  }, [
    _getTitleAirport,
    form.origin_location_id,
    form.destination_location_id,
    formOpenModal
  ]);

  const _getValueDefault = useCallback(
    (
      field:
        | "origin_country_code"
        | "destination_country_code"
        | "started_at"
        | "finished_at"
    ) => {
      if (
        tripState.activity.data.new_activity &&
        tripState.activity.data.new_activity.trip &&
        tripState.activity.data.new_activity.trip[field]
      ) {
        return tripState.activity.data.new_activity.trip[field];
      }
      return "";
    },
    [tripState.activity.data.new_activity]
  );

  const _openModal = useCallback(() => {
    setFromOpenModal(true);
    setModalDateTime(true);
    setFormLocation({
      origin_country_code:
        form.origin_country_code || _getValueDefault("origin_country_code"),
      origin_location_id: form.origin_location_id,
      destination_country_code:
        form.destination_country_code ||
        _getValueDefault("destination_country_code"),
      destination_location_id: form.destination_location_id,
      started_at: form.started_at
        ? convertDate(form.started_at, "YYYY-MM-DD")
        : convertDate(
            tripState.tripData && tripState.tripData.started_at,
            "YYYY-MM-DD"
          ) ||
          (_getValueDefault("started_at") &&
            convertDate(_getValueDefault("started_at"), "YYYY-MM-DD")),
      started_at_t: form.started_at_t || selectTime[0].value,
      finished_at: form.finished_at
        ? convertDate(form.finished_at, "YYYY-MM-DD")
        : convertDate(
            tripState.tripData && tripState.tripData.finished_at,
            "YYYY-MM-DD"
          ) ||
          (_getValueDefault("finished_at") &&
            convertDate(_getValueDefault("finished_at"), "YYYY-MM-DD")),
      finished_at_t: form.finished_at_t || selectTime[0].value
    });

    if (
      form.origin_country_code !== "" ||
      _getValueDefault("origin_country_code") !== ""
    ) {
      dispatch(
        getListAirport(
          form.origin_country_code || _getValueDefault("origin_country_code"),
          "departure"
        )
      );
    }

    if (
      form.destination_country_code !== "" ||
      _getValueDefault("destination_country_code") !== ""
    ) {
      dispatch(
        getListAirport(
          form.destination_country_code ||
            _getValueDefault("destination_country_code"),
          "arrival"
        )
      );
    }
  }, [form, dispatch, _getValueDefault]);

  const _closeModal = useCallback(() => {
    setModalDateTime(false);
    if (form.destination_location_id) {
      dispatch(getListAirport(form.destination_country_code, "arrival"));
    }
    if (form.origin_location_id) {
      dispatch(getListAirport(form.origin_country_code, "departure"));
    }
    if (isError) {
      setIsError(false);
    }
  }, [
    dispatch,
    form.destination_location_id,
    form.origin_location_id,
    form.destination_country_code,
    form.origin_country_code,
    isError
  ]);

  const _handleOrder = useCallback(() => {
    if (
      form.started_at !== "" &&
      form.started_at_t !== "" &&
      form.origin_country_code !== "" &&
      form.origin_location_id !== "" &&
      form.destination_country_code !== "" &&
      form.destination_location_id !== "" &&
      (form.is_round_trip
        ? form.finished_at !== "" && form.finished_at_t !== ""
        : true)
    ) {
      const getIndex = sourceState.sources.findIndex(
        item => item.attributes.title === "Flight - Vendor"
      );
      const formBooking = {
        ...form,
        source_id: sourceState.sources[getIndex].id,
        baggage: form.has_baggage ? form.baggage : 0,
        client_timezone: "Asia/Jakarta",
        invoices: [],
        trip_id: tripId || null,
        departure: _getTitleAirport("departure").split(" ")[1],
        return: _getTitleAirport("return").split(" ")[1],
        started_at_d: utcDate(form.started_at),
        finished_at_d: utcDate(form.finished_at),
        participants: [
          {
            id: homeState.myProfile.id,
            relationships: {
              user: { data: { id: homeState.myProfile.id, type: "user" } }
            }
          }
        ]
      };

      if (tripState.activity.data.bookingFlights) {
        dispatch(
          handleFormAcitivy(
            "bookingFlights",
            [...tripState.activity.data.bookingFlights, formBooking],
            tripId,
            () => dispatch(getListBookingFlight(tripId || ""))
          )
        );
      } else {
        dispatch(
          handleFormAcitivy("bookingFlights", [formBooking], tripId, () =>
            tripId ? dispatch(getListBookingFlight(tripId || "")) : () => null
          )
        );
      }

      setForm({
        ...initForm,
        vendor_id: vendorState.vendors[0].id,
        airline_id: newBookingFlightState.listAirline[0].value
      });
      setTextLocationDeparture("");
      setTextLocationReturn("");
      if (isError) {
        setIsError(false);
      }
      setModalSubmitForm(false);
      toast.success(t("activity.booking.orderSuccess"));
    } else if (!isError) {
      setIsError(true);
    }
  }, [
    sourceState.sources,
    tripState.activity.data.bookingFlights,
    vendorState.vendors,
    newBookingFlightState.listAirline,
    dispatch,
    form,
    _getTitleAirport,
    isError
  ]);

  const params = queryString(search);

  const _getTitleAirline = useCallback(
    (id: string) => {
      const getIndex = newBookingFlightState.listAirline.findIndex(
        e => e.value === id
      );
      return (
        newBookingFlightState.listAirline[getIndex] &&
        newBookingFlightState.listAirline[getIndex].label
      );
    },
    [newBookingFlightState.listAirline]
  );

  const _getLabelClass = useCallback((id: string) => {
    const getIndex = selectClass.findIndex(e => e.value === id);
    return selectClass[getIndex] && selectClass[getIndex].label;
  }, []);

  const _handleDelete = useCallback(
    (index: number, id?: string) => {
      if (tripId && id) {
        dispatch(deleteBookingById(id));
        toast.error(t("activity.booking.deleted"));
      } else {
        dispatch(
          handleFormAcitivy(
            "bookingFlights",
            tripState.activity.data.bookingFlights.filter(
              (e: any, i: number) => i !== index
            )
          )
        );
        toast.error(t("activity.booking.deleted"));
      }
    },
    [tripState.activity.data.bookingFlights, dispatch]
  );

  const _renderError = useCallback(
    (isErrorParam: boolean) =>
      isErrorParam && (
        <Text
          style={[
            styles.labelInput,
            { marginLeft: 12, marginTop: 4, color: COLORS.orange }
          ]}
        >
          {t("activity.detail.msg.req")}
        </Text>
      ),
    []
  );

  const _handleOpenModalConfirm = () => {
    if (
      form.started_at !== "" &&
      form.started_at_t !== "" &&
      form.origin_country_code !== "" &&
      form.origin_location_id !== "" &&
      form.destination_country_code !== "" &&
      form.destination_location_id !== "" &&
      (form.is_round_trip
        ? form.finished_at !== "" && form.finished_at_t !== ""
        : true)
    ) {
      const listCountry = _getListCountry();
      const listVendor = _getVendor();

      // const listRegencies = regionState && regionState.region;
      const dataModal = [
        {
          title: t("activity.new.departureCountry"),
          value: listCountry.filter(
            (item: any) => item.value === form.origin_country_code
          )[0].label
        },
        {
          title: t("activity.new.departureLocation"),
          value: _getTitleAirport("departure")
        },
        {
          title: t("activity.new.departureDate"),
          value: moment(form.started_at).format("DD MMMM YYYY")
        },
        {
          title: t("activity.new.departureTime"),
          value: selectTime.filter(
            (item: any) => item.value === form.started_at_t
          )[0].label
        },
        {
          title: t("activity.new.originCountry"),
          value: listCountry.filter(
            (item: any) => item.value === form.destination_country_code
          )[0].label
        },
        {
          title: t("activity.new.originLocation"),
          value: _getTitleAirport("return")
        },
        {
          title: t("activity.new.returningDate"),
          value: moment(form.finished_at).format("DD MMMM YYYY")
        },
        {
          title: t("activity.new.returningTime"),
          value: selectTime.filter(
            (item: any) => item.value === form.finished_at_t
          )[0].label
        },
        {
          title: t("activity.new.roundTrip"),
          value: form.is_round_trip ? t("activity.yes") : t("activity.no")
        },
        {
          title: t("activity.booking.vendor"),
          value: listVendor.filter(
            (item: any) => item.value === form.vendor_id
          )[0].label
        },
        {
          title: t("activity.new.airlines"),
          value: _getTitleAirline(form.airline_id)
        },
        {
          title: t("activity.new.class"),
          value: _getLabelClass(form.class_id)
        },
        {
          title: t("activity.new.baggage"),
          value: form.has_baggage ? t("activity.yes") : t("activity.no")
        },
        {
          title: t("activity.new.description"),
          value: form.remarks
        }
      ];
      if (form.has_baggage) {
        dataModal.splice(13, 0, {
          title: t("activity.new.baggageWeight"),
          value: `${form.baggage || "-"} Kg`
        });
      }
      setValueModalConfirm(dataModal);
      setModalSubmitForm(true);
    } else {
      setIsError(true);
    }
  };

  return (
    <WrapContent isLoading={newBookingFlightState.isLoadingAirline}>
      <View style={styles.container}>
        {tripId &&
          bookingState.listBookingFlight.length > 0 &&
          bookingState.listBookingFlight.map((item: any, index: number) => (
            <View
              style={[styles.row, index === 0 && { marginTop: 16 }]}
              key={index}
            >
              <Touchable
                onPress={() =>
                  history.push(
                    `/newtrip/detailbooking/flight/${index}/${tripId}${search}`
                  )
                }
              >
                {item.data.departure ? (
                  <View style={styles.cardCalendar}>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.order")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={[styles.valueList]}>{item.number}</Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: COLORS.green,
                          marginTop: 12
                        }}
                      >
                        {t("activity.booking.departure")}
                      </Text>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.airlines")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>
                          {item.data.departure.name}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.from")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {item.data.departure.origin}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.to")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {item.data.departure.destination}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.departure")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {moment(item.data.departure.departed_at).format(
                            "ddd, "
                          )}
                        </Text>
                        <Text style={[styles.valueList]}>
                          {moment(item.data.departure.departed_at).format(
                            "DD MMM YYYY"
                          )}
                        </Text>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {moment(item.data.departure.eta).format(" HH:mm")}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: COLORS.orange,
                          marginTop: 12
                        }}
                      >
                        {t("activity.booking.returning")}
                      </Text>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.airlines")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>
                          {item.data.returning.name}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.from")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {item.data.returning.origin}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.to")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {item.data.returning.destination}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.returning")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {moment(item.data.returning.departed_at).format(
                            "ddd, "
                          )}
                        </Text>
                        <Text style={[styles.valueList]}>
                          {moment(item.data.returning.departed_at).format(
                            "DD MMM YYYY"
                          )}
                        </Text>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {moment(item.data.returning.eta).format(" HH:mm")}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={styles.cardCalendar}>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.airlines")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>{item.data.name}</Text>
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.class")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>{item.data.class}</Text>
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.departure")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {`${item.data.origin} - ${
                            item.data.destination
                          }, ${convertDate(item.data.departure_date, "ddd")}`}
                          <Text style={styles.valueList}>
                            {` ${convertDate(
                              item.data.departure_date,
                              "DD MMM YYYY"
                            )}`}
                          </Text>
                          {` ${item.data.departure_time_range}`}
                        </Text>
                      </View>
                    </View>

                    {item.is_round_trip && (
                      <View style={[styles.flexDirection, styles.rowList]}>
                        <Text style={styles.labelList}>
                          {t("activity.booking.returning")}
                        </Text>
                        <View style={[styles.flexDirection, { flex: 1 }]}>
                          <Text
                            style={[styles.valueList, { fontWeight: "normal" }]}
                          >
                            {`${item.return} - ${item.departure}, ${convertDate(
                              item.finished_at,
                              "ddd"
                            )}`}
                            <Text style={styles.valueList}>
                              {` ${convertDate(
                                item.finished_at,
                                "DD MMM YYYY"
                              )}`}
                            </Text>
                            {` ${item.finished_at_t}`}
                          </Text>
                        </View>
                      </View>
                    )}
                    {tripId &&
                      (params.status === "1" || params.status === "4") &&
                      new Date(
                        tripState.tripData && tripState.tripData.finished_at
                      ) > new Date() && (
                        <View style={styles.wrapButtonTrash}>
                          <Touchable
                            onPress={e => {
                              e.stopPropagation();
                              _handleDelete(index, tripId ? item.id : null);
                            }}
                          >
                            <View style={styles.buttonTrash}>
                              <Image
                                source={ICONS.trash}
                                style={{ width: 12, height: 12 }}
                              />
                            </View>
                          </Touchable>
                        </View>
                      )}
                  </View>
                )}
              </Touchable>
            </View>
          ))}

        {tripState.activity.data.bookingFlights &&
          tripState.activity.data.bookingFlights.map(
            (item: any, index: number) => (
              <View
                style={[styles.row, index === 0 && { marginTop: 16 }]}
                key={index}
              >
                <Touchable
                  onPress={() =>
                    history.push(
                      `/newtrip/detailbooking/flight/${index}/${tripId}${search}`
                    )
                  }
                >
                  <View style={styles.cardCalendar}>
                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.airlines")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>
                          {_getTitleAirline(item.airline_id)}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.new.class")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text style={styles.valueList}>
                          {_getLabelClass(item.class_id)}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.flexDirection, styles.rowList]}>
                      <Text style={styles.labelList}>
                        {t("activity.booking.departure")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        <Text
                          style={[styles.valueList, { fontWeight: "normal" }]}
                        >
                          {`${item.departure} - ${item.return}, ${convertDate(
                            item.started_at,
                            "ddd"
                          )}`}
                          <Text style={styles.valueList}>
                            {` ${convertDate(item.started_at, "DD MMM YYYY")}`}
                          </Text>
                          {` ${item.started_at_t}`}
                        </Text>
                      </View>
                    </View>

                    {item.is_round_trip && (
                      <View style={[styles.flexDirection, styles.rowList]}>
                        <Text style={styles.labelList}>
                          {t("activity.booking.returning")}
                        </Text>
                        <View style={[styles.flexDirection, { flex: 1 }]}>
                          <Text
                            style={[styles.valueList, { fontWeight: "normal" }]}
                          >
                            {`${item.return} - ${item.departure}, ${convertDate(
                              item.finished_at,
                              "ddd"
                            )}`}
                            <Text style={styles.valueList}>
                              {` ${convertDate(
                                item.finished_at,
                                "DD MMM YYYY"
                              )}`}
                            </Text>
                            {` ${item.finished_at_t}`}
                          </Text>
                        </View>
                      </View>
                    )}

                    <View style={styles.wrapButtonTrash}>
                      <Touchable
                        onPress={e => {
                          e.stopPropagation();
                          _handleDelete(index);
                        }}
                      >
                        <View style={styles.buttonTrash}>
                          <Image
                            source={ICONS.trash}
                            style={{ width: 12, height: 12 }}
                          />
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </Touchable>
              </View>
            )
          )}
        {(((tripId && (params.status === "1" || params.status === "4")) ||
          !tripId) &&
          tripState.tripData &&
          new Date(tripState.tripData.finished_at) > new Date()) ||
        !tripId ? (
          <View>
            <View style={[styles.row, { marginTop: 16 }]}>
              <View style={[styles.flexDirection, { paddingHorizontal: 12 }]}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.departure")}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.returning")}
                  </Text>
                </View>
              </View>
              <Touchable onPress={_openModal}>
                <View style={[styles.cardCalendar, styles.flexDirection]}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.labelDate}>
                      {_getTitleAirport("departure")}
                    </Text>
                    <Text style={styles.labelDate}>
                      {form.started_at
                        ? convertDate(form.started_at, "DD MMMM YYYY")
                        : "-"}
                    </Text>
                    <Text style={styles.labelTime}>
                      {form.started_at_t || "-"}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={[styles.labelDate, styles.labelEndDate]}>
                      {_getTitleAirport("return")}
                    </Text>

                    <>
                      <Text style={[styles.labelDate, styles.labelEndDate]}>
                        {form.finished_at
                          ? convertDate(form.finished_at, "DD MMMM YYYY")
                          : "-"}
                      </Text>
                      <Text style={styles.labelTime}>
                        {form.finished_at_t || "-"}
                      </Text>
                    </>
                  </View>
                </View>
              </Touchable>
              {_renderError(
                isError &&
                  (form.started_at === "" ||
                    form.started_at_t === "" ||
                    form.origin_country_code === "" ||
                    form.origin_location_id === "" ||
                    form.destination_country_code === "" ||
                    form.destination_location_id === "" ||
                    form.finished_at === "" ||
                    form.finished_at_t === "")
              )}
            </View>

            <View
              style={[
                styles.row,
                styles.flexDirection,
                { alignItems: "center" }
              ]}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  paddingLeft: 12
                }}
              >
                <Switch
                  onChange={() =>
                    setForm({
                      ...form,
                      is_round_trip: !form.is_round_trip
                    })
                  }
                  checked={form.is_round_trip}
                  onColor="#50b1b1"
                  onHandleColor="#fffff7"
                  handleDiameter={19}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                  height={19}
                  width={34}
                />
                <Text
                  style={[
                    styles.textSwitch,
                    { opacity: form.is_round_trip ? 1 : 0.5 }
                  ]}
                >
                  {t("activity.new.roundTrip")}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {copyWritings("vendor")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Select
                    listValue={_getVendor()}
                    value={form.vendor_id}
                    onChange={e => _setForm("vendor_id", e.target.value)}
                    style={{ width: "100%" }}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.row, styles.flexDirection]}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.new.airlines")}
                  </Text>
                </View>
                <View
                  style={[
                    styles.card,
                    styles.flexDirection,
                    { justifyContent: "flex-start", alignItems: "center" }
                  ]}
                >
                  <Select
                    listValue={newBookingFlightState.listAirline}
                    value={form.airline_id}
                    onChange={e => _setForm("airline_id", e.target.value)}
                    style={{ width: "100%" }}
                  />
                </View>
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.new.class")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Select
                    listValue={selectClass}
                    value={form.class_id}
                    onChange={e => _setForm("class_id", e.target.value)}
                    style={{ width: "100%" }}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.row, styles.flexDirection]}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.new.baggage")}
                  </Text>
                </View>
                <View style={{ paddingLeft: 12, marginTop: 2 }}>
                  <Switch
                    onChange={() => _setForm("has_baggage", !form.has_baggage)}
                    checked={form.has_baggage}
                    onColor="#50b1b1"
                    onHandleColor="#fffff7"
                    handleDiameter={19}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                    height={19}
                    width={34}
                  />
                  {form.has_baggage && (
                    <Input
                      inputStyle={[
                        styles.textInput,
                        { textAlign: "left", marginBottom: 2 }
                      ]}
                      style={{ paddingHorizontal: 12, marginTop: 0 }}
                      value={form.baggage}
                      onChangeText={e => _setForm("baggage", e)}
                    />
                  )}
                </View>
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.budgetAllocation")}
                  </Text>
                </View>
                <View
                  style={[
                    styles.wrapLabeInput,
                    { flex: 1, justifyContent: "flex-start" }
                  ]}
                >
                  <Text style={styles.labelPolicy}>
                    IDR
                    <Text style={[styles.labelPolicy, { fontWeight: "bold" }]}>
                      {` ${
                        form.origin_country_code &&
                        form.destination_country_code
                          ? _getBudget()
                          : "-"
                      }`}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
            {tripState.tripData.users && tripId && (
              <View style={styles.row}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.passanger")}
                  </Text>
                </View>
                <View style={styles.containerPassenger}>
                  {tripState.tripData.users.map((item: any, index: number) => (
                    <View key={index} style={styles.cardPassenger}>
                      <Image
                        source={{
                          uri:
                            item.user && item.user.avatar
                              ? item.user.avatar.url
                              : IMAGES.avatar
                        }}
                        style={styles.imagePassenger}
                      />
                      <View
                        style={{
                          marginLeft: 8,
                          marginVertical: 4,
                          maxWidth: "85%"
                        }}
                      >
                        <Text
                          numberOfLines={1}
                          style={[styles.labelPolicy, { fontWeight: "bold" }]}
                        >
                          {item.user.name}
                        </Text>
                        <Text style={styles.labelPolicy}>
                          {item.user.position_name}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}

            <View style={styles.row}>
              <View style={styles.wrapLabeInput}>
                <Text style={styles.labelInput}>
                  {t("activity.new.description")}
                </Text>
              </View>
              <TextArea
                bold
                value={form.remarks}
                onChange={e => _setForm("remarks", e.target.value)}
                withPadding
              />
            </View>

            <View style={[styles.row, { alignItems: "center" }]}>
              <View
                style={[
                  styles.wrapButton,
                  sourceState.isLoadingSource && { opacity: 0.5 }
                ]}
              >
                <Touchable
                  disabled={sourceState.isLoadingSource}
                  onPress={_handleOpenModalConfirm}
                >
                  <View style={styles.button}>
                    <Text style={styles.textButton}>
                      {t("activity.booking.order")}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        ) : null}
      </View>

      <Modal isVisible={modalSubmitForm} backdropColor="rgba(255,255,255, 0.9)">
        <ModalConfirmForm
          data={valueModalConfirm}
          onPress={() => _handleOrder()}
          onCancel={() => setModalSubmitForm(false)}
          isLoading={false}
        />
      </Modal>

      <Modal
        isVisible={modalDateTime}
        onBackdropPress={_closeModal}
        backdropColor="rgba(0, 0, 0, 0.5)"
      >
        <View style={[styles.card, styles.containerModal]}>
          <View style={{ zIndex: 2 }}>
            <Text
              style={[
                styles.labelDate,
                { marginBottom: 8, textAlign: "center" }
              ]}
            >
              {t("activity.booking.departure")}
            </Text>
            <View style={{ flex: 1, marginBottom: 12 }}>
              <View style={styles.wrapLabeInput}>
                <Text style={styles.labelInput}>
                  {t("activity.booking.country")}
                </Text>
              </View>
              <View
                style={[
                  styles.card,
                  styles.flexDirection,
                  { justifyContent: "flex-start", alignItems: "center" }
                ]}
              >
                <Select
                  listValue={_getListCountry()}
                  style={{ width: "100%" }}
                  onChange={e => {
                    _setFormLocation("origin_country_code", e.target.value);
                    dispatch(getListAirport(e.target.value, "departure"));
                  }}
                  value={formLocation.origin_country_code}
                />
              </View>
              {_renderError(isError && formLocation.origin_country_code === "")}
            </View>
            <View style={{ flex: 1, zIndex: 1, marginBottom: 12 }}>
              <View style={styles.wrapLabeInput}>
                <Text style={styles.labelInput}>
                  {t("activity.booking.location")}
                </Text>
              </View>
              <View style={[styles.card]}>
                {regencyState.isLoadingAirports ? (
                  <Text style={styles.labelInput}>Loading...</Text>
                ) : (
                  <TextInput
                    style={styles.textInput}
                    value={textLocationDeparture}
                    onChangeText={e => setTextLocationDeparture(e)}
                    onFocus={() => setShowLocationDeparture(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowLocationDeparture(false);
                      }, 100);
                    }}
                  />
                )}
                {showLocationDeparture && (
                  <View style={styles.containerAutoComplete}>
                    <View style={styles.cardAutoComplete}>
                      {regencyState.departureAirports
                        .filter(
                          e =>
                            e.attributes.city_name
                              .toUpperCase()
                              .includes(textLocationDeparture.toUpperCase()) ||
                            e.attributes.name
                              .toUpperCase()
                              .includes(textLocationDeparture.toUpperCase())
                        )
                        .map((item: any, index: number) => (
                          <Touchable
                            key={index}
                            width="100%"
                            onPress={() => {
                              _setFormLocation("origin_location_id", item.id);
                              setTextLocationDeparture(
                                `(${item.attributes.airport_code}) ${item.attributes.city_name}`
                              );
                            }}
                          >
                            <View style={styles.buttonAutoComplete}>
                              <Text style={{ marginHorizontal: 10 }}>
                                {`(${item.attributes.airport_code}) ${item.attributes.city_name}`}
                              </Text>
                            </View>
                          </Touchable>
                        ))}
                    </View>
                  </View>
                )}
              </View>
              {_renderError(isError && formLocation.origin_location_id === "")}
            </View>
            <View style={styles.flexDirection}>
              <View style={{ flex: 1 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.date")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <input
                    style={{
                      backgroundColor: "transparent",
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      color: "#666666",
                      width: "100%"
                    }}
                    type="date"
                    className="input-date-new-trip"
                    onChange={e =>
                      _setFormLocation("started_at", e.target.value)
                    }
                    value={formLocation.started_at}
                    min={moment().format("YYYY-MM-DD")}
                    max={formLocation.finished_at}
                  />
                </View>
                {_renderError(isError && formLocation.started_at === "")}
              </View>

              <View style={{ flex: 1, marginLeft: 14 }}>
                <View style={styles.wrapLabeInput}>
                  <Text style={styles.labelInput}>
                    {t("activity.booking.time")}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Select
                    listValue={selectTime}
                    value={formLocation.started_at_t}
                    onChange={e =>
                      _setFormLocation("started_at_t", e.target.value)
                    }
                  />
                </View>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 36, zIndex: 1 }}>
            <Text
              style={[
                styles.labelDate,
                styles.labelEndDate,
                { marginBottom: 8, textAlign: "center" }
              ]}
            >
              {t("activity.booking.returning")}
            </Text>
            <View style={{ flex: 1, marginBottom: 12 }}>
              <View style={styles.wrapLabeInput}>
                <Text style={styles.labelInput}>
                  {t("activity.booking.country")}
                </Text>
              </View>
              <View
                style={[
                  styles.card,
                  styles.flexDirection,
                  { justifyContent: "flex-start", alignItems: "center" }
                ]}
              >
                <Select
                  listValue={_getListCountry()}
                  style={{ width: "100%" }}
                  value={formLocation.destination_country_code}
                  onChange={e => {
                    _setFormLocation(
                      "destination_country_code",
                      e.target.value
                    );
                    dispatch(getListAirport(e.target.value, "arrival"));
                  }}
                />
              </View>
              {_renderError(
                isError && formLocation.destination_country_code === ""
              )}
            </View>
            <View style={{ flex: 1, zIndex: 1, marginBottom: 12 }}>
              <View style={styles.wrapLabeInput}>
                <Text style={styles.labelInput}>
                  {t("activity.booking.location")}
                </Text>
              </View>
              <View style={[styles.card]}>
                {regencyState.isLoadingAirports ? (
                  <Text style={styles.labelInput}>Loading...</Text>
                ) : (
                  <TextInput
                    style={styles.textInput}
                    onFocus={() => setShowLocationReturn(true)}
                    value={textLocationReturn}
                    onChangeText={e => setTextLocationReturn(e)}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowLocationReturn(false);
                      }, 100);
                    }}
                  />
                )}
                {showLocationReturn && (
                  <View style={styles.containerAutoComplete}>
                    <View style={styles.cardAutoComplete}>
                      {regencyState.arrivalAirports
                        .filter(
                          e =>
                            e.attributes.city_name
                              .toUpperCase()
                              .includes(textLocationReturn.toUpperCase()) ||
                            e.attributes.name
                              .toUpperCase()
                              .includes(textLocationReturn.toUpperCase())
                        )
                        .map((item: any, index: number) => (
                          <Touchable
                            key={index}
                            width="100%"
                            onPress={() => {
                              _setFormLocation(
                                "destination_location_id",
                                item.id
                              );
                              setTextLocationReturn(
                                `(${item.attributes.airport_code}) ${item.attributes.city_name}`
                              );
                            }}
                          >
                            <View style={styles.buttonAutoComplete}>
                              <Text style={{ marginHorizontal: 10 }}>
                                {`(${item.attributes.airport_code}) ${item.attributes.city_name}`}
                              </Text>
                            </View>
                          </Touchable>
                        ))}
                    </View>
                  </View>
                )}
              </View>
              {_renderError(
                isError && formLocation.destination_location_id === ""
              )}
            </View>

            {form.is_round_trip && (
              <View style={styles.flexDirection}>
                <View style={{ flex: 1 }}>
                  <View style={styles.wrapLabeInput}>
                    <Text style={styles.labelInput}>
                      {t("activity.booking.date")}
                    </Text>
                  </View>
                  <View style={styles.card}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666",
                        width: "100%"
                      }}
                      type="date"
                      className="input-date-new-trip"
                      value={formLocation.finished_at}
                      onChange={e =>
                        _setFormLocation("finished_at", e.target.value)
                      }
                      min={
                        formLocation.started_at === ""
                          ? moment().format("YYYY-MM-DD")
                          : formLocation.started_at
                      }
                    />
                  </View>
                  {_renderError(isError && formLocation.finished_at === "")}
                </View>

                <View style={{ flex: 1, marginLeft: 14 }}>
                  <View style={styles.wrapLabeInput}>
                    <Text style={styles.labelInput}>
                      {t("activity.booking.time")}
                    </Text>
                  </View>
                  <View style={styles.card}>
                    <Select
                      listValue={selectTime}
                      value={formLocation.finished_at_t}
                      onChange={e =>
                        _setFormLocation("finished_at_t", e.target.value)
                      }
                    />
                  </View>
                </View>
              </View>
            )}
          </View>

          <View style={{ width: "100%", alignItems: "center", marginTop: 36 }}>
            <View style={styles.wrapButton}>
              <Touchable onPress={_handleSaveModal}>
                <View style={[styles.button, { height: 38 }]}>
                  <Text style={styles.textButton}>
                    {t("activity.booking.save")}
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
        </View>
      </Modal>
    </WrapContent>
  );
};

export default Flight;
