import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";

const getGeneralSetting = (settingName: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { generalSetting } = useSelector((state: Reducers) => state.auth);
  return generalSetting.find((el: any) => el.id === settingName).attributes
    .value;
};

export default getGeneralSetting;
