import React, { CSSProperties, ReactNode, useEffect } from "react";

import { SIZE } from "../../configs";

type Position = "bottom" | "top";

interface CustomPosition {
  top?: number;
  bottom?: number;
}

interface Props {
  children: ReactNode;
  getHeight?: (value: number) => void;
  position?: Position;
  customPosition?: CustomPosition;
}

const styles: CSSProperties = {
  position: "fixed",
  width: "100%",
  maxWidth: SIZE.maxWidth,
  zIndex: 1,
  backgroundColor: "transparent"
};

const stylesTop: CSSProperties = { ...styles, top: 0 };
const stylesBottom: CSSProperties = { ...styles, bottom: 0 };

const Fixed = ({ children, getHeight, position, customPosition }: Props) => {
  const stylesCustom: CSSProperties = { ...styles, ...customPosition };
  let heightElement: number;

  useEffect(() => {
    if (getHeight !== undefined) {
      getHeight(heightElement);
    }
  });

  const _checkStyles = () => {
    let resultStyle: CSSProperties = stylesTop;
    if (position === "bottom") {
      resultStyle = stylesBottom;
    } else if (customPosition?.top! >= 0 || customPosition?.bottom! >= 0) {
      resultStyle = stylesCustom;
    }

    return resultStyle;
  };

  return (
    <div
      style={_checkStyles()}
      ref={refs => {
        heightElement = refs?.clientHeight!;
      }}
    >
      {children}
    </div>
  );
};

Fixed.defaultProps = {
  position: "top"
};

export default Fixed;
