import React, { useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import General from "./General";
import TripExpense from "./TripExpense";
import ApproveExpense from "./ApproveExpense";
import { Fixed, Header, Touchable } from "../../components";
import styles from "./styles";
import { IMAGES } from "../../configs";
import { heightPercent } from "../../utils";
import { Reducers } from "../../redux/types";
import { getMyProfile } from "../../redux/actions";

const Booking = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state: Reducers) => state.home.myProfile);

  useEffect(() => {
    if (Object.keys(userProfile).length === 0) {
      dispatch(getMyProfile());
    }
  }, [dispatch]);
  const match = useRouteMatch();
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const { pathname } = history.location;
  const listPages = [
    {
      pathname: `${match.path}`,
      component: <TripExpense />
    },
    {
      pathname: `${match.path}/misc`,
      component: <General />
    },
    {
      pathname: `${match.path}/approval`,
      component: <ApproveExpense />,
      accessible:
        userProfile.employee && userProfile.employee.position.has_children
    }
  ];

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header title="Expense" goBack={() => history.push("/home")} />
          <View style={styles.container}>
            <View style={styles.navigation}>
              <View
                style={
                  listPages[0].pathname === pathname
                    ? styles.active
                    : styles.btnNav
                }
              >
                <Touchable onPress={() => history.push(listPages[0].pathname)}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{ height: 25, width: 40, marginBottom: 5 }}
                      source={IMAGES.flight}
                    />
                    <Text style={styles.textButton}>Trip</Text>
                  </View>
                </Touchable>
              </View>
              <View
                style={
                  listPages[1].pathname === pathname
                    ? styles.active
                    : styles.btnNav
                }
              >
                <Touchable onPress={() => history.push(listPages[1].pathname)}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Image
                      style={{ height: 25, width: 25, marginBottom: 5 }}
                      source={IMAGES.generalExpnense}
                    />
                    <Text style={styles.textButton}>Misc</Text>
                  </View>
                </Touchable>
              </View>
              <View
                style={
                  listPages[2].pathname === pathname
                    ? styles.active
                    : styles.btnNav
                }
              >
                <Touchable
                  disabled={!listPages[2].accessible}
                  onPress={() => history.push(listPages[2].pathname)}
                >
                  <View
                    style={[
                      {
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      },
                      listPages[2].accessible ? null : { opacity: 0.3 }
                    ]}
                  >
                    <Image
                      style={{ height: 25, width: 25, marginBottom: 5 }}
                      source={IMAGES.approval}
                    />
                    <Text style={styles.textButton}>Approval</Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </Fixed>

        <View
          style={{
            marginTop: heightHeader,
            minHeight: heightPercent(100) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};
export default Booking;
