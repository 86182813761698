import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import {
  BodyPostNewBookingHotel,
  BodyPutEditBookingHotel
} from "../../configs/api";
import { handleLogin } from ".";

export const POST_NEW_BOOKING_HOTEL_PENDING = "POST_NEW_BOOKING_HOTEL_PENDING";
export const POST_NEW_BOOKING_HOTEL_SUCCESS = "POST_NEW_BOOKING_HOTEL_SUCCESS";
export const POST_NEW_BOOKING_HOTEL_ERROR = "POST_NEW_BOOKING_HOTEL_ERROR";

export const PUT_EDIT_BOOKING_HOTEL_PENDING = "PUT_EDIT_BOOKING_HOTEL_PENDING";
export const PUT_EDIT_BOOKING_HOTEL_SUCCESS = "PUT_EDIT_BOOKING_HOTEL_SUCCESS";
export const PUT_EDIT_BOOKING_HOTEL_ERROR = "PUT_EDIT_BOOKING_HOTEL_ERROR";

export const GET_BOOKING_HOTEL_BY_ID_PENDING =
  "GET_BOOKING_HOTEL_BY_ID_PENDING";
export const GET_BOOKING_HOTEL_BY_ID_SUCCESS =
  "GET_BOOKING_HOTEL_BY_ID_SUCCESS";
export const GET_BOOKING_HOTEL_BY_ID_ERROR = "GET_BOOKING_HOTEL_BY_ID_ERROR";

declare const alert: (param: any) => void;

export const postNewBookingHotel = (
  form: BodyPostNewBookingHotel,
  cb: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_BOOKING_HOTEL_PENDING });

    const res = await API.postNewBookingHotel(form, auth.token);
    dispatch({
      type: POST_NEW_BOOKING_HOTEL_SUCCESS,
      payload: { data: res.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewBookingHotel(form, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_BOOKING_HOTEL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_NEW_BOOKING_HOTEL_ERROR });
  }
};

export const putEditBookingHotel = (
  form: BodyPutEditBookingHotel,
  id: any,
  cb: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_EDIT_BOOKING_HOTEL_PENDING });

    const res = await API.putEditBookingHotel(form, id, auth.token);
    dispatch({
      type: PUT_EDIT_BOOKING_HOTEL_SUCCESS,
      payload: { data: res.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(putEditBookingHotel(form, id, cb)))
        );
      } else {
        alert(err.response.data.message);
        dispatch({
          type: PUT_EDIT_BOOKING_HOTEL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: PUT_EDIT_BOOKING_HOTEL_ERROR });
  }
};

export const getBookingHotelById = (id: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BOOKING_HOTEL_BY_ID_PENDING });

    const res = await API.getBookingHotelById(id, auth.token);
    dispatch({
      type: GET_BOOKING_HOTEL_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBookingHotelById(id))));
      } else {
        dispatch({
          type: GET_BOOKING_HOTEL_BY_ID_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_BOOKING_HOTEL_BY_ID_ERROR });
  }
};
