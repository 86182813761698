import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_BUBBLE_PENDING = "GET_BUBBLE_PENDING";
export const GET_BUBBLE_SUCCESS = "GET_BUBBLE_SUCCESS";
export const GET_BUBBLE_ERROR = "GET_BUBBLE_ERROR";

export const getBubbleNotif = (type: "cash" | "activity" | "task") => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BUBBLE_PENDING });

    const res = await API.getBubbleNotif(type, auth.token);
    dispatch({
      type: GET_BUBBLE_SUCCESS,
      payload: { data: res.data.data[0] }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBubbleNotif(type))));
      } else {
        dispatch({
          type: GET_BUBBLE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BUBBLE_ERROR });
  }
};
