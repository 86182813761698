import { StyleSheet } from "react-native";
import { COLORS } from "../../../configs";
import { heightPercent, widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  formContainer: {
    marginTop: 13,
    paddingLeft: 41,
    paddingRight: 42
  },
  labelInput: {
    fontSize: 8,
    fontFamily: "Poppins",
    color: "rgba(102, 102, 102, 0.5)"
  },
  inputAmount: {
    width: "100%",
    paddingHorizontal: 20,
    textAlign: "right"
  },
  inputStyle: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 2,
    marginLeft: -2
  },
  validateText: {
    color: "red",
    fontFamily: "Poppins",
    fontSize: 8
  },
  columnInput: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center"
  },

  currencyContainer: {
    marginTop: 20,
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center"
  },

  buttonUpload: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  inputContainer: {
    marginBottom: 20
  },
  inputRecurring: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  },
  containerInputTime: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "center"
  },

  containerRecurring: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 52
  },
  containerDropdown: {
    backgroundColor: COLORS.main,
    height: 45,
    width: "100%"
  },
  wrapSwitch: {
    flexDirection: "row",
    marginTop: 9
  },
  textSelfBooking: {
    fontWeight: "bold",
    marginRight: 12,
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#666666",
    marginLeft: 5
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginVertical: 2,
    height: 35,
    width: "100%",
    borderRadius: 100
  },
  containerDelete: {
    height: 25,
    width: 25,
    justifyContent: "center",
    alignItems: "center"
  },
  cardItems: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginVertical: 2,
    width: "100%",
    borderRadius: 10
  },
  dropDown: {
    borderWidth: 0,
    height: 40,
    borderBottomWidth: 1,
    backgroundColor: COLORS.main,
    fontSize: 12,
    fontWeight: "bold",
    color: "#666666",
    fontFamily: "Poppins"
  },
  containerContent: {
    borderWidth: 0,
    height: 40,
    borderBottomWidth: 1,
    backgroundColor: COLORS.main,
    fontSize: 12,
    fontWeight: "bold",
    color: "#666666",
    fontFamily: "Poppins"
  },
  expenseTypeModal: {
    borderWidth: 0,
    height: 40,
    borderBottomColor: "#999999",
    borderBottomWidth: 1,
    backgroundColor: COLORS.main,
    fontSize: 12,
    fontWeight: "bold",
    color: "#666666",
    fontFamily: "Poppins"
  },
  recurringContainer: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  },

  button: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -4,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  buttonAddItem: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 0%, #262261 121%)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  fileAttachment: {
    width: "48%",
    height: 28,
    borderRadius: 100,
    marginBottom: 12,
    alignItems: "center",
    justifyContent: "center"
  },
  attributeTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#999999"
  },
  wrapBtn: {
    paddingHorizontal: 24,
    backgroundColor: COLORS.main
  },
  wrapBtnRow: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 16
  },
  btnAddItem: {
    borderRadius: 100,
    height: 45,
    width: widthPercent(50) - 28,
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 0%, #262261 121%)"
  },
  cardTax: {
    width: widthPercent(100) - 40,
    height: heightPercent(34),
    borderRadius: 8,
    marginHorizontal: 24,
    paddingVertical: 20,
    paddingHorizontal: 20,
    alignItems: "center"
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666",
    textAlign: "center"
  },
  text12: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666",
    fontSize: 12
  },
  isTaxText: {
    color: COLORS.green,
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  wrapSelectProject: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 6
  }
});

export default styles;
