/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";
import {
  ActivityIndicator,
  Image,
  ImageSourcePropType,
  Text,
  View
} from "react-native";

import i18n from "i18next";
import { IMAGES } from "../../../configs";
import styles from "./styles";
import { Touchable } from "../../index";
import { copyWritings } from "../../../utils";

interface Props {
  buttonPress?: () => void;
  subButtonPress?: () => void;
  closeBtn?: () => void;
  booking?: boolean;
  notificationTitle?: string;
  desc?: string;
  textBtnAction?: string;
  isLoading?: boolean;
  icon?: ImageSourcePropType;
}

const CustomNotification = ({
  buttonPress,
  subButtonPress,
  booking,
  notificationTitle,
  desc,
  textBtnAction,
  isLoading,
  icon,
  closeBtn
}: Props) => {
  const [width, setWidth] = useState(0);
  const { sprintf } = require("sprintf-js");
  const copText = {
    booking: copyWritings("booking")
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {notificationTitle || "NOTIFICATION SOMETHING"}
      </Text>
      <Image
        resizeMode="contain"
        source={icon || IMAGES.rejectModal}
        style={styles.image}
      />
      <Text style={styles.content}>{desc || "-"}</Text>

      {!isLoading ? (
        <>
          <View style={styles.buttonAttachmen}>
            <Touchable onPress={buttonPress}>
              <View
                style={{
                  height: 54,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Text
                  style={styles.buttonText}
                  onLayout={e => setWidth(e.nativeEvent.layout.width)}
                >
                  {textBtnAction || "ACTION"}
                </Text>
              </View>
            </Touchable>
          </View>
          <View style={{ marginTop: 12 }}>
            <Touchable onPress={subButtonPress}>
              <Text style={styles.cancel}>
                {booking
                  ? sprintf(i18n.t("activity.continueBooking"), copText.booking)
                  : "CANCEL"}
              </Text>
            </Touchable>
          </View>
          {closeBtn && (
            <View style={styles.close}>
              <Touchable onPress={closeBtn}>
                <Text style={styles.closeText}>{i18n.t("activity.close")}</Text>
              </Touchable>
            </View>
          )}
        </>
      ) : (
        <ActivityIndicator size="large" style={{ width, marginTop: 64 }} />
      )}
    </View>
  );
};

CustomNotification.defaultProps = {
  isLoading: false
};

export default CustomNotification;
