/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IMAGES } from "../../../configs";
import { putResolveTrip } from "../../../redux/actions";
import styles from "./styles";
import { Touchable } from "../../../components";
import { Reducers } from "../../../redux/types";
import { copyWritings } from "../../../utils";

interface Props {
  cancel?: () => void;
  idTrip?: string;
}

const ModalRevise = ({ cancel, idTrip }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sprintf } = require("sprintf-js");
  const { isLoadingPutResolveTrip } = useSelector(
    (state: Reducers) => state.trip
  );
  const handlePutResolveTrip = async () => {
    await dispatch(putResolveTrip(idTrip!));
    history.push("/trip");
  };

  const copTeks = {
    project: copyWritings("project"),
    trip: copyWritings("trip")
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {sprintf(
          t("activity.detail.msg.resolveConfirm"),
          copTeks.project.toUpperCase()
        )}
      </Text>
      <Image
        resizeMode="contain"
        source={IMAGES.rejectModal}
        style={styles.image}
      />
      <Image
        resizeMode="contain"
        source={IMAGES.resolvePerson}
        style={{ height: 205.8, width: 160, marginRight: 50, marginTop: 10 }}
      />
      <View>
        <Text style={styles.content}>
          {t("activity.detail.msg.resolve-1")}
          <Text style={{ fontWeight: "bold" }}>
            {t("activity.detail.msg.resolve-2")}
          </Text>
          {sprintf(t("activity.detail.msg.resolve-3"), copTeks.trip)}
          <Text style={{ fontWeight: "bold" }}>
            {t("activity.detail.msg.resolve-4")}
          </Text>
        </Text>
      </View>
      <View style={styles.buttonAttachmen}>
        <Touchable onPress={() => handlePutResolveTrip()}>
          <View
            style={{
              width: 180,
              height: 54,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {!isLoadingPutResolveTrip ? (
              <Text style={styles.buttonText}>{t("activity.resolve")}</Text>
            ) : (
              <ActivityIndicator size="small" color="#606060" />
            )}
          </View>
        </Touchable>
      </View>
      <View style={{ marginTop: 17 }}>
        <Touchable onPress={cancel || (() => history.goBack())}>
          <Text style={styles.cancel}>{t("activity.cancel")}</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default ModalRevise;
