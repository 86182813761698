import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapButton: {
    marginVertical: 45,
    alignItems: "center"
  }
});

export default styles;
