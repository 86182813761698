import { Slide, toast } from "react-toastify";
import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { BodyPutEditExpense } from "../../configs/api";
import { handleLogin } from "./auth";

export const PUT_EXPENSE_GENERAL_PENDING = "PUT_EXPENSE_GENERAL_PENDING";
export const PUT_EXPENSE_GENERAL_SUCCESS = "PUT_EXPENSE_GENERAL_SUCCESS";
export const PUT_EXPENSE_GENERAL_ERROR = "PUT_EXPENSE_GENERAL_ERROR";

export const POST_NEW_GENERAL_EXPENSE_PENDING =
  "POST_NEW_GENERAL_EXPENSE_PENDING";
export const POST_NEW_GENERAL_EXPENSE_SUCCESS =
  "POST_NEW_GENERAL_EXPENSE_SUCCESS";
export const POST_NEW_GENERAL_EXPENSE_ERROR = "POST_NEW_GENERAL_EXPENSE_ERROR";

export const GET_EXPENSE_GENERAL_PENDING = "GET_EXPENSE_GENERAL_PENDING";
export const GET_EXPENSE_GENERAL_SUCCESS = "GET_EXPENSE_GENERAL_SUCCESS";
export const GET_EXPENSE_GENERAL_ERROR = "GET_EXPENSE_GENERAL_ERROR";

export const GET_BUDGET_TYPE_PENDING = "GET_BUDGET_TYPE_PENDING";
export const GET_BUDGET_TYPE_SUCCESS = "GET_BUDGET_TYPE_SUCCESS";
export const GET_BUDGET_TYPE_ERROR = "GET_BUDGET_TYPE_ERROR";

export const GET_EXPENSE_TRIP_PENDING = "GET_EXPENSE_TRIP_PENDING";
export const GET_EXPENSE_TRIP_SUCCESS = "GET_EXPENSE_TRIP_SUCCESS";
export const GET_EXPENSE_TRIP_ERROR = "GET_EXPENSE_TRIP_ERROR";

export const GET_EXPENSE_DETAIL_PENDING = "GET_EXPENSE_DETAIL_PENDING";
export const GET_EXPENSE_DETAIL_SUCCESS = "GET_EXPENSE_DETAIL_SUCCESS";
export const GET_EXPENSE_DETAIL_ERROR = "GET_EXPENSE_DETAIL_ERROR";

export const GET_BUDGET_REMAINING_PENDING = "GET_BUDGET_REMAINING_PENDING";
export const GET_BUDGET_REMAINING_SUCCESS = "GET_BUDGET_REMAINING_SUCCESS";
export const GET_BUDGET_REMAINING_ERROR = "GET_BUDGET_REMAINING_ERROR";

export const getExpenseGeneral = (loadMore = false) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, expense } = getState();
    dispatch({ type: GET_EXPENSE_GENERAL_PENDING, payload: { loadMore } });

    const res = await API.getExpenseGeneral(
      !loadMore ? { ...expense.paramsGeneral, page: 1 } : expense.paramsGeneral,
      auth.token
    );
    dispatch({
      type: GET_EXPENSE_GENERAL_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getExpenseGeneral(loadMore))));
      } else {
        dispatch({
          type: GET_EXPENSE_GENERAL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_EXPENSE_GENERAL_ERROR });
  }
};

export const getExpenseTrip = (loadMore = false) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, expense } = getState();
    dispatch({ type: GET_EXPENSE_TRIP_PENDING, payload: { loadMore } });

    const res = await API.getExpenseTrip(
      !loadMore ? { ...expense.paramsTrip, page: 1 } : expense.paramsTrip,
      auth.token
    );
    dispatch({
      type: GET_EXPENSE_TRIP_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getExpenseTrip(loadMore))));
      } else {
        dispatch({
          type: GET_EXPENSE_TRIP_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_EXPENSE_TRIP_ERROR });
  }
};

export const getExpenseDetail = (expenseId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_EXPENSE_DETAIL_PENDING });

    const res = await API.getExpenseDetail(expenseId, auth.token);
    dispatch({
      type: GET_EXPENSE_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getExpenseDetail(expenseId))));
      } else {
        dispatch({
          type: GET_EXPENSE_DETAIL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_EXPENSE_DETAIL_ERROR });
  }
};

export const getBudgetType = (id: string, cb?: (e: any) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BUDGET_TYPE_PENDING });

    const res = await API.getBudgetType(id, auth.token);
    dispatch({
      type: GET_BUDGET_TYPE_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb(res.data.data.data);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBudgetType(id))));
      } else {
        dispatch({
          type: GET_BUDGET_TYPE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BUDGET_TYPE_ERROR });
  }
};

export const putEditExpense = (
  expenseId: any,
  form: BodyPutEditExpense
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_EXPENSE_GENERAL_PENDING });

    const res = await API.putEditExpense(expenseId, form, auth.token);
    dispatch({
      type: PUT_EXPENSE_GENERAL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putEditExpense(expenseId, form))));
      } else {
        dispatch({
          type: PUT_EXPENSE_GENERAL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_EXPENSE_GENERAL_ERROR });
  }
};

export const postNewGeneralExpense = (
  body: any,
  cb: () => void,
  id?: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_GENERAL_EXPENSE_PENDING });
    const realBody = body;

    const formData = new FormData();

    Object.keys(realBody).map(attribute => {
      if (realBody[attribute] !== null) {
        formData.append(attribute, realBody[attribute]);
      } else {
        formData.append(attribute, "");
      }
    });
    id && formData.append("_method", "PUT");

    const res = await API.postNewGeneralExpense(formData, auth.token, id);
    dispatch({
      type: POST_NEW_GENERAL_EXPENSE_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
    toast.success(i18n.t("task.assign.dataStored"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewGeneralExpense(body, cb))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: POST_NEW_GENERAL_EXPENSE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_NEW_GENERAL_EXPENSE_ERROR });
  }
};

export const getBudgetRemaining = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BUDGET_REMAINING_PENDING });

    const res = await API.getBudgetRemaining(id, auth.token);
    dispatch({
      type: GET_BUDGET_REMAINING_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBudgetRemaining(id))));
      } else {
        dispatch({
          type: GET_BUDGET_REMAINING_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BUDGET_REMAINING_ERROR });
  }
};
