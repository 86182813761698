import React from "react";
import { Image, Text, View } from "react-native";

import { IMAGES } from "../../configs";
import styles from "./styles";

interface Props {
  message: string;
  inputMsg?: boolean;
}

const Message = ({ message, inputMsg }: Props) => (
  <View
    style={inputMsg ? styles.containerInputMessage : styles.containerMessage}
  >
    {!inputMsg && (
      <Image source={IMAGES.rejectModal} style={styles.iconStyle} />
    )}
    <Text
      style={[styles.messageText, { textAlign: inputMsg ? "left" : "center" }]}
    >
      {message}
    </Text>
  </View>
);

export default Message;
