import React from "react";
import { StyleSheet, Text, View } from "react-native";

interface Props {
  title: string;
  desc: string;
  phoneNumber?: string;
}

const styles = StyleSheet.create({
  wrapDesc: {
    paddingHorizontal: 82,
    alignItems: "center",
    marginVertical: 25
  },
  title: {
    color: "#3f7790",
    fontSize: 20,
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "center"
  },
  desc: {
    textAlign: "center",
    color: "#8f8f8f",
    fontFamily: "Poppins",
    fontSize: 12,
    marginTop: 15
  },
  phoneNumber: {
    fontWeight: "bold",
    marginTop: 0
  }
});

const DescText = ({ title, desc, phoneNumber }: Props) => (
  <View style={styles.wrapDesc}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.desc}>{desc}</Text>
    {phoneNumber !== "" && (
      <Text style={[styles.desc, styles.phoneNumber]}>
        {`+62 ${phoneNumber}`}
      </Text>
    )}
  </View>
);

DescText.defaultProps = {
  phoneNumber: ""
};

export default DescText;
