import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
// import { BodyPostNewTrip } from "../../configs/api";
import { handleLogin } from ".";
import { toast } from "../../utils";

export const GET_POPULATE_CATEGORY_PENDING = "GET_POPULATE_CATEGORY_PENDING";
export const GET_POPULATE_CATEGORY_SUCCESS = "GET_POPULATE_CATEGORY_SUCCESS";
export const GET_POPULATE_CATEGORY_ERROR = "GET_POPULATE_CATEGORY_ERROR";

export const GET_POPULATE_FINANCE_CATEGORY_PENDING =
  "GET_POPULATE_FINANCE_CATEGORY_PENDING";
export const GET_POPULATE_FINANCE_CATEGORY_SUCCESS =
  "GET_POPULATE_FINANCE_CATEGORY_SUCCESS";
export const GET_POPULATE_FINANCE_CATEGORY_ERROR =
  "GET_POPULATE_FINANCE_CATEGORY_ERROR";

export const GET_POPULATE_PROJECT_PENDING = "GET_POPULATE_PROJECT_PENDING";
export const GET_POPULATE_PROJECT_SUCCESS = "GET_POPULATE_PROJECT_SUCCESS";
export const GET_POPULATE_PROJECT_ERROR = "GET_POPULATE_PROJECT_ERROR";

export const GET_POPULATE_CHILD_PROJECT_PENDING =
  "GET_POPULATE_CHILD_PROJECT_PENDING";
export const GET_POPULATE_CHILD_PROJECT_SUCCESS =
  "GET_POPULATE_CHILD_PROJECT_SUCCESS";
export const GET_POPULATE_CHILD_PROJECT_ERROR =
  "GET_POPULATE_CHILD_PROJECT_ERROR";
export const GET_POPULATE_CHILD_PROJECT_REMOVE =
  "GET_POPULATE_CHILD_PROJECT_REMOVE";

export const RESET_POPULATE_CHILD_PROJECT = "RESET_POPULATE_CHILD_PROJECT";

export const POST_NEW_TRIP_PENDING = "POST_NEW_TRIP_PENDING";
export const POST_NEW_TRIP_SUCCESS = "POST_NEW_TRIP_SUCCESS";
export const POST_NEW_TRIP_ERROR = "POST_NEW_TRIP_ERROR";

export const PUT_NEW_TRIP_PENDING = "PUT_NEW_TRIP_PENDING";
export const PUT_NEW_TRIP_SUCCESS = "PUT_NEW_TRIP_SUCCESS";
export const PUT_NEW_TRIP_ERROR = "PUT_NEW_TRIP_ERROR";

export const PUT_EDIT_TRIP_PENDING = "PUT_EDIT_TRIP_PENDING";
export const PUT_EDIT_TRIP_SUCCESS = "PUT_EDIT_TRIP_SUCCESS";
export const PUT_EDIT_TRIP_ERROR = "PUT_EDIT_TRIP_ERROR";

export const GET_DEPARTEMENT_V1_PENDING = "GET_DEPARTEMENT_V1_PENDING";
export const GET_DEPARTEMENT_V1_SUCCESS = "GET_DEPARTEMENT_V1_SUCCESS";
export const GET_DEPARTEMENT_V1_ERROR = "GET_DEPARTEMENT_V1_ERROR";

declare const alert: (param: any) => void;

export const getDepartmentV1 = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DEPARTEMENT_V1_PENDING });

    const res = await API.getDepartmentV1(auth.token);

    dispatch({
      type: GET_DEPARTEMENT_V1_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDepartmentV1())));
      } else {
        dispatch({
          type: GET_DEPARTEMENT_V1_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_DEPARTEMENT_V1_ERROR });
  }
};

export const getPopulateCategory = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POPULATE_CATEGORY_PENDING });

    const res = await API.getPopulateCategory(auth.token);
    dispatch({
      type: GET_POPULATE_CATEGORY_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPopulateCategory())));
      } else {
        dispatch({
          type: GET_POPULATE_CATEGORY_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_POPULATE_CATEGORY_ERROR });
  }
};

export const getFinanceCategory = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POPULATE_CATEGORY_PENDING });

    const res = await API.getFinanceCategory(auth.token);

    dispatch({
      type: GET_POPULATE_CATEGORY_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getFinanceCategory())));
      } else {
        dispatch({
          type: GET_POPULATE_CATEGORY_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_POPULATE_CATEGORY_ERROR });
  }
};

export const getPopulateChildProject = (params: any, index: number) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POPULATE_CHILD_PROJECT_PENDING });
    const res = await API.getPopulateProject(auth.token, params);
    dispatch({
      type: GET_POPULATE_CHILD_PROJECT_SUCCESS,
      payload: { data: res.data.data, index }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getPopulateChildProject(params, index)))
        );
      } else {
        dispatch({
          type: GET_POPULATE_CHILD_PROJECT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_POPULATE_CHILD_PROJECT_ERROR });
  }
};

export const setPopulateChildProject = (data: any, index: number) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: GET_POPULATE_CHILD_PROJECT_SUCCESS,
    payload: { data, index }
  });
};

export const resetPopulateChildProject = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RESET_POPULATE_CHILD_PROJECT
  });
};

export const getPopulateProject = (params: any = {}) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POPULATE_PROJECT_PENDING });
    const res = await API.getPopulateProject(auth.token, params);
    dispatch({
      type: GET_POPULATE_PROJECT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPopulateProject())));
      } else {
        dispatch({
          type: GET_POPULATE_PROJECT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_POPULATE_PROJECT_ERROR });
  }
};

export const removePopulateChildProject = (index: number) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: GET_POPULATE_CHILD_PROJECT_REMOVE, payload: { index } });
};

export const postNewTrip = (form: any, history: any, save: boolean) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_TRIP_PENDING });
    const formdata = new FormData();
    Object.keys(form).forEach((key: any) => {
      formdata.append(key, form[key]);
    });

    const res = await API.postNewTrip(formdata, auth.token);
    dispatch({
      type: POST_NEW_TRIP_SUCCESS,
      payload: { data: res.data }
    });
    if (save) {
      history.push("/trip");
    } else {
      history.push(`/newexpense/trip/${res.data.data.id}`);
    }
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewTrip(form, history, save))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_TRIP_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_NEW_TRIP_ERROR });
  }
};

export const putNewTrip = (params: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_NEW_TRIP_PENDING });

    const res = await API.putNewTrip(params, auth.token);
    dispatch({
      type: PUT_NEW_TRIP_SUCCESS,
      payload: { data: res.data }
    });
    toast.success(i18n.t("activity.invoice.activitySubmit"));
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putNewTrip(params, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: PUT_NEW_TRIP_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: PUT_NEW_TRIP_ERROR });
  }
};

export const putEditTrip = (form: any, id: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_EDIT_TRIP_PENDING });
    const formdata = new FormData();
    Object.keys(form).forEach((key: any) => {
      formdata.append(key, form[key]);
    });
    formdata.append("_method", "PUT");

    const res = await API.putEditTrip(formdata, auth.token, id);
    dispatch({
      type: PUT_EDIT_TRIP_SUCCESS,
      payload: { data: res.data }
    });
    toast.success(i18n.t("task.assign.editStored"));
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putEditTrip(form, id, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: PUT_EDIT_TRIP_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: PUT_EDIT_TRIP_ERROR });
  }
};
