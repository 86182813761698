import React, { ChangeEvent } from "react";
import "./styles.css";
import i18n from "i18next";

interface Props {
  document?: any;
  value?: any;
  image?: any;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  extionsion?: any;
  multiple: boolean;
  style?: object;
}

const upload = ({ onChange, image, multiple, style }: Props) => {
  return (
    <div>
      <div>
        {/* eslint-disable-next-line */}
        <label className="custom-file-upload" style={style}>
          <input
            accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
           application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            type="file"
            multiple={multiple}
            onChange={onChange}
            onClick={(event: any) => {
              // eslint-disable-next-line no-param-reassign
              event.target.value = null;
            }}
          />
          <div className="titleAttc">{i18n.t("task.detail.attachment")}</div>
        </label>
      </div>
      <img src={image} alt="" style={{ width: "40%" }} />
    </div>
  );
};

upload.defaultProps = {
  multiple: false
};

export default upload;
