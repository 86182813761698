import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  CardStatusColor,
  Card as Cards,
  LoadingLoadMore,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";
import { IMAGES } from "../../../configs";
import { convertDate, documentTitle } from "../../../utils";
import { Card } from "../components";
import styles from "./styles";

// redux action & types
import {
  getTripHistory,
  putFilterHistoryTrip,
  resetSearchTripValue
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const History = () => {
  documentTitle("Trip - History");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const tripState = useSelector((state: Reducers) => state.trip);

  useEffect(() => {
    dispatch(getTripHistory(false, tripState.searchValue));
  }, [dispatch, tripState.searchValue]);

  const [modalIsOpen, setModalOpen] = useState(false);

  const [categories, setCategory] = useState([
    { category: "1 week", active: false, value: "this_week" },
    { category: "1 month", active: false, value: "this_month" },
    { category: "3 months", active: false, value: "three_month" },
    { category: "6 months", active: false, value: "six_month" },
    { category: "1 year", active: false, value: "one_year" }
  ]);

  const [inputcategory, setInputcategory] = useState([]);

  // const copTeks = {
  //   project: copyWritings("project")
  // };

  const handlecategoryInput = async (index: number, category: string) => {
    const categoryData = [
      { category: "1 week", active: false, value: "this_week" },
      { category: "1 month", active: false, value: "this_month" },
      { category: "3 months", active: false, value: "three_month" },
      { category: "6 months", active: false, value: "six_month" },
      { category: "1 year", active: false, value: "one_year" }
    ];

    if (categoryData[index].active) {
      setInputcategory(inputcategory.filter(item => item !== category));
    } else {
      const input: any = [...inputcategory, category];
      setInputcategory(input);
    }
    categoryData[index].active = !categoryData[index].active;
    setCategory(categoryData);
    await dispatch(putFilterHistoryTrip(categoryData[index].value));
    await dispatch(getTripHistory(false, tripState.searchValue));
    await setModalOpen(false);
  };

  const handleClearFilter = async () => {
    const categoryData = [
      { category: "1 week", active: false, value: "this_week" },
      { category: "1 month", active: false, value: "this_month" },
      { category: "3 months", active: false, value: "three_month" },
      { category: "6 months", active: false, value: "six_month" },
      { category: "1 year", active: false, value: "one_year" }
    ];

    setCategory(categoryData);
    await dispatch(putFilterHistoryTrip(""));
    await dispatch(getTripHistory(false, tripState.searchValue));
    await setModalOpen(false);
  };

  const render = (item: any, index: number) => {
    return (
      <View
        style={[styles.cardItem, item.active && { backgroundColor: "#50b1b1" }]}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 11
          }}
        >
          <Touchable onPress={() => handlecategoryInput(index, item.category)}>
            <Text
              style={
                item.active
                  ? [styles.textItem, { color: "#fff", fontWeight: "bold" }]
                  : styles.textItem
              }
            >
              {item.category}
            </Text>
          </Touchable>
        </View>
      </View>
    );
  };

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2) {
      color = "#F5AA3F";
    } else if (status === 4 || status === 7) {
      color = "#ed5a28";
    }
    return color;
  };

  return (
    <WrapContent isLoading={tripState.isLoadingListHistory}>
      <WrapList
        isEmpty={tripState.listHistory.length === 0}
        emptyComponent={() => (
          <Touchable onPress={() => setModalOpen(true)}>
            <Image style={styles.image} source={IMAGES.iconFilter} />
          </Touchable>
        )}
        onEndReached={() =>
          tripState.loadMoreHistory &&
          dispatch(getTripHistory(true, tripState.searchValue))
        }
      >
        <FlatList
          style={{ paddingBottom: 10, paddingHorizontal: 25 }}
          data={tripState.listHistory}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={() => (
            <LoadingLoadMore active={tripState.loadMoreHistory} />
          )}
          renderItem={({ item, index }) => (
            <Card
              item={item}
              index={index}
              type="history"
              onPress={() => {
                history.push(
                  `/trip-detail/${item.id}?type=history&status=${item.status}`,
                  {
                    labelStatus: item.status
                  }
                );
                dispatch(resetSearchTripValue());
              }}
              withStatus
              statusColor={_statusColor(item.status)}
              statusText={t("activity.detail.date")}
              statusDesc={`${convertDate(
                item.started_at,
                "DD MMM YYYY"
              )} - ${convertDate(item.finished_at, "DD MMM YYYY")}`}
            />
          )}
        />
      </WrapList>

      {tripState.listHistory.length > 0 && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          <Touchable onPress={() => setModalOpen(true)}>
            <Image style={styles.image} source={IMAGES.iconFilter} />
          </Touchable>
        </div>
      )}
      <Modal isVisible={modalIsOpen}>
        <View style={styles.content}>
          <View>
            <Cards cardStyle={styles.cardStyle}>
              <>
                <Text style={styles.title}>Filter</Text>
                <FlatList
                  style={{ marginBottom: 20 }}
                  data={categories}
                  renderItem={({ item, index }) => render(item, index)}
                />
                <View style={styles.cardItem}>
                  <Touchable onPress={() => handleClearFilter()}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        paddingVertical: 11,
                        backgroundColor: "#e74c3c",
                        borderRadius: 20
                      }}
                    >
                      <Text
                        style={[
                          styles.textItem,
                          { color: "#fff", fontWeight: "bold" }
                        ]}
                      >
                        CLEAR
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </>
            </Cards>
          </View>
          <View style={styles.buttonClose}>
            <Touchable onPress={() => setModalOpen(false)}>
              <Text style={styles.textClose}>
                {t("activity.close").toUpperCase}
              </Text>
            </Touchable>
          </View>
        </View>
      </Modal>
    </WrapContent>
  );
};
export default History;
