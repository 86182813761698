import React, { useEffect } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import { getTaskList, resetSearchTaskValue } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { IMAGES } from "../../../configs";
import {
  Card,
  CardStatusColor,
  LoadingLoadMore,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";
import { permissionPage } from "../../../utils";

interface Props {
  type:
    | "upcoming"
    | "ongoing"
    | "finished"
    | "assigned"
    | "myrequest"
    | "moderation";
}

const List = ({ type }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const taskState = useSelector((state: Reducers) => state.task);
  const { pathname } = history.location as any;
  const pathStatus = pathname.split("/").pop();
  const permissionCreate = {
    asign: permissionPage("task-assign"),
    create: permissionPage("task-create"),
    selfView: permissionPage("task-self-view"),
    otherView: permissionPage("task-others-view")
  };

  useEffect(() => {
    dispatch(getTaskList(type, false, taskState.searchValue));
  }, [dispatch, taskState.searchValue]);

  const _statusColor = (status: number, historyExtends: any[]) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2) {
      color = "#F5AA3F";
    } else if (status === 4) {
      color = "#ed5a28";
    } else if (status === 5 && historyExtends.length > 1) {
      color = "#2B94FC";
    }
    return color;
  };

  const statusDesc = (status: number, historyExtends: any[]) => {
    let label = "";
    if (status === 1) {
      label = t("task.status.draft");
    } else if (status === 2) {
      label = t("task.status.issued");
    } else if (status === 3) {
      label = t("task.status.assigned");
    } else if (status === 4) {
      label = t("task.status.revise");
    } else if (status === 5 && historyExtends.length > 1) {
      label = t("task.status.delayed");
    } else if (status === 5) {
      label = t("task.status.ongoing");
    } else if (status === 6) {
      label = t("task.status.finished");
    } else if (status === 7) {
      label = t("task.status.cancelled");
    }
    return label;
  };

  const _datectTypeFile = (ext: any) => {
    if (ext.length === 0) {
      return false;
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "jpg" ||
          el.file.extension === "jpeg" ||
          el.file.extension === "png"
      )
    ) {
      return "image";
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "doc" ||
          el.file.extension === "docx" ||
          el.file.extension === "pdf" ||
          el.file.extension === "xlsx"
      )
    ) {
      return "document";
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "doc" ||
          el.file.extension === "docx" ||
          el.file.extension === "pdf" ||
          el.file.extension === "xlsx" ||
          el.file.extension === "jpg" ||
          el.file.extension === "png" ||
          el.file.extension === "jpeg"
      )
    ) {
      return "mix";
    }
    return false;
  };

  const _renderTypeFile = (item: any) => {
    if (_datectTypeFile(item) === "document") {
      return (
        <Image source={IMAGES.document} style={{ height: 32, width: 32 }} />
      );
    }
    if (_datectTypeFile(item) === "image") {
      return (
        <Image source={IMAGES.picAttach} style={{ height: 32, width: 32 }} />
      );
    }
    if (_datectTypeFile(item) === "mix") {
      return <Image source={IMAGES.mixed} style={{ height: 32, width: 32 }} />;
    }
    return <></>;
  };

  const _toDetail = (id: string) => {
    if (permissionCreate.selfView || permissionCreate.otherView) {
      history.push(`/task/detail/${id}/${pathStatus}`);
      dispatch(resetSearchTaskValue());
    } else {
      toast.error("CANNOT ACCESS TASK DETAIL", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      });
    }
  };

  return (
    <WrapContent isLoading={taskState.isLoadingTaskList}>
      <ToastContainer limit={1} />
      <WrapList
        isEmpty={taskState.taskList.length === 0}
        emptyComponent={() => (
          <View>
            {permissionCreate.create || permissionCreate.asign ? (
              <View>
                {type !== "myrequest" && type !== "moderation" ? (
                  <Touchable
                    onPress={() => {
                      history.push("/new-assign-task");
                      dispatch(resetSearchTaskValue());
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{ width: 62.6, height: 75.6 }}
                      source={IMAGES.assignTask}
                    />
                  </Touchable>
                ) : (
                  <Touchable
                    onPress={() => {
                      history.push("/new-request-task");
                      dispatch(resetSearchTaskValue());
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{ width: 52.6, height: 52.6 }}
                      source={IMAGES.plus}
                    />
                  </Touchable>
                )}
              </View>
            ) : (
              <Touchable disabled>
                <Image
                  resizeMode="contain"
                  style={{ width: 62.6, height: 75.6 }}
                  source={IMAGES.assignTaskGrey}
                />
              </Touchable>
            )}
          </View>
        )}
        onEndReached={() =>
          dispatch(getTaskList(type, true, taskState.searchValue))
        }
      >
        <FlatList
          data={taskState.taskList}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={
            <LoadingLoadMore active={taskState.loadMoreTaskList} />
          }
          renderItem={({ item, index }) => (
            <View style={styles.container}>
              <Card
                onPress={() => _toDetail(item.id)}
                statusColor={_statusColor(item.status, item.history_extends)}
                cardStyle={[
                  styles.cardStyle,
                  { marginTop: index === 0 ? 2 : 0 }
                ]}
                statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                statusDesc={
                  type === "finished"
                    ? moment(item.finished_at).format("ddd DD MMM YYYY")
                    : statusDesc(item.status, item.history_extends)
                }
                statusText={
                  type === "finished" ? t("task.card.finishedOn") : "Status"
                }
              >
                <View style={styles.wrapContentCard}>
                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("task.card.project")}
                    </Text>
                    <View style={styles.textValue}>
                      <Text style={[styles.textLabel, styles.textValueProject]}>
                        {item.number}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValueProject,
                          styles.big,
                          styles.bold
                        ]}
                      >
                        {item.title}
                        {item.recurring ? " - Repeat task" : ""}
                      </Text>
                      <Text style={[styles.textLabel, styles.textValueProject]}>
                        {item.project && item.project.title}
                      </Text>
                    </View>
                    <View>
                      {_renderTypeFile(
                        item.file_collection ? item.file_collection : []
                      )}
                    </View>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>{t("task.card.type")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.is_team ? "Team" : "Individual"}
                    </Text>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("task.card.assignee")}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.assignee
                        .map((el: any) => el.employee.name)
                        .join(", ")}
                    </Text>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("task.card.assignBy")}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.assigner && item.assigner.name}
                    </Text>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {t("task.card.starting")}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      <Text style={{ fontWeight: "normal" }}>
                        {`${moment(item.started_at).format("ddd")} `}
                      </Text>
                      {`${moment(item.started_at).format("DD MMM YYYY")} `}
                    </Text>
                  </View>

                  <View style={styles.wrapCardText}>
                    <Text style={styles.textLabel}>
                      {item.status === 6
                        ? t("task.card.finished")
                        : t("task.card.expected")}
                    </Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      <Text style={{ fontWeight: "normal" }}>
                        {item.status === 6
                          ? `${moment(item.finished_at).format("ddd")} `
                          : `${moment(item.due_at).format("ddd")} `}
                      </Text>
                      {item.status === 6
                        ? `${moment(item.finished_at).format("DD MMM YYYY")}`
                        : `${moment(item.due_at).format("DD MMM YYYY")} `}
                    </Text>
                  </View>
                  {item.recurring && (
                    <>
                      <View style={styles.wrapCardText}>
                        <Text style={styles.textLabel}>
                          {t("task.card.repeat_at")}
                        </Text>
                        <Text
                          style={[
                            styles.textLabel,
                            styles.textValue,
                            styles.bold
                          ]}
                        >
                          <Text style={{ fontWeight: "normal" }}>
                            {`${moment(item.recurring_at).format("ddd")} `}
                          </Text>
                          {`${moment(item.recurring_at).format(
                            "DD MMM YYYY"
                          )} `}
                        </Text>
                      </View>
                      <View style={styles.wrapCardText}>
                        <Text style={styles.textLabel}>
                          {t("task.card.end_period")}
                        </Text>
                        <Text
                          style={[
                            styles.textLabel,
                            styles.textValue,
                            styles.bold
                          ]}
                        >
                          {item.recurring_ended_at !== null ? (
                            <>
                              <Text style={{ fontWeight: "normal" }}>
                                {`${moment(item.recurring_ended_at).format(
                                  "ddd"
                                )} `}
                              </Text>
                              {`${moment(item.recurring_ended_at).format(
                                "DD MMM YYYY"
                              )} `}
                            </>
                          ) : (
                            <Text style={{ fontWeight: "normal" }}> - </Text>
                          )}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
              </Card>
            </View>
          )}
        />
      </WrapList>
      {taskState.taskList.length > 0 && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          {permissionCreate.create || permissionCreate.asign ? (
            <View>
              {type !== "myrequest" && type !== "moderation" ? (
                <Touchable
                  onPress={() => {
                    history.push("/new-assign-task");
                    dispatch(resetSearchTaskValue());
                  }}
                >
                  <Image
                    resizeMode="contain"
                    style={{ width: 62.6, height: 75.6 }}
                    source={IMAGES.assignTask}
                  />
                </Touchable>
              ) : (
                <Touchable
                  onPress={() => {
                    history.push("/new-request-task");
                    dispatch(resetSearchTaskValue());
                  }}
                >
                  <Image
                    resizeMode="contain"
                    style={{ width: 52.6, height: 52.6 }}
                    source={IMAGES.plus}
                  />
                </Touchable>
              )}
            </View>
          ) : (
            <Touchable disabled>
              <Image
                resizeMode="contain"
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.assignTaskGrey}
              />
            </Touchable>
          )}
        </div>
      )}
    </WrapContent>
  );
};

export default List;
