import {
  DELETE_TRIP_REPORT_ERROR,
  DELETE_TRIP_REPORT_PENDING,
  DELETE_TRIP_REPORT_SUCCESS,
  GET_AVAILABLE_DATE_ERROR,
  GET_AVAILABLE_DATE_PENDING,
  GET_AVAILABLE_DATE_SUCCESS,
  GET_REPORT_BY_ID_ERROR,
  GET_REPORT_BY_ID_PENDING,
  GET_REPORT_BY_ID_SUCCESS,
  GET_REPORT_BY_USER_ID_ERROR,
  GET_REPORT_BY_USER_ID_PENDING,
  GET_REPORT_BY_USER_ID_SUCCESS,
  LOGOUT_SUCCESS,
  POST_NEW_REPORT_ERROR,
  POST_NEW_REPORT_PENDING,
  POST_NEW_REPORT_SUCCESS,
  PUT_EDIT_REPORT_ERROR,
  PUT_EDIT_REPORT_PENDING,
  PUT_EDIT_REPORT_SUCCESS,
  PUT_SUBMIT_TRIP_REPORT_ERROR,
  PUT_SUBMIT_TRIP_REPORT_PENDING,
  PUT_SUBMIT_TRIP_REPORT_SUCCESS,
  RESET_REPORT_FORM,
  SET_FORM_REPORT
} from "../actions";
import { Action, ReportState } from "../types";

const initialState: ReportState = {
  isLoadingDeleteTripReport: false,
  isLoadingPutSubmitTripReport: false,
  isLoadingPostNewReport: false,
  isLoadingDateAvailableReport: false,
  listDateAvailableReport: [],
  listReportByIdUser: [],
  isLoadingGetReportByIdUser: false,
  isLoadingPutEditReport: false,
  isLoadingGetReportById: false,
  form: {
    date: "",
    title: "",
    file: null,
    description: "",
    status: 0
  }
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // post newtrip
    case POST_NEW_REPORT_PENDING:
      return { ...state, isLoadingPostNewReport: true };
    case POST_NEW_REPORT_SUCCESS:
      return { ...state, isLoadingPostNewReport: false };
    case POST_NEW_REPORT_ERROR:
      return { ...state, isLoadingPostNewReport: false };

    // put newtrip
    case PUT_EDIT_REPORT_PENDING:
      return { ...state, isLoadingPutEditReport: true };
    case PUT_EDIT_REPORT_SUCCESS:
      return { ...state, isLoadingPutEditReport: false };
    case PUT_EDIT_REPORT_ERROR:
      return { ...state, isLoadingPutEditReport: false };

    case PUT_SUBMIT_TRIP_REPORT_PENDING:
      return { ...state, isLoadingPutSubmitTripReport: true };
    case PUT_SUBMIT_TRIP_REPORT_SUCCESS:
      return { ...state, isLoadingPutSubmitTripReport: false };
    case PUT_SUBMIT_TRIP_REPORT_ERROR:
      return { ...state, isLoadingPutSubmitTripReport: false };

    case DELETE_TRIP_REPORT_PENDING:
      return { ...state, isLoadingDeleteTripReport: true };
    case DELETE_TRIP_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingDeleteTripReport: false,
        listDateAvailableReport: [
          ...state.listDateAvailableReport,
          { id: payload.date }
        ]
      };
    case DELETE_TRIP_REPORT_ERROR:
      return { ...state, isLoadingDeleteTripReport: false };

    // get report by id
    case GET_REPORT_BY_ID_PENDING:
      return { ...state, isLoadingGetReportById: true };
    case GET_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingGetReportById: false,
        form: {
          date: payload.data.date,
          title: payload.data.title,
          description: payload.data.description,
          file: payload.data.file,
          status: payload.data.status
        }
      };
    case GET_REPORT_BY_ID_ERROR:
      return { ...state, isLoadingGetReportById: false };

    // RESET REPORT GET FORM
    case RESET_REPORT_FORM:
      return {
        ...state,
        form: initialState.form
      };

    case GET_REPORT_BY_USER_ID_PENDING:
      return { ...state, isLoadingGetReportByIdUser: true };
    case GET_REPORT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        isLoadingGetReportByIdUser: false,
        listReportByIdUser: payload.data
      };
    case GET_REPORT_BY_USER_ID_ERROR:
      return { ...state, isLoadingGetReportByIdUser: false };

    // set form
    case SET_FORM_REPORT:
      return {
        ...state,
        form: { ...state.form, [payload.data.formName]: payload.data.value }
      };

    // populate date available
    case GET_AVAILABLE_DATE_PENDING:
      return {
        ...state,
        isLoadingPopulateCategory: true,
        listDateAvailableReport: []
      };
    case GET_AVAILABLE_DATE_SUCCESS:
      return {
        ...state,
        isLoadingPopulateCategory: false,
        listDateAvailableReport: payload.data
      };
    case GET_AVAILABLE_DATE_ERROR:
      return { ...state, listDateAvailableReport: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
