import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 20
  },
  cardHeader: {
    paddingVertical: 12,
    borderRadius: 12,
    paddingHorizontal: 16,
    width: widthPercent(100) - 40
  },
  wrapCardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 3
  },
  wrapCardHeaderValue: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  font: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: COLORS.greyLight
  },
  grey: {
    color: COLORS.greyMedium
  },
  greyBold: {
    color: COLORS.greyMedium,
    fontWeight: "bold"
  },
  greenBold: {
    fontWeight: "bold",
    color: COLORS.green
  },
  emptyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  },
  cardStyle: {
    marginBottom: 12,
    borderRadius: 12
  },
  wrapContentCard: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 12
  },
  wrapCardText: {
    flexDirection: "row",
    marginBottom: 4
  },
  wrapText: {
    flexDirection: "row",
    marginBottom: 4,
    paddingHorizontal: 16
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "22%"
  },
  textLabels: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11
  },
  textValue: {
    width: "75%",
    marginRight: 7
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.grey
  },
  wrapBtnModeration: {
    alignItems: "center",
    marginTop: 8,
    marginBottom: 0
  },
  btnModeration: {
    borderRadius: 100,
    width: 54,
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main
  }
});

export default styles;
