/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import moment from "moment";
import {
  AutoComplete,
  Fixed,
  Header,
  Input,
  Message,
  Modal,
  ModalConfirmForm,
  Space,
  TextArea,
  Touchable
} from "../../../components";
import {
  copyWritings,
  formatMoney,
  getPolicies,
  heightPercent,
  maskedMoney,
  toast,
  widthPercent
} from "../../../utils";
import styles from "./styles";
import "./style.css";

import {
  getBudgetRemaining,
  getBudgetType,
  getCurrency,
  getPopulate,
  getPopulateBrand,
  getPopulateChildProject,
  getPopulateProduct,
  getPopulateProject,
  getTaskListDropdown,
  getTaxList,
  getVendor,
  postNewGeneralExpense,
  removePopulateChildProject,
  resetPopulateChildProject
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { COLORS, ICONS, IMAGES } from "../../../configs";

const NewCash = () => {
  const dispatch = useDispatch();
  const custom001 = process.env.REACT_APP_CUSTOM_001 === "true";
  const [isProject, setIsProject] = useState(true);
  const [numberItemCA, setNumberItemCA] = useState([
    { title: "", amount: "", quantity: 1, category_id: null, product_id: null }
  ]);
  const { taskListDropdown } = useSelector((state: Reducers) => state.task);
  const { t } = useTranslation();

  const { sprintf } = require("sprintf-js");
  const { vendors } = useSelector((state: Reducers) => state.vendor);
  const {
    budgetType,
    isLoadingNewGeneralExpense,
    budgetRemaining
  } = useSelector((state: Reducers) => state.expense);
  const {
    listPopulate,
    currencies,
    listTax,
    populateBrand,
    populateProduct
  } = useSelector((state: Reducers) => state.newExpense);
  const {
    listPopulateProject,
    isLoadingPopulateProject,
    listPopulateChildProject
  } = useSelector((state: Reducers) => state.newTrip);

  const [modalIsOpen, setModalOpen] = useState(false);
  const [date]: any[] = useState([]);

  const history = useHistory();

  const initialBody = {
    purpose: null,
    type_id: null,
    project_id: "",
    task_id: null,
    date: null,
    vendor_id: null,
    started_at: null,
    currency_code: null,
    amount: null,
    quantity: 1,
    file: null,
    remarks: "",
    number2: null,
    items: [],
    recurring: false,
    recurring_period: null,
    recurring_month: null,
    recurring_weekday: null,
    recurring_day: null,
    recurring_time: null,
    recurring_ended_at: null
  };

  const [errorModal, setErrorModal] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);
  const [modalAddTax, setModalAddTax] = useState(false);
  const [endPeriod, setEndPeriod] = useState(true);
  const [tempDate, setTempDate] = useState("");
  const [tempRecurring, setTempRecurring] = useState("");
  const [tempTotal, setTempTotal] = useState(null) as any;
  const [projectParent, setProjectParent] = useState("");
  const [projectChild, setProjectChild] = useState<string[]>([]);
  const [itemType, setItemType] = useState("standard");
  const [bodyItems, setBodyItems]: any = useState({
    title: "",
    amount: "",
    category_id: "",
    budget_id: null,
    quantity: 1,
    categoryName: "",
    file: "",
    task_id: null,
    project_id: "",
    product_id: null,
    tax_id: null,
    tax_rate: null,
    tax_type: "",
    tax_category_name: "",
    brand_id: null
  });

  const [body, setBody]: any = useState(initialBody);

  const teks = {
    vendor: copyWritings("vendor"),
    task: copyWritings("task"),
    project: copyWritings("project"),
    cash_advance: copyWritings("cash_advance"),
    reimburse: copyWritings("reimburse"),
    payment_request: copyWritings("payment_request"),
    finance_category: copyWritings("finance_category"),
    policy: copyWritings("policy"),
    expense: copyWritings("expense")
  };

  useEffect(() => {
    dispatch(getTaskListDropdown(""));
    dispatch(getVendor());
    dispatch(getCurrency());
    dispatch(getTaxList());
    dispatch(getPopulateBrand());
    dispatch(getPopulateProduct());
    dispatch(getPopulateProject({ show_top_parent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (body.type_id === "rm") {
      dispatch(getPopulate("Reimburse"));
      dispatch(getTaskListDropdown("rm"));
    } else {
      dispatch(getPopulate());
      dispatch(getTaskListDropdown(""));
    }
  }, [dispatch, body.type_id]);

  useEffect(() => {
    if (currencies.length > 0) {
      setBody({ ...body, currency_code: currencies[0].attributes.real_id });
    }
  }, [currencies]);

  useEffect(() => {
    if (projectChild.length > 0) {
      const [project_id] = projectChild.slice(-1);
      dispatch(getBudgetRemaining(project_id));
      dispatch(getBudgetType(project_id));
      dispatch(
        getBudgetType(project_id, resp => {
          if (resp.length === 0) {
            body.items.forEach((_: any, index: number) => {
              body.items[index].budget_id = null;
            });
          }
        })
      );
    } else if (projectParent) {
      dispatch(getBudgetRemaining(projectParent));
      dispatch(
        getBudgetType(projectParent, resp => {
          if (resp.length === 0) {
            body.items.forEach((item: any, index: number) => {
              body.items[index].budget_id = null;
            });
          }
        })
      );
    }
  }, [projectParent, projectChild.length]);

  const _renderQty = (type_id: string) => {
    let data = false;
    if (type_id === "rm") {
      data = true;
    } else if (type_id === "pr") {
      data = true;
    }

    return data;
  };

  const _resetBodyItems = () => {
    setBodyItems({
      title: "",
      amount: "",
      category_id: "",
      budget_id: null,
      quantity: 1,
      categoryName: "",
      product_id: null,
      tax_id: null,
      tax_rate: null,
      tax_type: "",
      tax_category_name: "",
      brand_id: null
    });
  };

  const previewFile = (index?: number) => {
    if (index || index === 0) {
      if (
        body &&
        (body.items[index].file.type === "application/pdf" ||
          body.items[index].file.type === "application/vnd.ms-excel" ||
          body.items[index].file.type === ".doc" ||
          body.items[index].file.type === ".docx")
      ) {
        window.open(URL.createObjectURL(body.items[index].file));
      } else {
        const newTab = window.open();
        newTab!.document.body.innerHTML = `
    <div
      style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
    >
      <img src="${URL.createObjectURL(body.items[index].file)}">
    </div>`;
      }
    } else if (
      bodyItems &&
      (bodyItems.file.type === "application/pdf" ||
        bodyItems.file.type === "application/vnd.ms-excel" ||
        bodyItems.file.type === ".doc" ||
        bodyItems.file.type === ".docx")
    ) {
      window.open(URL.createObjectURL(bodyItems.file));
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
      <div
        style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
      >
        <img src="${URL.createObjectURL(bodyItems.file)}">
      </div>`;
    }
  };

  const renderDate = () => {
    for (let i = 1; i < 32; i += 1) {
      date.push({ label: i < 10 ? `0${i}` : `${i}`, value: i });
    }
  };

  const _handleDispatchPost = () => {
    const form = JSON.parse(JSON.stringify(body));

    if (form.type_id === "ca") {
      form.items = numberItemCA;
      form.date = moment(form.started_at).format("ddd, DD MMM YYYY HH:mm:ss");
      form.started_at = moment().format("ddd, DD MMM YYYY HH:mm:ss");
    }

    if (projectChild.length > 0) {
      const [project_id] = projectChild.slice(-1);
      form.project_id = project_id;
    } else {
      form.project_id = projectParent;
    }

    if (["rm", "pr"].includes(form.type_id)) {
      const budgetUnallocated =
        budgetRemaining.find(item => item.attributes.title === "Unallocated")
          ?.id || null;
      form.items.forEach((item: any, index: number) => {
        if (!item.tax_id && item.budget_id === null) {
          form.items[index].budget_id = budgetUnallocated;
        }
      });
    }

    form.items.map((item: any, index: number) => {
      if (item.file) {
        form[`file_${index}`] = item.file;
      } else {
        form[`file_${index}`] = null;
      }
      delete form.items[index].categoryName;
      delete form.items[index].task_id;
      delete form.items[index].project_id;
      delete form.items[index].fileName;
      delete form.items[index].file;
      delete form.items[index].tax_rate;
      delete form.items[index].tax_type;
      delete form.items[index].tax_category_name;
    });
    const temp = form.items;
    form.items = JSON.stringify(form.items);
    dispatch(postNewGeneralExpense(form, () => history.push("/cash")));
    form.items = temp;
  };

  const _onConfirmSubmitForm = () => {
    let projectName = "";
    let projectValue = "";
    if (isProject) {
      projectName = sprintf(t("cash.new.name"), teks.project);
      if (projectChild.length > 0) {
        const [project_id] = projectChild.slice(-1);
        projectValue =
          listPopulateChildProject.find(sub => {
            return sub.find((v: any) => v.value === project_id);
          })?.[0]?.label || "";
      } else {
        projectValue =
          listPopulateProject.find(e => e.value === projectParent)?.label ||
          "-";
      }
    } else {
      projectName = sprintf(t("cash.new.name"), teks.task);
      projectValue =
        taskListDropdown.find(e => e.id === body.task_id)?.attributes.title ||
        "-";
    }

    const vendorName = vendors.find(e => e.id === body.vendor_id)?.title;
    const headerItem = [
      t("cash.new.description"),
      teks.finance_category,
      t("cash.new.budgetType"),
      t("cash.new.qty"),
      t("cash.new.amount"),
      "Total",
      t("cash.new.attachments")
    ];
    const headerItemCA = [t("cash.new.description"), t("cash.new.amount")];
    let items: any = [];
    let tempItem: any = [];
    if (body.type_id === "ca") {
      tempItem = numberItemCA.map(({ title, amount }: any) => [
        title || "-",
        amount || "-"
      ]);
      items = [[...headerItemCA], ...tempItem];
    } else {
      tempItem =
        body.items.length > 0 &&
        body.items.map(
          ({
            title,
            category_id,
            budget_id,
            quantity,
            amount,
            fileName
          }: any) => [
            title || "-",
            category_id
              ? listPopulate.find(e => e.value === category_id)?.label
              : "",
            budget_id &&
            budgetType.find(e => e.id === budget_id).attributes.title
              ? budgetType.find(e => e.id === budget_id).attributes.title
              : "-",
            quantity || "-",
            amount || "-",
            quantity * amount || "-",
            fileName || "-"
          ]
        );
      items = [[...headerItem], ...tempItem];
    }

    const result: any = [
      {
        title: t("cash.new.subject"),
        value: body.purpose ? body.purpose : "-"
      },
      {
        title: t("cash.detail.method"),
        value:
          body.type_id === "ca"
            ? teks.cash_advance
            : body.type_id === "rm"
            ? teks.reimburse
            : teks.payment_request
      },
      {
        title: t("cash.new.date"),
        value: body.started_at
          ? moment(body.started_at).format("DD MMM YYYY")
          : "-"
      },
      { title: projectName, value: projectValue || "-" },
      {
        title: teks.vendor,
        value: body.type_id === "pr" && body.vendor_id ? vendorName : "-"
      },
      {
        title: t("cash.new.remarks"),
        value: body.remarks ? body.remarks : "-"
      },
      {
        title: t("cash.new.recurring"),
        value: body.recurring ? t("cash.new.yes") : t("cash.new.no")
      },
      {
        title: t("cash.new.period"),
        value:
          body.recurring_period === 1
            ? t("cash.new.daily")
            : body.recurring_period === 2
            ? t("cash.new.weekly")
            : body.recurring_period === 3
            ? t("cash.new.monthly")
            : !body.recurring
            ? "-"
            : t("cash.new.yearly")
      },
      {
        title: t("cash.new.recurringMonth"),
        value:
          body.recurring_period === 4
            ? moment()
                .month(Number(body.recurring_month) - 1)
                .format("MMMM")
            : "-"
      },
      {
        title: t("cash.new.recurringWeek"),
        value:
          body.recurring_period === 2
            ? moment().day(body.recurring_weekday).format("dddd")
            : "-"
      },
      {
        title: t("cash.new.recurringDate"),
        value: [3, 4].includes(body.recurring_period) ? body.recurring_day : "-"
      },
      {
        title: t("cash.new.recurringTime"),
        value: body.recurring ? body.recurring_time : "-"
      },
      {
        title: t("cash.new.recurringEndTime"),
        value: body.recurring ? body.recurring_ended_at : "-"
      },
      { title: t("cash.new.item"), value: items.length > 1 ? items : "-" }
    ];

    if (result) {
      setDataConfirmSubmit(result.filter((e: any) => e.value !== "-"));
      setModalSubmitForm(true);
    }
  };

  const _handleSubmit = () => {
    const listBody: any[] = body.items.filter(
      (item: any) => item.tax_id === null
    );
    const lisBodybudget: any[] = body.items.filter(
      (item: any) => item.budget_id !== null
    );

    if (
      !body.type_id ||
      !body.started_at ||
      !body.purpose ||
      (moment(body.started_at || "") < moment().startOf("day") &&
        body.type_id === "ca") ||
      (new Date(body.started_at) > new Date() && body.type_id === "rm") ||
      (body.type_id === "ca" &&
        numberItemCA.filter(item => item.title === "").length > 0) ||
      (body.type_id === "ca" &&
        numberItemCA.filter(item => item.amount === "").length > 0) ||
      (body.type_id === "pr" && !body.vendor_id) ||
      (isProject && projectParent === "") ||
      (!isProject && body.task_id === "") ||
      (body.recurring && !body.recurring_period) ||
      (body.recurring &&
        body.recurring_period !== null &&
        (body.recurring_period === 4 || body.recurring_period === 3) &&
        !body.recurring_day) ||
      (body.recurring &&
        body.recurring_period !== null &&
        body.recurring_period === 2 &&
        !body.recurring_weekday) ||
      (body.recurring &&
        body.recurring_period !== null &&
        !body.recurring_time) ||
      (lisBodybudget.length < listBody.length && budgetType.length > 0)
    ) {
      setErrorSubmit(true);
    } else if (body.type_id !== "ca" && listBody.length === 0) {
      toast.error(t("cash.new.atLeastOneItem"));
    } else if (
      body.items.length === 0 &&
      (body.type_id === "pr" || body.type_id === "rm")
    ) {
      toast.error(t("cash.new.atLeastOneItem"));
    } else {
      _onConfirmSubmitForm();
    }
  };

  const policyExpenseGeneral = getPolicies("expense-general");
  const policyCA = getPolicies("expense-general-ca");
  const policyCAAmount = getPolicies("expense-general-ca-amount");
  const policyRM = getPolicies("expense-general-rm");
  const policyPR = getPolicies("expense-general-pr");
  const policyReceiptRM = getPolicies("expense-general-rm-receipt");
  const policyReceiptPR = getPolicies("expense-general-pr-receipt");

  const _handleRenderMessagePolicy = () => {
    if (!policyExpenseGeneral) {
      return String(
        sprintf(t("cash.new.msg.policy-1"), teks.policy, teks.expense)
      );
    }
    if (!policyCA && body.type_id === "ca") {
      return "According to our policy, you cannot make cash advance. Violation will be recorded ";
    }
    if (Number(body.amount) > policyCAAmount && body.type_id === "ca") {
      return `According to our policy, you cannot input amount higher than ${maskedMoney(
        policyCAAmount
      )}. Violation will be recorded `;
    }
    if (!policyRM && body.type_id === "rm") {
      return "According to our policy, you cannot make reimburse. Violation will be recorded ";
    }
    if (!policyPR && body.type_id === "pr") {
      return "According to our policy, you cannot make payment request. Violation will be recorded ";
    }
    if (
      ((policyReceiptRM && body.type_id === "rm") ||
        (policyReceiptPR && body.type_id === "pr")) &&
      bodyItems.file === ""
    ) {
      // eslint-disable-next-line max-len
      return String(sprintf(t("cash.new.msg.policy-2"), teks.policy));
    }
    return "";
  };

  const _onChangeRecPeriod = (val?: number) => {
    if (val === 1) {
      setBody({
        ...body,
        recurring_ended_at: moment(tempDate)
          .local()
          .add(1, "d")
          .format("YYYY-MM-DD"),
        recurring_period: val
      });
    } else if (val === 2) {
      setBody({
        ...body,
        recurring_ended_at: moment(tempDate)
          .local()
          .add(7, "d")
          .format("YYYY-MM-DD"),
        recurring_period: val
      });
    } else if (val === 3) {
      setBody({
        ...body,
        recurring_ended_at: moment(tempDate)
          .local()
          .add(1, "M")
          .format("YYYY-MM-DD"),
        recurring_period: val
      });
    } else if (val === 4) {
      setBody({
        ...body,
        recurring_ended_at: moment(tempDate)
          .local()
          .add(12, "M")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        recurring_period: val
      });
    }
  };

  const _onChangeRecPeriodFromStartDate = (val: any) => {
    if (tempRecurring === "1") {
      setBody({
        ...body,
        recurring_ended_at: moment(val)
          .local()
          .add(1, "d")
          .format("YYYY-MM-DD"),
        started_at: val
      });
    } else if (tempRecurring === "2") {
      setBody({
        ...body,
        recurring_ended_at: moment(val)
          .local()
          .add(7, "d")
          .format("YYYY-MM-DD"),
        started_at: val
      });
    } else if (tempRecurring === "3") {
      setBody({
        ...body,
        recurring_ended_at: moment(val)
          .local()
          .add(1, "M")
          .format("YYYY-MM-DD"),
        started_at: val
      });
    } else if (tempRecurring === "4") {
      setBody({
        ...body,
        recurring_ended_at: moment(val)
          .local()
          .add(12, "M")
          .add(1, "d")
          .format("YYYY-MM-DD"),
        started_at: val
      });
    }
  };

  const _getTempTotal = (data?: any[]) => {
    let dt: any[] = body.items;
    let total = 0;

    if (data) {
      dt = data;
      const lisTax = body.items.filter((item: any) => item.tax_id !== null);
      const listItem: any[] = dt.filter((e: any) => e.tax_id === null);
      listItem.forEach(e => {
        total += Number(e.amount) * Number(e.quantity);
      });

      if (lisTax.length > 0) {
        lisTax.forEach((item: any, index: number) => {
          if (item.tax_type === "pph") {
            lisTax[index].amount = (total * Number(-item.tax_rate)) / 100;
          } else if (item.tax_type === "ppn") {
            lisTax[index].amount = (total * Number(item.tax_rate)) / 100;
          }
        });
      }
    } else {
      const listItem: any[] = dt.filter((e: any) => e.tax_id === null);
      listItem.forEach(e => {
        total += Number(e.amount) * Number(e.quantity);
      });
    }
    setTempTotal(total);
  };

  const _onProjectChange = (id: string, index: number) => {
    dispatch(getPopulateChildProject({ parent_id: id }, index));
  };

  const _getValuetOption = useCallback((data, id) => {
    if (id) {
      return { label: data.find((e: any) => e.value === id)?.label || "" };
    }
    return "";
  }, []);

  const _onChangeRadio = (e: any) => {
    setItemType(e.target.value);
    setBody({ ...body, items: [] });
  };

  const _onChangeProduct = (idProduct: string) => {
    if (idProduct) {
      const dtProduct = populateProduct.find(e => e.id === idProduct);
      setBodyItems({
        ...bodyItems,
        product_id: idProduct,
        amount: Number(dtProduct?.attributes?.amount) || "",
        title: dtProduct?.attributes?.description || "",
        category_id: dtProduct?.attributes?.category_id || ""
      });
    }
  };

  const _widthItemTitle = (itemTaxId: string) => {
    if (itemTaxId) {
      return "30%";
    }
    if (isProject && budgetType.length > 0) {
      return "30%";
    }
    if (isProject && !projectParent) {
      return "30%";
    }
    return "75%";
  };

  return (
    <View>
      {vendors && listPopulate ? (
        <View style={{ alignItems: "center" }}>
          <Header
            textHeader={{ fontSize: 18 }}
            subTitleHeader={{ fontSize: 11 }}
            title={sprintf(t("cash.new.newBill"), teks.expense)}
          />
          <View
            style={{
              width: widthPercent(100) - 48,
              marginTop: 20
            }}
          >
            {!policyExpenseGeneral ||
            (!policyCA && body.typeid === "ca") ||
            (!policyRM && body.type_id === "rm") ||
            (!policyPR && body.type_id === "pr") ||
            (((policyReceiptRM && body.type_id === "rm") ||
              (policyReceiptPR && body.type_id === "pr")) &&
              bodyItems.file === "") ||
            (Number(body.amount) > policyCAAmount && body.type_id === "ca") ? (
              <View style={{ marginBottom: 20 }}>
                <Message message={_handleRenderMessagePolicy()} />
              </View>
            ) : null}

            <View style={[styles.inputContainer]}>
              <View style={styles.columnInput}>
                <View style={{ width: "48%" }}>
                  <View style={styles.containerDropdown}>
                    <Text style={styles.labelInput}>
                      {t("cash.detail.method")}
                    </Text>
                    <select
                      style={{ color: "#666666", fontWeight: "bold" }}
                      className="container-content-input"
                      onChange={e => {
                        setBody({
                          ...body,
                          type_id: e.target.value,
                          items: [],
                          recurring: false
                        });
                      }}
                    >
                      <option selected hidden>
                        {t("cash.new.selectMethod")}
                      </option>
                      <option value="ca">{teks.cash_advance}</option>
                      <option value="pr">{teks.payment_request}</option>
                      <option value="rm">{teks.reimburse}</option>
                    </select>
                  </View>
                  {errorSubmit && !body.type_id ? (
                    <Text style={styles.validateText}>
                      {t("cash.new.msg.req")}
                    </Text>
                  ) : null}
                </View>
                <View style={{ width: "48%" }}>
                  <View
                    style={{
                      height: 45,
                      width: "100%"
                    }}
                  >
                    <Text style={styles.labelInput}>{t("cash.new.date")}</Text>
                    <input
                      onChange={(e: any) => {
                        setBody({
                          ...body,
                          started_at: e.target.value,
                          date: new Date(e.target.value).toUTCString()
                        });
                        setTempDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        if (body.type_id === "pr" && body.recurring) {
                          _onChangeRecPeriodFromStartDate(e.target.value);
                        }
                      }}
                      min={
                        body.type_id === "ca"
                          ? moment(new Date()).format("YYYY-MM-DD")
                          : ""
                      }
                      type="date"
                      style={{ color: "#666666", fontWeight: "bold" }}
                      className="container-content-input"
                      max={
                        body.type_id === "rm"
                          ? moment(new Date()).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                  </View>

                  {errorSubmit && !body.started_at ? (
                    <Text style={styles.validateText}>
                      {t("cash.new.msg.req")}
                    </Text>
                  ) : null}
                  {errorSubmit &&
                  moment(body.started_at || "") < moment().startOf("day") &&
                  body.type_id === "ca" ? (
                    <Text style={styles.validateText}>
                      {t("cash.new.msg.dateCannotLow")}
                    </Text>
                  ) : null}
                  {errorSubmit &&
                  new Date(body.started_at) > new Date() &&
                  body.type_id === "rm" ? (
                    <Text style={styles.validateText}>
                      {t("cash.new.msg.dateCannotHigh")}
                    </Text>
                  ) : null}
                </View>
              </View>
              <View style={{ width: "100%", marginTop: 20 }}>
                <Text style={styles.labelInput}>{t("cash.new.subject")}</Text>
                <Input
                  value={body.purpose}
                  onChangeText={(e: any) => setBody({ ...body, purpose: e })}
                  placeholder={t("cash.new.subjectPH")}
                  inputStyle={styles.inputStyle}
                />
                {!body.purpose && errorSubmit ? (
                  <Text style={styles.validateText}>
                    {t("cash.new.msg.req")}
                  </Text>
                ) : null}
              </View>

              {body.type_id === "pr" ? (
                <View>
                  <Text style={[styles.labelInput, { marginTop: 20 }]}>
                    {teks.vendor}
                  </Text>
                  <select
                    style={{ color: "#666666", fontWeight: "bold" }}
                    className="container-content-input"
                    onChange={e =>
                      setBody({ ...body, vendor_id: e.target.value })
                    }
                  >
                    <option selected hidden>
                      {sprintf(t("cash.new.selectVendor"), teks.vendor)}
                    </option>
                    {vendors.map((vendorData: any, index: number) => (
                      <option key={index} value={vendorData.id}>
                        {vendorData.title}
                      </option>
                    ))}
                  </select>
                  {body.type_id === "pr" && !body.vendor_id && errorSubmit ? (
                    <Text style={styles.validateText}>
                      {t("cash.new.msg.req")}
                    </Text>
                  ) : null}
                </View>
              ) : null}

              {body.type_id === "pr" && (
                <View style={{ marginTop: 16 }}>
                  <Text style={styles.labelInput}>Item Type</Text>
                  <Space height={4} />
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flexDirection: "row" }}>
                      <input
                        type="radio"
                        name="item_type"
                        id="standard"
                        value="standard"
                        checked={itemType === "standard"}
                        onChange={_onChangeRadio}
                      />
                      <label className="input-label-radio" htmlFor="standard">
                        Standard input
                      </label>
                    </View>
                    <Space width={8} />
                    <View style={{ flexDirection: "row" }}>
                      <input
                        type="radio"
                        name="item_type"
                        id="product"
                        value="product"
                        checked={itemType === "product"}
                        onChange={_onChangeRadio}
                      />
                      <label className="input-label-radio" htmlFor="product">
                        Product as template
                      </label>
                    </View>
                  </View>
                  <Space height={12} />
                </View>
              )}
            </View>

            <View style={styles.inputContainer}>
              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row"
                }}
              >
                <View style={{ width: "24%", height: 50 }}>
                  <Text style={styles.labelInput}>
                    {sprintf(
                      t("cash.new.chooseTaskProject"),
                      teks.task,
                      teks.project
                    )}
                  </Text>
                  <View style={[styles.wrapSwitch]}>
                    <Switch
                      onChange={() => {
                        setIsProject(!isProject);
                        setBody({ ...body, task_id: "" });
                        setProjectParent("");
                        setProjectChild([]);
                        dispatch(resetPopulateChildProject());
                      }}
                      checked={isProject}
                      onColor="#50b1b1"
                      onHandleColor="#fffff7"
                      handleDiameter={19}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                      height={19}
                      width={34}
                    />
                    <Text style={styles.textSelfBooking}>
                      {isProject ? teks.project : teks.task}
                    </Text>
                  </View>
                </View>
                <View style={{ width: "73%" }}>
                  <Text style={styles.labelInput}>
                    {sprintf(t("cash.new.title"), teks.task, teks.project)}
                  </Text>
                  {isProject ? (
                    <View>
                      <AutoComplete
                        fontSize={12}
                        placeHolder={t("task.assign.chooseProject")}
                        option={listPopulateProject}
                        onValueChange={e => {
                          _onProjectChange(e.value, 0);
                          setProjectChild([]);
                          setProjectParent(e.value);
                        }}
                        isLoading={isLoadingPopulateProject}
                      />
                      {projectParent === "" && errorSubmit && (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      )}
                      {listPopulateChildProject.map((item, i) => (
                        <View key={`childProject-${i}`}>
                          <View style={styles.wrapSelectProject}>
                            <View style={{ flex: 1 }}>
                              <AutoComplete
                                placeHolder={t("task.assign.chooseProject")}
                                fontSize={12}
                                value={_getValuetOption(item, projectChild[i])}
                                option={item}
                                onValueChange={e => {
                                  _onProjectChange(e.value, i + 1);
                                  const projectChildTmp = projectChild.map(
                                    v => v
                                  );
                                  projectChildTmp[i] = e.value;
                                  setProjectChild(projectChildTmp);
                                }}
                                isLoading={isLoadingPopulateProject}
                              />
                            </View>

                            <TouchableOpacity
                              onPress={() => {
                                dispatch(removePopulateChildProject(i));
                                const projectChildTmp = projectChild.filter(
                                  (_, index) => index < i
                                );
                                setProjectChild(projectChildTmp);
                              }}
                              style={[
                                {
                                  height: 25,
                                  width: 25,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: 0
                                }
                              ]}
                            >
                              <Image
                                source={IMAGES.trashBin}
                                style={{ width: 13, height: 16 }}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                      ))}
                    </View>
                  ) : (
                    <>
                      <select
                        style={{ color: "#666666", fontWeight: "bold" }}
                        className="container-content-input"
                        onChange={(e: any) => {
                          setBody({ ...body, task_id: e.target.value });
                        }}
                      >
                        <option selected hidden>
                          {sprintf(t("cash.new.selectVendor"), teks.task)}
                        </option>
                        {taskListDropdown.map((task: any, index: number) => (
                          <option key={index} value={task.id}>
                            {task.attributes.title}
                          </option>
                        ))}
                      </select>
                      {body.task_id === "" && errorSubmit && (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      )}
                    </>
                  )}
                </View>
              </View>
            </View>
            <View style={{ width: "100%" }}>
              <Text style={styles.labelInput}>{t("cash.new.remarks")}</Text>
              <TextArea
                value={body.remarks}
                bold
                onChange={(e: any) =>
                  setBody({ ...body, remarks: e.target.value })
                }
              />
            </View>

            <View style={{ width: "100%", marginTop: 20 }}>
              <Text style={styles.labelInput}>Secondary Number</Text>
              <Input
                value={body.number2}
                onChangeText={(e: any) => setBody({ ...body, number2: e })}
                placeholder="Secondary Number"
                inputStyle={styles.inputStyle}
              />
            </View>

            {body.type_id === "ca" ? (
              <View style={styles.inputContainer}>
                <View style={styles.currencyContainer}>
                  <View style={{ width: "100%" }}>
                    <Text style={styles.labelInput}>
                      {t("cash.new.currency")}
                    </Text>
                    <select
                      style={{ color: "#666666", fontWeight: "bold" }}
                      className="container-content-input"
                      onChange={e =>
                        setBody({ ...body, currency_code: e.target.value })
                      }
                    >
                      {currencies.map((currency: any, index: number) => (
                        <option key={index} value={currency.attributes.real_id}>
                          {currency.attributes.title}
                        </option>
                      ))}
                    </select>
                  </View>
                </View>
                {numberItemCA.map((_, indexItem) => (
                  <View key={indexItem} style={styles.currencyContainer}>
                    <View
                      style={{ width: numberItemCA.length > 1 ? "40%" : "48%" }}
                    >
                      <Text style={styles.labelInput}>
                        {t("cash.new.amount")}
                      </Text>
                      <View style={[styles.card, { justifyContent: "center" }]}>
                        <TextInput
                          keyboardType="numeric"
                          value={numberItemCA[indexItem].amount}
                          onChange={(e: any) => {
                            numberItemCA[indexItem].amount = String(
                              e.target.value
                            );
                            setNumberItemCA([...numberItemCA]);
                          }}
                          style={styles.inputAmount}
                        />
                      </View>
                      {body.type_id === "ca" &&
                      !numberItemCA[indexItem].amount &&
                      errorSubmit ? (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      ) : null}
                    </View>
                    <View
                      style={{ width: numberItemCA.length > 1 ? "40%" : "48%" }}
                    >
                      <Text style={styles.labelInput}>
                        {t("cash.new.description")}
                      </Text>
                      <TextArea
                        onChange={e => {
                          numberItemCA[indexItem].title = e.target.value;
                          setNumberItemCA([...numberItemCA]);
                        }}
                        value={numberItemCA[indexItem].title}
                        placeholder={t("cash.new.inputDescription")}
                        bold
                        fontSize="small"
                      />
                      {body.type_id === "ca" &&
                      !numberItemCA[indexItem].title &&
                      errorSubmit ? (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      ) : null}
                    </View>
                    {numberItemCA.length > 1 && (
                      <TouchableOpacity
                        onPress={() => {
                          const deletedItems = numberItemCA;
                          deletedItems.splice(indexItem, 1);
                          setNumberItemCA([...deletedItems]);
                        }}
                        style={[styles.card, styles.containerDelete]}
                      >
                        <Image
                          source={IMAGES.trashBin}
                          style={{ width: 13, height: 16 }}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                ))}
                <TouchableOpacity
                  onPress={() =>
                    setNumberItemCA([
                      ...numberItemCA,
                      {
                        title: "",
                        amount: "",
                        quantity: 1,
                        category_id: null,
                        product_id: null
                      }
                    ])
                  }
                  style={[styles.buttonUpload, { marginTop: 20 }]}
                >
                  <Text style={{ fontWeight: "bold", color: "white" }}>
                    {t("cash.new.addItem")}
                  </Text>
                </TouchableOpacity>
              </View>
            ) : null}
            <View style={styles.inputContainer}>
              <View style={styles.columnInput}>
                <View style={{ marginTop: "1rem" }}>
                  {body.type_id === "pr" ? (
                    <View>
                      <Text style={styles.labelInput}>
                        {t("cash.new.recurring")}
                      </Text>
                      <View style={[styles.wrapSwitch]}>
                        <Switch
                          onChange={() => {
                            renderDate();
                            setBody({
                              ...body,
                              recurring: !body.recurring,
                              recurring_period: null,
                              recurring_time: null,
                              recurring_month: null,
                              recurring_day: null
                            });
                          }}
                          checked={body.recurring}
                          onColor="#50b1b1"
                          onHandleColor="#fffff7"
                          handleDiameter={19}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                          height={19}
                          width={34}
                        />
                        <Text style={styles.textSelfBooking}>
                          {body.recurring
                            ? t("cash.new.yes")
                            : t("cash.new.no")}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>
                {body.recurring ? (
                  <View style={{ marginTop: "1rem", width: "73%" }}>
                    <Text style={styles.labelInput}>
                      {t("cash.new.period")}
                    </Text>
                    <select
                      style={{ color: "#666666", fontWeight: "bold" }}
                      className="container-content-input"
                      onChange={(e: any) => {
                        setBody({
                          ...body,
                          recurring_month: null,
                          recurring_day: null,
                          recurring_time: null,
                          recurring_weekday: null,
                          recurring_period: Number(e.target.value)
                        });
                        setTempRecurring(e.target.value);
                        _onChangeRecPeriod(Number(e.target.value));
                      }}
                    >
                      <option selected hidden>
                        {t("cash.new.periodePH")}
                      </option>
                      <option value={1}>{t("cash.new.daily")}</option>
                      <option value={2}>{t("cash.new.weekly")}</option>
                      <option value={3}>{t("cash.new.monthly")}</option>
                      <option value={4}>{t("cash.new.yearly")}</option>
                    </select>
                    {body.recurring && !body.recurring_period && errorSubmit ? (
                      <Text style={styles.validateText}>
                        {t("cash.new.msg.req")}
                      </Text>
                    ) : null}
                  </View>
                ) : null}
              </View>
            </View>
            <Space height={20} />
            <View style={[styles.inputContainer, { marginBottom: 110 }]}>
              {body.type_id === "pr" && body.recurring && tempRecurring ? (
                <View style={styles.containerRecurring}>
                  {tempRecurring === "4" ? (
                    <View
                      style={{
                        width: tempRecurring === "4" ? "32%" : "48%",
                        height: 50
                      }}
                    >
                      <Text style={styles.labelInput}>
                        {t("cash.new.month")}
                      </Text>
                      <View style={[styles.card, styles.inputRecurring]}>
                        <select
                          style={{
                            borderWidth: 0,
                            height: 40,
                            backgroundColor: COLORS.main,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "#666666",
                            fontFamily: "Poppins",
                            width: "80%"
                          }}
                          onChange={e =>
                            setBody({
                              ...body,
                              recurring_month: Number(e.target.value)
                            })
                          }
                        >
                          <option selected hidden>
                            {t("cash.new.selectMonth")}
                          </option>
                          <option value={1}>January</option>
                          <option value={2}>February</option>
                          <option value={3}>March</option>
                          <option value={4}>April</option>
                          <option value={5}>May</option>
                          <option value={6}>June</option>
                          <option value={7}>July</option>
                          <option value={8}>August</option>
                          <option value={9}>September</option>
                          <option value={10}>October</option>
                          <option value={11}>November</option>
                          <option value={12}>December</option>
                        </select>
                      </View>
                      {body.recurring &&
                      tempRecurring === "4" &&
                      errorSubmit &&
                      !body.recurring_month ? (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      ) : null}
                    </View>
                  ) : null}
                  {tempRecurring === "4" || tempRecurring === "3" ? (
                    <View
                      style={{
                        width: tempRecurring === "4" ? "32%" : "48%",
                        height: 50
                      }}
                    >
                      <Text style={styles.labelInput}>{t("cash.new.day")}</Text>
                      <View style={[styles.card, styles.inputRecurring]}>
                        <select
                          style={{
                            borderWidth: 0,
                            height: 40,
                            backgroundColor: COLORS.main,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "#666666",
                            fontFamily: "Poppins",
                            width: "80%"
                          }}
                          onChange={e =>
                            setBody({
                              ...body,
                              recurring_day: Number(e.target.value)
                            })
                          }
                        >
                          <option selected hidden>
                            {t("cash.new.selectDay")}
                          </option>
                          {date.map((dateData: any, index: number) => (
                            <option key={index} value={dateData.value}>
                              {dateData.label}
                            </option>
                          ))}
                        </select>
                      </View>
                      {body.recurring &&
                      (tempRecurring === "4" || tempRecurring === "3") &&
                      !body.recurring_day &&
                      errorSubmit ? (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      ) : null}
                    </View>
                  ) : null}
                  {tempRecurring === "2" ? (
                    <View
                      style={{
                        width: "48%",
                        height: 50
                      }}
                    >
                      <Text style={styles.labelInput}>
                        {t("cash.new.weekday")}
                      </Text>
                      <View style={[styles.card, styles.inputRecurring]}>
                        <select
                          style={{
                            borderWidth: 0,
                            height: 40,
                            backgroundColor: COLORS.main,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "#666666",
                            fontFamily: "Poppins",
                            width: "80%"
                          }}
                          onChange={e =>
                            setBody({
                              ...body,
                              recurring_weekday: Number(e.target.value)
                            })
                          }
                        >
                          <option selected hidden>
                            {t("cash.new.weekday")}
                          </option>
                          <option value={1}>{t("cash.new.days.Monday")}</option>
                          <option value={2}>
                            {t("cash.new.days.Tuesday")}
                          </option>
                          <option value={3}>
                            {t("cash.new.days.Wednesday")}
                          </option>
                          <option value={4}>
                            {t("cash.new.days.Thursday")}
                          </option>
                          <option value={5}>{t("cash.new.days.Friday")}</option>
                          <option value={6}>
                            {t("cash.new.days.Saturday")}
                          </option>
                          <option value={7}>{t("cash.new.days.Sunday")}</option>
                        </select>
                      </View>
                      {body.recurring &&
                      body.recurring_period !== null &&
                      tempRecurring === "2" &&
                      !body.recurring_weekday &&
                      errorSubmit ? (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      ) : null}
                    </View>
                  ) : null}
                  <View
                    style={{
                      width: tempRecurring === "4" ? "32%" : "48%",
                      height: 50
                    }}
                  >
                    <Text style={styles.labelInput}>Time</Text>
                    <View style={styles.card}>
                      <View style={styles.containerInputTime}>
                        <input
                          onChange={e =>
                            setBody({ ...body, recurring_time: e.target.value })
                          }
                          type="time"
                          style={{
                            width: "80%",
                            borderWidth: 0,
                            backgroundColor: COLORS.main
                          }}
                        />
                      </View>
                      {body.recurring &&
                      body.recurring_period !== null &&
                      !body.recurring_time &&
                      errorSubmit ? (
                        <Text style={styles.validateText}>
                          {t("cash.new.msg.req")}
                        </Text>
                      ) : null}
                    </View>
                  </View>
                </View>
              ) : null}
              {body.type_id === "pr" && body.recurring && (
                <View style={styles.containerRecurring}>
                  <View style={styles.columnInput}>
                    <View
                      style={{
                        marginTop: "1rem",
                        width: "24%"
                      }}
                    >
                      <View>
                        <Text style={styles.labelInput}>
                          {" "}
                          {t("cash.new.endPeriod")}
                        </Text>
                        <View style={[styles.wrapSwitch]}>
                          <Switch
                            onChange={() => setEndPeriod(!endPeriod)}
                            checked={endPeriod}
                            onColor="#50b1b1"
                            onHandleColor="#fffff7"
                            handleDiameter={19}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                            height={19}
                            width={34}
                          />
                          <Text style={styles.textSelfBooking}>
                            {endPeriod ? t("cash.new.yes") : t("cash.new.no")}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {body.recurring && endPeriod ? (
                      <View style={{ marginTop: "1rem", width: "73%" }}>
                        <View
                          style={{
                            height: 45,
                            width: "100%"
                          }}
                        >
                          <Text style={styles.labelInput}>
                            {t("cash.new.date")}
                          </Text>
                          <input
                            onChange={(e: any) => {
                              setBody({
                                ...body,
                                recurring_ended_at: e.target.value
                              });
                            }}
                            min={
                              body.started_at
                                ? moment(body.started_at).format("YYYY-MM-DD")
                                : ""
                            }
                            type="date"
                            style={{ color: "#666666", fontWeight: "bold" }}
                            className="container-content-input"
                            value={body.recurring_ended_at || ""}
                          />
                        </View>
                      </View>
                    ) : null}
                  </View>
                </View>
              )}

              {body.items.length > 0 &&
              (body.type_id === "pr" || body.type_id === "rm") ? (
                <View style={{ width: "100%", marginBottom: 60 }}>
                  <Text style={styles.labelInput}>{t("cash.new.item")}</Text>
                  <View style={styles.cardItems}>
                    <View style={{ marginHorizontal: 10, marginVertical: 10 }}>
                      <View style={{ width: "100%", flexDirection: "row" }}>
                        <View
                          style={{
                            width: "7%",
                            height: "100%"
                          }}
                        />
                        <View
                          style={{
                            width: "33%",
                            height: "100%"
                          }}
                        >
                          <Text
                            style={[
                              styles.attributeTask,
                              { fontWeight: "bold" }
                            ]}
                          >
                            {t("cash.new.item")}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: "20%",
                            height: "100%"
                          }}
                        />
                      </View>

                      {body.items.map((item: any, index: number) => {
                        return (
                          <View
                            key={index}
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              marginTop: 5,
                              position: "relative"
                            }}
                          >
                            <View
                              style={{
                                width: "7%",
                                height: "100%"
                              }}
                            >
                              <Text
                                style={[
                                  styles.attributeTask,
                                  { color: "#666666", fontWeight: "bold" }
                                ]}
                              >
                                {index + 1}
                              </Text>
                            </View>
                            <View
                              style={[
                                {
                                  height: "100%",
                                  width: _widthItemTitle(item.tax_id)
                                }
                              ]}
                            >
                              <View>
                                {item.tax_id ? (
                                  <Text style={styles.isTaxText}>Tax</Text>
                                ) : (
                                  <></>
                                )}
                                <Text
                                  numberOfLines={1}
                                  style={[
                                    styles.attributeTask,
                                    {
                                      color: "#666666",
                                      fontWeight: "bold"
                                    }
                                  ]}
                                >
                                  {item.title}
                                </Text>
                                <Text
                                  numberOfLines={1}
                                  style={[
                                    styles.attributeTask,
                                    { color: "#666666", fontWeight: "bold" }
                                  ]}
                                >
                                  {item.categoryName}
                                </Text>
                              </View>
                              <Text style={[styles.attributeTask]}>
                                {formatMoney(item.amount * item.quantity)}
                              </Text>
                            </View>
                            <View
                              style={[
                                {
                                  height: "100%"
                                },
                                isProject &&
                                  budgetType.length > 0 && { width: "45%" }
                              ]}
                            >
                              {isProject &&
                              projectParent &&
                              !item.tax_id &&
                              budgetType.length > 0 ? (
                                <>
                                  <select
                                    style={{
                                      borderWidth: 0,
                                      marginLeft: 4,
                                      marginRight: 4,
                                      height: 30,
                                      borderBottomColor: "#999999",
                                      borderBottomWidth: 1,
                                      backgroundColor: COLORS.main,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      color: "#666666",
                                      fontFamily: "Poppins"
                                    }}
                                    onChange={e => {
                                      // const deletedItems = body.items;
                                      // deletedItems.splice(index, 1);
                                      // _getTempTotal(deletedItems);
                                      // setBody({ ...body, items: deletedItems });

                                      // const tempBody =
                                      const tempItem = body.items;
                                      tempItem[index].budget_id =
                                        e.target.value;
                                      setBody({ ...body, items: tempItem });
                                    }}
                                  >
                                    <option value="" selected hidden>
                                      {t("cash.new.selectBudget")}
                                    </option>
                                    {budgetType.map(
                                      (
                                        budgetTypeData: any,
                                        budgetIndex: number
                                      ) => (
                                        <option
                                          key={budgetIndex}
                                          value={budgetTypeData.id}
                                        >
                                          {budgetTypeData.attributes.title}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {errorSubmit && !item.budget_id ? (
                                    <Text
                                      style={[
                                        styles.validateText,
                                        { marginLeft: 4 }
                                      ]}
                                    >
                                      {t("cash.new.msg.req")}
                                    </Text>
                                  ) : null}
                                </>
                              ) : item.tax_id ? (
                                <View>
                                  <Text
                                    style={
                                      (styles.attributeTask,
                                      { color: "#666666", fontWeight: "bold" })
                                    }
                                  >
                                    {item.tax_category_name}
                                  </Text>
                                  <Text
                                    style={
                                      (styles.attributeTask,
                                      { color: "#666666", fontWeight: "bold" })
                                    }
                                  >
                                    {`${item.tax_rate}%`}
                                  </Text>
                                </View>
                              ) : isProject && !projectParent ? (
                                <Text
                                  style={{
                                    color: "red",
                                    fontFamily: "Poppins",
                                    fontSize: 8,
                                    marginTop: 2,
                                    marginHorizontal: 4
                                  }}
                                >
                                  {sprintf(
                                    t("cash.new.msg.chooseProject"),
                                    teks.task,
                                    teks.project
                                  )}
                                </Text>
                              ) : (
                                <></>
                              )}
                            </View>
                            <View
                              style={{
                                position: "absolute",
                                right: 0,
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row"
                              }}
                            >
                              {body.items[index].file ? (
                                <TouchableOpacity
                                  style={[
                                    styles.card,
                                    {
                                      height: 25,
                                      width: 25,
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }
                                  ]}
                                  onPress={() => previewFile(index)}
                                >
                                  <Image
                                    source={ICONS.file}
                                    style={{ width: 13, height: 16 }}
                                  />
                                </TouchableOpacity>
                              ) : null}

                              <TouchableOpacity
                                onPress={() => {
                                  const deletedItems = body.items;
                                  deletedItems.splice(index, 1);
                                  _getTempTotal(deletedItems);
                                  setBody({ ...body, items: deletedItems });
                                }}
                                style={[
                                  styles.card,
                                  {
                                    height: 25,
                                    width: 25,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: body.items[index].file ? 4 : 0
                                  }
                                ]}
                              >
                                <Image
                                  source={IMAGES.trashBin}
                                  style={{ width: 13, height: 16 }}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
              ) : null}
            </View>

            <Modal
              isVisible={modalIsOpen}
              backdropColor="rgba(255,255,255, 0.8)"
            >
              <View
                style={[
                  styles.card,
                  {
                    backgroundColor: COLORS.main,
                    height: heightPercent(100) - 72,
                    width: widthPercent(100) - 40,
                    borderRadius: 10,
                    alignItems: "center"
                  }
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "#666666",
                    marginTop: 20
                  }}
                >
                  {t("cash.new.newReceipt")}
                </Text>
                <View
                  style={{
                    backgroundColor: COLORS.main,
                    height: 45,
                    width: "100%"
                  }}
                >
                  <View style={{ marginHorizontal: 20 }}>
                    {body.type_id === "pr" && itemType === "product" && (
                      <>
                        <Text style={styles.labelInput}>
                          {t("cash.new.product")}
                        </Text>
                        <select
                          className="new-cash-select"
                          onChange={e => _onChangeProduct(e.target.value)}
                        >
                          <option selected hidden>
                            {t("cash.new.selectProduct")}
                          </option>
                          {populateProduct.map(
                            (itemProduct: any, index: number) => (
                              <option key={index} value={itemProduct.id}>
                                {itemProduct.attributes.title}
                              </option>
                            )
                          )}
                        </select>
                        <Space height={16} />
                      </>
                    )}

                    <Text style={styles.labelInput}>
                      {teks.finance_category}
                    </Text>
                    <select
                      className="new-cash-select"
                      value={bodyItems.category_id}
                      onChange={e => {
                        setBodyItems({
                          ...bodyItems,
                          category_id: e.target.value
                        });
                      }}
                    >
                      <option selected hidden>
                        {sprintf(t("cash.new.select"), teks.finance_category)}
                      </option>
                      {listPopulate.map((expenseType: any, index: number) => (
                        <option key={index} value={expenseType.value}>
                          {expenseType.label}
                        </option>
                      ))}
                    </select>

                    {custom001 && (
                      <View style={{ marginTop: 12 }}>
                        <Text style={styles.labelInput}>Brand</Text>
                        <select
                          className="new-cash-select"
                          onChange={e => {
                            setBodyItems({
                              ...bodyItems,
                              brand_id: e.target.value
                            });
                          }}
                        >
                          <option selected hidden>
                            Select Brand
                          </option>
                          {populateBrand.map((item: any, index: number) => (
                            <option key={index} value={item.id}>
                              {item.attributes.title}
                            </option>
                          ))}
                        </select>
                      </View>
                    )}

                    <Text style={styles.validateText}>
                      {bodyItems.category_id === "" && errorModal
                        ? t("cash.new.msg.req")
                        : null}
                    </Text>
                    <Text style={[styles.labelInput, { marginTop: 20 }]}>
                      {t("cash.new.description")}
                    </Text>

                    <TextArea
                      onChange={e =>
                        setBodyItems({ ...bodyItems, title: e.target.value })
                      }
                      value={bodyItems.title}
                      placeholder={t("cash.new.inputDescription")}
                      bold
                      fontSize="small"
                    />
                    <Text style={styles.validateText}>
                      {bodyItems.title === "" && errorModal
                        ? t("cash.new.msg.req")
                        : null}
                    </Text>
                    {_renderQty(body.type_id) === true && (
                      <>
                        <Text style={[styles.labelInput, { marginTop: 20 }]}>
                          {t("cash.new.qty")}
                        </Text>
                        <Input
                          value={bodyItems.quantity}
                          onChangeText={(e: any) =>
                            setBodyItems({ ...bodyItems, quantity: e })
                          }
                          inputStyle={styles.inputStyle}
                          defaultValue={1}
                        />
                        <Text style={styles.validateText}>
                          {bodyItems.quantity === "" && errorModal
                            ? t("cash.new.msg.req")
                            : null}
                        </Text>
                        <Text style={styles.validateText}>
                          {isNaN(parseInt(bodyItems.quantity)) && errorModal
                            ? t("cash.new.msg.req")
                            : null}
                        </Text>
                      </>
                    )}
                    <Text style={[styles.labelInput, { marginTop: 20 }]}>
                      {t("cash.new.amount")}
                    </Text>
                    <Input
                      value={bodyItems.amount}
                      onChangeText={(e: any) =>
                        setBodyItems({ ...bodyItems, amount: e })
                      }
                      placeholder={t("cash.new.inputAmount")}
                      inputStyle={styles.inputStyle}
                    />
                    <Text style={styles.validateText}>
                      {bodyItems.amount === "" && errorModal
                        ? t("cash.new.msg.req")
                        : null}
                    </Text>
                    <Text style={styles.validateText}>
                      {isNaN(parseInt(bodyItems.amount)) && errorModal
                        ? t("cash.new.msg.reqNum")
                        : null}
                    </Text>
                    <Text style={[styles.labelInput, { marginVertical: 20 }]}>
                      {t("cash.new.receipt")}
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      {bodyItems.file ? (
                        <TouchableOpacity
                          style={[
                            styles.fileAttachment,
                            styles.card,
                            {
                              width: "48%",
                              height: 28,
                              borderRadius: 100,
                              alignItems: "center",
                              justifyContent: "center"
                            }
                          ]}
                          onPress={() => previewFile()}
                        >
                          <View style={{ width: "90%" }}>
                            <View style={{ flexDirection: "row" }}>
                              <View style={{ width: "85%" }}>
                                <Text
                                  numberOfLines={1}
                                  style={styles.attributeTask}
                                >
                                  {bodyItems.fileName}
                                </Text>
                              </View>
                              <TouchableOpacity
                                onPress={() => {
                                  setBodyItems({
                                    ...bodyItems,
                                    file: "",
                                    fileName: ""
                                  });
                                }}
                                style={{
                                  width: "15%",
                                  height: "100%",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <Image
                                  source={ICONS.iconDeleteFile}
                                  resizeMode="contain"
                                  style={{ width: 12, height: 12 }}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </TouchableOpacity>
                      ) : null}
                      <TouchableOpacity style={[styles.buttonUpload]}>
                        <label
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            fontSize: 11
                          }}
                        >
                          <input
                            type="file"
                            // eslint-disable-next-line max-len
                            accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                      application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={(e: any) => {
                              setBodyItems({
                                ...bodyItems,
                                file: e.target.files[0],
                                fileName: e.target.files[0].name
                              });
                            }}
                            onClick={(event: any) => {
                              // eslint-disable-next-line no-param-reassign
                              event.target.value = null;
                            }}
                          />
                          {t("cash.new.attachment")}
                        </label>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <Fixed position="bottom">
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row"
                    }}
                  >
                    <Touchable
                      onPress={e => {
                        e.preventDefault();
                        _resetBodyItems();
                        setModalOpen(false);
                      }}
                    >
                      <View
                        style={[
                          styles.buttonAddItem,
                          {
                            marginBottom: 24,
                            width: 150,
                            height: 45,
                            marginRight: 4
                          }
                        ]}
                      >
                        <Text style={[styles.textButton, { fontSize: 12 }]}>
                          {t("cash.new.cancel")}
                        </Text>
                      </View>
                    </Touchable>
                    <Touchable
                      onPress={() => {
                        const total =
                          bodyItems.amount * bodyItems.quantity + tempTotal;
                        const lisTax = body.items.filter(
                          (item: any) => item.tax_id !== null
                        );

                        if (bodyItems.amount && lisTax.length > 0) {
                          lisTax.forEach((item: any, index: number) => {
                            if (item.tax_type === "pph") {
                              lisTax[index].amount =
                                (total * Number(-item.tax_rate)) / 100;
                            } else if (item.tax_type === "ppn") {
                              lisTax[index].amount =
                                (total * Number(item.tax_rate)) / 100;
                            }
                          });
                        }

                        if (
                          bodyItems.amount &&
                          bodyItems.amount > 0 &&
                          bodyItems.category_id &&
                          bodyItems.title
                        ) {
                          setBody({
                            ...body,
                            items: [...body.items, bodyItems]
                          });
                          _resetBodyItems();
                          setModalOpen(false);
                          setErrorModal(false);
                        } else {
                          setErrorModal(true);
                        }
                      }}
                    >
                      <View
                        style={[
                          styles.button,
                          { marginBottom: 24, width: 150, height: 45 }
                        ]}
                      >
                        <Text style={[styles.textButton, { fontSize: 12 }]}>
                          {t("cash.new.add")}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                </Fixed>
              </View>
            </Modal>
          </View>
          <Fixed position="bottom">
            <View style={styles.wrapBtn}>
              {body.type_id !== "ca" && body.type_id && (
                <View style={styles.wrapBtnRow}>
                  <Touchable
                    onPress={() => {
                      setModalOpen(true);
                      _getTempTotal();
                    }}
                  >
                    <View style={[styles.btnAddItem]}>
                      <Text style={[styles.textButton, { fontSize: 12 }]}>
                        {t("cash.new.addReceipt")}
                      </Text>
                    </View>
                  </Touchable>
                  <Touchable
                    disabled={body.items.length === 0}
                    onPress={() => {
                      const bodyTax = body.items.filter(
                        (el: any) => el.tax_id !== null
                      );

                      if (bodyTax.length >= listTax.length) {
                        toast.error(t("cash.new.allTaxAlreadyUse"));
                      } else {
                        setModalAddTax(true);
                        _getTempTotal();
                      }
                    }}
                  >
                    <View
                      style={[
                        styles.btnAddItem,
                        body.items.length === 0 && { opacity: 0.4 }
                      ]}
                    >
                      <Text style={[styles.textButton, { fontSize: 12 }]}>
                        {t("cash.new.addTax")}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              )}
              <Touchable
                disabled={isLoadingNewGeneralExpense}
                onPress={() => _handleSubmit()}
              >
                <View
                  style={[
                    styles.button,
                    {
                      marginBottom: 24,
                      width: widthPercent(100) - 48,
                      height: 45
                    }
                  ]}
                >
                  {isLoadingNewGeneralExpense ? (
                    <ActivityIndicator color="#fff" />
                  ) : (
                    <Text style={[styles.textButton, { fontSize: 12 }]}>
                      {t("cash.new.submit")}
                    </Text>
                  )}
                </View>
              </Touchable>
            </View>
          </Fixed>
        </View>
      ) : null}
      <Modal isVisible={modalSubmitForm} backdropColor="rgba(255,255,255, 0.9)">
        <ModalConfirmForm
          data={dataConfirmSubmit}
          items={body.items}
          onPress={_handleDispatchPost}
          onCancel={() => setModalSubmitForm(false)}
          isLoading={isLoadingNewGeneralExpense}
        />
      </Modal>
      <Modal isVisible={modalAddTax}>
        <View style={[styles.card, styles.cardTax]}>
          <Text style={styles.text} onPress={() => setModalAddTax(false)}>
            {t("cash.new.addTax")}
          </Text>

          <select
            style={{
              borderWidth: 0,
              height: 40,
              borderBottomColor: "#999999",
              borderBottomWidth: 1,
              backgroundColor: COLORS.main,
              fontSize: 12,
              fontWeight: "bold",
              color: "#666666",
              fontFamily: "Poppins",
              width: "100%",
              marginTop: 12
            }}
            onChange={e => {
              const item = listTax.find(value => value.id === e.target.value);
              const indexTax = body.items.findIndex(
                (el: any) => el.tax_id === e.target.value
              );

              let amount = 0;
              if (item.type === "pph") {
                amount = (tempTotal * Number(-item.rate)) / 100;
              } else if (item.type === "ppn") {
                amount = (tempTotal * Number(item.rate)) / 100;
              }

              if (indexTax >= 0) {
                setErrorModal(true);
                _resetBodyItems();
              } else {
                setErrorModal(false);
                setBodyItems({
                  ...bodyItems,
                  title: item.title,
                  category_id: item.category.id,
                  tax_id: item.id,
                  tax_rate: item.rate,
                  tax_type: item.type,
                  tax_category_name: item.category.title,
                  amount
                });
              }
            }}
          >
            <option selected hidden>
              {sprintf(t("cash.new.selectTax"), teks.finance_category)}
            </option>
            {listTax.map((item: any, index: number) => (
              <option key={index} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
          {bodyItems.tax_rate && (
            <View style={{ alignSelf: "flex-start", marginTop: 12 }}>
              <Text style={styles.text12}>
                {`${t("cash.new.taxValue")}: ${bodyItems.tax_rate}%`}
              </Text>
              {/* <Text style={[styles.text12, { marginTop: 5 }]}>
                {`Tax Amount: ${formatMoney(bodyItems.amount)}`}
              </Text> */}
            </View>
          )}
          {bodyItems.tax_id === null && errorModal && (
            <View style={{ alignSelf: "flex-start", marginTop: 12 }}>
              <Text style={[styles.text12, { color: COLORS.danger }]}>
                {t("cash.new.taxAlreadyUse")}
              </Text>
            </View>
          )}
          <Fixed position="bottom">
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                alignSelf: "center"
              }}
            >
              <Touchable
                onPress={e => {
                  e.preventDefault();
                  _resetBodyItems();
                  setModalAddTax(false);
                  setErrorModal(false);
                }}
              >
                <View
                  style={[
                    styles.buttonAddItem,
                    {
                      marginBottom: 24,
                      width: 150,
                      height: 45,
                      marginRight: 4
                    }
                  ]}
                >
                  <Text style={[styles.textButton, { fontSize: 12 }]}>
                    {t("cash.new.cancel")}
                  </Text>
                </View>
              </Touchable>
              <Touchable
                onPress={() => {
                  if (
                    bodyItems.category_id &&
                    bodyItems.title &&
                    bodyItems.tax_id
                  ) {
                    setBody({
                      ...body,
                      items: [...body.items, bodyItems]
                    });
                    _resetBodyItems();
                    setModalAddTax(false);
                    setErrorModal(false);
                  } else {
                    setErrorModal(true);
                  }
                }}
              >
                <View
                  style={[
                    styles.button,
                    { marginBottom: 24, width: 150, height: 45 }
                  ]}
                >
                  <Text style={[styles.textButton, { fontSize: 12 }]}>
                    {t("cash.new.add")}
                  </Text>
                </View>
              </Touchable>
            </View>
          </Fixed>
        </View>
      </Modal>
    </View>
  );
};

export default NewCash;
