import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  insideCard: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 21,
    paddingVertical: 3
  },
  columnData: {
    width: "25%"
  },
  columnValue: {
    width: "75%"
  },
  keyData: {
    color: "#999999",
    fontSize: 11,
    fontFamily: "Poppins"
  },
  valueData: {
    color: "#666666",
    fontSize: 11,
    fontFamily: "Poppins",
    fontWeight: "bold"
  }
});

export default styles;
