import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

interface Props {
  children: ReactNode;
}

const styles = StyleSheet.create({
  wrapButton: {
    width: "100%",
    alignItems: "center",
    marginVertical: 33
  }
});

const WrapButton = ({ children }: Props) => (
  <View style={styles.wrapButton}>{children}</View>
);

export default WrapButton;
