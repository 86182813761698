import {
  GET_REGION_ERRROR,
  GET_REGION_PENDING,
  GET_REGION_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, RegionState } from "../types";

const initialState: RegionState = {
  region: [],
  isLoadingRegion: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_REGION_PENDING:
      return { ...state, isLoadingRegion: true };
    case GET_REGION_SUCCESS:
      return { ...state, isLoadingRegion: false, region: payload.data };
    case GET_REGION_ERRROR:
      return { ...state, isLoadingRegion: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
