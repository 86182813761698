import {
  GET_AIRPORT_ERROR,
  GET_AIRPORT_PENDING,
  GET_AIRPORT_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_COUNTRY_PENDING,
  GET_COUNTRY_SUCCESS,
  GET_REGENCY_ERROR,
  GET_REGENCY_PENDING,
  GET_REGENCY_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, RegencyState } from "../types";

const initialState: RegencyState = {
  regencies: [],
  isLoadingRegency: false,
  countries: [],
  isLoadingCountries: false,
  departureAirports: [],
  arrivalAirports: [],
  isLoadingAirports: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_REGENCY_PENDING:
      return { ...state, isLoadingRegency: true };
    case GET_REGENCY_SUCCESS:
      return { ...state, isLoadingRegency: false, regencies: payload.data };
    case GET_REGENCY_ERROR:
      return { ...state, isLoadingRegency: false };

    case GET_COUNTRY_PENDING:
      return { ...state, isLoadingCountries: true };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoadingCountries: false,
        countries: payload.data.data
      };
    case GET_COUNTRY_ERROR:
      return { ...state, isLoadingCountries: false };

    case GET_AIRPORT_PENDING:
      return { ...state, isLoadingAirports: true };
    case GET_AIRPORT_SUCCESS:
      return {
        ...state,
        isLoadingAirports: false,
        arrivalAirports:
          payload.data.type === "arrival"
            ? payload.data.data
            : state.arrivalAirports,
        departureAirports:
          payload.data.type === "departure"
            ? payload.data.data
            : state.departureAirports
      };
    case GET_AIRPORT_ERROR:
      return { ...state, isLoadingAirports: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
