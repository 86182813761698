import React, { useState } from "react";
import { FlatList, Image, Text, TextInput, View } from "react-native";

import { Button, Card, Datepicker, Header, Touchable } from "../../components";
import { IMAGES } from "../../configs";
import styles from "./styles";

const FormHotel = () => {
  const [star, setStar] = useState([
    { rating: 1, active: true },
    { rating: 2, active: true },
    { rating: 3, active: true },
    { rating: 4, active: true },
    { rating: 5, active: false }
  ]);
  // nilai rating
  // const [inputRating, setInputRating] = useState(4);

  const typeDateTime: any = [
    { date: "2020-01-28", time: "02:02" },
    { date: "2020-02-20", time: "01:02" }
  ];

  const [dateTime, setDateTime] = useState(typeDateTime);
  const [facilities, setFacility] = useState([
    { facility: "Breakfast", active: false },
    { facility: "Lunch", active: false },
    { facility: "Dinner", active: false },
    { facility: "Swimming Pool", active: false },
    { facility: "Gym", active: false },
    { facility: "Meeting Room", active: false }
  ]);

  const [inputFacility, setInputFacility] = useState([]);

  const setRating = (rating: number) => {
    let updatedRating: any[] = [];
    const maxRating = 5;
    for (let index = 0; index < maxRating; index += 1) {
      if (rating >= index) {
        updatedRating = [...updatedRating, { rating: index + 1, active: true }];
      } else {
        updatedRating = [
          ...updatedRating,
          { rating: index + 1, active: false }
        ];
      }
    }
    // ambil nilai rating
    // setInputRating(rating + 1);
    setStar(updatedRating);
  };

  const handleFacilityInput = (index: number, facility: string) => {
    const facilityData = facilities;

    if (facilityData[index].active) {
      setInputFacility(inputFacility.filter(item => item !== facility));
    } else {
      const input: any = [...inputFacility, facility];
      setInputFacility(input);
    }
    facilityData[index].active = !facilityData[index].active;
    setFacility(facilityData);
  };

  const getMonth = (m: number) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[m];
  };

  const dateFormated = (date: string) => {
    const dt = new Date(date);
    return `${dt.getDate()} ${getMonth(dt.getMonth())} ${dt.getFullYear()}`;
  };

  return (
    <View>
      <Header title="New Booking" subtitle="Hotel" />
      <View style={styles.form}>
        <View style={styles.row}>
          <Text
            style={[
              styles.label,
              {
                fontFamily: "Poppins",
                color: "#666666"
              }
            ]}
          >
            City
          </Text>
          <TextInput style={[{ paddingVertical: 10 }, styles.price]} />
        </View>
        <View style={styles.row}>
          <Text
            style={[
              styles.label,
              {
                fontFamily: "Poppins",
                color: "#666666"
              }
            ]}
          >
            Region
          </Text>
          <TextInput style={[{ paddingVertical: 10 }, styles.price]} />
        </View>
        <View />
      </View>
      <Text
        style={[
          styles.labelRating,
          {
            fontFamily: "Poppins",
            color: "#666666"
          }
        ]}
      >
        Rating
      </Text>
      <View style={styles.star}>
        {star.map((item, index) => {
          return (
            <Touchable key={index} onPress={() => setRating(index)}>
              {item.active ? (
                <Image source={IMAGES.star} style={{ height: 25, width: 25 }} />
              ) : (
                <Image
                  source={IMAGES.stargrey}
                  style={{ height: 25, width: 25 }}
                />
              )}
            </Touchable>
          );
        })}
      </View>
      <View style={styles.form}>
        <View style={styles.row}>
          <Text
            style={[
              styles.label,
              {
                fontFamily: "Poppins",
                color: "#666666"
              }
            ]}
          >
            Min. Price
          </Text>
          <TextInput style={styles.price} />
        </View>
        <View style={styles.row}>
          <Text
            style={[
              styles.label,
              {
                fontFamily: "Poppins",
                color: "#666666"
              }
            ]}
          >
            Max. Price
          </Text>
          <TextInput style={styles.price} />
        </View>
      </View>
      <Text
        style={[
          styles.label,
          { paddingLeft: 25, marginTop: 30.5 },
          {
            fontFamily: "Poppins",
            color: "#666666"
          }
        ]}
      >
        Facilities
      </Text>
      <FlatList
        style={{ paddingHorizontal: 17 }}
        numColumns={2}
        data={facilities}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => (
          <View
            style={[
              styles.buttonFacility,
              item.active && { backgroundColor: "#50b1b1" }
            ]}
          >
            <Touchable
              onPress={() => handleFacilityInput(index, item.facility)}
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <Text
                  style={[
                    item.active ? { color: "white" } : { color: "#666666" },
                    {
                      fontFamily: "Poppins",
                      color: "#666666"
                    }
                  ]}
                >
                  {item.facility}
                </Text>
              </View>
            </Touchable>
          </View>
        )}
      />
      <View style={styles.form}>
        <View style={{ width: "50%" }}>
          <Text
            style={[
              styles.label,
              {
                fontFamily: "Poppins",
                color: "#666666"
              }
            ]}
          >
            Check-in
          </Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text
            style={[
              styles.label,
              {
                fontFamily: "Poppins",
                color: "#666666"
              }
            ]}
          >
            Check-out
          </Text>
        </View>
        <View />
      </View>
      <View style={{ paddingHorizontal: 25, zIndex: 9999 }}>
        <Datepicker
          validate
          highlight
          value={dateTime}
          timepicker
          onChange={e => setDateTime(e)}
          head={(nav, setNav) => (
            <View style={styles.datePickerNavWrap}>
              <View
                style={[
                  styles.datePickerNav,
                  nav === 0 ? styles.datePickerNavActive : {}
                ]}
              >
                <Touchable onPress={() => (setNav ? setNav(0) : null)}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Text style={{ color: "#fff", fontWeight: "bold" }}>
                      Check In
                    </Text>
                  </View>
                </Touchable>
              </View>
              <View
                style={[
                  styles.datePickerNav,
                  nav === 1 ? styles.datePickerNavActive : {}
                ]}
              >
                <Touchable onPress={() => (setNav ? setNav(1) : null)}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Text style={{ color: "#fff", fontWeight: "bold" }}>
                      Check Out
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          )}
        >
          <Card>
            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "50%" }}>
                <Text
                  style={[
                    styles.datePicker,
                    {
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#666666"
                    }
                  ]}
                >
                  {dateFormated(dateTime[0].date)}
                </Text>
                <Text
                  style={[
                    styles.datePicker,
                    {
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#666666"
                    }
                  ]}
                >
                  {dateTime[0].time}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text
                  style={[
                    styles.datePicker,
                    {
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#666666"
                    }
                  ]}
                >
                  {dateFormated(dateTime[1].date)}
                </Text>
                <Text
                  style={[
                    styles.datePicker,
                    {
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#666666"
                    }
                  ]}
                >
                  {dateTime[1].time}
                </Text>
              </View>
            </View>
          </Card>
        </Datepicker>
      </View>
      <Button
        title="Search Hotel"
        style={{
          paddingHorizontal: 50,
          marginVertical: 30,
          alignItems: "center"
        }}
      />
    </View>
  );
};

export default FormHotel;
