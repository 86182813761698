import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  main: {
    backgroundColor: COLORS.main
  },
  container: {
    paddingTop: 25,
    paddingBottom: 5,
    paddingHorizontal: 20
  },
  tripNav: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 80,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    height: "fit-content",
    width: "fir-content"
  },
  active: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    flex: 1
  },
  btnNav: {
    height: 36,
    flex: 1,
    borderRadius: 100
  },
  btnText: {
    fontWeight: "bold",
    fontFamily: "poppins",
    fontSize: 14,
    color: "#ffffff"
  },
  footer: {
    flex: 1,
    alignItems: "center",
    height: 146,
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)"
  },
  containerTotalAmount: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    marginBottom: 20,
    marginTop: 18
  },
  btnSave: {
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    width: 125,
    height: 54,
    marginRight: 8,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center"
  },
  textBtn: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18
  },
  btnNext: {
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28, #db3a26)",
    width: 125,
    height: 54,
    marginLeft: 8,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
