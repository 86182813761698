import {
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENT_PENDING,
  GET_DEPARTMENT_SUCCESS,
  GET_EMPLOYEE_ERROR,
  GET_EMPLOYEE_PENDING,
  GET_EMPLOYEE_SUCCESS,
  GET_LIST_EMPLOYEE_ERROR,
  GET_LIST_EMPLOYEE_PENDING,
  GET_LIST_EMPLOYEE_SUCCESS,
  GET_MY_FULL_PROFILE_ERROR,
  GET_MY_FULL_PROFILE_PENDING,
  GET_MY_FULL_PROFILE_SUCCESS,
  GET_POSITION_ERROR,
  GET_POSITION_PENDING,
  GET_POSITION_SUCCESS,
  GET_REQUEST_EMPLOYEE_ERROR,
  GET_REQUEST_EMPLOYEE_PENDING,
  GET_REQUEST_EMPLOYEE_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_LIST_EMPLOYEE
} from "../actions";
import { Action, EmployeeState } from "../types";

const initialState: EmployeeState = {
  listEmployee: [],
  isLoadingListEmployee: false,
  myFullProfile: null,
  isLoadingGetMyFullProfile: false,
  department: null,
  isLoadingDepartment: false,
  position: [],
  isLoadingPosition: false,
  listEmployeeV2: [],
  listRequestEmployee: [],
  isLoadingListEmployeeV2: false,
  listPosition: [],
  isLoadingListPosition: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // General
    case GET_LIST_EMPLOYEE_PENDING:
      return { ...state, isLoadingListEmployee: true };
    case GET_LIST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingListEmployee: false,
        listEmployee: payload.data
      };
    case GET_LIST_EMPLOYEE_ERROR:
      return { ...state, isLoadingListEmployee: false };

    case GET_MY_FULL_PROFILE_ERROR:
      return { ...state, isLoadingGetMyFullProfile: false };
    case GET_MY_FULL_PROFILE_PENDING:
      return { ...state, isLoadingGetMyFullProfile: true };
    case GET_MY_FULL_PROFILE_SUCCESS:
      return {
        ...state,
        isLoadingGetMyFullProfile: false,
        myFullProfile: payload.data
      };

    case GET_DEPARTMENT_ERROR:
      return { ...state, isLoadingDepartment: false };
    case GET_DEPARTMENT_PENDING:
      return { ...state, isLoadingDepartment: true };
    case GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoadingDepartment: false,
        department: payload.data
      };

    case GET_POSITION_ERROR:
      return { ...state, isLoadingPosition: false };
    case GET_POSITION_PENDING:
      return { ...state, isLoadingPosition: true };
    case GET_POSITION_SUCCESS:
      return {
        ...state,
        isLoadingPosition: false,
        position: payload.data
      };
    case GET_EMPLOYEE_PENDING:
      return { ...state, isLoadingListEmployeeV2: true, listEmployeeV2: [] };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingListEmployeeV2: false,
        listEmployeeV2: payload.data
      };
    case GET_EMPLOYEE_ERROR:
      return { ...state, isLoadingListEmployeeV2: false };

    case GET_REQUEST_EMPLOYEE_PENDING:
      return { ...state, isLoadingListEmployeeRequest: true };
    case GET_REQUEST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingListEmployeeRequest: false,
        listRequestEmployee: payload.data.data
      };
    case GET_REQUEST_EMPLOYEE_ERROR:
      return { ...state, isLoadingListEmployeeRequest: false };

    case REFRESH_LIST_EMPLOYEE:
      return {
        ...state,
        listEmployee: [...payload.data]
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
