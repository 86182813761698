import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_NOTIFICATION_PENDING = "GET_NOTIFICATION_PENDING";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

declare const alert: (param: any) => void;

export const getNotification = (loadMore = false) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, notification } = getState();
    dispatch({ type: GET_NOTIFICATION_PENDING });

    const res = await API.getNotification(
      !loadMore ? { ...notification.params, page: 1 } : notification.params,
      auth.token
    );
    dispatch({
      type: GET_NOTIFICATION_SUCCESS,
      payload: { data: res.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getNotification())));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: GET_NOTIFICATION_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_NOTIFICATION_ERROR });
  }
};
