import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from "./auth";

export const GET_EXPENSE_LIST_APPROVAL_PENDING =
  "GET_EXPENSE_LIST_APPROVAL_PENDING";
export const GET_EXPENSE_LIST_APPROVAL_SUCCESS =
  "GET_EXPENSE_LIST_APPROVAL_SUCCESS";
export const GET_EXPENSE_LIST_APPROVAL_ERROR =
  "GET_EXPENSE_LIST_APPROVAL_ERROR";

export const getExpenseListApproval = (loadMore = false) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, expense } = getState();
    dispatch({
      type: GET_EXPENSE_LIST_APPROVAL_PENDING,
      payload: { loadMore }
    });

    const res = await API.getExpenseListApproval(
      !loadMore ? { ...expense.paramsGeneral, page: 1 } : expense.paramsGeneral,
      auth.token
    );
    dispatch({
      type: GET_EXPENSE_LIST_APPROVAL_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getExpenseListApproval(loadMore))));
      } else {
        dispatch({
          type: GET_EXPENSE_LIST_APPROVAL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_EXPENSE_LIST_APPROVAL_ERROR });
  }
};
