import { Slide, toast } from "react-toastify";

// import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from "./auth";

export const GET_TASK_LIST_PENDING = "GET_TASK_LIST_PENDING";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_ERROR = "GET_TASK_LIST_ERROR";

export const GET_TASK_LIST_DROPDOWN_PENDING = "GET_TASK_LIST_DROPDOWN_PENDING";
export const GET_TASK_LIST_DROPDOWN_SUCCESS = "GET_TASK_LIST_DROPDOWN_SUCCESS";
export const GET_TASK_LIST_DROPDOWN_ERROR = "GET_TASK_LIST_DROPDOWN_ERROR";

// move from detailTask state reducer
export const GET_DETAIL_TASK_PENDING = "GET_DETAIL_TASK_PENDING";
export const GET_DETAIL_TASK_SUCCESS = "GET_DETAIL_TASK_SUCCESS";
export const GET_DETAIL_TASK_ERROR = "GET_DETAIL_TASK_ERROR";

export const GET_DETAIL_TASK_REPORT_PENDING = "GET_DETAIL_TASK_REPORT_PENDING";
export const GET_DETAIL_TASK_REPORT_SUCCESS = "GET_DETAIL_TASK_REPORT_SUCCESS";
export const GET_DETAIL_TASK_REPORT_ERROR = "GET_DETAIL_TASK_REPORT_ERROR";

export const SET_TASK_DETAIL_REPORT_WANT_EDIT =
  "SET_TASK_DETAIL_REPORT_WANT_EDIT";

export const GET_DETAIL_TASK_REMARKS_PENDING =
  "GET_DETAIL_TASK_REMARKS_PENDING";
export const GET_DETAIL_TASK_REMARKS_SUCCESS =
  "GET_DETAIL_TASK_REMARKS_SUCCESS";
export const GET_DETAIL_TASK_REMARKS_ERROR = "GET_DETAIL_TASK_REMARKS_ERROR";

export const DELETE_FILE_DETAIL_TASK_PENDING =
  "DELETE_FILE_DETAIL_TASK_PENDING";
export const DELETE_FILE_DETAIL_TASK_SUCCESS =
  "DELETE_FILE_DETAIL_TASK_SUCCESS";
export const DELETE_FILE_DETAIL_TASK_ERROR = "DELETE_FILE_DETAIL_TASK_ERROR";

export const SUBMIT_END_TASK_PENDING = "SUBMIT_END_TASK_PENDING";
export const SUBMIT_END_TASK_SUCCESS = "SUBMIT_END_TASK_SUCCESS";
export const SUBMIT_END_TASK_ERROR = "SUBMIT_END_TASK_ERROR";

export const UPLOAD_FILE_DETAIL_TASK_PENDING =
  "UPLOAD_FILE_DETAIL_TASK_PENDING";
export const UPLOAD_FILE_DETAIL_TASK_SUCCESS =
  "UPLOAD_FILE_DETAIL_TASK_SUCCESS";
export const UPLOAD_FILE_DETAIL_TASK_ERROR = "UPLOAD_FILE_DETAIL_TASK_ERROR";

export const UPLOAD_FILE_DETAIL_TASK_REPORT_PENDING =
  "UPLOAD_FILE_DETAIL_TASK_REPORT_PENDING";
export const UPLOAD_FILE_DETAIL_TASK_REPORT_SUCCESS =
  "UPLOAD_FILE_DETAIL_TASK_REPORT_SUCCESS";
export const UPLOAD_FILE_DETAIL_TASK_REPORT_ERROR =
  "UPLOAD_FILE_DETAIL_TASK_REPORT_ERROR";

export const POST_TASK_DETAIL_REPORT_PENDING =
  "POST_TASK_DETAIL_REPORT_PENDING";
export const POST_TASK_DETAIL_REPORT_SUCCESS =
  "POST_TASK_DETAIL_REPORT_SUCCESS";
export const POST_TASK_DETAIL_REPORT_ERROR = "POST_TASK_DETAIL_REPORT_ERROR";

export const PUT_TASK_DETAIL_REPORT_PENDING = "PUT_TASK_DETAIL_REPORT_PENDING";
export const PUT_TASK_DETAIL_REPORT_SUCCESS = "PUT_TASK_DETAIL_REPORT_SUCCESS";
export const PUT_TASK_DETAIL_REPORT_ERROR = "PUT_TASK_DETAIL_REPORT_ERROR";

export const SUBMIT_TASK_DETAIL_REPORT_PENDING =
  "SUBMIT_TASK_DETAIL_REPORT_PENDING";
export const SUBMIT_TASK_DETAIL_REPORT_SUCCESS =
  "SUBMIT_TASK_DETAIL_REPORT_SUCCESS";
export const SUBMIT_TASK_DETAIL_REPORT_ERROR =
  "SUBMIT_TASK_DETAIL_REPORT_ERROR";

export const EXTENDS_DAYS_DETAIL_TASK_PENDING =
  "EXTENDS_DAYS_DETAIL_TASK_PENDING";
export const EXTENDS_DAYS_DETAIL_TASK_SUCCESS =
  "EXTENDS_DAYS_DETAIL_TASK_SUCCESS";
export const EXTENDS_DAYS_DETAIL_TASK_ERROR = "EXTENDS_DAYS_DETAIL_TASK_ERROR";

export const POST_DETAIL_TASK_REMARKS_PENDING =
  "POST_DETAIL_TASK_REMARKS_PENDING";
export const POST_DETAIL_TASK_REMARKS_SUCCESS =
  "POST_DETAIL_TASK_REMARKS_SUCCESS";
export const POST_DETAIL_TASK_REMARKS_ERROR = "POST_DETAIL_TASK_REMARKS_ERROR";

export const GET_AVAILABLE_DATE_TASK_REPORT_PENDING =
  "GET_AVAILABLE_DATE_TASK_REPORT_PENDING";
export const GET_AVAILABLE_DATE_TASK_REPORT_SUCCESS =
  "GET_AVAILABLE_DATE_TASK_REPORT_SUCCESS";
export const GET_AVAILABLE_DATE_TASK_REPORT_ERROR =
  "GET_AVAILABLE_DATE_TASK_REPORT_ERROR";

export const EDIT_DETAIL_TASK_REPORT_PENDING =
  "EDIT_DETAIL_TASK_REPORT_PENDING";
export const EDIT_DETAIL_TASK_REPORT_SUCCESS =
  "EDIT_DETAIL_TASK_REPORT_SUCCESS";
export const EDIT_DETAIL_TASK_REPORT_ERROR = "EDIT_DETAIL_TASK_REPORT_ERROR";

export const DELETE_FILE_TASK_REPORT_PENDING =
  "DELETE_FILE_TASK_REPORT_PENDING";
export const DELETE_FILE_TASK_REPORT_SUCCESS =
  "DELETE_FILE_TASK_REPORT_SUCCESS";
export const DELETE_FILE_TASK_REPORT_ERROR = "DELETE_FILE_TASK_REPORT_ERROR";

export const POST_TASK_PENDING = "POST_TASK_PENDING";
export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS";
export const POST_TASK_ERROR = "POST_TASK_ERROR";

export const PUT_TASK_EDIT_PENDING = "PUT_TASK_EDIT_PENDING";
export const PUT_TASK_EDIT_SUCCESS = "PUT_TASK_EDIT_SUCCESS";
export const PUT_TASK_EDIT_ERROR = "PUT_TASK_EDIT_ERROR";

export const PUT_TASK_EDIT_RECURRING_PENDING =
  "PUT_TASK_EDIT_RECURRING_PENDING";
export const PUT_TASK_EDIT_RECURRING_SUCCESS =
  "PUT_TASK_EDIT_RECURRING_SUCCESS";
export const PUT_TASK_EDIT_RECURRING_ERROR = "PUT_TASK_EDIT_RECURRING_ERROR";

export const POST_ACTIVATE_TASK_PENDING = "POST_ACTIVATE_TASK_PENDING";
export const POST_ACTIVATE_TASK_SUCCESS = "POST_ACTIVATE_TASK_SUCCESS";
export const POST_ACTIVATE_TASK_ERROR = "POST_ACTIVATE_TASK_ERROR";

export const POST_TASK_REQUEST_PENDING = "POST_TASK_REQUEST_PENDING";
export const POST_TASK_REQUEST_SUCCESS = "POST_TASK_REQUEST_SUCCESS";
export const POST_TASK_REQUEST_ERROR = "POST_TASK_REQUEST_ERROR";

export const PUT_TASK_PENDING = "PUT_TASK_PENDING";
export const PUT_TASK_SUCCESS = "PUT_TASK_SUCCESS";
export const PUT_TASK_ERROR = "PUT_TASK_ERROR";

export const PUT_TASK_MODERATION_PENDING = "PUT_TASK_MODERATION_PENDING";
export const PUT_TASK_MODERATION_SUCCESS = "PUT_TASK_MODERATION_SUCCESS";
export const PUT_TASK_MODERATION_ERROR = "PUT_TASK_MODERATION_ERROR";

export const SET_ADD_PARTICIPANT = "SET_ADD_PARTICIPANT";
export const SET_DELETE_PARTICIPANT = "SET_DELETE_PARTICIPANT";
export const SAVE_ADD_PARTICIPANT = "SAVE_ADD_PARTICIPANT";
export const RESET_PARTICIPANT = "RESET_PARTICIPANT";
export const RESET_DETAIL_TASK = "RESET_DETAIL_TASK";
export const GET_ADD_PARTICIPANT = "GET_ADD_PARTICIPANT";

export const SET_MODAL_RECURRING_TASK = "SET_MODAL_RECURRING_TASK";
export const SET_SEARCH_TASK_VALUE = "SET_SEARCH_TASK_VALUE";
export const RESET_SEARCH_TASK_VALUE = "RESET_SEARCH_TASK_VALUE";

export const getTaskList = (
  status: string,
  loadMore = false,
  q?: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth, task } = getState();
    dispatch({ type: GET_TASK_LIST_PENDING, payload: { loadMore } });

    const res = await API.getTaskList(
      status,
      !loadMore ? { ...task.paramTaskList, page: 1 } : task.paramTaskList,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TASK_LIST_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTaskList(status, loadMore))));
      } else {
        dispatch({
          type: GET_TASK_LIST_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_TASK_LIST_ERROR });
  }
};

export const getDetailTask = (taskId: string | undefined) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DETAIL_TASK_PENDING });

    const res = await API.getDetailTask(taskId, auth.token);
    dispatch({
      type: GET_DETAIL_TASK_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDetailTask(taskId))));
      } else {
        dispatch({
          type: GET_DETAIL_TASK_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_DETAIL_TASK_ERROR });
  }
};

export const getAvailableDateTaskReport = (
  taskId: string | undefined,
  userId: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_AVAILABLE_DATE_TASK_REPORT_PENDING });

    const res = await API.getAvailableDateTaskReport(
      taskId,
      userId,
      auth.token
    );
    dispatch({
      type: GET_AVAILABLE_DATE_TASK_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(getAvailableDateTaskReport(taskId, userId))
          )
        );
      } else {
        dispatch({
          type: GET_AVAILABLE_DATE_TASK_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_AVAILABLE_DATE_TASK_REPORT_ERROR });
  }
};

export const getDetailTaskRemarks = (taskId: string | undefined) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DETAIL_TASK_REMARKS_PENDING });

    const res = await API.getDetailTaskRemarks(taskId, auth.token);
    dispatch({
      type: GET_DETAIL_TASK_REMARKS_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDetailTaskRemarks(taskId))));
      } else {
        dispatch({
          type: GET_DETAIL_TASK_REMARKS_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_DETAIL_TASK_ERROR });
  }
};

export const uploadFileDetailTask = (
  taskId: string,
  fileName: string,
  file: any
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: UPLOAD_FILE_DETAIL_TASK_PENDING });
    const formDataFileCollection = new FormData();
    formDataFileCollection.append("file[0]", file);

    const res = await API.uploadFileDetailTask(
      taskId,
      formDataFileCollection,
      auth.token
    );
    dispatch({
      type: UPLOAD_FILE_DETAIL_TASK_SUCCESS,
      payload: { data: { fileName, file, res } }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(uploadFileDetailTask(taskId, fileName, file))
          )
        );
      } else {
        dispatch({
          type: UPLOAD_FILE_DETAIL_TASK_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: UPLOAD_FILE_DETAIL_TASK_ERROR });
  }
};

export const setTaskDetailReportWantEdit = (
  taskDetailReportData: any
) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_TASK_DETAIL_REPORT_WANT_EDIT,
    payload: { data: taskDetailReportData }
  });
};

export const uploadFileDetailTaskReport = (
  reportId: string,
  file: File,
  cb: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: UPLOAD_FILE_DETAIL_TASK_REPORT_PENDING });

    const formDataFileCollection = new FormData();

    formDataFileCollection.append("file", file);
    await API.uploadFileDetailTaskReport(
      reportId,
      formDataFileCollection,
      auth.token
    );

    dispatch({
      type: UPLOAD_FILE_DETAIL_TASK_REPORT_SUCCESS
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(uploadFileDetailTaskReport(reportId, file, cb))
          )
        );
      } else {
        dispatch({
          type: UPLOAD_FILE_DETAIL_TASK_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: UPLOAD_FILE_DETAIL_TASK_ERROR });
  }
};

export const deleteFileDetailTask = (
  taskId: string,
  fileId: string,
  index: number
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_FILE_DETAIL_TASK_PENDING });

    await API.deleteFileDetailTask(taskId, fileId, auth.token);
    dispatch({
      type: DELETE_FILE_DETAIL_TASK_SUCCESS,
      payload: { data: index }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(deleteFileDetailTask(taskId, fileId, index))
          )
        );
      } else {
        dispatch({
          type: DELETE_FILE_DETAIL_TASK_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: DELETE_FILE_DETAIL_TASK_ERROR });
  }
};

export const getDetailTaskReport = (
  taskId: string | undefined,
  userId: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DETAIL_TASK_REPORT_PENDING });

    const res = await API.getDetailTaskReport(taskId, userId, auth.token);
    dispatch({
      type: GET_DETAIL_TASK_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getDetailTaskReport(taskId, userId)))
        );
      } else {
        dispatch({
          type: GET_DETAIL_TASK_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_DETAIL_TASK_REPORT_ERROR });
  }
};

export const extendDaysDetailTask = (
  taskId: string,
  days: number,
  expectedLastDay: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: EXTENDS_DAYS_DETAIL_TASK_PENDING });

    await API.extendDaysDetailTask(taskId, { days }, auth.token);
    dispatch({
      type: EXTENDS_DAYS_DETAIL_TASK_SUCCESS,
      payload: { data: expectedLastDay }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(extendDaysDetailTask(taskId, days, expectedLastDay))
          )
        );
      } else {
        dispatch({
          type: EXTENDS_DAYS_DETAIL_TASK_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: EXTENDS_DAYS_DETAIL_TASK_ERROR });
  }
};

export const postDetailTaskRemarks = (
  taskId: string,
  comment: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_DETAIL_TASK_REMARKS_PENDING });

    const res = await API.postDetailTaskRemarks(
      taskId,
      { comment },
      auth.token
    );
    dispatch({
      type: POST_DETAIL_TASK_REMARKS_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(postDetailTaskRemarks(taskId, comment)))
        );
      } else {
        dispatch({
          type: POST_DETAIL_TASK_REMARKS_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_DETAIL_TASK_REMARKS_ERROR });
  }
};

export const postTask = (
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_TASK_PENDING });
    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("project_id", body.project_id);
    formData.append("due_at", body.due_at);
    formData.append("started_at", body.started_at);
    formData.append("participants", body.participants);
    formData.append("assigned", body.assigned);
    formData.append("client_timezone", body.client_timezone);
    formData.append("finished_at", body.finished_at);
    formData.append("is_team", body.is_team);
    formData.append("recurring", body.recurring);
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_month", body.recurring_month);
    formData.append("recurring_day", body.recurring_day);
    formData.append("recurring_weekday", body.recurring_weekday);
    formData.append("recurring_time", body.recurring_time);
    formData.append("recurring_history", body.recurring_history);
    formData.append(
      "recurring_ended_at",
      body.recurring_ended_at ? `${body.recurring_ended_at} 23:59` : ""
    );
    body.files.map((item: any, index: number) =>
      formData.append(`files[${String(index)}]`, item)
    );
    const res = await API.postTask(formData, auth.token);
    dispatch({ type: POST_TASK_SUCCESS, payload: { data: res.data.data } });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response.data.success,
      message: err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogin(() => dispatch(postTask(body, cb))));
    }
    dispatch({ type: POST_TASK_ERROR });
  }
};

export const putTaskEdit = (
  taskId: string,
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_TASK_EDIT_PENDING });
    const formData = new URLSearchParams();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("project_id", body.project_id);
    formData.append("due_at", body.due_at);
    formData.append("started_at", body.started_at);
    formData.append("assigned_by", body.participants);
    formData.append("assigned", body.assigned);
    formData.append("client_timezone", body.client_timezone);
    formData.append("finished_at", body.finished_at);
    formData.append("is_team", body.is_team);
    formData.append("recurring", body.recurring);
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_month", body.recurring_month);
    formData.append("recurring_day", body.recurring_day);
    formData.append("recurring_weekday", body.recurring_weekday);
    formData.append("recurring_time", body.recurring_time);
    formData.append("recurring_history", body.recurring_history);
    formData.append(
      "recurring_ended_at",
      body.recurring_ended_at ? `${body.recurring_ended_at} 23:59` : ""
    );
    const res = await API.putTaskEdit(taskId, formData, auth.token);
    dispatch({ type: PUT_TASK_EDIT_SUCCESS, payload: { data: res.data.data } });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response.data.success,
      message: err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogin(() => dispatch(putTaskEdit(taskId, body, cb))));
    }
    dispatch({ type: PUT_TASK_EDIT_ERROR });
  }
};

export const postTaskRequest = (
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_TASK_REQUEST_PENDING });
    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("project_id", body.project_id);
    formData.append("due_at", body.due_at);
    formData.append("started_at", body.started_at);
    formData.append("assigned_by", body.participants);
    formData.append("assigned", body.assigned);
    formData.append("client_timezone", body.client_timezone);
    formData.append("finished_at", body.finished_at);
    formData.append("repeat_at", body.repeat_at);
    formData.append("recurring", body.recurring);
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_time", body.recurring_time);
    body.files.map((item: any, index: number) =>
      formData.append(`files[${String(index)}]`, item)
    );
    const res = await API.postTaskRequest(formData, auth.token);
    dispatch({
      type: POST_TASK_REQUEST_SUCCESS,
      payload: { data: res.data.data }
    });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response.data.success,
      message: err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogin(() => dispatch(postTask(body, cb))));
    }
    dispatch({ type: POST_TASK_REQUEST_ERROR });
  }
};

export const putModerateTaskRequest = (body: any, cb?: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_TASK_MODERATION_PENDING });

    const res = await API.putModeration(body, auth.token);
    const { status } = res.data.data;
    let label = "";
    if (status === 3) {
      label = "REVISED SUCCESS";
    } else if (status === 2) {
      label = "APPROVED SUCCESS";
    } else {
      label = "REJECTED SUCCESS";
    }
    dispatch({
      type: PUT_TASK_MODERATION_SUCCESS,
      payload: { data: res.data.data }
    });
    toast.success(label, {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb ? cb() : window.location.reload();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putModerateTaskRequest(body))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: PUT_TASK_MODERATION_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_TASK_MODERATION_ERROR });
  }
};

export const setAddParticipants = (data: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ADD_PARTICIPANT,
    payload: { data }
  });
};

export const setDeleteParticipants = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DELETE_PARTICIPANT,
    payload: { data: id }
  });
};

export const saveAddParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: SAVE_ADD_PARTICIPANT
  });
};

export const getParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: GET_ADD_PARTICIPANT
  });
};

export const resetParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_PARTICIPANT
  });
};

export const resetDetailTask = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_DETAIL_TASK
  });
};

export const putTask = (id: string, body: any, cb?: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_TASK_PENDING });

    const res = await API.putTask(id, body, auth.token);
    dispatch({ type: PUT_TASK_SUCCESS, payload: { data: res.data } });
    cb && cb();
    toast.success("ADD PARTICIPANT SUCCESS", {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      window.location.reload();
    }, 2600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogin(() => dispatch(putTask(id, body))));
    } else {
      toast.error(error.data.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      });
    }
    dispatch({ type: PUT_TASK_ERROR });
  }
};

export const getTaskListDropdown = (type: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TASK_LIST_DROPDOWN_PENDING });

    const res = await API.getTaskListDropdown(auth.token, type);
    dispatch({
      type: GET_TASK_LIST_DROPDOWN_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogin(() => dispatch(getTaskListDropdown(type))));
    }
    dispatch({ type: GET_TASK_LIST_DROPDOWN_ERROR });
  }
};

export const deleteFileTaskReport = (
  reportId: string | undefined,
  fileId: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_FILE_TASK_REPORT_PENDING });

    const res = await API.deleteFileTaskReport(reportId, fileId, auth.token);
    dispatch({
      type: DELETE_FILE_TASK_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(deleteFileTaskReport(reportId, fileId)))
        );
      } else {
        dispatch({
          type: DELETE_FILE_TASK_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: DELETE_FILE_TASK_REPORT_ERROR });
  }
};

export const submitTaskDetailReport = (
  taskId: string | undefined,
  userId: string,
  reportId: string,
  body: { date: string; description: string }
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: SUBMIT_TASK_DETAIL_REPORT_PENDING });

    const res = await API.submitTaskDetailReport(
      taskId,
      userId,
      reportId,
      body,
      auth.token
    );
    await API.getDetailTaskReport(taskId, userId, auth.token);
    dispatch({
      type: SUBMIT_TASK_DETAIL_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
    toast.success(i18n.t("cash.new.msg.toastSuccess"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(submitTaskDetailReport(taskId, userId, reportId, body))
          )
        );
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: SUBMIT_TASK_DETAIL_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_TASK_DETAIL_REPORT_ERROR });
  }
};

export const postTaskDetailReport = (
  taskId: string | undefined,
  userId: string,
  body: any
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_TASK_DETAIL_REPORT_PENDING });

    const formData = new FormData();
    formData.append("date", body.date);
    formData.append("description", body.description);
    body.files.map((item: any, index: number) =>
      formData.append(`files[${String(index)}]`, item)
    );

    await API.postTaskDetailReport(taskId, userId, formData, auth.token);
    const res = await API.getDetailTaskReport(taskId, userId, auth.token);
    // const { t } = useTranslation();

    dispatch({
      type: POST_TASK_DETAIL_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
    window.history.back();
    toast.success(i18n.t("task.assign.dataStored"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(postTaskDetailReport(taskId, userId, body))
          )
        );
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });

        dispatch({
          type: POST_TASK_DETAIL_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_TASK_DETAIL_REPORT_ERROR });
  }
};

export const putTaskDetailReport = (
  taskId: string | undefined,
  userId: string,
  reportId: string,
  body: any
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_TASK_DETAIL_REPORT_PENDING });

    const res = await API.putTaskDetailReport(
      taskId,
      userId,
      reportId,
      body,
      auth.token
    );
    dispatch({
      type: PUT_TASK_DETAIL_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(putTaskDetailReport(taskId, userId, reportId, body))
          )
        );
      } else {
        dispatch({
          type: PUT_TASK_DETAIL_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_TASK_DETAIL_REPORT_ERROR });
  }
};

export const submitEndtask = (taskId: string | undefined) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: SUBMIT_END_TASK_PENDING });

    const res = await API.submitEndTask(taskId, auth.token);
    dispatch({
      type: SUBMIT_END_TASK_SUCCESS,
      payload: { data: res.data.data }
    });
    toast.success("END TASK SUCCESS", {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      window.location.reload();
    }, 2600);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(submitEndtask(taskId))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: SUBMIT_END_TASK_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: SUBMIT_END_TASK_ERROR });
  }
};

export const editDetailTask = (
  reportId: string,
  uploadedFiles: any[],
  taskId: string,
  uuid: string,
  desc: { description: string | undefined }
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: EDIT_DETAIL_TASK_REPORT_PENDING });

    await API.putTaskDetailReport(taskId, uuid, reportId, desc, auth.token);

    const res = await API.getDetailTaskReport(taskId, uuid, auth.token);

    dispatch({
      type: EDIT_DETAIL_TASK_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
    window.history.back();
    toast.success(i18n.t("task.assign.editStored"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(
              editDetailTask(reportId, uploadedFiles, taskId, uuid, desc)
            )
          )
        );
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });

        dispatch({
          type: EDIT_DETAIL_TASK_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: EDIT_DETAIL_TASK_REPORT_ERROR });
  }
};

export const postActivateTask = (taskId: string | undefined) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_ACTIVATE_TASK_PENDING });

    const res = await API.postTaskActivate(taskId, auth.token);
    dispatch({
      type: POST_ACTIVATE_TASK_SUCCESS,
      payload: { data: res.data.data }
    });
    toast.success(i18n.t("activity.detail.addSuccess"), {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      window.location.reload();
    }, 2600);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postActivateTask(taskId))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: POST_ACTIVATE_TASK_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: SUBMIT_END_TASK_ERROR });
  }
};

export const setModalRecurringTask = (flag: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_RECURRING_TASK,
    payload: { data: flag }
  });
};

export const putTaskRecurring = (
  taskId: string,
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_TASK_EDIT_RECURRING_PENDING });
    const formData = new URLSearchParams();
    formData.append("recurring", body.recurring);
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_month", body.recurring_month);
    formData.append("recurring_day", body.recurring_day);
    formData.append("recurring_weekday", body.recurring_weekday);
    formData.append("recurring_time", body.recurring_time);
    formData.append(
      "recurring_ended_at",
      body.recurring_ended_at ? `${body.recurring_ended_at} 23:59` : ""
    );
    const res = await API.putTaskRecurring(taskId, formData, auth.token);
    dispatch({
      type: PUT_TASK_EDIT_RECURRING_SUCCESS,
      payload: { data: res.data.data }
    });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response.data.success,
      message: err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogin(() => dispatch(putTaskRecurring(taskId, body, cb))));
    }
    dispatch({ type: PUT_TASK_EDIT_RECURRING_ERROR });
  }
};

export const setSearchTaskValue = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_TASK_VALUE,
    payload: { data: value }
  });
};

export const resetSearchTaskValue = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_SEARCH_TASK_VALUE
  });
};
