import React, { useEffect, useState } from "react";
import { FlatList, Image, ImageProps, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  getConfigUploadFile,
  getDateAvailableReport,
  getReportById,
  putEditReport,
  setFormReport
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { IMAGES } from "../../../../configs";
import styles from "./styles";
import "./style.css";
import {
  Button,
  Card,
  Fixed,
  Header,
  Input,
  Select,
  Touchable,
  Upload,
  WrapContent
} from "../../../../components";
import { convertDate, documentTitle, queryString } from "../../../../utils";

declare const alert: (param: any) => void;

interface Item {
  image: ImageProps;
}
const dataAttc = [
  {
    image: IMAGES.avatar
  }
];

const EditReport = () => {
  documentTitle("Report - Edit Report");
  const { id } = useParams() as any;
  const dispatch = useDispatch();
  const location = useLocation();
  const editReportState = useSelector((state: Reducers) => state.report);
  const auth = useSelector((state: Reducers) => state.auth);
  const history = useHistory();
  const param = queryString(location.search);

  const [heightInputSubject, setHeightInputSubject] = useState(0);
  const [extension, setExtensions] = useState(null);

  const [
    showConfirmDeleteAttachment,
    setShowConfirmDeleteAttachment
  ] = useState(false);
  const [heightFooter, setHeightFooter] = useState(0);
  const populateDateAvailable = editReportState.listDateAvailableReport.map(
    (e: any) => {
      return { value: e.id, label: convertDate(e.id) };
    }
  );

  useEffect(() => {
    dispatch(getReportById(param.report));
    dispatch(getDateAvailableReport(id!));
    dispatch(getConfigUploadFile());
  }, [dispatch, id, param.report]);

  const _setForm = (formName: string, value: string) => {
    dispatch(setFormReport(formName, value));
  };

  const _uploadDocument = (e: any) => {
    const files = e.target.files[0];
    const size = auth.myConfig.max_size / auth.myConfig.max_size;

    if (files.size <= auth.myConfig.max_size) {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = () => {
        // setImage(reader.result);
      };
      const extensions = e.target.files[0].name.split(".").pop();
      setExtensions(extensions);
      _setForm("file", files);
    } else {
      alert(`file to big! maximum size allowed ${size}MB.`);
    }
  };

  const _goBack = () => {
    history.push(`/trip-detail/${id}/report?type=ongoing`);
  };
  const renderAttchment = (item: Item, index: number) => {
    const buttonView = {
      backgroundImage:
        "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
      borderRadius: 100,
      width: "100%"
    };
    return (
      <View style={{ flexDirection: "row" }} key={index}>
        <Card cardStyle={styles.cardAttchmen}>
          <View>
            <Image
              style={{ height: 109, width: 109 }}
              source={{ uri: editReportState.form.file.url }}
            />
            <View
              style={{
                position: "absolute",
                height: "100%",
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                alignSelf: "center"
              }}
            >
              <div style={buttonView}>
                <Touchable
                  onPress={() =>
                    window.open(editReportState.form.file.url, "Detail Gambar")
                  }
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        color: "#FFF",
                        fontWeight: "bold",
                        marginVertical: 5,
                        fontSize: 11
                      }}
                    >
                      VIEW
                    </Text>
                  </View>
                </Touchable>
              </div>
              <View>
                <Touchable
                  onPress={() =>
                    setShowConfirmDeleteAttachment(!showConfirmDeleteAttachment)
                  }
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        color: "#707070",
                        fontWeight: "bold",
                        marginVertical: 5,
                        fontSize: 11
                      }}
                    >
                      DELETE
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </Card>
      </View>
    );
  };

  return (
    <>
      <Header title="Edit Report" goBack={_goBack} />
      <WrapContent isLoading={editReportState.isLoadingGetReportById}>
        <View
          style={[styles.newReportContent, { marginBottom: heightFooter * 2 }]}
        >
          <View style={[styles.rowWrap, { zIndex: 1 }]}>
            <View style={[styles.wrapLabelInput, { flex: 0.52 }]}>
              <Text style={styles.label}>Date</Text>
              <Card cardStyle={styles.cardMultiTrip}>
                {!editReportState.isLoadingDateAvailableReport &&
                editReportState.listDateAvailableReport.length > 0 ? (
                  <Select
                    listValue={populateDateAvailable}
                    defaultValue={() =>
                      _setForm("date", populateDateAvailable[0].value)
                    }
                    onChange={e => _setForm("date", e.target.value)}
                  />
                ) : (
                  <Text style={styles.noAvnoAvailableDate}>
                    {convertDate(editReportState.form.date)}
                  </Text>
                )}
              </Card>
            </View>
          </View>

          <View style={styles.wrapSubject}>
            <Text style={styles.label}>Subject</Text>
            <Input
              inputStyle={[
                styles.inputSubject,
                heightInputSubject > 0 &&
                  editReportState.form.title !== "" && {
                    height: heightInputSubject
                  }
              ]}
              style={{ marginLeft: 14 }}
              onChangeText={text => _setForm("title", text)}
              multiline
              value={editReportState.form.title}
              onContentSizeChange={({ nativeEvent }) =>
                setHeightInputSubject(nativeEvent.contentSize.height)
              }
            />
          </View>
          <View style={styles.wrapReport}>
            <Text style={styles.label}>Report</Text>
            <Input
              inputStyle={[
                styles.inputSubject,
                { fontWeight: "normal", color: "#666666" }
              ]}
              style={{ marginLeft: 14 }}
              multiline
              numberOfLines={5}
              value={editReportState.form.description}
              onChangeText={text => _setForm("description", text)}
            />
          </View>
          <View style={{ marginLeft: 14 }}>
            {editReportState.form.file === null ? (
              <Upload
                onChange={(e: any) => _uploadDocument(e)}
                extionsion={extension}
                multiple={false}
              />
            ) : (
              <></>
            )}
            <View>
              {editReportState.form.file ? (
                <FlatList
                  data={dataAttc}
                  renderItem={({ item, index }) => renderAttchment(item, index)}
                  keyExtractor={(item, index) => index.toString()}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
        <Fixed
          getHeight={e => setHeightFooter(e)}
          customPosition={{ bottom: 30 }}
        >
          <View style={styles.button}>
            <Button
              title="SAVE"
              onPress={() => dispatch(putEditReport(param.report, _goBack))}
              isLoading={editReportState.isLoadingPutEditReport}
            />
          </View>
        </Fixed>
      </WrapContent>
    </>
  );
};

export default EditReport;
