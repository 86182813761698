import { StyleSheet } from "react-native";
import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  newReportContent: {
    paddingHorizontal: 20,
    marginTop: 25
  },
  rowWrap: {
    flexDirection: "row",
    paddingTop: 4
  },
  wrapLabelInput: { flex: 1, marginRight: 9 },
  label: {
    marginLeft: 12,
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  },
  inputWrap: {
    marginTop: 12,
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginBottom: 14
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#666666"
  },
  wrapSubject: {
    marginTop: 20
  },
  wrapReport: {
    marginTop: 12,
    marginBottom: 12
  },
  inputSubject: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "bold",
    color: "#707070",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    borderBottomWidth: 0.5,
    borderBottomColor: "#999",
    paddingLeft: 12,
    marginLeft: -2
  },
  buttonAttachmen: {
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    borderRadius: 20,
    marginTop: 20,
    marginLeft: 12,
    paddingVertical: 5,
    width: "fit-content",
    paddingHorizontal: 17
  },
  titleAttc: {
    color: "#fffff7",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  button: {
    alignItems: "center"
  },
  uploadWrap: {
    marginTop: 30
  },
  noAvnoAvailableDate: {
    fontSize: 14,
    color: "#666666",
    fontWeight: "bold"
  },
  cardMultiTrip: {
    paddingHorizontal: 12,
    paddingBottom: 5,
    paddingTop: 5
  },
  error: {
    color: "tomato",
    fontSize: 12,
    fontFamily: "Poppins",
    marginLeft: 12
  },
  row: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight
  },

  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginTop: 4,
    marginBottom: 2,
    outlineColor: "rgba(255,255,255,0)"
  }
});

export default styles;
