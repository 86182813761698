import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapTextInput: {
    borderBottomWidth: 0.5
  },
  textInput: {
    paddingVertical: 5,
    textAlign: "center",
    fontFamily: "Poppins"
  }
});

export default styles;
