import React, { useState } from "react";
import { Text, View } from "react-native";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Fixed, Header, Modal, Touchable } from "../../components";
import styles from "./styles";
import { heightPercent, maskedMoney } from "../../utils";
import CustomNotification from "../../components/ModalComponents/CustomNotification";

import { putNewTrip } from "../../redux/actions";
import { Reducers } from "../../redux/types";

import PerDiem from "./PerDiem";
import CashAdvance from "./CashAdvance";

const NewExpenseTrip = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { id } = useParams();
  const history = useHistory();
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;
  const { pathname } = history.location;
  const [heightHeader, setHeightHeader] = useState(0);
  const [heightBottom, setHeightBottom] = useState(0);
  const [modalIsOpen, setModalOpen] = useState(false);
  const { NewExpenseTripState, newTripState } = useSelector(
    (state: Reducers) => ({
      NewExpenseTripState: state.newExpenseTrip,
      newTripState: state.newTrip
    }),
    shallowEqual
  );
  const listPages = [
    {
      pathname: initialRoute,
      component: <PerDiem id={id!} />,
      titleTab: "Per Diem"
    },
    {
      pathname: `${initialRoute}/cashadvance`,
      component: <CashAdvance id={id!} />,
      titleTab: "Cash Advance"
    }
  ];

  const _totalPerDiem = () => {
    let total: any = "";
    if (NewExpenseTripState.listPerDiem.length > 0) {
      total = NewExpenseTripState.listPerDiem.reduce(
        (a: any, b: any) => a + (Number(b.total) || 0),
        0
      );
    }

    return total;
  };

  const _totalCA = () => {
    let total: any = "";
    if (NewExpenseTripState.listCashAdvance.length > 0) {
      total = NewExpenseTripState.listCashAdvance.reduce(
        (a: any, b: any) => a + (Number(b.total) || 0),
        0
      );
    }

    return total;
  };

  const _handleModal = () => {
    setModalOpen(!modalIsOpen);
  };

  const _goToBookingPage = () => {
    history.push(`/booking/${id}`);
  };

  const _submitTrip = () => {
    dispatch(putNewTrip(id!, () => history.push("/trip")));
  };

  const _haandleSave = () => {
    history.push("/trip");
  };

  return (
    <Switch>
      <View>
        <Fixed getHeight={e => setHeightHeader(e)}>
          <View style={styles.main}>
            <Header
              title="New Expense"
              subtitle="Meeting"
              goBack={() => history.push(`/newtrip?id=${id}`)}
            />
            <View style={styles.container}>
              <View style={styles.tripNav}>
                {listPages.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.btnNav,
                      item.pathname === pathname && styles.active
                    ]}
                  >
                    <Touchable onPress={() => history.push(item.pathname)}>
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          flex: 1
                        }}
                      >
                        <Text style={styles.btnText}>{item.titleTab}</Text>
                      </View>
                    </Touchable>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Fixed>

        <View
          style={{
            minHeight: heightPercent(100) - (heightHeader + heightBottom),
            marginTop: heightHeader,
            marginBottom: heightBottom
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact key={index}>
              {item.component}
            </Route>
          ))}
        </View>

        <Fixed getHeight={e => setHeightBottom(e)} position="bottom">
          <View style={styles.footer}>
            <View style={styles.containerTotalAmount}>
              <Text
                style={{ fontFamily: "Poppins", color: "white", fontSize: 11 }}
              >
                {pathname === listPages[0].pathname
                  ? "Total Per Diem"
                  : "Total Cash Advance"}
              </Text>
              <Text
                style={{ fontFamily: "Poppins", color: "white", fontSize: 18 }}
              >
                IDR
                <Text
                  style={{
                    fontFamily: "Poppins",
                    color: "white",
                    fontSize: 18,
                    fontWeight: "bold"
                  }}
                >
                  {pathname === listPages[0].pathname
                    ? ` ${maskedMoney(_totalPerDiem())}`
                    : ` ${maskedMoney(_totalCA())}`}
                </Text>
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Touchable
                onPress={_haandleSave}
                disabled={newTripState.isLoadingEditTrip}
              >
                <View style={styles.btnSave}>
                  <Text style={styles.textBtn}>SAVE</Text>
                </View>
              </Touchable>
              <Touchable
                onPress={_handleModal}
                disabled={newTripState.isLoadingEditTrip}
              >
                <View style={styles.btnNext}>
                  <Text style={styles.textBtn}>NEXT</Text>
                </View>
              </Touchable>
            </View>
          </View>
        </Fixed>

        {/* Modal */}
        <Modal isVisible={modalIsOpen} onBackdropPress={_handleModal}>
          <CustomNotification
            subButtonPress={_goToBookingPage}
            buttonPress={_submitTrip}
            booking
            notificationTitle="BOOKING"
            desc="Do you want continue adding bookings or finish now without booking?"
            textBtnAction="FINISH"
            isLoading={newTripState.isLoadingEditTrip}
          />
        </Modal>
      </View>
    </Switch>
  );
};

export default NewExpenseTrip;
