import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, TextInput, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  Datepicker,
  FileUpload,
  Header,
  Message,
  Select,
  Upload
} from "../../components";
import styles from "./styles";
import { convertDate, documentTitle, getPolicies } from "../../utils";

// redux action & types
import {
  getConfigUploadFile,
  getPopulate,
  getProject,
  postNewExpense
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

const methodOptions = [
  { value: "ca", label: "Cash Advance" },
  { value: "rm", label: "Reimburse" }
];

declare const alert: (param: any) => void;

const NewExpense = () => {
  documentTitle("Expense - New Expense");
  const dispatch = useDispatch();
  const history = useHistory();
  const newExpenseState = useSelector((state: Reducers) => state.newExpense);
  const projectState = useSelector((state: Reducers) => state.project);
  const auth = useSelector((state: Reducers) => state.auth);
  const [extension, setExtensions] = useState(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);
  const [heightInputSubject, setHeightInputSubject] = useState(0);
  const [form, setForm] = useState({
    purpose: "",
    type_id: methodOptions[0].value,
    started_at_d: convertDate(undefined, "YYYY-MM-DD"),
    category_id: "",
    amount: "",
    project_id: "",
    started_at_t: null,
    finished_at_d: null,
    finished_at_t: null,
    remarks: "",
    file: null
  });

  const initialPolicy = getPolicies("expense-rm-receipt");
  const [policy, setPolicy] = useState(initialPolicy);

  useEffect(() => {
    dispatch(getPopulate());
    dispatch(getProject());
    dispatch(getConfigUploadFile());
  }, [dispatch]);

  useEffect(() => {
    if (form.file || form.file !== null) {
      setPolicy(false);
    }
  }, [form.file]);

  const UploadDocument = async (e: any) => {
    const file = e.target.files[0];
    const size = auth.myConfig.max_size / auth.myConfig.max_size;

    if (file.size <= auth.myConfig.max_size) {
      setImage(file);
      const extensions = e.target.files[0].name.split(".").pop();
      setExtensions(extensions);
      setName(e.target.files[0].name);
      setForm({ ...form, file });
    } else {
      alert(`file to big! maximum size allowed ${size}MB.`);
    }
  };

  const deleteFile = async () => {
    setImage(null);
    setName(null);
    setForm({ ...form, file: null });
    setPolicy(initialPolicy);
  };

  const renderFile = () => {
    if (extension === "png" || extension === "jpeg" || extension === "jpg") {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    if (
      extension === "pdf" ||
      extension === "docx" ||
      extension === "xls" ||
      extension === "xlsx"
    ) {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    return null;
  };

  const _submitExpense = () => {
    if (form.purpose === "") {
      // eslint-disable-next-line no-alert
      alert("Nama expense anda tidak dimasukan");
    } else {
      dispatch(
        postNewExpense(
          { ...form, amount: form.amount === "" ? "0" : form.amount },
          () => history.push("/expense/misc")
        )
      );
    }
  };

  const _validateDatepicker = (type: string) => {
    if (type === "ca") {
      return { backDate: true, forwardDate: false };
    }

    return { backDate: false, forwardDate: true };
  };

  const _valueDate = (dt: string) => {
    const validate = _validateDatepicker(form.type_id);
    const x = convertDate(convertDate(undefined, "YYYY-MM-DD"), "x");
    let cdt = dt;
    if (validate.backDate) {
      const xdt = convertDate(cdt, "x");
      if (xdt < x) {
        cdt = convertDate(undefined, "YYYY-MM-DD");
        setForm({ ...form, started_at_d: cdt });
      }
    }
    if (validate.forwardDate) {
      const xdt = convertDate(cdt, "x");
      if (x < xdt) {
        cdt = convertDate(undefined, "YYYY-MM-DD");
        setForm({ ...form, started_at_d: cdt });
      }
    }
    return [{ date: cdt }];
  };

  return (
    <View>
      <Header title="New Expense" subtitle="General" />
      <View style={[styles.formWrap]}>
        {form.type_id === "rm" && policy && (
          <Message
            message="According to our policy, You are required to submit a photo or
          screenshot of the receipt. Violations will be recorded."
          />
        )}
        <View style={[styles.rowWrap, { marginBottom: 28 }]}>
          <View style={styles.wrapLabelInput}>
            <Text style={styles.label}>Subject</Text>
            <TextInput
              style={[
                styles.input,
                styles.inputLine,
                heightInputSubject > 0 &&
                  form.purpose !== "" && { height: heightInputSubject }
              ]}
              value={form.purpose}
              onChangeText={text => setForm({ ...form, purpose: text })}
              multiline
              onContentSizeChange={({ nativeEvent }) =>
                setHeightInputSubject(nativeEvent.contentSize.height)
              }
            />
          </View>
        </View>
        <View style={[styles.rowWrap, { zIndex: 1 }]}>
          <View style={[styles.wrapLabelInput, { flex: 0.5 }]}>
            <Text style={styles.label}>Date</Text>
            <Datepicker
              validate={_validateDatepicker(form.type_id)}
              value={_valueDate(form.started_at_d)}
              onChange={e =>
                setForm({ ...form, ...{ started_at_d: e[0].date } })
              }
            >
              <Card cardStyle={styles.inputWrap}>
                <Text style={styles.input}>
                  {convertDate(form.started_at_d)}
                </Text>
              </Card>
            </Datepicker>
          </View>
        </View>
        <View style={[styles.rowWrap]}>
          <View style={[styles.rowWrap, { flex: 1 }]}>
            <View style={styles.wrapLabelInput}>
              <Text style={styles.label}>Expense Type</Text>
              <Card cardStyle={styles.inputWrap}>
                {!newExpenseState.isLoadingPopulate &&
                newExpenseState.listPopulate.length > 0 ? (
                  <Select
                    listValue={newExpenseState.listPopulate}
                    defaultValue={() =>
                      setForm({
                        ...form,
                        category_id: newExpenseState.listPopulate[0].value
                      })
                    }
                    value={form.category_id}
                    onChange={e =>
                      setForm({ ...form, category_id: e.target.value })
                    }
                  />
                ) : (
                  <ActivityIndicator color="#344370" />
                )}
              </Card>
            </View>
          </View>
          <View style={[styles.rowWrap, { flex: 1 }]}>
            <View style={styles.wrapLabelInput}>
              <Text style={styles.label}>Method</Text>
              <Card cardStyle={styles.inputWrap}>
                <Select
                  listValue={methodOptions}
                  value={form.type_id}
                  onChange={e => setForm({ ...form, type_id: e.target.value })}
                />
              </Card>
            </View>
          </View>
        </View>
        <View style={[styles.rowWrap]}>
          <View style={[styles.rowWrap, { flex: 1 }]}>
            <View style={styles.wrapLabelInput}>
              <Text style={styles.label}>Project</Text>
              <Card cardStyle={styles.inputWrap}>
                {!projectState.isLoadingProject &&
                projectState.listProject.length > 0 ? (
                  <Select
                    listValue={projectState.listProject}
                    defaultValue={() =>
                      setForm({
                        ...form,
                        project_id: projectState.listProject[0].value
                      })
                    }
                    value={form.category_id}
                    onChange={e => {
                      setForm({ ...form, project_id: e.target.value });
                    }}
                  />
                ) : (
                  <ActivityIndicator color="#344370" />
                )}
              </Card>
            </View>
          </View>
        </View>

        <View style={styles.attachment}>
          {form.type_id === "rm" && (
            <View style={{ marginVertical: 20 }}>
              {image != null ? <View>{renderFile()}</View> : null}
            </View>
          )}
          {form.type_id === "rm" ? (
            <Upload
              onChange={(e: any) => UploadDocument(e)}
              extionsion={extension}
              multiple={false}
            />
          ) : null}
        </View>
        {/* <View>
          {form.type_id === "rm" ? (
            <View>{image !== "" ? <View>{_renderImage()}</View> : null}</View>
          ) : null}
        </View> */}

        <View style={styles.rowWrapAmount}>
          <Text style={styles.label}>Amount (IDR)</Text>
          <View style={{ width: "60%" }}>
            <TextInput
              style={[styles.input, styles.inputLine, styles.inputAmount]}
              value={form.amount}
              onChangeText={text => setForm({ ...form, amount: text })}
            />
          </View>
        </View>
        <View style={{ marginVertical: 70, alignItems: "center" }}>
          <Button
            title="SUBMIT"
            style={{ paddingHorizontal: 10 }}
            onPress={() => _submitExpense()}
            isLoading={newExpenseState.isLoadingPostNewExpense}
          />
        </View>
      </View>
    </View>
  );
};

export default NewExpense;
