import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";
import { widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  wrapLoanNav: {
    backgroundColor: COLORS.main,
    width: "90%"
  },
  loanNav: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 80,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    height: "fit-content",
    // width: "fir-content",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  infoLoan: {
    alignItems: "center",
    width: "90%",
    backgroundColor: "#99999915",
    marginTop: 8,
    borderRadius: 10
  },
  infoLoanTextWrap: {
    width: "90%",
    flexDirection: "row"
  },
  txtInfoLoan: {
    color: "grey",
    fontSize: 10,
    fontFamily: "Poppins"
  },
  btnNew: {
    fontWeight: "bold",
    fontSize: 12,
    color: "white",
    paddingVertical: 10,
    paddingHorizontal: 40
  },
  btnNav: {
    color: "white",
    fontSize: 8,
    paddingVertical: 8,
    fontWeight: "bold"
  },
  btnText: {
    marginTop: 4,
    fontWeight: "bold",
    fontFamily: "poppins",
    fontSize: 8,
    color: COLORS.white
  },
  btnSubmitModal: {
    backgroundImage: `linear-gradient(to top, ${COLORS.orange},  ${COLORS.yellow})`,
    marginTop: 12
  },
  active: {
    backgroundImage: `linear-gradient(to top, ${COLORS.orange},  ${COLORS.yellow})`,
    flex: 1,
    borderRadius: 100
  },
  containerModal: {
    width: widthPercent(90),
    backgroundColor: "white",
    alignItems: "center"
  },
  titleModal: {
    color: COLORS.orange,
    fontWeight: "bold",
    fontSize: 20,
    marginVertical: 20,
    fontFamily: "Poppins"
  },
  text: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: COLORS.grey,
    width: "100%"
  },
  inputLine: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 30,
    paddingLeft: 12
  },
  monthText: {
    fontWeight: "bold",
    fontSize: 12,
    paddingHorizontal: 12,
    paddingVertical: 8
  },
  containerInput: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 12
  }
});

export default styles;
