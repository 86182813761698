/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Fixed, Header, Touchable } from "../../../../components";
import styles from "./styles";
import {
  getListAirport,
  getListBookingFlight,
  getListBookingHotel,
  getMyProfile,
  getPopulateCategory,
  getPopulateProject,
  getRegion,
  getTaskListDropdown,
  getTermDataActivity,
  getVendorDetail
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import Details from "./Details";
import Invoice from "./Invoice";
import { copyWritings } from "../../../../utils";
// import booking from "../../../../redux/reducers/booking";

interface Props {
  type: "bookingHotels" | "bookingFlights";
}

const DetailBooking = ({ type }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { id, tripId } = useParams() as any;
  const { sprintf } = require("sprintf-js");
  const { search } = history.location;

  const { pathname } = history.location;
  const matchLast = `${match.path.slice(0, -12)}/${id}/${tripId}`;
  const listPages = [
    {
      label: t("activity.invoice.details"),
      component: <Details id={id} type={type} tripId={tripId || null} />,
      exact: true,
      pathname: `${matchLast}`
    },
    {
      label: t("activity.invoice.invoice"),
      component: <Invoice id={id} type={type} tripId={tripId || null} />,
      exact: false,
      pathname: `${matchLast}/invoice`
    }
  ];

  const [heightHeader, setHeightHeader] = useState(0);
  const tripState = useSelector((state: Reducers) => state.trip);
  const bookingState = useSelector((state: Reducers) => state.booking);
  const data =
    tripState.activity.data[type] && tripState.activity.data[type][id];

  useEffect(() => {
    if (tripState.activity.getData) {
      dispatch(getTermDataActivity());
    }
  }, [dispatch, tripState.activity.getData]);

  useEffect(() => {
    if (tripState.activity.data[type] && tripState.activity.data[type][id]) {
      dispatch(getVendorDetail(tripState.activity.data[type][id].vendor_id));
    } else if (
      bookingState.listBookingHotel.length > 0 &&
      type === "bookingHotels"
    ) {
      dispatch(
        getVendorDetail(
          bookingState.listBookingHotel[id].djurneeBooking.vendor.id
        )
      );
    } else if (
      bookingState.listBookingFlight.length > 0 &&
      type === "bookingFlights"
    ) {
      dispatch(
        getVendorDetail(
          bookingState.listBookingFlight[id].djurneeBooking.vendor.id
        )
      );
    }
  }, [
    dispatch,
    tripState.activity.data,
    bookingState.listBookingHotel,
    bookingState.listBookingFlight,
    type,
    id
  ]);

  useEffect(() => {
    dispatch(getPopulateCategory());
    dispatch(getPopulateProject());
    dispatch(getTaskListDropdown(""));
    dispatch(getMyProfile());

    if (type === "bookingHotels") {
      dispatch(getRegion());
      if (
        tripId !== undefined &&
        tripId !== "undefined" &&
        tripId !== "hotel-detail" &&
        tripId !== "flight-detail"
      ) {
        dispatch(getListBookingHotel(tripId));
      }
    }
    if (
      tripId !== undefined &&
      tripId !== "undefined" &&
      tripId !== "hotel-detail" &&
      tripId !== "flight-detail"
    ) {
      dispatch(getRegion());
      if (tripId) {
        dispatch(getListBookingFlight(tripId));
      }
    }
  }, [dispatch, type]);

  useEffect(() => {
    if (data) {
      if (type === "bookingFlights") {
        dispatch(getListAirport(data.origin_country_code, "departure"));
        dispatch(getListAirport(data.destination_country_code, "arrival"));
      }
    } else if (bookingState.listBookingFlight.length > 0) {
      if (type === "bookingFlights") {
        dispatch(
          getListAirport(
            bookingState.listBookingFlight[id].data.origin_country_id,
            "departure"
          )
        );
        dispatch(
          getListAirport(
            bookingState.listBookingFlight[id].data.destination_country_id,
            "arrival"
          )
        );
      }
    }
  }, [type, dispatch, bookingState.listBookingFlight, data]);

  return (
    <View style={styles.container}>
      <Fixed getHeight={e => setHeightHeader(e)}>
        <Header
          title={
            type === "bookingHotels"
              ? sprintf(
                  t("activity.detail.detailHotel"),
                  copyWritings("booking")
                )
              : sprintf(
                  t("activity.detail.detailFlight"),
                  copyWritings("booking")
                )
          }
          textHeader={{ fontSize: 18 }}
          goBack={() => {
            if (
              tripId !== "undefined" &&
              tripId !== "hotel-detail" &&
              tripId !== "flight-detail"
            ) {
              history.push(
                `/trip-detail/${tripId}/booking${
                  type === "bookingFlights" ? "/flight" : ""
                }${search}`
              );
            } else {
              history.push(
                `/newtrip/booking${type === "bookingFlights" ? "/flight" : ""}`
              );
            }
          }}
        />
        <View style={styles.containerNav}>
          <View style={styles.wrapNav}>
            {listPages.map((item, index) => (
              <View key={index} style={{ flex: 1 }}>
                <Touchable
                  onPress={() => history.push(`${item.pathname}${search}`)}
                >
                  <View
                    style={[
                      styles.buttonTab,
                      item.pathname === pathname && styles.activeButton
                    ]}
                  >
                    <Text style={styles.labelTab}>{item.label}</Text>
                  </View>
                </Touchable>
              </View>
            ))}
          </View>
        </View>
      </Fixed>
      <View style={{ marginTop: heightHeader }}>
        {listPages.map((item, index) => (
          <Route key={index} path={item.pathname} exact={item.exact}>
            {item.component}
          </Route>
        ))}
      </View>
    </View>
  );
};

export default DetailBooking;
