import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Hotel from "./list";
import NewBooking from "./new";
import EditHotel from "./edit";

interface Props {
  id: string;
}

const HotelRoute = ({ id }: Props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <Hotel id={id!} />
      </Route>
      <Route exact path={`${match.path}/hotel/new`}>
        <NewBooking id={id!} />
      </Route>
      <Route exact path={`${match.path}/hotel/edit/:id`}>
        <EditHotel />
      </Route>
    </Switch>
  );
};

export default HotelRoute;
