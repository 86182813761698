import { StyleSheet } from "react-native";
import { heightPercent } from "../../../utils";

const styles = StyleSheet.create({
  image: {
    width: 62.6,
    height: 75.6,
    marginTop: 40
  },
  cardItem: {
    alignSelf: "center",
    marginBottom: 9,
    width: 160,
    backgroundColor: "#fffff7",
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1
  },
  textItem: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  content: {
    height: heightPercent(100),
    justifyContent: "center",
    alignItems: "center"
  },
  cardStyle: {
    alignItems: "center",
    width: 335,
    paddingVertical: 36,
    backgroundColor: "#fffff7"
  },
  title: {
    alignSelf: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#666666",
    marginBottom: 25
  },
  textClose: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070"
  },
  buttonClose: {
    bottom: 22,
    left: 34,
    position: "absolute"
  }
});

export default styles;
