import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

interface Props {
  children: ReactNode;
  isEmpty: boolean;
  emptyComponent?: () => ReactElement;
  onEndReached?: () => void;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  keyData: {
    color: "#999999",
    fontSize: 12,
    fontFamily: "Poppins"
  }
});

const _emptyComponent = () => <Text style={styles.keyData}>Empty</Text>;

let run: any;

const WrapList = ({
  isEmpty,
  children,
  emptyComponent,
  onEndReached
}: Props) => {
  const [isScroll, setIsScroll] = useState(true);

  const _runFuncEndReach = async () => {
    if (
      document.documentElement.scrollHeight -
        document.documentElement.clientHeight -
        100 <=
        Math.ceil(window.scrollY) &&
      isScroll
    ) {
      await onEndReached!();
      setIsScroll(false);
    }
  };

  const _runFunc = () => {
    if (!isScroll) {
      setIsScroll(true);
    }
    clearTimeout(run);
    run = setTimeout(() => {
      _runFuncEndReach();
    }, 100);
  };

  useEffect(() => {
    if (onEndReached) {
      window.addEventListener("scroll", _runFunc);
    }

    return () => {
      if (onEndReached) {
        window.removeEventListener("scroll", _runFunc);
      }
    };
  }, []);

  return isEmpty ? (
    <View style={styles.container}>{emptyComponent!()}</View>
  ) : (
    <>{children}</>
  );
};

WrapList.defaultProps = {
  emptyComponent: () => _emptyComponent()
};

export default WrapList;
