import { StyleSheet } from "react-native";
import { heightPercent, widthPercent } from "../../utils";

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 20,
    marginBottom: 30
  },
  header: {
    height: 44,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "center",
    backgroundColor: "#50b1b1"
  },
  textHeader: {
    marginLeft: 21,
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "white"
  },
  text: {
    marginTop: 23,
    color: "#666666",
    marginHorizontal: 21,
    fontSize: 11,
    fontFamily: "Poppins",
    fontStyle: "italic"
  },
  textFile: {
    marginVertical: 23,
    color: "#666666",
    marginHorizontal: 21,
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  posDesc: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 21
  },
  line: {
    borderBottomColor: "#ecf0f1",
    borderBottomWidth: 1
  },
  content: {
    height: heightPercent(100),
    justifyContent: "center",
    alignItems: "center"
  },
  cardStyle: {
    alignItems: "center",
    width: 335,
    paddingVertical: 36,
    backgroundColor: "#fffff7"
  },
  cardModalStyle: {
    borderRadius: 20,
    marginTop: 30,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    width: widthPercent(100) - 25,
    minHeight: heightPercent(95),
    paddingVertical: 36,
    backgroundColor: "#fffff7",
    marginBottom: 2
  },
  cardItem: {
    alignSelf: "center",
    marginBottom: 9,
    width: 160,
    backgroundColor: "#fffff7",
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1
  },
  title: {
    alignSelf: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#666666",
    marginBottom: 25
  },
  textItem: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  textClose: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070"
  },
  buttonClose: {
    bottom: 22,
    left: 34,
    position: "absolute"
  },
  insideModal: {
    width: widthPercent(100) - 54,
    paddingHorizontal: 22,
    alignItems: "center"
  },
  titleReport: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#666666",
    marginBottom: 4
  },
  dateReport: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#666666",
    marginBottom: 37
  },
  desc: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#666666",
    marginBottom: 20
  },
  textImages: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#666666",
    fontWeight: "bold",
    marginBottom: 12
  },
  textFiles: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#666666",
    fontWeight: "bold",
    marginTop: 45
  },
  btnClose: {
    marginLeft: 20,
    paddingVertical: 36,
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666"
  },
  footer: {
    width: widthPercent(100) - 25,
    backgroundColor: "#fffff7"
  }
});

export default styles;
