/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
// import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
// import { IMAGES } from "../../../configs";
import { Fixed, Message, Touchable } from "../../../components";
import { Reducers } from "../../../redux/types";
import Hotel from "../../NewTrip/Booking/Hotel";
import Flight from "../../NewTrip/Booking/Flight";
// import NoteRevise from "../ModalNoteRevise";
// import ModalResolve from "../ModalResolve";
import {
  getAirline,
  getListBookingFlight,
  getListBookingHotel,
  getListCountry,
  getPolicies,
  getRegion,
  getSource,
  getTripById,
  getVendor
} from "../../../redux/actions";
import {
  copyWritings,
  documentTitle,
  heightPercent,
  queryString
  // textToLink
} from "../../../utils";
// import ClosingTripNotes from "../Trip/components/ClosingTripNotes";

interface Props {
  id: string;
  search: string;
  top: number;
  offsetTop: number;
}

const Booking = ({ id, search, top, offsetTop }: Props) => {
  documentTitle("Trip Detail - Booking");
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const dispatch = useDispatch();
  const { tripState } = useSelector((state: Reducers) => ({
    tripState: state.trip
  }));

  const params = queryString(search);
  useEffect(() => {
    dispatch(getVendor());
    dispatch(getSource());
    dispatch(getPolicies());
    dispatch(getRegion());
    dispatch(getAirline());
    dispatch(getListCountry());
    dispatch(getListBookingHotel(id));
    dispatch(getListBookingFlight(id));
    if (!tripState.tripData) {
      getTripById(id);
    }
  }, [dispatch]);

  const copTeks = {
    trip: copyWritings("trip")
  };

  const { pathname } = history.location;
  const listPages = [
    {
      pathname: `${match.path}`,
      component: <Hotel id={id!} />,
      // component: <Hotel />,
      exact: true
    },
    {
      pathname: `${match.path}/flight`,
      component: <Flight tripId={id!} />,
      exact: true
    }
  ];

  return (
    <Switch>
      <View>
        <Fixed
          getHeight={e => setHeightHeader(e)}
          customPosition={{ top: top - offsetTop }}
        >
          <View style={styles.bookingpDetailContent} />
        </Fixed>
        <View
          style={{
            alignItems: "center"
          }}
        >
          <View style={styles.navigation}>
            <View
              style={
                listPages[0].pathname === pathname ||
                pathname.includes(`${listPages[0].pathname}/hotel`)
                  ? [
                      styles.active,
                      {
                        borderBottomLeftRadius: 25
                      }
                    ]
                  : styles.btnNav
              }
            >
              <Touchable
                onPress={() => history.push(listPages[0].pathname + search)}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingVertical: 6
                  }}
                >
                  <Text style={styles.textButton}>Hotel</Text>
                </View>
              </Touchable>
            </View>

            <View
              style={
                pathname.includes(listPages[1].pathname)
                  ? [
                      styles.active,
                      {
                        borderBottomRightRadius: 25
                      }
                    ]
                  : styles.btnNav
              }
            >
              <Touchable
                onPress={() => history.push(listPages[1].pathname + search)}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingVertical: 6
                  }}
                >
                  <Text style={styles.textButton}>
                    {t("activity.booking.flight")}
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
          {tripState.tripData &&
            (params.status === "1" || params.status === "4") &&
            new Date(tripState.tripData.finished_at) < new Date() && (
              <View style={styles.warning}>
                <Message
                  message={sprintf(
                    t("activity.detail.msg.bookingNotSubmit"),
                    copTeks.trip,
                    copTeks.trip,
                    copTeks.trip
                  )}
                />
              </View>
            )}
        </View>

        <View style={styles.bookingpDetailContent}>
          <View
            style={{
              marginTop: heightHeader,
              minHeight: heightPercent(100) - (heightHeader + top)
            }}
          >
            {listPages.map((item, index) => (
              <Route path={item.pathname} exact={item.exact} key={index}>
                {item.component}
              </Route>
            ))}
          </View>
        </View>
      </View>
    </Switch>
  );
};
export default Booking;
