import { StyleSheet } from "react-native";
import { COLORS } from "../../../configs";

// import { widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main
  },
  button: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  container: {
    width: "100%",
    alignItems: "center"
  },
  fileAttachment: {
    width: "48%",
    height: 28,
    borderRadius: 100,
    marginBottom: 12
  },
  attributeTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#999999"
  }
});

export default styles;
