/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";
import styles from "./styles";
import { Card, Modal, WrapContent } from "../../../../components";
import { Reducers } from "../../../../redux/types";
import { convertDate, copyWritings } from "../../../../utils";

import NoteRevise from "../../ModalNoteRevise";
import { ICONS } from "../../../../configs";
import ModerateTrip from "../../../../components/ModalComponents/ModerateTrip";

const Trip = () => {
  const [modalDesc, setModalDesc] = useState(false);
  const [modalModeration, setModalModeration] = useState(false);
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const { tripData, tripState, uuid } = useSelector(
    (state: Reducers) => ({
      tripData: state.trip.tripData,
      tripState: state.trip,
      uuid: state.auth.uuid
    }),
    shallowEqual
  );

  const copTeks = {
    expense: copyWritings("expense"),
    booking: copyWritings("booking"),
    report: copyWritings("report"),
    project: copyWritings("project"),
    trip: copyWritings("trip"),
    invoice: copyWritings("invoice")
  };

  const _handleRenderStatusModeration = (status: number) => {
    if (status === 1) {
      return t("activity.status.waitingAnswer");
    }
    if (status === 2) {
      return t("activity.status.approved");
    }
    if (status === 3) {
      return t("activity.status.revise");
    }
    if (status === 4) {
      return t("activity.status.rejected");
    }
    return "";
  };

  const _canModerateTrip = useCallback(() => {
    let res = false;
    if (tripState.tripModerationData.length > 0) {
      res =
        tripState.tripModerationData &&
        tripState.tripModerationData[
          tripState.tripModerationData.length - 1
        ].users.filter((userData: any) => userData.user.id === uuid).length > 0;
    }

    if (tripData && tripData.status === 2 && res) {
      return true;
    }
    return false;
  }, [uuid, tripState.tripModerationData, tripData]);

  return (
    <View style={{ flex: 1 }}>
      <WrapContent isLoading={tripState.isLoadingGetAccountableByTripId}>
        <View style={styles.container}>
          {tripState.accountableTripData !== null && (
            <View style={styles.wrapTripStatus}>
              <View style={[styles.partTripStatus, styles.line]}>
                <Text style={[styles.font, styles.bold]}>
                  {copTeks.expense}
                </Text>
                <Text style={[styles.font, styles.numberTrip, styles.green]}>
                  {`${tripState.accountableTripData.expense_c} / ${tripState.accountableTripData.expense_t}`}
                </Text>
              </View>
              <View style={[styles.partTripStatus, styles.line]}>
                <Text style={[styles.font, styles.bold]}>
                  {copTeks.booking}
                </Text>
                <Text style={[styles.font, styles.numberTrip, styles.orange]}>
                  {`${tripState.accountableTripData.booking_c} / ${tripState.accountableTripData.booking_t}`}
                </Text>
              </View>
              <View style={[styles.partTripStatus, styles.line]}>
                <Text style={[styles.font, styles.bold]}>{copTeks.report}</Text>
                <Text style={[styles.font, styles.numberTrip, styles.red]}>
                  {`${tripState.accountableTripData.report_c} / ${tripState.accountableTripData.report_t}`}
                </Text>
              </View>
              <View style={styles.partTripStatus}>
                <Text style={[styles.font, styles.bold]}>
                  {copTeks.invoice}
                </Text>
                <Text style={[styles.font, styles.numberTrip, styles.yellow]}>
                  {`${tripState.accountableTripData.invoice_c} / ${tripState.accountableTripData.invoice_t}`}
                </Text>
              </View>
            </View>
          )}
          {tripData !== null && (
            <View style={styles.tripDetailContainer}>
              <View>
                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>{copTeks.project}</Text>
                  <View style={styles.detailDescription}>
                    <Text style={[styles.titleDescription, { width: "100%" }]}>
                      {tripData.number}
                    </Text>
                    <Text style={[styles.font, styles.bold, styles.font14]}>
                      {tripData.project ? tripData.project.title : "-"}
                    </Text>
                    <Text style={[styles.font, styles.light]}>
                      {tripData.category ? tripData.category.title : "-"}
                    </Text>
                  </View>
                </View>

                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>{copTeks.trip}</Text>
                  <View style={styles.detailDescription}>
                    <Text style={styles.font}>
                      {`${tripData.origin_location.toLowerCase()} - ${tripData.destination_location.toLowerCase()}`}
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[styles.font]}>
                        {`${convertDate(tripData.started_at, "ddd")} `}
                      </Text>
                      <Text style={[styles.font, styles.bold]}>
                        {`${convertDate(
                          tripData.started_at,
                          "DD MMM YYYY"
                        )} - `}
                      </Text>
                      <Text style={[styles.font]}>
                        {`${convertDate(tripData.finished_at, "ddd")} `}
                      </Text>
                      <Text style={[styles.font, styles.bold]}>
                        {`${convertDate(tripData.finished_at, "DD MMM YYYY")} `}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>
                    {t("activity.management.participant")}
                  </Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      {tripData.users.map((item: any, index: number) => {
                        return `${item.user.name}${
                          index !== tripData.users.length - 1 ? ", " : ""
                        }`;
                      })}
                    </Text>
                  </View>
                </View>

                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>
                    {t("activity.new.subject")}
                  </Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      {tripData.title}
                    </Text>
                  </View>
                </View>

                <View style={styles.desciption}>
                  <Text style={styles.titleDescription}>
                    {sprintf(
                      t("activity.management.selfBooking"),
                      copTeks.booking
                    )}
                  </Text>
                  <View style={styles.detailDescription}>
                    <Text
                      style={[
                        styles.font,
                        styles.bold,
                        tripData.djurneeTrip.self_booking
                          ? { color: "#50B1B1" }
                          : { color: "#ff6347" }
                      ]}
                    >
                      {tripData.djurneeTrip.self_booking
                        ? t("activity.yes").toUpperCase()
                        : t("activity.no").toUpperCase()}
                    </Text>
                  </View>
                </View>

                <Card
                  cardStyle={styles.noteStyle}
                  onPress={() => setModalDesc(true)}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.titleDescription, { width: "24%" }]}>
                      {t("activity.new.description")}
                    </Text>
                    <Text
                      numberOfLines={4}
                      style={[styles.font, { width: "75%" }]}
                    >
                      {tripData.description ? tripData.description : " - "}
                    </Text>
                  </View>
                </Card>
              </View>
              {tripState.tripModerationData.length > 0 && (
                <View style={styles.stepperContainer}>
                  <View style={{ marginLeft: 18 }}>
                    <Text style={styles.stepperStatus}>
                      {t("activity.issued")}
                    </Text>
                    <Text style={[styles.stepperAssigner, { marginBottom: 8 }]}>
                      {/* {tripData.moderation && tripData.moderation.user
                        ? tripData.moderation.user.position_name
                        : ""} */}
                      {tripState.tripModerationData?.[0]?.issuer?.name || "-"}
                    </Text>
                    <View style={styles.stepperItems}>
                      <View style={[styles.stepperCircle]} />
                      <View style={styles.stepperLine} />
                    </View>
                    <Text
                      style={[
                        styles.stepperAssigner,
                        { fontWeight: "bold", marginTop: 8 }
                      ]}
                    >
                      {moment(
                        new Date(tripData.created_at).toUTCString()
                      ).format("DD MMM YY")}
                    </Text>
                    <Text style={styles.stepperAssigner}>
                      {moment(
                        new Date(tripData.created_at).toUTCString()
                      ).format("HH:mm")}
                    </Text>
                  </View>

                  {tripState.tripModerationData &&
                    tripState.tripModerationData.map((item, index) => (
                      <View
                        key={index}
                        style={{
                          paddingRight:
                            tripState.tripModerationData.length - 1 === index
                              ? 16
                              : 0
                        }}
                      >
                        <Text style={styles.stepperStatus}>
                          {_handleRenderStatusModeration(item.status)}
                        </Text>
                        <Text
                          style={[styles.stepperAssigner, { marginBottom: 8 }]}
                        >
                          {(item.moderator && item.moderator.name) ||
                            (item.users.length > 0 &&
                              item?.users?.[0]?.user?.position_name) ||
                            "-"}
                        </Text>
                        <View style={styles.stepperItems}>
                          <View style={[styles.stepperCircle]} />
                          {tripState.tripModerationData.length - 1 !==
                            index && <View style={styles.stepperLine} />}
                        </View>
                        <Text
                          style={[
                            styles.stepperAssigner,
                            { fontWeight: "bold", marginTop: 8 }
                          ]}
                        >
                          {item.moderator &&
                            moment(
                              new Date(item.updated_at).toUTCString()
                            ).format("DD MMM YY")}
                        </Text>
                        <Text style={styles.stepperAssigner}>
                          {item.moderator &&
                            moment(
                              new Date(item.updated_at).toUTCString()
                            ).format("HH:mm")}
                        </Text>
                      </View>
                    ))}
                </View>
              )}
            </View>
          )}
          {_canModerateTrip() && (
            <View style={styles.wrapBtnModeration}>
              <TouchableOpacity
                activeOpacity={0.8}
                style={styles.btnModeration}
                onPress={() => setModalModeration(true)}
              >
                <Image
                  source={ICONS.moderationIcon}
                  style={{ height: 28, width: 28 }}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
      </WrapContent>
      {modalDesc && (
        <Modal
          isVisible={modalDesc}
          wrapStyle={{ justifyContent: "flex-start" }}
          backdropColor="rgba(255,255,255, 0.9)"
        >
          <NoteRevise
            headerTitle={t("activity.new.description")}
            onPress={() => setModalDesc(false)}
          >
            <Text>{tripData.description}</Text>
          </NoteRevise>
        </Modal>
      )}
      {modalModeration && (
        <Modal
          isVisible={modalModeration}
          backdropColor="rgba(240,240,240, 0.5)"
        >
          {tripData && (
            <ModerateTrip
              tripId={tripData.id}
              onCancel={() => setModalModeration(false)}
            />
          )}
        </Modal>
      )}
    </View>
  );
};

export default Trip;
