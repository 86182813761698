import {
  GET_BUDGET_REMAINING_ERROR,
  GET_BUDGET_REMAINING_PENDING,
  GET_BUDGET_REMAINING_SUCCESS,
  GET_BUDGET_TYPE_ERROR,
  GET_BUDGET_TYPE_PENDING,
  GET_BUDGET_TYPE_SUCCESS,
  GET_EXPENSE_DETAIL_ERROR,
  GET_EXPENSE_DETAIL_PENDING,
  GET_EXPENSE_DETAIL_SUCCESS,
  GET_EXPENSE_GENERAL_ERROR,
  GET_EXPENSE_GENERAL_PENDING,
  GET_EXPENSE_GENERAL_SUCCESS,
  GET_EXPENSE_TRIP_ERROR,
  GET_EXPENSE_TRIP_PENDING,
  GET_EXPENSE_TRIP_SUCCESS,
  LOGOUT_SUCCESS,
  POST_NEW_GENERAL_EXPENSE_ERROR,
  POST_NEW_GENERAL_EXPENSE_PENDING,
  POST_NEW_GENERAL_EXPENSE_SUCCESS,
  PUT_EXPENSE_GENERAL_ERROR,
  PUT_EXPENSE_GENERAL_PENDING,
  PUT_EXPENSE_GENERAL_SUCCESS
} from "../actions";
import { Action, ExpenseState } from "../types";

const initialState: ExpenseState = {
  paramsGeneral: {
    per_page: 10,
    page: 1
  },
  isLoadingEditExpenseGeneral: false,
  isLoadingGeneral: false,
  loadMoreGeneral: true,
  listGeneral: [],
  paramsTrip: {
    per_page: 10,
    page: 1
  },
  isLoadingTrip: false,
  loadMoreTrip: true,
  listTrip: [],
  isLoadingExpenseDetail: false,
  expenseDetail: null,
  editExpenseGeneral: null,
  budgetType: [],
  isLoadingBudgetType: false,
  isLoadingNewGeneralExpense: false,
  budgetRemaining: []
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // General
    case GET_EXPENSE_GENERAL_PENDING:
      return { ...state, isLoadingGeneral: !payload.loadMore };
    case GET_EXPENSE_GENERAL_SUCCESS:
      return {
        ...state,
        isLoadingGeneral: false,
        listGeneral: !payload.loadMore
          ? payload.data
          : [...state.listGeneral, ...payload.data],
        paramsGeneral: {
          ...state.paramsGeneral,
          page: !payload.loadMore ? 2 : state.paramsGeneral.page + 1
        },
        loadMoreGeneral: !(
          payload.data.length < initialState.paramsGeneral.per_page
        )
      };
    case GET_EXPENSE_GENERAL_ERROR:
      return { ...state, isLoadingGeneral: false };

    case PUT_EXPENSE_GENERAL_PENDING:
      return { ...state, isLoadingEditExpenseGeneral: true };
    case PUT_EXPENSE_GENERAL_SUCCESS:
      return {
        ...state,
        isLoadingEditExpenseGeneral: false
      };
    case PUT_EXPENSE_GENERAL_ERROR:
      return { ...state, isLoadingEditExpenseGeneral: false };

    // Trip
    case GET_EXPENSE_TRIP_PENDING:
      return { ...state, isLoadingTrip: !payload.loadMore };
    case GET_EXPENSE_TRIP_SUCCESS:
      return {
        ...state,
        isLoadingTrip: false,
        listTrip: !payload.loadMore
          ? payload.data
          : [...state.listTrip, ...payload.data],
        paramsTrip: {
          ...state.paramsTrip,
          page: !payload.loadMore ? 2 : state.paramsTrip.page + 1
        },
        loadMoreTrip: !(payload.data.length < initialState.paramsTrip.per_page)
      };
    case GET_EXPENSE_TRIP_ERROR:
      return { ...state, isLoadingTrip: false };

    case GET_BUDGET_TYPE_PENDING:
      return { ...state, budgetType: [], isLoadingBudgetType: true };
    case GET_BUDGET_TYPE_SUCCESS:
      return {
        ...state,
        isLoadingBudgetType: false,
        budgetType: payload.data.data
      };
    case GET_BUDGET_TYPE_ERROR:
      return { ...state, isLoadingBudgetType: false };

    // Detail Expense
    case GET_EXPENSE_DETAIL_PENDING:
      return { ...state, isLoadingExpenseDetail: true };
    case GET_EXPENSE_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingExpenseDetail: false,
        expenseDetail: payload.data
      };
    case GET_EXPENSE_DETAIL_ERROR:
      return { ...state, isLoadingExpenseDetail: false };

    // post general expense
    case POST_NEW_GENERAL_EXPENSE_PENDING:
      return { ...state, isLoadingNewGeneralExpense: true };
    case POST_NEW_GENERAL_EXPENSE_SUCCESS:
      return {
        ...state,
        isLoadingNewGeneralExpense: false
      };
    case POST_NEW_GENERAL_EXPENSE_ERROR:
      return { ...state, isLoadingNewGeneralExpense: false };

    case GET_BUDGET_REMAINING_PENDING:
      return { ...state, budgetRemaining: [], isLoadingBudgetType: true };
    case GET_BUDGET_REMAINING_SUCCESS:
      return {
        ...state,
        isLoadingBudgetType: false,
        budgetRemaining: payload.data.data
      };
    case GET_BUDGET_REMAINING_ERROR:
      return { ...state, isLoadingBudgetType: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
