import {
  GET_BRANCH_POPULATE_ERROR,
  GET_BRANCH_POPULATE_PENDING,
  GET_BRANCH_POPULATE_SUCCESS,
  GET_BRAND_POPULATE_ERROR,
  GET_BRAND_POPULATE_PENDING,
  GET_BRAND_POPULATE_SUCCESS,
  GET_CURRENCY_ERROR,
  GET_CURRENCY_PENDING,
  GET_CURRENCY_SUCCESS,
  GET_POPULATE_ERROR,
  GET_POPULATE_PENDING,
  GET_POPULATE_SUCCESS,
  GET_PRODUCT_POPULATE_ERROR,
  GET_PRODUCT_POPULATE_PENDING,
  GET_PRODUCT_POPULATE_SUCCESS,
  GET_TAX_LIST_ERROR,
  GET_TAX_LIST_PENDING,
  GET_TAX_LIST_SUCCESS,
  LOGOUT_SUCCESS,
  POST_BILL_ERROR,
  POST_BILL_PENDING,
  POST_BILL_SUCCESS,
  POST_NEW_EXPENSE_ERROR,
  POST_NEW_EXPENSE_PENDING,
  POST_NEW_EXPENSE_SUCCESS
} from "../actions";
import { Action, NewExpenseState, SelectOptions } from "../types";

const initialState: NewExpenseState = {
  isLoadingPopulate: false,
  listPopulate: [],
  isLoadingPostNewExpense: false,
  currencies: [],
  isLoadingGetCurrency: false,
  isLoadingPostBill: false,
  listTax: [],
  isLoadingListTax: false,
  populateBrand: [],
  isLoadingPopulateBrand: false,
  isLoadingPopulateBranch: false,
  populateBranch: [],
  populateProduct: [],
  isLoadingPopulateProduct: false
};

let listPopulate: SelectOptions[];

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // populate options
    case GET_POPULATE_PENDING:
      return { ...state, isLoadingPopulate: true };
    case GET_POPULATE_SUCCESS:
      listPopulate = [];
      payload.data.map((item: any) => {
        listPopulate = [
          ...listPopulate,
          { value: item.id, label: item.attributes.title }
        ];
      });
      return { ...state, isLoadingPopulate: false, listPopulate };
    case GET_POPULATE_ERROR:
      return { ...state, isLoadingPopulate: false };

    case GET_CURRENCY_PENDING:
      return { ...state, isLoadingGetCurrency: true };
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoadingGetCurrency: false,
        currencies: [...payload.data]
      };
    case GET_CURRENCY_ERROR:
      return { ...state, isLoadingGetCurrency: false };

    // post new expense
    case POST_NEW_EXPENSE_PENDING:
      return { ...state, isLoadingPostNewExpense: true };
    case POST_NEW_EXPENSE_SUCCESS:
      return { ...state, isLoadingPostNewExpense: false };
    case POST_NEW_EXPENSE_ERROR:
      return { ...state, isLoadingPostNewExpense: false };

    case POST_BILL_PENDING:
      return { ...state, isLoadingPostBill: true };
    case POST_BILL_SUCCESS:
      return { ...state, isLoadingPostBill: false };
    case POST_BILL_ERROR:
      return { ...state, isLoadingPostBill: false };

    case GET_TAX_LIST_PENDING:
      return { ...state, isLoadingListTax: true };
    case GET_TAX_LIST_SUCCESS:
      return { ...state, isLoadingListTax: false, listTax: payload.data };
    case GET_TAX_LIST_ERROR:
      return { ...state, isLoadingListTax: false };

    case GET_BRAND_POPULATE_PENDING:
      return { ...state, isLoadingPopulateBrand: true };
    case GET_BRAND_POPULATE_SUCCESS:
      return {
        ...state,
        isLoadingPopulateBrand: false,
        populateBrand: payload.data
      };
    case GET_BRAND_POPULATE_ERROR:
      return { ...state, isLoadingPopulateBrand: false };

    case GET_BRANCH_POPULATE_PENDING:
      return { ...state, isLoadingPopulateBranch: true };
    case GET_BRANCH_POPULATE_SUCCESS:
      return {
        ...state,
        isLoadingPopulateBranch: false,
        populateBranch: payload.data
      };
    case GET_BRANCH_POPULATE_ERROR:
      return { ...state, isLoadingPopulateBranch: false };

    case GET_PRODUCT_POPULATE_PENDING:
      return { ...state, isLoadingPopulateProduct: true };
    case GET_PRODUCT_POPULATE_SUCCESS:
      return {
        ...state,
        isLoadingPopulateProduct: false,
        populateProduct: payload.data
      };
    case GET_PRODUCT_POPULATE_ERROR:
      return { ...state, isLoadingPopulateProduct: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
