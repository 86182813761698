/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Reducers } from "../../redux/types";

import { Header } from "../../components";
import { AddBill } from "../../components/ModalComponents";
import { getBill, getInvoiceDetail } from "../../redux/actions";
import { copyWritings } from "../../utils";

const NewBillGeneral = () => {
  const { invoiceId, projectId } = useParams() as any;
  const dispatch = useDispatch();
  const history = useHistory();
  const { sprintf } = require("sprintf-js");

  useEffect(() => {
    dispatch(getInvoiceDetail(invoiceId));
    dispatch(getBill(invoiceId));
  }, [dispatch]);
  const { invoiceDetail } = useSelector((state: Reducers) => state.invoice);
  const { cashDetail } = useSelector((state: Reducers) => state.cash);
  const { t } = useTranslation();

  const copTeks = {
    expense: copyWritings("expense")
  };

  const statusDesc = (status: number, total: any, total_paid: any) => {
    let label = "";
    if (status === 1) {
      label = t("cash.status.draft");
    } else if (status === 2) {
      label = t("cash.status.needApproval");
    } else if (status === 3) {
      label = t("cash.status.approved");
    } else if (status === 4) {
      label = t("cash.status.revise");
    } else if (status === 5 && total_paid >= total) {
      label = t("cash.status.notSettled");
    } else if (status === 5) {
      label = t("cash.status.unpaid");
    } else if (status === 6) {
      label = t("cash.status.settled");
    } else if (status === 7) {
      label = t("cash.status.rejected");
    } else if (status === 8) {
      label = t("cash.status.expired");
    } else if (status === 9) {
      label = t("cash.status.rejectedByFinance");
    }
    return label;
  };

  return (
    <View>
      <Header
        textHeader={{ fontSize: 18 }}
        subTitleHeader={{ fontSize: 11 }}
        title={(sprintf("cash.new.newBill"), copTeks.expense)}
        subtitle={invoiceDetail && invoiceDetail.number}
        label={statusDesc(
          invoiceDetail && invoiceDetail.status,
          cashDetail && cashDetail.total,
          cashDetail && cashDetail.total_paid
        )}
        goBack={() => history.push(`/cash-details/${invoiceId}/bill/bill`)}
      />
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <AddBill
          invoiceId={invoiceId}
          projectId={projectId}
          onCancel={() => history.push(`/cash-details/${invoiceId}/bill/bill`)}
        />
      </View>
    </View>
  );
};
export default NewBillGeneral;
