import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  card: {
    marginTop: 15
  },
  rowItem: { flexDirection: "row" },
  rowLabel: {
    flex: 1
  },
  rowLabelText: {
    color: "#999999",
    fontSize: 11,
    fontFamily: "Poppins"
  },
  rowValue: {
    flex: 4
  },
  rowValueText: {
    color: "#707070",
    fontSize: 11,
    fontFamily: "Poppins"
  },
  star: {
    width: 9.5,
    height: 9.5,
    marginRight: 5
  },
  hotelName: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 7,
    color: "#666666"
  }
});

export default styles;
