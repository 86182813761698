import { StyleSheet } from "react-native";
import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    borderRadius: 15,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: COLORS.main,
    marginBottom: -10,
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  iconStyle: {
    height: 14,
    width: 14,
    tintColor: COLORS.grey,
    marginTop: 3
  },
  inputStyle: {
    color: COLORS.grey,
    fontFamily: "Poppins",
    flex: 1
  }
});

export default styles;
