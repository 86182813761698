import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  newReportContent: {
    paddingHorizontal: 20,
    marginTop: 25
  },
  rowWrap: {
    flexDirection: "row",
    paddingTop: 8
  },
  wrapLabelInput: { flex: 1, marginRight: 9 },
  label: {
    marginLeft: 14,
    fontSize: 14,
    color: "#666666",
    fontFamily: "Poppins"
  },
  inputWrap: {
    marginTop: 14,
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginBottom: 14
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#666666"
  },
  wrapSubject: {
    marginTop: 20
  },
  cardAttchmen: {
    overflow: "hidden",
    marginHorizontal: 10,
    marginBottom: 2
  },
  wrapReport: {
    marginTop: 30,
    marginBottom: 30
  },
  inputSubject: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "bold",
    color: "#707070",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 2,
    marginLeft: -2
  },
  buttonAttachmen: {
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    borderRadius: 20,
    marginTop: 20,
    marginLeft: 14,
    paddingVertical: 5,
    width: "fit-content",
    paddingHorizontal: 17
  },
  titleAttc: {
    color: "#fffff7",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  button: {
    alignItems: "center"
  },
  uploadWrap: {
    marginTop: 30
  },
  cardMultiTrip: {
    paddingLeft: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginTop: 8
  },
  noAvnoAvailableDate: {
    fontSize: 14,
    color: "#666666",
    fontWeight: "bold"
  }
});

export default styles;
