import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";
import { toast } from "../../utils";

export const POST_NEW_REPORT_PENDING = "POST_NEW_REPORT_PENDING";
export const POST_NEW_REPORT_SUCCESS = "POST_NEW_REPORT_SUCCESS";
export const POST_NEW_REPORT_ERROR = "POST_NEW_REPORT_ERROR";

export const GET_AVAILABLE_DATE_PENDING = "GET_AVAILABLE_DATE_PENDING";
export const GET_AVAILABLE_DATE_SUCCESS = "GET_AVAILABLE_DATE_SUCCESS";
export const GET_AVAILABLE_DATE_ERROR = "GET_AVAILABLE_DATE_ERROR";

export const PUT_EDIT_REPORT_PENDING = "PUT_EDIT_REPORT_PENDING";
export const PUT_EDIT_REPORT_SUCCESS = "PUT_EDIT_REPORT_SUCCESS";
export const PUT_EDIT_REPORT_ERROR = "PUT_EDIT_REPORT_ERROR";

export const GET_REPORT_BY_ID_PENDING = "GET_REPORT_BY_ID_PENDING";
export const GET_REPORT_BY_ID_SUCCESS = "GET_REPORT_BY_ID_SUCCESS";
export const GET_REPORT_BY_ID_ERROR = "GET_REPORT_BY_ID_ERROR";

export const GET_REPORT_BY_USER_ID_PENDING = "GET_REPORT_BY_USER_ID_PENDING";
export const GET_REPORT_BY_USER_ID_SUCCESS = "GET_REPORT_BY_USER_ID_SUCCESS";
export const GET_REPORT_BY_USER_ID_ERROR = "GET_REPORT_BY_USER_ID_ERROR";

export const PUT_SUBMIT_TRIP_REPORT_PENDING = "PUT_SUBMIT_TRIP_REPORT_PENDING";
export const PUT_SUBMIT_TRIP_REPORT_SUCCESS = "PUT_SUBMIT_TRIP_REPORT_SUCCESS";
export const PUT_SUBMIT_TRIP_REPORT_ERROR = "PUT_SUBMIT_TRIP_REPORT_ERROR";

export const DELETE_TRIP_REPORT_PENDING = "DELETE_TRIP_REPORT_PENDING";
export const DELETE_TRIP_REPORT_SUCCESS = "DELETE_TRIP_REPORT_SUCCESS";
export const DELETE_TRIP_REPORT_ERROR = "DELETE_TRIP_REPORT_ERROR";

export const SET_FORM_REPORT = "SET_FORM_REPORT";
export const RESET_REPORT_FORM = "RESET_REPORT_FORM";

declare const alert: (param: any) => void;

export const postNewReport = (form: any, cb: () => void, id?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_REPORT_PENDING });

    const formData = new FormData();
    Object.keys(form).forEach((key: any) => {
      formData.append(key, form[key]);
    });
    id && formData.append("_method", "PUT");

    const res = id
      ? await API.putEditReport(id, formData, auth.token)
      : await API.postNewReport(formData, auth.token);
    dispatch({
      type: POST_NEW_REPORT_SUCCESS,
      payload: { data: res.data }
    });
    toast.success(
      `${
        id
          ? i18n.t("cash.new.msg.updateReportSuccess")
          : i18n.t("cash.new.msg.addReportSuccess")
      }`
    );
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewReport(form, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_NEW_REPORT_ERROR });
  }
};

export const putEditReport = (param: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, report } = getState();
    dispatch({ type: PUT_EDIT_REPORT_PENDING });

    const res = await API.putEditReport(param, report.form, auth.token);
    dispatch({
      type: PUT_EDIT_REPORT_SUCCESS,
      payload: { data: res.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putEditReport(param, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: PUT_EDIT_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: PUT_EDIT_REPORT_ERROR });
  }
};

export const getReportById = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_REPORT_BY_ID_PENDING });

    const res = await API.getReportById(id, auth.token);
    dispatch({
      type: GET_REPORT_BY_ID_SUCCESS,
      payload: { data: res.data.data.attributes }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getReportById(id))));
      } else {
        dispatch({
          type: GET_REPORT_BY_ID_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_REPORT_BY_ID_ERROR });
  }
};

export const getReportByUserId = (filter: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_REPORT_BY_USER_ID_PENDING });

    const res = await API.getReportByUserId(filter, auth.token);
    dispatch({
      type: GET_REPORT_BY_USER_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getReportByUserId(filter))));
      } else {
        dispatch({
          type: GET_REPORT_BY_USER_ID_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_REPORT_BY_USER_ID_ERROR });
  }
};

export const setFormReport = (formName: string, value: string | number) => (
  dispatch: Dispatch
) => {
  dispatch({ type: SET_FORM_REPORT, payload: { data: { formName, value } } });
};

export const getDateAvailableReport = (param: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_AVAILABLE_DATE_PENDING });

    const res = await API.getDateAvailableReport(param, auth.token);
    dispatch({
      type: GET_AVAILABLE_DATE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDateAvailableReport(param))));
      } else {
        dispatch({
          type: GET_AVAILABLE_DATE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_AVAILABLE_DATE_ERROR });
  }
};

export const putSubmitTripReport = (param: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_SUBMIT_TRIP_REPORT_PENDING });

    await API.putSubmitTripReport(param, auth.token);
    dispatch({
      type: PUT_SUBMIT_TRIP_REPORT_SUCCESS,
      payload: { id: param }
    });
    toast.success(i18n.t("cash.new.msg.toastSuccess"));
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putSubmitTripReport(param))));
      } else {
        dispatch({
          type: PUT_SUBMIT_TRIP_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: PUT_SUBMIT_TRIP_REPORT_ERROR });
  }
};

export const deleteTripReport = (id: string, date: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_TRIP_REPORT_PENDING });

    await API.deleteTripReport(id, auth.token);
    dispatch({
      type: DELETE_TRIP_REPORT_SUCCESS,
      payload: { id, date }
    });
    toast.success(i18n.t("activity.deleteToast"));
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteTripReport(id, date))));
      } else {
        dispatch({
          type: DELETE_TRIP_REPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: DELETE_TRIP_REPORT_ERROR });
  }
};

export const resetReportForm = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_REPORT_FORM
  });
};
