import React from "react";
import { Image, Text, TextInput, View } from "react-native";
import { useHistory } from "react-router-dom";

import { IMAGES } from "../../../configs";
import { Button } from "../../../components";
import {
  Container,
  DescImage,
  DescText,
  WrapButton,
  WrapForm
} from "../components";
import styles from "./styles";

interface Props {
  next: string;
}

const EmailRegister = ({ next }: Props) => {
  const history = useHistory();

  return (
    <Container>
      <DescImage image={IMAGES.iconPhone} />
      <DescText
        title="Phone Number"
        desc="Enter your phone number, we will send you unique number to verify later"
        phoneNumber="xxxxxx90"
      />
      <WrapForm>
        <View style={styles.wrapTextInput}>
          <View style={styles.wrapIcon}>
            <Image
              source={IMAGES.indonesia}
              style={styles.iconIndonesia}
              resizeMode="contain"
            />
            <Text style={styles.text62}>+62</Text>
          </View>
          <TextInput placeholder="phone number" style={styles.textInput} />
        </View>
        <WrapButton>
          <Button title="CONTINUE" onPress={() => history.push(next)} />
        </WrapButton>
      </WrapForm>
    </Container>
  );
};

export default EmailRegister;
