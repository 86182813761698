import {
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_PENDING,
  GET_NOTIFICATION_SUCCESS,
  LOGOUT_SUCCESS
} from "../actions";
import { Action, NotificationState } from "../types";

const initialState: NotificationState = {
  isLoadingNotification: false,
  listNotification: [],
  loadMore: true,
  params: {
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc"
  }
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_NOTIFICATION_PENDING:
      return { ...state, isLoadingNotification: true };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingNotification: false,
        params: {
          ...state.params,
          page: !payload.loadMore ? 2 : state.params.page + 1
        },
        loadMore: !(payload.data.data.length < state.params.per_page),
        listNotification: !payload.loadMore
          ? payload.data.data
          : [...state.listNotification, ...payload.data.data]
      };
    case GET_NOTIFICATION_ERROR:
      return { ...state, isLoadingNotification: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
