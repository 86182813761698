import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import { IMAGES } from "../../configs";
import {
  BubbleNotif,
  Fixed,
  Header,
  Search,
  Touchable
} from "../../components";

import { Reducers } from "../../redux/types";

// screens
import Upcoming from "./Upcoming";
import Ongoing from "./Ongoing";
import History from "./History";
import Approval from "./Approval";
import Assignment from "./Assignment";
import ApprovalBill from "./ApprovalBill";
import { copyWritings, heightPercent } from "../../utils";
import {
  getBubbleNotif,
  resetSearchTripValue,
  setSearchTaskValue
} from "../../redux/actions";
import { debounce } from "../../utils/helpers";

const Trip = () => {
  const authData = useSelector((state: Reducers) => state.auth);
  const bubbleState = useSelector((state: Reducers) => state.bubbleNotif);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const { pathname } = history.location;

  useEffect(() => {
    dispatch(getBubbleNotif("activity"));
    dispatch(resetSearchTripValue());
  }, [dispatch]);

  const _getBubleData = useCallback(
    (type: "upcomming" | "approval" | "ongoing" | "history" | "assignment") => {
      if (bubbleState.dataBubble) {
        return bubbleState.dataBubble && bubbleState.dataBubble[type];
      }
      return false;
    },
    [bubbleState.dataBubble]
  );

  const copText = {
    trip: copyWritings("trip")
  };
  const listPages = [
    {
      pathname: match.path,
      component: <Upcoming />,
      iconTab: IMAGES.upcoming,
      titleTab: t("activity.upcomingTab"),
      exact: true,
      accessible: true,
      bubbleData: _getBubleData("upcomming")
    },
    {
      pathname: `${match.path}/ongoing`,
      component: <Ongoing />,
      iconTab: IMAGES.ongoing,
      titleTab: t("activity.ongoingTab"),
      exact: true,
      accessible: true,
      bubbleData: _getBubleData("ongoing")
    },
    {
      pathname: `${match.path}/history`,
      component: <History />,
      iconTab: IMAGES.history,
      titleTab: t("activity.historyTab"),
      exact: true,
      accessible: true,
      bubbleData: _getBubleData("history")
    },
    {
      pathname: `${match.path}/approval`,
      component: !authData.myPermission.find(
        (el: string) => el === "trip-others-list"
      ) ? (
        <Redirect to="/trip" />
      ) : (
        <Approval />
      ),
      iconTab: IMAGES.approval,
      titleTab: t("activity.approvalTab"),
      exact: false,
      accessible: authData.myPermission.find(
        (el: string) => el === "trip-others-list"
      ),
      bubbleData: _getBubleData("approval")
    },
    {
      pathname: `${match.path}/assignment`,
      component: !authData.myPermission.find(
        (el: string) => el === "trip-others-list"
      ) ? (
        <Redirect to="/trip" />
      ) : (
        <Assignment />
      ),
      iconTab: IMAGES.assignment,
      titleTab: t("activity.assignTab"),
      exact: true,
      accessible: authData.myPermission.find(
        (el: string) => el === "trip-others-list"
      ),
      bubbleData: _getBubleData("assignment")
    },
    {
      pathname: `${match.path}/bill`,
      component: <ApprovalBill />,
      iconTab: IMAGES.document,
      titleTab: "Cash",
      exact: true,
      accessible: authData.myPermission.find(
        (el: string) => el === "trip-others-list"
      ),
      bubbleData: false
    }
  ];

  const _onChangeSearch = debounce((text: string) => {
    dispatch(setSearchTaskValue(text));
  });

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            title={copText.trip.toUpperCase()}
            goBack={() => history.push("/home")}
          />
          <View style={styles.container}>
            <View style={styles.wrapTipNav}>
              <View style={styles.tripNav}>
                {listPages.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.btnNav,
                      index === 0 &&
                        item.pathname === pathname &&
                        styles.activeFirst,
                      index === listPages.length - 1 &&
                        item.pathname === pathname &&
                        styles.activeLast,
                      index !== 0 &&
                        pathname.includes(item.pathname) &&
                        styles.active
                    ]}
                  >
                    <Touchable
                      onPress={() => history.push(item.pathname)}
                      disabled={!item.accessible}
                    >
                      <View
                        style={[
                          {
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            flex: 1
                          },
                          !item.accessible ? { opacity: 0.3 } : null
                        ]}
                      >
                        <Image
                          resizeMode="contain"
                          style={styles.image}
                          source={item.iconTab}
                        />
                        <Text
                          style={[
                            styles.btnText,
                            { color: item.accessible ? "white" : "#666666" }
                          ]}
                        >
                          {item.titleTab}
                        </Text>
                      </View>
                    </Touchable>
                    {item.bubbleData && (
                      <BubbleNotif
                        value={item.bubbleData.total}
                        message={item.bubbleData.message}
                      />
                    )}
                  </View>
                ))}
              </View>
            </View>
            <Search onChange={_onChangeSearch} />
          </View>
        </Fixed>

        <View
          style={{
            marginTop: heightHeader,
            minHeight: heightPercent(100) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};

export default Trip;
