import React, { useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import { convertDate, documentTitle, maskedMoney } from "../../../utils";
import {
  LoadingLoadMore,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";

// redux action & types
import { getExpenseTrip } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const TripExpense = () => {
  documentTitle("Expense - Trip");
  const history = useHistory();
  const dispatch = useDispatch();
  const expenseState = useSelector((state: Reducers) => state.expense);

  useEffect(() => {
    dispatch(getExpenseTrip(false));
  }, [dispatch, history]);

  const dateFormat = (start: string, end: string) => {
    return `${convertDate(start)} - ${convertDate(end)}`;
  };

  const _goToDetailTrip = (
    id: string,
    titleHeader: string,
    labelStatus: number,
    expense: boolean
  ) => {
    history.push(
      `/trip-detail-expense/${id}?type=upcoming&status=${labelStatus}`,
      {
        titleHeader,
        labelStatus,
        expense
      }
    );
  };

  return (
    <WrapContent isLoading={expenseState.isLoadingTrip}>
      <WrapList
        isEmpty={expenseState.listTrip.length === 0}
        emptyComponent={() => <Text style={styles.text}>Kosong</Text>}
        onEndReached={() => dispatch(getExpenseTrip(true))}
      >
        <FlatList
          style={{ marginHorizontal: 20 }}
          data={expenseState.listTrip}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={
            <LoadingLoadMore active={expenseState.loadMoreTrip} />
          }
          renderItem={({ item, index }) => (
            <Touchable
              onPress={() =>
                _goToDetailTrip(item.id, item.project.title, item.status, true)
              }
            >
              <>
                <View style={[styles.card, index === 0 && { marginTop: 10 }]}>
                  <View style={[styles.row, { marginTop: 18 }]}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>No</Text>
                    </View>
                    <View style={{ width: "60%" }}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.number}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Subject</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.title ? item.title : "No project related"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Type</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      {typeof item.category !== "undefined" ? (
                        <Text style={[styles.desc, { marginBottom: 5 }]}>
                          {item.category.title}
                        </Text>
                      ) : (
                        <></>
                      )}
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Team</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.users.map((team: any, indexUser: number) => {
                          return `${team.user.name}${
                            indexUser !== item.users.length - 1 ? ", " : ""
                          }`;
                        })}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Date</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {dateFormat(item.started_at, item.finished_at)}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.row, { marginBottom: 18 }]}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Method</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.Method}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.footerCard}>
                  <Text style={styles.textStatus}>Ongoing expense</Text>
                  <Text style={styles.textDesc}>
                    <Text
                      style={[
                        styles.textDesc,
                        { fontWeight: "normal", marginRight: 0 }
                      ]}
                    >
                      IDR
                    </Text>
                    {` ${maskedMoney(item.ongoing_expenses || 0)}`}
                  </Text>
                </View>
                <View style={[styles.footerCard, styles.budgetRequest]}>
                  <Text style={styles.textStatus}>Budget request</Text>
                  <Text style={styles.textDesc}>
                    <Text
                      style={[
                        styles.textDesc,
                        { fontWeight: "normal", marginRight: 0 }
                      ]}
                    >
                      IDR
                    </Text>
                    {` ${maskedMoney(item.budget_request || 0)}`}
                  </Text>
                </View>
              </>
            </Touchable>
          )}
        />
      </WrapList>
    </WrapContent>
  );
};

export default TripExpense;
