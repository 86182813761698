import {
  GET_MENU_ERROR,
  GET_MENU_PENDING,
  GET_MENU_SUCCESS,
  GET_MY_DASHBOARD_ERROR,
  GET_MY_DASHBOARD_PENDING,
  GET_MY_DASHBOARD_SUCCESS,
  GET_MY_PROFILE_ERROR,
  GET_MY_PROFILE_PENDING,
  GET_MY_PROFILE_SUCCESS,
  LOGOUT_SUCCESS,
  POST_FEEDBACK_ERROR,
  POST_FEEDBACK_PENDING,
  POST_FEEDBACK_SUCCESS,
  SET_CONFIRMATION_MODAL
} from "../actions";
import { Action, HomeState } from "../types";

const initialState: HomeState = {
  isLoadingMyProfile: false,
  myProfile: { id: "" },
  myDashboard: [],
  isLoadingMyDashboard: false,
  isLoadingMenu: false,
  listMenu: [],
  confirmationFlag: false,
  isLoadingAddFeedback: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_MY_PROFILE_PENDING:
      return { ...state, isLoadingMyProfile: true, myProfile: {} };
    case GET_MY_PROFILE_SUCCESS:
      return { ...state, isLoadingMyProfile: false, myProfile: payload.data };
    case GET_MY_PROFILE_ERROR:
      return { ...state, isLoadingMyProfile: false };
    case GET_MY_DASHBOARD_PENDING:
      return { ...state, isLoadingMyDashboard: true, myDashboard: [] };
    case GET_MY_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoadingMyDashboard: false,
        myDashboard: payload.data
      };
    case GET_MY_DASHBOARD_ERROR:
      return { ...state, isLoadingMyDashboard: false };

    case GET_MENU_PENDING:
      return { ...state, isLoadingMenu: true, listMenu: [] };
    case GET_MENU_SUCCESS:
      return { ...state, isLoadingMenu: false, listMenu: payload.data };
    case GET_MENU_ERROR:
      return { ...state, isLoadingMenu: false };

    // confirmation modal
    case SET_CONFIRMATION_MODAL:
      return { ...state, confirmationFlag: payload.data };

    case POST_FEEDBACK_PENDING:
      return { ...state, isLoadingAddFeedback: true };
    case POST_FEEDBACK_SUCCESS:
      return { ...state, isLoadingAddFeedback: false };
    case POST_FEEDBACK_ERROR:
      return { ...state, isLoadingAddFeedback: false };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
