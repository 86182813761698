/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { SyntheticEvent } from "react";
import { Text, View } from "react-native";

import "./styles.css";
import { ICONS } from "../../configs";

interface Props {
  name?: any;
  deleteFile?: (e: SyntheticEvent) => void;
  style?: object;
  hideDelete?: boolean;
  onPress?: (e: SyntheticEvent) => void;
}

const FileUpload = ({
  name,
  deleteFile,
  style,
  onPress,
  hideDelete
}: Props) => {
  return (
    <div className="file-upload" style={style} onClick={onPress}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        <Text
          numberOfLines={1}
          ellipsizeMode="middle"
          style={{
            color: "#707070",
            fontSize: 14,
            fontFamily: "Poppins",
            fontWeight: "bold",
            width: "85%",
            marginRight: 10
          }}
        >
          {name}
        </Text>
        {deleteFile && !hideDelete && (
          <View
            style={{
              width: "10%",
              justifyContent: "center"
            }}
          >
            <img
              src={ICONS.iconDeleteFile}
              alt=""
              onClick={deleteFile}
              className="deleteFile"
            />
          </View>
        )}
      </View>
    </div>
  );
};

FileUpload.defaultProps = {
  name: "hello",
  hideDelete: false
};

export default FileUpload;
