import React from "react";
import { Image, ImageProps, StyleSheet, View } from "react-native";

interface Props {
  image: ImageProps;
}

const styles = StyleSheet.create({
  wrapIcon: {
    alignItems: "center",
    width: "100%"
  },
  icon: {
    width: 155,
    height: 155
  }
});

const DescImage = ({ image }: Props) => (
  <View style={styles.wrapIcon}>
    <Image source={image} style={styles.icon} resizeMode="contain" />
  </View>
);

export default DescImage;
