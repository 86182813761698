import React from "react";
import { Text, View } from "react-native";

import styles from "./styles";
import {
  CardStatusColor,
  Card as ComponentCard,
  Touchable
} from "../../../../components";
import { convertDate } from "../../../../utils";

type Type = "approval" | "default" | "submitted" | "";

interface Props {
  item: any;
  index: number;
  onPress?: () => void;
  withStatus?: boolean;
  statusText?: string;
  statusColor?: CardStatusColor;
  statusDesc?: string;
  type?: Type;
}

const CardApproval = ({
  item,
  index,
  onPress,
  withStatus,
  statusText,
  statusColor,
  statusDesc
}: Props) => {
  const propsCard = withStatus
    ? {
        statusColor,
        statusText,
        statusDesc
      }
    : {};

  return (
    <Touchable onPress={onPress}>
      <ComponentCard
        cardStyle={{
          marginBottom: 15,
          marginTop: index === 0 ? 20 : 0
        }}
        {...propsCard}
      >
        {item.reference ? (
          <View style={{ width: "100%", paddingVertical: 14 }}>
            <View style={styles.insideCard}>
              <View style={styles.columnData}>
                <Text style={styles.keyData}>Trip</Text>
              </View>
              <View style={styles.columnValue}>
                <Text style={styles.keyData}>{item.reference.number}</Text>
                <Text style={styles.valueData}>
                  {item.title ? item.title : "No project related"}
                </Text>
                <Text style={styles.keyData}>{item.title}</Text>
              </View>
            </View>
            <View style={styles.insideCard}>
              <View style={styles.columnData}>
                <Text style={styles.keyData}>Team</Text>
              </View>
              <View style={styles.columnValue}>
                <Text style={styles.valueData}>{item.issuer.name}</Text>
              </View>
            </View>
            <View style={styles.insideCard}>
              <View style={styles.columnData}>
                <Text style={styles.keyData}>Trip 1</Text>
              </View>
              <View style={styles.columnValue}>
                {item.reference ? (
                  <Text style={styles.valueData}>
                    {`${item.reference.origin_location} - ${item.reference.destination_location}`}
                  </Text>
                ) : null}
                <Text style={styles.keyData}>
                  {convertDate(
                    item.reference.published_at,
                    "ddd DD MMM YYYY HH:mm"
                  )}
                </Text>
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
      </ComponentCard>
    </Touchable>
  );
};

CardApproval.defaultProps = {
  withStatus: false,
  statusText: "Status",
  statusDesc: "",
  type: "default"
};

export default CardApproval;
