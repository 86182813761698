import React, { useState } from "react";
import { Text, View } from "react-native";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";

import styles from "./styles";
import { Fixed, Header, Touchable } from "../../components";

// screen
import MyActivity from "./pages/MyActivity";
import Expense from "./pages/Expense";
import Booking from "./pages/Booking";

const DetailActivity = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;
  const { id } = useParams() as any;
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;

  const [heightHeader, setHeightHeader] = useState(0);

  const listPages = [
    {
      label: "My Activity",
      component: <MyActivity />,
      exact: true,
      pathname: initialRoute
    },
    {
      label: "Expense",
      component: <Expense />,
      exact: false,
      pathname: `${initialRoute}/expense`
    },
    {
      label: "Booking",
      component: <Booking />,
      exact: false,
      pathname: `${initialRoute}/booking`
    }
  ];

  return (
    <>
      <View>
        <Fixed getHeight={e => setHeightHeader(e)}>
          <View style={styles.header}>
            <Header
              title="Revitalisasi Stasiun"
              subtitle="Meeting (External)"
              label="Draft"
              goBack={() => history.push("/trip")}
              textHeader={{ fontSize: 18 }}
              subTitleHeader={{ fontSize: 12 }}
            />
            <View style={styles.containerNav}>
              <View style={styles.wrapNav}>
                {listPages.map((item, index) => (
                  <View key={index} style={{ flex: 1 }}>
                    <Touchable onPress={() => history.push(item.pathname)}>
                      <View
                        style={[
                          styles.buttonTab,
                          (item.pathname === pathname ||
                            `${item.pathname}/cashadvance` === pathname) &&
                            styles.activeButton
                        ]}
                      >
                        <Text style={styles.labelTab}>{item.label}</Text>
                      </View>
                    </Touchable>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Fixed>
        <View style={[styles.content, { paddingTop: heightHeader }]}>
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </>
  );
};

export default DetailActivity;
