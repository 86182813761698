import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import {
  Container,
  Fixed,
  Header,
  Select,
  Touchable,
  WrapContent
} from "../../components";
import { documentTitle } from "../../utils";
import { COLORS } from "../../configs";

import Round from "./Round";
import OneWay from "./OneWay";

// redux action & types
import {
  getAirline,
  getBookingSourceFlight,
  getRegion,
  getTripById,
  getVendor,
  postNewBookingFlight,
  setFormNewBookingFlight
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

interface DataForm {
  formName: string;
  value: string | number;
}

// const selectClass = [
//   { label: "Economy", value: "economy" },
//   { label: "Any", value: "any" }
// ];

const NewBookingFlight = () => {
  documentTitle("Flight - New Booking");
  const { id } = useParams();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const { dataState, vendorState, tripState } = useSelector(
    (state: Reducers) => ({
      dataState: state.newBookingFlight,
      vendorState: state.vendor,
      tripState: state.trip
    }),
    shallowEqual
  );
  const { pathname, search } = history.location;

  const [footerHeight, setFooterHight] = useState(0);

  const listPages = [
    {
      pathname: `${initialRoute}`,
      component: <Round isError={isError} />
    },
    {
      pathname: `${initialRoute}/oneway`,
      component: <OneWay isError={isError} />
    }
  ];

  const vendorSelect = vendorState.vendors.map((e: any) => {
    return { value: e.id, label: e.title };
  });

  useEffect(() => {
    dispatch(getBookingSourceFlight());
    dispatch(getAirline());
    dispatch(getVendor());
    dispatch(getRegion());
    dispatch(getTripById(id));
  }, [dispatch, id]);

  useEffect(() => {
    setIsError(false);
  }, [pathname]);

  const setForm = (data: DataForm, index: number) => {
    dispatch(setFormNewBookingFlight(data, index));
  };

  const _redirect = () => {
    if (search !== "") {
      history.push(`/trip-detail/${id}/booking/flight${search}`);
    } else {
      history.push(`/booking/${id}/flight`);
    }
  };

  const _submit = () => {
    const { form } = dataState;
    if (form.from !== "" && form.to !== "" && form.started_at_d !== "") {
      dispatch(
        postNewBookingFlight(id!, _redirect, pathname === listPages[0].pathname)
      );
    } else {
      setIsError(true);
    }
  };

  return (
    <Switch>
      <Container>
        <Header title="New Booking" subtitle="Flight" goBack={_redirect} />
        <WrapContent
          isLoading={
            dataState.isLoadingSourceFlight ||
            dataState.isLoadingVendorFlight ||
            tripState.isLoadingTripById
          }
        >
          <View style={[styles.content, { marginBottom: footerHeight }]}>
            <View style={styles.wrapVendor}>
              <Text style={styles.textTitleInput}>Vendor</Text>
              <View style={styles.wrapSelectVendor}>
                {!vendorState.isLoadingVendor &&
                vendorState.vendors.length !== 0 ? (
                  <Select
                    listValue={vendorSelect}
                    value={dataState.form.vendor_id}
                    defaultValue={() => {
                      setForm(
                        {
                          formName: "vendor_id",
                          value: vendorSelect[0].value
                        },
                        0
                      );
                      setForm(
                        {
                          formName: "vendor_id",
                          value: vendorSelect[0].value
                        },
                        1
                      );
                    }}
                    onChange={e => {
                      setForm(
                        {
                          formName: "vendor_id",
                          value: e.target.value
                        },
                        0
                      );
                      setForm(
                        {
                          formName: "vendor_id",
                          value: e.target.value
                        },
                        1
                      );
                    }}
                  />
                ) : (
                  <ActivityIndicator color="#344370" />
                )}
              </View>
            </View>
            {/* <View style={styles.airlinesWrap}>
              <View style={{ width: "48%" }}>
                <Text style={styles.airlinesTitle}>Airlines</Text>
                <Card cardStyle={styles.airlinesCard}>
                  {!dataState.isLoadingAirline &&
                  dataState.listAirline.length > 0 ? (
                    <Select
                      listValue={dataState.listAirline}
                      defaultValue={() =>
                        setForm({
                          formName: "airline_id",
                          value: dataState.listAirline[0].value
                        })
                      }
                      value={dataState.form.airline_id}
                      onChange={e =>
                        setForm({
                          formName: "airline_id",
                          value: e.target.value
                        })
                      }
                    />
                  ) : (
                    <ActivityIndicator color="#344370" />
                  )}
                </Card>
              </View>

              <View style={{ width: "48%" }}>
                <Text style={styles.airlinesTitle}>Class</Text>
                <Card cardStyle={styles.airlinesCard}>
                  <Select
                    listValue={selectClass}
                    value={dataState.form.class_id}
                    onChange={e =>
                      setForm({
                        formName: "class_id",
                        value: e.target.value
                      })
                    }
                  />
                </Card>
              </View>
            </View> */}

            <View style={styles.navigation}>
              <View
                style={
                  listPages[0].pathname === pathname
                    ? styles.active
                    : styles.btnNav
                }
              >
                <Touchable
                  onPress={() => history.push(listPages[0].pathname + search)}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Text style={styles.textButton}>Round Trip</Text>
                  </View>
                </Touchable>
              </View>

              <View
                style={
                  listPages[1].pathname === pathname
                    ? styles.active
                    : styles.btnNav
                }
              >
                <Touchable
                  onPress={() => history.push(listPages[1].pathname + search)}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <Text style={styles.textButton}>One-way Trip</Text>
                  </View>
                </Touchable>
              </View>
            </View>

            {listPages.map((item, index) => (
              <Route path={item.pathname} key={index} exact>
                {item.component}
              </Route>
            ))}
          </View>
          <Fixed position="bottom" getHeight={value => setFooterHight(value)}>
            <View
              style={{
                width: "100%",
                alignItems: "center",
                backgroundColor: COLORS.main
              }}
            >
              <View style={styles.cardButton}>
                <Touchable
                  onPress={_submit}
                  disabled={dataState.isLoadingPostNewBookingFlight}
                >
                  <View style={{ paddingVertical: 15, paddingHorizontal: 58 }}>
                    {!dataState.isLoadingPostNewBookingFlight ? (
                      <Text style={styles.textButtonSubmit}>SUBMIT</Text>
                    ) : (
                      <ActivityIndicator color="#FFFFFF" />
                    )}
                  </View>
                </Touchable>
              </View>
            </View>
          </Fixed>
        </WrapContent>
      </Container>
    </Switch>
  );
};

export default NewBookingFlight;
