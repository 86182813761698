import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

interface Props {
  children: ReactNode;
}

const styles = StyleSheet.create({
  wrapForm: {
    paddingHorizontal: 70,
    marginTop: 45
  }
});

const WrapForm = ({ children }: Props) => (
  <View style={styles.wrapForm}>{children}</View>
);

export default WrapForm;
