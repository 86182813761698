import { StyleSheet } from "react-native";

import { heightPercent } from "../../utils";
import { SIZE } from "../../configs";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center"
  },
  wrapper: {
    backgroundColor: "#fffff7",
    width: "100%",
    maxWidth: SIZE.maxWidth,
    minHeight: heightPercent(100)
  }
});

export default styles;
