import {
  POST_NEW_REIMBURSE_ERROR,
  POST_NEW_REIMBURSE_PENDING,
  POST_NEW_REIMBURSE_SUCCESS
} from "../actions";
import { Action, ReimburseState } from "../types";

const initialState: ReimburseState = {
  isLoadingReimburse: false,
  reimburseData: []
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case POST_NEW_REIMBURSE_PENDING:
      return {
        ...state,
        isLoadingReimburse: true,
        reimburseData: []
      };
    case POST_NEW_REIMBURSE_SUCCESS:
      return {
        ...state,
        isLoadingReimburse: false,
        reimburseData: payload.data
      };
    case POST_NEW_REIMBURSE_ERROR:
      return {
        ...state,
        isLoadingReimburse: false
      };
    default:
      return { ...state };
  }
};
