import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    paddingHorizontal: 20,
    backgroundColor: "#fffff7"
  },
  navigation: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  btnNav: {
    height: "100%",
    borderRadius: 50
  },
  active: {
    borderRadius: 50,
    height: "100%",
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  textHeader: {
    fontSize: 20,
    marginTop: 5
  }
});

export default styles;
