import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapTextButton: {
    paddingVertical: 15,
    paddingHorizontal: 67
  },
  textButton: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ffffff"
  },
  wrapTextButtonLarge: {
    paddingVertical: 30,
    paddingHorizontal: 92
  },
  textButtonLarge: {
    fontSize: 24,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ffffff"
  }
});

export default styles;
