import React from "react";
import { Image, Text, View } from "react-native";

import styles from "./styles";
import Card from "../NewTrip/components/Card";
import { Header } from "../../components";
import { ICONS } from "../../configs";

const data = [
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail:
      "Your trip request booking for Elnusa Website & iOS has been DECLINED with NOTES.",
    icon: ICONS.iconNotes
  },
  {
    detail:
      "You have been ASSIGNED for Apple Academy F&B Lounge. Please respond to the assignment",
    icon: ICONS.iconAssigned
  },
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail:
      "Your trip request booking for Elnusa Website & iOS has been DECLINED with NOTES.",
    icon: ICONS.iconNotes
  },
  {
    detail:
      "You have been ASSIGNED for Apple Academy F&B Lounge. Please respond to the assignment",
    icon: ICONS.iconAssigned
  },
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail: "Your flight booking for Elnusa Website & iOS has been APPROVED.",
    icon: ICONS.iconApproved
  },
  {
    detail:
      "Your trip request booking for Elnusa Website & iOS has been DECLINED with NOTES.",
    icon: ICONS.iconNotes
  },
  {
    detail:
      "You have been ASSIGNED for Apple Academy F&B Lounge. Please respond to the assignment",
    icon: ICONS.iconAssigned
  }
];

const Notification = () => {
  const checkText = (text: string) => {
    let result;
    if (text.includes("APPROVED")) {
      const textSplit = text.split("APPROVED.");
      result = textSplit.map((res: string, index: number) => {
        return (
          <>
            {res}
            {textSplit.length - 1 !== index && (
              <Text style={{ color: "#50b1b1", fontWeight: "bold" }}>
                APPROVED.
              </Text>
            )}
          </>
        );
      });
    } else if (text.includes("ASSIGNED")) {
      const textSplit = text.split("ASSIGNED");
      result = textSplit.map((res: string, index: number) => {
        return (
          <>
            {res}
            {textSplit.length - 1 !== index && (
              <Text style={{ color: "#ed5a28", fontWeight: "bold" }}>
                ASSIGNED
              </Text>
            )}
          </>
        );
      });
    } else if (text.includes("DECLINED with NOTES")) {
      const textSplit = text.split("DECLINED with NOTES.");
      result = textSplit.map((res: string, index: number) => {
        return (
          <>
            {res}
            {textSplit.length - 1 !== index && (
              <Text style={{ color: "#ed5a28", fontWeight: "bold" }}>
                DECLINED with NOTES.
              </Text>
            )}
          </>
        );
      });
    }

    return result;
  };

  return (
    <View>
      <Header title="Notification" />
      <View style={styles.content}>
        {data.map((item, index) => {
          return (
            <Card cardStyle={styles.cardStyle} key={index}>
              <>
                <Image source={item.icon} style={styles.iconStyle} />
                <Text style={styles.textStyle}>{checkText(item.detail)}</Text>
              </>
            </Card>
          );
        })}
      </View>
    </View>
  );
};

export default Notification;
