import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  newTripContent: {
    marginTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20
  },
  labelInput: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  cardMultiTrip: {
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginTop: 8
  },
  textDatePeriod: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: "#666666"
  },
  inputStyle: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 2,
    marginLeft: -2
  },
  submit: {
    backgroundImage:
      "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
    borderRadius: 100,
    width: 180,
    height: 54,
    marginBottom: 20,
    alignSelf: "center"
  },
  submitTextWrap: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  submitText: {
    color: "#FFF",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18
  },
  policyMessage: {
    fontFamily: "Poppins",
    fontWeight: "normal",
    fontSize: 14,
    color: "#666666"
  }
});

export default styles;
