import React, { useState } from "react";
import { FlatList, Text, View } from "react-native";

import styles from "./styles";
import Card from "../../../pages/NewTrip/components/Card";
import { Touchable } from "../../index";

const FilterCategory = () => {
  const [categories, setCategory] = useState([
    { category: "Booking", active: false },
    { category: "Trip", active: false },
    { category: "Assignment", active: false },
    { category: "Expense", active: false }
  ]);
  const [inputcategory, setInputcategory] = useState([]);
  const handlecategoryInput = (index: number, category: string) => {
    const categoryData = categories;

    if (categoryData[index].active) {
      setInputcategory(inputcategory.filter(item => item !== category));
    } else {
      const input: any = [...inputcategory, category];
      setInputcategory(input);
    }
    categoryData[index].active = !categoryData[index].active;
    setCategory(categoryData);
  };

  const render = (item: any, index: number) => {
    return (
      <View
        style={[styles.cardItem, item.active && { backgroundColor: "#50b1b1" }]}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 11
          }}
        >
          <Touchable onPress={() => handlecategoryInput(index, item.category)}>
            <Text
              style={
                item.active
                  ? [styles.textItem, { color: "#fff", fontWeight: "bold" }]
                  : styles.textItem
              }
            >
              {item.category}
            </Text>
          </Touchable>
        </View>
      </View>
    );
  };
  return (
    <View style={styles.content}>
      <View>
        <Card cardStyle={styles.cardStyle}>
          <>
            <Text style={styles.title}>Filter</Text>
            <FlatList
              style={{}}
              data={categories}
              renderItem={({ item, index }) => render(item, index)}
            />
          </>
        </Card>
      </View>
      <View style={styles.buttonClose}>
        <Touchable>
          <Text style={styles.textClose}>CLOSE</Text>
        </Touchable>
      </View>
    </View>
  );
};
export default FilterCategory;
