/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";

import {
  Card,
  FileUpload,
  Input,
  Select,
  Touchable,
  Upload
} from "../../../../../components";
import {
  editDetailBills,
  emptyDetailBills,
  emptyListCashAdvanceBill,
  getConfigUploadFile,
  getDetailBills
} from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";
import styles from "./styles";

interface Props {
  value?: any;
  billsId?: any;
  detailExpense?: any;
  closeModal?: () => void;
}

const initImage: any = null;

const EditBills = ({ billsId, detailExpense, value, closeModal }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetailBills(billsId));
    dispatch(emptyDetailBills());
    dispatch(getConfigUploadFile());
  }, [dispatch]);

  const dateFormat = (date: string) => {
    const mo = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const dt = date.split(" ")[0].split("-");
    return `${parseInt(dt[2], 0)} ${mo[parseInt(dt[1], 0) - 1]} ${dt[0]}`;
  };

  const detailBils = useSelector((state: Reducers) => state.cashAdvanceBill);
  const auth = useSelector((state: Reducers) => state.auth);
  const [form, setForm] = useState({
    description: "",
    amount: "",
    quantity: 1,
    title: ""
  });
  const [currency, setCurrency] = useState("");
  const [isBillable, setBillable] = useState(false);
  const [heightInputPurpose, setHeightInputPurpose] = useState(0);
  const [extension, setExtensions] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(initImage);
  const [count, setCount] = useState(0);

  const UploadDocument = (e: any) => {
    const file = e.target.files[0];
    const size = auth.myConfig.max_size / auth.myConfig.max_size;

    if (file.size <= auth.myConfig.max_size) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      };

      const extensions = e.target.files[0].name.split(".").pop();
      setExtensions(extensions);
      setName(e.target.files[0].name);
    } else {
      alert(`file to big! maximum size allowed ${size}MB.`);
    }
  };
  const deleteFile = () => {
    setImage(null);
    setName(null);
  };

  const renderFile = () => {
    if (extension === "png" || extension === "jpeg" || extension === "jpg") {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    if (
      extension === "pdf" ||
      extension === "docx" ||
      extension === "xls" ||
      extension === "xlsx"
    ) {
      return <FileUpload name={name} deleteFile={deleteFile} />;
    }
    return null;
  };
  return (
    <Card>
      {!detailBils.isloadingGetBills && detailBils.loadDataDetailBills ? (
        <View
          style={{
            width: 335,
            height: 720,
            paddingTop: 24
          }}
        >
          {count === 0 && detailBils.loadDataDetailBills != null ? (
            <>
              {setForm({
                description: detailBils.loadDataDetailBills.description,
                amount: detailBils.loadDataDetailBills.amount,
                quantity: 1,
                title: ""
              })}
              {setBillable(detailBils.loadDataDetailBills.billable)}
              {setCount(1)}
            </>
          ) : (
            <></>
          )}
          <View style={{ paddingHorizontal: 22 }}>
            <View style={styles.row}>
              <Text style={styles.labelText}>Code</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueNumberText}>
                  {detailExpense.number}
                </Text>
                <Text style={styles.valueProjectText}>
                  {detailExpense.project.title}
                </Text>
                <Text style={styles.valueNumberText}>
                  {detailExpense.category.title}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Team</Text>
              <View style={styles.wrapValue}>
                {detailExpense.users.map(
                  (
                    user: { user: { name: React.ReactNode } },
                    index: number
                  ) => (
                    <Text style={styles.valueText} key={index}>
                      {`${user.user.name}${
                        detailExpense.users.length - (index + 1) !== 0
                          ? ","
                          : ""
                      }`}
                    </Text>
                  )
                )}
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Date</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueText}>
                  {`${dateFormat(detailExpense.started_at)}${
                    dateFormat(detailExpense.finished_at) !==
                    dateFormat(detailExpense.started_at)
                      ? `${
                          detailExpense.finished_at !== null
                            ? ` - ${dateFormat(detailExpense.finished_at)}`
                            : ""
                        }`
                      : ""
                  }`}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Method</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueText}>Cash Advance</Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Category</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueCategoryText}>{value.category}</Text>
              </View>
            </View>
            <View style={[styles.row, { paddingTop: 10 }]}>
              <Text style={styles.labelText}>Desc.</Text>
              <View>
                <Input
                  value={form.description}
                  placeholder="Enter Description"
                  inputStyle={[
                    styles.inputStyle,
                    heightInputPurpose > 0 &&
                      form.description !== "" && { height: heightInputPurpose }
                  ]}
                  onChangeText={text => {
                    setForm({ ...form, description: text });
                  }}
                  multiline
                  onContentSizeChange={({ nativeEvent }) =>
                    setHeightInputPurpose(nativeEvent.contentSize.height)
                  }
                />
              </View>
            </View>
            <View style={[styles.row, { paddingTop: 10 }]}>
              <Text style={styles.labelText}>Billable</Text>
              <View>
                <Switch
                  onChange={() => setBillable(!isBillable)}
                  checked={isBillable}
                  onColor="#50b1b1"
                  onHandleColor="#fffff7"
                  handleDiameter={19}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                  height={19}
                  width={34}
                />
              </View>
            </View>
            <View style={{ marginTop: 30, alignItems: "center" }}>
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    color: "#666666"
                  }}
                >
                  Amount
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      borderBottomWidth: 0.5,
                      borderColor: "#E7E8E0",
                      borderStyle: "solid",
                      height: 36,
                      marginRight: 10.8,
                      width: 59
                    }}
                  >
                    <Select
                      listValue={[
                        { value: "IDR", label: "IDR" },
                        { value: "USD", label: "USD" }
                      ]}
                      value={currency}
                      onChange={e => setCurrency(e.target.value)}
                    />
                  </View>
                  <Input
                    value={form.amount}
                    keyboardType="numeric"
                    placeholder="Amount"
                    inputStyle={[
                      styles.inputStyle,
                      { textAlign: "right", width: 141 }
                    ]}
                    onChangeText={text => {
                      let _text: any = text.split(",").join("");
                      if (text === "") {
                        _text = "0";
                      } else {
                        _text = _text.split("");
                        const re = /^[0-9]*[0-9]$/i;
                        if (text.split(",").join("").match(re)) {
                          if (_text[0] === "0" && _text.length > 1) {
                            _text[0] = "";
                          }
                          _text = _text.join("");
                        }
                      }
                      setForm({ ...form, amount: _text });
                    }}
                    multiline
                  />
                </View>
              </View>
            </View>
            <View
              style={{
                alignContent: "center",
                alignSelf: "center",
                paddingTop: 20
              }}
            >
              {detailBils.loadDataDetailBills.file ? (
                <Image
                  source={{ uri: detailBils.loadDataDetailBills.file }}
                  style={{ width: 70, height: 70, borderRadius: 15 }}
                  resizeMode="contain"
                />
              ) : (
                <Text>No Image</Text>
              )}
            </View>
            <View>
              <View style={{ marginVertical: 20 }}>
                {image != null ? <View>{renderFile()}</View> : null}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Upload
                  onChange={(e: any) => UploadDocument(e)}
                  extionsion={extension}
                  multiple={false}
                />
              </View>
            </View>
            <View style={styles.footerOption}>
              <View style={styles.closeBill}>
                <Touchable
                  onPress={() => {
                    closeModal!();
                    dispatch(emptyListCashAdvanceBill());
                    dispatch(
                      editDetailBills(
                        { ...form, billable: isBillable },
                        billsId
                      )
                    );
                  }}
                >
                  <View style={styles.closeBillTextWrap}>
                    {detailBils.isLoadingEditBills ? (
                      <ActivityIndicator color="#fff" />
                    ) : (
                      <Text style={styles.closeBillText}>SAVE</Text>
                    )}
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </View>
      ) : (
        <View>
          <ActivityIndicator color="#000" />
        </View>
      )}
    </Card>
  );
};

export default EditBills;
