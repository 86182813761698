import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  cardStyle: {
    marginBottom: 12
  },
  wrapContentCard: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 12
  },
  wrapCardText: {
    flexDirection: "row",
    marginBottom: 4
  },
  wrapText: {
    flexDirection: "row",
    marginBottom: 4,
    paddingHorizontal: 16
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "23%"
  },
  textValue: {
    width: "77%",
    marginRight: 7
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.grey
  },
  wrapAmountText: {
    paddingHorizontal: 20,
    marginTop: -22
  },
  amountText: {
    color: COLORS.green,
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right"
  },
  line: {
    borderBottomWidth: 1,
    borderColor: COLORS.greyLight,
    marginTop: 8
  },
  wrapTotal: {
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16
  },
  totalLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11
  },
  emptyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  },
  wrapButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16
  },
  button: {
    width: "48%"
  },
  button2: {
    width: "100%",
    alignItems: "center",
    paddingVertical: 4
  },
  button3: {
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  buttonText: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.grey,
    fontWeight: "bold"
  },
  buttonText2: {
    color: COLORS.white
  },
  wrapTrashIcon: {
    height: 26,
    width: 26,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center"
  },
  addTaxContent: {
    width: widthPercent(100) - 30,
    marginVertical: 20,
    marginHorizontal: 24,
    backgroundColor: COLORS.main,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 3,
    paddingHorizontal: 20,
    borderRadius: 16,
    paddingVertical: 20
  },
  submitBtn: {
    // marginBottom: 24,
    height: 54,
    width: 180,
    borderRadius: 100,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
