import { StyleSheet } from "react-native";

import { heightPercent } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    height: heightPercent(100),
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#ed5a28",
    fontWeight: "bold"
  },
  buttonAttachmen: {
    marginTop: 122,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28, #db3a26)",
    borderRadius: 30
  },
  image: {
    width: 58.3,
    height: 58.5
  },
  content: {
    fontFamily: "Poppins",
    marginTop: 54.4,
    paddingHorizontal: 46,
    textAlign: "center",
    fontSize: 14,
    color: "#666666"
  },
  buttonText: {
    paddingHorizontal: 41,
    paddingVertical: 16,
    fontWeight: "bold",
    color: "white",
    fontFamily: "Poppins",
    fontSize: 16
  },
  cancel: {
    marginTop: 24,
    fontWeight: "bold",
    padding: 12,
    fontSize: 14,
    color: "#3f7790",
    fontFamily: "Poppins",
    textDecorationLine: "underline"
  },
  close: {
    position: "absolute",
    bottom: 10,
    left: 14
  },
  closeText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#3f7790",
    fontFamily: "Poppins"
  }
});

export default styles;
