import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";

const PermissionPage = (slug: string) => {
  const authState = useSelector((state: Reducers) => state.auth);
  const res = authState.myPermission.find((el: any) => el === slug);
  if (res) {
    return true;
  }
  return false;
};

export default PermissionPage;
