import React, { ReactNode } from "react";
import { View, ViewStyle } from "react-native";

interface Props {
  children?: ReactNode;
  backgroundImage: string;
  backgroundImageStyle?: object;
  style?: ViewStyle;
}

const Gradient = ({
  children,
  backgroundImage,
  style,
  backgroundImageStyle
}: Props) => (
  <div style={{ backgroundImage, ...backgroundImageStyle }}>
    <View style={style}>{children}</View>
  </div>
);

export default Gradient;
