import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

import { heightPercent } from "../../utils";

interface Props {
  active: boolean;
}

const styles = StyleSheet.create({
  wrapLoading: {
    height: heightPercent(10),
    justifyContent: "center"
  },
  text: {
    textAlign: "center",
    fontFamily: "Poppins"
  }
});

const LoadingLoadMore = ({ active }: Props) => {
  return active ? (
    <View style={styles.wrapLoading}>
      <ActivityIndicator color="#344370" size="large" />
    </View>
  ) : null;
};

export default LoadingLoadMore;
