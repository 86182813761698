import {
  DELETE_TRIP_ATTACHMENT_ERROR,
  DELETE_TRIP_ATTACHMENT_PENDING,
  DELETE_TRIP_ATTACHMENT_SUCCESS,
  DELETE_TRIP_ERROR,
  DELETE_TRIP_PENDING,
  DELETE_TRIP_REPORT_SUCCESS,
  DELETE_TRIP_SUCCESS,
  FORM_DATA_ACTIVITY,
  GET_ACCOUNTABLE_BY_TRIP_ID_ERROR,
  GET_ACCOUNTABLE_BY_TRIP_ID_PENDING,
  GET_ACCOUNTABLE_BY_TRIP_ID_SUCCESS,
  GET_CLOSE_TRIP_ERROR,
  GET_CLOSE_TRIP_PENDING,
  GET_CLOSE_TRIP_SUCCESS,
  GET_TERM_DATA_ACTIVITY_ERROR,
  GET_TERM_DATA_ACTIVITY_PENDING,
  GET_TERM_DATA_ACTIVITY_SUCCESS,
  GET_TRIP_APPROVAL_ADDITIONAL_ERROR,
  GET_TRIP_APPROVAL_ADDITIONAL_PENDING,
  GET_TRIP_APPROVAL_ADDITIONAL_SUCCESS,
  GET_TRIP_APPROVAL_ERROR,
  GET_TRIP_APPROVAL_PENDING,
  GET_TRIP_APPROVAL_SUCCESS,
  GET_TRIP_ASSIGN_ERROR,
  GET_TRIP_ASSIGN_PENDING,
  GET_TRIP_ASSIGN_SUCCESS,
  GET_TRIP_BILL_ERROR,
  GET_TRIP_BILL_MODERATION_ERROR,
  GET_TRIP_BILL_MODERATION_PENDING,
  GET_TRIP_BILL_MODERATION_SUCCESS,
  GET_TRIP_BILL_PENDING,
  GET_TRIP_BILL_SUCCESS,
  GET_TRIP_BY_ID_ERROR,
  GET_TRIP_BY_ID_PENDING,
  GET_TRIP_BY_ID_SUCCESS,
  GET_TRIP_HISTORY_ERROR,
  GET_TRIP_HISTORY_PENDING,
  GET_TRIP_HISTORY_SUCCESS,
  GET_TRIP_MODERATION_ERROR,
  GET_TRIP_MODERATION_PENDING,
  GET_TRIP_MODERATION_SUCCESS,
  GET_TRIP_ONGOING_ERROR,
  GET_TRIP_ONGOING_PENDING,
  GET_TRIP_ONGOING_SUCCESS,
  GET_TRIP_REPORT_ERROR,
  GET_TRIP_REPORT_PENDING,
  GET_TRIP_REPORT_SUCCESS,
  GET_TRIP_UPCOMING_ERROR,
  GET_TRIP_UPCOMING_PENDING,
  GET_TRIP_UPCOMING_SUCCESS,
  LOGOUT_SUCCESS,
  POST_GENERATE_ALLOWANCE_ERROR,
  POST_GENERATE_ALLOWANCE_PENDING,
  POST_GENERATE_ALLOWANCE_SUCCESS,
  POST_SUBMIT_ACTIVITY_ERROR,
  POST_SUBMIT_ACTIVITY_PENDING,
  POST_SUBMIT_ACTIVITY_SUCCESS,
  POST_TERM_DATA_ACTIVITY_ERROR,
  POST_TERM_DATA_ACTIVITY_PENDING,
  POST_TERM_DATA_ACTIVITY_SUCCESS,
  PUT_CLOSEBILL_SUCCESS,
  PUT_FILTER_HISTORY_TRIP_SUCCESS,
  PUT_MODERATION_PENDING,
  PUT_MODERATION_SUCCESS,
  PUT_RESOLVE_TRIP_ERROR,
  PUT_RESOLVE_TRIP_PENDING,
  PUT_RESOLVE_TRIP_SUCCESS,
  PUT_SUBMIT_TRIP_REPORT_SUCCESS,
  RESET_CLOSE_TRIP_ERROR,
  RESET_SEARCH_TRIP_VALUE,
  SET_SEARCH_TASK_VALUE
} from "../actions";
import { Action, TripState } from "../types";

const initialState: TripState = {
  listTripReport: [],
  isLoadingTripReport: false,
  isLoadingModeration: false,
  isLoadingListUpcoming: false,
  listUpcoming: [],
  loadMoreUpcoming: true,
  paramUpcoming: {
    filter: "",
    type: "upcoming",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
  },
  isLoadingListOngoing: false,
  listOngoing: [],
  loadMoreOngoing: true,
  paramOngoing: {
    filter: "",
    type: "ongoing",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
  },
  isLoadingListAssign: false,
  listAssign: [],
  loadMoreAssign: true,
  paramAssign: {
    filter: "",
    type: "assigned",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
  },
  isLoadingListHistory: false,
  listHistory: [],
  loadMoreHistory: true,
  paramHistory: {
    filter: "",
    type: "finished",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      // eslint-disable-next-line max-len
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking,djurneeBooking.booking.source"
  },
  isLoadingListApproval: false,
  listApproval: [],
  loadMoreApproval: true,
  paramApproval: {
    filter: "",
    type: "issued",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
  },
  isLoadingListBill: false,
  listBill: [],
  loadMoreBill: true,
  paramBill: {
    filter: "",
    type: "bill",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
  },
  isLoadingListApprovalAdditional: false,
  listApprovalAdditional: [],
  loadMoreApprovalAdditional: true,
  paramApprovalAdditional: {
    type: "issued",
    per_page: 10,
    page: 1,
    sortField: "created_at",
    sortOrder: "desc",
    include:
      "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
  },
  isLoadingTripById: false,
  tripData: null,
  isLoadingPutResolveTrip: false,
  isLoadingDeleteTripAttachment: false,
  isSuccessDeleteTripAttachment: false,
  isErrorDeleteTripAttachment: false,
  isLoadingCloseTrip: false,
  closeTrip: null,
  activity: {
    isLoading: false,
    data: { new_activity: { project_id: "" } },
    getData: true
  },
  allowance: {
    list: [],
    isLoading: false
  },
  isLoadingSubmitAtivity: false,
  isLoadingDeleteTrip: false,
  isLoadingGetAccountableByTripId: false,
  accountableTripData: null,
  isLoadingGettripModeration: false,
  tripModerationData: [],
  isLoadingGettripBillModeration: false,
  tripBillModerationData: [],
  searchValue: ""
};

let listTripReport: any[];
let indexReport = 0;

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // upcoming
    case GET_TRIP_UPCOMING_PENDING:
      return { ...state, isLoadingListUpcoming: !payload.loadMore };
    case GET_TRIP_UPCOMING_SUCCESS:
      return {
        ...state,
        isLoadingListUpcoming: false,
        listUpcoming: !payload.loadMore
          ? payload.data
          : [...state.listUpcoming, ...payload.data],
        paramUpcoming: {
          ...state.paramUpcoming,
          page: !payload.loadMore ? 2 : state.paramUpcoming.page + 1
        },
        loadMoreUpcoming: !(payload.data.length < state.paramUpcoming.per_page)
      };
    case GET_TRIP_UPCOMING_ERROR:
      return { ...state, isLoadingListUpcoming: false };

    // ongoing
    case GET_TRIP_ONGOING_PENDING:
      return { ...state, isLoadingListOngoing: !payload.loadMore };
    case GET_TRIP_ONGOING_SUCCESS:
      return {
        ...state,
        isLoadingListOngoing: false,
        listOngoing: !payload.loadMore
          ? payload.data
          : [...state.listOngoing, ...payload.data],
        paramOngoing: {
          ...state.paramOngoing,
          page: !payload.loadMore ? 2 : state.paramOngoing.page + 1
        },
        loadMoreOngoing: !(payload.data.length < state.paramOngoing.per_page)
      };
    case GET_TRIP_ONGOING_ERROR:
      return { ...state, isLoadingListOngoing: false };

    // assign
    case GET_TRIP_ASSIGN_PENDING:
      return { ...state, isLoadingListAssign: !payload.loadMore };
    case GET_TRIP_ASSIGN_SUCCESS:
      return {
        ...state,
        isLoadingListAssign: false,
        listAssign: !payload.loadMore
          ? payload.data
          : [...state.listAssign, ...payload.data],
        paramAssign: {
          ...state.paramAssign,
          page: !payload.loadMore ? 2 : state.paramAssign.page + 1
        },
        loadMoreAssign: !(payload.data.length < state.paramAssign.per_page)
      };
    case GET_TRIP_ASSIGN_ERROR:
      return { ...state, isLoadingListAssign: false };

    // history
    case GET_TRIP_HISTORY_PENDING:
      return { ...state, isLoadingListHistory: !payload.loadMore };
    case GET_TRIP_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingListHistory: false,
        listHistory: !payload.loadMore
          ? payload.data
          : [...state.listHistory, ...payload.data],
        paramHistory: {
          ...state.paramHistory,
          page: !payload.loadMore ? 2 : state.paramHistory.page + 1
        },
        loadMoreHistory: !(payload.data.length < state.paramHistory.per_page)
      };
    case GET_TRIP_HISTORY_ERROR:
      return { ...state, isLoadingListHistory: false };

    case PUT_FILTER_HISTORY_TRIP_SUCCESS:
      return {
        ...state,
        paramHistory: payload.data
      };

    // approval
    case GET_TRIP_APPROVAL_PENDING:
      return { ...state, isLoadingListApproval: !payload.loadMore };
    case GET_TRIP_APPROVAL_SUCCESS:
      return {
        ...state,
        isLoadingListApproval: false,
        listApproval: !payload.loadMore
          ? payload.data
          : [...state.listApproval, ...payload.data],
        paramApproval: {
          ...state.paramApproval,
          page: !payload.loadMore ? 2 : state.paramApproval.page + 1
        },
        loadMoreApproval: !(payload.data.length < state.paramApproval.per_page)
      };
    case GET_TRIP_APPROVAL_ERROR:
      return { ...state, isLoadingListApproval: false };

    // bill
    case GET_TRIP_BILL_PENDING:
      return { ...state, isLoadingListBill: !payload.loadMore };
    case GET_TRIP_BILL_SUCCESS:
      return {
        ...state,
        isLoadingListBill: false,
        listBill: !payload.loadMore
          ? payload.data
          : [...state.listBill, ...payload.data],
        paramBill: {
          ...state.paramBill,
          page: !payload.loadMore ? 2 : state.paramBill.page + 1
        },
        loadMoreBill: !(payload.data.length < state.paramBill.per_page)
      };
    case GET_TRIP_BILL_ERROR:
      return { ...state, isLoadingListBill: false };

    case GET_TRIP_BY_ID_PENDING:
      return { ...state, isLoadingTripById: true };
    case GET_TRIP_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingTripById: false,
        tripData: payload.data
      };
    case GET_TRIP_BY_ID_ERROR:
      return { ...state, isLoadingTripById: false };
    case GET_TRIP_REPORT_PENDING:
      return { ...state, isLoadingTripById: true };
    case GET_TRIP_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingTripById: false,
        listTripReport: payload.data
      };
    case GET_TRIP_REPORT_ERROR:
      return { ...state, isLoadingTripReport: false };
    case PUT_MODERATION_PENDING:
      return { ...state, isLoadingTripReport: true };
    case PUT_MODERATION_SUCCESS:
      return {
        ...state,
        listTripReport: payload.data,
        isLoadingTripReport: false
      };

    case GET_TRIP_APPROVAL_ADDITIONAL_PENDING:
      return { ...state, isLoadingListApprovalAdditional: !payload.loadMore };
    case GET_TRIP_APPROVAL_ADDITIONAL_SUCCESS:
      return {
        ...state,
        isLoadingListApprovalAdditional: false,
        listApprovalAdditional: payload.data,
        paramApprovalAdditional: {
          ...state.paramApprovalAdditional,
          page: !payload.loadMore ? 2 : state.paramApprovalAdditional.page + 1
        },
        loadMoreApprovalAdditional: !(
          payload.data.length < state.paramApprovalAdditional.per_page
        )
      };
    case GET_TRIP_APPROVAL_ADDITIONAL_ERROR:
      return { ...state, isLoadingListApprovalAdditional: false };

    // get activity
    case GET_TERM_DATA_ACTIVITY_PENDING:
      return { ...state, activity: { ...state.activity, isLoading: true } };
    case GET_TERM_DATA_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          isLoading: false,
          data: payload.data,
          getData: false
        }
      };
    case GET_TERM_DATA_ACTIVITY_ERROR:
      return { ...state, activity: { ...state.activity, isLoading: false } };

    // post allowance
    case POST_GENERATE_ALLOWANCE_PENDING:
      return { ...state, allowance: { ...state.allowance, isLoading: true } };
    case POST_GENERATE_ALLOWANCE_SUCCESS:
      return {
        ...state,
        allowance: {
          ...state.allowance,
          isLoading: false,
          list: payload.data
        }
      };
    case POST_GENERATE_ALLOWANCE_ERROR:
      return { ...state, allowance: { ...state.allowance, isLoading: false } };

    // post activy
    case POST_TERM_DATA_ACTIVITY_PENDING:
      return { ...state, activity: { ...state.activity, isLoading: true } };
    case POST_TERM_DATA_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          isLoading: false,
          data: payload.data ? {} : state.activity.data
        }
      };
    case POST_TERM_DATA_ACTIVITY_ERROR:
      return { ...state, activity: { ...state.activity, isLoading: false } };

    // post submit
    case POST_SUBMIT_ACTIVITY_PENDING:
      return { ...state, isLoadingSubmitAtivity: true };
    case POST_SUBMIT_ACTIVITY_SUCCESS:
      return { ...state, isLoadingSubmitAtivity: false };
    case POST_SUBMIT_ACTIVITY_ERROR:
      return { ...state, isLoadingSubmitAtivity: false };

    case FORM_DATA_ACTIVITY:
      return {
        ...state,
        activity: {
          ...state.activity,
          data: {
            ...state.activity.data,
            [payload.formName!]: payload.formValue
          }
        }
      };

    // delete report
    case DELETE_TRIP_REPORT_SUCCESS:
      return {
        ...state,
        listTripReport: state.listTripReport.filter(e => e.id !== payload.id)
      };

    // submit report
    case PUT_SUBMIT_TRIP_REPORT_SUCCESS:
      indexReport = state.listTripReport.findIndex(e => e.id === payload.id);
      listTripReport = [...state.listTripReport];
      listTripReport[indexReport].status = 2;

      return { ...state, listTripReport };

    // put resolve trip
    case PUT_RESOLVE_TRIP_PENDING:
      return { ...state, isLoadingPutResolveTrip: true };
    case PUT_RESOLVE_TRIP_SUCCESS:
      return { ...state, isLoadingPutResolveTrip: false };
    case PUT_RESOLVE_TRIP_ERROR:
      return { ...state, isLoadingPutResolveTrip: false };

    // put close bill
    case PUT_CLOSEBILL_SUCCESS:
      return {
        ...state,
        tripData: {
          ...state.tripData,
          djurneeTrip: { ...state.tripData.djurneeTrip, expense: false }
        }
      };

    case DELETE_TRIP_ATTACHMENT_SUCCESS:
      return {
        ...state,
        tripData: { ...state.tripData, file: null },
        isLoadingDeleteTripAttachment: false,
        isSuccessDeleteTripAttachment: true,
        isErrorDeleteTripAttachment: false
      };
    case DELETE_TRIP_ATTACHMENT_PENDING:
      return {
        ...state,
        isLoadingDeleteTripAttachment: true,
        isSuccessDeleteTripAttachment: false,
        isErrorDeleteTripAttachment: false
      };
    case DELETE_TRIP_ATTACHMENT_ERROR:
      return {
        ...state,
        isLoadingDeleteTripAttachment: false,
        isSuccessDeleteTripAttachment: false,
        isErrorDeleteTripAttachment: true
      };

    case GET_CLOSE_TRIP_SUCCESS:
      return {
        ...state,
        isLoadingCloseTrip: false,
        closeTrip: payload.data
      };
    case GET_CLOSE_TRIP_PENDING:
      return {
        ...state,
        isLoadingCloseTrip: true,
        closeTrip: null
      };
    case GET_CLOSE_TRIP_ERROR:
      return {
        ...state,
        isLoadingCloseTrip: false,
        closeTrip: null
      };
    case RESET_CLOSE_TRIP_ERROR:
      return {
        ...state,
        isLoadingCloseTrip: false,
        closeTrip: null
      };

    // DELETE resolve trip
    case DELETE_TRIP_PENDING:
      return { ...state, isLoadingDeleteTrip: true };
    case DELETE_TRIP_SUCCESS:
      return { ...state, isLoadingDeleteTrip: false };
    case DELETE_TRIP_ERROR:
      return { ...state, isLoadingDeleteTrip: false };

    // GET ACCOUNTABLE TRIP
    case GET_ACCOUNTABLE_BY_TRIP_ID_PENDING:
      return { ...state, isLoadingGetAccountableByTripId: true };
    case GET_ACCOUNTABLE_BY_TRIP_ID_SUCCESS:
      return {
        ...state,
        isLoadingGetAccountableByTripId: false,
        accountableTripData: payload.data
      };
    case GET_ACCOUNTABLE_BY_TRIP_ID_ERROR:
      return { ...state, isLoadingGetAccountableByTripId: false };

    // get moderation trip
    case GET_TRIP_MODERATION_PENDING:
      return { ...state, isLoadingGetTripModeration: true };
    case GET_TRIP_MODERATION_SUCCESS:
      return {
        ...state,
        isLoadingGetTripModeration: false,
        tripModerationData: payload.data
      };
    case GET_TRIP_MODERATION_ERROR:
      return { ...state, isLoadingGetTripModeration: false };

    // get moderation trip bill
    case GET_TRIP_BILL_MODERATION_PENDING:
      return { ...state, isLoadingGetTripBillModeration: true };
    case GET_TRIP_BILL_MODERATION_SUCCESS:
      return {
        ...state,
        isLoadingGetTripBillModeration: false,
        tripBillModerationData: payload.data
      };
    case GET_TRIP_BILL_MODERATION_ERROR:
      return { ...state, isLoadingGetTripBillModeration: false };

    case SET_SEARCH_TASK_VALUE:
      return { ...state, searchValue: payload.data };

    case RESET_SEARCH_TRIP_VALUE:
      return { ...state, searchValue: initialState.searchValue };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
