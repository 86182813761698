import React from "react";
import { View } from "react-native";
import Select from "react-select";

import styles from "./styles";

interface Props {
  placeHolder?: string;
  color?: any;
  style?: any;
  option: { value: string; label: string }[];
  onValueChange?: (text: any) => void;
  defaultValue?: any;
}
const PickerSelect = ({
  option,
  onValueChange,
  color,
  placeHolder,
  style,
  defaultValue
}: Props) => (
  <View style={style}>
    <Select
      name="form-field-name"
      defaultInputValue={defaultValue}
      options={option}
      placeholder={placeHolder}
      styles={{
        ...styles,
        control: base => ({
          ...base,
          backgroundColor: "transparent",
          fontSize: "100%",
          fontFamily: "Poppins",
          fontWeight: "bold",
          zIndex: 99,
          "&:hover": { borderColor: "gray" },
          border: "none",
          boxShadow: "none"
        }),
        option: () => {
          return {
            zIndex: 1,
            margin: 10,
            justifyContent: "",
            borderBottomColor: "#000",
            backgroundColor: "#fff",
            fontSize: 14,
            fontFamily: "Poppins"
          };
        },
        valueContainer: () => {
          return {
            textOverflow: "ellipsis",
            textAlign: "center",
            overflow: "hidden"
          };
        }
      }}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: "orangered",
          neutral50: color,
          neutral80: color
        }
      })}
      onChange={onValueChange}
    />
  </View>
);

PickerSelect.defaultProps = {
  color: "#7e7e7e",
  placeHolder: "Select City"
};
export default PickerSelect;
