/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import moment from "moment";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Confirmation, Fixed, Modal, Touchable } from "../../../components";

import styles from "./styles";
import { ICONS, IMAGES } from "../../../configs";
import { Reducers } from "../../../redux/types";
import {
  getAvailableDateTaskReport,
  getDetailTaskReport,
  setTaskDetailReportWantEdit,
  submitEndtask,
  submitTaskDetailReport
} from "../../../redux/actions";
import { permissionPage, textToLink } from "../../../utils";

interface Props {
  id: string;
  userId: string;
  pic: string;
}

const Report = ({ id, userId, pic }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uuid } = useSelector((state: Reducers) => state.auth);
  const detailTaskReport = useSelector((state: Reducers) => state.task)
    .detailTaskReportData;
  const isLoading = useSelector((state: Reducers) => state.task)
    .isLoadingDetailTaskReportData;
  const taskDetailData = useSelector((state: Reducers) => state.task);
  const [assigner, setAssigner] = useState(false);

  const permission = {
    selfEdit: permissionPage("task-self-edit")
  };
  const searchSelfTask = () => {
    const taskData =
      taskDetailData.detailTaskData && taskDetailData.detailTaskData.assignee;
    const res = taskData.map((e: any) => e.user.id).indexOf(uuid);
    if (res !== -1) {
      return res;
    }
    return 0;
  };

  const [indexProfile, setIndexProfile] = useState(searchSelfTask());
  const [modalEndTask, setModalEndTask] = useState(false);

  useEffect(() => {
    if (
      taskDetailData.detailTaskData.assigner.uuid === userId ||
      userId === pic
    ) {
      if (userId !== pic) {
        setAssigner(true);
      }

      dispatch(
        getDetailTaskReport(
          id,
          taskDetailData.detailTaskData.assignee[indexProfile].user.id
        )
      );
      dispatch(
        getAvailableDateTaskReport(
          id,
          taskDetailData.detailTaskData.assignee[indexProfile].user.id
        )
      );
    } else {
      dispatch(getDetailTaskReport(id, uuid));
      dispatch(getAvailableDateTaskReport(id, uuid));
    }
  }, [dispatch, indexProfile]);

  const _goToEditReportPage = (taskData: any) => {
    dispatch(setTaskDetailReportWantEdit(taskData));
    history.push(`${history.location.pathname}/edit`);
  };

  const _handleSubmit = (reportId: string, date: string, desc: string) => {
    const body = {
      date,
      description: desc
    };
    dispatch(submitTaskDetailReport(id, uuid, reportId, body));
  };

  const _handleEndTask = () => {
    if (!taskDetailData.isLoadingEndtask) {
      dispatch(submitEndtask(id));
      setModalEndTask(false);
    }
  };

  return (
    <View style={{ width: "100%", alignItems: "center" }}>
      {detailTaskReport && !isLoading ? (
        <View style={{ width: "100%", alignItems: "center" }}>
          {assigner || userId === pic ? (
            <View
              style={{
                width: "100%",
                height: 36,
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: 20
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIndexProfile(indexProfile - 1);
                }}
                style={{
                  height: "100%",
                  width: 36,
                  justifyContent: "center",
                  alignItems: "center"
                }}
                disabled={indexProfile === 0}
              >
                {indexProfile !== 0 ? (
                  <Image
                    resizeMode="contain"
                    source={ICONS.prevButton}
                    style={{ height: 24, width: 12 }}
                  />
                ) : null}
              </TouchableOpacity>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  justifyContent: "center"
                }}
              >
                <View style={[styles.cardPhotos]}>
                  <Image
                    resizeMode="contain"
                    source={
                      taskDetailData.detailTaskData.assignee[0].employee.avatar
                        ? taskDetailData.detailTaskData.assignee[0].employee
                            .avatar.url
                        : IMAGES.avatar
                    }
                    style={{ height: 32, width: 32, borderRadius: 100 }}
                  />
                </View>
                <View
                  style={{
                    height: 36,
                    marginLeft: 5,
                    justifyContent: "center"
                  }}
                >
                  <Text
                    style={{
                      fontSize: 11,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: "#666666"
                    }}
                  >
                    {
                      taskDetailData.detailTaskData.assignee[indexProfile]
                        .employee.name
                    }
                  </Text>
                  <Text
                    style={{
                      fontSize: 11,
                      fontFamily: "Poppins",
                      marginTop: -5,
                      color: "#666666"
                    }}
                  >
                    {
                      taskDetailData.detailTaskData.assignee[indexProfile]
                        .employee.position_name
                    }
                  </Text>
                </View>
              </View>

              <TouchableOpacity
                onPress={() => {
                  setIndexProfile(indexProfile + 1);
                }}
                style={{
                  height: "100%",
                  width: 36,
                  justifyContent: "center",
                  alignItems: "center"
                }}
                disabled={
                  taskDetailData.detailTaskData.assignee.length - 2 <
                  indexProfile
                }
              >
                {taskDetailData.detailTaskData.assignee.length - 1 >
                indexProfile ? (
                  <Image
                    resizeMode="contain"
                    source={ICONS.nextButton}
                    style={{ height: 24, width: 12 }}
                  />
                ) : null}
              </TouchableOpacity>
            </View>
          ) : null}
          {detailTaskReport.map((taskDetail: any, index: number) => (
            <View style={{ width: "100%" }} key={index}>
              <View
                style={{
                  backgroundColor:
                    taskDetail.status !== 2 ? "#F5AA3F" : "#50B1B1",
                  width: "100%",
                  height: 40,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={styles.statusCard}>
                  <View style={{ height: "100%", justifyContent: "center" }}>
                    <Text style={{ color: "white" }}>
                      {moment(taskDetail.date).format("ddd ")}
                      <Text style={{ fontWeight: "bold" }}>
                        {moment(taskDetail.date).format("DD MMMM YYYY")}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {taskDetailData.detailTaskData.assignee[indexProfile].user
                      .id === uuid &&
                    taskDetail.status !== 2 &&
                    !assigner &&
                    permission.selfEdit ? (
                      <TouchableOpacity
                        style={{
                          backgroundColor: "white",
                          height: 25,
                          width: 25,
                          borderRadius: 100,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                        onPress={() =>
                          _handleSubmit(
                            taskDetail.id,
                            taskDetail.date,
                            taskDetail.description
                          )
                        }
                      >
                        <Image
                          source={IMAGES.checkList}
                          resizeMode="contain"
                          style={{ width: 16, height: 16 }}
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>
              </View>
              <Touchable
                width="100%"
                key={index}
                onPress={e => {
                  _goToEditReportPage(taskDetail);
                  e.stopPropagation();
                }}
              >
                <View
                  style={[
                    styles.card,
                    { width: "100%", height: 100, marginBottom: 12 }
                  ]}
                >
                  <View style={{ width: "92%" }}>
                    <Text
                      style={{
                        marginTop: 12,
                        fontSize: 11,
                        color: "#707070",
                        fontFamily: "Poppins",
                        height: 80
                      }}
                      numberOfLines={3}
                    >
                      <div
                        dangerouslySetInnerHTML={textToLink(
                          taskDetail.description
                        )}
                      />
                    </Text>
                    <Text
                      style={{
                        marginTop: 30,
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#707070",
                        fontFamily: "Poppins"
                      }}
                    >
                      {`${taskDetail.file_collection.length} `}
                      {t("task.assign.attachment")}
                    </Text>
                  </View>
                </View>
              </Touchable>
            </View>
          ))}
          <View
            style={{ width: "100%", alignItems: "center", marginBottom: 100 }}
          >
            {!assigner &&
            (userId !== pic ||
              taskDetailData.detailTaskData.assignee[indexProfile].user.id ===
                uuid) &&
            taskDetailData.detailTaskData.status < 6 &&
            taskDetailData.availableDateTaskReport &&
            permission.selfEdit &&
            taskDetailData.availableDateTaskReport.length > 0 ? (
              <TouchableOpacity
                style={[styles.button, { marginTop: 8 }]}
                onPress={() => {
                  history.push(`${history.location.pathname}/add`);
                }}
              >
                <Text style={styles.textButton}>
                  {t("task.report.addReport")}
                </Text>
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
      ) : (
        <ActivityIndicator style={{ marginTop: 20 }} />
      )}
      <Fixed position="bottom">
        <View
          style={{
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {pic === userId &&
          taskDetailData.detailTaskData.status < 6 &&
          permission.selfEdit ? (
            <TouchableOpacity
              activeOpacity={0.9}
              style={[
                styles.button,
                { marginBottom: 24, width: 180, height: 54 },
                taskDetailData.isLoadingEndtask
                  ? { backgroundColor: "#666666" }
                  : null
              ]}
              onPress={e => {
                e.stopPropagation();
                setModalEndTask(true);
              }}
            >
              {taskDetailData.isLoadingEndtask ? (
                <ActivityIndicator />
              ) : (
                <Text style={[styles.textButton, { fontSize: 18 }]}>
                  {t("task.report.endTask")}
                </Text>
              )}
            </TouchableOpacity>
          ) : null}
        </View>
      </Fixed>
      <Modal isVisible={modalEndTask} backdropColor="rgba(255,255,255, 0.97)">
        <Confirmation
          headerTitle={t("task.report.endTask")}
          actionTitle={t("task.detail.yes")}
          description={t("task.report.confirmationEnd")}
          onCancel={() => setModalEndTask(false)}
          isLoading={taskDetailData.isLoadingEndtask}
          onAction={_handleEndTask}
        />
      </Modal>
    </View>
  );
};
export default Report;
