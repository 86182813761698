import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20
  },
  wrapTaskNav: {
    paddingTop: 20,
    backgroundColor: COLORS.main
  },
  taskNav: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 80,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    height: "fit-content",
    width: "fir-content",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  wrapBtn: {
    height: "100%",
    justifyContent: "center",
    width: "80%",
    alignItems: "center"
  },
  btnNav: {
    height: 34,
    flex: 1,
    alignItems: "center",
    lineHeight: 36
  },
  btnText: {
    fontWeight: "bold",
    fontFamily: "poppins",
    fontSize: 11,
    color: "#ffffff"
  },
  active: {
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)",
    flex: 1
  },
  activeFirst: {
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)",
    flex: 1,
    borderTopLeftRadius: 80,
    borderBottomLeftRadius: 80
  },
  activeLast: {
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)",
    flex: 1,
    borderTopRightRadius: 80,
    borderBottomRightRadius: 80
  }
});

export default styles;
