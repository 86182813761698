import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  text: {
    color: COLORS.white,
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18
  },
  container: {
    paddingVertical: 24,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: COLORS.main
  },
  wrapButtonSave: {
    borderRadius: 28,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    overflow: "hidden",
    marginRight: 16,
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  wrapButtonSubmit: {
    borderRadius: 28,
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  button: {
    width: 125,
    height: 54,
    justifyContent: "center",
    alignItems: "center"
  },
  buttonDisable: {
    backgroundColor: COLORS.greyLight,
    backgroundImage: null
  }
});

export default styles;
