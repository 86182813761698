import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  container: {
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    height: 24,
    width: 24,
    borderRadius: 20,
    position: "absolute",
    top: -10,
    right: 2,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 1.8
  },
  valueStyle: {
    fontFamily: "Poppins",
    fontSize: 11,
    fontWeight: "bold",
    color: COLORS.white
  }
});

export default styles;
