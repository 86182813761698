import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  form: {
    flexDirection: "row",
    paddingHorizontal: 20,
    marginTop: 25,
    marginBottom: 18
  },
  label: {
    fontSize: 14,
    color: "#666666",
    paddingLeft: 10,
    marginBottom: 8
  },
  labelRating: {
    alignSelf: "center",
    marginTop: 18,
    marginBottom: 7.8,
    color: "#666666"
  },
  star: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center"
  },
  price: {
    borderBottomWidth: 1,
    borderColor: "#666666",
    paddingVertical: 10,
    marginRight: 10,
    marginLeft: 10
  },
  row: {
    width: "50%",
    height: 50
  },
  buttonFacility: {
    marginBottom: 9,
    marginHorizontal: 8,
    height: 40,
    width: "45%",
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  datePicker: {
    paddingHorizontal: 15,
    height: 20,
    marginVertical: 9
  },
  datePickerNav: {
    width: 168,
    height: 70
  },
  datePickerNavWrap: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)"
  },
  datePickerNavActive: {
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  }
});
export default styles;
