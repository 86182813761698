import React, { memo } from "react";
import { Text, View } from "react-native";

import i18n from "i18next";
import { Touchable } from "../../../../components";
import styles from "./styles";

interface Props {
  onPressNext?: () => void;
  onPressSave?: () => void;
}

const Footer = ({ onPressNext, onPressSave }: Props) => {
  const listButton = [
    {
      label: i18n.t("activity.new.save"),
      onPress: onPressSave
    },
    { label: i18n.t("activity.new.next"), onPress: onPressNext }
  ];

  return (
    <View style={styles.container}>
      {listButton.map((item, index) => (
        <View
          key={index}
          style={[
            index === 0 ? styles.wrapButtonSave : styles.wrapButtonSubmit,
            !item.onPress && styles.buttonDisable
          ]}
        >
          <Touchable onPress={item.onPress} disabled={!item.onPress}>
            <View style={styles.button}>
              <Text style={styles.text}>{item.label}</Text>
            </View>
          </Touchable>
        </View>
      ))}
    </View>
  );
};
export default memo(Footer);
