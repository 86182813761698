import React, { ReactNode } from "react";
import {
  KeyboardType,
  NativeSyntheticEvent,
  TextInput,
  TextInputContentSizeChangeEventData,
  TextStyle,
  View,
  ViewStyle
} from "react-native";

import styles from "./styles";
import "./styles.css";

interface Props {
  readonly?: boolean;
  placeholder?: string;
  onChangeText?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string;
  defaultValue?: any;
  borderColor?: string;
  style?: ViewStyle | ViewStyle[] | TextStyle;
  inputStyle?: Record<string, any>;
  textColor?: string;
  securityText?: boolean;
  endEditing?: (value: any) => void;
  multiline?: boolean;
  numberOfLines?: number;
  keyboardType?: KeyboardType;
  noBorder?: boolean;
  onContentSizeChange?: (
    e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => void;
  leftComponent?: ReactNode;
}

const Input = ({
  readonly,
  placeholder,
  onChangeText,
  value,
  borderColor,
  style,
  textColor,
  inputStyle,
  securityText,
  endEditing,
  multiline,
  numberOfLines,
  keyboardType,
  onContentSizeChange,
  defaultValue,
  onFocus,
  onBlur,
  noBorder,
  leftComponent
}: Props) => (
  <View
    style={[
      styles.wrapTextInput,
      { borderBottomColor: borderColor },
      style,
      noBorder && { borderBottomWidth: 0 }
    ]}
  >
    {leftComponent}
    <TextInput
      onFocus={onFocus}
      onBlur={onBlur}
      editable={!readonly}
      defaultValue={defaultValue}
      placeholder={placeholder}
      style={[styles.textInput, { color: textColor }, inputStyle]}
      onChangeText={onChangeText}
      value={value}
      secureTextEntry={securityText}
      autoCapitalize="none"
      onKeyPress={endEditing}
      numberOfLines={numberOfLines}
      multiline={multiline}
      keyboardType={keyboardType}
      onContentSizeChange={onContentSizeChange}
    />
  </View>
);

Input.defaultProps = {
  borderColor: "rgba(102, 102, 102, 0.5)",
  textColor: "#000000",
  securityText: false,
  keyboardType: "default",
  noBorder: false
};

export default Input;
