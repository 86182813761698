import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  keyData: {
    color: "#999999",
    fontSize: 12,
    fontFamily: "Poppins"
  }
});

export default styles;
