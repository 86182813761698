import React from "react";
import { Text, View } from "react-native";

import {
  convertDate,
  documentTitle,
  maskedMoney
} from "../../../../../../utils";
import { WrapContent } from "../../../../../../components";
import styles from "./styles";

const Allowance = () => {
  documentTitle("Detail Activity - Allowance");

  const dummyData = [
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    },
    {
      started_at: "2010-12-12",
      category_title: "Fuel",
      title: "Halo",
      price: 20000
    }
  ];

  return (
    <WrapContent isLoading={false}>
      <View style={styles.content}>
        {dummyData.map((item, index) => (
          <View key={index} style={styles.row}>
            <View style={styles.wrapDesc}>
              <View style={styles.col1}>
                <Text style={styles.title}>Date</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.desc}>
                  {convertDate(item.started_at, "DD MMM YYYY")}
                </Text>
              </View>
            </View>
            <View style={styles.wrapDesc}>
              <View style={styles.col1}>
                <Text style={styles.title}>Type</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.desc}>{item.category_title}</Text>
              </View>
            </View>
            <View style={styles.wrapDesc}>
              <View style={styles.col1}>
                <Text style={styles.title}>Desc</Text>
              </View>
              <View style={styles.col2}>
                <Text style={styles.desc}>{item.title}</Text>
              </View>
              <View style={styles.col3}>
                <Text style={styles.nominal}>
                  IDR
                  <Text style={[styles.nominal, { fontWeight: "bold" }]}>
                    {` ${maskedMoney(item.price)}`}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        ))}

        <View style={styles.wrapTotal}>
          <Text style={styles.titleTotal}>Total Allowance</Text>
          <Text style={styles.nominal}>
            IDR
            <Text style={[styles.nominal, { fontWeight: "bold" }]}>
              {` ${maskedMoney(
                dummyData.reduce((a, b) => a + (b.price || 0), 0)
              )}`}
            </Text>
          </Text>
        </View>
      </View>
    </WrapContent>
  );
};

export default Allowance;
