import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  flightTitle: {
    fontSize: 14,
    marginLeft: 15,
    marginBottom: 8,
    color: "#666666",
    fontFamily: "Poppins"
  },
  cardTripList: {
    paddingHorizontal: 15,
    paddingTop: 12,
    paddingBottom: 13,
    width: "50%"
  },
  cardTripListTo: {
    width: "50%"
  },
  fromTitle: {
    fontSize: 14,
    color: "#50b1b1",
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  fromDetail: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: "#666666"
  },
  toTitle: {
    color: "#ed5a28"
  },
  buttonFacility: {
    marginBottom: 9,
    marginHorizontal: 8,
    width: "46%",
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  cardButton: {
    width: "fit-content",
    alignSelf: "center",
    borderRadius: 40,
    marginTop: 34,
    marginBottom: 32,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  textButton: {
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18
  },
  titleTrip: {
    fontSize: 14,
    color: "#666666",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  datePickerNav: {
    width: 168,
    height: 70
  },
  datePickerNavWrap: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)"
  },
  datePickerNavActive: {
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  wrapFormFlight: {
    paddingHorizontal: 14
  },
  formFlight: {
    marginVertical: 14
  },
  textTitleInput: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  wrapSelect: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderStyle: "solid"
  },
  rowFormFlight: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  wrapInput: {
    flex: 1
  }
});

export default styles;
