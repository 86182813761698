/* eslint-disable valid-typeof */
import React, { useState } from "react";
import { FlatList, Image, ImageProps, Text, View } from "react-native";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

// import { putNewTrip } from "../../../redux/actions";

import styles from "./styles";
import { IMAGES } from "../../../configs";
import Card from "../../NewTrip/components/Card";
import { Reducers } from "../../../redux/types";
import { Modal, Touchable, WrapContent } from "../../../components";
import { documentTitle, queryString } from "../../../utils";
import NoteRevise from "../ModalNoteRevise";
import ModalResolve from "../ModalResolve";

interface Item {
  image: ImageProps;
}

const dataAttc = [
  {
    image: IMAGES.avatar
  }
];

interface Props {
  id: string;
  search: string;
}

const Trip = ({ id, search }: Props) => {
  documentTitle("Trip Detail - Trip");
  // const dispatch = useDispatch();
  // const history = useHistory();
  const [moderationId, setModerationId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const [noteReviseOpen, setNoteReviseOpen] = useState(false);
  const [screenModal, setScreenModal] = useState("");
  const dataTrip = queryString(search);

  const { tripData, isLoadingTripById } = useSelector(
    (state: Reducers) => state.trip
  );

  const _openModal = (screen: string, moderationIdTrip: string) => {
    setModerationId(moderationIdTrip);
    setModalOpen(true);
    setScreenModal(screen);
  };

  const _openResolveModal = () => {
    setNoteReviseOpen(false);
    setResolveOpen(true);
  };

  const renderAttchment = (item: Item, index: number) => {
    const buttonView = {
      backgroundImage:
        "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
      borderRadius: 100,
      width: "100%"
    };
    return (
      <View style={{ flexDirection: "row" }} key={index}>
        <Card cardStyle={styles.cardAttchmen}>
          <View>
            <Image
              style={{ height: 109, width: 109 }}
              source={{ uri: JSON.parse(tripData.file).url }}
            />
            <View
              style={{
                position: "absolute",
                height: "100%",
                width: "60%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                alignSelf: "center"
              }}
            >
              <div style={buttonView}>
                <Touchable>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        color: "#FFF",
                        fontWeight: "bold",
                        marginVertical: 5,
                        fontSize: 11
                      }}
                    >
                      VIEW
                    </Text>
                  </View>
                </Touchable>
              </div>
              <View>
                {dataTrip.type !== "history" ? (
                  <Touchable>
                    <View style={{ flex: 1, alignItems: "center" }}>
                      <Text
                        style={{
                          fontFamily: "Poppins",
                          color: "#707070",
                          fontWeight: "bold",
                          marginVertical: 5,
                          fontSize: 11
                        }}
                      >
                        DELETE
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
            </View>
          </View>
        </Card>
      </View>
    );
  };

  const _formatDate = (date: any) => {
    let result;
    const arr = String(new Date(date)).slice(4, 15).split(" ");
    for (let i = 0; i <= arr.length; i += 1) {
      result = ` ${arr[1]} ${arr[0]} ${arr[2]} `;
    }
    return result;
  };

  const _formatTime = (date: any) => {
    return String(new Date(date)).slice(16, 21);
  };

  return (
    <WrapContent isLoading={isLoadingTripById}>
      {tripData !== null && (
        <View style={styles.tripDetailContainer}>
          <View style={styles.tripDetailContent}>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Trip</Text>
              <View style={styles.detailDescription}>
                <Text style={[styles.titleDescription, { width: "100%" }]}>
                  {tripData.number}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#666666",
                    fontWeight: "bold",
                    fontFamily: "Poppins"
                  }}
                >
                  {tripData.project ? tripData.project.title : "-"}
                </Text>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#999999",
                    marginTop: 4,
                    fontFamily: "Poppins"
                  }}
                >
                  {tripData.category.title}
                </Text>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Team</Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#666666",
                    fontWeight: "bold",
                    fontFamily: "Poppins"
                  }}
                >
                  {tripData.users.map((item: any, index: number) => {
                    return `${item.user.name}${
                      index !== tripData.users.length - 1 ? ", " : ""
                    }`;
                  })}
                </Text>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Trip </Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#666666",
                    fontFamily: "Poppins"
                  }}
                >
                  {`${tripData.origin_location} to ${tripData.destination_location}`}
                </Text>
                <View style={{ flexDirection: "row", marginTop: 4 }}>
                  <Text
                    style={{
                      fontSize: 11,
                      color: "#666666",
                      fontWeight: "bold",
                      fontFamily: "Poppins"
                    }}
                  >
                    {`${_formatDate(tripData.started_at)} `}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11,
                      color: "#666666",
                      fontFamily: "Poppins"
                    }}
                  >
                    {_formatTime(tripData.started_at)}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Category </Text>
              <View style={styles.detailDescription}>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#666666",
                    fontWeight: "bold",
                    fontFamily: "Poppins"
                  }}
                >
                  {tripData.category.title}
                </Text>
              </View>
            </View>
            <Card cardStyle={styles.noteStyle}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    width: "25%",
                    fontSize: 11,
                    color: "#999999",
                    fontFamily: "Poppins"
                  }}
                >
                  Notes
                </Text>
                <Text
                  numberOfLines={4}
                  style={{
                    width: "75%",
                    fontSize: 11,
                    color: "#666666",
                    fontFamily: "Poppins"
                  }}
                >
                  {tripData.description ? tripData.description : " - "}
                </Text>
              </View>
            </Card>
            <View
              style={{
                paddingLeft: 22,
                marginBottom: 15,
                flexDirection: "row"
              }}
            >
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "Poppins",
                  color: "#999999",
                  marginRight: 14
                }}
              >
                Self Booking
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "Poppins",
                  color: "#707070",
                  fontWeight: "bold"
                }}
              >
                {tripData.djurneeTrip.self_booking ? "Yes" : "No"}
              </Text>
            </View>
            {tripData.file ? (
              <FlatList
                data={dataAttc}
                renderItem={({ item, index }) => renderAttchment(item, index)}
                keyExtractor={(item, index) => index.toString()}
                horizontal
                showsHorizontalScrollIndicator={false}
              />
            ) : (
              <></>
            )}

            {dataTrip.type === "approval" ? (
              <View style={styles.cardButtonContainer}>
                <Card cardStyle={styles.cardButton}>
                  <Touchable
                    onPress={() =>
                      _openModal("rejectProject", dataTrip.moderation)
                    }
                  >
                    <Image
                      source={IMAGES.cross}
                      style={{ height: 24, width: 24 }}
                    />
                  </Touchable>
                </Card>
                <Card cardStyle={styles.cardButton}>
                  <Touchable
                    onPress={() =>
                      _openModal("reviseProject", dataTrip.moderation)
                    }
                  >
                    <Image
                      source={IMAGES.pencil}
                      style={{ height: 24, width: 24 }}
                    />
                  </Touchable>
                </Card>
                <Card cardStyle={styles.cardButton}>
                  <Touchable
                    onPress={() =>
                      _openModal("approveProject", dataTrip.moderation)
                    }
                  >
                    <Image
                      source={IMAGES.check}
                      style={{ height: 24, width: 24 }}
                    />
                  </Touchable>
                </Card>
              </View>
            ) : null}
          </View>
        </View>
      )}
      {dataTrip.status === "4" && (
        <div
          style={{
            position: "fixed",
            paddingLeft: 20,
            paddingBottom: 21.7,
            paddingTop: 5,
            alignSelf: "flex-start",
            bottom: 0
          }}
        >
          <Touchable onPress={() => setNoteReviseOpen(true)}>
            <Image
              style={{ width: 62.6, height: 62.6 }}
              source={IMAGES.reviseAlert}
            />
          </Touchable>
        </div>
      )}
      <Modal
        moderationId={moderationId}
        isVisible={modalOpen}
        screen={screenModal}
        cancelApprove={() => setModalOpen(false)}
      />
      <Modal isVisible={noteReviseOpen} wrapStyle={styles.modalStyle}>
        <NoteRevise
          onPress={() => setNoteReviseOpen(false)}
          onPressRevise={() => _openResolveModal()}
        >
          <Text>
            {tripData !== null &&
              tripData.moderation !== null &&
              typeof tripData !== "undefined" &&
              typeof tripData.moderation !== "undefined" &&
              typeof tripData.moderation.remarks !== "undefined" &&
              tripData.moderation.remarks}
          </Text>
        </NoteRevise>
      </Modal>
      {resolveOpen && (
        <Modal isVisible={resolveOpen}>
          <ModalResolve cancel={() => setResolveOpen(false)} idTrip={`${id}`} />
        </Modal>
      )}
    </WrapContent>
  );
};

export default Trip;
