import React, { useEffect, useState } from "react";
import { ActivityIndicator, Image, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  Card,
  Datepicker,
  Header,
  Select,
  Touchable,
  WrapContent
} from "../../components";
import { IMAGES } from "../../configs";
import styles from "./styles";
import { queryString, utcDate } from "../../utils";

import {
  getPolicies,
  getRegency,
  getSource,
  getVendor,
  postNewBookingHotel
} from "../../redux/actions";
import { Reducers } from "../../redux/types";
import { BodyPostNewBookingHotel } from "../../configs/api";

const NewBookingHotel = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const { search } = history.location;
  const regencies = useSelector((state: Reducers) => state.regency);
  const vendors = useSelector((state: Reducers) => state.vendor);
  const sources = useSelector((state: Reducers) => state.source);
  const policies = useSelector((state: Reducers) => state.policies);
  const bookingHotel = useSelector((state: Reducers) => state.bookingHotel);
  const dispatch = useDispatch();
  const [regencyText, setRegencyText] = useState("");
  const [showKota, setShowKota] = useState(false);
  const typeFilterRegency: any = [];
  const [filterRegency, setFilterRegency] = useState(typeFilterRegency);
  const typeRegencyId: any = null;
  const [regencyId, setRegencyId] = useState(typeRegencyId);
  const [starId, setStarId] = useState("any");
  const [vendorId, setVendorId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [suiteId, setSuiteId] = useState("");
  const [hotel, setHotel] = useState("");
  const [isError, setIsError] = useState(false);

  const onChangeKota = (item: any) => {
    setRegencyId(item.id);
    setRegencyText(item.attributes.name.trim());
    setShowKota(false);
  };

  useEffect(() => {
    dispatch(getVendor());
    dispatch(getRegency());
    dispatch(getSource());
    dispatch(getPolicies());
    // eslint-disable-next-line
  }, [dispatch]);

  const onFilterRegency = (text: string) => {
    setShowKota(true);
    setFilterRegency(
      regencies.regencies.filter(
        (i: any) =>
          i.attributes.name.toLowerCase().substring(0, text.length) ===
          text.toLowerCase()
      )
    );
    setRegencyText(text);
  };

  const [star, setStar] = useState([
    { rating: 1, active: false },
    { rating: 2, active: false },
    { rating: 3, active: false },
    { rating: 4, active: false },
    { rating: 5, active: false }
  ]);

  const typeDateTime: any = [
    { date: "", time: "00:00" },
    { date: "", time: "00:00" }
  ];
  const getMonth = (m: number) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[m];
  };
  const dateFormated = (date: string) => {
    const dt = new Date(date);
    return `${dt.getDate()} ${getMonth(dt.getMonth())} ${dt.getFullYear()}`;
  };

  const [dateTime, setDateTime] = useState(typeDateTime);

  const setRating = (rating: number) => {
    let updatedRating: any[] = [];
    const maxRating = 5;

    if ((rating + 1).toString() !== starId) {
      for (let index = 0; index < maxRating; index += 1) {
        if (rating >= index) {
          updatedRating = [
            ...updatedRating,
            { rating: index + 1, active: true }
          ];
        } else {
          updatedRating = [
            ...updatedRating,
            { rating: index + 1, active: false }
          ];
        }
      }
      // ambil nilai rating
      setStarId((rating + 1).toString());
      setStar(updatedRating);
    } else {
      setStarId("any");
      setStar([
        { rating: 1, active: false },
        { rating: 2, active: false },
        { rating: 3, active: false },
        { rating: 4, active: false },
        { rating: 5, active: false }
      ]);
    }
  };

  const _submitBooking = () => {
    if (
      id &&
      vendorId !== "" &&
      regencyId !== "" &&
      regencyId !== null &&
      dateTime[0].date !== "" &&
      dateTime[0].time !== "" &&
      dateTime[1].date !== "" &&
      dateTime[1].time !== "" &&
      starId !== "" &&
      suiteId !== ""
    ) {
      const form: BodyPostNewBookingHotel = {
        trip_id: id,
        source_id: sources.sources.filter(
          item => item.attributes.title === "Hotel - Vendor"
        )[0].id,
        vendor_id: vendorId,
        regency_id: regencyId,
        started_at_d: utcDate(`${dateTime[0].date} ${dateTime[0].time}`),
        started_at_t: utcDate(`${dateTime[0].date} ${dateTime[0].time}`),
        finished_at_d: utcDate(`${dateTime[1].date} ${dateTime[1].time}`),
        finished_at_t: utcDate(`${dateTime[1].date} ${dateTime[1].time}`),
        star_id: starId,
        suite_id: suiteId,
        inhabitants: 1,
        quantity: 1,
        remarks: "",
        started_at: utcDate(`${dateTime[0].date} ${dateTime[0].time}`),
        finished_at: utcDate(`${dateTime[1].date} ${dateTime[1].time}`),
        client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hotel: hotel === "" ? null : hotel
      };

      if (search !== "") {
        const param = queryString(search);
        dispatch(
          postNewBookingHotel(form, () =>
            history.push(`/trip-detail/${id}/booking?type=${param.type}`)
          )
        );
      } else {
        dispatch(
          postNewBookingHotel(form, () => history.push(`/booking/${id}`))
        );
      }
    } else {
      setIsError(true);
    }
  };

  const _notifError = (showError: boolean, textError: string) => {
    if (isError && showError) {
      return (
        <View>
          <Text style={styles.errorAlert}>{textError}</Text>
        </View>
      );
    }
    return null;
  };

  const vendorSelect = vendors.vendors.map((e: any) => {
    return { value: e.id, label: e.title };
  });

  const roomHotel = [
    { label: "Bebas", value: "any" },
    { label: "Single", value: "single" },
    { label: "Double", value: "double" },
    { label: "Keluarga", value: "family" }
  ];
  const [isVisibleDropdown, setVisibleDropdown] = useState(true);
  const coba = () => {
    if (filterRegency.length === 0) {
      setRegencyText("");
    }
    setVisibleDropdown(!isVisibleDropdown);
  };
  return (
    <>
      <View style={{ zIndex: 3 }}>
        <Header title="New Booking" subtitle="Hotel" label="DRAFT" />
      </View>
      <WrapContent isLoading={policies.isLoadingPolicies}>
        <View style={[styles.form, { marginTop: 25 }, { zIndex: 2 }]}>
          {regencies.regencies.length > 0 ? (
            <View style={[{ width: "47%" }, showKota ? { zIndex: 2 } : {}]}>
              <Text style={styles.label}>City</Text>
              <TextInput
                style={[styles.textInput, { paddingVertical: 4 }]}
                value={regencyText}
                onChangeText={text => onFilterRegency(text)}
                onBlur={() => coba()}
                onFocus={() => setVisibleDropdown(true)}
              />
              {showKota ? (
                <View style={styles.autocompleteWrap}>
                  <View style={styles.autocompleteList}>
                    {filterRegency.length > 0 ? (
                      filterRegency.map((item: any, index: number) => (
                        <View
                          style={styles.autocompleteWrapLabel}
                          key={index.toString()}
                        >
                          <Touchable onPress={() => onChangeKota(item)}>
                            <Text style={styles.autocompleteWrapText}>
                              {item.attributes.name}
                            </Text>
                          </Touchable>
                        </View>
                      ))
                    ) : (
                      <View>
                        {isVisibleDropdown ? (
                          <View style={styles.autocompleteWrapLabel}>
                            <Text
                              style={[
                                styles.autocompleteWrapText,
                                { fontWeight: "normal" }
                              ]}
                            >
                              NO OPTION
                            </Text>
                          </View>
                        ) : null}
                      </View>
                    )}
                  </View>
                </View>
              ) : (
                <></>
              )}
              {_notifError(regencyText === "", "City is Required")}
            </View>
          ) : null}
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Vendor</Text>
            {vendors.vendors.length > 0 ? (
              <Select
                listValue={vendorSelect}
                defaultValue={() => setVendorId(vendorSelect[0].value)}
                onChange={e => setVendorId(e.target.value)}
              />
            ) : null}
            {_notifError(vendorId === "", "Vendor is Required")}
            <View style={[styles.textInput, { paddingVertical: 2 }]} />
          </View>
        </View>
        <View style={[styles.form, { marginTop: 35.5 }]}>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Hotel</Text>
            <TextInput
              style={[styles.textInput, { paddingVertical: 3 }]}
              value={hotel}
              onChangeText={e => setHotel(e)}
            />
          </View>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Rating</Text>
            <View style={{ flexDirection: "row" }}>
              {star.map((item, index) => {
                return (
                  <Touchable key={index} onPress={() => setRating(index)}>
                    {item.active ? (
                      <Image
                        source={IMAGES.star}
                        style={{ height: 25, width: 25 }}
                      />
                    ) : (
                      <Image
                        source={IMAGES.stargrey}
                        style={{ height: 25, width: 25 }}
                      />
                    )}
                  </Touchable>
                );
              })}
            </View>
            {_notifError(starId === "", "Rating is Required")}
          </View>
        </View>
        <View style={[styles.form, { marginTop: 27.5 }]}>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Room</Text>
            {/* <Picker
            style={styles.pickerWrap}
            selectedValue={suiteId}
            onValueChange={itemValue => setSuiteId(itemValue)}
          >
            <Picker.Item label="" value="0" />
            <Picker.Item label="Bebas" value="any" />
            <Picker.Item label="Single" value="single" />
            <Picker.Item label="Double" value="double" />
            <Picker.Item label="Keluarga" value="family" />
          </Picker> */}
            <Select
              listValue={roomHotel}
              defaultValue={() => setSuiteId(roomHotel[0].label)}
              onChange={e => setSuiteId(e.target.value)}
            />
            <View style={styles.textInput} />
            {_notifError(suiteId === "", "Room is Required")}
          </View>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Budget Allocation</Text>
            <Text
              style={{ fontFamily: "Poppins", color: "#666666", fontSize: 14 }}
            >
              IDR.
              <Text style={{ fontWeight: "bold" }}>
                {policies.policies.length > 0
                  ? policies.policies.filter(el => {
                      return (
                        el.id === "expense-booking-hotel-domestic-max-price"
                      );
                    })[0].value_object.value
                  : null}
              </Text>
            </Text>
          </View>
        </View>
        <View style={[styles.form, { marginTop: 35.5 }]}>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Check-In</Text>
          </View>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Check-Out</Text>
          </View>
        </View>
        <View style={{ paddingHorizontal: 25, zIndex: 1 }}>
          <Datepicker
            validate
            highlight
            value={dateTime}
            onChange={e => setDateTime(e)}
            head={(nav, setNav) => (
              <View style={styles.datePickerNavWrap}>
                <View
                  style={[
                    styles.datePickerNav,
                    nav === 0 ? styles.datePickerNavActive : {}
                  ]}
                >
                  <Touchable onPress={() => (setNav ? setNav(0) : null)}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <Text style={{ color: "#fff", fontWeight: "bold" }}>
                        Check In
                      </Text>
                    </View>
                  </Touchable>
                </View>
                <View
                  style={[
                    styles.datePickerNav,
                    nav === 1 ? styles.datePickerNavActive : {}
                  ]}
                >
                  <Touchable onPress={() => (setNav ? setNav(1) : null)}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <Text style={{ color: "#fff", fontWeight: "bold" }}>
                        Check Out
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            )}
          >
            <>
              <Card>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "50%" }}>
                    <Text
                      style={[
                        styles.datePicker,
                        {
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#666666"
                        }
                      ]}
                    >
                      {dateTime[0].date ? dateFormated(dateTime[0].date) : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.datePicker,
                        {
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#666666"
                        }
                      ]}
                    >
                      {dateTime[0].time}
                    </Text>
                  </View>
                  <View style={{ width: "50%" }}>
                    <Text
                      style={[
                        styles.datePicker,
                        {
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#666666"
                        }
                      ]}
                    >
                      {dateTime[1].date ? dateFormated(dateTime[1].date) : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.datePicker,
                        {
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: "#666666"
                        }
                      ]}
                    >
                      {dateTime[1].time}
                    </Text>
                  </View>
                </View>
              </Card>
              {_notifError(
                dateTime[0].date === "",
                "Date Check-in is Required"
              )}
              {_notifError(dateTime[0].time === "", "Room is Required")}
              {_notifError(
                dateTime[1].date === "",
                "Date Check-out is Required"
              )}
              {_notifError(dateTime[1].time === "", "Room is Required")}
            </>
          </Datepicker>
        </View>
        <View style={[styles.form, { marginTop: 23 }]}>
          <View style={{ width: "100%" }}>
            <Text style={styles.label}>Notes</Text>
            <TextInput
              value={remarks}
              onChangeText={text => setRemarks(text)}
              numberOfLines={3}
              multiline
              style={styles.textArea}
            />
          </View>
        </View>
        <View style={{ alignItems: "center" }}>
          <Touchable
            onPress={() => _submitBooking()}
            disabled={bookingHotel.isLoadingPostNewBookingHotel}
          >
            <View style={styles.button}>
              {!bookingHotel.isLoadingPostNewBookingHotel ? (
                <Text style={styles.txtButton}>SUBMIT</Text>
              ) : (
                <ActivityIndicator />
              )}
            </View>
          </Touchable>
        </View>
      </WrapContent>
    </>
  );
};

export default NewBookingHotel;
