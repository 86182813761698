import React, { useEffect } from "react";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { KasbonNavigation } from "../../components";
import { getKasbonSetting, getLoanProfile } from "../../redux/actions";
import { KasbonContent } from "./components/KasbonContent/KasbonContent";

import styles from "./styles";

const KasbonAdd = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoanProfile());
    dispatch(getKasbonSetting());
  }, [dispatch]);

  const hasCompany = localStorage.getItem("kasbon-setting");
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: hasCompany
            ? JSON.parse(hasCompany).hex_code
            : "white"
        }
      ]}
    >
      <KasbonNavigation />
      <View style={styles.content}>
        <KasbonContent />
      </View>
    </View>
  );
};
export default KasbonAdd;
