import { StyleSheet } from "react-native";
import { widthPercent } from "../../../../utils";

// import { heightPercent, widthPercent } from "../../utils";

const styles = StyleSheet.create({
  container: {
    width: widthPercent(100) - 60,
    borderRadius: 20
  },
  content: {
    marginVertical: 36,
    marginHorizontal: 24
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  rowSecond: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 32,
    alignItems: "flex-end"
  },
  logo: {
    width: 70,
    height: 24
  },
  number: {
    fontSize: 12,
    fontWeight: "700",
    color: "#343434;"
  },
  name: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "right",
    color: "#343434;"
  },
  position: {
    fontSize: 12,
    textAlign: "right",
    color: "#343434;"
  },
  containerProfile: {
    width: 210
  },
  profile: {
    width: 72,
    height: 72,
    borderRadius: 8
  }
});

export default styles;
