import React, { ReactChild, SyntheticEvent } from "react";

interface Props {
  children: ReactChild;
  onPress?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  hidden?: boolean;
  width?: string;
}

const Touchable = ({ children, onPress, disabled, hidden, width }: Props) => {
  return !disabled ? (
    <div
      onClick={onPress}
      style={{ height: "100%", cursor: "pointer", width }}
      hidden={hidden}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

Touchable.defaultProps = {
  disabled: false
};

export default Touchable;
