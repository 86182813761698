import React, { ReactNode, useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

import { heightPercent } from "../../utils";

interface Props {
  isLoading: boolean;
  children: ReactNode;
}

const styles = StyleSheet.create({
  wrapLoading: {
    height: heightPercent(60),
    justifyContent: "center"
  }
});

const WrapContent = ({ isLoading, children }: Props) => {
  const [first, setFirst] = useState(true);

  useEffect(() => {
    setFirst(false);
  }, []);

  return isLoading || first ? (
    <View style={styles.wrapLoading}>
      <ActivityIndicator color="#344370" size="large" />
    </View>
  ) : (
    <>{children}</>
  );
};

export default WrapContent;
