import React from "react";
import { FlatList, Image, Text, View } from "react-native";

import styles from "./styles";
import { Card, Header, Touchable } from "../../components";
import { IMAGES } from "../../configs";

const data = [
  {
    name: "Garuda Indonesia",
    maxpolicy: 2000000,
    price: 1200000,
    flight: [
      {
        depature: "Soekarno Hatta",
        arrival: "Ngurah Rai",
        date: "20 Des 2020",
        class: "Economy",
        seat: "43 C"
      },
      {
        depature: "Soekarno Hatta",
        arrival: "Ngurah Rai",
        date: "20 Des 2020",
        class: "Economy",
        seat: "43 C"
      }
    ],
    facility: ["Luggage 25 kg", "Snack", "Food"]
  },
  {
    name: "Garuda Indonesia",
    maxpolicy: 2000000,
    price: 3200000,
    flight: [
      {
        depature: "Soekarno Hatta",
        arrival: "Ngurah Rai",
        date: "20 Des 2020",
        class: "Economy",
        seat: "43 C"
      },
      {
        depature: "Soekarno Hatta",
        arrival: "Ngurah Rai",
        date: "20 Des 2020",
        class: "Economy",
        seat: "43 C"
      }
    ],
    facility: ["Luggage 25 kg", "Snack", "Food"]
  },
  {
    name: "Garuda Indonesia",
    maxpolicy: 2000000,
    price: 1200000,
    flight: [
      {
        depature: "Soekarno Hatta",
        arrival: "Ngurah Rai",
        date: "20 Des 2020",
        class: "Economy",
        seat: "43 C"
      },
      {
        depature: "Soekarno Hatta",
        arrival: "Ngurah Rai",
        date: "20 Des 2020",
        class: "Economy",
        seat: "43 C"
      }
    ],
    facility: ["Luggage 25 kg", "Snack", "Food"]
  }
];

const formatPrice = (price: { toString: () => string }) => {
  return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

const BookingFlightList = () => (
  <View>
    <Header title="New Booking" subtitle="Flight List" />
    <FlatList
      data={data}
      renderItem={({ item }) => (
        <View style={{ paddingHorizontal: 20, paddingVertical: 15 }}>
          <Card
            statusDesc={`IDR. ${formatPrice(item.price)}`}
            statusText={item.price > item.maxpolicy ? "Out of policy" : ""}
            statusColor={item.price > item.maxpolicy ? "#ed5a28" : "#50b1b1"}
          >
            <View style={{ padding: 21 }}>
              <Text style={styles.text}>Airlines</Text>
              <View style={styles.row}>
                <View>
                  <Text style={[styles.textDesc, { fontWeight: "bold" }]}>
                    {item.name}
                  </Text>
                </View>
              </View>
              {item.flight.map((flights, index) => {
                return (
                  <View key={index} style={styles.row}>
                    <View style={{ width: "20%" }}>
                      <Text style={[styles.text]}>{`Flight ${index + 1}`}</Text>
                    </View>
                    <View>
                      <Text style={styles.textDesc}>
                        {`${flights.depature} - ${flights.arrival}`}
                      </Text>
                      <Text style={styles.textDesc}>{flights.date}</Text>
                      <Text style={styles.text}>
                        {`${flights.class}, ${flights.seat}`}
                      </Text>
                    </View>
                  </View>
                );
              })}
              {/* <View style={styles.row}>
                <View style={{ width: "20%" }}>
                  <Text style={styles.text}>Facility</Text>
                </View>
                {item.facility.map((facilities, index) => {
                  return (
                    <View key={index}>
                      <Text style={[styles.textDesc]}>{`${facilities}, `}</Text>
                    </View>
                  );
                })}
              </View> */}
            </View>
          </Card>
        </View>
      )}
    />
    <div
      style={{
        position: "sticky",
        bottom: 0,
        padding: 20,
        paddingTop: 5,
        alignSelf: "flex-end"
      }}
    >
      <Touchable>
        <Image
          style={{ width: 62.6, height: 75.6 }}
          source={IMAGES.iconFilter}
        />
      </Touchable>
    </div>
  </View>
);

export default BookingFlightList;
