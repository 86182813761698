import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  flightContain: {
    flex: 1
  },
  flightCardCity: {
    paddingVertical: 7,
    marginBottom: 9
  },
  cardTime: {
    alignItems: "center",
    paddingVertical: 7,
    marginBottom: 35
  },
  cardDateContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cardDate: {
    textAlign: "center",
    width: "48%",
    paddingVertical: 7
  },
  buttonStyle: {
    marginHorizontal: 78
  }
});

export default styles;
