import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  insideCard: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 21,
    paddingVertical: 3
  },
  columnData: {
    width: "25%"
  },
  columnValue: {
    width: "75%"
  },
  keyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  },
  valueData: {
    color: "#666666",
    fontSize: 11,
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  navApproval: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%, #262261)",
    marginTop: 11,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  navApprovalItemActiveWrap: {
    backgroundImage: "linear-gradient(to top, #ed5a28 59%, #f5aa3f)",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    alignSelf: "center"
  },
  navApprovalItem: {
    paddingVertical: 9,
    paddingHorizontal: 24
  },
  navApprovalItemText: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#fff",
    fontWeight: "bold"
  }
});

export default styles;
