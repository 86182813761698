import React from "react";
import { View } from "react-native";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import FlightList from "./list";
import Edit from "./edit";

interface Props {
  id: string;
  search: string;
  height: number;
}

const Flight = ({ id, search, height }: Props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <View style={{ minHeight: height }}>
        <Route exact path={match.path}>
          <FlightList id={id} search={search} />
        </Route>
        <Route exact path={`${match.path}/:idBooking`}>
          <Edit id={id} search={search} />
        </Route>
      </View>
    </Switch>
  );
};

export default Flight;
