import React, { useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  CustomText,
  Fixed,
  Header,
  Input,
  Space,
  TextArea,
  Touchable
} from "../../../components";

import "./style.css";
import styles from "./styles";
import { COLORS } from "../../../configs";
import { Reducers } from "../../../redux/types";
import {
  getCashDetail,
  getCurrency,
  getPopulateBranch,
  postNewGeneralExpense
} from "../../../redux/actions";
import { convertDate, maskedMoney } from "../../../utils";

const initialBody = {
  invoice_id: null,
  branch_id: "",
  purpose: "",
  remarks: "",
  number2: "",
  currency_id: "",
  client_timezone: "Asia/Jakarta"
};

const Component = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const { cashDetail } = useSelector((state: Reducers) => state.cash);
  const { currencies, populateBranch } = useSelector(
    (state: Reducers) => state.newExpense
  );
  const { isLoadingNewGeneralExpense } = useSelector(
    (state: Reducers) => state.expense
  );

  const [body, setBody] = useState(initialBody);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getPopulateBranch());
    if (cashDetail === null) {
      dispatch(getCashDetail(id));
    }

    if (cashDetail) {
      setBody({
        ...body,
        invoice_id: id || "",
        purpose: cashDetail?.purpose || "",
        number2: cashDetail?.number2 || "",
        branch_id: cashDetail?.djurneeInvoice?.branch?.id || "",
        remarks: cashDetail?.remarks || "",
        currency_id: cashDetail?.currency?.code || ""
      });
    }
  }, [cashDetail]);

  const _handleSubmit = () => {
    dispatch(postNewGeneralExpense(body, () => history.goBack(), id));
  };

  return (
    <View>
      <Header
        textHeader={{ fontSize: 18 }}
        subTitleHeader={{ fontSize: 11 }}
        title="Edit Invoice"
      />
      <View style={styles.container}>
        <View style={styles.wrapInput}>
          <CustomText size={8} color="#66666680">
            {t("cash.new.subject")}
          </CustomText>
          <Input
            value={body.purpose}
            onChangeText={(e: any) => setBody({ ...body, purpose: e })}
            placeholder={t("cash.new.subjectPH")}
            inputStyle={styles.inputStyle}
          />
        </View>

        <View style={styles.wrapRow}>
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              {t("cash.detail.method")}
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.djurneeInvoice?.type?.title || "-"}
            </CustomText>
          </View>
          <Space width={12} />
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              Issued By
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.creator?.name || "-"}
            </CustomText>
          </View>
        </View>

        <View style={styles.wrapRow}>
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              {t("loan.invoiceNumber")}
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.number || "-"}
            </CustomText>
          </View>
          <Space width={12} />
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              Secondary Number
            </CustomText>
            <Input
              value={body.number2}
              onChangeText={(e: any) => setBody({ ...body, number2: e })}
              placeholder="Secondary Number"
              inputStyle={styles.inputStyle}
            />
          </View>
        </View>

        <View style={styles.wrapRow}>
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              {t("cash.new.issued")}
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.created_at
                ? convertDate(cashDetail.created_at, "DD MMMM YYYY HH:mm")
                : "-"}
            </CustomText>
          </View>
          <Space width={12} />
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              Date Due
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.due_at
                ? convertDate(cashDetail.due_at, "DD MMMM YYYY HH:mm")
                : "-"}
            </CustomText>
          </View>
        </View>

        <View style={styles.wrapInput}>
          <CustomText size={8} color="#66666680">
            Company
          </CustomText>
          <select
            style={{ color: "#666666", fontWeight: "bold" }}
            className="edit-cash-select"
            onChange={e => setBody({ ...body, branch_id: e.target.value })}
            value={body.branch_id}
          >
            <option hidden>{`${t("activity.new.select")} Company`}</option>
            {populateBranch.map(item => (
              <option key={item.id} value={item.id}>
                {item.attributes.name}
              </option>
            ))}
          </select>
        </View>

        <View style={styles.wrapRow}>
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              Project
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.djurneeInvoice?.project?.title || "-"}
            </CustomText>
          </View>
          <Space width={12} />
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              Vendor
            </CustomText>
            <Space height={8} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.djurneeInvoice?.vendor?.name || "-"}
            </CustomText>
          </View>
        </View>

        <View style={styles.wrapRow}>
          <View style={styles.wrapCurency}>
            <CustomText size={8} color="#66666680">
              {t("cash.new.currency")}
            </CustomText>
            <select
              style={{ color: "#666666", fontWeight: "bold" }}
              className="edit-cash-select"
              onChange={e => setBody({ ...body, currency_id: e.target.value })}
              value={body.currency_id}
            >
              <option hidden>{t("cash.new.currency")}</option>
              {currencies.map(item => (
                <option key={item.id} value={item.id}>
                  {item.attributes.title}
                </option>
              ))}
            </select>
          </View>
          <Space width={16} />
          <View style={styles.wrapInputRow}>
            <CustomText size={8} color="#66666680">
              Total
            </CustomText>
            <Space height={12} />
            <CustomText size={12} color={COLORS.greyMedium} type="bold">
              {cashDetail?.total ? maskedMoney(cashDetail?.total) : "-"}
            </CustomText>
          </View>
        </View>

        <View style={styles.wrapInput}>
          <CustomText size={8} color="#66666680">
            {t("cash.new.remarks")}
          </CustomText>
          <TextArea
            bold
            value={body.remarks}
            onChange={(e: any) => setBody({ ...body, remarks: e.target.value })}
          />
        </View>
      </View>
      <Fixed position="bottom">
        <View style={styles.wrapBtn}>
          <Touchable
            disabled={isLoadingNewGeneralExpense}
            onPress={() => _handleSubmit()}
          >
            <View style={[styles.button]}>
              {isLoadingNewGeneralExpense ? (
                <ActivityIndicator color={COLORS.white} />
              ) : (
                <CustomText color={COLORS.white} type="bold" size={12}>
                  {t("cash.new.submit")}
                </CustomText>
              )}
            </View>
          </Touchable>
        </View>
      </Fixed>
    </View>
  );
};

export default Component;
