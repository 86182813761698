import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  footer: {
    flex: 1,
    alignItems: "center",
    height: 146,
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)"
  },
  text: {
    fontSize: 11,
    width: 66,
    color: "#999999",
    fontFamily: "Poppins"
  },
  textDesc: {
    width: "70%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  containerTotalAmount: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    marginBottom: 20,
    marginTop: 18
  },
  btnSave: {
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    width: 125,
    height: 54,
    marginRight: 8,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center"
  },
  textBtn: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18
  },
  btnNext: {
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28, #db3a26)",
    width: 125,
    height: 54,
    marginLeft: 8,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
