import { StyleSheet } from "react-native";

import { IMAGES } from "../../configs";

const font = "Poppins";
const background = "#fffff7";
const style = StyleSheet.create({
  wrapChooseMonth: {
    flexDirection: "column",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 50,
    alignItems: "center",
    backgroundColor: background,
    position: "absolute",
    top: 35
  },
  wrapChooseMonthRow: {
    flexDirection: "row"
  },
  wrapChooseMonthItem: {
    flex: 1,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center"
  },
  wrapChooseMonthItemSelected: {
    borderColor: "#7e7e7e",
    borderStyle: "solid",
    borderWidth: 1
  },
  chooseMonthItem: {
    margin: 10,
    fontFamily: font,
    color: "#7e7e7e"
  },
  wrapMonthYear: {
    flexDirection: "row"
  },
  wrapMonthYearItem: {
    justifyContent: "center",
    alignItems: "center"
  },
  roundCard: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 50,
    alignItems: "center",
    width: 500,
    height: 32,
    justifyContent: "center"
  },
  wrapMonthYearButton: {
    width: 140,
    height: 34,
    justifyContent: "center",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 50,
    alignItems: "center"
  },
  wrapMonthYearButtonText: {
    color: "#666666",
    fontFamily: font,
    fontWeight: "bold",
    fontSize: 14
  },
  wrapDate: {
    height: 43,
    width: 41,
    justifyContent: "center",
    alignItems: "center"
  },
  date: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 43,
    width: 42
  },
  dateLabel: {
    fontFamily: font
  },
  dateToday: {
    borderRadius: 50,
    borderColor: "#faaf40",
    borderWidth: 3,
    borderStyle: "solid",
    height: 43,
    width: 42
  },
  dateActiveGrey: {
    borderRadius: 50,
    backgroundColor: "#ccc",
    width: 50,
    height: 30
  },
  dateActive: {
    backgroundImage: `url(${IMAGES.selectedDate})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 43,
    width: 42
  },
  wrapWeek: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  wrapDayNameView: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    height: 41.9,
    width: 40.3
  },
  wrapDayName: {
    fontWeight: "bold",
    fontFamily: font,
    color: "#7e7e7e"
  },
  buttonSave: {
    marginTop: 47,
    width: 180,
    height: 54,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  buttonBack: {
    marginTop: 10,
    marginBottom: 30
  },
  calendar: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center"
  },
  calendarTop: {
    bottom: 0
  },
  calendarBottom: {
    top: 0
  },
  timepickerWrapStyle: {
    marginTop: 5,
    flexDirection: "row",
    width: 150,
    marginLeft: 108,
    marginRight: 107
  },
  timepickerStyle: { flex: 1, height: 50 },
  travelingWrap: {
    flex: 1,
    flexDirection: "column",
    marginHorizontal: 36
  },
  travelingRow: { flex: 1, flexDirection: "row" }
});

export default style;
