import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  formWrap: {
    paddingTop: 28,
    paddingLeft: 20,
    paddingRight: 18
  },
  rowWrap: {
    flexDirection: "row",
    paddingTop: 8
  },
  wrapLabelInput: { flex: 1, marginRight: 9 },
  label: {
    marginLeft: 14,
    fontSize: 14,
    color: "#666666",
    fontFamily: "Poppins"
  },
  inputWrap: {
    marginTop: 14,
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginBottom: 14
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#666666"
  },
  inputLine: {
    marginLeft: 14,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderStyle: "solid",
    height: 30
  },
  rowWrapAmount: {
    paddingTop: 70,
    alignItems: "center",
    flexDirection: "column"
  },
  inputAmount: { textAlign: "center", fontSize: 24 },
  attachment: {
    paddingTop: 20
  },
  policyMessage: {
    fontFamily: "Poppins",
    fontWeight: "normal",
    fontSize: 14,
    color: "#666666",
    marginBottom: 8
  }
});

export default styles;
