import React, { useEffect, useState } from "react";
import { ActivityIndicator, Image, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  Datepicker,
  Picker,
  Select,
  Touchable,
  WrapContent
} from "../../../../../components";

import { Reducers } from "../../../../../redux/types";
import { IMAGES } from "../../../../../configs";
import {
  getBookingHotelById,
  getPolicies,
  getRegency,
  getSource,
  getVendor,
  putEditBookingHotel
} from "../../../../../redux/actions";
import styles from "./styles";
import { convertDate } from "../../../../../utils";

const EditHotel = () => {
  const { id } = useParams();
  const roomHotel = [
    { label: "Bebas", value: "any" },
    { label: "Single", value: "single" },
    { label: "Double", value: "double" },
    { label: "Keluarga", value: "family" }
  ];

  const [typeDateTime, setTypeDateTime] = useState([
    { date: "", time: "" },
    { date: "", time: "" }
  ]);

  const dispatch = useDispatch();
  const regencies = useSelector((state: Reducers) => state.regency);
  const vendors = useSelector((state: Reducers) => state.vendor);
  const policies = useSelector((state: Reducers) => state.policies);
  const bookingHotel = useSelector((state: Reducers) => state.bookingHotel);
  const sources = useSelector((state: Reducers) => state.source);
  const [vendorId, setVendorId] = useState("");
  const [regencyId, setRegencyId] = useState("");
  const [starId, setStarId] = useState("");
  const [suiteId, setSuiteId] = useState("");
  const [remarks, setRemarks] = useState("");
  const history = useHistory();
  let listRegency: any[] = [];

  const [star, setStar] = useState([
    { rating: 1, active: false },
    { rating: 2, active: false },
    { rating: 3, active: false },
    { rating: 4, active: false },
    { rating: 5, active: false }
  ]);

  const setRating = (rating: number) => {
    let updatedRating: any[] = [];
    const maxRating = 5;

    if ((rating + 1).toString() !== starId) {
      for (let index = 0; index < maxRating; index += 1) {
        if (rating >= index) {
          updatedRating = [
            ...updatedRating,
            { rating: index + 1, active: true }
          ];
        } else {
          updatedRating = [
            ...updatedRating,
            { rating: index + 1, active: false }
          ];
        }
      }
      // ambil nilai rating
      setStarId((rating + 1).toString());
      setStar(updatedRating);
    } else {
      setStarId("0");
      setStar([
        { rating: 1, active: false },
        { rating: 2, active: false },
        { rating: 3, active: false },
        { rating: 4, active: false },
        { rating: 5, active: false }
      ]);
    }
  };

  useEffect(() => {
    dispatch(getBookingHotelById(id));
    dispatch(getRegency());
    dispatch(getVendor());
    dispatch(getSource());
    dispatch(getPolicies());
    setTypeDateTime([
      {
        date: convertDate(
          bookingHotel.listBookingHotelById.data.check_in,
          "YYYY-MM-DD"
        ),
        time: convertDate(
          bookingHotel.listBookingHotelById.data.check_in,
          "HH:mm"
        )
      },
      {
        date: convertDate(
          bookingHotel.listBookingHotelById.data.check_out,
          "YYYY-MM-DD"
        ),
        time: convertDate(
          bookingHotel.listBookingHotelById.data.check_out,
          "HH:mm"
        )
      }
    ]);

    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    setRating(
      Number(bookingHotel.listBookingHotelById.data.prediction_star - 1)
    );
  }, [bookingHotel.listBookingHotelById.data.prediction_star]);

  if (regencies.regencies.length > 0) {
    regencies.regencies.map(data => {
      listRegency = [
        ...listRegency,
        { value: data.id, label: data.attributes.name }
      ];
    });
  }

  const vendorSelect = vendors.vendors.map((e: any) => {
    return { value: e.id, label: e.title };
  });

  const [hotel, setHotel] = useState("");

  const submitBooking = () => {
    const form = {
      trip_id: `${id}`,
      source_id: sources.sources.filter(
        (item: any) => item.attributes.title === "Hotel - Vendor"
      )[0].id,
      vendor_id: vendorId,
      regency_id:
        regencyId || bookingHotel.listBookingHotelById.data.regency_id,
      started_at_d: typeDateTime[0].date,
      started_at_t: typeDateTime[0].time,
      finished_at_d: typeDateTime[1].date,
      finished_at_t: typeDateTime[1].time,
      star_id: starId || bookingHotel.listBookingHotelById.data.prediction_star,
      suite_id:
        suiteId || bookingHotel.listBookingHotelById.data.prediction_suit,
      inhabitants: 1,
      quantity: 1,
      remarks: remarks || bookingHotel.listBookingHotelById.remarks,
      started_at: `${typeDateTime[0].date} ${typeDateTime[0].time}`,
      finished_at: `${typeDateTime[1].date} ${typeDateTime[1].time}`,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hotel: hotel || bookingHotel.listBookingHotelById.data.prediction_name
    };
    dispatch(putEditBookingHotel(form, id, () => history.goBack()));
  };

  return (
    <WrapContent isLoading={bookingHotel.isLoadingGetBookingHotelById}>
      <View style={{ marginHorizontal: 10 }}>
        <View style={[styles.row, { zIndex: 3 }]}>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>City</Text>
            {!bookingHotel.isLoadingGetBookingHotelById ? (
              <Picker
                option={listRegency}
                defaultValue={bookingHotel.listBookingHotelById.data.location}
                style={{
                  borderColor: "#666666",
                  borderBottomWidth: 1
                }}
                onValueChange={text => setRegencyId(text.value)}
              />
            ) : (
              <ActivityIndicator />
            )}
          </View>
          <View style={{ width: "47%" }}>
            <Text style={[styles.label, { paddingBottom: 10 }]}>Vendor</Text>
            {vendors.vendors.length > 0 ? (
              <Select
                listValue={vendorSelect}
                defaultValue={() => setVendorId(vendorSelect[0].value)}
                onChange={e => setVendorId(e.target.value)}
              />
            ) : null}
            <View style={styles.line} />
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Hotel</Text>
            <TextInput
              defaultValue={
                bookingHotel.listBookingHotelById.data.prediction_name
              }
              style={styles.input}
              onChangeText={text => setHotel(text)}
            />
            <View style={[styles.line, { marginTop: 6 }]} />
          </View>
          <View style={{ width: "47%" }}>
            <Text style={[styles.label, { paddingBottom: 10 }]}>Star</Text>
            <View style={{ flexDirection: "row" }}>
              {star.map((item, index) => {
                return (
                  <Touchable key={index} onPress={() => setRating(index)}>
                    {item.active ? (
                      <Image
                        source={IMAGES.star}
                        style={{ height: 25, width: 25 }}
                      />
                    ) : (
                      <Image
                        source={IMAGES.stargrey}
                        style={{ height: 25, width: 25 }}
                      />
                    )}
                  </Touchable>
                );
              })}
            </View>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: "47%" }}>
            <Text style={[styles.label, { paddingBottom: 10 }]}>Room</Text>
            <Select
              type={bookingHotel.listBookingHotelById.data.prediction_suit}
              listValue={roomHotel}
              defaultValue={() => setSuiteId(roomHotel[0].label)}
              onChange={e => setSuiteId(e.target.value)}
            />
            <View style={[styles.line]} />
          </View>
          <View style={{ width: "47%" }}>
            <Text style={[styles.label, { paddingBottom: 10 }]}>
              Budget Allocation
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.label}>
                Rp.
                <Text style={[styles.label, { fontWeight: "bold" }]}>
                  {policies.policies.length > 0
                    ? policies.policies.filter(el => {
                        return (
                          el.id === "expense-booking-hotel-domestic-max-price"
                        );
                      })[0].value_object.value
                    : null}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.row, { marginTop: 35.5 }]}>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Check-In</Text>
          </View>
          <View style={{ width: "47%" }}>
            <Text style={styles.label}>Check-Out</Text>
          </View>
        </View>
        <View style={{ zIndex: 1, marginTop: 10 }}>
          <Datepicker
            validate
            highlight
            value={typeDateTime}
            timepicker
            onChange={e => setTypeDateTime(e)}
            head={(nav, setNav) => (
              <View style={styles.datePickerNavWrap}>
                <View
                  style={[
                    styles.datePickerNav,
                    nav === 0 ? styles.datePickerNavActive : {}
                  ]}
                >
                  <Touchable onPress={() => (setNav ? setNav(0) : null)}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <Text style={{ color: "#fff", fontWeight: "bold" }}>
                        Check In
                      </Text>
                    </View>
                  </Touchable>
                </View>
                <View
                  style={[
                    styles.datePickerNav,
                    nav === 1 ? styles.datePickerNavActive : {}
                  ]}
                >
                  <Touchable onPress={() => (setNav ? setNav(1) : null)}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <Text style={{ color: "#fff", fontWeight: "bold" }}>
                        Check Out
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            )}
          >
            <Card>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "50%" }}>
                  <Text
                    style={[
                      styles.datePicker,
                      {
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#666666"
                      }
                    ]}
                  >
                    {typeDateTime[0].date
                      ? convertDate(
                          `${typeDateTime[0].date} ${typeDateTime[0].time}`
                        )
                      : convertDate(
                          bookingHotel.listBookingHotelById.data.check_in
                        )}
                  </Text>
                  <Text
                    style={[
                      styles.datePicker,
                      {
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#666666"
                      }
                    ]}
                  >
                    {typeDateTime[0].time ||
                      convertDate(
                        bookingHotel.listBookingHotelById.data.check_in,
                        "HH:mm"
                      )}
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text
                    style={[
                      styles.datePicker,
                      {
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#666666"
                      }
                    ]}
                  >
                    {typeDateTime[1].date
                      ? convertDate(
                          `${typeDateTime[1].date} ${typeDateTime[1].time}`
                        )
                      : convertDate(
                          bookingHotel.listBookingHotelById.data.check_out
                        )}
                  </Text>
                  <Text
                    style={[
                      styles.datePicker,
                      {
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#666666"
                      }
                    ]}
                  >
                    {typeDateTime[1].time ||
                      convertDate(
                        bookingHotel.listBookingHotelById.data.check_out,
                        "HH:mm"
                      )}
                  </Text>
                </View>
              </View>
            </Card>
          </Datepicker>
        </View>
        <View style={[styles.row, { marginTop: 23 }]}>
          <View style={{ width: "100%" }}>
            <Text style={styles.label}>Notes</Text>
            <TextInput
              defaultValue={bookingHotel.listBookingHotelById.remarks}
              onChangeText={text => setRemarks(text)}
              numberOfLines={3}
              multiline
              style={styles.textArea}
            />
          </View>
        </View>
        <View style={{ alignItems: "center" }}>
          <Touchable onPress={() => submitBooking()}>
            <View style={styles.button}>
              <Text style={styles.txtButton}>SUBMIT</Text>
            </View>
          </Touchable>
        </View>
      </View>
    </WrapContent>
  );
};

export default EditHotel;
