import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";

import { getDetailTask, putModerateTaskRequest } from "../../../redux/actions";
import styles from "./styles";
import "./style.css";
import Fixed from "../../Fixed";

interface Props {
  onCancel: () => void;
  moderationId: string;
  taskId: string;
}

const ModerateTaskRequest = ({ taskId, moderationId, onCancel }: Props) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);

  const [body, setBody]: any = useState({
    id: moderationId,
    answer: "",
    message: ""
  });

  const _handleModerateTaskRequest = () => {
    if (body.answer) {
      const form = {
        ...body,
        message: null,
        position_id: null,
        employee_id: null
      };
      body && body.answer === "3" ? (form.message = " ") : (form.message = "");
      setBody({ ...body, form });
      dispatch(putModerateTaskRequest(form));
      dispatch(getDetailTask(String(taskId)));
      onCancel();
    } else {
      setError(true);
    }
  };

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <View style={styles.containerContent}>
        <Text style={styles.title}>Your Response</Text>
        <View style={[styles.card, styles.containerDropdown]}>
          <select
            className="moderation-dropdown"
            onChange={e => {
              setBody({
                ...body,
                answer: e.target.value,
                message: ""
              });
              setError(false);
            }}
          >
            <option selected hidden>
              Select Answer
            </option>
            <option value="1">Approve</option>
            <option value="3">Reject</option>
          </select>
        </View>
        {error && <Text style={styles.error}>Please select your answer</Text>}
      </View>
      <Fixed position="bottom">
        <View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TouchableOpacity
              onPress={() => {
                _handleModerateTaskRequest();
              }}
              style={styles.button}
            >
              <Text style={styles.buttonFont}>SUBMIT</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={onCancel}>
              <Text
                style={{
                  color: "#666666",
                  fontWeight: "bold",
                  marginBottom: 20,
                  fontFamily: "Poppins"
                }}
              >
                CANCEL
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Fixed>
    </View>
  );
};

export default ModerateTaskRequest;
