import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapButtonBack: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    justifyContent: "center"
  },
  buttonBack: {
    padding: 34
  },
  iconBack: {
    width: 20,
    height: 20,
    tintColor: "#262261"
  }
});

export default styles;
