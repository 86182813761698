import React from "react";
import { ActivityIndicator, Text, View } from "react-native";

import { Gradient, Touchable } from "../index";
import styles from "./styles";

type Size = "small" | "large";

interface Props {
  onPress?: () => void;
  title: string;
  size?: Size;
  style?: object;
  isLoading?: boolean;
  disabled?: boolean;
}

const Button = ({
  onPress,
  title,
  size,
  style,
  isLoading,
  disabled
}: Props) => {
  return (
    <Touchable onPress={onPress} disabled={disabled || isLoading}>
      <View style={style}>
        <Gradient
          backgroundImage={
            disabled
              ? "linear-gradient(to bottom, #999999, #707070 63%, #666666)"
              : "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
          }
          backgroundImageStyle={{ borderRadius: size === "small" ? 27 : 45 }}
          style={
            size === "small"
              ? styles.wrapTextButton
              : styles.wrapTextButtonLarge
          }
        >
          {!isLoading ? (
            <Text
              style={
                size === "small" ? styles.textButton : styles.textButtonLarge
              }
            >
              {title}
            </Text>
          ) : (
            <ActivityIndicator color="#fff" />
          )}
        </Gradient>
      </View>
    </Touchable>
  );
};

Button.defaultProps = {
  size: "small",
  isLoading: false
};

export default Button;
