import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_PROJECT_PENDING = "GET_PROJECT_PENDING";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";

export const GET_PROJECT_CA_PENDING = "GET_PROJECT_CA_PENDING";
export const GET_PROJECT_CA_SUCCESS = "GET_PROJECT_CA_SUCCESS";
export const GET_PROJECT_CA_ERROR = "GET_PROJECT_CA_ERROR";

export const getProject = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();

    dispatch({ type: GET_PROJECT_PENDING });

    const res = await API.getProjectOptions(auth.token);
    dispatch({
      type: GET_PROJECT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getProject())));
      } else {
        dispatch({ type: GET_PROJECT_ERROR, payload: { data: err.response } });
      }
    }
    dispatch({ type: GET_PROJECT_ERROR });
  }
};

export const getCategoryByTrip = (tripId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();

    dispatch({ type: GET_PROJECT_CA_PENDING });

    const res = await API.getCategoryByTrip(tripId, auth.token);
    dispatch({
      type: GET_PROJECT_CA_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCategoryByTrip(tripId))));
      } else {
        dispatch({
          type: GET_PROJECT_CA_SUCCESS,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_PROJECT_CA_SUCCESS });
  }
};
