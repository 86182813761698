import {
  GET_AIRLINE_ERROR,
  GET_AIRLINE_PENDING,
  GET_AIRLINE_SUCCESS,
  GET_BOOKING_SOURCE_FLIGHT_ERROR,
  GET_BOOKING_SOURCE_FLIGHT_PENDING,
  GET_BOOKING_SOURCE_FLIGHT_SUCCESS,
  LOGOUT_SUCCESS,
  POST_NEW_BOOKING_FLIGHT_ERROR,
  POST_NEW_BOOKING_FLIGHT_PENDING,
  POST_NEW_BOOKING_FLIGHT_SUCCESS,
  RESET_FORM_FLIGHT_SUCCESS,
  SET_FORM_NEW_BOOKING_FLIGHT
} from "../actions";
import { Action, NewBookingFlightState, SelectOptions } from "../types";

const initialState: NewBookingFlightState = {
  isLoadingSourceFlight: false,
  isLoadingAirline: false,
  isLoadingVendorFlight: false,
  listAirline: [],
  isLoadingPostNewBookingFlight: false,
  form: {
    source_id: "",
    trip_id: "",
    vendor_id: "",
    from: "",
    to: "",
    started_at_d: "",
    started_at_t: "any",
    finished_at_d: "",
    finished_at_t: "any",
    class_id: "economy",
    baggage: 0,
    airline_id: "",
    participants: {},
    remarks: "",
    is_round_trip: false,
    started_at: "",
    finished_at: "",
    has_baggage: false,
    client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
};

let listAirline: SelectOptions[];

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_BOOKING_SOURCE_FLIGHT_PENDING:
      return { ...state, isLoadingSourceFlight: true };
    case GET_BOOKING_SOURCE_FLIGHT_SUCCESS:
      return {
        ...state,
        isLoadingSourceFlight: false,
        form: { ...state.form, source_id: payload.data }
      };
    case GET_BOOKING_SOURCE_FLIGHT_ERROR:
      return { ...state, isLoadingSourceFlight: false };

    // airline
    case GET_AIRLINE_PENDING:
      return { ...state, isLoadingAirline: true };
    case GET_AIRLINE_SUCCESS:
      listAirline = [];
      payload.data.forEach((item: any) => {
        listAirline = [...listAirline, { value: item.id, label: item.title }];
      });

      return {
        ...state,
        isLoadingAirline: false,
        listAirline
      };
    case GET_AIRLINE_ERROR:
      return { ...state, isLoadingAirline: false };

    // form
    case SET_FORM_NEW_BOOKING_FLIGHT:
      return {
        ...state,
        form: { ...state.form, [payload.data.formName]: payload.data.value }
      };

    // post new booking flight
    case POST_NEW_BOOKING_FLIGHT_PENDING:
      return { ...state, isLoadingPostNewBookingFlight: true };
    case POST_NEW_BOOKING_FLIGHT_SUCCESS:
      return { ...state, isLoadingPostNewBookingFlight: false };
    case POST_NEW_BOOKING_FLIGHT_ERROR:
      return { ...state, isLoadingPostNewBookingFlight: false };

    case RESET_FORM_FLIGHT_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          baggage: initialState.form.baggage,
          from: initialState.form.from,
          to: initialState.form.to,
          started_at_d: initialState.form.started_at_d,
          finished_at_d: initialState.form.finished_at_d,
          class_id: initialState.form.class_id,
          started_at: initialState.form.started_at,
          finished_at: initialState.form.finished_at,
          has_baggage: initialState.form.has_baggage
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
