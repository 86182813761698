import React, { ReactChild, useCallback, useEffect, useState } from "react";
import { Image, Picker, Text, TextInput, View } from "react-native";

import { ICONS } from "../../configs";
import styles from "./styles";
import { Modal, Picker as RegionPicker, Select, Touchable } from "..";
import { SelectOptions } from "../../redux/types";
import { widthPercent } from "../../utils";
import "./styles.css";

interface Validation {
  backDate: boolean;
  forwardDate: boolean;
}

interface Props {
  disable?: boolean;
  value?: any;
  onChange?: (text: any) => void;
  timepicker?: boolean;
  head?: (navText: number, setNav: (nav: any) => void) => ReactChild;
  children?: ReactChild;
  style?: object;
  highlight?: boolean;
  traveling?: boolean;
  visible?: boolean;
  onChangeVisible?: (text: boolean) => void;
  showNav?: number;
  validate?: Validation | boolean;
  region?: any;
  travelingV2?: boolean;
  flight?: boolean;
  flightOneWay?: boolean;
  maxDate?: string;
  minDate?: string;
}

const selectTime: SelectOptions[] = [
  { label: "Bebas", value: "any" },
  { label: "Pagi (05:00-9:59)", value: "05:00-9:59" },
  { label: "Siang (10:00-13:59)", value: "10:00-13:59" },
  { label: "Malam (14:00-04:59)", value: "14:00-04:59" }
];

const Datepicker = ({
  value,
  onChange,
  timepicker,
  head,
  children,
  style,
  // position,
  highlight,
  traveling,
  visible = false,
  onChangeVisible,
  showNav = 0,
  validate = false,
  travelingV2 = false,
  region,
  // bottom,
  flight,
  flightOneWay = false,
  maxDate,
  minDate,
  disable
}: Props) => {
  const today = new Date();

  const [showYear, setShowYear] = useState(false);

  const [listYear] = useState([today.getFullYear(), today.getFullYear() + 1]);

  const [showMonths, setShowMonths] = useState(false);
  const [showInputYear, setShowInputYear] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);

  const typeListHours: string[] = [];
  const [listHours, setListHours] = useState(typeListHours);

  const typeListMinutes: string[] = [];
  const [listMinutes, setListMinutes] = useState(typeListMinutes);

  // const [departure, setDeparture] = useState("");
  // const [arrival, setArrival] = useState("");

  const getDataFromValue = useCallback(() => {
    let v: any = [];
    value.forEach(
      (vl: {
        date: any;
        time: string;
        departure: any;
        arrival: any;
        dateTo: any;
        timeTo: any;
      }) => {
        let dis: any = {
          date: vl.date
        };
        if (travelingV2 && flightOneWay) {
          dis = {
            ...dis,
            time: vl.time !== "" ? vl.time : "00:00",
            departure: vl.departure,
            arrival: vl.arrival
          };
        }
        if (travelingV2 && !flightOneWay) {
          dis = {
            ...dis,
            dateTo: vl.dateTo,
            time: vl.time !== "" ? vl.time : "00:00",
            timeTo: vl.timeTo !== "" ? vl.timeTo : "00:00",
            departure: vl.departure,
            arrival: vl.arrival
          };
        }
        if (timepicker) {
          dis = {
            ...dis,
            ...{
              time: vl.time
            }
          };
        }
        v = [...v, ...[dis]];
      }
    );
    return v;
  }, [flightOneWay, timepicker, travelingV2, value]);
  const vc: any = getDataFromValue();

  const typeValueDates: any[] = vc;
  const [valueDates, setValueDates] = useState(typeValueDates);

  const [valueUse, setValueUse] = useState(true);

  const typeNavRoute = showNav;
  const [navRoute, setNavRoute] = useState(typeNavRoute);

  const typeMidDate: any[] = [];
  const [midDate, setMidDate] = useState(typeMidDate);

  const monthList = [
    ["Jan", "Feb", "Mar"],
    ["Apr", "May", "Jun"],
    ["Jul", "Aug", "Sep"],
    ["Oct", "Nov", "Dec"]
  ];

  const checkValueDates = useCallback(
    (dt: string) => {
      let exist = false;
      valueDates.forEach(item => {
        if (item.date === dt) {
          exist = true;
        }
      });
      return exist;
    },
    [valueDates]
  );

  const zeroNumber = useCallback(d => {
    return d < 10 ? `0${d}` : d;
  }, []);

  const dateFormated = useCallback(
    (dt: any = today) => {
      let assignDt = dt;
      if (typeof assignDt === "string") {
        assignDt = new Date(assignDt);
      }
      return `${assignDt.getFullYear()}-${zeroNumber(
        assignDt.getMonth() + 1
      )}-${zeroNumber(assignDt.getDate())}`;
    },
    [zeroNumber, today]
  );

  const setUpMidDate = useCallback(
    (dts: string[]) => {
      let md: any = [];
      dts.forEach((dt, index) => {
        if (index !== 0) {
          const aDay = 1 * 24 * 60 * 60 * 1000;
          const d = new Date(dt).getTime();
          const ds = new Date(dts[index - 1]).getTime();
          for (let i = ds; i < d; i += aDay) {
            md = [...md, ...[dateFormated(new Date(i))]];
          }
        }
      });
      setMidDate(md);
    },
    [dateFormated]
  );

  const curValue = new Date(value ? value[0].date : dateFormated(today));
  const [calendar, setCalendar] = useState({
    year: curValue.getFullYear(),
    month: curValue.getMonth() + 1
  });

  const getLastDate = useCallback(
    (dt = today) => {
      let assignDt = dt;
      if (typeof assignDt === "string") {
        assignDt = new Date(assignDt);
      }
      const thirtyOne = [1, 3, 5, 7, 8, 10, 12];
      const month = assignDt.getMonth() + 1;
      const year = assignDt.getFullYear();
      let lastdate = 30;
      if (month === 2) {
        if (year % 4 === 0) {
          lastdate = 29;
        } else {
          lastdate = 28;
        }
      }
      if (thirtyOne.indexOf(month) >= 0) {
        lastdate = 31;
      }
      return lastdate;
    },
    [today]
  );

  const getDay = useCallback(
    (dt = today) => {
      let assignDt = dt;
      if (typeof assignDt === "string") {
        assignDt = new Date(assignDt);
      }
      return assignDt.getDay();
    },
    [today]
  );

  const nextYearDate = useCallback(() => {
    const nextyr = today.getFullYear() + 1;
    const mo = today.getMonth() + 1;
    if (mo !== 2 || (mo === 2 && today.getDate() !== 29)) {
      return `${nextyr}-${zeroNumber(today.getMonth() + 1)}-${zeroNumber(
        today.getDate()
      )}`;
    }

    const last = getLastDate(`${nextyr}-${mo}-01`);
    return `${nextyr}-${zeroNumber(mo)}-${zeroNumber(last)}`;
  }, [zeroNumber, getLastDate, today]);

  const disabledDate = useCallback(
    (dt, nav) => {
      if (typeof validate === "object") {
        let disabled = false;
        const timeP = new Date(dt).getTime();
        // back date
        if (
          today.getTime() > timeP &&
          dt !== dateFormated(today) &&
          validate.backDate === true
        ) {
          disabled = true;
        }
        // forward date
        if (
          today.getTime() < timeP &&
          dt !== dateFormated(today) &&
          validate.forwardDate === true
        ) {
          disabled = true;
        }
        return disabled;
      }
      if (typeof validate === "boolean" && validate === true) {
        let disabled = false;
        const timeP = new Date(dt).getTime();

        // back date
        if (today.getTime() > timeP && dt !== dateFormated(today)) {
          disabled = true;
        }

        if (timeP > new Date(nextYearDate()).getTime()) {
          disabled = true;
        }
        if (nav > 0) {
          const cdt = new Date(valueDates[nav - 1].date).getTime();
          if (cdt > timeP) {
            disabled = true;
          }
        }
        if (
          nav < valueDates.length - 1 &&
          valueDates.length > 1 &&
          valueDates[nav + 1].date !== ""
        ) {
          const cdt = new Date(valueDates[nav + 1].date).getTime();
          if (timeP > cdt) {
            disabled = true;
          }
        }
        return disabled;
      }
      return false;
    },
    [validate, today, dateFormated, valueDates, nextYearDate]
  );

  const getAllDate = useCallback(
    (yr, mo, nav = null) => {
      const dt = [];
      let dy = [];
      let row = 0;
      const month = `${yr}-${zeroNumber(mo)}`;
      const last = getLastDate(`${month}-01`);

      for (let i = 1; i <= last; i += 1) {
        const day = getDay(`${month}-${zeroNumber(i)}`);
        if (day === 0 || i === 1) {
          dy = [];
        }
        // dates before current month -- start
        if (day !== 0 && i === 1) {
          let lastMo = mo - 1;
          let lastYr = yr;
          if (lastMo === 0) {
            lastMo = 12;
            lastYr = yr - 1;
          }
          const lastMonth = `${lastYr}-${zeroNumber(lastMo)}`;
          const lastLastDate = getLastDate(lastMonth);
          for (let j = 0; j < day; j += 1) {
            const lastDate = lastLastDate - (day - 1 - j);
            const dateP = `${lastMonth}-${lastDate}`;
            dy[j] = {
              label: lastDate,
              date: dateP,
              today: false,
              blur: true,
              active: false,
              disabled: disabledDate(dateP, nav == null ? navRoute : nav)
            };
          }
        }
        // dates before current month -- end
        // dates on current month -- start
        const dateC = dateFormated(`${month}-${zeroNumber(i)}`);
        dy[day] = {
          label: i,
          date: dateC,
          today: dateFormated(today) === dateC,
          blur: false,
          active: checkValueDates(dateC),
          disabled:
            disabledDate(dateC, nav == null ? navRoute : nav) ||
            (maxDate && Date.parse(dateC) > Date.parse(maxDate)) ||
            (minDate && Date.parse(dateC) < Date.parse(minDate))
        };
        // dates on current month -- end
        if ((day + 1) % 7 === 0 || i === last) {
          if (i === last) {
            let nextMo = mo + 1;
            let nextYr = yr;
            if (nextMo === 13) {
              nextMo = 1;
              nextYr = yr + 1;
            }
            const nextMonth = `${nextYr}-${zeroNumber(nextMo)}`;
            let l = 0;
            // dates after current month -- start
            for (let k = day + 1; k < 7; k += 1) {
              const dateN = `${nextMonth}-${zeroNumber(l + 1)}`;
              dy[k] = {
                label: l + 1,
                date: dateN,
                today: false,
                blur: true,
                active: false,
                disabled: disabledDate(dateN, nav == null ? navRoute : nav)
              };
              l += 1;
            }
            // dates after current month -- end
          }
          dt[row] = dy;
          row += 1;
        }
      }
      return dt;
    },
    [
      zeroNumber,
      getLastDate,
      getDay,
      dateFormated,
      today,
      checkValueDates,
      disabledDate,
      navRoute
    ]
  );

  const getMonth = (m: number) => {
    const assignM = m - 1;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[assignM];
  };

  const onShowMonths = () => {
    setShowMonths(!showMonths);
    if (showInputYear) {
      setShowInputYear(false);
    }
  };

  const [dates, setDates] = useState(getAllDate(calendar.year, calendar.month));

  const onSelectDate = (dt: any) => {
    if (!dt.disabled) {
      const val = valueDates;
      if (travelingV2 && !flightOneWay && !flight) {
        if (val[navRoute].date === "" && val[navRoute].dateTo === "") {
          val[navRoute].date = dt.date;
        } else if (val[navRoute].date !== "" && val[navRoute].dateTo === "") {
          if (
            new Date(val[navRoute].date).getTime() > new Date(dt.date).getTime()
          ) {
            val[navRoute].dateTo = val[navRoute].date;
            val[navRoute].date = dt.date;
          } else if (
            new Date(val[navRoute].date).getTime() <
              new Date(dt.date).getTime() ||
            val[navRoute].date === dt.date
          ) {
            val[navRoute].dateTo = dt.date;
          }
        } else if (val[navRoute].date !== "" && val[navRoute].dateTo !== "") {
          val[navRoute].date = dt.date;
          val[navRoute].dateTo = "";
        }
      } else {
        val[navRoute].date = dt.date;
      }
      setValueDates(val);
      const d = new Date(dt.date);

      setDates(getAllDate(d.getFullYear(), d.getMonth() + 1));
      let md: any = [];
      val.forEach((v: { date: any; dateTo?: any }) => {
        md = [...md, ...[v.date]];
        if (travelingV2) {
          md = [...md, ...[v.dateTo]];
        }
      });
      setUpMidDate(md);
    }
  };

  const onMonth = (m: number) => {
    setCalendar({
      year: calendar.year,
      month: m
    });
    setDates(getAllDate(calendar.year, m));
    setShowMonths(!showMonths);
  };

  const renderTraveling = () => {
    const font = {
      fontFamily: "Poppins"
    };
    return (
      <View
        style={{
          alignItems: "center"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 30
          }}
        >
          <View>
            <Text style={[font, { color: "rgba(102, 102, 102, 0.5)" }]}>
              Departure
            </Text>
          </View>
          <View style={{ marginLeft: 78, marginRight: 17 }}>
            <Text style={[font, { color: "rgba(102, 102, 102, 0.5)" }]}>
              Arrival
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 12,
            flexDirection: "row"
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TextInput
              style={[
                font,
                {
                  textAlign: "center",
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#707070",
                  fontWeight: "bold",
                  color: "#666666",
                  width: 75
                }
              ]}
              value={valueDates[navRoute].departure.location}
              onChangeText={e => {
                const vd = valueDates;
                vd[navRoute].departure.location = e;
                setValueDates(vd);
                const d = new Date(vd[navRoute].date);

                setDates(getAllDate(d.getFullYear(), d.getMonth() + 1));
              }}
            />
          </View>
          <View style={{ alignItems: "center", marginLeft: 25.5 }}>
            <Image
              source={ICONS.iconNext}
              resizeMode="contain"
              style={{
                width: 20,
                height: 20,
                tintColor: "#666666"
              }}
            />
          </View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 25
            }}
          >
            <TextInput
              style={[
                font,
                {
                  textAlign: "center",
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#707070",
                  fontWeight: "bold",
                  color: "#666666",
                  width: 75
                }
              ]}
              onChangeText={e => {
                const vd = valueDates;
                vd[navRoute].arrival.location = e;
                setValueDates(vd);
                const d = new Date(vd[navRoute].date);

                setDates(getAllDate(d.getFullYear(), d.getMonth() + 1));
              }}
              value={valueDates[navRoute].arrival.location}
            />
          </View>
        </View>

        <View style={{ marginTop: 18.5, marginLeft: 146, marginRight: 153 }}>
          <Text
            style={{
              color: "rgba(102, 102, 102, 0.5)",
              fontWeight: "bold",
              fontFamily: "Poppins",
              textAlign: "center"
            }}
          >
            Time
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            width: 256
          }}
        >
          <Picker
            style={{ width: "50%" }}
            selectedValue={valueDates[navRoute].departure.time.split(":")[0]}
            onValueChange={e => {
              const vd = valueDates;
              vd[navRoute].departure.time = `${e}:${
                vd[navRoute].departure.time.split(":")[1]
              }`;
              setValueDates(vd);
              const d = new Date(vd[navRoute].date);

              setDates(getAllDate(d.getFullYear(), d.getMonth() + 1));
            }}
          >
            {listHours.map(item => (
              <Picker.Item
                label={zeroNumber(item)}
                value={zeroNumber(item)}
                key={item}
              />
            ))}
          </Picker>
          <Picker
            style={{ width: "50%" }}
            selectedValue={valueDates[navRoute].departure.time.split(":")[1]}
            onValueChange={e => {
              const vd = valueDates;
              vd[navRoute].departure.time = `${
                vd[navRoute].departure.time.split(":")[0]
              }:${e}`;
              setValueDates(vd);
              const d = new Date(vd[navRoute].date);

              setDates(getAllDate(d.getFullYear(), d.getMonth() + 1));
            }}
          >
            {listMinutes.map(item => (
              <Picker.Item
                label={zeroNumber(item)}
                value={zeroNumber(item)}
                key={item}
              />
            ))}
          </Picker>
        </View>
      </View>
    );
  };

  const renderTimepickerFlightRound = () => {
    return (
      <>
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row"
            },
            flightOneWay && { justifyContent: "center" }
          ]}
        >
          <Select
            type={valueDates[navRoute].departure.time}
            listValue={selectTime}
            onChange={e => {
              const vd = valueDates;
              vd.forEach((item, index) => {
                vd[index].departure.time = e.target.value;
              });
              setValueDates(vd);

              let d = "";
              if (vd[navRoute]?.date === "") {
                d = dateFormated(today);
              } else {
                d = vd[navRoute]?.date;
              }
              const dt = new Date(d);

              setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
            }}
          />
        </View>
        {!flightOneWay && (
          <>
            <View style={{ flex: 1, alignItems: "center" }}>
              <Image
                source={ICONS.iconNext}
                resizeMode="contain"
                style={{
                  width: 20,
                  height: 20,
                  tintColor: "#666666"
                }}
              />
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row"
              }}
            >
              <Select
                type={valueDates[navRoute].arrival.time}
                listValue={selectTime}
                onChange={e => {
                  const vd = valueDates;
                  vd.forEach((item, index) => {
                    vd[index].arrival.time = e.target.value;
                  });
                  setValueDates(vd);

                  let d = "";
                  if (vd[navRoute]?.date === "") {
                    d = dateFormated(today);
                  } else {
                    d = vd[navRoute]?.date;
                  }
                  const dt = new Date(d);

                  setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
                }}
              />
            </View>
          </>
        )}
      </>
    );
  };

  const renderTimepickerV2 = () => {
    return (
      <>
        <View style={{ alignItems: "center", marginTop: 44 }}>
          <Text style={{ color: "#ccc", fontWeight: "bold" }}>Time</Text>
        </View>
        <input
          value={valueDates[navRoute].time}
          onChange={e => {
            const val = valueDates;
            val[navRoute].time = e.target.value;
            setValueDates(val);
            let d = "";
            if (val[navRoute]?.date === "") {
              d = dateFormated(today);
            } else {
              d = val[navRoute]?.date;
            }
            const dt = new Date(d);

            setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
          }}
          className="date-timepicker"
          type="time"
          placeholder="Time"
        />
      </>
    );
  };

  const renderTimepickerTripV2 = () => {
    return (
      <>
        <View
          style={{
            flex: 1,
            flexDirection: "row"
          }}
        >
          <input
            value={valueDates[navRoute].time}
            onChange={e => {
              const val = valueDates;
              val[navRoute].time = e.target.value;
              setValueDates(val);
              let d = "";
              if (val[navRoute]?.date === "") {
                d = dateFormated(today);
              } else {
                d = val[navRoute]?.date;
              }
              const dt = new Date(d);

              setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
            }}
            className="date-timepicker"
            type="time"
            placeholder="Time"
          />
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Image
            source={ICONS.iconNext}
            resizeMode="contain"
            style={{
              width: 20,
              height: 20,
              tintColor: "#666666"
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row"
          }}
        >
          <input
            value={valueDates[navRoute].timeTo}
            onChange={e => {
              const val = valueDates;
              val[navRoute].timeTo = e.target.value;
              setValueDates(val);
              let d = "";
              if (val[navRoute]?.date === "") {
                d = dateFormated(today);
              } else {
                d = val[navRoute]?.date;
              }
              const dt = new Date(d);

              setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
            }}
            className="date-timepicker"
            type="time"
            placeholder="Time"
          />
        </View>
      </>
    );
  };

  const renderTravelingV2 = () => {
    const font = {
      fontFamily: "Poppins"
    };
    return (
      <View
        style={{
          alignItems: "center",
          zIndex: 5
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 30,
            width: "100%"
          }}
        >
          <View style={{ alignItems: "center", flex: 1 }}>
            <Text style={[font, { color: "rgba(102, 102, 102, 0.5)" }]}>
              {flight || flightOneWay ? "Departure" : "From"}
            </Text>
          </View>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Text style={[font, { color: "rgba(102, 102, 102, 0.5)" }]}>
              {flight || flightOneWay ? "Arrival" : "To"}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 12,
            flexDirection: "row",
            zIndex: 1
          }}
        >
          <View>
            <RegionPicker
              defaultValue={
                typeof valueDates[navRoute] !== "undefined" &&
                typeof valueDates[navRoute].departure !== "undefined" &&
                typeof valueDates[navRoute].departure.location !== "undefined"
                  ? valueDates[navRoute].departure.location
                  : ""
              }
              option={region}
              style={[
                font,
                {
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#707070",
                  fontWeight: "300",
                  color: "#7e7e7e",
                  width: 100
                }
              ]}
              onValueChange={e => {
                const vd = JSON.parse(JSON.stringify(valueDates));
                vd[navRoute].departure.location = e.label;
                vd[navRoute].departure.id = e.value;
                setValueDates(vd);
                let d = "";
                if (vd[navRoute]?.date === "") {
                  d = dateFormated(today);
                } else {
                  d = vd[navRoute]?.date;
                }
                const dt = new Date(d);

                setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
              }}
            />
          </View>
          <View
            style={{ alignItems: "center", marginLeft: 40, marginRight: 40 }}
          >
            <Image
              source={ICONS.iconNext}
              resizeMode="contain"
              style={{
                width: 20,
                height: 20,
                tintColor: "#666666"
              }}
            />
          </View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <RegionPicker
              option={region}
              defaultValue={
                typeof valueDates[navRoute] !== "undefined" &&
                typeof valueDates[navRoute].arrival !== "undefined" &&
                typeof valueDates[navRoute].arrival.location !== "undefined"
                  ? valueDates[navRoute].arrival.location
                  : ""
              }
              style={[
                font,
                {
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#707070",
                  color: "#7e7e7e",
                  width: 100
                }
              ]}
              onValueChange={e => {
                const vd = JSON.parse(JSON.stringify(valueDates));
                vd[navRoute].arrival.location = e.label;
                vd[navRoute].arrival.id = e.value;
                setValueDates(vd);
                let d = "";
                if (vd[navRoute]?.date === "") {
                  d = dateFormated(today);
                } else {
                  d = vd[navRoute]?.date;
                }
                const dt = new Date(d);

                setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
              }}
            />
          </View>
        </View>

        <View style={{ marginTop: 18.5, marginLeft: 146, marginRight: 153 }}>
          <Text
            style={{
              color: "rgba(102, 102, 102, 0.5)",
              fontWeight: "bold",
              fontFamily: "Poppins",
              textAlign: "center"
            }}
          >
            Time
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            width: 300,
            justifyContent: "center"
          }}
        >
          {flight ? renderTimepickerFlightRound() : <></>}
          {!flight && !flightOneWay ? renderTimepickerTripV2() : <></>}
        </View>
      </View>
    );
  };

  const renderCalendarMonth = () => {
    return (
      <View style={styles.wrapChooseMonth}>
        {monthList.map((row, i) => (
          <View style={styles.wrapChooseMonthRow} key={i}>
            {row.map((m, j) => (
              <View style={{ flex: 1 }} key={j}>
                <Touchable onPress={() => onMonth(i * 3 + 1 + j)}>
                  <View
                    style={[
                      styles.wrapChooseMonthItem,
                      calendar.month === i * 3 + 1 + j
                        ? styles.wrapChooseMonthItemSelected
                        : {}
                    ]}
                  >
                    <Text style={styles.chooseMonthItem}>{m}</Text>
                  </View>
                </Touchable>
              </View>
            ))}
          </View>
        ))}
      </View>
    );
  };
  const renderCalendarYear = () => {
    return (
      <View style={styles.wrapChooseMonth}>
        {listYear.map((row, i) => (
          <Touchable
            key={i}
            onPress={() => {
              const cal = calendar;
              cal.year = row;
              setCalendar(cal);

              setDates(getAllDate(row, cal.month));
              setShowYear(!showYear);
            }}
          >
            <View
              style={[
                styles.wrapChooseMonthRow,
                { paddingHorizontal: 60 },
                row === calendar.year
                  ? {
                      borderColor: "rgb(126, 126, 126)",
                      borderWidth: 1,
                      borderStyle: "solid"
                    }
                  : {}
              ]}
            >
              <Text
                style={{ fontFamily: "Poppins", color: "rgb(126, 126, 126)" }}
              >
                {row}
              </Text>
            </View>
          </Touchable>
        ))}
      </View>
    );
  };

  // const onChangeYear = (text: string) => {
  //   const year = parseFloat(text);
  //   setCalendar({ year, month: calendar.month });
  //   setDates(getAllDate(year, calendar.month));
  // };

  const getStyleDate = (dy: any) => {
    let styling: any = [];
    if (highlight) {
      if (checkValueDates(dy.date) || dy.active) {
        styling = [...styling, styles.dateActive];
      }
    } else if (dy.date === valueDates[navRoute].date && !travelingV2) {
      styling = [...styling, styles.dateActive];
    }
    if (travelingV2) {
      if (
        dy.date === valueDates[navRoute].date ||
        dy.date === valueDates[navRoute].dateTo
      ) {
        if (valueDates[navRoute].dateTo === valueDates[navRoute].date) {
          styling = [...styling, styles.dateToday];
        }
        styling = [...styling, styles.dateActive];
      }
    }
    return styling;
  };

  const getStyleDateText = (dy: any) => {
    let styling: any = [{ color: "#7e7e7e" }];
    if (dy.blur || dy.disabled) {
      styling = [
        ...styling,
        {
          color: "#ccc"
        }
      ];
    } else if (midDate.indexOf(dy.date) >= 0 && (highlight || travelingV2)) {
      styling = [
        ...styling,
        {
          color: "#ed5a28",
          fontSize: 14,
          fontWeight: "bold"
        }
      ];
    }
    if (highlight && !travelingV2) {
      if (checkValueDates(dy.date)) {
        styling = [
          ...styling,
          {
            color: "#fff",
            fontWeight: "bold",
            fontSize: 18
          }
        ];
      }
    } else if (dy.date === valueDates[navRoute].date && !travelingV2) {
      styling = [
        ...styling,
        {
          color: "#fff",
          fontWeight: "bold",
          fontSize: 18
        }
      ];
    }
    if (travelingV2) {
      if (
        dy.date === valueDates[navRoute].date ||
        dy.date === valueDates[navRoute].dateTo
      ) {
        styling = [
          ...styling,
          {
            color: "#fff",
            fontWeight: "bold",
            fontSize: 18
          }
        ];
      }
    }

    return styling;
  };

  const onChangeNav = (nav: number) => {
    let dt = "";
    if (value[nav].date === "") {
      dt = dateFormated(today);
    } else {
      dt = value[nav].date;
    }
    const d = new Date(dt);
    setDates(getAllDate(d.getFullYear(), d.getMonth() + 1, nav));
    setCalendar({
      year: d.getFullYear(),
      month: d.getMonth() + 1
    });
    setNavRoute(nav);
  };

  const renderHead = (h: ReactChild) => {
    return h;
  };

  const renderCalendar = () => {
    const mo = calendar.month;
    const dt = dates;
    const month = getMonth(mo);
    const sty = {
      backgroundColor: "#fffff7",
      borderRadius: 50,
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
    };
    // const responsive = {
    //   width: 284,
    //   height: 480,
    //   overflow: "scroll"
    // };
    return (
      <View style={{}}>
        <View
          style={[
            sty,
            widthPercent(100) <= 375
              ? {
                  paddingLeft: 100,
                  marginTop: timepicker ? 150 : 0,
                  height: timepicker ? "100%" : 480,
                  width: 284,
                  overflow: "scroll"
                }
              : {},
            {
              alignItems: "center"
            }
          ]}
        >
          {head ? renderHead(head(navRoute, onChangeNav)) : <></>}

          {traveling ? renderTraveling() : <></>}
          {travelingV2 ? renderTravelingV2() : <></>}
          <View
            style={[
              styles.wrapMonthYear,
              { marginTop: 35 },
              { justifyContent: "center" },
              { zIndex: 1 }
            ]}
          >
            <View style={[styles.wrapMonthYearItem]}>
              <Touchable onPress={() => onShowMonths()}>
                <View style={styles.wrapMonthYearButton}>
                  <Text style={styles.wrapMonthYearButtonText}>{month}</Text>
                </View>
              </Touchable>
              {showMonths ? renderCalendarMonth() : <></>}
            </View>
            <View
              style={[
                styles.wrapMonthYearItem,
                {
                  paddingLeft: 10,
                  flexDirection: "column",
                  marginLeft: 16
                }
              ]}
            >
              <Touchable onPress={() => setShowYear(!showYear)}>
                <View style={styles.wrapMonthYearButton}>
                  <Text
                    style={[
                      styles.wrapMonthYearButtonText,
                      { textAlign: "center" }
                    ]}
                  >
                    {calendar.year.toString()}
                  </Text>
                </View>
              </Touchable>
              {showYear ? renderCalendarYear() : <></>}
            </View>
          </View>
          <View style={{ alignItems: "center" }}>
            <View
              style={{
                zIndex: -1,
                marginTop: 28,
                flexDirection: "row"
              }}
            >
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>S</Text>
              </View>
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>M</Text>
              </View>
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>T</Text>
              </View>
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>W</Text>
              </View>
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>T</Text>
              </View>
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>F</Text>
              </View>
              <View style={styles.wrapDayNameView}>
                <Text style={styles.wrapDayName}>S</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "column",
                zIndex: -1
              }}
            >
              {dt.map((week, i) => (
                <View key={i} style={styles.wrapWeek}>
                  {week.map((dy, j) => (
                    <View style={[styles.wrapDate]} key={j}>
                      <Touchable onPress={() => onSelectDate(dy)}>
                        <View style={[styles.date, ...getStyleDate(dy)]}>
                          <Text
                            style={[styles.dateLabel, ...getStyleDateText(dy)]}
                          >
                            {dy.label}
                          </Text>
                        </View>
                      </Touchable>
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </View>
          {traveling ? <></> : <>{timepicker ? renderTimepickerV2() : <></>}</>}
          <View style={{ alignItems: "center" }}>
            <Touchable
              onPress={() => {
                if (onChange) {
                  let val: any = [];
                  valueDates.forEach(item => {
                    let dis: any = {
                      date: item.date
                    };
                    if (timepicker) {
                      dis = {
                        ...dis,
                        ...{ time: item.time }
                      };
                    }
                    if (traveling) {
                      dis = {
                        ...dis,
                        ...{ departure: item.departure },
                        ...{ arrival: item.arrival }
                      };
                    }
                    if (travelingV2 && !flightOneWay) {
                      dis = {
                        ...dis,
                        time: item.time,
                        timeTo: item.timeTo,
                        departure: item.departure,
                        arrival: item.arrival
                      };
                      if (item.dateTo === "") {
                        dis = {
                          ...dis,
                          dateTo: item.date
                        };
                      } else {
                        dis = {
                          ...dis,
                          dateTo: item.dateTo
                        };
                      }
                    }
                    if (travelingV2 && flightOneWay) {
                      dis = {
                        ...dis,
                        time: item.time,
                        departure: item.departure,
                        arrival: item.arrival
                      };
                    }
                    val = [...val, ...[dis]];
                  });
                  onChange(val);

                  setValueUse(true);
                }
                setShowCalendar(false);
                if (typeof showNav === "number") {
                  setNavRoute(showNav);
                }
                if (onChangeVisible) {
                  onChangeVisible(false);
                }
              }}
            >
              <View style={styles.buttonSave}>
                <Text style={{ color: "#fff", fontWeight: "bold" }}>Save</Text>
              </View>
            </Touchable>
            <Touchable
              onPress={() => {
                const v: any = getDataFromValue();
                setValueDates(v);
                setValueUse(true);
                setShowCalendar(false);
                if (onChangeVisible) {
                  onChangeVisible(false);
                }
                if (typeof showNav === "number") {
                  setNavRoute(showNav);
                }
                if (onChange) {
                  onChange(value);
                }
              }}
            >
              <View style={styles.buttonBack}>
                <Text style={{ color: "#ccc", fontWeight: "bold" }}>Back</Text>
              </View>
            </Touchable>
          </View>
        </View>
      </View>
    );
  };

  useEffect(() => {
    if (listHours.length === 0) {
      let hour: any[] = [];
      for (let i = 0; i <= 23; i += 1) {
        hour = [...hour, ...[i]];
      }
      setListHours(hour);
    }
    if (listMinutes.length === 0) {
      let minute: any[] = [];
      for (let i = 0; i <= 59; i += 1) {
        minute = [...minute, ...[i]];
      }
      setListMinutes(minute);
    }

    const v: any = getDataFromValue();
    if (valueUse && v !== valueDates && showCalendar) {
      setValueDates(v);
      setValueUse(false);
      let dd: any = [];
      value.forEach((vd: { date: any; dateTo?: any }) => {
        dd = [...dd, ...[vd.date]];
        if (travelingV2) {
          dd = [...dd, ...[vd.dateTo]];
        }
      });
      setUpMidDate(dd);
      let d = "";
      if (value[showNav].date === "") {
        d = dateFormated(today);
      } else {
        d = value[showNav].date;
      }
      const dt = new Date(d);
      setDates(getAllDate(dt.getFullYear(), dt.getMonth() + 1));
      setCalendar({
        year: dt.getFullYear(),
        month: dt.getMonth() + 1
      });
      setNavRoute(showNav);
    }
    if (visible) {
      if (onChangeVisible) {
        onChangeVisible(!visible);
      }
      setShowCalendar(true);
    }
  }, [
    flightOneWay,
    listHours.length,
    listMinutes.length,
    setUpMidDate,
    value,
    valueDates,
    valueUse,
    timepicker,
    onChangeVisible,
    showNav,
    visible,
    showCalendar,
    traveling,
    dateFormated,
    getAllDate,
    today,
    travelingV2,
    getDataFromValue
  ]);

  return (
    <View style={[style || {}, {}]}>
      <View style={{ flex: 1 }}>
        <Touchable
          disabled={disable}
          onPress={() => {
            setShowCalendar(true);
            if (onChangeVisible) {
              onChangeVisible(true);
            }
          }}
        >
          <View style={{ flex: 1 }}>{children}</View>
        </Touchable>
      </View>
      <Modal isVisible={showCalendar} withScroll>
        {showCalendar ? renderCalendar() : <></>}
      </Modal>
    </View>
  );
};

Datepicker.defaulProps = {
  bottom: 0,
  flight: false
};

export default Datepicker;
