/* eslint-disable no-nested-ternary */
import axios from "axios";
import { GetEmployeeV2Params } from "../redux/types";

const host = axios.create({
  baseURL: process.env.REACT_APP_HOST
});

// const hostStagingWeb = axios.create({
//   baseURL: process.env.REACT_APP_HOST_STAGING_WEB
// });
// const hostDummy = axios.create({
//   baseURL: process.env.REACT_APP_HOST_DUMMY
// });

const hostStaging = axios.create({
  baseURL: process.env.REACT_APP_HOST_STAGING
});

interface BodyLogin {
  email: string;
  password: string;
}

type TypeTrip =
  | "upcoming"
  | "ongoing"
  | "assigned"
  | "finished"
  | "issued"
  | "bill";

export interface ParamsTrip {
  type: TypeTrip;
  per_page: number;
  page: number;
  sortField: string;
  sortOrder: string;
  include: string;
  filter: string;
}

export interface ParamsExpenseGeneral {
  per_page: number;
  page: number;
}

export interface ParamsExpenseListApproval {
  per_page: number;
  page: number;
}

export interface ParamsExpenseTrip {
  per_page: number;
  page: number;
}

export interface BodyPostNewExpense {
  purpose: string;
  type_id: string;
  started_at_d: string;
  category_id: string;
  project_id: string;
  amount: string;
  started_at_t: null;
  finished_at_d: null;
  finished_at_t: null;
}

export interface BodyPutEditExpense {
  purpose: string;
  description: string;
  amount: string;
}

export interface BodyPostNewTrip {
  category_id: string;
  description: string;
  destination_location_id: string;
  finished_at_d: string;
  finished_at_t: string;
  origin_location_id: string;
  participants: string;
  project_id: string;
  self_booking: boolean;
  started_at_d: string;
  started_at_t: string;
  title: string;
  participants_data: any[];
}

export interface BodyPostNewBookingFlight {
  source_id: string;
  trip_id: string;
  vendor_id: string;
  from: string;
  to: string;
  started_at_d: string;
  started_at_t: string;
  finished_at_d: string;
  finished_at_t: string;
  class_id: string;
  baggage: number;
  airline_id: string;
  participants: any;
  remarks: string;
  is_round_trip: boolean;
  started_at: string;
  finished_at: string;
  has_baggage: boolean;
  client_timezone: any;
}

export interface BodyPutBookingFlight {
  vendor_id: string;
  from: string;
  to: string;
  started_at_d: string;
  started_at_t: string;
  finished_at_d: string;
  finished_at_t: string;
  class_id: string;
  baggage: number;
  airline_id: string;
  participants: any;
  remarks: string;
}

export interface BodyPostNewBookingHotel {
  source_id: string;
  trip_id: string | null;
  vendor_id: string;
  regency_id: string;
  started_at_d: string;
  started_at_t: string;
  finished_at_d: string;
  finished_at_t: string;
  star_id: string;
  suite_id: string;
  inhabitants: number;
  quantity: number;
  remarks: string | null;
  started_at: string;
  finished_at: string;
  client_timezone: any;
  hotel?: string | null;
}

export interface BodyPutEditBookingHotel {
  source_id: string;
  trip_id: string | null;
  vendor_id: string;
  regency_id: string;
  started_at_d: string;
  started_at_t: string;
  finished_at_d: string;
  finished_at_t: string;
  star_id: string;
  suite_id: string;
  inhabitants: number;
  quantity: number;
  remarks: string | null;
  started_at: string;
  finished_at: string;
  client_timezone: any;
  hotel?: string | null;
}

export interface BodyPostNewReport {
  trip_id: string;
  date: string;
  title: string;
  description: string;
  file: any;
}

export interface BodyPutEditReport {
  date: string;
  title: string;
  description: string;
  file: any;
  status: number;
}

export interface BodyPostCashExpense {
  trip_id: string;
  type_id: string;
  currency_code: string;
  title: string;
  category_id: string;
  description: string;
  quantity: number;
  amount: string;
  billable: boolean;
}

export interface BodyPutCloseBill {
  trip_user_id: string;
}

export interface ParamGetBookingList {
  idTrip: string;
  type: "hotel" | "flight";
}

export interface ParamTaskList {
  per_page: number;
  page: number;
}

export interface ParamCashList {
  per_page: number;
  page: number;
}

const api = {
  postLogin: (body: BodyLogin) => host.post("api/v2/auth/login", body),
  postLogout: (token: string) =>
    host.post(
      "auth/logout",
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getMyProfile: (token: string) =>
    host.get("api/v2/auth/user?include=employee", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getMyFullProfile: (uuid: string, token: string) =>
    host.get(`api/v2/company/employee/${uuid}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getMyDashboard: (token: string) =>
    host.get("api/v1/mobiledashboard", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getMyPermission: (token: string) =>
    host.get("api/v1/setting/permission/owned", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getMySettingGeneral: (token: string) =>
    host.get("api/v1/setting/general/owned", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTrip: (params: ParamsTrip, token: string, q: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/trip/trip/status/${params.type}?query=${params.filter}&per_page=${params.per_page}&q=${q}&page=${params.page}&sortField=${params.sortField}&sortOrder=${params.sortOrder}&include=${params.include}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getTripById: (tripId: any, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/trip/trip/${tripId}?per_page=10&q=&page=1&sortField=created_at&sortOrder=desc&include=currency,project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getBookingHotelById: (id: string, token: string) =>
    hostStaging.get(
      // eslint-disable-next-line max-len
      `/api/v2/booking/booking/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  deleteBookingById: (id: string, token: string) =>
    hostStaging.delete(
      // eslint-disable-next-line max-len
      `/api/v1/booking/booking/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getTripReport: (tripId: any, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/trip/report/trip/${tripId}/owned`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getPolicies: (token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      "api/v1/policy/policy/owned",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  deleteTripReport: (reportId: any, token: string) =>
    host.delete(
      // eslint-disable-next-line max-len
      `api/v1/trip/report/${reportId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  putSubmitTripReport: (reportId: any, token: string) =>
    host.put(
      // eslint-disable-next-line max-len
      `api/v1/trip/report/${reportId}/submit`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getApprovalTrip: (token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      "api/v2/moderation/moderation/my/trip?include=reference,reference.users,djurneeBooking,djurneeBooking.booiking",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  putModerationTrip: (tripId: any, answer: any, message: any, token: string) =>
    host.put(
      `api/v1/moderation/moderation/${tripId}`,
      {
        id: tripId,
        answer,
        message
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getRegion: (token: string) =>
    host.get("api/v2/master/regency/get-regency-by-province-id", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getListLoan: (token: string, param: any, status: number) =>
    host.get(`api/v2/finance/loan/owned?page=${param.page}&status=${status}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  getLoanHistory: (token: string, params: any) =>
    host.get("api/v1/finance/loan/owned", {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        include: "employee,invoiceRequest",
        status: [6, 8],
        ...params
      }
    }),
  getLoanProfile: (token: string) =>
    host.get("api/v2/finance/loan/profiles/owned", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getSubmittedLoan: (token: string) =>
    host.get("api/v1/finance/loan/owned", {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        include: "employee,invoiceRequest",
        status: [2, 3, 9, 5],
        sortOrder: "desc"
      }
    }),
  getKasbonSetting: (token: string) =>
    host.get("api/v1/finance/loan/credit", {
      headers: { Authorization: `Bearer ${token}` }
    }),

  postLoan: (token: string, body: any) =>
    host.post("api/v1/finance/loan/create", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getLoanDetail: (token: string, id: string) =>
    host.get(`api/v1/finance/loan/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getLoanTotal: (token: string) =>
    host.get("api/v2/finance/loan/owned", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getExpenseGeneral: (params: ParamsExpenseGeneral, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/finance/invoice/owned?per_page=${params.per_page}&q=&page=${params.page}&non_trip=1&sortField=created_at&sortOrder=desc&include=currency,djurneeInvoice,djurneeInvoice.trip,type,djurneeInvoice.type,djurneeInvoice.user,moderation,items,djurneeInvoice.djurneeItems.category`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getExpenseTrip: (params: ParamsExpenseTrip, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/trip/trip/resumeexpenses?per_page=${params.per_page}&q=&page=${params.page}&sortField=created_at&sortOrder=desc&include=project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking,expense,invoice,sumexpenses`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getBookingList: (params: ParamGetBookingList, token: string) =>
    hostStaging.get(
      `api/v2/booking/booking/trip/${params.idTrip}?q=${params.type}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getPopulateOptions: (token: string) =>
    host.get(
      "api/v1/finance/category/populate-implement-policy?return=true&hidden=false",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getFinanceCategory: (token: string) =>
    host.get(
      "api/v1/finance/category/populate-implement-policy?return=true&hidden=false",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getFinanceCategoryReimburse: (token: string) =>
    host.get(
      "api/v1/finance/category/populate-implement-policy-reimburse?return=true&hidden=false",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getDateAvailableReport: (params: string, token: string) =>
    host.get(`api/v1/trip/report/trip/${params}/populate/date`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getProjectOptions: (token: string) =>
    host.get("api/v2/project/project/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postInvoiceBooking: (body: any, token: string) =>
    host.post("api/v1/djurnee/item/booking", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putInvoiceBooking: (body: any, itemId: string, token: string) =>
    host.post(`api/v1/djurnee/item/${itemId}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getInvoiceBookingList: (id: string, token: string) =>
    host.get(`api/v2/djurnee/invoice/booking/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postNewExpense: (body: BodyPostNewExpense, token: string) =>
    host.post(
      "api/v1/finance/invoice",
      {
        ...{
          currency_code: "IDR",
          quantity: 1
        },
        ...body
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  putEditExpense: (params: string, body: BodyPutEditExpense, token: string) =>
    host.put(
      `api/v1/finance/invoice/${params}/general-expense`,
      {
        ...{
          currency_code: "IDR",
          quantity: 1
        },
        ...body
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getVendor: (token: string) =>
    host.get("api/v2/booking/vendor/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getVendorDetail: (id: string, token: string) =>
    host.get(`api/v2/booking/vendor/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDetailBookingHotel: (params: string, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/booking/booking/trip/category/${params}/hotel?include=djurneeBooking.trip,booking,djurneeBooking,source,creator,subjects`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getRegency: (token: string) =>
    host.get("api/v1/master/regency", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getSource: (token: string) =>
    host.get("api/v1/booking/source/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postNewBookingHotel: (body: BodyPostNewBookingHotel, token: string) =>
    hostStaging.post("api/v1/booking/booking", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putEditBookingHotel: (
    body: BodyPutEditBookingHotel,
    id: string,
    token: string
  ) =>
    hostStaging.put(`api/v2/booking/booking/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDetailBookingFlight: (id: string, token: string) =>
    host.get(`api/v2/booking/booking/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getExpenseDetail: (moderationId: string, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/finance/invoice/${moderationId}?id=${moderationId}&include=payments,payments.payment,payments.payment.method,currency,djurneeInvoice,type,djurneeInvoice.trip,djurneeInvoice.trip.category,djurneeInvoice.trip.client,djurneeInvoice.type,djurneeInvoice.trip.project,moderation,moderation.user,djurneeInvoice.user`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getPopulateCategory: (token: string) =>
    host.get("api/v2/trip/category/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getPopulateFinanceCategory: (token: string) =>
    host.get(
      "api/v1/finance/category/populate-implement-policy?hidden=false&return=true",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getPopulateProject: (token: string, customParams: any = {}) =>
    host.get("api/v1/project/project/populate", {
      headers: { Authorization: `Bearer ${token}` },
      params: { ...customParams }
    }),
  postNewTrip: (body: any, token: string) =>
    host({
      method: "post",
      url: "api/v1/trip/trip",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  putEditTrip: (body: any, token: string, id: string) =>
    host({
      method: "post",
      url: `api/v1/trip/trip/${id}`,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  putNewTrip: (params: string, token: string) =>
    host.put(
      `api/v1/trip/trip/${params}/issue`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getBookingSourceFlight: (token: string) =>
    host.get("api/v2/booking/source/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getAirline: (token: string) =>
    host.get("api/v2/booking/airline/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postNewBookingFlight: (body: BodyPostNewBookingFlight, token: string) =>
    host.post(
      "api/v1/booking/booking",
      {
        ...body
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getExpenseListApproval: (params: ParamsExpenseGeneral, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/finance/invoice/approvable?per_page=${params.per_page}&q=&page=${params.page}&sortField=created_at&sortOrder=desc&include=currency,djurneeInvoice,djurneeInvoice.trip,type,djurneeInvoice.type,djurneeInvoice.user,moderation,items,djurneeInvoice.djurneeItems.category`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  postNewReport: (body: any, token: string) =>
    host({
      method: "post",
      url: "/api/v1/trip/report",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  putEditReport: (id: string, body: any, token: string) =>
    host.post(`api/v1/trip/report/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getReportById: (id: string, token: string) =>
    host.get(`api/v1/trip/report/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getNewExpenseTripPerDiem: (params: string, token: string) =>
    host.get(`api/v2/djurnee/invoice/user/lumpsum/${params}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
  getNewExpenseTripCashAdvance: (params: string, token: string) =>
    host.get(`api/v2/djurnee/invoice/user/cashadvance/${params}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
  getNewExpenseCashAdvance: (params: string, token: string) =>
    host.get(
      `api/v2/djurnee/invoice/user/cashadvance/${params}?withoutUser=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ),
  getTripApprovalAdditonal: (params: any, token: string) => {
    // eslint-disable-next-line max-len
    return host.get(
      // eslint-disable-next-line max-len
      `api/v2/moderation/moderation/owned?q=${params}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },
  postnewCashExpense: (body: any, token: string) =>
    host.post("api/v1/djurnee/item", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  getBudgetReq: (params: string, token: string, lang: string) =>
    host.get(`api/v1/finance/invoice/total?trip_id=${params}&lang=${lang}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getReportByUserId: (filter: any, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/trip/report?${filter}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getBillsByTripId: (id: string, categoryId: string, token: string) =>
    host.get(
      `api/v2/djurnee/invoice/user/cashadvance/${id}/category?param=${categoryId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  postNewBill: (body: any, token: string) =>
    host({
      method: "post",
      url: "api/v1/djurnee/expense",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  postBillable: (body: any, id: string, token: string) =>
    host({
      method: "post",
      url: `api/v2/djurnee/invoice/user/cashadvance/billable/${id}`,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
  putCloseBill: (body: BodyPutCloseBill, token: string) =>
    host.put(
      `api/v1/djurnee/expense/user/${body.trip_user_id}/confirm`,
      {
        ...body
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getReimburse: (params: string, token: string) =>
    host.get(`api/v2/djurnee/invoice/user/reimbuse/${params}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putBookingFlight: (id: string, body: BodyPutBookingFlight, token: string) =>
    host.put(`api/v2/booking/booking/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postNewExpenseReimburse: (body: any, token: string) =>
    host({
      method: "post",
      url: "/api/v1/djurnee/item",
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  deleteReimburse: (params: string, token: string) =>
    host.delete(`api/v2/djurnee/item/${params}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDetailBills: (params: any, token: string) =>
    host.get(`api/v2/djurnee/expense/${params}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  editDetailBills: (body: any, params: any, token: string) =>
    host.put(
      `api/v1/djurnee/expense/${params}`,
      {
        ...body
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  putResolveTrip: (params: string, token: string) =>
    host.put(
      `api/v1/trip/trip/${params}/issue`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getEmployee: (token: string) =>
    host.get("api/v1/company/employee", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getConfigSizeUpload: () => host.get("api/v2/auth/config"),
  deleteBill: (uuid: string, token: string) =>
    host.delete(`api/v1/djurnee/expense/${uuid}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  deleteCashAdvance: (id: string, token: string) =>
    host.delete(`api/v1/djurnee/item/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  deleteTripAttachmentById: (tripId: any, token: string) =>
    host.delete(
      // eslint-disable-next-line max-len
      `api/v2/trip/trip/${tripId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getCloseTrip: (tripId: any, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/djurnee/invoice/${tripId}/closingtrip`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getCategoryByTrip: (tripid: string, token: string) =>
    host.get(`api/v2/trip/trip/${tripid}/expense-category`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDetailTask: (taskId: string | undefined, token: string) =>
    host.get(`api/v2/task/${taskId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  extendDaysDetailTask: (
    taskId: string,
    body: { days: number },
    token: string
  ) => {
    host.put(`api/v2/task/${taskId}/extend`, body, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  uploadFileDetailTask: (taskId: string, body: any, token: string) =>
    host.post(`api/v2/task/${taskId}/upload/file`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  uploadFileDetailTaskReport: (reportId: string, body: any, token: string) =>
    host.post(`api/v2/task/report/${reportId}/upload/file`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  deleteFileDetailTask: (taskId: string, fileId: any, token: string) =>
    host.delete(`api/v2/task/${taskId}/delete/file/${fileId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDetailTaskRemarks: (taskId: string | undefined, token: string) =>
    host.get(`api/v2/task/${taskId}/remarks`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postDetailTaskRemarks: (
    taskId: string,
    body: { comment: string },
    token: string
  ) =>
    host.post(`api/v2/task/${taskId}/comment`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDetailTaskReport: (
    taskId: string | undefined,
    userId: string,
    token: string
  ) =>
    host.get(`api/v2/task/${taskId}/user/${userId}/reports`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getAvailableDateTaskReport: (
    taskId: string | undefined,
    userId: string,
    token: string
  ) =>
    host.get(`api/v2/task/${taskId}/user/${userId}/reports/available-date`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postTaskDetailReport: (
    taskId: string | undefined,
    userId: string,
    body: any,
    token: string
  ) =>
    host.post(`api/v2/task/${taskId}/user/${userId}/reports`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  putTaskDetailReport: (
    taskId: string | undefined,
    userId: string,
    reportId: string,
    body: any,
    token: string
  ) =>
    host.put(`api/v2/task/${taskId}/user/${userId}/reports/${reportId}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  submitTaskDetailReport: (
    taskId: string | undefined,
    userId: string,
    reportId: string,
    body: { date: string; description: string },
    token: string
  ) =>
    host.put(
      `api/v2/task/${taskId}/user/${userId}/reports/${reportId}/submit`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),

  submitEndTask: (taskId: string | undefined, token: string) =>
    host.post(
      `api/v2/task/${taskId}/finish`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),

  deleteFileTaskReport: (
    reportId: string | undefined,

    fileId: string,
    token: string
  ) =>
    host.delete(`api/v2/task/report/${reportId}/delete/file/${fileId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTaskList: (
    status: string,
    params: ParamTaskList,
    token: string,
    q: string
  ) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/task/status/${status}?per_page=${params.per_page}&page=${params.page}&q=${q}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getDepartment: (token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      "api/v2/company/department",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),

  getDepartmentV1: (token: string) =>
    host.get("api/v1/reports/menu/department", {
      headers: { Authorization: `Bearer ${token}` }
    }),

  getCurrency: (token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      "api/v1/master/currency/populate",
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  editProfile: (body: any, employeeId: string, token: string) =>
    host.put(
      // eslint-disable-next-line max-len
      `api/v2/company/employee/${employeeId}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  postTask: (body: any, token: string) =>
    host.post("api/v2/task", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putTask: (idTask: string, body: any, token: string) =>
    host.post(
      "api/v2/task/assignee",
      { task_id: idTask, ...body },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  putTaskEdit: (taskId: string, body: any, token: string) =>
    host.put(`api/v2/task/updateRequest/${taskId}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putTaskRecurring: (taskId: string, body: any, token: string) =>
    host.put(`api/v2/task/${taskId}/recurring`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postTaskRequest: (body: any, token: string) =>
    host.post("api/v2/task/createRequest", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putModeration: (body: any, token: string) =>
    host.put(`api/v1/moderation/moderation/${body.id}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postTaskActivate: (taskId: string | undefined, token: string) =>
    host.post(
      `api/v2/task/submitRequest/${taskId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getEmployeeV2: (token: string, params: GetEmployeeV2Params = {}) =>
    host.get("api/v2/company/employee", {
      headers: { Authorization: `Bearer ${token}` },
      params
    }),
  getEmployeeExceptSelf: (token: string) =>
    host.get("api/v1/company/employee/populateExceptSelf", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postNewGeneralExpense: (body: any, token: string, id?: string) =>
    host.post(`api/v1/finance/invoice${id ? `/${id}` : ""}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  getBudgetType: (id: string, token: string) =>
    host.get(`api/v1/djurnee/project/${id}/budget/populate/defined`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getMenu: (mod: string, token: string) =>
    host.get(`api/v1/dashboard/menu?module=${mod}&hide_draft=true`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getInvoiceList: (token: string, type: string) =>
    host.get(`api/v1/djurnee/invoice/unpaid/populate?type=${type}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getInvoiceDetail: (invoiceId: string, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/finance/invoice/${invoiceId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getItemDetail: (itemId: string, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/djurnee/item/${itemId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getInvoiceDetailV1: (invoiceId: string, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v1/finance/invoice/${invoiceId}?include=currency,djurneeInvoice,djurneeInvoice.user,djurneeInvoice.type,djurneeInvoice.vendor`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getPaymentMethod: (token: string) =>
    host.get("api/v1/djurnee/payment/method/active?include=bank,method", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getListBankAccount: (type: "user" | "vendor", id: string, token: string) =>
    host.get(`api/v1/finance/account/${type}/${id}/populate`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDescBankAccount: (id: string, token: string) =>
    host.get(`api/v1/finance/account/${id}?include=bank`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getListUserBankAccount: (userId: string, token: string) =>
    host.get(`api/v1/finance/account/user/${userId}/populate`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDescUserBankAccount: (accountId: string, token: string) =>
    host.get(`api/v2/finance/account/${accountId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postInvoicePay: (body: any, token: string) =>
    host.post("api/v1/finance/payment", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  getCashList: (
    status: string,
    params: ParamCashList,
    token: string,
    q: string
  ) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/finance/invoice/${status}?per_page=${params.per_page}&page=${
        params.page
        // eslint-disable-next-line max-len
      }&q=${q}&sortField=status,due_at&sortOrder=asc,asc&include=currency,djurneeInvoice,creator,djurneeInvoice.trip,type,djurneeInvoice.type,djurneeInvoice.user,items.files.file${
        status === "owned"
          ? ",djurneeInvoice.category,djurneeInvoice.project"
          : status === "approvable"
          ? ",djurneeInvoice.category,djurneeInvoice.project,moderation"
          : status === "collectable"
          ? ",djurneeInvoice.client"
          : status === "billable"
          ? ",djurneeInvoice.client"
          : ""
      }`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getCashDetail: (id: string, token: string) =>
    host.get(`api/v2/finance/invoice/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putRecurring: (body: any, token: string) =>
    host.put(`api/v1/finance/invoice/${body.invoice_id}/recurring`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getPosition: (token: string) =>
    host.get("api/v1/company/position/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getModerationData: (id: string, token: string) =>
    host.get(`api/v1/moderation/moderation/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getModerationReference: (invoiceId: string, token: string) =>
    host.get(`api/v2/moderation/moderation/reference/invoice/${invoiceId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putModerateCash: (body: any, token: string) =>
    host.put(
      // eslint-disable-next-line max-len
      `api/v1/moderation/moderation/${body.id}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getRequestList: (id: string, token: string) =>
    host.get(`api/v2/djurnee/item/invoice/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getBill: (id: string, token: string) =>
    host.get(`api/v2/djurnee/expense/invoice/${id}?include=category`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postCashItem: (body: any, token: string) =>
    host.post("api/v1/djurnee/item", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putCashItem: (body: any, itemID: string, token: string) =>
    host({
      method: "post",
      url: `api/v1/djurnee/item/${itemID}`,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  getCashItemById: (id: string, token: string) =>
    host.get(`api/v2/djurnee/item/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  deleteCashItem: (id: string, token: string) =>
    host.delete(`api/v1/djurnee/item/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putReissue: (id: string, body: any, token: string) =>
    host.post(`api/v1/finance/invoice/${id}/issue`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  submitIssue: (id: string, token: string) =>
    host.post(`api/v1/djurnee/invoice/${id}/submit`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
  putEditTripss: (body: any, token: string, id: string) =>
    host({
      method: "post",
      url: `api/v1/trip/trip/${id}`,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }),
  putSettle: (body: any, token: string) =>
    host.put(
      `api/v1/djurnee/expense/user/${
        body.invoice_id ? body.invoice_id : body.trip_user_id
      }/confirm`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getListPaymentMethod: (token: string) =>
    host.get("api/v1/djurnee/payment/method/active?include=bank,method", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getListCountry: (token: string) =>
    host.get("api/v2/master/country/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getListAirport: (token: string, countryCode: string) =>
    host.get(
      `api/v1/ticket/airport/international?country_code=${countryCode}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getCompanyAddress: (token: string) =>
    host.get("api/v1/company/company/owned/address", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTaskListDropdown: (token: string, type: string) =>
    host.get(
      `api/v1/task/project/${
        type === "rm" ? "list" : "list-ongoing-or-approved"
      }`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  postFeedBack: (body: any, token: string) =>
    host.post("api/v1/help-and-support", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTermDataActivity: (token: string) =>
    host.get("api/v2/temp-form-data/trip", {
      headers: { Authorization: `Bearer ${token}` }
    }),

  postTermDataActivity: (body: { data: string }, token: string) =>
    host.post("api/v2/temp-form-data/trip", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postGenerateAllowance: (body: any, userId: string, token: string) =>
    host.post(`api/v2/trip/trip/generate/allowance/user/${userId}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postSubmitActivity: (body: any, token: string) =>
    host.post("api/v2/trip/trip", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getNotification: (params: any, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/moderation/moderation/myowned?per_page=${params.per_page}&page=${params.page}&sortField=${params.sortField}&sortOrder=${params.sortOrder}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getBookingVendorById: (token: string, idVendor: string) =>
    host.get(`api/v2/booking/vendor/${idVendor}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  deleteTrip: (id: string, token: string) =>
    host.delete(`api/v1/trip/trip/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putTripCA: (body: any, id: string, token: string) =>
    host.post(`api/v1/djurnee/item/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getAccountableByTripId: (id: string, token: string) =>
    host.get(`api/v1/djurnee/trip/${id}/accountable?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTripModeration: (id: string, token: string) =>
    host.get(`api/v2/moderation/moderation/reference/trip/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTripBillModeration: (tripUserId: string, token: string) =>
    host.get(`api/v2/moderation/moderation/reference/tripuser/${tripUserId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTripExpense: (id: string, token: string) =>
    host.get(
      // eslint-disable-next-line max-len
      `api/v2/finance/invoice/trip/${id}?include=currency,djurneeInvoice,djurneeInvoice.trip,type,djurneeInvoice.type,djurneeInvoice.user&id=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  getBillTrip: (id: string, token: string) =>
    host.get(`api/v2/djurnee/invoice/user/bill/list/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getBubbleNotif: (type: string, token: string) =>
    host.get(`api/v2/dashboard/notifications/${type}`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postBillTrip: (body: any, token: string, id?: string) =>
    host.post(`api/v1/djurnee/expense${id ? `/${id}` : ""}`, body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getTaxList: (token: string) =>
    host.get("api/v2/finance/tax?include=category", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getPopulateBrand: (token: string) =>
    host.get("api/v1/djurnee/brand/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getPopulateBranch: (token: string) =>
    host.get("api/v1/company/branch/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getParentProject: (uuid: any, token: string) =>
    host.get(`api/v1/project/project/${uuid}/parent`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  postTaxItem: (body: any, token: string) =>
    host.post("api/v1/finance/item/tax", body, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getPopulateProduct: (token: string) =>
    host.get("api/v1/finance/product/populate", {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getBudgetRemaining: (id: string, token: string) =>
    host.get(
      `api/v1/djurnee/project/${id}/budget/remaining?id=${id}e&return=true`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    ),
  putSubmitBill: (body: any, token: string) =>
    host.put(
      `api/v1/djurnee/expense/user/${
        body.invoice_id ? body.invoice_id : body.trip_user_id
      }/confirm-moderation`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
};

export default api;
