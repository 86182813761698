import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, TextInput, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";

import { IMAGES } from "../../../configs";
import Cards from "../../NewTrip/components/Card";
import { convertDate, documentTitle, widthPercent } from "../../../utils";
import {
  Button,
  Card,
  LoadingLoadMore,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";

// redux action & types
import { getExpenseGeneral, putEditExpense } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const initExpenseData: any = null;

const General = () => {
  documentTitle("Expense - General");
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalOpen] = useState(false);
  const [expenseData, setExpenseData] = useState(initExpenseData);

  const [form, setForm] = useState({
    purpose: "",
    description: "",
    amount: "",
    category: []
  });
  const expenseState = useSelector((state: Reducers) => state.expense);
  // const newExpenseState = useSelector((state: Reducers) => state.newExpense);
  // const methodOptions = [
  //   { value: "ca", label: "Cash Advance" },
  //   { value: "rm", label: "Reimburse" }
  // ];
  useEffect(() => {
    dispatch(getExpenseGeneral(false));
  }, [dispatch, history]);

  const handleOpenModal = async (expense: any) => {
    setForm({
      purpose: expense.purpose,
      description: expense.type.description,
      amount: expense.amount,
      category: expense.type.djurneeItems
    });
    setExpenseData(expense);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  // const maskingMoney = (param: string) => {
  //   const res = Number(param)
  //     .toLocaleString("en-US")
  //     .replace(",", ".")
  //     .replace(",", ".");
  //   return res;
  // };

  const submitEdit = async (expenseId: string) => {
    await dispatch(putEditExpense(expenseId, form));
    await window.location.reload();
    await setModalOpen(false);
  };

  const statusDesc = (status: number) => {
    const result = {
      label: "",
      color: ""
    };
    switch (status) {
      case 1:
        result.label = "draft";
        break;
      case 2:
        result.label = "issued";
        result.color = "#ed5a28";
        break;
      case 3:
        result.label = "approved";
        result.color = "#50b1b1";
        break;
      case 4:
        result.label = "revision";
        result.color = "#F5AA3F";
        break;
      case 5:
        result.label = "unpaid";
        result.color = "#F5AA3F";
        break;
      case 6:
        result.label = "paid";
        result.color = "#50b1b1";
        break;
      case 7:
        result.label = "rejected";
        result.color = "#F32013";
        break;
      case 8:
        result.label = "expired";
        result.color = "#F32013";
        break;
      default:
        return result;
    }
    return result;
  };

  return (
    <WrapContent isLoading={expenseState.isLoadingGeneral}>
      <WrapList
        isEmpty={expenseState.listGeneral.length === 0}
        emptyComponent={() => (
          <Touchable onPress={() => history.push("/new-expense")}>
            <Image
              resizeMode="contain"
              style={{ width: 62.6, height: 75.6 }}
              source={IMAGES.newExpense}
            />
          </Touchable>
        )}
        onEndReached={() => dispatch(getExpenseGeneral(true))}
      >
        <FlatList
          style={{ marginHorizontal: 20 }}
          data={expenseState.listGeneral}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={
            <LoadingLoadMore active={expenseState.loadMoreGeneral} />
          }
          renderItem={({ item, index }) => (
            <View>
              <Touchable onPress={() => handleOpenModal(item)}>
                <Card
                  cardStyle={{
                    marginBottom: 15,
                    marginTop: index === 0 ? 20 : 0
                  }}
                  statusColor={statusDesc(item.status).color}
                  statusText="Status"
                  statusDesc={statusDesc(item.status).label}
                >
                  <View style={styles.card}>
                    <View style={[styles.row, { marginTop: 18 }]}>
                      <View style={{ width: "30%" }}>
                        <Text style={styles.text}>No</Text>
                      </View>
                      <View style={{ width: "60%" }}>
                        <Text style={[styles.desc, { fontWeight: "bold" }]}>
                          {item.number}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={{ width: "30%" }}>
                        <Text style={styles.text}>Subject</Text>
                      </View>
                      <View style={{ width: "60%" }}>
                        <Text style={[styles.desc, { fontWeight: "bold" }]}>
                          {item.purpose}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={{ width: "30%" }}>
                        <Text style={styles.text}>Type</Text>
                      </View>
                      <View style={styles.columnDesc}>
                        <Text style={[styles.desc, { fontWeight: "bold" }]}>
                          {item.type.djurneeItems.map(
                            (itemType: any, indexType: number) =>
                              indexType === item.type.djurneeItems.length - 1
                                ? itemType.category.title
                                : `${itemType.category.title}, `
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={{ width: "30%" }}>
                        <Text style={styles.text}>Date</Text>
                      </View>
                      <View style={styles.columnDesc}>
                        <Text style={[styles.desc, { fontWeight: "bold" }]}>
                          {convertDate(item.published_at)}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.row, { marginBottom: 18 }]}>
                      <View style={{ width: "30%" }}>
                        <Text style={styles.text}>Method</Text>
                      </View>
                      <View style={styles.columnDesc}>
                        <Text style={[styles.desc, { fontWeight: "bold" }]}>
                          {item.type.type.title}
                        </Text>
                      </View>
                    </View>
                  </View>
                </Card>
              </Touchable>
            </View>
          )}
        />
      </WrapList>

      {expenseState.listGeneral.length > 0 && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          <Touchable onPress={() => history.push("/new-expense")}>
            <Image
              resizeMode="contain"
              style={{ width: 62.6, height: 75.6 }}
              source={IMAGES.newExpense}
            />
          </Touchable>
        </div>
      )}
      <Modal isVisible={modalIsOpen}>
        <View style={styles.container}>
          <Card
            cardStyle={{
              marginBottom: 23,
              justifyContent: "center"
            }}
          >
            <View>
              <View
                style={{
                  marginLeft: 22,
                  marginRight: 37
                }}
              >
                <View style={{ marginBottom: 34 }}>
                  <View
                    style={[
                      styles.desciption,
                      { marginBottom: 18.5, marginTop: 127 }
                    ]}
                  >
                    <Text style={styles.titleDescription}>Subject</Text>
                    <TextInput
                      value={form ? form.purpose : ""}
                      editable={false}
                      style={{
                        paddingHorizontal: 13,
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        borderBottomWidth: 1,
                        borderColor: "#707070",
                        width: "75%"
                      }}
                      onChangeText={text => setForm({ ...form, purpose: text })}
                    />
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Type</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {form.category.map((itemType: any, indexType: number) =>
                          indexType === form.category.length - 1
                            ? itemType.category.title
                            : `${itemType.category.title}, `
                        )}
                      </Text>
                    </Cards>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Date</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseData
                          ? convertDate(expenseData.published_at)
                          : "-"}
                      </Text>
                    </Cards>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Method</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseData ? expenseData.type.type.title : "-"}
                      </Text>
                    </Cards>
                  </View>
                </View>
              </View>
              <View style={styles.amountContainer}>
                <Text style={styles.amountTitle}>
                  {expenseData && expenseData.currency
                    ? `Amount (${expenseData.currency.code})`
                    : "Amount -"}
                </Text>
                <TextInput
                  editable={false}
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "#666666",
                    fontSize: 24,
                    textAlign: "center",
                    borderBottomColor: "#666666",
                    borderBottomWidth: 1,
                    width: widthPercent(100) - 210
                  }}
                  keyboardType="numeric"
                  maxLength={11}
                  value={form ? form.amount : ""}
                  onChangeText={text => {
                    setForm({
                      ...form,
                      amount: text
                    });
                  }}
                />
              </View>
              <View
                style={{
                  alignItems: "center",
                  marginTop: 67,
                  marginBottom: 74
                }}
              >
                {(expenseData && expenseData.status === 1) ||
                (expenseData && expenseData.status === 4) ? (
                  <Button
                    title="SAVE"
                    onPress={() => submitEdit(expenseData.id)}
                  />
                ) : null}
              </View>
            </View>
          </Card>
          <View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row"
              }}
            >
              <Touchable onPress={() => handleCloseModal()}>
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "#666666"
                  }}
                >
                  CLOSE
                </Text>
              </Touchable>
            </View>
          </View>
        </View>
      </Modal>
    </WrapContent>
  );
};

export default General;
