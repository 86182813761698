import React from "react";
import { Image, Text, View } from "react-native";
import { useLocation } from "react-router-dom";

import styles from "./styles";
import { Button, Card, Touchable } from "../../../components";
import { convertDate, maskedMoney } from "../../../utils";
import Cards from "../../NewTrip/components/Card";
import { IMAGES } from "../../../configs";

const dataButton = [
  {
    image: IMAGES.cross
  },
  {
    image: IMAGES.pencil
  },
  {
    image: IMAGES.check
  }
];

const ExpenseDetail = () => {
  const location = useLocation();
  const { detailExpense } = location!.state! as any;

  return (
    <View style={styles.container}>
      <Card cardStyle={[styles.cardDescription, { marginBottom: 15 }]}>
        <View style={{ marginTop: 15 }}>
          <View style={{ marginBottom: 10 }}>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Purpose</Text>
              <View
                style={[
                  styles.detailDescription,
                  {
                    borderBottomColor: "#000",
                    borderBottomWidth: 0.1,
                    paddingRight: 10
                  }
                ]}
              >
                <Text
                  style={[styles.detailDescriptionText, { paddingLeft: 10 }]}
                >
                  {detailExpense.purpose}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ marginBottom: 34 }}>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Date</Text>
              <Cards cardStyle={styles.detailDescriptionCard}>
                <Text style={styles.detailDescriptionText}>
                  {convertDate(detailExpense.publshed_at)}
                </Text>
              </Cards>
            </View>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Method</Text>
              <Cards cardStyle={styles.detailDescriptionCard}>
                <Text style={styles.detailDescriptionText}>
                  {detailExpense.type.type.title}
                </Text>
              </Cards>
            </View>
            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Purpose</Text>
              <Cards cardStyle={styles.detailDescriptionCard}>
                <Text style={styles.detailDescriptionText}>-</Text>
              </Cards>
            </View>

            <View style={styles.desciption}>
              <Text style={styles.titleDescription}>Notes</Text>
              <Cards
                cardStyle={[
                  styles.detailDescriptionCard,
                  { height: 60, borderRadius: 10 }
                ]}
              >
                <Text style={styles.detailDescriptionText}>
                  Banyak Banget Krunya
                </Text>
              </Cards>
            </View>
          </View>
          <View style={styles.amountContainer}>
            <Text style={styles.amountTitle}>Amount (IDR)</Text>
            <Text style={styles.amount}>
              {` ${maskedMoney(detailExpense.amount || 0)}`}
            </Text>
          </View>
          <View
            style={{ alignItems: "center", marginTop: 67, marginBottom: 74 }}
          >
            <Button title="SAVE" />
          </View>
        </View>
      </Card>
      <View style={styles.cardButtonContainer}>
        {dataButton.map((item, index) => {
          return (
            <Card cardStyle={styles.cardButton} key={index}>
              <Touchable>
                <Image source={item.image} style={{ height: 24, width: 24 }} />
              </Touchable>
            </Card>
          );
        })}
      </View>
    </View>
  );
};
export default ExpenseDetail;
