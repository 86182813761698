import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.main,
    paddingHorizontal: 10,
    paddingBottom: 100
  },
  wrapLabeInput: {
    paddingHorizontal: 12
  },
  labelInput: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey,
    marginBottom: 2
  },
  row: {
    marginBottom: 12
  },
  flexDirection: {
    flexDirection: "row"
  },
  cardCalendar: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingVertical: 8
  },
  labelDate: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: COLORS.green
  },
  labelEndDate: {
    color: COLORS.orange
  },
  labelTime: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  iconStar: {
    height: 16,
    width: 16
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    borderRadius: 20,
    paddingHorizontal: 12,
    height: 28,
    justifyContent: "center",
    backgroundColor: COLORS.main
  },
  textInput: {
    fontFamily: "Poppins",
    color: COLORS.grey,
    fontWeight: "bold",
    outlineColor: "rgba(255,255,255,0)"
  },
  labelPolicy: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.grey
  },
  wrapButton: {
    width: 180,
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    borderRadius: 17,
    overflow: "hidden"
  },
  button: {
    width: "100%",
    height: 28,
    justifyContent: "center",
    alignItems: "center"
  },
  textButton: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.white
  },
  containerAutoComplete: {
    backgroundColor: "#fffff7",
    width: "100%",
    borderRadius: 5,
    position: "absolute",
    zIndex: 1,
    top: 30,
    left: 0
  },
  cardAutoComplete: {
    minHeight: 40,
    maxHeight: 120,
    overflow: "scroll",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  buttonAutoComplete: {
    width: "100%",
    height: 40,
    justifyContent: "center"
  },
  containerModal: {
    width: widthPercent(90),
    paddingVertical: 36,
    height: "100%"
  },
  labelList: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyLight,
    width: "25%"
  },
  rowList: {
    marginVertical: 2
  },
  valueList: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey,
    fontWeight: "bold"
  },
  wrapButtonTrash: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    position: "absolute",
    right: 8,
    top: 8
  },
  buttonTrash: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
