import React, { useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import styles from "./styles";
import { GetEmployeeV2Params, Reducers } from "../../../redux/types";
import {
  getDepartmentV1,
  getEmployeeV2,
  setAddParticipants,
  setDeleteParticipants
} from "../../../redux/actions";
import { COLORS, IMAGES } from "../../../configs";
import { Button, Card, Modal, Select, Space } from "../..";
import { heightPercent, widthPercent } from "../../../utils";

interface Props {
  onCancel?: () => void;
  onPress?: (e?: any[]) => void;
  isUpdate?: boolean;
  isRequest?: boolean;
}

const AddParticipants = ({ onCancel, onPress, isUpdate, isRequest }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee]: any = useState();
  const [searchText, setSearchText] = useState("");
  const [department, setDepartment] = useState<string>("1");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { employeeState, newTripState, taskState, detailTask } = useSelector(
    (state: Reducers) => ({
      newTripState: state.newTrip,
      employeeState: state.employee,
      taskState: state.task,
      detailTask: state.task
    }),
    shallowEqual
  );

  const _changeEmployee = () => {
    const params: GetEmployeeV2Params = {
      hidden: false,
      outsource: false,
      disable: false,
      page: 1,
      show_self: false,
      bypass_permission: true
    };
    if (department !== "1") params.department_id = department;
    if (searchText.length) params.q = searchText;
    dispatch(getEmployeeV2(params));
  };

  useEffect(() => {
    _changeEmployee();
  }, [department]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      _changeEmployee();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    dispatch(getDepartmentV1());

    if (isUpdate) {
      dispatch(
        getEmployeeV2({
          hidden: false,
          outsource: false,
          disable: false,
          page: 1,
          show_self: true,
          parent: true,
          bypass_permission: true
        })
      );
    }
  }, []);

  const _selected = useCallback(
    (id: string) => {
      const result = taskState.participanList.findIndex(e => e.id === id);
      return result >= 0;
    },
    [taskState.participanList]
  );

  const _getEmployee = useCallback(() => {
    if (isUpdate) {
      return detailTask.detailTaskData
        ? employeeState.listEmployeeV2.filter(
            e =>
              detailTask.detailTaskData.assignee.findIndex(
                (res: any) => res.employee.id === e.id
              ) < 0
          )
        : [];
    }
    return employeeState.listEmployeeV2;
  }, [detailTask.detailTaskData, employeeState.listEmployeeV2, isUpdate]);

  const _handleAddPicModal = (data: any) => {
    setSelectedEmployee(data);
    if (taskState.participanList.length === 0 && !isRequest) {
      setOpenModal(true);
    } else if (taskState.participanList.length === 0 && isRequest) {
      dispatch(setAddParticipants(data));
    } else if (!isRequest) {
      dispatch(setAddParticipants(data));
    }
  };

  const _handleSubmitModal = () => {
    dispatch(setAddParticipants(selectedEmployee));
    setOpenModal(false);
  };

  const _renderList = () => {
    if (_getEmployee().length > 0 && !employeeState.isLoadingListEmployeeV2) {
      return (
        <View style={styles.wrapPersonList}>
          {_getEmployee().map((item, index) => (
            <View key={index} style={{ marginBottom: 8 }}>
              <Card
                onPress={() =>
                  _selected(item.id)
                    ? dispatch(setDeleteParticipants(item.id))
                    : _handleAddPicModal(item)
                }
                cardStyle={[
                  { borderRadius: 40 },
                  _selected(item.id)
                    ? { backgroundColor: "#50B1B1" }
                    : { backgroundColor: "#fff" }
                ]}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={(item.avatar && item.avatar.url) || IMAGES.avatar}
                    style={styles.imageStyle}
                  />
                  <View style={{ marginLeft: 4, width: "65%" }}>
                    <Text
                      style={[
                        styles.textStyle,
                        { fontWeight: "bold" },
                        _selected(item.id)
                          ? { color: "#fff" }
                          : { color: "#707070" }
                      ]}
                      numberOfLines={1}
                    >
                      {item.name}
                    </Text>
                    <Text
                      style={[
                        styles.textStyle,
                        _selected(item.id)
                          ? { color: "#fff" }
                          : { color: "#707070" }
                      ]}
                      numberOfLines={1}
                    >
                      {item.position_name}
                    </Text>
                  </View>
                </View>
              </Card>
            </View>
          ))}
        </View>
      );
    }
    if (employeeState.isLoadingListEmployeeV2) {
      return (
        <View style={styles.isLoadingList}>
          <ActivityIndicator size={40} color="#344370" />
        </View>
      );
    }
    return (
      <View style={styles.isLoadingList}>
        <Text style={styles.headerTitle}>Empty Data</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.headerTitle}>Add Participant</Text>
      <View style={{ flex: 1 }}>
        <View style={[styles.departmentSearchContainer]}>
          <View style={styles.wrapCardInput}>
            <Text style={{ color: COLORS.grey }}>Department: </Text>
            <Space width={4} />
            {!newTripState.isLoadingDepartmentV1 &&
            newTripState.listDepartmentV1.length > 0 ? (
              <Select
                listValue={newTripState.listDepartmentV1}
                style={{ flex: 1, marginTop: -1 }}
                value={department}
                onChange={e => {
                  setDepartment(e.target.value);
                }}
                type={department}
              />
            ) : (
              <ActivityIndicator color="#344370" />
            )}
          </View>
          <View style={{ width: "90%" }}>
            <TextInput
              style={{
                color: COLORS.greyLight,
                borderBottomWidth: 1,
                borderBottomColor: COLORS.greyMedium,
                height: 30,
                width: "100%"
              }}
              placeholder={t("task.assign.searchSomeone")}
              onChangeText={text => setSearchText(text)}
              value={searchText}
            />
          </View>
        </View>

        {_renderList()}

        <View style={styles.footerBtn}>
          <Button
            title="SAVE"
            onPress={() => onPress && onPress(taskState.participanList)}
          />
          <TouchableOpacity style={{ marginTop: 17 }} onPress={onCancel}>
            <Text style={styles.cancelStyle}>CANCEL</Text>
          </TouchableOpacity>
        </View>

        <Modal isVisible={openModal} backdropColor="rgba(255,255,255, 0.9)">
          <View
            style={{
              width: widthPercent(100),
              height: heightPercent(100)
            }}
          >
            <View style={styles.containerModal}>
              <Image
                source={IMAGES.rejectModal}
                style={{ height: 80, width: 80, marginBottom: 40 }}
              />
              <Text style={styles.textWarning}>
                {"Set "}
                <Text style={{ fontWeight: "bold" }}>
                  {`${selectedEmployee && selectedEmployee.name} `}
                </Text>
                as Person in Charge (PIC)?
              </Text>
            </View>
            <View style={styles.btnModal}>
              <View style={{ alignItems: "center" }}>
                <Button title="YES" onPress={() => _handleSubmitModal()} />
                <TouchableOpacity
                  style={{ marginTop: 17 }}
                  onPress={() => setOpenModal(false)}
                >
                  <Text style={styles.cancelStyle}>NO</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
};

AddParticipants.defaultProps = {
  isUpdate: false
};

export default AddParticipants;
