import { Dimensions, StyleSheet } from "react-native";
import { COLORS } from "../../../configs";

const { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    height,
    width,
    alignItems: "center",
    paddingHorizontal: 40
  },
  wrapHeaderTitle: {
    marginTop: 120,
    marginBottom: 60
  },
  headerTitle: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18,
    color: COLORS.orange,
    textTransform: "uppercase"
  },
  headerImage: {
    height: 58,
    width: 58,
    alignSelf: "center"
  },
  descStyle: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "Poppins",
    color: COLORS.grey,
    opacity: 0.8
  },
  wrapButton: {
    flex: 1,
    justifyContent: "flex-end",
    paddingBottom: 44
  },
  cancelButton: {
    textAlign: "center",
    marginTop: 16,
    width: "fit-content",
    alignSelf: "center"
  },
  cancelText: {
    fontSize: 16,
    fontFamily: "Poppins",
    color: COLORS.greyMedium,
    fontWeight: "bold"
  },
  actionButton: {
    height: 54,
    width: 180,
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    borderRadius: 50,
    justifyContent: "center"
  },
  actionText: {
    textAlign: "center",
    color: COLORS.white,
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Poppins"
  }
});

export default styles;
