import React, { ReactChild } from "react";
import { View } from "react-native";

import styles from "./style";

interface Props {
  children: ReactChild;
  cardStyle?: object;
}

const Card = ({ children, cardStyle }: Props) => {
  return <View style={[styles.card, cardStyle]}>{children}</View>;
};

export default Card;
