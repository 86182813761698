import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingHorizontal: 20
  },
  navigation: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  btnNav: {
    paddingHorizontal: 26,
    height: "100%",
    borderRadius: 50
  },
  active: {
    borderRadius: 50,
    paddingHorizontal: 26,
    height: "100%",
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    paddingVertical: 2,
    marginVertical: -2
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  textHeader: {
    fontSize: 20,
    marginTop: 5
  },
  bookingpDetailContent: {
    paddingHorizontal: 20
  },
  cardDescription: {
    paddingLeft: 21,
    paddingTop: 23,
    paddingBottom: 12,
    paddingRight: 20
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%"
  },
  modalStyle: {
    justifyContent: "flex-start"
  }
});

export default styles;
