import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  cardDescription: {
    marginTop: 9,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 12,
    paddingRight: 20,
    marginBottom: 15,
    marginHorizontal: 4
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  descriptionCity: {
    marginBottom: 0,
    marginTop: 0
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "70%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  },
  titleHotel: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    marginBottom: 16
  },
  descDate: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 30
  },
  star: {
    width: 9.5,
    height: 9.5,
    marginRight: 5
  },
  noDataStyle: {
    fontSize: 14,
    fontFamily: "Poppins",
    alignSelf: "center",
    marginTop: 10
  },
  textNoData: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11
  }
});

export default styles;
