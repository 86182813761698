import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomRoute from "./CustomRoute";
import { Reducers } from "../redux/types";
import SaveRoute from "./SaveRoute";

// screens
import Intro from "../pages/Intro";
import Register from "../pages/Register";
import Login from "../pages/Login";

import Home from "../pages/Home";
import Trip from "../pages/Trip";
import Settings from "../pages/Settings";
import EditProfile from "../pages/EditProfile";
import NewTrip from "../pages/NewTrip";
import Flight from "../pages/Trip/Flight";
import ExpenseDetail from "../pages/ExpenseDetail";
// import EditTrip from "../pages/EditTrip";
import Notification from "../pages/Notification";
import Feedback from "../pages/Feedback";

// booking screens
import Booking from "../pages/Booking";
import NewBookingFlight from "../pages/NewBookingFlight";
import BookingFlightList from "../pages/NewBookingFlightList";
import FormHotel from "../pages/FormHotel";
import NewBookingHotelList from "../pages/NewBookingHotelList";
import NewBookingHotel from "../pages/NewBookingHotel";

// trip detail screens
import TripDetail from "../pages/TripDetail";
import TripDetailExpense from "../pages/TripDetailExpense";
import HistoryDetail from "../pages/HistoryDetail";
import AssignTripDetail from "../pages/AssignTripDetail";
import NewAssignment from "../pages/Trip/Assignment/NewAssignment";
import Notes from "../pages/Notes";
import NewReport from "../pages/TripDetail/Report/NewReport";
import EditReport from "../pages/TripDetail/Report/EditReport";
import EditTrip from "../pages/Trip/EditTrip";
import Management from "../pages/TripDetail/Management";
import NewTripBill from "../pages/TripDetail/Expense/NewTripBill";
import BilDetail from "../pages/TripDetail/BillDetail";

// expense screens
import Expense from "../pages/Expense";
import NewExpense from "../pages/NewExpense";
import DetailExpense from "../pages/Expense/detailExpense";
import Coba from "../pages/Coba";
import FilterCategory from "../components/ModalComponents/FilterCategory";
import FilterPrice from "../components/ModalComponents/FilterPrice";
import NewExpenseTrip from "../pages/NewExpenseTrip";
import NewCashExpense from "../pages/NewCashExpense";
import NewReimburseTrip from "../pages/NewReimburseTrip";
import NewBill from "../pages/NewBill";
import NewExpenseActivity from "../pages/NewTrip/NewExpense";
import NewCashAdvance from "../pages/NewTrip/NewCashAdvance";
import BookingActivity from "../pages/NewTrip/Booking";
import DetailBookingActivity from "../pages/NewTrip/Booking/DetailBooking";
import NewInvoiceBookingActivity from "../pages/NewTrip/Booking/NewInvoice";

// detail activity
import DetailActivity from "../pages/DetailActivity";

// report screens
import Report from "../pages/Report";

// task screens
import Task from "../pages/Task";
import NewAssignTask from "../pages/Task/NewAssignTask";
import NewRequestTask from "../pages/Task/NewRequestTask";
import TaskDetail from "../pages/TaskDetail";

// modal screens
import Approve from "../components/ModalComponents/Approve";
import CustomNotification from "../components/ModalComponents/CustomNotification";
import Reject from "../components/ModalComponents/Reject";
import Revise from "../components/ModalComponents/Revise";
import ResolveRevision from "../components/ModalComponents/ResolveRevision";

// cash screens
import NewCash from "../pages/Cash/NewCash";
import Cash from "../pages/Cash";
import Details from "../pages/Cash/Details";
import AddItemCash from "../pages/Cash/AddItemCash";
import EditCash from "../pages/Cash/EditCash";

// invoice screens
import Payment from "../pages/Invoice/Payment";
import NewBillGeneral from "../pages/NewBillGeneral";
import EditBill from "../pages/EditBill";

// notification screens
import ShortcutNotification from "../pages/ShortcutNotification";

// Loan screens
import Loan from "../pages/Loan";
import LoanDetail from "../pages/Loan/LoanDetail";

// Kasbon screens

import Kasbon from "../pages/Kasbon/Kasbon";
import KasbonAdd from "../pages/KasbonAdd/KasbonAdd";
import KasbonDetail from "../pages/KasbonDetail/KasbonDetail";

const App = () => {
  const auth = useSelector((state: Reducers) => state.auth);
  const { myProfile } = useSelector((state: Reducers) => state.home);
  const isLogin = auth.token !== "";
  const isFirstTime = auth.firstTime;

  const handleFirstTime = () => {
    let firstTime = "";
    if (isFirstTime) {
      firstTime = "intro";
    } else {
      firstTime = "login";
    }
    return firstTime;
  };

  return (
    <Router>
      <ToastContainer limit={1} />
      <SaveRoute>
        <Switch>
          <CustomRoute path="/booking/hotel/:id">
            <NewBookingHotel />
          </CustomRoute>
          {/* modal */}
          <Route path="/testmodal/notification">
            <CustomNotification />
          </Route>
          <CustomRoute path="/moderation/revise/:id">
            <Revise />
          </CustomRoute>
          <CustomRoute path="/shortcut">
            <ShortcutNotification />
          </CustomRoute>
          {/* modal */}
          <CustomRoute path="/moderation/reject/:id">
            <Reject />
          </CustomRoute>
          <CustomRoute path="/resolveRevision">
            <ResolveRevision />
          </CustomRoute>
          {/* modal */}
          <CustomRoute path="/moderation/approve/:id">
            <Approve />
          </CustomRoute>
          <CustomRoute path="/report">
            <Report />
          </CustomRoute>
          <CustomRoute path="/trip/notes">
            <Notes />
          </CustomRoute>
          <CustomRoute path="/trip/assign/detail">
            <AssignTripDetail />
          </CustomRoute>
          <CustomRoute path="/components/filter-category">
            <FilterCategory />
          </CustomRoute>
          <CustomRoute path="/components/filter-price">
            <FilterPrice />
          </CustomRoute>
          <CustomRoute path="/notification">
            <Notification />
          </CustomRoute>
          <CustomRoute path="/booking/newflight/flightlist">
            <BookingFlightList />
          </CustomRoute>
          <CustomRoute path="/generalexpense/new">
            <NewCash />
          </CustomRoute>
          <CustomRoute path="/trip/assignment/newassignment">
            <NewAssignment />
          </CustomRoute>
          <CustomRoute path="/edittrip/:id">
            <NewTrip />
          </CustomRoute>
          <CustomRoute path="/invoice/:id/pay">
            <Payment />
          </CustomRoute>
          <CustomRoute path="/booking/newhotel">
            <FormHotel />
          </CustomRoute>
          <CustomRoute path="/expensedetail/:id">
            <ExpenseDetail />
          </CustomRoute>
          <CustomRoute path="/trip-detail/:id">
            <TripDetail />
          </CustomRoute>
          <CustomRoute path="/trip-detail-expense/:id">
            <TripDetailExpense />
          </CustomRoute>
          <CustomRoute path="/detailExpense">
            <DetailExpense />
          </CustomRoute>
          <CustomRoute path="/trip">
            <Trip />
          </CustomRoute>
          <CustomRoute path="/historydetail">
            <HistoryDetail />
          </CustomRoute>
          <CustomRoute path="/new-booking-flight/:id">
            <NewBookingFlight />
          </CustomRoute>
          <CustomRoute path="/expense">
            <Expense />
          </CustomRoute>
          <CustomRoute path="/booking/:id">
            <Booking />
          </CustomRoute>
          <Route path="/" exact>
            <Redirect to={{ pathname: isLogin ? "home" : handleFirstTime() }} />
          </Route>
          <CustomRoute path="/newtrip" exact>
            <NewTrip />
          </CustomRoute>
          <CustomRoute path="/newtrip/newexpense">
            <NewExpenseActivity />
          </CustomRoute>
          <CustomRoute path="/newtrip/booking">
            <BookingActivity />
          </CustomRoute>
          <CustomRoute path="/newtrip/detailbooking/hotel/:id/:tripId">
            <DetailBookingActivity type="bookingHotels" />
          </CustomRoute>
          <CustomRoute path="/newtrip/detailbooking/flight/:id/:tripId">
            <DetailBookingActivity type="bookingFlights" />
          </CustomRoute>
          <CustomRoute path="/newtrip/newinvoice/hotel/:id">
            <NewInvoiceBookingActivity type="bookingHotels" />
          </CustomRoute>
          <CustomRoute path="/newtrip/newinvoice/flight/:id">
            <NewInvoiceBookingActivity type="bookingFlights" />
          </CustomRoute>
          <CustomRoute path="/newtrip/newcashadvance" exact>
            <NewCashAdvance />
          </CustomRoute>
          <CustomRoute path="/newtrip/newcashadvance/:id">
            <NewCashAdvance />
          </CustomRoute>
          <CustomRoute path="/assigntrip">
            <NewTrip />
          </CustomRoute>
          <CustomRoute path="/task/detail/:id/:type">
            <TaskDetail />
          </CustomRoute>
          <CustomRoute path="/flight">
            <Flight />
          </CustomRoute>
          <CustomRoute path="/editprofile">
            <EditProfile />
          </CustomRoute>
          <CustomRoute path="/intro" privateMode={false}>
            <Intro />
          </CustomRoute>
          <CustomRoute path="/register" privateMode={false}>
            <Register />
          </CustomRoute>
          <CustomRoute path="/login" privateMode={false} withContainer={false}>
            <Login />
          </CustomRoute>
          <CustomRoute path="/home">
            {myProfile?.employee?.roles.find(
              (item: any) => item.name === "personal-loan-user"
            ) ? (
              <Kasbon />
            ) : (
              <Home />
            )}
          </CustomRoute>
          <CustomRoute path="/settings">
            <Settings />
          </CustomRoute>
          <CustomRoute path="/new-booking-hotel-list">
            <NewBookingHotelList />
          </CustomRoute>
          <CustomRoute path="/new-expense">
            <NewExpense />
          </CustomRoute>
          <CustomRoute path="/newexpense/trip/:id">
            <NewExpenseTrip />
          </CustomRoute>
          <Route path="/coba">
            <Coba />
          </Route>
          <CustomRoute path="/tripdetail/newreport/:id">
            <NewReport />
          </CustomRoute>
          <CustomRoute path="/tripdetail/editreport/:id">
            <EditReport />
          </CustomRoute>
          <CustomRoute path="/new-cash-expense">
            <NewCashExpense />
          </CustomRoute>
          <CustomRoute path="/new-expense-reimburse-trip/">
            <NewReimburseTrip />
          </CustomRoute>
          <CustomRoute path="/new-bill/:tripId">
            <NewBill />
          </CustomRoute>
          <CustomRoute path="/newBill/general/:invoiceId/:projectId">
            <NewBillGeneral />
          </CustomRoute>
          <CustomRoute path="/editbill/:invoiceId/:billId">
            <EditBill />
          </CustomRoute>
          <CustomRoute path="/task">
            <Task />
          </CustomRoute>
          <CustomRoute path="/new-assign-task">
            <NewAssignTask />
          </CustomRoute>
          <CustomRoute path="/edit-task/:taskUuid/:typeList/:projectId">
            <NewAssignTask />
          </CustomRoute>
          <CustomRoute path="/new-request-task">
            <NewRequestTask />
          </CustomRoute>
          <CustomRoute path="/cash">
            <Cash />
          </CustomRoute>
          <CustomRoute path="/cash-details/:id/:type">
            <Details />
          </CustomRoute>
          <CustomRoute path="/add-cash-item/:idInvoice">
            <AddItemCash />
          </CustomRoute>
          <CustomRoute path="/feedback">
            <Feedback />
          </CustomRoute>
          <CustomRoute path="/detail-activity/:id">
            <DetailActivity />
          </CustomRoute>
          <CustomRoute path="/edit-activity/:id">
            <EditTrip />
          </CustomRoute>
          <CustomRoute path="/managemet-trip/:id/">
            <Management />
          </CustomRoute>
          <CustomRoute path="/new-trip-bill">
            <NewTripBill />
          </CustomRoute>
          <CustomRoute path="/loan">
            <Loan />
          </CustomRoute>
          <CustomRoute path="/loan-detail/:id/">
            <LoanDetail />
          </CustomRoute>
          <CustomRoute path="/edit-cash/:id/">
            <EditCash />
          </CustomRoute>
          <CustomRoute path="/kasbon-add">
            <KasbonAdd />
          </CustomRoute>
          <CustomRoute path="/kasbon-detail/:id">
            <KasbonDetail />
          </CustomRoute>
          <CustomRoute path="/trip-bill-detail/:id">
            <BilDetail />
          </CustomRoute>
        </Switch>
      </SaveRoute>
    </Router>
  );
};

export default App;
