import React from "react";
import { Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";

import { IMAGES } from "../../../configs";
import styles from "./styles";
import { Touchable } from "../../index";

interface Props {
  cancel?: () => void;
}

const ResolveRevision = ({ cancel }: Props) => {
  const history = useHistory();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>RESOLVE REVISION</Text>
      <Image
        resizeMode="contain"
        source={IMAGES.rejectModal}
        style={styles.image}
      />
      <Text style={styles.content}>
        This action will
        <Text style={{ fontWeight: "bold" }}> send notification </Text>
        to your approver to be checked again and will turn your trip back to
        <Text style={{ fontWeight: "bold" }}> SUBMITTED</Text>
      </Text>
      <View style={styles.buttonAttachmen}>
        <Touchable>
          <View
            style={{
              height: 54,
              alignItems: "center",
              justifyContent: "center",
              width: 140
            }}
          >
            <Text style={styles.buttonText}>RESOLVE</Text>
          </View>
        </Touchable>
      </View>
      <View style={{ marginTop: 12 }}>
        <Touchable onPress={cancel || (() => history.goBack())}>
          <Text style={styles.cancel}>CANCEL</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default ResolveRevision;
