import { StyleSheet } from "react-native";
import { widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingHorizontal: 20
  },
  navigation: {
    flexDirection: "row",
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    alignSelf: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  btnNav: {
    paddingHorizontal: 56,
    height: "100%",
    borderRadius: 50
  },
  active: {
    paddingHorizontal: 56,
    height: "100%",
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)"
  },
  warning: {
    marginTop: 20,
    width: widthPercent(100) - 80
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  textHeader: {
    fontSize: 20,
    marginTop: 5
  },
  bookingpDetailContent: {
    paddingHorizontal: 20
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 14,
    paddingRight: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%"
  },
  modalStyle: {
    justifyContent: "flex-start"
  }
});

export default styles;
