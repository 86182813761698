import React, { useEffect } from "react";
import { Image, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import styles from "./styles";
import { Button, Card, Input, Touchable, WrapContent } from "../../components";
import { convertDate, maskedMoney } from "../../utils";
import Cards from "../NewTrip/components/Card";
import { IMAGES } from "../../configs";

import { getExpenseDetail, putModerationTrip } from "../../redux/actions";
import { Reducers } from "../../redux/types";

const dataButton = [
  {
    image: IMAGES.cross
  },
  {
    image: IMAGES.pencil
  },
  {
    image: IMAGES.check
  }
];

const ExpenseDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { expenseDetail, isLoadingExpenseDetail } = useSelector(
    (state: Reducers) => state.expense
  );
  const { id } = useParams() as any;
  useEffect(() => {
    dispatch(getExpenseDetail(id!));
    // eslint-disable-next-line
  }, [dispatch]);

  const moderateExpense = (moderationId: any, answer: any) => {
    dispatch(putModerationTrip(moderationId, answer, ""));
    history.push("/trip");
  };

  return (
    <WrapContent isLoading={isLoadingExpenseDetail}>
      {expenseDetail !== null && (
        <View style={styles.container}>
          <Card
            cardStyle={{
              marginTop: 49,
              marginBottom: 23,
              height: 623,
              justifyContent: "center"
            }}
          >
            <View>
              <View
                style={{
                  marginLeft: 22,
                  marginRight: 37
                }}
              >
                <View style={{ marginBottom: 34 }}>
                  <View style={[styles.desciption, { marginBottom: 18.5 }]}>
                    <Text style={styles.titleDescription}>Name</Text>
                    <TextInput
                      value={expenseDetail.moderation.title}
                      style={{
                        paddingHorizontal: 13,
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        borderBottomWidth: 1,
                        borderColor: "#707070",
                        width: "75%"
                      }}
                    />
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Purpose</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseDetail.purpose}
                      </Text>
                    </Cards>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Date</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {convertDate(expenseDetail.type.created_at)}
                      </Text>
                    </Cards>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Method</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseDetail.type.type.title}
                      </Text>
                    </Cards>
                  </View>

                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Notes</Text>
                    <Cards
                      cardStyle={[
                        styles.detailDescriptionCard,
                        { height: 60, borderRadius: 10 }
                      ]}
                    >
                      <Text style={styles.detailDescriptionText}>-</Text>
                    </Cards>
                  </View>
                </View>
              </View>
              <View style={styles.amountContainer}>
                <Text style={styles.amountTitle}>
                  {`Amount (${expenseDetail.currency.code})`}
                </Text>
                <Input
                  inputStyle={styles.amount}
                  style={{ width: 150 }}
                  value={maskedMoney(expenseDetail.amount || 0)}
                />
              </View>
              <View
                style={{
                  alignItems: "center",
                  marginTop: 67,
                  marginBottom: 74
                }}
              >
                <Button title="SAVE" />
              </View>
            </View>
          </Card>
          <View style={styles.cardButtonContainer}>
            {dataButton.map((item, index) => {
              return (
                <Card cardStyle={styles.cardButton} key={index}>
                  <Touchable
                    onPress={() =>
                      moderateExpense(expenseDetail.moderation.id, 3 - index)
                    }
                  >
                    <Image
                      source={item.image}
                      style={{ height: 24, width: 24 }}
                    />
                  </Touchable>
                </Card>
              );
            })}
          </View>
        </View>
      )}
    </WrapContent>
  );
};
export default ExpenseDetail;
