import React, { useState } from "react";
import { Text, View } from "react-native";

import styles from "./styles";
import "./style.css";
import { Modal } from "..";
import NoteRevise from "../../pages/TripDetail/ModalNoteRevise";
import Touchable from "../Touchable";

interface Props {
  value: number;
  message?: string;
}

const BubbleNotif = ({ value, message }: Props) => {
  const [openModal, setModal] = useState(false);
  return (
    <>
      {value > 0 && (
        <View style={styles.container}>
          <Touchable onPress={() => setModal(true)}>
            <Text style={styles.valueStyle}>{value}</Text>
          </Touchable>
          <Modal
            isVisible={openModal}
            wrapStyle={{ justifyContent: "flex-start" }}
            backdropColor="rgba(255,255,255, 0.9)"
          >
            <NoteRevise
              headerTitle="Description"
              onPress={() => setModal(false)}
            >
              <Text>{message}</Text>
            </NoteRevise>
          </Modal>
        </View>
      )}
    </>
  );
};

BubbleNotif.defaultProps = {
  message: ""
};

export default BubbleNotif;
