import { StyleSheet } from "react-native";

import { COLORS } from "../../../../../configs";
import { widthPercent } from "../../../../../utils";

const styles = StyleSheet.create({
  container: {
    width: widthPercent(100) - 48,
    paddingVertical: 12
  },
  card: {
    borderRadius: 12,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main
  },
  invoiceNumber: {
    maxWidth: "100%",
    color: COLORS.grey,
    fontSize: 12,
    paddingHorizontal: 20,
    fontWeight: "bold",
    paddingVertical: 8,
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginHorizontal: 22,
    marginTop: -16,
    paddingTop: 22
  },
  cardDesc: {
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 8
  },
  row: {
    flexDirection: "row",
    marginBottom: 4
  },
  line: {
    borderBottomColor: COLORS.greyLight,
    borderBottomWidth: 1
  },
  col1: {
    width: "25%"
  },
  col2: {
    width: "70%"
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyLight
  },
  desc: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  footerCard: {
    backgroundColor: COLORS.green,
    paddingHorizontal: 16,
    paddingVertical: 6,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  textNom: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "white"
    // color: COLORS.green
  },
  textSettled: {
    alignSelf: "flex-end",
    marginTop: -12,
    fontSize: 14,
    paddingBottom: 8,
    color: COLORS.green
  },
  wrapButtonTrash: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    position: "absolute",
    right: 8,
    top: 8
  },
  buttonTrash: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  wrapButtonAdd: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    borderRadius: 16,
    marginBottom: 16
  },
  buttonAdd: {
    width: 180,
    flexDirection: "row",
    height: 28,
    alignItems: "center",
    justifyContent: "center"
  },
  textAddAdd: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: COLORS.white,
    fontSize: 14
  },
  iconPlus: {
    width: 12,
    height: 12,
    marginRight: 4
  }
});

export default styles;
