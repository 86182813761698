import { Dimensions, StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const { height, width } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    height,
    width,
    paddingTop: 54,
    paddingHorizontal: 20
  },
  departmentSearchContainer: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "white",
    marginBottom: 10
  },
  headerTitle: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    alignSelf: "center",
    color: COLORS.grey,
    marginBottom: 24
  },
  wrapPersonList: {
    flex: 1,
    flexDirection: "column",
    overflow: "scroll"
  },
  imageStyle: {
    height: 36,
    width: 36,
    borderRadius: 40,
    margin: 4
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  cancelStyle: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: COLORS.greyMedium,
    fontWeight: "bold"
  },
  textWarning: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#666666"
  },
  btnModal: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 48
  },
  containerModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  wrapCardInput: {
    flexDirection: "row",
    width: "90%"
  },
  footerBtn: {
    marginTop: 12,
    alignItems: "center",
    marginBottom: 38
  },
  isLoadingList: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
