import React, { useEffect } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteCashAdvance, getCashAdvance } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

import { Card, Touchable, WrapContent, WrapList } from "../../../components";
import styles from "./styles";
import { IMAGES } from "../../../configs";
import { convertDate, maskedMoney } from "../../../utils";

interface Props {
  id: string;
}

const CashAdvance = ({ id }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const NewExpenseTripState = useSelector(
    (state: Reducers) => state.newExpenseTrip
  );

  const _addCashExpense = () => {
    history.push("/new-cash-expense", {
      id,
      invoice_id:
        NewExpenseTripState.listCashAdvance.length === 0
          ? null
          : NewExpenseTripState.listCashAdvance[0].invoice_id
    });
  };

  const _deleteCashAdvance = (idCA: string) => {
    dispatch(deleteCashAdvance(idCA));
  };

  useEffect(() => {
    dispatch(getCashAdvance(id));
  }, [dispatch]);

  return (
    <WrapContent isLoading={NewExpenseTripState.isLoadingListCashAdvance}>
      <WrapList
        isEmpty={NewExpenseTripState.listCashAdvance.length === 0}
        emptyComponent={() => (
          <Touchable onPress={_addCashExpense}>
            <Image style={{ width: 72, height: 72 }} source={IMAGES.plus} />
          </Touchable>
        )}
      >
        <FlatList
          style={{ paddingBottom: 10, paddingHorizontal: 25 }}
          data={NewExpenseTripState.listCashAdvance}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Card
              key={index}
              cardStyle={{
                marginBottom: 15,
                marginTop: index === 0 ? 20 : 0
              }}
              statusColor="#50b1b1"
              statusText="Amount"
              statusDesc={`IDR ${maskedMoney(item.total)}`}
            >
              <View style={{ marginHorizontal: 22, marginVertical: 19 }}>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={styles.text}>Subject</Text>
                  <Text style={styles.textDesc}>{item.title}</Text>
                  <View>
                    <Touchable onPress={() => _deleteCashAdvance(item.id)}>
                      <View
                        style={{
                          height: 28,
                          width: 28,
                          borderRadius: 20,
                          shadowColor: "#ddd",
                          shadowOffset: { width: 0, height: 2 },
                          shadowOpacity: 1,
                          shadowRadius: 5,
                          elevation: 2,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Image
                          source={IMAGES.trashBin}
                          style={{ width: 13, height: 16 }}
                        />
                      </View>
                    </Touchable>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={styles.text}>Category</Text>
                  <Text style={styles.textDesc}>
                    {!item.category ? "-" : item.category}
                  </Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={styles.text}>Date</Text>
                  <Text style={styles.textDesc}>
                    {item.created_at !== null
                      ? `${convertDate(item.created_at)}`
                      : "-"}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.text}>Billable</Text>
                  <Text style={styles.textDesc}>
                    {item.billable ? "YES" : "NO"}
                  </Text>
                </View>
              </View>
            </Card>
          )}
        />
      </WrapList>

      {NewExpenseTripState.listCashAdvance.length > 0 && (
        <div
          style={{
            position: "sticky",
            bottom: 150,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          <Touchable onPress={_addCashExpense}>
            <Image style={{ width: 72, height: 72 }} source={IMAGES.plus} />
          </Touchable>
        </div>
      )}
    </WrapContent>
  );
};

export default CashAdvance;
