import { StyleSheet } from "react-native";
import { widthPercent } from "../../../utils";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  containerContent: {
    width: widthPercent(100) - 40,
    flex: 1,
    alignItems: "center"
  },
  attributeTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#999999"
  },
  valueTask: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#666666",
    fontWeight: "bold"
  },
  detailTask: {
    width: widthPercent(100) - 76,
    alignItems: "center"
  },
  button: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  card: {
    borderRadius: 10,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: COLORS.main
  },
  fileAttachment: {
    width: "48%",
    height: 28,
    borderRadius: 100,
    marginBottom: 12
  },
  avatarContainer: {
    width: 36,
    height: 36,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center"
  },
  btnSubmit: {
    borderRadius: 100,
    width: "30%",
    height: 28,
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  btnCancel: {
    borderRadius: 100,
    width: "30%",
    height: 28,
    backgroundColor: "#666666",
    alignItems: "center",
    justifyContent: "center"
  },
  remarksContainer: {
    width: widthPercent(100) - 76
  },
  contentWrapper: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 4
  },
  dateTaskWrapper: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 4
  },
  addParticipant: {
    position: "absolute",
    right: 0,
    alignItems: "center",
    top: -5
  },
  imagePlusAddParticipant: {
    width: 36,
    height: 36,
    marginBottom: 2
  },
  extendsTaskWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 30
  },
  inputExtends: {
    borderColor: "#666666",
    borderBottomWidth: 1,
    height: 25,
    color: "#666666",
    width: 100,
    marginBottom: 12,
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  buttonWrapperExtends: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center"
  },
  attachmentWrapper: {
    width: "100%",
    marginTop: 12,
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  descriptionWrapper: {
    flexDirection: "row",
    marginVertical: 16,
    marginHorizontal: 16
  },
  attachmentFileWrapper: {
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center"
  },
  deleteFile: {
    width: "15%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  fileUploadWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 11
  },
  textInputRemarks: {
    width: "100%",
    borderColor: "#666666",
    borderBottomWidth: 1,
    height: 25,
    color: "#999999",
    marginBottom: 12
  },
  buttonRemarks: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center"
  },
  buttonRequestActions: {
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    height: 54,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    marginHorizontal: 10
  },
  buttonRequestBlue: {
    backgroundImage: "linear-gradient(to bottom, #50b1b1, #262261 168%)",
    height: 54,
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    marginHorizontal: 10
  },
  buttonTextWhite: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "white",
    fontWeight: "bold"
  },
  cancelStyle: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: COLORS.greyMedium,
    fontWeight: "bold"
  },
  textWarning: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#666666"
  },
  btnModal: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 48
  },
  containerModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  recuringCard: {
    width: 180,
    alignItems: "center",
    paddingVertical: 2
  },
  poppins: {
    fontFamily: "Poppins",
    color: COLORS.greyMedium
  },
  requestLabel: {
    fontSize: 11,
    color: COLORS.greyLight,
    marginBottom: 2,
    textAlign: "center"
  },
  requestValue: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center"
  }
});

export default styles;
