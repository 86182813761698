import React, { useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { CustomText, KasbonNavigation } from "../../components";
import {
  getLoanHistory,
  getLoanProfile,
  getSubmittedLoan
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

import { KasbonCard } from "./components/KasbonCard/KasbonCard";
import { KasbonContent } from "./components/KasbonContent/KasbonContent";

import styles from "./styles";

const Kasbon = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoanProfile());
    dispatch(getLoanHistory());
    dispatch(getSubmittedLoan());
  }, [dispatch]);

  const { isLoadingKasbonSetting } = useSelector(
    (state: Reducers) => state.loan
  );
  const hasCompany = localStorage.getItem("kasbon-setting");

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor:
            hasCompany && !isLoadingKasbonSetting
              ? JSON.parse(hasCompany).hex_code
              : "white"
        }
      ]}
    >
      {!isLoadingKasbonSetting ? (
        <>
          <KasbonNavigation />
          <View
            style={[
              styles.content,
              {
                backgroundColor: hasCompany
                  ? JSON.parse(hasCompany).hex_code
                  : "white"
              }
            ]}
          >
            <KasbonCard />
            <KasbonContent />
          </View>
        </>
      ) : (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color="#344370" size="large" />
          <CustomText style={styles.loadingText}>
            Sedang di proses mohon tunggu sebentar...
          </CustomText>
        </View>
      )}
    </View>
  );
};
export default Kasbon;
