import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  wrapDetailCash: {
    paddingHorizontal: 16,
    position: "relative"
  },
  wrapDetailtext: {
    flexDirection: "row",
    marginBottom: 4
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "24%"
  },
  textValue: {
    width: "70%",
    marginRight: 8,
    color: COLORS.greyMedium
  },
  textValueDate: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  textValueTrip: {
    width: "100%",
    marginRight: 8
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.greyMedium
  },
  wrapContentCard: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 12
  },
  wrapCardText: {
    flexDirection: "row",
    marginBottom: 4
  },
  wrapSecondContent: {
    marginTop: 16,
    alignItems: "center"
  },
  poppins: {
    fontFamily: "Poppins",
    color: COLORS.greyMedium
  },
  requestLabel: {
    fontSize: 11,
    color: COLORS.greyLight,
    marginBottom: 2,
    textAlign: "center"
  },
  requestValue: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center"
  },
  recuringCard: {
    width: 180,
    alignItems: "center",
    paddingVertical: 2
  },
  btnModeration: {
    borderRadius: 100,
    width: 54,
    height: 54,
    marginBottom: 24,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main
  },
  stepperContainer: {
    width: widthPercent(100),
    flexDirection: "row",
    overflow: "scroll",
    marginTop: 40
  },
  stepperStatus: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  stepperAssigner: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  stepperItems: {
    flexDirection: "row",
    alignItems: "center"
  },
  stepperCircle: {
    height: 18,
    width: 18,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",
    borderRadius: 100
  },
  stepperLine: {
    height: 3,
    width: 166,
    marginHorizontal: 4,
    backgroundColor: "#50B1B1"
  },
  wrapEditBtn: {
    borderRadius: 25,
    padding: 8,
    position: "absolute",
    right: 12,
    top: 0,
    backgroundColor: COLORS.main,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,

    elevation: 6
  }
});

export default styles;
