import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  label: {
    fontFamily: "Poppins",
    color: "#666666"
  },
  line: {
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#666666",
    paddingTop: 5
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 25
  },
  input: {
    color: "#666666",
    marginTop: 6,
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold"
  },
  datePickerNavWrap: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)"
  },
  datePickerNav: {
    width: 168,
    height: 70
  },
  datePickerNavActive: {
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  datePicker: {
    paddingHorizontal: 15,
    height: 20,
    marginVertical: 9
  },
  textArea: {
    fontFamily: "Poppins",
    color: "#666666",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#666666"
  },
  button: {
    borderRadius: 50,
    marginBottom: 30,
    marginTop: 71.5,
    height: 54,
    width: 180,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 , #db3a26)",
    justifyContent: "center",
    alignItems: "center"
  },
  txtButton: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#ffffff"
  }
});

export default styles;
