import { StyleSheet } from "react-native";

import { COLORS } from "../../../../../../configs";

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20
  },
  row: {
    padding: 12,
    borderBottomColor: COLORS.greyLight,
    borderBottomWidth: 0.5,
    paddingBottom: 8
  },
  wrapDesc: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 4
  },
  col1: {
    width: "20%"
  },
  col2: {
    width: "50%"
  },
  col3: {
    width: "30%"
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyLight
  },
  desc: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey,
    fontWeight: "bold"
  },
  nominal: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.green
  },
  titleTotal: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  wrapTotal: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default styles;
