import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_REGENCY_PENDING = "GET_REGENCY_PENDING";
export const GET_REGENCY_SUCCESS = "GET_REGENCY_SUCCESS";
export const GET_REGENCY_ERROR = "GET_REGENCY_ERROR";

export const GET_COUNTRY_PENDING = "GET_COUNTRY_PENDING";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";

export const GET_AIRPORT_PENDING = "GET_AIRPORT_PENDING";
export const GET_AIRPORT_SUCCESS = "GET_AIRPORT_SUCCESS";
export const GET_AIRPORT_ERROR = "GET_AIRPORT_ERROR";

export const getRegency = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_REGENCY_PENDING });

    const res = await API.getRegency(auth.token);
    dispatch({
      type: GET_REGENCY_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getRegency())));
      } else {
        dispatch({
          type: GET_REGENCY_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_REGENCY_ERROR });
  }
};

export const getListCountry = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_COUNTRY_PENDING });

    const res = await API.getListCountry(auth.token);
    dispatch({
      type: GET_COUNTRY_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getListCountry())));
      } else {
        dispatch({
          type: GET_COUNTRY_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_COUNTRY_ERROR });
  }
};

export const getListAirport = (
  countryCode: string,
  type: "arrival" | "departure"
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_AIRPORT_PENDING });

    const res = await API.getListAirport(auth.token, countryCode);
    dispatch({
      type: GET_AIRPORT_SUCCESS,
      payload: { data: { data: res.data.data, type } }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getListAirport(countryCode, type)))
        );
      } else {
        dispatch({
          type: GET_AIRPORT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_AIRPORT_ERROR });
  }
};
