import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  formContainer: {
    marginTop: 13,
    paddingLeft: 41,
    paddingRight: 42
  },
  labelInput: {
    fontSize: 8,
    fontFamily: "Poppins",
    color: "rgba(102, 102, 102, 0.5)"
  },
  inputStyle: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 2,
    marginLeft: -2
  },
  inputContainer: {
    marginBottom: 10
  },
  saveButton: {
    marginBottom: 50,
    marginTop: 20,
    alignItems: "center"
  },
  pencil: {
    height: 40,
    width: 40,
    position: "absolute",
    alignSelf: "flex-end",
    borderRadius: 100,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center"
  },
  pp: {
    width: 108,
    height: 108,
    borderRadius: 100,
    zIndex: -1,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  containerAutoComplete: {
    backgroundColor: "#fffff7",
    width: "100%",
    borderRadius: 5,
    position: "absolute",
    marginTop: 50
  },
  cardAutoComplete: {
    minHeight: 40,
    maxHeight: 120,
    overflow: "scroll",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  }
});

export default styles;
