/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import { getCloseTrip, resetCloseTrip } from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";
import { IMAGES } from "../../../../../configs";

import { copyWritings, maskedMoney } from "../../../../../utils";
import { Touchable } from "../../../../../components";
import CapsuleBudget from "./components";

interface Props {
  tripId: string;
  closeBtn: () => void;
}
const ClosingTripNotes = ({ tripId, closeBtn }: Props) => {
  const dispatch = useDispatch();
  const { closeTrip } = useSelector((state: Reducers) => state.trip);
  const [oneTimeEffect, setOneTimeEffect] = useState(true);
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  const copTeks = {
    trip: copyWritings("trip"),
    cash_advance: copyWritings("cash_advance"),
    reimburse: copyWritings("reimburse"),
    report: copyWritings("report"),
    booking: copyWritings("booking"),
    collectable: copyWritings("collectable")
  };

  const renderValue = (value: string) => {
    const splitValue = value.split(" ");
    let _value = <></>;
    if (splitValue[0].match(/^[0-9]*[0-9]$/i)) {
      _value = (
        <>
          <Text>IDR </Text>
          <Text style={{ fontWeight: "bold" }}>
            {maskedMoney(splitValue[0])}
          </Text>
          {typeof splitValue[1] !== "undefined" ? ` ${splitValue[1]}` : ""}
        </>
      );
    } else {
      _value = (
        <>
          <Text>IDR </Text>
          {`${splitValue[0]} `}
          {typeof splitValue[1] !== "undefined" ? (
            <Text style={{ fontWeight: "bold" }}>
              {maskedMoney(splitValue[1])}
            </Text>
          ) : (
            <></>
          )}
        </>
      );
    }
    return (
      <Text
        style={[
          styles.rectangularItemTextRed,
          { position: "absolute", right: 0, paddingRight: 15 }
        ]}
      >
        {_value}
      </Text>
    );
  };

  useEffect(() => {
    if (oneTimeEffect) {
      setOneTimeEffect(false);
      dispatch(resetCloseTrip());
      dispatch(getCloseTrip(tripId));
    }
  }, [dispatch, tripId, oneTimeEffect]);

  const renderReport = () => {
    return (
      <Text
        style={[
          styles.rectangularItemTextRed,
          { position: "absolute", right: 0, paddingRight: 15 }
        ]}
      >
        <Text style={{ fontWeight: "bold" }}>{closeTrip.sisa_report}</Text>
        {` ${t("task.detail.days")}`}
      </Text>
    );
  };

  const _renderStatusBooking = () => {
    const status = closeTrip.booking_status;

    let desc = "FINISHED";
    if (status === 1) {
      desc = t("cash.status.draft");
    } else if (status === 2) {
      desc = t("cash.status.issued");
    } else if (status === 3) {
      desc = t("cash.status.approved");
    } else if (status === 4) {
      desc = t("cash.status.revise");
    } else if (status === 5) {
      desc = t("cash.status.unpaid");
    } else if (status === 6) {
      desc = t("cash.status.paid");
    } else if (status === 7) {
      desc = t("cash.status.cancel");
    } else if (status === 9) {
      desc = t("cash.status.rejected");
    }

    return (
      <Text
        style={[
          styles.rectangularItemTextRed,
          { position: "absolute", right: 0, paddingRight: 15 }
        ]}
      >
        <Text style={{ fontWeight: "bold" }}>{desc}</Text>
      </Text>
    );
  };

  return (
    <View>
      <View style={styles.container}>
        <View>
          <View style={{ alignItems: "center", marginBottom: 22 }}>
            <Image
              source={IMAGES.closingTrip}
              style={{ width: 46, height: 46 }}
            />
          </View>
          <View style={{ alignItems: "center" }}>
            <Text style={styles.title}>
              {sprintf(t("activity.closingTrip"), copTeks.trip)}
            </Text>
          </View>
          <View
            style={{ alignItems: "center", marginBottom: 22, marginTop: 4 }}
          >
            <Text style={styles.subtitle}>
              {sprintf(t("activity.closingTripMsg"), copTeks.trip)}
            </Text>
            {/* <Text style={styles.subtitle}>can close your trip</Text> */}
          </View>
          {closeTrip && closeTrip.total_ca > 0 ? (
            <CapsuleBudget
              active
              title={copTeks.cash_advance}
              subtitle={t("activity.remaining")}
              value={renderValue(String(closeTrip.total_ca))}
            />
          ) : null}
          {closeTrip && closeTrip.reimburse > 0 ? (
            <CapsuleBudget
              active
              title={copTeks.reimburse}
              subtitle={t("activity.remaining")}
              value={renderValue(String(closeTrip.reimburse))}
            />
          ) : null}
          {closeTrip && closeTrip.sisa_report > 0 ? (
            <CapsuleBudget
              active
              title={copTeks.report}
              subtitle={t("activity.missing")}
              value={renderReport()}
            />
          ) : null}
          {closeTrip && closeTrip.collectable > 0 ? (
            <CapsuleBudget
              active
              title={copTeks.collectable}
              subtitle={t("activity.outstanding")}
              value={renderValue(String(closeTrip.collectable))}
            />
          ) : null}
          {closeTrip &&
          closeTrip.booking_status !== 8 &&
          closeTrip &&
          closeTrip.booking_status !== 0 &&
          closeTrip &&
          closeTrip.booking_status !== null ? (
            <CapsuleBudget
              active
              title={copTeks.booking}
              subtitle="Status"
              value={_renderStatusBooking()}
            />
          ) : null}

          <View style={{ alignItems: "center", marginTop: 40 }}>
            <Text style={styles.title}>{t("activity.settlement")}</Text>
          </View>
          <View style={{ alignItems: "center", marginBottom: 22 }}>
            <Text style={styles.subtitle}>
              {t("activity.settlementWithFinance")}
            </Text>
          </View>

          {closeTrip && closeTrip.total_ca === 0 ? (
            <CapsuleBudget
              active={false}
              title={copTeks.cash_advance}
              subtitle={t("activity.remaining")}
              value={renderValue(String(closeTrip.total_ca))}
            />
          ) : null}
          {closeTrip && closeTrip.reimburse === 0 ? (
            <CapsuleBudget
              active={false}
              title={copTeks.reimburse}
              subtitle={t("activity.remaining")}
              value={renderValue(String(0))}
            />
          ) : null}
          {closeTrip && closeTrip.sisa_report === 0 ? (
            <CapsuleBudget
              active={false}
              title={copTeks.report}
              subtitle={t("activity.missing")}
              value={renderReport()}
            />
          ) : null}
          {closeTrip && closeTrip.collectable === 0 ? (
            <CapsuleBudget
              active={false}
              title={copTeks.collectable}
              subtitle={t("activity.outstanding")}
              value={renderValue(String(0))}
            />
          ) : null}
          {(closeTrip && closeTrip.booking_status === 8) ||
          (closeTrip && closeTrip.booking_status === 0) ? (
            <CapsuleBudget
              active={false}
              title={copTeks.booking}
              subtitle="Status"
              value={_renderStatusBooking()}
            />
          ) : null}
        </View>
      </View>
      <View style={styles.buttonClose}>
        <Touchable onPress={closeBtn}>
          <Text style={styles.closeText}>{t("activity.close")}</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default ClosingTripNotes;
