import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20
  },
  text: {
    fontSize: 11,
    width: "22%",
    color: "#999999",
    fontFamily: "Poppins"
    // backgroundColor: "blue"
  },
  textDesc: {
    width: "78%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins",
    fontWeight: "bold"
    // backgroundColor: "red"
  },
  btnReport: {
    width: 28,
    height: 28,
    backgroundColor: "#fffff7",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  footer: {
    height: 74,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "center",
    alignItems: "center"
  },
  budgetReq: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#fffff7"
  },
  priceIdr: {
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#FFFFF7",
    marginTop: 2
  }
});

export default styles;
