import {
  DELETE_BILL_FAILED,
  DELETE_BILL_PENDING,
  DELETE_BILL_SUCCESS,
  EDIT_DETAIL_BILLS_FAILED,
  EDIT_DETAIL_BILLS_PENDING,
  EDIT_DETAIL_BILLS_SUCCESS,
  EMPTY_DETAIL_BILLS,
  EMPTY_LIST_CASH_ADVANCE_BILL,
  GET_DETAIL_BILLS_FAILED,
  GET_DETAIL_BILLS_PENDING,
  GET_DETAIL_BILLS_SUCCESS,
  GET_LIST_CASH_ADVANCE_BILL_ERROR,
  GET_LIST_CASH_ADVANCE_BILL_PENDING,
  GET_LIST_CASH_ADVANCE_BILL_SUCCESS,
  LOGOUT_SUCCESS,
  POST_NEW_BILL_ERROR,
  POST_NEW_BILL_PENDING,
  POST_NEW_BILL_SUCCESS,
  PUT_BILLABLE_ERROR,
  PUT_BILLABLE_PENDING,
  PUT_BILLABLE_SUCCESS,
  PUT_CLOSEBILL_ERROR,
  PUT_CLOSEBILL_PENDING,
  PUT_CLOSEBILL_SUCCESS,
  RESET_BILL,
  RESET_DONE_LIST_CASH_ADVANCE_BILL
} from "../actions";
import { ListCashAdvanceBill } from "../types";

const initialState: ListCashAdvanceBill = {
  isDoneGetListCashAdvanceBill: false,
  isLoadingListCashAdvanceBill: false,
  listCashAdvanceBill: [],
  isLoadingPostNewBill: false,
  isDonePostNewBill: true,
  isSuccessPostNewBill: false,
  isErrorPostNewBill: false,
  isLoadingPutBillable: false,
  isDonePutBillable: true,
  isSuccessPutBillable: false,
  isErrorPutBillable: false,
  tripDetail: null,
  isloadingGetBills: false,
  loadDataDetailBills: null,
  isLoadingEditBills: false,
  isLoadingPutCloseBill: false,
  isDonePutCloseBill: false,
  isSuccessPutCloseBill: false,
  isErrorPutCloseBill: false,
  isLoadingDeleteBill: false,
  isDoneDeleteBill: false,
  isSuccessDeleteBill: false,
  isErrorDeleteBill: false
};

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case EMPTY_DETAIL_BILLS:
      return {
        ...state,
        loadDataDetailBills: null
      };
    case GET_LIST_CASH_ADVANCE_BILL_PENDING:
      return { ...state, isLoadingListCashAdvanceBill: true };
    case GET_LIST_CASH_ADVANCE_BILL_SUCCESS:
      return {
        ...state,
        isLoadingListCashAdvanceBill: false,
        listCashAdvanceBill: payload.data,
        tripDetail: payload.mainData,
        isDoneGetListCashAdvanceBill: true
      };
    case GET_LIST_CASH_ADVANCE_BILL_ERROR:
      return {
        ...state,
        isLoadingListCashAdvanceBill: false,
        isDoneGetListCashAdvanceBill: true
      };
    case RESET_DONE_LIST_CASH_ADVANCE_BILL:
      return {
        ...state,
        isLoadingListCashAdvanceBill: false,
        isDoneGetListCashAdvanceBill: false,
        isLoadingPostNewBill: false,
        isDonePostNewBill: true,
        isSuccessPostNewBill: false,
        isErrorPostNewBill: false
      };

    case POST_NEW_BILL_PENDING:
      return {
        ...state,
        isLoadingPostNewBill: true,
        isDonePostNewBill: false,
        isSuccessPostNewBill: false,
        isErrorPostNewBill: false
      };

    case POST_NEW_BILL_SUCCESS:
      return {
        ...state,
        isLoadingPostNewBill: false,
        isDonePostNewBill: true,
        isSuccessPostNewBill: true,
        isErrorPostNewBill: false
      };

    case POST_NEW_BILL_ERROR:
      return {
        ...state,
        isLoadingPostNewBill: false,
        isDonePostNewBill: true,
        isSuccessPostNewBill: false,
        isErrorPostNewBill: true
      };

    case PUT_BILLABLE_PENDING:
      return {
        ...state,
        isLoadingPutBillable: true,
        isDonePutBillable: false,
        isSuccessPutBillable: true,
        isErrorPutBillable: false
      };

    case PUT_BILLABLE_SUCCESS:
      return {
        ...state,
        isLoadingPutBillable: false,
        isDonePutBillable: true,
        isSuccessPutBillable: true,
        isErrorPutBillable: false
      };

    case PUT_BILLABLE_ERROR:
      return {
        ...state,
        isLoadingPutBillable: false,
        isDonePutBillable: true,
        isSuccessPutBillable: false,
        isErrorPutBillable: true
      };

    case PUT_CLOSEBILL_ERROR:
      return {
        ...state,
        isLoadingPutCloseBill: false,
        isDonePutCloseBill: true,
        isSuccessPutCloseBill: false,
        isErrorPutCloseBill: true
      };

    case PUT_CLOSEBILL_PENDING:
      return {
        ...state,
        isLoadingPutCloseBill: true,
        isDonePutCloseBill: false,
        isSuccessPutCloseBill: false,
        isErrorPutCloseBill: false
      };

    case PUT_CLOSEBILL_SUCCESS:
      return {
        ...state,
        isLoadingPutCloseBill: false,
        isDonePutCloseBill: true,
        isSuccessPutCloseBill: false,
        isErrorPutCloseBill: false
      };

    case EMPTY_LIST_CASH_ADVANCE_BILL:
      return {
        ...state,
        listCashAdvanceBill: []
      };
    case GET_DETAIL_BILLS_PENDING:
      return {
        ...state,
        isloadingGetBills: true,
        loadDataDetailBills: null
      };
    case GET_DETAIL_BILLS_SUCCESS:
      return {
        ...state,
        isloadingGetBills: false,
        loadDataDetailBills: payload.data
      };
    case GET_DETAIL_BILLS_FAILED:
      return {
        ...state,
        isloadingGetBills: false
      };
    case EDIT_DETAIL_BILLS_PENDING:
      return {
        ...state,
        isLoadingEditBills: true
      };
    case EDIT_DETAIL_BILLS_SUCCESS:
      return {
        ...state,
        isLoadingEditBills: false
      };
    case EDIT_DETAIL_BILLS_FAILED:
      return {
        ...state,
        isLoadingEditBills: false
      };

    case DELETE_BILL_PENDING:
      return {
        ...state,
        isLoadingDeleteBill: true,
        isDoneDeleteBill: false,
        isSuccessDeleteBill: false,
        isErrorDeleteBill: false
      };

    case DELETE_BILL_SUCCESS:
      return {
        ...state,
        isLoadingDeleteBill: false,
        isDoneDeleteBill: true,
        isSuccessDeleteBill: true,
        isErrorDeleteBill: false
      };
    case DELETE_BILL_FAILED:
      return {
        ...state,
        isLoadingDeleteBill: false,
        isDoneDeleteBill: true,
        isSuccessDeleteBill: false,
        isErrorDeleteBill: true
      };

    case RESET_BILL:
      return {
        ...state,
        isDoneGetListCashAdvanceBill: false,
        isLoadingListCashAdvanceBill: false,
        isLoadingPostNewBill: false,
        isDonePostNewBill: false,
        isSuccessPostNewBill: false,
        isErrorPostNewBill: false,
        isLoadingPutBillable: false,
        isDonePutBillable: false,
        isSuccessPutBillable: false,
        isErrorPutBillable: false,
        tripDetail: null,
        isloadingGetBills: false,
        loadDataDetailBills: null,
        isLoadingEditBills: false,
        isLoadingPutCloseBill: false,
        isDonePutCloseBill: false,
        isSuccessPutCloseBill: false,
        isErrorPutCloseBill: false,
        isLoadingDeleteBill: false,
        isDoneDeleteBill: false,
        isSuccessDeleteBill: false,
        isErrorDeleteBill: false
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
