import React from "react";
import { Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { IMAGES } from "../../../configs";
import { putModerationTrip } from "../../../redux/actions";
import styles from "./styles";
import { Touchable } from "../../index";

interface Props {
  notificationType?: string;
  moderationId?: string;
  cancel?: () => void;
}

const Approve = ({ notificationType, moderationId, cancel }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleModerationTrip = async () => {
    await dispatch(putModerationTrip(moderationId, 1, ""));
    if (notificationType) {
      history.push("/expense/approval");
      window.location.reload();
    } else {
      history.push("/trip");
    }
  };
  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {`APPROVE ${notificationType?.toUpperCase() || "PROJECT"}`}
      </Text>
      <Image
        resizeMode="contain"
        source={IMAGES.approveModal}
        style={styles.image}
      />
      <Text style={styles.content}>
        {` Are you sure to get this ${
          notificationType || "project"
        } ongoing? We will notify the ${
          notificationType ? "staff" : "team"
        } afterwards`}
      </Text>
      <View style={{ marginTop: 149 }}>
        <Touchable onPress={() => handleModerationTrip()}>
          <View style={styles.buttonAttachmen}>
            <Text style={styles.buttonText}>APPROVE</Text>
          </View>
        </Touchable>
      </View>
      <View style={{ marginTop: 12 }}>
        <Touchable onPress={cancel || (() => history.goBack())}>
          <Text style={styles.cancel}>CANCEL</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default Approve;
