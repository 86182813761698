/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  deleteReimburse,
  getCashDetail,
  getReimburse,
  putReissue
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import {
  Card,
  Confirmation,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import { IMAGES } from "../../../../configs";
import styles from "./styles";
import {
  convertDate,
  copyWritings,
  maskedMoney,
  toast
} from "../../../../utils";

interface Props {
  id: string;
  search: string;
  isBillApproval?: boolean;
}

const Reimburse = ({ id, search, isBillApproval }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const copTeks = {
    reimburse: copyWritings("reimburse"),
    policy: copyWritings("policy")
  };
  const { ExpenseState, tripState, cashState } = useSelector(
    (state: Reducers) => ({
      ExpenseState: state.newExpenseTrip,
      tripState: state.trip,
      cashState: state.cash
    }),
    shallowEqual
  );

  const [modalDeleteReimburse, setModalDeleteReimburse] = useState(false);
  const [idReimburse, setIdReimburse] = useState("");
  const [modalIssueReimburse, setModalIssueReimburse] = useState(false);

  useEffect(() => {
    dispatch(getReimburse(id));
  }, [dispatch]);

  useEffect(() => {
    if (ExpenseState.listReimburse.length > 0) {
      dispatch(
        getCashDetail(
          ExpenseState.listReimburse && ExpenseState.listReimburse[0].invoice_id
        )
      );
    }
  }, [dispatch, ExpenseState.listReimburse]);

  const _permissionCreate = () => {
    const trip = tripState.tripData;
    if (trip && [5, 8].includes(trip.status) && !isBillApproval) {
      return true;
    }

    return false;
  };

  const _permissionEdit = () => {
    const trip = tripState.tripData;
    const cash = cashState.cashDetail;

    if (
      trip &&
      [5, 8].includes(trip.status) &&
      cash &&
      [1, 4].includes(cash.status) &&
      !isBillApproval
    ) {
      return true;
    }

    return false;
  };

  const _permissionIssue = () => {
    const trip = tripState.tripData;
    const cash = cashState.cashDetail;

    if (
      trip &&
      [5, 8].includes(trip.status) &&
      cash &&
      [1, 4].includes(cash.status) &&
      !isBillApproval
    ) {
      return true;
    }
    return false;
  };

  const _newReimburse = (itemId: string, item: any) => {
    history.push(`/new-expense-reimburse-trip${search}`, {
      id,
      status: tripState.tripData && tripState.tripData.status,
      invoice_id:
        ExpenseState.listReimburse.length === 0
          ? null
          : ExpenseState.listReimburse[0].invoice_id,
      project_id:
        tripState.tripData?.project?.id || tripState.tripData?.project?.uuid,
      itemId,
      item,
      permissionCreate: _permissionCreate()
    });
  };

  const _deleteReimburse = () => {
    dispatch(
      deleteReimburse(idReimburse, () => {
        setModalDeleteReimburse(false);
        toast.success(t("cash.new.msg.toastDelete"));
      })
    );
  };

  const _onReissue = () => {
    const formIssue = {
      invoice_id:
        ExpenseState.listReimburse.length > 0
          ? ExpenseState.listReimburse[0].invoice_id
          : "",
      client_timezone: "Asia/Jakarta"
    };
    dispatch(
      putReissue(formIssue, (run, info) => {
        if (run === true) {
          setModalIssueReimburse(false);
          toast.success(t("cash.new.msg.toastSuccess"));
          dispatch(
            getCashDetail(
              ExpenseState.listReimburse &&
                ExpenseState.listReimburse[0].invoice_id
            )
          );
        } else {
          toast.error(info);
        }
      })
    );
  };

  const _goToPreview = (link: string) => {
    window.open(`${link}`, "_blank");
  };

  const _totalItem = () => {
    if (ExpenseState.listReimburse.length > 0) {
      return ExpenseState.listReimburse.reduce((acc, current) => ({
        total: Number(acc.total) + Number(current.total)
      })).total;
    }
    return 0;
  };

  return (
    <WrapContent isLoading={ExpenseState.isLoadingListReimburse}>
      <WrapList
        isEmpty={ExpenseState.listReimburse.length === 0}
        emptyComponent={() =>
          _permissionCreate() ? (
            <View style={styles.wrapButtonEmpty}>
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => _newReimburse("", {})}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {sprintf(t("cash.new.addNew"), copTeks.reimburse)}
                </Text>
              </Card>
            </View>
          ) : (
            <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
          )
        }
      >
        <View style={{ paddingHorizontal: 20 }}>
          <FlatList
            style={{
              marginTop: 11,
              paddingTop: 5
            }}
            data={ExpenseState.listReimburse}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <Card
                cardStyle={styles.cardStyle}
                statusColor="#50b1b1"
                statusTextLight={t("cash.new.qty")}
                statusText={item ? ` ${item.quantity}` : "-"}
                statusDescLight="IDR "
                statusDesc={maskedMoney(item.total)}
                statusDescStyle={{ fontSize: 14 }}
                statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                onPress={() => _newReimburse(item.id, item)}
                key={index}
              >
                <View style={styles.wrapContentCard}>
                  <View
                    style={[
                      styles.wrapCardText,
                      _permissionCreate()
                        ? { marginBottom: -5 }
                        : { marginBottom: 4 }
                    ]}
                  >
                    <Text style={[styles.textLabel]}>{t("cash.new.date")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      <Text style={{ fontWeight: "normal" }}>
                        {`${convertDate(item.created_at, "ddd")} ` || "-"}
                      </Text>
                      {convertDate(item.created_at, "DD MMM YYYY") || ""}
                    </Text>
                    <View style={styles.wrapIcon}>
                      {item.file && (
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            _goToPreview(
                              item.file && JSON.parse(item.file).url
                            );
                          }}
                        >
                          <View style={styles.wrapTrashIcon}>
                            <Image
                              source={IMAGES.clipAttchment}
                              style={{ width: 17, height: 20 }}
                            />
                          </View>
                        </Touchable>
                      )}
                      {_permissionEdit() && (
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            setModalDeleteReimburse(true);
                            setIdReimburse(item.id);
                          }}
                        >
                          <View style={styles.wrapTrashIcon}>
                            <Image
                              source={IMAGES.trashBin}
                              style={{ width: 12, height: 15 }}
                            />
                          </View>
                        </Touchable>
                      )}
                    </View>
                  </View>

                  <View style={[styles.wrapCardText]}>
                    <Text style={styles.textLabel}>{t("cash.new.type")}</Text>
                    <View style={{ width: "60%" }}>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold,
                          { width: "100%" }
                        ]}
                      >
                        {item.category || "-"}
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.wrapCardText]}>
                    <Text style={styles.textLabel}>
                      {t("cash.new.description")}
                    </Text>
                    <View style={{ width: "60%" }}>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold,
                          { width: "100%" }
                        ]}
                      >
                        {item.title || "-"}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          { width: "100%", fontSize: 10, color: "#999999" }
                        ]}
                      >
                        {item.description || "-"}
                      </Text>
                    </View>
                  </View>
                </View>
              </Card>
            )}
          />
          <View
            style={[
              styles.wrapButtonAdd,
              (!_permissionEdit() || !_permissionIssue()) && {
                justifyContent: "center"
              }
            ]}
          >
            {_permissionEdit() && (
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => _newReimburse("", {})}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {sprintf(t("cash.new.addNew"), copTeks.reimburse)}
                </Text>
              </Card>
            )}
            {_permissionIssue() && (
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => setModalIssueReimburse(true)}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {t("cash.new.issue")}
                </Text>
              </Card>
            )}
          </View>
          <View style={styles.wrapTotal}>
            <Text style={styles.totalLabel}>
              {sprintf(t("cash.new.total"), copTeks.reimburse)}
            </Text>
            <Text style={styles.amountText}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(_totalItem())}
            </Text>
          </View>
        </View>
      </WrapList>
      <Modal
        isVisible={modalDeleteReimburse}
        backdropColor="rgba(255,255,255, 0.9)"
      >
        <Confirmation
          headerTitle={sprintf(t("cash.new.deleteCop"), copTeks.reimburse)}
          description={sprintf(t("cash.new.msg.deleteCA"), copTeks.reimburse)}
          actionTitle={t("cash.new.delete")}
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalDeleteReimburse(false)}
          isLoading={ExpenseState.isLoadingDeleteReimburse}
          onAction={_deleteReimburse}
        />
      </Modal>
      <Modal
        isVisible={modalIssueReimburse}
        backdropColor="rgba(255,255,255, 0.9)"
      >
        <Confirmation
          headerTitle={sprintf(t("cash.new.issueCop"), copTeks.reimburse)}
          // eslint-disable-next-line max-len
          description={sprintf(
            t("cash.new.msg.issueReimburse"),
            copTeks.reimburse,
            copTeks.policy
          )}
          actionTitle={t("cash.new.issue").toUpperCase()}
          cancelTitle={t("cash.new.cancel")}
          onCancel={() => setModalIssueReimburse(false)}
          isLoading={cashState.isLoadingReissue}
          onAction={_onReissue}
        />
      </Modal>
    </WrapContent>
  );
};

export default Reimburse;
