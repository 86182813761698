import React from "react";
import { Text, TextInput, View } from "react-native";

import { Button } from "../../components";
import styles from "./styles";

const Notes = () => (
  <View>
    <Text style={styles.title}>Notes</Text>
    <TextInput
      style={styles.note}
      multiline
      placeholder="Add your notes here"
    />

    <Button title="SAVE" style={styles.btnSave} />
  </View>
);

export default Notes;
