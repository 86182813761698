import { StyleSheet } from "react-native";
import { COLORS } from "../../../configs";
// import { widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: COLORS.main,
    minHeight: 156,
    width: "100%",
    alignItems: "center"
  },
  cardPhotos: {
    borderRadius: 100,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: COLORS.main,
    height: 36,
    width: 36,
    alignItems: "center",
    justifyContent: "center"
  },
  statusCard: {
    width: "92%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  button: {
    borderRadius: 100,
    width: "48%",
    height: 28,
    flexDirection: "row",
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  }
});

export default styles;
