import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  inputContainer: {
    marginBottom: 16,
    marginTop: 10,
    marginHorizontal: 10
  },
  labelInput: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  inputStyle: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    fontFamily: "Poppins",
    outlineColor: "rgba(255,255,255,0)",
    paddingLeft: 2,
    marginLeft: -2
  },
  newTripContent: {
    marginTop: 25,
    paddingLeft: 20,
    paddingRight: 20
  },
  newTripFoto: {
    height: 60,
    width: 60,
    resizeMode: "contain",
    borderRadius: 40,
    marginHorizontal: 7,
    marginBottom: 4
  },
  imgContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 5
  },
  newTripAddIcon: {
    fontSize: 60,
    color: "#f05a28",
    marginHorizontal: 7
  },
  cardMultiTrip: {
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginTop: 8
  },
  cardNote: {
    marginTop: 14,
    paddingHorizontal: 14,
    paddingBottom: 11,
    paddingTop: 9,
    marginBottom: 14
  },
  cardTripList: {
    paddingHorizontal: 15,
    paddingTop: 12,
    paddingBottom: 18,
    width: "50%"
  },
  cardTripListTo: {
    borderWidth: 0,
    borderTopWidth: 0,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0
  },
  titleTrip: {
    fontSize: 14,
    marginLeft: 15,
    marginBottom: 8,
    color: "#666666"
  },
  tripCard: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cityName: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#50b1b1",
    marginBottom: 8
  },
  dateStyle: {
    fontSize: 14,
    color: "#50b1b1",
    fontWeight: "bold"
  },
  buttonMultiTrip: {
    flexDirection: "row",
    marginLeft: 14
  },
  containerListCard: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "space-between"
  },
  listCardText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#666666"
  },
  buttonAssign: {
    color: "#ed5a28",
    alignSelf: "center",
    fontSize: 11,
    fontWeight: "bold",
    fontFamily: "Poppins",
    marginTop: 5
  },
  buttonAttachmen: {
    width: "fit-content",
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    borderRadius: 20
  },
  titleAttc: {
    color: "#fffff7",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  saveButtonContainer: {
    marginTop: 29,
    flexDirection: "row",
    justifyContent: "center"
  },
  buttonSave: {
    width: 125,
    backgroundImage: "linear-gradient(to bottom, #50b1b1, #262261 168%)",
    borderRadius: 30,
    marginHorizontal: 8
  },
  buttonSubmit: {
    width: 125,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  buttonSaveText: {
    color: "#ffffff",
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  errorAlert: {
    color: "red",
    fontFamily: "Poppins"
  },
  wrapSwitch: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 8
  },
  textSelfBooking: {
    marginRight: 12,
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666",
    marginLeft: 5
  },
  datePickerNav: {
    flex: 1,
    alignItems: "center",
    height: 70,
    justifyContent: "center"
  },
  datePickerNavWrap: {
    width: 375,
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)"
  },
  datePickerNavActive: {
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  }
});

export default styles;
