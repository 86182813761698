import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20
  },
  wrapTripStatus: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  partTripStatus: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  line: {
    borderRightWidth: 0.5,
    height: 55,
    borderColor: COLORS.greyLight
  },
  numberTrip: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "bold"
  },
  font: {
    fontFamily: "Poppins",
    color: COLORS.grey,
    fontSize: 11,
    textTransform: "capitalize"
  },
  medium: {
    fontSize: 14
  },
  bold: {
    fontWeight: "bold"
  },
  green: {
    color: COLORS.green
  },
  orange: {
    color: COLORS.orange
  },
  yellow: {
    color: COLORS.yellow
  },
  red: {
    color: COLORS.danger
  },
  tripDetailContainer: {
    marginTop: 16
  },
  desciption: {
    flexDirection: "row",
    marginTop: 4,
    paddingLeft: 16
  },
  titleDescription: {
    width: "23%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "65%"
  },
  font14: {
    fontSize: 14
  },
  light: {
    color: "#999999"
  },
  noteStyle: {
    paddingLeft: 20,
    paddingRight: 50,
    marginTop: 12,
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 15,
    borderRadius: 12
  },
  wrapBtnModeration: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center"
  },
  btnModeration: {
    borderRadius: 100,
    width: 54,
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main
  },
  stepperContainer: {
    flexDirection: "row",
    overflow: "scroll",
    marginTop: 12,
    paddingBottom: 12
  },
  stepperStatus: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  stepperAssigner: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  stepperItems: {
    flexDirection: "row",
    alignItems: "center"
  },
  stepperCircle: {
    height: 18,
    width: 18,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",
    borderRadius: 100
  },
  stepperLine: {
    height: 3,
    width: 168,
    marginHorizontal: 4,
    backgroundColor: "#50B1B1"
  }
});

export default styles;
