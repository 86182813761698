/* eslint-disable no-useless-escape */
const validator = {
  make: async (body: any, rules: any) => {
    let error = 0;
    const err_msg: any = {};
    Object.keys(rules).forEach(key => {
      const rule = rules[key];
      const label =
        rule.label == null || rule.label === undefined || rule.label === ""
          ? key
          : rule.label;

      let noRule = 0;
      Object.keys(rule.rule).forEach(keyRule => {
        if (keyRule === "required") {
          if (rule.rule[keyRule]) {
            if (
              typeof body[key] === "undefined" ||
              body[key] === null ||
              body[key] === undefined ||
              body[key] === ""
            ) {
              if (noRule === 0) {
                err_msg[key] = [];
              }
              err_msg[key].push(`${label} is required.`);
              noRule += 1;
              error += 1;
            }
          }
        } else if (keyRule === "email") {
          if (rule.rule[keyRule]) {
            // eslint-disable-next-line max-len
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (
              typeof body[key] !== "undefined" &&
              !re.test(body[key].toLowerCase().trim())
            ) {
              if (noRule === 0) {
                err_msg[key] = [];
              }
              err_msg[key].push(`${label} is invalid.`);
              noRule += 1;
              error += 1;
            }
          }
        } else if (keyRule === "equalsTo") {
          if (rule.rule[keyRule]) {
            const value2 = body[rule.rule[keyRule]];

            const key2 = rule.rule[keyRule];

            const label2 =
              rules[key2].label === null ||
              rules[key2].label === undefined ||
              rules[key2].label === ""
                ? key2
                : rules[key2].label;

            if (body[key] !== value2) {
              if (noRule === 0) {
                err_msg[key] = [];
              }

              err_msg[key].push(`${label} must be match to ${label2}.`);
              noRule += 1;
              error += 1;
            }
          }
        } else if (keyRule === "customWithRegex") {
          if (
            typeof rule.rule[keyRule].regex !== "undefined" &&
            typeof rule.rule[keyRule].message !== "undefined"
          ) {
            // eslint-disable-next-line no-eval
            const re = eval(`/${rule.rule[keyRule].regex}/i`);

            if (!re.test(body[key].trim())) {
              if (noRule === 0) {
                err_msg[key] = [];
              }
              const new_message = rule.rule[keyRule].message.replace(
                "%s",
                label
              );
              err_msg[key].push(new_message);
              noRule += 1;
              error += 1;
            }
          }
        }
      });
    });
    return {
      fails() {
        if (error > 0) {
          return true;
        }
        return false;
      },
      getMessages() {
        return err_msg;
      }
    };
  }
};

export default validator;
