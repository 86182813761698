/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import {
  autoGrowTextArea,
  copyWritings,
  getPolicies,
  heightPercent,
  permissionPage,
  widthPercent
} from "../../../utils";
import styles from "./styles";
import "./style.css";
import { Reducers } from "../../../redux/types";
import {
  getBudgetType,
  getCurrency,
  getPopulate,
  getProject,
  getVendor,
  postBill
} from "../../../redux/actions";
import Input from "../../Input";
import { COLORS, ICONS } from "../../../configs";
import Fixed from "../../Fixed";
import Message from "../../Message";

interface Props {
  invoiceId: string;
  onCancel: () => void;
  projectId?: string;
}

const AddBill = ({ invoiceId, onCancel, projectId }: Props) => {
  const [body, setBody]: any = useState({
    trip_id: null,
    file: null,
    invoice_id: invoiceId,
    budget_id: "",
    date_now: true,
    date: null,
    date_d: "",
    date_t: "",
    category_id: "",
    currency_code: "",
    title: "Bill - ",
    description: "",
    quantity: 1,
    amount: 0,
    billable: false,
    currency_id: "",
    client_timezone: "Asia/Jakarta"
  });
  const history = useHistory();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  const myPermission = permissionPage("expense-self-edit");
  const [hourDate, setHourDate]: any[] = useState([]);
  const [isError, setError] = useState(false);
  const dispatch = useDispatch();
  const { listPopulate, currencies } = useSelector(
    (state: Reducers) => state.newExpense
  );
  const { budgetType } = useSelector((state: Reducers) => state.expense);
  const { isLoadingPostBill } = useSelector(
    (state: Reducers) => state.newExpense
  );
  const { uuid } = useSelector((state: Reducers) => state.auth);

  const cashState = useSelector((state: Reducers) => state.cash);

  const copTeks = {
    policy: copyWritings("policy"),
    finance_category: copyWritings("finance_category")
  };

  useEffect(() => {
    dispatch(getProject());
    dispatch(getVendor());
    dispatch(getPopulate());
    dispatch(getCurrency());
    if (projectId) {
      dispatch(getBudgetType(projectId));
    }
  }, [dispatch]);
  // pre merge master
  useEffect(() => {
    if (currencies.length > 0) {
      setBody({
        ...body,
        currency_code: currencies[0].attributes.real_id,
        currency_id: currencies[0].attributes.real_id
      });
    }
  }, [currencies]);
  useEffect(() => {
    if (cashState.cashDetail) {
      if (cashState.cashDetail.creator.id !== uuid || !myPermission) {
        history.push("/cash");
      }
    }
  }, [cashState.cashDetail, myPermission]);

  const previewFile = () => {
    if (
      body &&
      (body.file.type === "application/pdf" ||
        body.file.type === "application/vnd.ms-excel" ||
        body.file.type === ".doc" ||
        body.file.type === ".docx")
    ) {
      window.open(URL.createObjectURL(body.file));
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
    <div
      style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
    >
      <img src="${URL.createObjectURL(body.file)}">
    </div>`;
    }
  };

  const handleCancel = () => {
    setBody({
      trip_id: null,
      file: null,
      invoice_id: invoiceId,
      budget_id: "",
      date_now: true,
      date: "",
      date_d: "",
      date_t: "",
      category_id: "",
      currency_code: "",
      title: "Bill - ",
      description: "",
      quantity: 1,
      amount: 0,
      billable: false,
      currency_id: "",
      client_timezone: "Asia/Jakarta"
    });
    onCancel();
  };

  const handleSubmit = () => {
    if (Number(body.amount) <= 0 || !body.category_id) {
      setError(true);
    } else if (body.date_t) {
      body.date = new Date(body.date_d);
      body.date = new Date(body.date).setHours(hourDate[0]);
      body.date = new Date(body.date).setMinutes(hourDate[1]);
      body.date = new Date(body.date).toUTCString();
      dispatch(postBill(body, invoiceId, () => onCancel()));
    } else {
      dispatch(postBill(body, invoiceId, () => onCancel()));
    }
  };

  return (
    <View>
      <View
        style={{
          width: widthPercent(100) - 20,
          height: heightPercent(100) - 50,
          backgroundColor: COLORS.main
        }}
      >
        <View style={{ marginHorizontal: 20 }}>
          {getPolicies("expense-general-ca-receipt") && body.file === null ? (
            <View style={{ marginTop: 20 }}>
              <Message
                message={sprintf(
                  t("cash.new.msg.policyNewBill"),
                  copTeks.policy
                )}
              />
            </View>
          ) : null}
        </View>

        <View style={{ margin: 20 }}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <View style={{ width: "48%" }}>
              <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>
                  {copTeks.finance_category}
                </Text>
                <View
                  style={[
                    styles.card,
                    { justifyContent: "center", alignItems: "center" }
                  ]}
                >
                  <select
                    className="add-bill-dropdown"
                    onChange={(e: any) =>
                      setBody({ ...body, category_id: e.target.value })
                    }
                  >
                    <option selected hidden>
                      {sprintf(t("cash.new.select"), copTeks.finance_category)}
                    </option>
                    {listPopulate.map((expenseType: any, index: number) => (
                      <option key={index} value={expenseType.value}>
                        {expenseType.label}
                      </option>
                    ))}
                  </select>
                </View>

                {isError && !body.category_id ? (
                  <Text style={styles.validateText}>
                    {t("cash.new.msg.req")}
                  </Text>
                ) : null}
              </View>
            </View>
            <View style={{ width: "48%" }}>
              <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>{t("cash.new.date")}</Text>
                <View style={[styles.wrapSwitch]}>
                  <Switch
                    onChange={() => {
                      setBody({ ...body, date_now: !body.date_now });
                    }}
                    checked={body.date_now}
                    onColor="#50b1b1"
                    onHandleColor="#fffff7"
                    handleDiameter={19}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                    height={19}
                    width={34}
                  />
                  <Text style={styles.textSelfBooking}>
                    {body.date_now
                      ? t("cash.new.today")
                      : t("cash.new.selectDateTime")}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {body.date_now ? null : (
            <View style={styles.inputContainer}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <View style={{ width: "48%" }}>
                  <Text style={styles.labelInput}>
                    {t("cash.new.selectDate")}
                  </Text>
                  <View style={styles.card}>
                    <View style={styles.dateContainer}>
                      <input
                        onChange={e =>
                          setBody({
                            ...body,
                            date_d: new Date(e.target.value).toUTCString()
                          })
                        }
                        type="date"
                        className="add-bill-date-time"
                      />
                    </View>
                  </View>
                </View>
                <View style={{ width: "48%" }}>
                  <Text style={styles.labelInput}>
                    {t("cash.new.selectTime")}
                  </Text>
                  <View style={styles.card}>
                    <View style={styles.dateContainer}>
                      <input
                        onChange={e => {
                          const hour = e.target.value.split(":")[0];
                          const minute = e.target.value.split(":")[1];
                          setHourDate(e.target.value.split(":"));

                          const date = moment(new Date())
                            .set({
                              hour: Number(hour),
                              minute: Number(minute)
                            })
                            .format("DD-MMMM-YYYY HH:mm");
                          setBody({
                            ...body,
                            date_t: new Date(date).toUTCString()
                          });
                        }}
                        type="time"
                        className="add-bill-date-time"
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}

          <View style={styles.inputContainer}>
            <Text style={styles.labelInput}>{t("cash.new.subject")}</Text>
            <Input
              value={body.title}
              onChangeText={(e: any) => setBody({ ...body, title: e })}
              placeholder={t("cash.new.subjectPH")}
              inputStyle={styles.inputStyle}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text style={styles.labelInput}>{t("cash.new.description")}</Text>
            <textarea
              className="add-bill-description"
              style={{ width: "100%", backgroundColor: COLORS.main }}
              onChange={e => setBody({ ...body, description: e.target.value })}
              onInput={e => autoGrowTextArea(e, 38)}
            />
          </View>
          <View />
          <View
            style={[
              styles.inputContainer,
              {
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row"
              }
            ]}
          >
            <View style={{ width: "48%" }}>
              <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>
                  {t("cash.new.budgetAllocation")}
                </Text>
                <View style={[styles.card, { marginTop: 10 }]}>
                  <View style={styles.dateContainer}>
                    <select
                      style={{
                        color: "#666666",
                        fontWeight: "bold",
                        width: "90%",
                        borderWidth: 0,
                        backgroundColor: "#fffff7",
                        fontSize: 12,
                        fontFamily: "Poppins"
                      }}
                      onChange={(e: any) =>
                        setBody({ ...body, budget_id: e.target.value })
                      }
                    >
                      <option selected hidden>
                        {t("cash.new.selectBudget")}
                      </option>
                      {budgetType.map((budgetTypeData: any, index: number) => (
                        <option key={index} value={budgetTypeData.id}>
                          {budgetTypeData.attributes.title}
                        </option>
                      ))}
                    </select>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                styles.currencyContainer,
                { width: "48%", marginTop: -20 }
              ]}
            >
              <View style={{ width: "24%" }}>
                <Text style={styles.labelInput}>{t("cash.new.amount")}</Text>
                <select
                  style={{ color: "#666666", fontWeight: "bold" }}
                  className="container-content-input"
                  onChange={(e: any) =>
                    setBody({
                      ...body,
                      currency_code: e.target.value,
                      currency_id: e.target.value
                    })
                  }
                >
                  {currencies.map((currency: any, index: number) => (
                    <option key={index} value={currency.attributes.real_id}>
                      {currency.attributes.title}
                    </option>
                  ))}
                </select>
              </View>
              <View style={{ width: "73%" }}>
                <Text style={styles.labelInput}> </Text>
                <View style={[{ justifyContent: "center" }]}>
                  <TextInput
                    keyboardType="numeric"
                    value={body.amount}
                    onChange={(e: any) =>
                      setBody({ ...body, amount: e.target.value })
                    }
                    style={{
                      height: 40,
                      width: "100%",
                      paddingHorizontal: 20,
                      textAlign: "right",
                      borderBottomWidth: 1,
                      borderBottomColor: "#666666"
                    }}
                  />
                </View>
                {isError && Number(body.amount) <= 0 ? (
                  <Text style={styles.validateText}>
                    {t("cash.new.msg.cannotLowZero")}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
          <View style={[styles.inputContainer, { marginBottom: 150 }]}>
            <Text style={styles.labelInput}>{t("cash.new.receipt")}</Text>
            <View style={{ flexDirection: "row" }}>
              {body.file ? (
                <TouchableOpacity
                  style={[
                    styles.fileAttachment,
                    styles.card,
                    {
                      width: "48%",
                      height: 28,
                      borderRadius: 100,
                      alignItems: "center",
                      justifyContent: "center"
                    }
                  ]}
                  onPress={() => previewFile()}
                >
                  <View style={{ width: "90%" }}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ width: "85%" }}>
                        <Text numberOfLines={1} style={styles.attributeTask}>
                          {body.file.name}
                        </Text>
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          setBody({
                            ...body,
                            file: null
                          });
                        }}
                        style={{
                          width: "15%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Image
                          source={ICONS.iconDeleteFile}
                          resizeMode="contain"
                          style={{ width: 12, height: 12 }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableOpacity>
              ) : null}
              <TouchableOpacity style={[styles.buttonUpload]}>
                <label
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    fontSize: 11
                  }}
                >
                  <input
                    type="file"
                    // eslint-disable-next-line max-len
                    accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                      application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e: any) => {
                      setBody({
                        ...body,
                        file: e.target.files[0]
                      });
                    }}
                    onClick={(event: any) => {
                      // eslint-disable-next-line no-param-reassign
                      event.target.value = null;
                    }}
                  />
                  {t("cash.new.attachment")}
                </label>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <Fixed position="bottom">
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fffff7",
            marginRight: 10
          }}
        >
          <TouchableOpacity
            style={[
              styles.button,
              {
                marginBottom: 24,
                width: 150,
                marginTop: 10,
                height: 45,
                marginRight: 20
              }
            ]}
            onPress={() => handleSubmit()}
            disabled={isLoadingPostBill}
          >
            {isLoadingPostBill ? (
              <ActivityIndicator />
            ) : (
              <Text style={[styles.textButton, { fontSize: 12 }]}>
                {t("cash.new.add")}
              </Text>
            )}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleCancel()}>
            {!isLoadingPostBill ? (
              <Text
                style={{
                  fontWeight: "bold",
                  color: "#666666",
                  marginBottom: 24,
                  fontFamily: "Poppins",
                  marginRight: 20
                }}
              >
                {t("cash.new.cancel")}
              </Text>
            ) : null}
          </TouchableOpacity>
        </View>
      </Fixed>
    </View>
  );
};

export default AddBill;
