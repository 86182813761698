import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_SOURCE_PENDING = "GET_SOURCE_PENDING";
export const GET_SOURCE_SUCCESS = "GET_SOURCE_SUCCESS";
export const GET_SOURCE_ERROR = "GET_SOURCE_ERROR";

export const getSource = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_SOURCE_PENDING });

    const res = await API.getSource(auth.token);
    dispatch({
      type: GET_SOURCE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getSource())));
      } else {
        dispatch({
          type: GET_SOURCE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_SOURCE_ERROR });
  }
};
