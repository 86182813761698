import { StyleSheet } from "react-native";

// import { IMAGES } from "../../configs";

const font = "Poppins";
const background = "#fffff7";
const style = StyleSheet.create({
  employee: {
    width: 277,
    height: 68,
    borderRadius: 34,
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: background,
    marginHorizontal: 10,
    marginVertical: 4,
    padding: 4
  },
  photo: {
    height: 60,
    width: 60,
    resizeMode: "contain",
    borderRadius: 40
  },
  description: {
    marginLeft: 8,
    justifyContent: "center"
  },
  name: {
    fontFamily: font,
    fontWeight: "bold",
    color: "#666666",
    fontSize: 11
  },
  title: {
    fontFamily: font,
    color: "#666666",
    fontSize: 11
  },
  employeeActive: {
    width: 277,
    height: 68,
    borderRadius: 34,
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#50b1b1",
    marginHorizontal: 10,
    marginVertical: 4,
    padding: 4
  },
  nameActive: {
    fontFamily: font,
    fontWeight: "bold",
    color: "#fffff7",
    fontSize: 11
  },
  titleActive: {
    fontFamily: font,
    color: "#fffff7",
    fontSize: 11
  }
});

export default style;
