import React from "react";
import { Image, Text, View } from "react-native";

import { useTranslation } from "react-i18next";
import styles from "./styles";
import {
  CardStatusColor,
  Card as ComponentCard,
  Touchable
} from "../../../../components";
import { IMAGES } from "../../../../configs";
import { convertDate, copyWritings, maskedMoney } from "../../../../utils";

type Type = "approval" | "default" | "submitted" | "ongoing" | "history";

interface Props {
  item: any;
  index: number;
  onPress?: () => void;
  withStatus?: boolean;
  statusText?: string;
  statusColor?: CardStatusColor;
  statusDesc?: string;
  type?: Type;
  pastDueDate?: boolean;
  onPressNotif?: () => void;
  withAttch?: boolean;
}

const Card = ({
  item,
  index,
  onPress,
  withStatus,
  statusText,
  statusColor,
  statusDesc,
  type,
  pastDueDate,
  onPressNotif,
  withAttch
}: Props) => {
  const propsCard = withStatus
    ? {
        statusColor,
        statusText,
        statusDesc,
        statusStyle: {
          paddingVertical: 6,
          paddingHorizontal: 16
        },
        statusDescStyle: { fontSize: 14 }
      }
    : {};

  const { t } = useTranslation();

  const copTeks = {
    trip: copyWritings("trip"),
    project: copyWritings("project"),
    expense: copyWritings("expense")
  };

  const _datectTypeFileArray = (ext: any) => {
    if (ext.length === 0) {
      return <></>;
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "jpg" ||
          el.file.extension === "jpeg" ||
          el.file.extension === "png"
      )
    ) {
      return (
        <View style={styles.positionAttch}>
          <Image source={IMAGES.picAttach} style={{ height: 28, width: 28 }} />
        </View>
      );
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "doc" ||
          el.file.extension === "docx" ||
          el.file.extension === "pdf" ||
          el.file.extension === "xlsx"
      )
    ) {
      return (
        <View style={styles.positionAttch}>
          <Image source={IMAGES.mixed} style={{ height: 28, width: 28 }} />
        </View>
      );
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "doc" ||
          el.file.extension === "docx" ||
          el.file.extension === "pdf" ||
          el.file.extension === "xlsx" ||
          el.file.extension === "jpg" ||
          el.file.extension === "png" ||
          el.file.extension === "jpeg"
      )
    ) {
      return (
        <View style={styles.positionAttch}>
          <Image source={IMAGES.document} style={{ height: 28, width: 28 }} />
        </View>
      );
    }
    return <></>;
  };

  const _detectTypeFile = (file: any) => {
    if (file === "png" || file === "jpg" || file === "jpeg") {
      return (
        <View style={styles.positionAttch}>
          <Image source={IMAGES.picAttach} style={{ height: 28, width: 28 }} />
        </View>
      );
    }
    if (
      file === "doc" ||
      file === "docx" ||
      file === "pdf" ||
      file === "xlsx"
    ) {
      return (
        <View style={styles.positionAttch}>
          <Image source={IMAGES.document} style={{ height: 28, width: 28 }} />
        </View>
      );
    }
    return <></>;
  };

  return (
    <View>
      <Touchable onPress={onPress}>
        <ComponentCard
          cardStyle={{
            marginBottom: 15,
            marginTop: index === 0 ? 20 : 0
          }}
          {...propsCard}
        >
          <View style={{ width: "100%", paddingVertical: 14 }}>
            <View style={styles.insideCard}>
              <View style={styles.columnData}>
                <Text style={styles.keyData}>{t("task.detail.project")}</Text>
              </View>
              <View
                style={[
                  styles.columnValue,
                  withAttch && pastDueDate && { width: "60%" }
                ]}
              >
                <Text style={styles.keyData}>{item.number}</Text>
                <Text style={styles.valueData}>
                  {item.title ? item.title : "No project related"}
                </Text>
                {item.category ? (
                  <Text style={styles.keyData}>{item.category.title}</Text>
                ) : (
                  <></>
                )}
              </View>
              {item.files.length > 0
                ? _datectTypeFileArray(item.files)
                : _detectTypeFile(item.file && JSON.parse(item.file).extension)}
            </View>

            {type !== "history" && (
              <View style={styles.insideCard}>
                <View style={styles.columnData}>
                  <Text style={styles.keyData}>{copTeks.trip}</Text>
                </View>
                <View
                  style={[
                    styles.columnValue,
                    type === "ongoing" && pastDueDate && { width: "65%" }
                  ]}
                >
                  <Text style={[styles.valueData, { fontWeight: "normal" }]}>
                    {`${item.origin_location} - ${item.destination_location}`}
                  </Text>
                  <Text style={styles.keyData}>
                    {`${convertDate(
                      item.started_at,
                      "ddd DD MMM YYYY"
                    )} - ${convertDate(item.finished_at, "ddd DD MMM YYYY")}`}
                  </Text>
                </View>
              </View>
            )}
            {type === "history" && (
              <View style={styles.insideCard}>
                <View style={styles.columnData}>
                  <Text style={styles.keyData}>{copTeks.expense}</Text>
                </View>
                <View style={[styles.columnValue]}>
                  <Text style={[styles.valueData]}>
                    {`IDR ${item.expenses ? maskedMoney(item.expenses) : 0}`}
                  </Text>
                </View>
              </View>
            )}
            {type === "approval" && (
              <View style={styles.insideCard}>
                <View style={styles.columnData}>
                  <Text style={styles.keyData}>
                    {t("activity.invoice.requestedBy")}
                  </Text>
                </View>
                <View style={[styles.columnValue]}>
                  <Text style={[styles.valueData]}>
                    {item.allModeration.length > 1
                      ? item.allModeration[1].issuer.name
                      : item.allModeration[0].issuer.name}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </ComponentCard>
      </Touchable>
      {type === "ongoing" && pastDueDate && (
        <View
          style={[
            {
              position: "absolute",
              right: 5,
              top: 23,
              zIndex: 1
            },
            withAttch && { top: 10, right: 34 }
          ]}
        >
          <Touchable onPress={onPressNotif}>
            <Image source={IMAGES.pastDateNotif} style={styles.notifPastDate} />
          </Touchable>
        </View>
      )}
    </View>
  );
};

Card.defaultProps = {
  withStatus: false,
  statusText: "Status",
  statusDesc: "",
  type: "default",
  withAttch: false
};

export default Card;
