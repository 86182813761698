import React, { ReactNode, memo } from "react";
import { Text, TextStyle } from "react-native";

import { COLORS } from "../../configs";

interface Props {
  children: ReactNode;
  style?: TextStyle | TextStyle[];
  numberOfLines?: number | undefined;
  onPress?: () => void;
  type?: "light" | "reguler" | "semibold" | "bold" | "extrabold";
  color?: string;
  size?: number;
  opacity?: number;
  lineHeight?: number;
  textAlign?: "left" | "center" | "right";
  onTextLayout?: (e: any) => void;
}

const Component = ({
  style,
  children,
  numberOfLines,
  onPress,
  type,
  color,
  size,
  opacity,
  lineHeight,
  textAlign,
  onTextLayout
}: Props) => {
  const _type = () => {
    if (type === "light") {
      return "300";
    }
    if (type === "semibold") {
      return "600";
    }
    if (type === "bold") {
      return "700";
    }
    if (type === "extrabold") {
      return "800";
    }
    return "400";
  };

  return (
    <Text
      onLayout={onTextLayout}
      style={[
        {
          fontFamily: "Poppins",
          color,
          fontSize: size,
          opacity,
          lineHeight,
          textAlign,
          fontWeight: _type()
        },
        style
      ]}
      numberOfLines={numberOfLines}
      onPress={onPress}
      ellipsizeMode="tail"
    >
      {children}
    </Text>
  );
};

Component.defaultProps = {
  type: "reguler",
  color: COLORS.greyMedium,
  size: 14,
  opacity: 1,
  textAlign: "left"
};

export default memo(Component);
