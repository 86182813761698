import { Dispatch } from "../types";

export const STORE_TRIP_DATA_TEMP_NEW_BILL = "STORE_TRIP_DATA_TEMP_NEW_BILL";
export const STORE_EXPENSE_DATA_TEMP_NEW_BILL =
  "STORE_EXPENSE_DATA_TEMP_NEW_BILL";
export const STORE_LIST_BILL_NEW_BILL = "STORE_LIST_BILL_NEW_BILL";

export const storeTripDataTempNewBill = (tripData: any) => (
  dispatch: Dispatch
) => {
  dispatch({ type: STORE_TRIP_DATA_TEMP_NEW_BILL, payload: tripData });
};
export const storeExpenseDataTempNewBill = (expenseData: any) => (
  dispatch: Dispatch
) => {
  dispatch({ type: STORE_EXPENSE_DATA_TEMP_NEW_BILL, payload: expenseData });
};
export const storeListBillTempNewBill = (newBill: any) => (
  dispatch: Dispatch
) => {
  dispatch({ type: STORE_LIST_BILL_NEW_BILL, payload: newBill });
};
