import { Dimensions } from "react-native";

import { SIZE } from "../configs";

let { width } = Dimensions.get("window");

const widthPercent = (percent: number) => {
  width = width >= SIZE.maxWidth ? SIZE.maxWidth : width;
  return (percent / 100) * width;
};

export default widthPercent;
