import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  title: {
    marginTop: 36,
    fontFamily: "Poppins",
    color: "#666666",
    fontSize: 18,
    fontWeight: "bold",
    alignSelf: "center"
  },
  note: {
    padding: 10,
    borderRadius: 10,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    height: 447.5,
    marginTop: 53.2,
    marginHorizontal: 25.2,
    lineHeight: 30
  },
  btnSave: {
    paddingHorizontal: 78,
    marginTop: 16,
    alignItems: "center",
    marginBottom: 62
  }
});

export default styles;
