import { Slide, toast } from "react-toastify";

import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from "./auth";

export const GET_INVOICE_LIST_PENDING = "GET_INVOICE_LIST_PENDING";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_ERROR = "GET_INVOICE_LIST_ERROR";

export const GET_INVOICE_DETAIL_PENDING = "GET_INVOICE_DETAIL_PENDING";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";
export const GET_INVOICE_DETAIL_ERROR = "GET_INVOICE_DETAIL_ERROR";

export const GET_INVOICE_DETAIL_LIST_PENDING =
  "GET_INVOICE_DETAIL_LIST_PENDING";
export const GET_INVOICE_DETAIL_LIST_SUCCESS =
  "GET_INVOICE_DETAIL_LIST_SUCCESS";
export const GET_INVOICE_DETAIL_LIST_ERROR = "GET_INVOICE_DETAIL_LIST_ERROR";

export const REMOVE_INVOICE_DETAIL_LIST = "REMOVE_INVOICE_DETAIL_LIST";
export const CHANGE_VALUE_INVOICE_DETAIL_LIST =
  "CHANGE_VALUE_INVOICE_DETAIL_LIST";

export const POST_INVOICE_PAY_PENDING = "POST_INVOICE_PAY_PENDING";
export const POST_INVOICE_PAY_SUCCESS = "POST_INVOICE_PAY_SUCCESS";
export const POST_INVOICE_PAY_ERROR = "POST_INVOICE_PAY_ERROR";

export const GET_PAYMENT_METHOD_PENDING = "GET_PAYMENT_METHOD_PENDING";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_ERROR = "GET_PAYMENT_METHOD_ERROR";

export const GET_LIST_USER_BANK_ACCOUNT_PENDING =
  "GET_LIST_USER_BANK_ACCOUNT_PENDING";
export const GET_LIST_USER_BANK_ACCOUNT_SUCCESS =
  "GET_LIST_USER_BANK_ACCOUNT_SUCCESS";
export const GET_LIST_USER_BANK_ACCOUNT_ERROR =
  "GET_LIST_USER_BANK_ACCOUNT_ERROR";

export const GET_LIST_BANK_ACCOUNT_PENDING = "GET_LIST_BANK_ACCOUNT_PENDING";
export const GET_LIST_BANK_ACCOUNT_SUCCESS = "GET_LIST_BANK_ACCOUNT_SUCCESS";
export const GET_LIST_BANK_ACCOUNT_ERROR = "GET_LIST_BANK_ACCOUNT_ERROR";

export const GET_DESC_BANK_ACCOUNT_PENDING = "GET_DESC_BANK_ACCOUNT_PENDING";
export const GET_DESC_BANK_ACCOUNT_SUCCESS = "GET_DESC_BANK_ACCOUNT_SUCCESS";
export const GET_DESC_BANK_ACCOUNT_ERROR = "GET_DESC_BANK_ACCOUNT_ERROR";

export const CHANGE_FORM_PAYMENT = "CHANGE_FORM_PAYMENT";

export const GET_DESC_USER_BANK_ACCOUNT_PENDING =
  "GET_DESC_USER_BANK_ACCOUNT_PENDING";
export const GET_DESC_USER_BANK_ACCOUNT_SUCCESS =
  "GET_DESC_USER_BANK_ACCOUNT_SUCCESS";
export const GET_DESC_USER_BANK_ACCOUNT_ERROR =
  "GET_DESC_USER_BANK_ACCOUNT_ERROR";

export const GET_VENDOR_CONTACT_PENDING = "GET_VENDOR_CONTACT_PENDING";
export const GET_VENDOR_CONTACT_SUCCESS = "GET_VENDOR_CONTACT_SUCCESS";
export const GET_VENDOR_CONTACT_ERROR = "GET_VENDOR_CONTACT_ERROR";

export const getInvoiceList = (type: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_INVOICE_LIST_PENDING });

    const res = await API.getInvoiceList(auth.token, type);
    dispatch({
      type: GET_INVOICE_LIST_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getInvoiceList(type))));
      } else {
        dispatch({
          type: GET_INVOICE_LIST_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_INVOICE_LIST_ERROR });
  }
};

export const getInvoiceDetail = (invoiceId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_INVOICE_DETAIL_PENDING });

    const res = await API.getInvoiceDetail(invoiceId, auth.token);
    dispatch({
      type: GET_INVOICE_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getInvoiceDetail(invoiceId))));
      } else {
        dispatch({
          type: GET_INVOICE_DETAIL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_INVOICE_DETAIL_ERROR });
  }
};

export const getDescBankAccount = (id: string, invoiceId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DESC_BANK_ACCOUNT_PENDING });

    const res = await API.getDescBankAccount(id, auth.token);
    dispatch({
      type: GET_DESC_BANK_ACCOUNT_SUCCESS,
      payload: { data: res.data.data, id: invoiceId }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getDescBankAccount(id, invoiceId)))
        );
      } else {
        dispatch({
          type: GET_DESC_BANK_ACCOUNT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_DESC_BANK_ACCOUNT_ERROR });
  }
};

export const getListBankAccount = (
  invoiceId: string,
  type: "user" | "vendor",
  id: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LIST_BANK_ACCOUNT_PENDING });

    const res = await API.getListBankAccount(type, id, auth.token);
    dispatch(getDescBankAccount(res.data.data.data[0].id, invoiceId));
    dispatch({
      type: GET_LIST_BANK_ACCOUNT_SUCCESS,
      payload: { data: res.data.data.data, id: invoiceId }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getListBankAccount(invoiceId, type, id)))
        );
      } else {
        dispatch({
          type: GET_LIST_BANK_ACCOUNT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LIST_BANK_ACCOUNT_ERROR });
  }
};

export const getInvoiceDetailList = (
  invoiceId: string,
  first = false,
  cb?: (e?: any) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_INVOICE_DETAIL_LIST_PENDING });

    const res = await API.getInvoiceDetailV1(invoiceId, auth.token);
    dispatch({
      type: GET_INVOICE_DETAIL_LIST_SUCCESS,
      payload: { data: res.data }
    });
    const isVendor =
      res.data.included.findIndex((e: any) => e.type === "vendor") >= 0;
    dispatch(
      getListBankAccount(
        invoiceId,
        isVendor ? "vendor" : "user",
        isVendor
          ? res.data.included[
              res.data.included.findIndex((e: any) => e.type === "vendor")
            ].id
          : res.data.included[
              res.data.included.findIndex((e: any) => e.type === "user")
            ].id
      )
    );
    cb && cb(res.data);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getInvoiceDetailList(invoiceId))));
      } else {
        dispatch({
          type: GET_INVOICE_DETAIL_LIST_ERROR,
          payload: { data: err.response.data, isFirst: first }
        });
      }
    }
    dispatch({ type: GET_INVOICE_DETAIL_LIST_ERROR });
  }
};

export const getPaymentMethod = (cb: (e: string) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_PAYMENT_METHOD_PENDING });

    const res = await API.getPaymentMethod(auth.token);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: { data: res.data }
    });
    cb(res.data.data[0].id);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPaymentMethod(cb))));
      } else {
        dispatch({
          type: GET_PAYMENT_METHOD_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_PAYMENT_METHOD_ERROR });
  }
};

export const getListUserBankAccount = (
  uuid: string,
  cb: (value: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LIST_USER_BANK_ACCOUNT_PENDING });

    const res = await API.getListUserBankAccount(uuid, auth.token);
    cb && cb(res.data.data.data[0].id);
    dispatch({
      type: GET_LIST_USER_BANK_ACCOUNT_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getListUserBankAccount(uuid, cb))));
      } else {
        dispatch({
          type: GET_LIST_USER_BANK_ACCOUNT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LIST_USER_BANK_ACCOUNT_ERROR });
  }
};

export const getDescUserBankAccount = (accountId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DESC_USER_BANK_ACCOUNT_PENDING });

    const res = await API.getDescUserBankAccount(accountId, auth.token);

    dispatch({
      type: GET_DESC_USER_BANK_ACCOUNT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getDescUserBankAccount(accountId)))
        );
      } else {
        dispatch({
          type: GET_DESC_USER_BANK_ACCOUNT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_DESC_USER_BANK_ACCOUNT_ERROR });
  }
};

export const postInvoicePay = (body: any, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_INVOICE_PAY_PENDING });

    const formData = new FormData();

    Object.keys(body).map((item: any) => {
      if (item === "company") {
        Object.keys(body[item].account).map((e: any) => {
          formData.append(`${item}[account][${e}]`, body[item].account[e]);

          return true;
        });
      } else if (item === "subjects") {
        formData.append(item, JSON.stringify(body[item]));
      } else {
        formData.append(item, body[item]);
      }

      return true;
    });

    await API.postInvoicePay(formData, auth.token);

    cb();
    dispatch({ type: POST_INVOICE_PAY_SUCCESS });
    toast.success(i18n.t("cash.new.msg.toastSuccess"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postInvoicePay(body, cb))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });

        dispatch({
          type: POST_INVOICE_PAY_ERROR,
          payload: { data: error.data }
        });
      }
    }
    dispatch({ type: POST_INVOICE_PAY_ERROR });
  }
};

export const removeInvoiceDetailList = (index: number) => (
  dispatch: Dispatch
) => {
  dispatch({ type: REMOVE_INVOICE_DETAIL_LIST, payload: { index } });
};

export const changeValueInvoiceDetailList = (value: string, index: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: CHANGE_VALUE_INVOICE_DETAIL_LIST,
    payload: { data: value, index }
  });
};

export const changeFormPayment = (
  form: { formName: "desc" | "fee" | "file"; formValue: any },
  index: number
) => (dispatch: Dispatch) => {
  dispatch({ type: CHANGE_FORM_PAYMENT, payload: { data: form, index } });
};

export const getVendorContact = (id: string, invoiceId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_VENDOR_CONTACT_PENDING });

    const res = await API.getBookingVendorById(auth.token, id);
    dispatch({
      type: GET_VENDOR_CONTACT_SUCCESS,
      payload: { data: res.data.data.contacts, id: invoiceId }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getVendorContact(id, invoiceId))));
      } else {
        dispatch({
          type: GET_VENDOR_CONTACT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_VENDOR_CONTACT_ERROR });
  }
};
