/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  copyWritings,
  documentTitle,
  encodeBase64,
  maskedMoney,
  openPreviewBase64,
  toast
} from "../../../utils";
import {
  FileUpload,
  Fixed,
  Header,
  TextArea,
  Touchable,
  Upload
} from "../../../components";
import styles from "./styles";
import { handleFormAcitivy } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

interface InitForm {
  title: string;
  description: string;
  quantity: string;
  amount: string;
  currency_code: string;
  invoice_id: null;
  billable: boolean;
  category_id: string;
  files: string[];
}

const initForm: InitForm = {
  title: "",
  description: "",
  quantity: "1",
  amount: "",
  currency_code: "IDR",
  invoice_id: null,
  billable: false,
  category_id: "",
  files: []
};

const NewCashAdvance = () => {
  documentTitle("Cash Advance");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams() as any;
  const { sprintf } = require("sprintf-js");

  const [isError, setIsError] = useState(false);
  const [heightFoot, setHeightFoot] = useState(0);
  const [form, setForm] = useState(initForm);
  const { tripState, authState } = useSelector(
    (state: Reducers) => ({ tripState: state.trip, authState: state.auth }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      setForm({
        ...tripState.activity.data.cash_advance[id],
        file: tripState.activity.data.cash_advance[id].file || []
      });
    }
  }, [id, tripState.activity.data.cash_advance]);

  const _setForm = useCallback(
    (
      formName: "title" | "description" | "amount" | "files",
      formValue: any
    ) => {
      setForm({ ...form, [formName]: formValue });
      if (isError) {
        setIsError(false);
      }
    },
    [form, isError]
  );

  const _handleSubmit = useCallback(() => {
    if (form.title !== "" && form.amount !== "") {
      if (id) {
        const data = [...tripState.activity.data.cash_advance];
        data[id] = form;
        dispatch(handleFormAcitivy("cash_advance", data));
        toast.success("Save changes");
      } else if (
        tripState.activity.data.cash_advance &&
        tripState.activity.data.cash_advance.length > 0
      ) {
        dispatch(
          handleFormAcitivy("cash_advance", [
            form,
            ...tripState.activity.data.cash_advance
          ])
        );
        toast.success(t("activity.invoice.addSuccess"));
      } else {
        dispatch(handleFormAcitivy("cash_advance", [form]));
        toast.success(t("activity.invoice.addSuccess"));
      }
      history.goBack();
    } else {
      setIsError(true);
    }
  }, [dispatch, tripState.activity.data, form, history, id]);

  const _handleAttachFile = useCallback(
    (e: any) => {
      const files = [...e.target.files];
      let result: any = [];
      files.map(async (item, index) => {
        const base64 = await encodeBase64(item);
        result = [...result, base64];

        if (index === files.length - 1) {
          const mergeData = [...result, ...form.files];
          _setForm(
            "files",
            mergeData.filter(
              (res, i) => mergeData.findIndex(es => es === res) === i
            )
          );
        }

        return true;
      });
    },
    [_setForm, form, authState.myConfig.max_size]
  );

  const _renderAlertError = useCallback(
    (isSHow: boolean) =>
      isSHow && (
        <Text style={[styles.label, styles.labelError]}>
          {t("activity.detail.msg.req")}
        </Text>
      ),
    []
  );

  return (
    <View>
      <Header
        title={sprintf(
          t("activity.detail.newCashAdvance"),
          copyWritings("cash_advance")
        )}
        label={t("activity.status.draft")}
        textHeader={{ fontSize: 18 }}
      />
      <View style={[styles.container, { marginBottom: heightFoot }]}>
        <View
          style={[styles.row, isError && form.title === "" && styles.error]}
        >
          <Text style={styles.label}>{t("activity.new.subject")}</Text>
          <TextInput
            style={styles.textInput}
            onChangeText={e => _setForm("title", e)}
            value={form.title}
          />
          {_renderAlertError(isError && form.title === "")}
        </View>

        <View style={styles.row}>
          <Text style={styles.label}>{t("activity.new.description")}</Text>
          <TextArea
            bold
            onChange={e => _setForm("description", e.target.value)}
            value={form.description}
            border={false}
          />
        </View>

        <View style={styles.wrapTwoInput}>
          <View
            style={[
              styles.row,
              { width: "100%" },
              isError && form.amount === "" && styles.error
            ]}
          >
            <Text style={styles.label}>{t("activity.new.amount")}</Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Text style={[styles.label, { fontSize: 14, width: "25%" }]}>
                IDR
              </Text>
              <TextInput
                style={[styles.textInput, { textAlign: "right", width: "75%" }]}
                onChangeText={e => _setForm("amount", e)}
                value={form.amount}
              />
            </View>
            {_renderAlertError(isError && form.amount === "")}
          </View>
        </View>

        <View style={styles.wrapTwoInput}>
          <View style={[styles.row, { width: "45%", borderBottomWidth: 0 }]}>
            <Text style={styles.label}>{t("activity.new.total")}</Text>
          </View>

          <View
            style={[
              styles.row,
              {
                width: "45%",
                borderBottomWidth: 0,
                flexDirection: "row",
                justifyContent: "space-between"
              }
            ]}
          >
            <Text style={[styles.label, { fontSize: 14, width: "25%" }]}>
              IDR
            </Text>
            <Text
              style={[
                styles.label,
                {
                  fontSize: 14,
                  fontWeight: "bold",
                  width: "75%",
                  textAlign: "right"
                }
              ]}
            >
              {maskedMoney(Number(form.quantity) * Number(form.amount))}
            </Text>
          </View>
        </View>

        <View>
          {form.files.map((item, index) => (
            <FileUpload
              key={index}
              name={`File ${index + 1}`}
              style={{ marginBottom: 12 }}
              onPress={() => openPreviewBase64(item)}
              deleteFile={e => {
                e.stopPropagation();
                _setForm(
                  "files",
                  form.files.filter((res, i) => i !== index)
                );
              }}
            />
          ))}
          <Upload multiple onChange={_handleAttachFile} />
        </View>
      </View>

      <Fixed getHeight={e => setHeightFoot(e)} position="bottom">
        <View style={styles.footer}>
          <View style={styles.wrapButtonFoot}>
            <Touchable onPress={_handleSubmit}>
              <View style={styles.buttonFoot}>
                <Text style={styles.textButtonFoot}>
                  {id ? t("activity.new.save") : t("activity.new.add")}
                </Text>
              </View>
            </Touchable>
          </View>
        </View>
      </Fixed>
    </View>
  );
};

export default NewCashAdvance;
