import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { saveRoute } from "../redux/actions";

interface Props {
  children: ReactNode;
}

const blackList = [
  "/intro",
  "/intro/second",
  "/intro/third",
  "/register",
  "/register/phone-number",
  "/register/verification",
  "/register/company",
  "/register/success",
  "/login",
  "/"
];

const SaveRoute = ({ children }: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!blackList.includes(location.pathname)) {
      dispatch(saveRoute(location.pathname + location.search));
    }
  }, [location.pathname, dispatch, location.search]);

  return <>{children}</>;
};

export default SaveRoute;
