import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import "./style.css";
import { Datepicker, Select, Touchable } from "../../../components";
import { maskedMoney, utcDate } from "../../../utils";
import { NotifError } from "../components";

// redux action & types
import {
  handleResetFlight,
  setFormNewBookingFlight
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const listFacility = [
  { label: "No Luggage", value: "0" },
  { label: "Luggage 10Kg", value: "10" },
  { label: "Luggage 20Kg", value: "20" }
];

const selectClass = [
  { label: "Economy", value: "economy" },
  { label: "Any", value: "any" }
];

interface Props {
  isError: boolean;
}

const OneWay = ({ isError }: Props) => {
  const dispatch = useDispatch();
  const { dataState, regionState } = useSelector(
    (state: Reducers) => ({
      dataState: state.newBookingFlight,
      regionState: state.region
    }),
    shallowEqual
  );

  const regionPopulate = regionState.region.map((e: any) => {
    return { value: e.id, label: e.name };
  });

  const [flight, setFlight] = useState([
    {
      title: "Flight 1",
      cityFrom: "",
      cityFromId: "",
      cityTo: "",
      cityToId: "",
      regionFrom: "Soekarno Hatta",
      regionTo: "Ngurah Rai",
      dateFrom: "",
      dateTo: "",
      timeFrom: "",
      timeTo: ""
    }
  ]);

  const getMonth = (m: number) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[m];
  };

  const setForm = (
    formName: string,
    value: number | string | boolean,
    index: number
  ) => {
    dispatch(setFormNewBookingFlight({ formName, value }, index));
  };

  const [showDatepicker, setShowDatepicker] = useState([false, false]);
  const conv = (f: any) => {
    let n: any = [];
    f.forEach(
      (item: {
        dateFrom: string;
        cityFrom: string;
        cityFromId: string;
        timeFrom: string;
        cityToId: string;
        cityTo: string;
        timeTo: string;
      }) => {
        n = [
          ...n,
          {
            date: item.dateFrom,
            departure: {
              location: item.cityFrom,
              id: item.cityFromId,
              time: item.timeFrom
            },
            arrival: {
              location: item.cityTo,
              id: item.cityToId,
              time: item.timeFrom
            }
          }
        ];
      }
    );
    return n;
  };

  useEffect(() => {
    dispatch(handleResetFlight());
  }, [dispatch]);

  const onChangeDatePicker = (value: any) => {
    // eslint-disable-next-line prefer-const
    let f = flight;

    f[0].cityFrom = value[0].departure.location;
    f[0].cityFromId = value[0].departure.id;
    f[0].cityTo = value[0].arrival.location;
    f[0].cityToId = value[0].arrival.id;
    f[0].timeFrom = value[0].departure.time || "any";
    f[0].dateFrom = value[0].date;

    dispatch(
      setFormNewBookingFlight(
        {
          formName: "started_at_t",
          value: value[0].departure.time || "any"
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "started_at_t",
          value: value[0].departure.time || "any"
        },
        1
      )
    );

    dispatch(
      setFormNewBookingFlight(
        {
          formName: "finished_at_t",
          value: value[0].arrival.time || "any"
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "finished_at_t",
          value: value[0].arrival.time || "any"
        },
        1
      )
    );

    dispatch(
      setFormNewBookingFlight(
        {
          formName: "from",
          value: value[0].departure.id
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight({ formName: "to", value: value[0].arrival.id }, 0)
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "started_at_d",
          value: utcDate(`${value[0].date} 00:00`)
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "finished_at_d",
          value: utcDate(`${value[0].date} 00:00`)
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "started_at",
          value: utcDate(`${value[0].date} 00:00`)
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "finished_at",
          value: utcDate(`${value[0].date} 00:00`)
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "is_round_trip",
          value: false
        },
        0
      )
    );
    setFlight(f);
    setFlight(f);
  };

  const _setLuggage = (value: string, i: number) => {
    if (value === "0") {
      setForm("baggage", value, i);
      setForm("has_baggage", false, i);
    } else if (value !== "0") {
      setForm("baggage", value, i);
      setForm("has_baggage", true, i);
    }
  };

  const dateFormated = (date: string) => {
    const dt = new Date(date);
    return `${dt.getDate()} ${getMonth(dt.getMonth())}`;
  };

  const renderHeaderDatepicker = (
    nav: number,
    setNav: (number: number) => void
  ) => (
    <View style={styles.datePickerNavWrap}>
      <View style={[styles.datePickerNav]}>
        <Touchable onPress={() => (setNav ? setNav(0) : null)}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "bold" }}>Flight 1</Text>
          </View>
        </Touchable>
      </View>
    </View>
  );

  const _notifError = (showError: boolean, textError: string) => (
    <NotifError showError={isError && showError} textError={textError} />
  );
  const handleEllipsisText = (text: string) => {
    let res = "";
    if (text.length > 10) {
      res = text.slice(0, 9);
      res += "...";
    } else {
      res = text;
    }
    return res;
  };

  return (
    <View>
      {flight.map((item, index) => {
        return (
          <View
            style={{ marginBottom: 27, zIndex: flight.length - index }}
            key={index}
          >
            <Datepicker
              flightOneWay
              validate
              value={conv(flight)}
              style={{ zIndex: 1 }}
              travelingV2
              region={regionPopulate}
              visible={showDatepicker[index]}
              onChangeVisible={e => {
                const val = showDatepicker;
                val[index] = e;
                setShowDatepicker([...showDatepicker]);
              }}
              showNav={index}
              head={renderHeaderDatepicker}
              onChange={onChangeDatePicker}
              flight
            />
            <View style={styles.wrapFormFlight}>
              <Text style={styles.titleTrip}>{item.title}</Text>
              <View style={styles.formFlight}>
                <View style={styles.rowFormFlight}>
                  <View style={styles.wrapInput}>
                    <Text style={styles.textTitleInput}>Airlines</Text>
                    <View style={styles.wrapSelect}>
                      {!dataState.isLoadingAirline &&
                      dataState.listAirline.length > 0 ? (
                        <Select
                          listValue={dataState.listAirline}
                          defaultValue={() =>
                            setForm(
                              "airline_id",
                              dataState.listAirline[0].value,
                              0
                            )
                          }
                          value={dataState.form.airline_id}
                          onChange={e =>
                            setForm("airline_id", e.target.value, 0)
                          }
                        />
                      ) : (
                        <ActivityIndicator color="#344370" />
                      )}
                    </View>
                  </View>
                  <View style={[styles.wrapInput, { marginLeft: 30 }]}>
                    <Text style={styles.textTitleInput}>Class</Text>
                    <View style={styles.wrapSelect}>
                      <Select
                        listValue={selectClass}
                        value={dataState.form.class_id}
                        onChange={e => setForm("class_id", e.target.value, 0)}
                      />
                    </View>
                  </View>
                </View>
                <View style={[styles.rowFormFlight, { marginTop: 14 }]}>
                  <View style={styles.wrapInput}>
                    <Text style={styles.textTitleInput}>
                      Luggage
                      <Text
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 11,
                          fontStyle: "italic"
                        }}
                      >
                        {`${" "}(optional)`}
                      </Text>
                    </Text>
                    <View style={styles.wrapSelect}>
                      <Select
                        listValue={listFacility}
                        onChange={e => _setLuggage(e.target.value, index)}
                      />
                    </View>
                  </View>
                  <View style={[styles.wrapInput, { marginLeft: 30 }]}>
                    <Text style={styles.textTitleInput}>Budget Allocation</Text>
                    <View style={{ marginTop: 3 }}>
                      <Text style={styles.textTitleInput}>
                        IDR
                        <Text
                          style={[
                            styles.textTitleInput,
                            { fontFamily: "Poppins", fontWeight: "bold" }
                          ]}
                        >
                          {` ${maskedMoney(3500000)}`}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <Touchable
              onPress={() => {
                const val = showDatepicker;
                val[index] = !val[index];
                setShowDatepicker([...showDatepicker]);
              }}
            >
              <>
                <div className="box-one">
                  <div>
                    <div>
                      <p className="city-from-one">
                        {handleEllipsisText(item.cityFrom) || "-"}
                      </p>
                      <p className="region-one">
                        {handleEllipsisText(item.cityFrom) || "-"}
                      </p>
                    </div>
                    <div>
                      <p className="city-from-one">
                        {item.dateFrom === ""
                          ? "-"
                          : dateFormated(item.dateFrom)}
                      </p>
                      <p className="region-one">
                        {item.timeFrom === "" ? "-" : item.timeFrom}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div>
                      <p className="city-from-one city-to-one">
                        {handleEllipsisText(item.cityTo) || "-"}
                      </p>
                      <p className="region-one">
                        {handleEllipsisText(item.cityTo) || "-"}
                      </p>
                    </div>
                    <div>
                      <p className="city-from-one city-to-one">-</p>
                      <p className="region-one">-</p>
                    </div>
                  </div>
                </div>
              </>
            </Touchable>
            {_notifError(dataState.form.from === "", "From is Required")}
            {_notifError(dataState.form.to === "", "To is Required")}
            {_notifError(
              dataState.form.started_at_d === "",
              "Date is Required"
            )}
          </View>
        );
      })}

      {/* <View>
        <Text style={styles.flightTitle}>Facility</Text>
        <FlatList
          numColumns={2}
          data={listFacility}
          keyExtractor={(item, index) => index.toString()}
          extraData={dataState}
          renderItem={({ item }) => (
            <View
              style={[
                styles.buttonFacility,
                item.value === dataState.form.baggage && {
                  backgroundColor: "#50b1b1"
                }
              ]}
            >
              <Touchable onPress={() => setForm("baggage", item.value)}>
                <View style={{ paddingVertical: 10, paddingLeft: 15 }}>
                  <Text
                    style={
                      item.value === dataState.form.baggage
                        ? { color: "white" }
                        : { color: "#666666" }
                    }
                  >
                    {item.facility}
                  </Text>
                </View>
              </Touchable>
            </View>
          )}
        />
      </View> */}
    </View>
  );
};

export default OneWay;
