import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  card: {
    paddingRight: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1
  },
  row: {
    marginVertical: 5,
    marginHorizontal: 20,
    flexDirection: "row"
  },
  text: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11
  },
  desc: {
    color: "#666666",
    fontFamily: "Poppins",
    fontSize: 11
  },
  columnDesc: {
    width: "70%",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  textStatus: {
    marginLeft: 20,
    fontWeight: "bold",
    fontSize: 11,
    color: "#fffff7",
    fontFamily: "Poppins"
  },
  textDesc: {
    paddingVertical: 10,
    marginRight: 20,
    fontSize: 18,
    fontWeight: "bold",
    color: "#fffff7",
    fontFamily: "Poppins"
  },
  footerCard: {
    width: "100%",
    backgroundColor: "#50b1b1",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  budgetRequest: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 15
  }
});

export default styles;
