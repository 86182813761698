import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapButton: {
    alignItems: "center",
    marginVertical: 100
  },
  buttonSignup: {
    marginTop: 12
  },
  textSignup: {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ed5a28",
    textDecorationLine: "underline"
  }
});

export default styles;
