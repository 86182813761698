/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  // TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import { useTranslation } from "react-i18next";
import styles from "./styles";

import { Fixed, Header, Input } from "../../components";
import { IMAGES } from "../../configs";
import { widthPercent } from "../../utils";
import {
  // getDepartment,
  // editProfile,
  getMyFullProfile,
  getPosition,
  getRegency
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state: Reducers) => state.auth);
  const employee = useSelector((state: Reducers) => state.employee);
  const regency = useSelector((state: Reducers) => state.regency);
  const { t } = useTranslation();
  const [inputCity, setInputCity] = useState("");
  // const [inputPosition, setInputPosition] = useState("");
  const [body, setBody]: any = useState();
  // const [visibleDropdown, setVisibleDropdown] = useState(false);
  // const [visibleDropdownPos, setVisibleDropdownPos] = useState(false);
  // const [department, setDepartment] = useState("");
  const [isError] = useState(false);
  // const [isError, setError] = useState(false);

  // const [image, setFileImage]: any = useState(null);
  // const liatGambar = (file: any) => {
  //   setFileImage(URL.createObjectURL(file));
  //   setBody({ ...body, avatar: file });
  // };

  useEffect(() => {
    dispatch(getMyFullProfile(userId));
    // dispatch(getDepartment());
    dispatch(getRegency());
    dispatch(getPosition());
  }, [dispatch]);

  const _handleUnFocusInputCity = () => {
    const resFilter = regency.regencies.filter(
      (city: any) =>
        city.attributes.name.toLowerCase() === inputCity.toLowerCase()
    );
    if (resFilter.length === 0) {
      setInputCity("");
      setBody({ ...body, "addresses[0][regency_id]": "" });
    } else {
      setBody({ ...body, "addresses[0][regency_id]": resFilter[0].id });
    }
    // setVisibleDropdown(false);
  };

  // const _handleUnFocusInputPosition = () => {
  //   const resFilter = employee.position.filter(
  //     (position: any) =>
  //       position.title.toLowerCase() === inputPosition.toLowerCase()
  //   );
  //   if (resFilter.length === 0) {
  //     setInputPosition("");
  //     setBody({ ...body, position_id: "" });
  //     setDepartment("");
  //   } else {
  //     setBody({ ...body, position_id: resFilter[0].id });
  //     setBody({ ...body, department_id: resFilter[0].department.id });
  //     setDepartment(resFilter[0].department.title);
  //   }
  //   setVisibleDropdownPos(false);
  // };

  // const _handleSave = () => {
  //   if (
  //     inputCity === "" ||
  //     body.number === "" ||
  //     body.name === "" ||
  //     body.pob === "" ||
  //     body.dob === "" ||
  //     body["addresses[0][address]"] === "" ||
  //     body["addresses[0][zip]"] === null ||
  //     body["addresses[0][phone]"] === null
  //   ) {
  //     setError(true);
  //   } else {
  //     dispatch(editProfile(body, userId));
  //   }
  // };

  useEffect(() => {
    if (employee.myFullProfile && regency.regencies && employee.position) {
      setBody({
        gender: "male",
        // department_id: employee.myFullProfile.department.id,
        // position_id: employee.myFullProfile.position.id,
        number: employee.myFullProfile.number,
        "addresses[0][id]": employee.myFullProfile.addresses[0].id,
        "addresses[0][regency_id]":
          employee.myFullProfile.addresses[0].address.regency.id,
        name: employee.myFullProfile.name,
        pob: employee.myFullProfile.primaryUser.pob,
        dob: employee.myFullProfile.primaryUser.dob,
        "addresses[0][address]":
          employee.myFullProfile.addresses[0].address.address,
        "addresses[0][zip]": employee.myFullProfile.addresses[0].address.zip,
        "addresses[0][phone]":
          employee.myFullProfile.addresses[0].address.phone,
        "addresses[0][fax]": employee.myFullProfile.addresses[0].address.fax,
        email: employee.myFullProfile.primaryUser.email,
        "idNumber[number]": employee.myFullProfile.idNumber
          ? employee.myFullProfile.idNumber.number
          : "",
        password: null,
        "passport[number]": employee.myFullProfile.passport
          ? employee.myFullProfile.passport.number
          : "",
        "passport[expired_at]": employee.myFullProfile.passport
          ? employee.myFullProfile.passport.expired_at
          : "",
        "taxNumber[number]": employee.myFullProfile.taxNumber
          ? employee.myFullProfile.taxNumber.number
          : "",
        hidden: employee.myFullProfile.is_hidden,
        active: true
      });

      const filteredRegency = regency.regencies.filter(
        city =>
          city.id === employee.myFullProfile.addresses[0].address.regency.id
      );

      if (filteredRegency.length > 0) {
        setInputCity(filteredRegency[0].attributes.name);
      }
    }
  }, [employee, regency]);

  return (
    <div>
      <Header title={t("profile.editProfile")} />
      {employee.myFullProfile &&
      body &&
      regency.regencies.length > 0 &&
      employee.position ? (
        <View
          style={{
            width: widthPercent(100),
            justifyContent: "center"
          }}
        >
          <View
            style={{
              marginHorizontal: 30,
              flexDirection: "row",
              marginTop: 24
            }}
          >
            <View
              style={{
                width: 120
              }}
            >
              {/* <View style={styles.pencil}>
                <label>
                  <input
                    type="file"
                    accept=" image/jpeg, image/png, image/jpg"
                    onChange={(e: any) => {
                      liatGambar(e.target.files[0]);
                    }}
                    onClick={(event: any) => {
                      // eslint-disable-next-line no-param-reassign
                      event.target.value = null;
                    }}
                  />
                  <Image
                    source={IMAGES.pencil}
                    style={{ width: 18, height: 18 }}
                  />
                </label>
              </View> */}
              <View style={styles.pp}>
                <Image
                  source={
                    // image || employee.myFullProfile.avatar.url || IMAGES.avatar
                    employee.myFullProfile.avatar
                      ? employee.myFullProfile.avatar.url
                      : IMAGES.avatar
                  }
                  style={{ borderRadius: 100, width: 100, height: 100 }}
                />
              </View>
            </View>
            <div className="width-container">
              <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>{t("profile.name")}</Text>
                <Input
                  readonly
                  value={body.name}
                  placeholder="-"
                  inputStyle={styles.inputStyle}
                  onChangeText={(e: any) => setBody({ ...body, name: e })}
                />
                {(body.name === "" || body.name === null) && isError ? (
                  <Text style={{ color: "red", fontSize: 11 }}>
                    {t("profile.req")}
                  </Text>
                ) : null}
              </View>
              {/* <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>Departement</Text>
                <Input
                  value={department}
                  readonly
                  inputStyle={styles.inputStyle}
                />
              </View> */}
            </div>
          </View>
          <View
            style={{
              marginHorizontal: 30,
              marginTop: 12
            }}
          >
            {/* disini */}
            {/* <View style={styles.inputContainer}>
              <Text style={styles.labelInput}>Position</Text>
              <Input
                readonly
                onFocus={() => setVisibleDropdownPos(true)}
                onChangeText={(e: any) => setInputPosition(e)}
                value={inputPosition}
                placeholder="Input Position"
                inputStyle={styles.inputStyle}
                onBlur={() => _handleUnFocusInputPosition()}
              />
              {(inputPosition === "" || inputPosition === null) && isError ? (
                <Text style={{ color: "red", fontSize: 11 }}>
                  this field is required
                </Text>
              ) : null}
            </View> */}
            {/* {visibleDropdownPos ? (
              <View style={[styles.containerAutoComplete, { zIndex: 4 }]}>
                <View style={styles.cardAutoComplete}>
                  {employee.position
                    .filter(
                      (position: any) =>
                        position.title
                          .toLowerCase()
                          .substring(0, inputPosition.length) ===
                        inputPosition.toLowerCase()
                    )
                    .map((position: any, index: number) => (
                      <TouchableOpacity
                        key={index}
                        style={{
                          width: "100%",
                          height: 40,
                          justifyContent: "center"
                        }}
                        onPress={() => {
                          setInputPosition(position.title);
                          setVisibleDropdownPos(false);
                        }}
                      >
                        <Text style={{ marginHorizontal: 10 }}>
                          {position.title}
                        </Text>
                      </TouchableOpacity>
                    ))}
                </View>
              </View>
            ) : null} */}
          </View>

          <View
            style={{
              marginHorizontal: 30,
              zIndex: -1
            }}
          >
            <View style={styles.inputContainer}>
              <Text style={styles.labelInput}>{t("profile.email")}</Text>
              <Input
                readonly
                value={body.email}
                onChangeText={e => setBody({ ...body, email: e })}
                placeholder="-"
                inputStyle={styles.inputStyle}
              />
              {(body.email === "" || body.email === null) && isError ? (
                <Text style={{ color: "red", fontSize: 11 }}>
                  {t("profile.req")}
                </Text>
              ) : null}
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelInput}>{t("profile.password")}</Text>
              <Input
                readonly
                securityText
                inputStyle={styles.inputStyle}
                value={body.password}
                onChangeText={e => setBody({ ...body, password: e })}
              />
            </View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <View style={[styles.inputContainer, { width: "48%" }]}>
                <Text style={styles.labelInput}>{t("profile.placeBirth")}</Text>
                <Input
                  readonly
                  onChangeText={e => setBody({ ...body, pob: e })}
                  value={body.pob}
                  placeholder="-"
                  inputStyle={styles.inputStyle}
                />
                {(body.pob === "" || body.pob === null) && isError ? (
                  <Text style={{ color: "red", fontSize: 11 }}>
                    {t("profile.req")}
                  </Text>
                ) : null}
              </View>
              <View style={[styles.inputContainer, { width: "48%" }]}>
                <Text style={styles.labelInput}>{t("profile.dateBirth")}</Text>
                <input
                  readOnly
                  onChange={(e: any) =>
                    setBody({ ...body, dob: e.target.value })
                  }
                  value={body.dob}
                  type="date"
                  style={{
                    backgroundColor: "transparent",
                    fontFamily: "Poppins",
                    borderWidth: 0,
                    marginTop: 8,
                    fontSize: 12,
                    color: "#666666",
                    fontWeight: "bold"
                  }}
                />
                <View
                  style={{
                    height: 1,
                    marginTop: 5,
                    width: "100%",
                    backgroundColor: "#999999"
                  }}
                />
                {(body.dob === "" || body.dob === null) && isError ? (
                  <Text style={{ color: "red", fontSize: 11 }}>
                    {t("profile.req")}
                  </Text>
                ) : null}
              </View>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.labelInput}>{t("profile.staffID")}</Text>
              <Input
                readonly
                value={body.number}
                onChangeText={e => setBody({ ...body, number: e })}
                placeholder="-"
                inputStyle={styles.inputStyle}
              />
              {(body.number === "" || body.number === null) && isError ? (
                <Text style={{ color: "red", fontSize: 11 }}>
                  {t("profile.req")}
                </Text>
              ) : null}
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelInput}>{t("profile.address")}</Text>
              <Input
                readonly
                onChangeText={e =>
                  setBody({ ...body, "addresses[0][address]": e })
                }
                value={body["addresses[0][address]"]}
                placeholder="-"
                inputStyle={styles.inputStyle}
              />
              {(body["adresses[0][]address"] === "" ||
                body["adresses[0][]address"] === null) &&
              isError ? (
                <Text style={{ color: "red", fontSize: 11 }}>
                  {t("profile.req")}
                </Text>
              ) : null}
            </View>
          </View>
          <View style={{ marginHorizontal: 30 }}>
            <View style={[styles.inputContainer, { zIndex: 3 }]}>
              <Text style={styles.labelInput}>{t("profile.city")}</Text>
              <Input
                readonly
                placeholder="-"
                inputStyle={[styles.inputStyle]}
                // onFocus={() => setVisibleDropdown(true)}
                onBlur={() => _handleUnFocusInputCity()}
                value={inputCity}
                onChangeText={(e: any) => setInputCity(e)}
              />
              {/* {(inputCity === "" || inputCity === null) && isError ? (
                <Text style={{ color: "red", fontSize: 11 }}>
                  this field is required
                </Text>
              ) : null}
              {visibleDropdown ? (
                <View style={[styles.containerAutoComplete, { zIndex: 3 }]}>
                  <View style={styles.cardAutoComplete}>
                    {regency.regencies
                      .filter(
                        (city: any) =>
                          city.attributes.name
                            .toLowerCase()
                            .substring(0, inputCity.length) ===
                          inputCity.toLowerCase()
                      )
                      .map((city: any, index: number) => (
                        <TouchableOpacity
                          key={index}
                          style={{
                            width: "100%",
                            height: 40,
                            justifyContent: "center"
                          }}
                          onPress={() => {
                            setInputCity(city.attributes.name);
                            setVisibleDropdown(false);
                          }}
                        >
                          <Text style={{ marginHorizontal: 10 }}>
                            {city.attributes.name}
                          </Text>
                        </TouchableOpacity>
                      ))}
                  </View>
                </View>
              ) : null}
            </View>
          </View>
          <View style={{ zIndex: -1, marginHorizontal: 30 }}>
            <View>
              <Text style={styles.labelInput}>Phone Number</Text>
              <Input
                readonly
                keyboardType="numeric"
                value={body["addresses[0][phone]"]}
                onChangeText={e =>
                  setBody({ ...body, "addresses[0][phone]": e })
                }
                placeholder="input Phone Number"
                inputStyle={styles.inputStyle}
              />
              {(body["addresses[0][phone]"] === "" ||
                body["addresses[0][phone]"] === null) &&
              isError ? (
                <Text style={{ color: "red", fontSize: 11 }}>
                  this field is required
                </Text>
              ) : null} */}
            </View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <View style={[styles.inputContainer, { width: "48%" }]}>
                <Text style={styles.labelInput}>{t("profile.zipCode")}</Text>
                <Input
                  readonly
                  keyboardType="numeric"
                  value={body["addresses[0][zip]"]}
                  onChangeText={e =>
                    setBody({ ...body, "addresses[0][zip]": e })
                  }
                  placeholder="-"
                  inputStyle={styles.inputStyle}
                />
                {(body["addresses[0][zip]"] === "" ||
                  body["addresses[0][zip]"] === null) &&
                isError ? (
                  <Text style={{ color: "red", fontSize: 11 }}>
                    {t("profile.req")}
                  </Text>
                ) : null}
              </View>
              <View style={[styles.inputContainer, { width: "48%" }]}>
                <Text style={styles.labelInput}>{t("profile.faxNumber")}</Text>
                <Input
                  readonly
                  keyboardType="numeric"
                  value={body["addresses[0][fax]"]}
                  onChangeText={e =>
                    setBody({ ...body, "addresses[0][fax]": e })
                  }
                  placeholder="-"
                  inputStyle={styles.inputStyle}
                />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelInput}>
                {t("profile.identificationNumber")}
              </Text>
              <Input
                readonly
                keyboardType="numeric"
                value={body["idNumber[number]"]}
                placeholder="Identification number"
                onChangeText={e => setBody({ ...body, "idNumber[number]": e })}
                inputStyle={styles.inputStyle}
              />
            </View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <View style={[styles.inputContainer, { width: "48%" }]}>
                <Text style={styles.labelInput}>
                  {t("profile.passportNumber")}
                </Text>
                <Input
                  readonly
                  keyboardType="numeric"
                  value={body["passport[number]"]}
                  onChangeText={e =>
                    setBody({ ...body, "passport[number]": e })
                  }
                  placeholder="-"
                  inputStyle={styles.inputStyle}
                />
              </View>
              <View style={[styles.inputContainer, { width: "48%" }]}>
                <Text style={styles.labelInput}>
                  {t("profile.expiredDate")}
                </Text>
                {body["passport[expired_at]"] ? (
                  <>
                    <input
                      readOnly
                      value={body["passport[expired_at]"]}
                      onChange={(e: any) =>
                        setBody({
                          ...body,
                          "passport[expired_at]": e.target.value
                        })
                      }
                      type="date"
                      style={{
                        backgroundColor: "transparent",
                        fontFamily: "Poppins",
                        borderWidth: 0,
                        marginTop: 8,
                        fontSize: 12,
                        color: "#666666",
                        fontWeight: "bold"
                      }}
                    />
                    <View
                      style={{
                        height: 1,
                        marginTop: 5,
                        width: "100%",
                        backgroundColor: "#999999"
                      }}
                    />
                  </>
                ) : (
                  <Input
                    readonly
                    keyboardType="numeric"
                    value={body["passport[number]"]}
                    onChangeText={e =>
                      setBody({ ...body, "passport[number]": e })
                    }
                    placeholder="-"
                    inputStyle={styles.inputStyle}
                  />
                )}
              </View>
            </View>
            <View style={[styles.inputContainer, { marginBottom: 80 }]}>
              <Text style={styles.labelInput}>{t("profile.taxNumber")}</Text>
              <Input
                readonly
                keyboardType="numeric"
                onChangeText={e => setBody({ ...body, "taxNumber[number]": e })}
                value={body["taxNumber[number]"]}
                placeholder="-"
                inputStyle={styles.inputStyle}
              />
            </View>
          </View>

          <Fixed position="bottom">
            {/* <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                paddingBottom: 10
              }}
            >
              <Button
                style={{ width: 182 }}
                title="SAVE"
                onPress={() => {
                  _handleSave();
                }}
              />
            </View> */}
          </Fixed>
        </View>
      ) : (
        <ActivityIndicator />
      )}
    </div>
  );
};

export default EditProfile;
