import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { Container, Fixed, Header } from "../../../components";
import { getTripById } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { queryString } from "../../../utils";
// import { CustomText } from "../../../components";

import Expense from "../Expense";

import styles from "./styles";

const Component = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const tripState = useSelector((state: Reducers) => state.trip);
  const { search, state } = history.location as any;
  const { id } = useParams() as any;
  const param = queryString(search);

  const [heightHeader, setHeightHeader] = useState(0);

  useEffect(() => {
    dispatch(getTripById(id));
  }, [dispatch, id]);

  const _titleHeader = () => {
    let title = "Loading...";
    if (tripState.tripData) {
      title = tripState.tripData.title;
    }
    return title;
  };

  const _handleGoBack = () => {
    if (param.type === "upcoming") {
      history.push("/trip");
    } else {
      history.push(`/trip/${param.type}`);
    }
  };

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (state) {
      status = state.labelStatus;
    } else if (tripState.tripData) {
      status = tripState.tripData.status;
    }

    if (status === 1) {
      desc = t("activity.status.draft");
    } else if (status === 2) {
      desc = t("activity.status.needApproval");
    } else if (status === 3) {
      desc = t("activity.status.approved");
    } else if (status === 4) {
      desc = t("activity.status.revise");
    } else if (status === 5) {
      desc = t("activity.status.ongoing");
    } else if (status === 6) {
      desc = t("activity.status.finished");
    } else if (status === 7) {
      desc = t("activity.status.rejected");
    } else if (status === 8) {
      desc = t("cash.status.overdue");
    }

    return desc;
  };

  return (
    <Container>
      <Fixed getHeight={value => setHeightHeader(value)}>
        <Header
          title={_titleHeader()}
          textHeader={styles.textHeader}
          goBack={_handleGoBack}
          label={_statusDesc()}
        />
      </Fixed>
      <View>
        <Expense
          id={id!}
          search={search}
          top={heightHeader + 20}
          offsetTop={heightHeader + 20}
          isBillApproval
        />
      </View>
    </Container>
  );
};

export default Component;
