import { Slide, toast } from "react-toastify";
import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { BodyPostNewExpense } from "../../configs/api";
import { handleLogin } from ".";

export const GET_POPULATE_PENDING = "GET_POPULATE_PENDING";
export const GET_POPULATE_SUCCESS = "GET_POPULATE_SUCCESS";
export const GET_POPULATE_ERROR = "GET_POPULATE_ERROR";

export const GET_CURRENCY_PENDING = "GET_CURRENCY_PENDING";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_ERROR = "GET_CURRENCY_ERROR";

export const POST_NEW_EXPENSE_PENDING = "POST_NEW_EXPENSE_PENDING";
export const POST_NEW_EXPENSE_SUCCESS = "POST_NEW_EXPENSE_SUCCESS";
export const POST_NEW_EXPENSE_ERROR = "POST_NEW_EXPENSE_ERROR";

export const POST_BILL_PENDING = "POST_BILL_PENDING";
export const POST_BILL_SUCCESS = "POST_BILL_SUCCESS";
export const POST_BILL_ERROR = "POST_BILL_ERROR";

export const GET_TAX_LIST_PENDING = "GET_TAX_LIST_PENDING";
export const GET_TAX_LIST_SUCCESS = "GET_TAX_LIST_SUCCESS";
export const GET_TAX_LIST_ERROR = "GET_TAX_LIST_ERROR";

export const GET_BRAND_POPULATE_PENDING = "GET_BRAND_POPULATE_PENDING";
export const GET_BRAND_POPULATE_SUCCESS = "GET_BRAND_POPULATE_SUCCESS";
export const GET_BRAND_POPULATE_ERROR = "GET_BRAND_POPULATE_ERROR";

export const GET_BRANCH_POPULATE_PENDING = "GET_BRANCH_POPULATE_PENDING";
export const GET_BRANCH_POPULATE_SUCCESS = "GET_BRANCH_POPULATE_SUCCESS";
export const GET_BRANCH_POPULATE_ERROR = "GET_BRANCH_POPULATE_ERROR";

export const GET_PRODUCT_POPULATE_PENDING = "GET_PRODUCT_POPULATE_PENDING";
export const GET_PRODUCT_POPULATE_SUCCESS = "GET_PRODUCT_POPULATE_SUCCESS";
export const GET_PRODUCT_POPULATE_ERROR = "GET_PRODUCT_POPULATE_ERROR";

declare const alert: (param: any) => void;

export const getPopulate = (policy?: "Reimburse" | "") => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_POPULATE_PENDING });

    const res =
      policy === "Reimburse"
        ? await API.getFinanceCategoryReimburse(auth.token)
        : await API.getFinanceCategory(auth.token);
    dispatch({
      type: GET_POPULATE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPopulate())));
      } else {
        dispatch({
          type: GET_POPULATE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_POPULATE_ERROR });
  }
};

export const getCurrency = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_CURRENCY_PENDING });

    const res = await API.getCurrency(auth.token);
    dispatch({
      type: GET_CURRENCY_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCurrency())));
      } else {
        dispatch({
          type: GET_CURRENCY_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_CURRENCY_ERROR });
  }
};

export const postNewExpense = (
  form: BodyPostNewExpense,
  cb: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_EXPENSE_PENDING });

    const res = await API.postNewExpense(form, auth.token);
    dispatch({
      type: POST_NEW_EXPENSE_SUCCESS,
      payload: { data: res.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewExpense(form, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_EXPENSE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_NEW_EXPENSE_ERROR });
  }
};

export const postBill = (body: any, id: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_BILL_PENDING });
    const realBody = body;

    const formData = new FormData();

    if (body.date_now) {
      realBody.date = null;
      realBody.date_d = null;
      realBody.date_t = null;
    }

    Object.keys(realBody).map(attribute => {
      if (realBody[attribute] !== null) {
        formData.append(attribute, realBody[attribute]);
      } else {
        formData.append(attribute, "");
      }
    });

    const res = await API.postNewBill(formData, auth.token);
    await API.getInvoiceDetail(id, auth.token);
    await API.getBill(id, auth.token);
    dispatch({
      type: POST_BILL_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb();
    toast.success(i18n.t("cash.new.msg.toastSuccess"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postBill(body, id, cb))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: POST_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_BILL_ERROR });
  }
};

export const getTaxList = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TAX_LIST_PENDING });

    const res = await API.getTaxList(auth.token);
    dispatch({
      type: GET_TAX_LIST_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTaxList())));
      } else {
        dispatch({
          type: GET_TAX_LIST_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_TAX_LIST_ERROR });
  }
};

export const getPopulateBrand = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BRAND_POPULATE_PENDING });

    const res = await API.getPopulateBrand(auth.token);
    dispatch({
      type: GET_BRAND_POPULATE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPopulateBrand())));
      } else {
        dispatch({
          type: GET_BRAND_POPULATE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BRAND_POPULATE_ERROR });
  }
};

export const getPopulateBranch = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BRANCH_POPULATE_PENDING });

    const res = await API.getPopulateBranch(auth.token);
    dispatch({
      type: GET_BRANCH_POPULATE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPopulateBranch())));
      } else {
        dispatch({
          type: GET_BRANCH_POPULATE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BRANCH_POPULATE_ERROR });
  }
};

export const getPopulateProduct = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_PRODUCT_POPULATE_PENDING });

    const res = await API.getPopulateProduct(auth.token);
    dispatch({
      type: GET_PRODUCT_POPULATE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getPopulateProduct())));
      } else {
        dispatch({
          type: GET_PRODUCT_POPULATE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_PRODUCT_POPULATE_ERROR });
  }
};
