import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";
import { heightPercent, widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginHorizontal: 20,
    borderRadius: 15,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginBottom: 24,
    backgroundColor: COLORS.main
  },
  tripDescBox: {
    marginHorizontal: 20,
    marginTop: 15,
    marginBottom: 20
  },
  btnReport: {
    width: 24,
    height: 24,
    backgroundColor: "white",
    marginRight: 8.4,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center"
  },
  bookingDetailContainer: {
    flex: 1
  },
  bookingpDetailContent: {
    paddingHorizontal: 20
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 12,
    paddingRight: 20
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "22%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "58%"
  },
  fabStyle: {
    alignItems: "center",
    justifyContent: "center"
  },
  fabIcon: {
    width: 80,
    height: 80
  },
  footer: {
    flexDirection: "row",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "space-between",
    paddingVertical: 15
  },
  containAmount: {
    width: "50%",
    alignItems: "center"
  },
  titleAmount: {
    color: "white",
    fontSize: 11,
    marginBottom: 8
  },
  card: {
    marginHorizontal: 20,
    marginBottom: 16,
    marginTop: 16,
    borderRadius: 10
  },
  header: {
    height: 44,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    justifyContent: "center"
  },
  textHeader: {
    marginLeft: 21,
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "white"
  },
  text: {
    color: "#666666",
    marginLeft: 21,
    fontSize: 11,
    fontFamily: "Poppins"
  },
  posDesc: {
    marginTop: 20,
    marginBottom: 11,
    marginRight: 22
  },
  line: {
    borderBottomColor: "#ecf0f1",
    borderBottomWidth: 1
  },
  footerModal: {
    width: widthPercent(100) - 25,
    backgroundColor: "#fffff7"
  },
  btnClose: {
    marginLeft: 20,
    paddingVertical: 36,
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666"
  },
  cardModalStyle: {
    borderRadius: 20,
    marginTop: 30,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    width: widthPercent(100) - 25,
    minHeight: heightPercent(95),
    paddingVertical: 36,
    backgroundColor: "#fffff7",
    marginBottom: 2
  },
  cardItem: {
    alignSelf: "center",
    marginBottom: 9,
    width: 160,
    backgroundColor: "#fffff7",
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1
  },
  title: {
    alignSelf: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#666666",
    marginBottom: 25
  },
  textItem: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666"
  },
  textClose: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    color: "#707070"
  },
  buttonClose: {
    bottom: 22,
    left: 34,
    position: "absolute"
  },
  insideModal: {
    width: widthPercent(100) - 54,
    paddingHorizontal: 22,
    alignItems: "center"
  },
  titleReport: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#666666",
    marginBottom: 4
  },
  dateReport: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#666666",
    marginBottom: 37
  },
  desc: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#666666",
    marginBottom: 20
  },
  textImages: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#666666",
    fontWeight: "bold",
    marginBottom: 12
  },
  textFiles: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
    color: "#666666",
    fontWeight: "bold",
    marginTop: 45
  },
  button: {
    width: 180
  },
  button2: {
    width: "100%",
    alignItems: "center",
    paddingVertical: 4
  },
  button3: {
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  buttonText: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.grey,
    fontWeight: "bold"
  },
  buttonText2: {
    color: COLORS.white
  },
  centerText: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
