import React, { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Touchable, WrapContent } from "../../../../../components";
import styles from "./styles";
import { COLORS, IMAGES } from "../../../../../configs";
import { convertDate, copyWritings, documentTitle } from "../../../../../utils";
import { Reducers } from "../../../../../redux/types";
import { getTripById } from "../../../../../redux/actions";

interface Props {
  id: number;
  type: "bookingHotels" | "bookingFlights";
  tripId: string;
}

const suiteHotel = [
  { label: "Any", value: "any" },
  { label: "Single", value: "single" },
  { label: "Double", value: "double" },
  { label: "Family", value: "family" }
];

const selectClass = [
  { label: "Economy", value: "ECONOMY" },
  { label: "Business", value: "BUSINESS" },
  { label: "Any", value: "ANY" }
];

const Details = ({ id, type, tripId }: Props) => {
  const { t } = useTranslation();
  documentTitle(
    `Detail Booking - ${
      type === "bookingHotels" ? "Hotel" : t("activity.booking.flight")
    }`
  );

  const dispatch = useDispatch();

  const [showMore, setShowMore] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);
  const {
    tripState,
    regionState,
    vendorState,
    newTripState,
    taskState,
    newBookingFlightState,
    regencyState,
    homeState,
    bookingState
  } = useSelector(
    (state: Reducers) => ({
      tripState: state.trip,
      regionState: state.region,
      vendorState: state.vendor,
      newTripState: state.newTrip,
      taskState: state.task,
      newBookingFlightState: state.newBookingFlight,
      regencyState: state.regency,
      homeState: state.home,
      bookingState: state.booking
    }),
    shallowEqual
  );

  const data =
    tripState.activity.data[type] && tripState.activity.data[type][id];

  const _renderStar = useCallback((value: number) => {
    let result: any[] = [];
    for (let i = 1; i <= 5; i += 1) {
      result = [
        ...result,
        <View key={i} style={{ marginRight: 4 }}>
          <Image
            source={i <= value ? IMAGES.star : IMAGES.stargrey}
            style={styles.iconStar}
          />
        </View>
      ];
    }

    return result;
  }, []);

  const _getRegionName = useCallback(
    (idReg: string) => {
      const getIndex = regionState.region.findIndex(item => item.id === idReg);
      return regionState.region[getIndex] && regionState.region[getIndex].name;
    },
    [regionState.region]
  );

  const _getLabelSuite = useCallback(value => {
    const getIndex = suiteHotel.findIndex(e => e.value === value);

    return suiteHotel[getIndex] && suiteHotel[getIndex].label;
  }, []);

  const _getCategory = useCallback(() => {
    const index = newTripState.listPopulateCategory.findIndex(
      e =>
        e.value ===
        (tripState.activity.data.new_activity &&
          tripState.activity.data.new_activity.category_id)
    );

    return (
      newTripState.listPopulateCategory[index] &&
      newTripState.listPopulateCategory[index].value !== "" &&
      newTripState.listPopulateCategory[index].label
    );
  }, [newTripState.listPopulateCategory, tripState.activity.data.new_activity]);

  const _getLabelProject = useCallback(() => {
    const index = newTripState.listPopulateProject.findIndex(
      e =>
        e.value ===
        (tripState.activity.data.new_activity &&
          tripState.activity.data.new_activity.project_id)
    );

    return (
      newTripState.listPopulateProject[index] &&
      newTripState.listPopulateProject[index].label
    );
  }, [newTripState.listPopulateProject, tripState.activity.data.new_activity]);

  const _getLabelTask = useCallback(() => {
    const index = taskState.taskListDropdown.findIndex(
      e =>
        e.id ===
        (tripState.activity.data.new_activity &&
          tripState.activity.data.new_activity.task_id)
    );

    return (
      taskState.taskListDropdown[index] &&
      taskState.taskListDropdown[index].attributes.title
    );
  }, [taskState.taskListDropdown, tripState.activity.data.new_activity]);

  const _getAirlineLabel = useCallback(
    (airlineId: string) => {
      const getIndex = newBookingFlightState.listAirline.findIndex(
        e => e.value === airlineId
      );
      return (
        newBookingFlightState.listAirline[getIndex] &&
        newBookingFlightState.listAirline[getIndex].label
      );
    },
    [newBookingFlightState.listAirline]
  );

  useEffect(() => {
    if (
      tripId !== undefined &&
      tripId !== "undefined" &&
      tripId !== "hotel-detail" &&
      tripId !== "flight-detail"
    ) {
      dispatch(getTripById(tripId));
    }
  }, [dispatch]);

  const _getLabelClass = useCallback((classId: string) => {
    const getIndex = selectClass.findIndex(e => e.value === classId);
    return selectClass[getIndex] && selectClass[getIndex].label;
  }, []);

  const _getTitleAirport = useCallback(
    (idAirport: string, typeAirport: "departure" | "return") => {
      if (typeAirport === "departure") {
        const getIndex = regencyState.departureAirports.findIndex(
          e => e.id === idAirport
        );
        const result = regencyState.departureAirports[getIndex];
        return getIndex >= 0 ? result.attributes.city_name : "-";
      }
      const getIndex = regencyState.arrivalAirports.findIndex(
        e => e.id === idAirport
      );
      const result = regencyState.arrivalAirports[getIndex];
      return getIndex >= 0 ? result.attributes.city_name : "-";
    },
    [regencyState.departureAirports, regencyState.arrivalAirports]
  );

  return (
    <WrapContent isLoading={tripState.activity.isLoading}>
      <View style={styles.container}>
        <View style={{ paddingHorizontal: 16 }}>
          <View style={styles.flexDirection}>
            {tripState.activity.data.new_activity &&
            tripState.activity.data.new_activity.is_project ? (
              <>
                <Text style={[styles.label, styles.widthLabel]}>
                  {copyWritings("project")}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.label}>
                    {tripId && tripState.tripData && tripState.tripData.number}
                  </Text>
                  <Text style={styles.font14bold}>
                    {newTripState.isLoadingPopulateProject
                      ? "Loading..."
                      : _getLabelProject()}
                    {tripId &&
                      tripState.tripData &&
                      tripState.tripData.project?.title}
                  </Text>
                  <Text style={styles.label}>
                    {newTripState.isLoadingPopulateCategory
                      ? "Loading..."
                      : _getCategory()}
                    {tripId &&
                      tripState.tripData &&
                      tripState.tripData.category.title}
                  </Text>
                </View>
              </>
            ) : (
              <>
                <Text style={[styles.label, styles.widthLabel]}>
                  {copyWritings("task")}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.font14bold}>
                    {taskState.isLoadingTaskListDropDown
                      ? "Loading..."
                      : _getLabelTask()}
                  </Text>
                  <Text style={styles.label}>
                    {newTripState.isLoadingPopulateCategory
                      ? "Loading..."
                      : _getCategory()}
                  </Text>
                </View>
              </>
            )}
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>
              {t("activity.invoice.requestedBy")}
            </Text>
            <View style={{ flex: 1 }}>
              <Text style={[styles.font14bold, { fontSize: 12 }]}>
                {homeState.isLoadingMyProfile
                  ? "Loading..."
                  : homeState.myProfile.name}
              </Text>
            </View>
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>
              {t("activity.invoice.booker")}
            </Text>
            <View style={{ flex: 1 }}>
              <Text style={[styles.font14bold, { fontSize: 12 }]}>
                {homeState.isLoadingMyProfile
                  ? "Loading..."
                  : homeState.myProfile.name}
              </Text>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <View style={[styles.flexDirection, { marginBottom: 0 }]}>
              {type === "bookingHotels" ? (
                <>
                  <Text style={[styles.label, styles.widthLabel]}>Hotel</Text>
                  <View style={[{ flex: 1, flexDirection: "row" }]}>
                    {data && _renderStar(data.star_id)}
                    {!data &&
                      bookingState.listBookingHotel.length > 0 &&
                      _renderStar(
                        bookingState.listBookingHotel[id].djurneeBooking
                          .template
                          ? bookingState.listBookingHotel[id].data
                              .prediction_star
                          : bookingState.listBookingHotel[id].data.star
                      )}
                  </View>
                </>
              ) : (
                <>
                  {!data &&
                  bookingState.listBookingFlight.length > 0 &&
                  !bookingState.listBookingFlight[id].data.departure ? (
                    <Text style={[styles.label, styles.widthLabel]}>
                      {t("activity.new.")}
                    </Text>
                  ) : null}
                </>
              )}
            </View>

            <View style={styles.flexDirection}>
              {type === "bookingHotels" ? (
                <Text style={styles.font14bold}>
                  {data && data.hotel}
                  {!data &&
                    bookingState.listBookingHotel.length > 0 &&
                    (bookingState.listBookingHotel[id].data.name ||
                      bookingState.listBookingHotel[id].data.prediction_name)}
                  {!data &&
                    bookingState.listBookingHotel.length > 0 &&
                    bookingState.listBookingHotel[id].data.hotel}
                </Text>
              ) : (
                <Text style={styles.font14bold}>
                  {data &&
                    tripId !== "undefined" &&
                    _getAirlineLabel(data.airline_id)}
                  {!data &&
                    bookingState.listBookingFlight.length > 0 &&
                    bookingState.listBookingFlight[id].data.name}
                </Text>
              )}
            </View>

            {type === "bookingHotels" ? (
              <>
                <View style={styles.flexDirection}>
                  <Text style={[styles.label, styles.widthLabel]}>
                    {bookingState.listBookingHotel.length > 0 &&
                    !data &&
                    bookingState.listBookingHotel[id].data.city
                      ? t("activity.invoice.city")
                      : t("activity.booking.location")}
                  </Text>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.value}>
                      {bookingState.listBookingHotel.length > 0 &&
                        !data &&
                        bookingState.listBookingHotel[id].data.city}
                      {bookingState.listBookingHotel.length > 0 &&
                        !data &&
                        bookingState.listBookingHotel[id].data.location}
                      {regionState.isLoadingRegion
                        ? "Loading..."
                        : data && _getRegionName(data.regency_id)}
                    </Text>
                  </View>
                </View>
                {bookingState.listBookingHotel.length > 0 &&
                !data &&
                bookingState.listBookingHotel[id].data.address ? (
                  <View style={styles.flexDirection}>
                    <Text style={[styles.label, styles.widthLabel]}>
                      {t("activity.invoice.address")}
                    </Text>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.value}>
                        {bookingState.listBookingHotel[id].data.address}
                      </Text>
                    </View>
                  </View>
                ) : null}

                <View style={styles.flexDirection}>
                  <Text style={[styles.label, styles.widthLabel]}>
                    Check In
                  </Text>
                  <View style={{ flex: 1 }}>
                    {!data && bookingState.listBookingHotel.length > 0 && (
                      <Text style={styles.value}>
                        {convertDate(
                          !data &&
                            bookingState.listBookingHotel[id].data.check_in,
                          "ddd"
                        )}
                        <Text style={[styles.value, { fontWeight: "bold" }]}>
                          {` ${convertDate(
                            !data &&
                              bookingState.listBookingHotel[id].data.check_in,
                            "DD MMM YYYY"
                          )} `}
                        </Text>
                        {convertDate(
                          !data &&
                            bookingState.listBookingHotel[id].data.check_in,
                          "HH:mm"
                        )}
                      </Text>
                    )}
                    {data && (
                      <Text style={styles.value}>
                        {convertDate(data.started_at, "ddd")}
                        <Text style={[styles.value, { fontWeight: "bold" }]}>
                          {` ${convertDate(data.started_at, "DD MMM YYYY")} `}
                        </Text>
                        {convertDate(data.started_at, "HH:mm")}
                      </Text>
                    )}
                  </View>
                </View>

                <View style={styles.flexDirection}>
                  <Text style={[styles.label, styles.widthLabel]}>
                    Check Out
                  </Text>
                  <View style={{ flex: 1 }}>
                    {!data && bookingState.listBookingHotel.length > 0 && (
                      <Text style={styles.value}>
                        {convertDate(
                          !data &&
                            bookingState.listBookingHotel[id].data.check_out,
                          "ddd"
                        )}
                        <Text style={[styles.value, { fontWeight: "bold" }]}>
                          {` ${convertDate(
                            !data &&
                              bookingState.listBookingHotel[id].data.check_out,
                            "DD MMM YYYY"
                          )} `}
                        </Text>
                        {convertDate(
                          !data &&
                            bookingState.listBookingHotel[id].data.check_out,
                          "HH:mm"
                        )}
                      </Text>
                    )}
                    {data && (
                      <Text style={styles.value}>
                        {convertDate(data.finished_at, "ddd")}
                        <Text style={[styles.value, { fontWeight: "bold" }]}>
                          {` ${convertDate(data.finished_at, "DD MMM YYYY")} `}
                        </Text>
                        {convertDate(data.finished_at, "HH:mm")}
                      </Text>
                    )}
                  </View>
                </View>

                <View style={styles.flexDirection}>
                  <Text style={[styles.label, styles.widthLabel]}>
                    {t("activity.booking.suite")}
                  </Text>
                  <View style={{ flex: 1 }}>
                    <Text style={[styles.value, { fontWeight: "bold" }]}>
                      {data && _getLabelSuite(data.suite_id)}
                    </Text>
                    {!data && bookingState.listBookingHotel.length > 0 ? (
                      <Text style={[styles.value, { fontWeight: "bold" }]}>
                        {bookingState.listBookingHotel[id].djurneeBooking
                          .template
                          ? bookingState.listBookingHotel[id].data
                              .prediction_suite
                          : bookingState.listBookingHotel[id].data.suite}
                      </Text>
                    ) : null}
                  </View>
                </View>
                {!data &&
                bookingState.listBookingHotel.length > 0 &&
                bookingState.listBookingHotel[id].file ? (
                  <View style={styles.flexDirection}>
                    <Text style={[styles.label, styles.widthLabel]}>
                      E-Booking
                    </Text>
                    <View style={{ flex: 1 }}>
                      <Text style={[styles.value, { fontWeight: "bold" }]}>
                        {data && _getLabelSuite(data.suite_id)}
                      </Text>

                      <Touchable
                        onPress={() =>
                          window.open(
                            `${
                              JSON.parse(bookingState.listBookingHotel[id].file)
                                .url
                            }`,
                            "_blank"
                          )
                        }
                      >
                        <Text
                          style={[
                            styles.value,
                            { fontWeight: "bold", color: COLORS.orange }
                          ]}
                        >
                          {t("activity.invoice.tapDownload")}
                        </Text>
                      </Touchable>
                    </View>
                  </View>
                ) : null}
              </>
            ) : (
              <>
                {!data &&
                bookingState.listBookingFlight.length > 0 &&
                !bookingState.listBookingFlight[id].data.departure ? (
                  <View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.class")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        <Text style={[styles.value, { fontWeight: "bold" }]}>
                          {data && _getLabelClass(data.class_id)}
                          {!data &&
                            bookingState.listBookingFlight.length > 0 &&
                            bookingState.listBookingFlight[id].data.class}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.roundTrip")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {data ? (
                          <Text
                            style={[
                              styles.value,
                              {
                                fontWeight: "bold",
                                color:
                                  data && data.is_round_trip
                                    ? COLORS.green
                                    : COLORS.orange
                              }
                            ]}
                          >
                            {data && data.is_round_trip ? "YES" : "NO"}
                          </Text>
                        ) : null}
                        {!data && bookingState.listBookingFlight.length > 0 ? (
                          <Text
                            style={[
                              styles.value,
                              {
                                fontWeight: "bold",
                                color:
                                  data && data.is_round_trip
                                    ? COLORS.orange
                                    : COLORS.green
                              }
                            ]}
                          >
                            {bookingState.listBookingFlight[id].data[
                              "one-way-trip"
                            ]
                              ? t("activity.invoice.no")
                              : t("activity.invoice.yes")}
                          </Text>
                        ) : null}
                      </View>
                    </View>

                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.booking.departure")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {data && (
                          <Text style={styles.value}>
                            {_getTitleAirport(
                              data.origin_location_id,
                              "departure"
                            )}
                            <Text
                              style={[styles.value, { fontWeight: "bold" }]}
                            >
                              {`, ${convertDate(
                                data.started_at,
                                "DD MMM YYYY"
                              )} `}
                            </Text>
                            {data.started_at_t}
                          </Text>
                        )}
                        {!data && bookingState.listBookingFlight.length > 0 && (
                          <Text style={styles.value}>
                            {_getTitleAirport(
                              bookingState.listBookingFlight[id].data
                                .origin_airport_id,
                              "departure"
                            )}
                            <Text
                              style={[styles.value, { fontWeight: "bold" }]}
                            >
                              {`, ${convertDate(
                                bookingState.listBookingFlight[id].data
                                  .departure_date,
                                "DD MMM YYYY"
                              )} `}
                            </Text>
                            {
                              bookingState.listBookingFlight[id].data
                                .departure_time_range
                            }
                          </Text>
                        )}
                      </View>
                    </View>

                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.booking.destination")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {data && (
                          <Text style={styles.value}>
                            {_getTitleAirport(
                              data.destination_location_id,
                              "return"
                            )}
                            {data.is_round_trip && (
                              <>
                                <Text
                                  style={[styles.value, { fontWeight: "bold" }]}
                                >
                                  {`, ${convertDate(
                                    data.finished_at,
                                    "DD MMM YYYY"
                                  )} `}
                                </Text>
                                {data.finished_at_t}
                              </>
                            )}
                          </Text>
                        )}
                        {!data && bookingState.listBookingFlight.length > 0 && (
                          <Text style={styles.value}>
                            {_getTitleAirport(
                              bookingState.listBookingFlight[id].data
                                .destination_airport_id,
                              "return"
                            )}
                            {!bookingState.listBookingFlight[id].data[
                              "one-way-trip"
                            ] && (
                              <>
                                <Text
                                  style={[styles.value, { fontWeight: "bold" }]}
                                >
                                  {`, ${convertDate(
                                    bookingState.listBookingFlight[id].data
                                      .return_date,
                                    "DD MMM YYYY"
                                  )} `}
                                </Text>
                                {
                                  bookingState.listBookingFlight[id].data
                                    .return_time_range
                                }
                              </>
                            )}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={{ marginTop: -20 }}>
                    <Text
                      style={{
                        color: COLORS.green,

                        marginBottom: 5,
                        fontWeight: "bold"
                      }}
                    >
                      {t("activity.booking.departure")}
                    </Text>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.airlines")}
                      </Text>
                      {tripId !== "undefined" ? (
                        <View style={{ flex: 1 }}>
                          <Text style={[styles.value, { fontWeight: "bold" }]}>
                            {bookingState.listBookingFlight[id]
                              ? bookingState.listBookingFlight[id].data
                                  .departure.name
                              : "-"}
                          </Text>
                        </View>
                      ) : (
                        <View style={{ flex: 1 }}>
                          <Text style={[styles.value, { fontWeight: "bold" }]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? _getAirlineLabel(
                                  String(
                                    tripState.activity.data.bookingFlights[id]
                                      .airline_id
                                  )
                                )
                              : "-"}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.from")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {tripId !== "undefined" ? (
                          <Text style={[styles.value]}>
                            {bookingState.listBookingFlight[id]
                              ? bookingState.listBookingFlight[id].data
                                  .departure.origin
                              : "-"}
                          </Text>
                        ) : (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? tripState.activity.data.bookingFlights[id]
                                  .departure
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.to")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {tripId !== "undefined" ? (
                          <Text style={[styles.value]}>
                            {bookingState.listBookingFlight[id]
                              ? bookingState.listBookingFlight[id].data
                                  .departure.destination
                              : "-"}
                          </Text>
                        ) : (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? tripState.activity.data.bookingFlights[id]
                                  .return
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.booking.departure")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        {tripId !== "undefined" ? (
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[styles.value, { fontWeight: "normal" }]}
                            >
                              {convertDate(
                                bookingState.listBookingFlight[id] &&
                                  bookingState.listBookingFlight[id].data
                                    .departure.departed_at,
                                "ddd, "
                              )}
                            </Text>
                            <Text style={[styles.value]}>
                              {convertDate(
                                bookingState.listBookingFlight[id] &&
                                  bookingState.listBookingFlight[id].data
                                    .departure.departed_at,
                                "DD MMM YYYY"
                              )}
                            </Text>
                            <Text
                              style={[styles.value, { fontWeight: "normal" }]}
                            >
                              {convertDate(
                                bookingState.listBookingFlight[id] &&
                                  bookingState.listBookingFlight[id].data
                                    .departure.eta,
                                " HH:mm"
                              )}
                            </Text>
                          </View>
                        ) : (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? `${convertDate(
                                  tripState.activity.data.bookingFlights[id]
                                    .started_at_d,
                                  "DD MMM YYYY"
                                )}, ${
                                  tripState.activity.data.bookingFlights[id]
                                    .started_at_t
                                }`
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                    <Text
                      style={{
                        color: COLORS.orange,
                        marginBottom: 5,
                        fontWeight: "bold"
                      }}
                    >
                      {t("activity.booking.returning")}
                    </Text>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.airlines")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {tripId !== "undefined" ? (
                          <Text style={[styles.value, { fontWeight: "bold" }]}>
                            {bookingState.listBookingFlight[id]
                              ? bookingState.listBookingFlight[id].data
                                  .returning.name
                              : "-"}
                          </Text>
                        ) : (
                          <Text style={[styles.value, { fontWeight: "bold" }]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? _getAirlineLabel(
                                  String(
                                    tripState.activity.data.bookingFlights[id]
                                      .airline_id
                                  )
                                )
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.from")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {tripId !== "undefined" ? (
                          <Text style={[styles.value]}>
                            {bookingState.listBookingFlight[id]
                              ? bookingState.listBookingFlight[id].data
                                  .returning.origin
                              : "-"}
                          </Text>
                        ) : (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? tripState.activity.data.bookingFlights[id]
                                  .return
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.new.to")}
                      </Text>
                      <View style={{ flex: 1 }}>
                        {tripId !== "undefined" ? (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            bookingState.listBookingFlight[id]
                              ? bookingState.listBookingFlight[id].data
                                  .returning.destination
                              : "-"}
                          </Text>
                        ) : (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? tripState.activity.data.bookingFlights[id]
                                  .return
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.flexDirection}>
                      <Text style={[styles.label, styles.widthLabel]}>
                        {t("activity.booking.returning")}
                      </Text>
                      <View style={[styles.flexDirection, { flex: 1 }]}>
                        {tripId !== "undefined" ? (
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[styles.value, { fontWeight: "normal" }]}
                            >
                              {convertDate(
                                bookingState.listBookingFlight[id] &&
                                  bookingState.listBookingFlight[id].data
                                    .returning.departed_at,
                                "ddd, "
                              )}
                            </Text>
                            <Text style={[styles.value]}>
                              {convertDate(
                                bookingState.listBookingFlight[id] &&
                                  bookingState.listBookingFlight[id].data
                                    .returning.departed_at,
                                "DD MMM YYYY"
                              )}
                            </Text>
                            <Text
                              style={[styles.value, { fontWeight: "normal" }]}
                            >
                              {convertDate(
                                bookingState.listBookingFlight[id] &&
                                  bookingState.listBookingFlight[id].data
                                    .returning.eta,
                                " HH:mm"
                              )}
                            </Text>
                          </View>
                        ) : (
                          <Text style={[styles.value]}>
                            {tripState.activity.data.bookingFlights &&
                            tripState.activity.data.bookingFlights[id]
                              ? `${convertDate(
                                  tripState.activity.data.bookingFlights[id]
                                    .finished_at_d,
                                  "DD MMM YYYY"
                                )}, ${
                                  tripState.activity.data.bookingFlights[id]
                                    .finished_at_t
                                }`
                              : "-"}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                )}

                <View style={styles.flexDirection}>
                  <Text style={[styles.label, styles.widthLabel]}>
                    {t("activity.booking.passanger")}
                  </Text>
                  <View style={{ flex: 1 }}>
                    {tripState.tripData &&
                      tripState.tripData.users.map(
                        (item: any, index: number) => (
                          <Text
                            key={index}
                            style={[styles.value, { fontWeight: "bold" }]}
                          >
                            {item.user.name}
                            {tripState.tripData.users.length === index + 1
                              ? ""
                              : ", "}
                          </Text>
                        )
                      )}
                  </View>
                </View>
              </>
            )}
          </View>

          {vendorState.isLoadingDetailVendor ? (
            <View style={{ marginVertical: 40 }}>
              <ActivityIndicator size="large" />
            </View>
          ) : (
            <View style={{ marginTop: 20 }}>
              <View style={styles.flexDirection}>
                <Text style={[styles.label, styles.widthLabel]}>
                  {t("activity.booking.vendor")}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.value}>
                    {vendorState.vendorDetail.name}
                  </Text>
                </View>
              </View>
              <View style={styles.flexDirection}>
                <Text style={[styles.label, styles.widthLabel]}>
                  {t("activity.invoice.address")}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.value}>
                    {vendorState.vendorDetail.address &&
                      vendorState.vendorDetail.address.address}
                  </Text>
                </View>
              </View>
              <View style={styles.flexDirection}>
                <Text style={[styles.label, styles.widthLabel]}>
                  {t("activity.invoice.contact")}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.value}>
                    {vendorState.vendorDetail.address &&
                      vendorState.vendorDetail.address.phone}
                  </Text>
                </View>
              </View>
              <View style={styles.flexDirection}>
                <Text style={[styles.label, styles.widthLabel]}>Fax</Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.value}>
                    {(vendorState.vendorDetail.address &&
                      vendorState.vendorDetail.address.fax) ||
                      "-"}
                  </Text>
                </View>
              </View>
              <View style={styles.flexDirection}>
                <Text style={[styles.label, styles.widthLabel]}>
                  {t("activity.invoice.email")}
                </Text>
                <View style={{ flex: 1 }}>
                  <Text style={styles.value}>
                    {vendorState.vendorDetail.address &&
                      vendorState.vendorDetail.address.email}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>

        <View style={{ marginTop: 20 }}>
          <View style={styles.card}>
            <View style={styles.flexDirection}>
              <Text style={[styles.label, styles.widthLabel]}>
                {t("activity.new.description")}
              </Text>
              <View style={{ flex: 1 }}>
                <Text
                  style={styles.value}
                  numberOfLines={isShowMore ? undefined : 3}
                  onLayout={e => {
                    if (!showMore && e.nativeEvent.layout.height > 51) {
                      setShowMore(true);
                      setIsShowMore(false);
                    }
                  }}
                >
                  {(data && data.remarks) ||
                    (!data &&
                      type === "bookingHotels" &&
                      bookingState.listBookingHotel.length > 0 &&
                      bookingState.listBookingHotel[id].remarks) ||
                    "-"}
                </Text>
                {showMore && (
                  <Text
                    style={[
                      styles.value,
                      { marginTop: 12, textDecorationLine: "underline" }
                    ]}
                    onPress={() => setIsShowMore(!isShowMore)}
                  >
                    {!isShowMore
                      ? t("activity.invoice.showMore")
                      : t("activity.invoice.showLess")}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </WrapContent>
  );
};

export default Details;
