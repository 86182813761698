import React from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { sprintf } from "sprintf-js";
import styles from "./styles";
import { Card, Touchable, WrapContent, WrapList } from "../../../../components";
import { Reducers } from "../../../../redux/types";
import {
  convertDate,
  copyWritings,
  maskedMoney,
  permissionPage
} from "../../../../utils";
import { IMAGES } from "../../../../configs";

interface Props {
  id: string;
}

const History = ({ id }: Props) => {
  const history = useHistory();
  const cashState = useSelector((state: Reducers) => state.cash);
  const data: any[] = cashState.cashHistory.payments;
  const outStand =
    cashState.cashHistory.total - cashState.cashHistory.total_paid;
  const { status, total, total_paid } = cashState.cashHistory;

  const { t } = useTranslation();

  const copTeks = {
    collectable: copyWritings("collectable")
  };

  const _permissionCreate = () => {
    if (
      permissionPage("payment-create") &&
      [3, 5].includes(status) &&
      Number(total) - Number(total_paid) > 0
    ) {
      return true;
    }
    return false;
  };

  const _goToPreview = (link: string) => {
    window.open(`${link}`, "_blank");
  };

  return (
    <WrapContent isLoading={cashState.isLoadingCashDetail}>
      <WrapList
        isEmpty={data.length === 0}
        emptyComponent={() => (
          <Card
            containerStyle={styles.button}
            cardStyle={[
              styles.button2,
              _permissionCreate() ? styles.button3 : styles.button2
            ]}
            onPress={() => history.push(`/invoice/${id}/pay`)}
            disabled={!_permissionCreate()}
          >
            <Text style={[styles.buttonText, styles.buttonText2]}>
              {cashState.cashHistory.djurneeInvoice.type.title &&
              cashState.cashHistory.djurneeInvoice.type.title === "Collectable"
                ? sprintf(t("cash.new.addCollect"), copTeks.collectable)
                : t("cash.new.plusPayment")}
            </Text>
          </Card>
        )}
      >
        <View style={styles.container}>
          <FlatList
            data={data}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <View style={{ marginBottom: 12 }} key={index}>
                <View style={styles.wrapText}>
                  <Text style={styles.textLabel}>{t("cash.new.date")}</Text>
                  <Text
                    style={[
                      styles.textLabel,
                      styles.textValue,
                      styles.bold,
                      { width: "70%" }
                    ]}
                  >
                    <Text style={{ fontWeight: "normal" }}>
                      {`${convertDate(item.date, "ddd")} `}
                    </Text>
                    {convertDate(item.date, "DD MMM YYYY HH:mm")}
                  </Text>
                  {item.payment.file && (
                    <Touchable
                      onPress={() =>
                        _goToPreview(
                          item.payment.file && JSON.parse(item.payment.file).url
                        )
                      }
                    >
                      <View style={styles.wrapTrashIcon}>
                        <Image
                          source={IMAGES.clipAttchment}
                          style={{ width: 17, height: 20 }}
                        />
                      </View>
                    </Touchable>
                  )}
                </View>
                <View
                  style={[
                    styles.wrapText,
                    item.payment.file && { marginTop: -10 }
                  ]}
                >
                  <Text style={styles.textLabel}>
                    {t("cash.detail.method")}
                  </Text>
                  <Text style={[styles.textLabel, styles.textValue]}>
                    {item.payment.method ? item.payment.method.title : "-"}
                  </Text>
                </View>
                <View style={styles.wrapText}>
                  <Text style={[styles.textLabel, { marginRight: 1 }]}>
                    {t("cash.new.fee")}
                  </Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    <Text style={{ fontWeight: "normal" }}>IDR </Text>
                    {maskedMoney(item.payment.amount_fee)}
                  </Text>
                </View>
                <View style={styles.wrapAmountText}>
                  <Text style={styles.amountText}>
                    <Text style={{ fontWeight: "normal" }}>IDR </Text>
                    {maskedMoney(item.total)}
                  </Text>
                </View>
                <View style={styles.line} />
              </View>
            )}
          />
          <TouchableOpacity
            activeOpacity={0.8}
            style={styles.wrapButton}
            disabled={!_permissionCreate()}
            onPress={() => history.push(`/invoice/${id}/pay`)}
          >
            <Card
              containerStyle={styles.button}
              cardStyle={[
                styles.button2,
                _permissionCreate() ? styles.button3 : styles.button2
              ]}
            >
              <Text style={[styles.buttonText, styles.buttonText2]}>
                {cashState.cashHistory.djurneeInvoice.type.title &&
                cashState.cashHistory.djurneeInvoice.type.title ===
                  "Collectable"
                  ? sprintf(t("cash.new.addCollect"), copTeks.collectable)
                  : t("cash.new.plusPayment")}
              </Text>
            </Card>
          </TouchableOpacity>
          <View style={[styles.wrapTotal, { marginBottom: 0 }]}>
            <Text style={styles.totalLabel}>{t("cash.new.outstanding")}</Text>
            <Text style={[styles.amountText, { color: "#ED5A28" }]}>
              <Text style={{ fontWeight: "normal" }}>
                {outStand ? "IDR " : ""}
              </Text>
              {maskedMoney(outStand)}
            </Text>
          </View>
          <View style={[styles.wrapTotal, { marginTop: 8 }]}>
            <Text style={styles.totalLabel}>{t("cash.new.totalPayment")}</Text>
            <Text style={styles.amountText}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(cashState.cashDetail && cashState.cashDetail.total)}
            </Text>
          </View>
        </View>
      </WrapList>
    </WrapContent>
  );
};

export default History;
