import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  background: {
    paddingTop: 25,
    backgroundColor: COLORS.main,
    borderBottomLeftRadius: 80,
    borderBottomRightRadius: 80
  },
  image: {
    width: 27,
    height: 26,
    tintColor: COLORS.white
  },
  wrapTipNav: {
    paddingTop: 20,
    backgroundColor: COLORS.main,
    paddingBottom: 22,
    marginBottom: -8
  },
  tripNav: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 80,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    height: "fit-content",
    width: "fir-content",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  active: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    flex: 1
  },
  activeFirst: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    flex: 1,
    borderTopLeftRadius: 80,
    borderBottomLeftRadius: 80
  },
  activeLast: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    flex: 1,
    borderTopRightRadius: 80,
    borderBottomRightRadius: 80
  },
  btnNav: {
    height: 70,
    flex: 1
  },
  btnText: {
    marginTop: 4,
    fontWeight: "bold",
    fontFamily: "poppins",
    fontSize: 8,
    color: "#ffffff"
  }
});

export default styles;
