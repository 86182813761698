import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  copyWritings,
  documentTitle,
  locatToUtc,
  maskedMoney
} from "../../../utils";
import { Fixed, Header, TextArea, WrapContent } from "../../../components";
import "./style.css";
import styles from "./newStyles";
import { COLORS, ICONS, IMAGES } from "../../../configs";
import {
  changeFormPayment,
  changeValueInvoiceDetailList,
  getDescBankAccount,
  getInvoiceDetailList,
  getInvoiceList,
  getPaymentMethod,
  getVendorContact,
  // getVendorContact,
  postInvoicePay,
  removeInvoiceDetailList
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const NewPayment = () => {
  documentTitle("New Payment");
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams() as any;
  const { t } = useTranslation();

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [heightBottom, setHeightBottom] = useState(0);
  const [moreInvoice, setMoreInvoice] = useState("");
  const invoiceState = useSelector((state: Reducers) => state.invoice);
  const { myProfile } = useSelector((state: Reducers) => state.home);

  const copTeks = {
    payment_method: copyWritings("payment_method"),
    vendor: copyWritings("vendor"),
    employee: copyWritings("employee")
  };

  const _getIndex = useCallback(
    (item: any, type: "user" | "vendor" | "djurnee_invoice_type") =>
      item.included.findIndex((e: any) => e.type === type),
    []
  );

  const _isVendor = useCallback((item: any) => {
    const index = item.included.findIndex(
      (e: any) => e.type === "djurnee_invoice"
    );
    return !!item.included[index].relationships.vendor;
  }, []);

  useEffect(() => {
    setDate(moment().format("YYYY-MM-DD"));
    setTime(moment().format("HH:mm"));
    dispatch(getPaymentMethod(e => setPaymentId(e)));
    dispatch(
      getInvoiceDetailList(id, true, e => {
        dispatch(
          getInvoiceList(
            e.included[
              _getIndex(e, "djurnee_invoice_type")
            ].attributes.title.replaceAll(" ", "+")
          )
        );
        if (_isVendor(e)) {
          dispatch(
            getVendorContact(e.included[_getIndex(e, "vendor")].id, e.data.id)
          );
        }
      })
    );
  }, [dispatch, id, _getIndex, _isVendor]);

  const _subList = useCallback(
    (e: any, item: any) =>
      (_isVendor(e) &&
        _isVendor(item) &&
        e.included[_getIndex(e, "vendor")].id ===
          item.included[_getIndex(e, "vendor")].id) ||
      (!_isVendor(e) &&
        !_isVendor(item) &&
        e.included[_getIndex(e, "user")].id ===
          item.included[_getIndex(e, "user")].id),
    [_isVendor, _getIndex]
  );

  const _list = useCallback(
    (e: any, item: any) => {
      let result = true;

      e.map((res: any) => {
        if (result) {
          if (_isVendor(res) && _isVendor(item)) {
            result =
              res.included[_getIndex(res, "vendor")].id !==
              item.included[_getIndex(item, "vendor")].id;
          }
          if (!_isVendor(res) && !_isVendor(item)) {
            result =
              res.included[_getIndex(res, "user")].id !==
              item.included[_getIndex(item, "user")].id;
          }
        }

        return true;
      });
      return result;
    },
    [_isVendor, _getIndex]
  );

  const _getIndexBank = useCallback((item: any) => {
    return item.listBank.findIndex((e: any) => e.id === item.activeBank);
  }, []);

  const _goToPreview = useCallback((file: File) => {
    if (
      file.type === "application/pdf" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === ".doc" ||
      file.type === ".docx"
    ) {
      window.open(URL.createObjectURL(file));
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
    <div
      style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
    >
      <img src="${URL.createObjectURL(file)}">
    </div>`;
    }
  }, []);

  const _getMethodActive = useCallback(() => {
    const indexMethod = invoiceState.listPaymentMethod.findIndex(
      e => e.id === paymentId
    );
    const idRelation =
      invoiceState.listPaymentMethod[indexMethod] &&
      invoiceState.listPaymentMethod[indexMethod].relationships.method.data.id;
    const indexInclude = invoiceState.includedMethod.findIndex(
      e => e.id === idRelation
    );
    return (
      invoiceState.includedMethod[indexInclude] &&
      invoiceState.includedMethod[indexInclude].attributes.title
    );
  }, [paymentId, invoiceState.listPaymentMethod, invoiceState.includedMethod]);

  const _isCollect = useCallback(
    () =>
      invoiceState.invoiceDetailList.list.length > 0 &&
      invoiceState.invoiceDetailList.list[0].included[
        _getIndex(
          invoiceState.invoiceDetailList.list[0],
          "djurnee_invoice_type"
        )
      ].attributes.title === "Collectable",
    [invoiceState.invoiceDetailList.list, _getIndex]
  );

  const _onPay = useCallback(() => {
    let error = false;
    let message = "";
    const indexAcc = invoiceState.listPaymentMethod.findIndex(
      e => e.id === paymentId
    );

    let body: any = {
      collect: _isCollect(),
      date: date ? moment(date).local().format("YYYY-MM-DD") : "",
      time: time ? locatToUtc(`${date || ""} ${time || ""}`) : "",
      payment_method_id: paymentId,
      company: {
        account: {
          bank_id:
            _getMethodActive() === "Bank"
              ? invoiceState.listPaymentMethod[indexAcc].relationships.bank.data
                  .id
              : null,
          bank_name:
            _getMethodActive() === "Bank"
              ? invoiceState.listPaymentMethod[indexAcc].attributes.account_name
              : null,
          branch:
            _getMethodActive() === "Bank"
              ? invoiceState.listPaymentMethod[indexAcc].attributes
                  .account_branch
              : null,
          number:
            _getMethodActive() === "Bank"
              ? invoiceState.listPaymentMethod[indexAcc].attributes
                  .account_number
              : null,
          name:
            _getMethodActive() === "Bank"
              ? invoiceState.listPaymentMethod[indexAcc].attributes.account_name
              : null
        }
      },
      subjects: []
    };

    let num = 0;

    invoiceState.invoiceDetailList.list.map((item, index) => {
      if (
        (index > 0 &&
          _list(invoiceState.invoiceDetailList.list.slice(0, index), item)) ||
        index === 0
      ) {
        let subjects: any = {
          type: _isVendor(item) ? "vendor" : "user",
          id: _isVendor(item)
            ? item.included[_getIndex(item, "vendor")].id
            : item.included[_getIndex(item, "user")].id,
          remarks: item.desc || "",
          amount_fee: _getMethodActive() === "Bank" ? item.fee || 0 : 0,
          account: {
            bank_id:
              _getMethodActive() === "Bank"
                ? item.descBank && item.descBank.relationships.bank.data.id
                : null,
            bank_name:
              _getMethodActive() === "Bank"
                ? item.descBank && item.descBank.attributes.bankName
                : null,
            branch:
              _getMethodActive() === "Bank"
                ? item.descBank && item.descBank.attributes.branch
                : null,
            number:
              _getMethodActive() === "Bank"
                ? item.descBank && item.descBank.attributes.number
                : null,
            name:
              _getMethodActive() === "Bank"
                ? item.descBank && item.descBank.attributes.name
                : null
          },
          invoices: []
        };

        invoiceState.invoiceDetailList.list.map((e, i) => {
          if (_subList(e, item)) {
            if (
              e.data.attributes.total <= 0 ||
              e.data.attributes.total === ""
            ) {
              error = true;
              message = "Input nominal pay required";
            } else if (_getMethodActive() === "Bank" && !e.listBank) {
              error = true;
              message = `#${i + 1} account number is required!`;
            }
            subjects = {
              ...subjects,
              invoices: [
                ...subjects.invoices,
                {
                  id: e.data.id,
                  amount: e.data.attributes.total
                }
              ]
            };
          }

          return true;
        });

        body = {
          ...body,
          subjects: [...body.subjects, subjects],
          [`file-${num}`]: item.file || null,
          client_timezone: "Asia/Jakarta"
        };

        num += 1;
      }

      return true;
    });

    if (myProfile?.user_id === 1 && !body?.["file-0"]) {
      error = true;
      message = t("cash.detail.msg.disburse_message");
    }

    if (!error) {
      dispatch(postInvoicePay(body, () => history.goBack()));
    } else {
      toast.error(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      });
    }
  }, [
    invoiceState.listPaymentMethod,
    paymentId,
    date,
    time,
    _list,
    _subList,
    _getMethodActive,
    _getIndex,
    _isVendor,
    _isCollect,
    invoiceState.invoiceDetailList.list,
    dispatch,
    history
  ]);

  const _toPhoneCall = (phone: any) => {
    window.open(`tel:${phone}`);
  };

  return (
    <>
      <Header
        textHeader={{ fontSize: 18 }}
        subTitleHeader={{ fontSize: 11 }}
        title={t("cash.new.newPayment")}
      />

      <WrapContent
        isLoading={
          invoiceState.isLoadingPaymentMethod ||
          invoiceState.invoiceList.isLoading ||
          invoiceState.invoiceDetailList.isLoading ||
          invoiceState.isLoading
        }
      >
        <View style={styles.container}>
          <View style={styles.row}>
            <Text style={[styles.font, styles.label]}>
              {copTeks.payment_method}
            </Text>
            <View style={styles.card}>
              <select
                className="container-content-input-payment"
                style={{
                  color: "#666666",
                  fontWeight: "bold",
                  borderRadius: 100,
                  borderBottomWidth: 0,
                  marginLeft: 10,
                  marginRight: 10
                }}
                onChange={e => setPaymentId(e.target.value)}
                value={paymentId}
              >
                {invoiceState.listPaymentMethod.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.attributes.title}
                  </option>
                ))}
              </select>
            </View>
          </View>

          <View style={styles.row}>
            <Text style={[styles.font, styles.label]}>
              {t("cash.new.date")}
            </Text>
            <View style={styles.wrapInputDateTime}>
              <View style={styles.card}>
                <input
                  className="container-content-input-payment"
                  type="date"
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: "#666666",
                    fontWeight: "bold",
                    borderRadius: 100,
                    borderBottomWidth: 0
                  }}
                  onChange={e => setDate(e.target.value)}
                  value={date}
                />
              </View>
              <View style={styles.card}>
                <input
                  className="container-content-input-payment"
                  type="time"
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: "#666666",
                    fontWeight: "bold",
                    borderRadius: 100,
                    borderBottomWidth: 0
                  }}
                  onChange={e => setTime(e.target.value)}
                  value={time}
                />
              </View>
            </View>
          </View>

          <View style={styles.row}>
            {myProfile.user_id !== 1 && (
              <View>
                <Text style={[styles.font, styles.label]}>
                  {t("cash.new.addMoreInvoice")}
                </Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View style={[styles.card, { flex: 1 }]}>
                    <select
                      className="container-content-input-payment"
                      style={{
                        color: "#666666",
                        fontWeight: "bold",
                        borderRadius: 100,
                        borderBottomWidth: 0,
                        marginLeft: 10,
                        marginRight: 10
                      }}
                      onChange={e => setMoreInvoice(e.target.value)}
                    >
                      <option value="">{t("cash.new.selectInvoice")}</option>
                      {invoiceState.invoiceList.list
                        .filter(
                          item =>
                            invoiceState.invoiceDetailList.list.findIndex(
                              e => e.data.id === item.id
                            ) < 0
                        )
                        .map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.attributes.title}
                          </option>
                        ))}
                    </select>
                  </View>
                  <TouchableOpacity
                    style={{ marginLeft: 10 }}
                    onPress={() =>
                      dispatch(
                        getInvoiceDetailList(moreInvoice, false, e => {
                          setMoreInvoice("");
                          if (_isVendor(e)) {
                            dispatch(
                              getVendorContact(
                                e.included[_getIndex(e, "vendor")].id,
                                e.data.id
                              )
                            );
                          }
                        })
                      )
                    }
                    disabled={moreInvoice === ""}
                  >
                    <Image
                      source={IMAGES.plus}
                      style={{ width: 25, height: 25 }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {invoiceState.invoiceDetailList.list.map((item, index) => {
              let num = 0;
              return (
                ((index > 0 &&
                  _list(
                    invoiceState.invoiceDetailList.list.slice(0, index),
                    item
                  )) ||
                  index === 0) && (
                  <View
                    style={[styles.card, { marginTop: 10, padding: 12 }]}
                    key={index}
                  >
                    <View style={styles.titleCard}>
                      <View style={styles.wrapStatus}>
                        <Text
                          style={[styles.font, styles.label, { color: "#FFF" }]}
                        >
                          {_isVendor(item) ? copTeks.vendor : copTeks.employee}
                        </Text>
                      </View>
                      <Text
                        style={[
                          styles.font,
                          styles.label,
                          { paddingHorizontal: 8 }
                        ]}
                      >
                        {_isVendor(item)
                          ? item.included[_getIndex(item, "vendor")].attributes
                              .name
                          : item.included[_getIndex(item, "user")].attributes
                              .name}
                      </Text>
                      {_isVendor(item) && (
                        <>
                          <div
                            data-tip
                            data-event="click focus"
                            data-for={`clickme${index}`}
                            style={{ width: "fit-content" }}
                          >
                            <Image
                              source={IMAGES.phone}
                              style={{ width: 16, height: 16 }}
                            />
                          </div>
                        </>
                      )}

                      {!item.file ? (
                        <View style={{ flex: 1, alignItems: "flex-end" }}>
                          <TouchableOpacity style={[styles.buttonUpload]}>
                            <label className="payment-button-label">
                              <input
                                type="file"
                                // eslint-disable-next-line max-len
                                accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                      application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={e =>
                                  dispatch(
                                    changeFormPayment(
                                      {
                                        formName: "file",
                                        formValue: e.target.files![0]
                                      },
                                      index
                                    )
                                  )
                                }
                              />
                              {t("cash.new.attachment")}
                            </label>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <TouchableOpacity
                          style={styles.attachment}
                          onPress={() => {
                            _goToPreview(item.file);
                          }}
                        >
                          <View style={{ width: "90%" }}>
                            <View style={{ flexDirection: "row" }}>
                              <View style={{ width: "85%" }}>
                                <Text
                                  numberOfLines={1}
                                  style={styles.attributeTask}
                                >
                                  {item.file.name}
                                </Text>
                              </View>
                              <TouchableOpacity
                                style={{
                                  width: "15%",
                                  height: "100%",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                                onPress={e => {
                                  e.stopPropagation();
                                  dispatch(
                                    changeFormPayment(
                                      {
                                        formName: "file",
                                        formValue: null
                                      },
                                      index
                                    )
                                  );
                                }}
                              >
                                <Image
                                  source={ICONS.iconDeleteFile}
                                  resizeMode="contain"
                                  style={{ width: 12, height: 12 }}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>

                    {_getMethodActive() === "Bank" && (
                      <View
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          flexDirection: "row"
                        }}
                      >
                        {item.listBank && item.listBank.length > 0 ? (
                          <select
                            className="container-content-input-payment"
                            style={{
                              color: "#666666",
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginRight: 10,
                              flex: 1
                            }}
                            value={item.activeBank}
                            onChange={e =>
                              dispatch(
                                getDescBankAccount(e.target.value, item.data.id)
                              )
                            }
                          >
                            {item.listBank &&
                              item.listBank.map((res: any, iB: number) => (
                                <option value={res.id} key={iB}>
                                  {res.attributes.title}
                                </option>
                              ))}
                          </select>
                        ) : (
                          <Text style={styles.attributeTask}>
                            {t("cash.new.noAccount")}
                          </Text>
                        )}
                        <View style={{ flex: 1 }}>
                          <Text style={[styles.font, styles.label]}>
                            {invoiceState.descBank.isLoading
                              ? "Loading..."
                              : item.descBank &&
                                `${item.descBank.attributes.bankName} (${item.descBank.attributes.branch})`}
                          </Text>
                          <Text style={[styles.font, styles.label]}>
                            {item.listBank &&
                              item.listBank[_getIndexBank(item)].attributes
                                .title}
                          </Text>
                        </View>
                      </View>
                    )}

                    <View
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        flexDirection: "row"
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text style={[styles.font, styles.label]}>
                          {t("cash.new.description")}
                        </Text>
                        <TextArea
                          onChange={e =>
                            dispatch(
                              changeFormPayment(
                                { formName: "desc", formValue: e.target.value },
                                index
                              )
                            )
                          }
                          value={item.desc || ""}
                          bold
                          fontSize="small"
                        />
                      </View>

                      {_getMethodActive() === "Bank" && (
                        <View style={{ flex: 1, paddingLeft: 15 }}>
                          <Text style={[styles.font, styles.label]}>
                            {t("cash.new.transferFee")}
                          </Text>
                          <input
                            className="container-content-input"
                            style={{
                              color: "#666666",
                              fontWeight: "bold",
                              fontSize: 11,
                              height: 32
                            }}
                            value={item.fee || ""}
                            onChange={e =>
                              dispatch(
                                changeFormPayment(
                                  {
                                    formName: "fee",
                                    formValue: e.target.value
                                  },
                                  index
                                )
                              )
                            }
                          />
                        </View>
                      )}
                    </View>

                    {invoiceState.invoiceDetailList.list.map((e, i) => {
                      if (_subList(e, item)) {
                        num += 1;
                      }

                      return (
                        _subList(e, item) && (
                          <View
                            style={[styles.wrapInputCash, { marginBottom: 20 }]}
                            key={i}
                          >
                            <View style={{ width: "8%" }}>
                              <Text
                                style={[
                                  styles.font,
                                  styles.label,
                                  { fontWeight: "bold" }
                                ]}
                              >
                                {`#${num}`}
                              </Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <View>
                                <Text style={[styles.font, styles.label]}>
                                  {e.data.attributes.purpose}
                                </Text>
                                <Text
                                  style={[
                                    styles.font,
                                    styles.label,
                                    { fontWeight: "bold" }
                                  ]}
                                >
                                  {
                                    e.included[
                                      _getIndex(e, "djurnee_invoice_type")
                                    ].attributes.title
                                  }
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.wrapInputCash,
                                  { alignItems: "center" }
                                ]}
                              >
                                <View
                                  style={[
                                    styles.wrapInputCash,
                                    {
                                      alignItems: "center",
                                      flex: 1,
                                      marginRight: 10
                                    }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.font,
                                      styles.label,
                                      { marginRight: 5 }
                                    ]}
                                  >
                                    IDR
                                  </Text>
                                  <input
                                    className="container-content-input"
                                    type="number"
                                    disabled={myProfile?.user_id === 1}
                                    style={{
                                      color: "#666666",
                                      fontWeight: "bold",
                                      fontSize: 11,
                                      height: 32,
                                      width: "100%",
                                      backgroundColor:
                                        myProfile?.user_id === 1
                                          ? "#dddddd80"
                                          : COLORS.main
                                    }}
                                    value={Number(e.data.attributes.total)}
                                    onChange={res =>
                                      dispatch(
                                        changeValueInvoiceDetailList(
                                          res.target.value,
                                          i
                                        )
                                      )
                                    }
                                  />
                                </View>
                                <TouchableOpacity
                                  onPress={() =>
                                    dispatch(removeInvoiceDetailList(i))
                                  }
                                  disabled={
                                    invoiceState.invoiceDetailList.list
                                      .length === 1
                                  }
                                >
                                  <Image
                                    source={IMAGES.cross}
                                    style={{ width: 20, height: 20 }}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        )
                      );
                    })}
                    {_isVendor(item) && (
                      <ReactTooltip
                        globalEventOff="click"
                        id={`clickme${index}`}
                        type="info"
                        effect="solid"
                        place="bottom"
                        clickable
                        className="payment-tooltips"
                      >
                        {item.contacts &&
                          item.contacts.map((el: any, indexItem: number) => (
                            <View style={styles.wrapTooltip} key={indexItem}>
                              <Text
                                style={[
                                  styles.font,
                                  styles.textTooltips,
                                  { textDecorationLine: "underline" }
                                ]}
                              >
                                {el.position}
                              </Text>
                              <View style={styles.wrapTooltipName}>
                                <Text
                                  numberOfLines={1}
                                  style={[styles.font, styles.tooltipName]}
                                >
                                  {el.name}
                                </Text>
                                <TouchableOpacity
                                  activeOpacity={0.8}
                                  onPress={() => _toPhoneCall(el.number)}
                                >
                                  <Text
                                    style={[
                                      styles.font,
                                      styles.tooltipName,
                                      { margin: 0 }
                                    ]}
                                  >
                                    {el.number}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          ))}
                      </ReactTooltip>
                    )}
                  </View>
                )
              );
            })}

            <View
              style={{
                marginTop: 10,
                alignItems: "flex-end",
                marginBottom: heightBottom
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={[
                    styles.font,
                    styles.label,
                    { fontWeight: "bold", fontSize: 16, marginRight: 8 }
                  ]}
                >
                  Total
                </Text>
                <Text style={[styles.font, styles.label, { fontSize: 16 }]}>
                  IDR
                </Text>
                <Text
                  style={[
                    styles.font,
                    styles.label,
                    { fontWeight: "bold", fontSize: 16, marginLeft: 8 }
                  ]}
                >
                  {maskedMoney(
                    invoiceState.invoiceDetailList.list.reduce(
                      (a, b) => a + Number(b.data.attributes.total),
                      0
                    )
                  )}
                </Text>
              </View>
              <Text
                style={[styles.font, styles.label, { fontStyle: "italic" }]}
              >
                {t("cash.new.msg.transferFeeMsg")}
              </Text>
            </View>
          </View>
        </View>

        <Fixed position="bottom" getHeight={e => setHeightBottom(e)}>
          <View
            style={{
              backgroundColor: "#fffff7",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              style={[
                styles.button,
                { marginBottom: 24, width: 150, height: 45 }
              ]}
              onPress={_onPay}
            >
              <Text style={[styles.textButton, { fontSize: 12 }]}>
                {_isCollect() ? t("cash.new.receive") : t("cash.new.pay")}
              </Text>
            </TouchableOpacity>
          </View>
        </Fixed>
      </WrapContent>
    </>
  );
};

export default NewPayment;
