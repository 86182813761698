import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import {
  Card,
  CardStatusColor,
  Fixed,
  Modal,
  Touchable,
  WrapContent
} from "../../../../components";
import NoteRevise from "../../../TripDetail/ModalNoteRevise";
import { Reducers } from "../../../../redux/types";
import {
  convertDate,
  copyWritings,
  permissionPage,
  textToLink
} from "../../../../utils";
import {
  ModerateInvoice,
  UpdateRecurring
} from "../../../../components/ModalComponents";
import {
  getModerationReference,
  setModalRecurring
} from "../../../../redux/actions";
import { ICONS, IMAGES } from "../../../../configs";

interface Props {
  type: string;
  id: string;
}

const CashDetail = ({ type, id }: Props) => {
  const history = useHistory();
  const [moderationModal, setModerationModal] = useState(false);
  const [reviseModal, setReviseModal] = useState(false);
  const dispatch = useDispatch();
  const cashState = useSelector((state: Reducers) => state.cash);
  const { uuid } = useSelector((state: Reducers) => state.auth);
  const { t } = useTranslation();
  const custom001 = process.env.REACT_APP_CUSTOM_001 === "true";

  const selfEdit = permissionPage("invoice-self-edit");
  const copText = {
    project: copyWritings("project"),
    vendor: copyWritings("vendor")
  };

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2 || status === 5) {
      color = "#F5AA3F";
    } else if (status === 4 || status === 7) {
      color = "#ed5a28";
    }
    return color;
  };

  useEffect(() => {
    dispatch(getModerationReference(id));
  }, [dispatch]);
  const { moderationReference, isLoadingModerationReference } = useSelector(
    (state: Reducers) => state.cash
  );

  const statusDesc = (
    status: number,
    page: string,
    total: any,
    total_paid: any
  ) => {
    let label = "";
    if (status === 1) {
      label = t("cash.status.draft");
    } else if (status === 2 && page === "owned") {
      label = t("cash.status.onVerification");
    } else if (status === 2) {
      label = t("cash.status.needApproval");
    } else if (status === 3) {
      label = t("cash.status.approved");
    } else if (status === 4) {
      label = t("cash.status.revise");
    } else if (status === 5 && total_paid >= total) {
      label = t("cash.status.notSettled");
    } else if (status === 5) {
      label = t("cash.status.unpaid");
    } else if (status === 6) {
      label = t("cash.status.settled");
    } else if (status === 7) {
      label = t("cash.status.rejected");
    } else if (status === 8) {
      label = t("cash.status.expired");
    } else if (status === 9) {
      label = t("cash.status.rejectByFinance");
    }
    return label;
  };

  const _recurringStatus = (recur: number) => {
    let result = "";
    if (!cashState.cashDetail?.djurneeInvoice?.recurring) {
      result = t("cash.new.no");
    } else if (recur === 4) {
      result = t("cash.new.yearly");
    } else if (recur === 3) {
      result = t("cash.new.monthly");
    } else if (recur === 2) {
      result = t("cash.new.weekly");
    } else if (recur === 1) {
      result = t("cash.new.daily");
    } else {
      result = "-";
    }
    return result;
  };

  const _handleRenderStatusModeration = (status: number) => {
    if (status === 1) {
      return t("cash.status.waitingAnswer");
    }
    if (status === 2) {
      return t("cash.status.approved");
    }
    if (status === 3) {
      return t("cash.status.revise");
    }
    if (status === 4) {
      return t("cash.status.rejected");
    }
    return "";
  };

  const _handleConditionRecurring = () => {
    return (
      cashState.cashDetail &&
      cashState.cashDetail?.djurneeInvoice?.type?.title === "Payment Request" &&
      cashState.cashDetail?.djurneeInvoice?.recurring
    );
  };

  const _selfModeratedCashCondition = () => {
    const res =
      moderationReference &&
      moderationReference[moderationReference.length - 1].users.filter(
        (userData: any) => userData.user.id === uuid
      ).length > 0;
    const status = cashState.cashDetail && cashState.cashDetail.status;
    return res && status !== 5;
  };

  return (
    <View>
      <WrapContent
        isLoading={
          cashState.isLoadingCashDetail || isLoadingModerationReference
        }
      >
        <View style={styles.container}>
          {cashState.cashDetail && cashState.cashDetail.djurneeInvoice.trip ? (
            <Card
              statusColor={_statusColor(
                cashState.cashDetail && cashState.cashDetail.status
              )}
              statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
              statusDesc={statusDesc(
                cashState.cashDetail && cashState.cashDetail.status,
                "",
                cashState.cashDetail && cashState.cashDetail.total,
                cashState.cashDetail && cashState.cashDetail.total_paid
              )}
              statusText="Status"
              statusTextStyle={{ fontWeight: "normal" }}
            >
              <View style={styles.wrapContentCard}>
                <View style={styles.wrapCardText}>
                  <Text style={styles.textLabel}>{copText.project}</Text>
                  <View style={{ width: "75%" }}>
                    <Text style={[styles.textLabel, styles.textValueTrip]}>
                      {cashState.cashDetail &&
                        cashState.cashDetail.djurneeInvoice.trip.number}
                    </Text>
                    <Text
                      style={[
                        styles.textLabel,
                        styles.textValueTrip,
                        styles.bold
                      ]}
                    >
                      {cashState.cashDetail &&
                        cashState.cashDetail.djurneeInvoice.trip.title}
                    </Text>
                    <Text style={[styles.textLabel, styles.textValueTrip]}>
                      {cashState.cashDetail &&
                        cashState.cashDetail.djurneeInvoice.project.title}
                    </Text>
                  </View>
                </View>

                <View style={styles.wrapCardText}>
                  <Text style={styles.textLabel}>{t("cash.new.period")}</Text>
                  <Text style={[styles.textValueDate, styles.bold]}>
                    <Text style={{ fontWeight: "normal" }}>
                      {`${convertDate(
                        cashState.cashDetail &&
                          cashState.cashDetail.djurneeInvoice.trip.started_at,
                        "ddd"
                      )} `}
                    </Text>
                    {convertDate(
                      cashState.cashDetail &&
                        cashState.cashDetail.djurneeInvoice.trip.started_at,
                      "DD MMM YYYY"
                    )}
                  </Text>
                  <Text style={[styles.textValueDate, styles.bold]}>
                    <Text style={{ fontWeight: "normal" }}>
                      {` - ${convertDate(
                        cashState.cashDetail.djurneeInvoice.trip.finished_at,
                        "ddd"
                      )} `}
                    </Text>
                    {`${convertDate(
                      cashState.cashDetail.djurneeInvoice.trip.finished_at,
                      "DD MMM YYYY"
                    )}`}
                  </Text>
                </View>
                <View style={styles.wrapDetailtext}>
                  <Text style={styles.textLabel}>{t("cash.new.remarks")}</Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    {(cashState.cashDetail && cashState.cashDetail.remarks) ||
                      "-"}
                  </Text>
                </View>
              </View>
              {custom001 && (
                <TouchableOpacity
                  style={[
                    styles.wrapEditBtn,
                    {
                      top: 8,
                      opacity: cashState.cashDetail?.status > 5 ? 0.4 : 1
                    }
                  ]}
                  onPress={() =>
                    history.push(`/edit-cash/${cashState.cashDetail.id}`)
                  }
                  disabled={cashState.cashDetail?.status > 6}
                >
                  <Image
                    source={IMAGES.pencil}
                    style={{ height: 12, width: 12 }}
                  />
                </TouchableOpacity>
              )}
            </Card>
          ) : (
            <View style={styles.wrapDetailCash}>
              {custom001 && cashState.cashDetail?.djurneeInvoice?.branch && (
                <View style={styles.wrapDetailtext}>
                  <Text style={styles.textLabel}>Company</Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    {cashState.cashDetail &&
                      cashState.cashDetail.djurneeInvoice.branch.name}
                  </Text>
                </View>
              )}

              {custom001 && (
                <View style={styles.wrapDetailtext}>
                  <Text style={styles.textLabel}>External No.</Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    {cashState.cashDetail?.number2
                      ? cashState.cashDetail.number2
                      : "-"}
                  </Text>
                </View>
              )}

              <View style={styles.wrapDetailtext}>
                <Text style={styles.textLabel}>{t("cash.detail.method")}</Text>
                <Text style={[styles.textLabel, styles.textValue, styles.bold]}>
                  {cashState.cashDetail &&
                    cashState.cashDetail.djurneeInvoice.type.title}
                </Text>
              </View>

              {cashState.cashDetail &&
                cashState.cashDetail.djurneeInvoice.vendor && (
                  <View style={styles.wrapDetailtext}>
                    <Text style={styles.textLabel}>{copText.vendor}</Text>
                    <Text style={[styles.textLabel, styles.textValue]}>
                      {cashState.cashDetail &&
                        cashState.cashDetail.djurneeInvoice.vendor.name}
                    </Text>
                  </View>
                )}

              <View style={styles.wrapDetailtext}>
                <Text style={styles.textLabel}>{t("cash.new.subject")}</Text>
                <Text style={[styles.textLabel, styles.textValue, styles.bold]}>
                  {cashState.cashDetail && cashState.cashDetail.purpose}
                </Text>
              </View>

              <View style={styles.wrapDetailtext}>
                <Text style={styles.textLabel}>{t("cash.new.issued")}</Text>
                <Text style={[styles.textLabel, styles.textValue, styles.bold]}>
                  <Text style={{ fontWeight: "normal" }}>
                    {`${convertDate(
                      cashState.cashDetail && cashState.cashDetail.created_at,
                      "ddd"
                    )} `}
                  </Text>
                  {convertDate(
                    cashState.cashDetail && cashState.cashDetail.created_at,
                    "DD MMM YYYY"
                  )}
                </Text>
              </View>

              <View style={styles.wrapDetailtext}>
                <Text style={styles.textLabel}>{t("cash.detail.expired")}</Text>
                <Text style={[styles.textLabel, styles.textValue, styles.bold]}>
                  <Text style={{ fontWeight: "normal" }}>
                    {`${convertDate(
                      cashState.cashDetail && cashState.cashDetail.due_at,
                      "ddd"
                    )} `}
                  </Text>
                  {convertDate(
                    cashState.cashDetail && cashState.cashDetail.due_at,
                    "DD MMM YYYY"
                  )}
                </Text>
              </View>
              <View style={styles.wrapDetailtext}>
                <Text style={styles.textLabel}>{t("cash.new.remarks")}</Text>
                <Text style={[styles.textLabel, styles.textValue, styles.bold]}>
                  {(cashState.cashDetail && cashState.cashDetail.remarks) ||
                    "-"}
                </Text>
              </View>

              {custom001 && (
                <TouchableOpacity
                  style={[
                    styles.wrapEditBtn,
                    { opacity: cashState.cashDetail?.status > 5 ? 0.4 : 1 }
                  ]}
                  disabled={cashState.cashDetail?.status > 5}
                  onPress={() =>
                    history.push(`/edit-cash/${cashState.cashDetail.id}`)
                  }
                >
                  <Image
                    source={IMAGES.pencil}
                    style={{ height: 12, width: 12 }}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}

          <View style={styles.wrapSecondContent}>
            <View style={{ marginBottom: 12 }}>
              <Text style={[styles.poppins, styles.requestLabel]}>
                {t("cash.new.requestBy")}
              </Text>
              <Text style={[styles.poppins, styles.requestValue]}>
                {cashState.cashDetail && cashState.cashDetail.creator.name}
              </Text>
            </View>
            {_handleConditionRecurring() ? (
              <View>
                <Text style={[styles.poppins, styles.requestLabel]}>
                  {t("cash.new.recurring")}
                </Text>
                <Card
                  disabled={
                    !(
                      selfEdit &&
                      cashState.cashDetail &&
                      cashState.cashDetail?.status <= 5 &&
                      cashState.cashDetail?.creator?.id === uuid &&
                      cashState.cashDetail?.djurneeInvoice?.type?.title ===
                        "Payment Request" &&
                      cashState.cashDetail?.djurneeInvoice?.recurring
                    )
                  }
                  cardStyle={styles.recuringCard}
                  onPress={() => dispatch(setModalRecurring(true))}
                >
                  <Text style={[styles.poppins, styles.requestValue]}>
                    {_recurringStatus(
                      cashState.cashDetail &&
                        cashState.cashDetail.djurneeInvoice.recurring_period
                    )}
                  </Text>
                </Card>
              </View>
            ) : null}
          </View>
        </View>
        {moderationReference && moderationReference.length > 0 ? (
          <View style={styles.stepperContainer}>
            <View style={{ marginLeft: 36 }}>
              <Text style={styles.stepperStatus}>
                {t("cash.new.issued").toUpperCase()}
              </Text>
              <Text style={[styles.stepperAssigner, { marginBottom: 8 }]}>
                {cashState.cashDetail && cashState.cashDetail.creator.name}
              </Text>
              <View style={styles.stepperItems}>
                <View style={[styles.stepperCircle]} />
                <View style={styles.stepperLine} />
              </View>
              <Text
                style={[
                  styles.stepperAssigner,
                  { fontWeight: "bold", marginTop: 8 }
                ]}
              >
                {cashState.cashDetail &&
                  moment(
                    new Date(cashState.cashDetail.created_at).toUTCString()
                  ).format("DD MMM YY")}
              </Text>
              <Text style={styles.stepperAssigner}>
                {cashState.cashDetail &&
                  moment(
                    new Date(cashState.cashDetail.created_at).toUTCString()
                  ).format("HH:mm")}
              </Text>
            </View>
            {moderationReference &&
              moderationReference.map((item: any, index: number) => (
                <View
                  key={index}
                  style={{
                    paddingRight:
                      moderationReference.length - 1 === index ? 36 : 0
                  }}
                >
                  <Text style={styles.stepperStatus}>
                    {_handleRenderStatusModeration(item.status)}
                  </Text>
                  <Text style={[styles.stepperAssigner, { marginBottom: 8 }]}>
                    {(item.moderator && item.moderator.name) ||
                      (item.users.length > 0 &&
                        item.users[0].user.position_name)}
                  </Text>
                  <View style={styles.stepperItems}>
                    <View style={[styles.stepperCircle]} />
                    {moderationReference.length - 1 === index ? null : (
                      <View style={styles.stepperLine} />
                    )}
                  </View>
                  <Text
                    style={[
                      styles.stepperAssigner,
                      { fontWeight: "bold", marginTop: 8 }
                    ]}
                  >
                    {(item.moderator &&
                      moment(new Date(item.updated_at).toUTCString()).format(
                        "DD MMM YY"
                      )) ||
                      ""}
                  </Text>
                  <Text style={styles.stepperAssigner}>
                    {(item.moderator &&
                      moment(new Date(item.updated_at).toUTCString()).format(
                        "HH:mm"
                      )) ||
                      ""}
                  </Text>
                </View>
              ))}
          </View>
        ) : null}
        <Fixed position="bottom">
          <View
            style={{
              width: "100%",
              alignItems: "center"
            }}
          >
            {cashState.cashDetail &&
            (cashState.cashDetail.status === 2 ||
              (cashState.cashDetail.status === 5 &&
                moderationReference &&
                moderationReference.length > 0)) &&
            type === "approvable" &&
            _selfModeratedCashCondition() ? (
              <TouchableOpacity
                style={styles.btnModeration}
                onPress={() => setModerationModal(true)}
              >
                <Image
                  source={ICONS.moderationIcon}
                  style={{ height: 28, width: 28 }}
                />
              </TouchableOpacity>
            ) : null}
          </View>
        </Fixed>
        <Modal
          isVisible={moderationModal}
          backdropColor="rgba(240,240,240, 0.5)"
        >
          {cashState.cashDetail ? (
            <ModerateInvoice
              cashSettlement={cashState.cashDetail.status === 5}
              invoiceId={cashState.cashDetail.id}
              onCancel={() => setModerationModal(false)}
            />
          ) : (
            <ActivityIndicator />
          )}
        </Modal>
        <Modal
          isVisible={cashState.setModalRecurring}
          backdropColor="rgba(255,255,255, 0.9)"
        >
          <UpdateRecurring
            onCancel={() => dispatch(setModalRecurring(false))}
          />
        </Modal>
        <Modal isVisible={reviseModal} backdropColor="rgba(255,255,255, 0.9)">
          <NoteRevise
            headerTitle={t("moderation.notes")}
            onPress={() => setReviseModal(false)}
            // onPressRevise={() => _openResolveModal()}
          >
            <Text>
              <span
                dangerouslySetInnerHTML={textToLink(
                  (moderationReference !== null &&
                    moderationReference.length > 0 &&
                    moderationReference[moderationReference.length - 1]
                      .revise &&
                    moderationReference[moderationReference.length - 1]
                      .remarks) ||
                    "-"
                )}
              />
            </Text>
          </NoteRevise>
        </Modal>

        {moderationReference !== null &&
        moderationReference.length > 0 &&
        moderationReference[moderationReference.length - 1].revise &&
        moderationReference[moderationReference.length - 1].remarks ? (
          <div
            style={{
              position: "fixed",
              paddingLeft: 20,
              paddingBottom: 21.7,
              paddingTop: 5,
              alignSelf: "flex-start",
              bottom: 0
            }}
          >
            <Touchable onPress={() => setReviseModal(true)}>
              <Image
                style={{ width: 62.6, height: 62.6 }}
                source={IMAGES.reviseAlert}
              />
            </Touchable>
          </div>
        ) : null}
      </WrapContent>
    </View>
  );
};

export default CashDetail;
