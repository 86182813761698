import { StyleSheet } from "react-native";
import { widthPercent } from "../../utils";

const styles = StyleSheet.create({
  containerContent: {
    marginTop: 16,
    width: widthPercent(100) - 40,
    height: 32,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    borderRadius: 100,
    flex: 1,
    flexDirection: "row"
  },
  buttonNav: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%"
  },
  buttonNavFull: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  active: {
    borderRadius: 100,
    height: 32,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  content: {
    alignItems: "center",
    width: widthPercent(100) - 40,
    zIndex: -1,
    marginTop: 20
  }
});

export default styles;
