/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Controller, useForm } from "react-hook-form";
import {
  getConfigUploadFile,
  getListAirport,
  getListCountry,
  getPopulateCategory,
  getPopulateChildProject,
  getPopulateProject,
  getRegion,
  getTaskListDropdown,
  getTermDataActivity,
  handleFormAcitivy,
  postGenerateAllowance,
  postTermDataActivity,
  removePopulateChildProject,
  resetPopulateChildProject,
  setPopulateChildProject
} from "../../redux/actions";
import { Reducers } from "../../redux/types";
import {
  copyWritings,
  encodeBase64,
  getPolicies,
  // locatToUtc,
  openPreviewBase64,
  toast
} from "../../utils";

import styles from "./style";
import "./styles.css";
import {
  AutoComplete,
  FileUpload,
  Fixed,
  Header,
  Modal,
  Select,
  TextArea,
  Touchable,
  Upload,
  UserPicker,
  WrapContent
} from "../../components";
import { ModalConfirmForm } from "../../components/ModalComponents";
import Card from "./components/Card";
import { API, IMAGES } from "../../configs";
import { Footer } from "./components";

const data = [
  {
    trip: [
      {
        title: "Trip 1",
        cityFrom: "Jakarta",
        dateFrom: "20 January",
        cityTo: "Denpasar",
        dateTo: "21 January"
      }
    ],
    profile: [
      {
        image: IMAGES.plus,
        title: ""
      }
    ]
  }
];

interface Employee {
  id: string;
  name: string;
  photo: string | null;
  title: string;
}

const NewTrip = () => {
  const { id }: any = useParams();

  const history = useHistory();
  const { sprintf } = require("sprintf-js");
  const dispatch = useDispatch();
  const typeEmployee: Employee[] = [];
  const { t } = useTranslation();
  const [employees, setEmployees] = useState(typeEmployee);
  const [showPickDestination, setShowPickDestination] = useState(false);
  const [selectedCountryDepartureId, setSelectedCountryDepartureId] = useState(
    "ID"
  );

  const [firstParentProject, setFirstParentProject] = useState("");

  const [selectedCountryArrivalId, setSelectedCountryArrivalId] = useState(
    "ID"
  );
  const [countryDepartureIdTemp, setCountryDepartureIdTemp] = useState("");
  const [countryArrivalIdTemp, setCountryArrivalIdTemp] = useState("");

  const [
    selectedCountryDepartureName,
    setSelectedCountryDepartureName
  ] = useState("Indonesia");
  const [selectedCountryArrivalName, setSelectedCountryArrivalName] = useState(
    "Indonesia"
  );
  const [countryDepartureNameTemp, setCountryDepartureNameTemp] = useState("");
  const [countryArrivalNameTemp, setCountryArrivalNameTemp] = useState("");
  const [showUserPicker, setShowUserPicker] = useState(false);
  const [listFileName, setListFileName]: any = useState([]);

  const [tempRegionArrival, setTempRegionArrival] = useState("");
  const [inputRegionArrival, setInputRegionArrival] = useState("");
  const [idRegionArrival, setIdRegionArrival] = useState("");
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [valueModalConfirm, setValueModalConfirm]: any = useState();

  const [isErrorActivityPicker, setErrorActivityPicker] = useState(false);
  const [isErrorFormActivity, setErrorFormActivity] = useState(false);
  const [heightFooter, setHeightFooter] = useState(0);
  const [notSet, setNotSet] = useState(false);
  const {
    newTripState,
    auth,
    regionState,
    tripState,
    countries,
    departureAirports,
    arrivalAirports,
    taskListDropDown,
    token
  } = useSelector(
    (state: Reducers) => ({
      newTripState: state.newTrip,
      auth: state.auth,
      regionState: state.region,
      tripState: state.trip,
      countries: state.regency.countries,
      departureAirports: state.regency.departureAirports,
      arrivalAirports: state.regency.arrivalAirports,
      taskListDropDown: state.task.taskListDropdown,
      token: state.auth.token
    }),
    shallowEqual
  );

  const [idRegionDeparture, setIdRegionDeparture] = useState(
    auth.companyAddress[0].regency.id
  );

  const populateDeparture = () => {
    let result: any[] = [];
    if (selectedCountryDepartureId === "ID") {
      result = regionState.region.map(e => {
        return { value: e.id, label: e.name };
      });
    } else {
      result = departureAirports.map(e => {
        return {
          value: e.id,
          label: e.attributes.name,
          code: e.attributes.airport_code
        };
      });
    }
    return result;
  };

  const populateArrival = () => {
    let result: any[] = [];
    if (selectedCountryArrivalId === "ID") {
      result = regionState.region.map(e => {
        return { value: e.id, label: e.name };
      });
    } else {
      result = arrivalAirports.map(e => {
        return {
          value: e.id,
          label: e.attributes.name,
          code: e.attributes.airport_code
        };
      });
    }
    return result;
  };

  const _getValuetOption = useCallback((optionData, optionId) => {
    if (optionId) {
      return {
        label: optionData.find((e: any) => e.value === optionId)?.label || ""
      };
    }
    return "";
  }, []);

  const typeForm: any = {
    category_id: "",
    description: "",
    destination_location_id: "",
    finished_at_d: "",
    finished_at_t: "",
    finished_at: "",
    origin_location_id: "",
    participants: auth.userId,
    project_id: "",
    self_booking: true,
    project: true,
    started_at: "",
    started_at_d: "",
    started_at_t: "",
    task_id: "",
    origin_country_code_id: "",
    destination_country_code: "",
    title: "",
    participants_data: [auth.userId],
    file: null,
    client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
  const [form, setForm] = useState(typeForm);
  const [formTemp, setFormTemp] = useState(typeForm);
  const [projectChild, setProjectChild] = useState<string[]>([]);

  const _getParentProject = async () => {
    const childId = tripState?.activity?.data?.new_activity?.project_id;
    if (childId && projectChild.length === 0) {
      await API.getParentProject(childId, token).then(async res => {
        const arrParent: any[] = res.data.data;
        if (arrParent.length > 0) {
          arrParent.reverse();
          const parentProjectIdList = [...arrParent, childId];
          setFirstParentProject(parentProjectIdList[0]);

          for (let index = 0; index < parentProjectIdList.length; index += 1) {
            /* eslint-disable no-await-in-loop */
            const response = await API.getPopulateProject(token, {
              parent_id: parentProjectIdList[index]
            });
            dispatch(setPopulateChildProject(response.data?.data, index));
          }
          parentProjectIdList.shift();
          setProjectChild(parentProjectIdList);
        } else {
          setFirstParentProject(childId);
          setProjectChild([]);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getPopulateCategory());
    dispatch(getPopulateProject({ show_top_parent: true }));
    dispatch(getRegion());
    dispatch(getConfigUploadFile());
    dispatch(getListCountry());
    dispatch(getTaskListDropdown(""));
    dispatch(getTermDataActivity());
  }, [dispatch]);

  useEffect(() => {
    _getParentProject();
  }, [tripState.activity.data?.new_activity?.project_id]);

  useEffect(() => {
    if (selectedCountryDepartureId !== "ID") {
      dispatch(getListAirport(selectedCountryDepartureId, "departure"));
    }
  }, [selectedCountryDepartureId, dispatch]);

  useEffect(() => {
    if (selectedCountryArrivalId !== "ID") {
      dispatch(getListAirport(selectedCountryArrivalId, "arrival"));
    }
  }, [selectedCountryArrivalId, dispatch]);

  useEffect(() => {
    if (taskListDropDown.length > 0) {
      setForm({ ...form, task_id: taskListDropDown[0].id });
    }
  }, [taskListDropDown]);

  const policyMinInTownDate = getPolicies("trip-min-date-in-town");
  const policyMaxInTownDate = getPolicies("trip-max-date-in-town");
  const policyMinDomesticDate = getPolicies("trip-min-date-domestic");
  const policyMaxDomesticDate = getPolicies("trip-max-date-domestic");

  const _handleShowPolicyDate = (
    policyDateMinInTown: any,
    policyDateMaxInTown: any,
    policyDateMinDomestic: any,
    policyDateMaxDomestic: any,
    dateFrom: any
  ) => {
    if (form.origin_location_id === form.destination_location_id) {
      if (dateFrom < policyDateMinInTown) {
        return policyMinInTownDate;
      }
      if (dateFrom > policyDateMaxInTown) {
        return policyMaxInTownDate;
      }
    } else {
      if (dateFrom < policyDateMinDomestic) {
        return policyMinDomesticDate;
      }
      if (dateFrom > policyDateMaxDomestic) {
        return policyMaxDomesticDate;
      }
    }
    return null;
  };

  const dateFrom = new Date(moment(form.started_at_d).format("DD MMMM YYYY"));
  const policyDateMinInTown = new Date(
    moment().add(policyMinInTownDate, "days").format("DD MMMM YYYY")
  );
  const policyDateMaxInTown = new Date(
    moment().add(policyMaxInTownDate, "days").format("DD MMMM YYYY")
  );
  const policyDateMinDomestic = new Date(
    moment().add(policyMinDomesticDate, "days").format("DD MMMM YYYY")
  );
  const policyDateMaxDomestic = new Date(
    moment().add(policyMaxDomesticDate, "days").format("DD MMMM YYYY")
  );

  const _handleShowWarning = () => {
    if (
      form.started_at_d &&
      (((dateFrom < policyDateMinInTown || dateFrom > policyDateMaxInTown) &&
        form.destination_location_id === form.origin_location_id) ||
        ((dateFrom < policyDateMinDomestic ||
          dateFrom > policyDateMaxDomestic) &&
          form.destination_location_id !== form.origin_location_id)) &&
      form.origin_location_id &&
      form.destination_location_id
    ) {
      return true;
    }
    return false;
  };

  const _handleCancelPickerActivity = () => {
    setErrorActivityPicker(false);
    setForm(formTemp);
    setShowPickDestination(false);
    setSelectedCountryDepartureName(countryDepartureNameTemp);
    setSelectedCountryArrivalName(countryArrivalNameTemp);
    setInputRegionArrival(tempRegionArrival);
    setSelectedCountryArrivalId(countryArrivalIdTemp);
    setSelectedCountryDepartureId(countryDepartureIdTemp);
    !tripState.activity.data?.new_activity?.trip?.origin_location_id &&
      setIdRegionDeparture(auth.companyAddress[0].regency.id);
    !tripState.activity.data?.new_activity?.trip?.destination_location_id &&
      setIdRegionArrival("");
  };

  const _handleConditionErrorPicker = useCallback(() => {
    return (
      !idRegionDeparture ||
      !idRegionArrival ||
      form.started_at_d === null ||
      form.started_at_d === "" ||
      form.started_at_t === null ||
      form.started_at_t === "" ||
      form.finished_at_d === null ||
      form.finished_at_d === "" ||
      form.finished_at_t === null ||
      form.finished_at_t === ""
    );
  }, [
    idRegionDeparture,
    idRegionArrival,
    form.started_at_d,
    form.started_at_t,
    form.finished_at_d,
    form.finished_at_t
  ]);

  const copText = {
    trip: copyWritings("trip"),
    project: copyWritings("project"),
    task: copyWritings("task")
  };

  const _getCountryTitle = useCallback(
    (idCountry: string) => {
      const getIndex = countries.findIndex(item => item.id === idCountry);

      return countries[getIndex] && countries[getIndex].title;
    },
    [countries]
  );

  const _getRegionTitle = useCallback(
    (idRegion: string) => {
      const getIndex = regionState.region.findIndex(
        item => item.id === idRegion
      );

      return regionState.region[getIndex] && regionState.region[getIndex].name;
    },
    [regionState.region]
  );

  const _getDepartureAirpotTitle = useCallback(
    (idAirpot: string) => {
      const getIndex = departureAirports.findIndex(
        item => item.id === idAirpot
      );

      return (
        departureAirports[getIndex] &&
        departureAirports[getIndex].attributes.name
      );
    },
    [departureAirports]
  );

  const _getArrivalAirpotTitle = useCallback(
    (idAirpot: string) => {
      const getIndex = arrivalAirports.findIndex(item => item.id === idAirpot);

      return (
        arrivalAirports[getIndex] && arrivalAirports[getIndex].attributes.name
      );
    },
    [arrivalAirports]
  );

  useEffect(() => {
    if (
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.trip &&
      !notSet
    ) {
      const formTrip = tripState.activity.data.new_activity.trip;
      setForm({
        ...form,
        started_at_d: moment(formTrip.started_at).format("YYYY-MM-DD"),
        started_at_t: moment(formTrip.started_at).format("HH:mm"),
        finished_at_d: moment(formTrip.finished_at).format("YYYY-MM-DD"),
        finished_at_t: moment(formTrip.finished_at).format("HH:mm")
      });
      setSelectedCountryDepartureId(formTrip.origin_country_code);
      setSelectedCountryDepartureName(
        _getCountryTitle(formTrip.origin_country_code)
      );
      setSelectedCountryArrivalId(formTrip.destination_country_code);
      setSelectedCountryArrivalName(
        _getCountryTitle(formTrip.destination_country_code)
      );

      setIdRegionDeparture(formTrip.origin_location_id);
      setInputRegionArrival(
        formTrip.origin_country_code === "ID"
          ? _getRegionTitle(formTrip.destination_location_id)
          : _getArrivalAirpotTitle(formTrip.destination_location_id)
      );
      setIdRegionArrival(formTrip.destination_location_id);
    }
  }, [
    notSet,
    tripState.activity.data.new_activity,
    _getCountryTitle,
    _getRegionTitle,
    _getDepartureAirpotTitle,
    _getArrivalAirpotTitle
  ]);

  let title = sprintf(t("activity.new.newActivity"), copText.trip);
  if (history.location.pathname === "/assigntrip") {
    title = "New Assignment";
  }
  if (id) {
    title = sprintf(t("activity.new.editTrip"), copText.trip);
  }

  const _convertDataTaskForSelect = useCallback(() => {
    let listPopulateCategory = [{ label: t("activity.new.select"), value: "" }];
    taskListDropDown.map((item: any) => {
      listPopulateCategory = [
        ...listPopulateCategory,
        { value: item.id, label: item.attributes.title }
      ];
    });
    return listPopulateCategory;
  }, [taskListDropDown]);

  const _onProjectChange = (projectId: string, index: number) => {
    dispatch(getPopulateChildProject({ parent_id: projectId }, index));
  };

  const _setForm = useCallback(
    (formName: string, formValue: any) => {
      if (formName === "project_id") {
        dispatch(
          handleFormAcitivy("new_activity", {
            ...tripState.activity.data.new_activity,
            [formName]: formValue,
            is_project: true,
            task_id: null
          })
        );
      } else if (formName === "task_id") {
        dispatch(
          handleFormAcitivy("new_activity", {
            ...tripState.activity.data.new_activity,
            [formName]: formValue,
            is_project: false,
            project_id: null
          })
        );
      } else if (formName === "is_project") {
        if (formValue) {
          dispatch(
            handleFormAcitivy("new_activity", {
              ...tripState.activity.data.new_activity,
              [formName]: formValue,
              task_id: null
            })
          );
        } else {
          dispatch(
            handleFormAcitivy("new_activity", {
              ...tripState.activity.data.new_activity,
              [formName]: formValue,
              project_id: null
            })
          );
        }
      } else if (formName === "self_booking") {
        dispatch(
          handleFormAcitivy("new_activity", {
            ...tripState.activity.data.new_activity,
            [formName]: formValue,
            is_project: tripState.activity.data.new_activity
              ? tripState.activity.data.new_activity.is_project
              : true
          })
        );
      } else {
        dispatch(
          handleFormAcitivy("new_activity", {
            ...tripState.activity.data.new_activity,
            [formName]: formValue,
            is_project: tripState.activity.data.new_activity
              ? tripState.activity.data.new_activity.is_project
              : true
          })
        );
      }
    },
    [dispatch, tripState.activity.data.new_activity]
  );

  const _uploadDocument = useCallback(
    async (e: any) => {
      const files = [...e.target.files];
      let result: any = [];
      setListFileName([...listFileName, ...files]);
      files.map(async (item, index) => {
        const base64 = await encodeBase64(item);
        result = [...result, base64];

        if (index === files.length - 1) {
          const mergeData = [
            ...result,
            ...((tripState.activity.data.new_activity &&
              tripState.activity.data.new_activity.files) ||
              [])
          ];
          _setForm(
            "files",
            mergeData.filter(
              (res, i) => mergeData.findIndex(es => es === res) === i
            )
          );
        }
        return true;
      });
    },
    [tripState.activity.data.new_activity, auth.myConfig.max_size, _setForm]
  );

  const _handleSubmitPickerActivity = useCallback(() => {
    if (_handleConditionErrorPicker()) {
      setErrorActivityPicker(true);
    } else {
      setShowPickDestination(false);
      _setForm("trip", {
        origin_country_code: selectedCountryDepartureId,
        origin_location_id: idRegionDeparture,
        started_at: new Date(
          `${form.started_at_d} ${form.started_at_t}`
        ).toUTCString(),
        destination_country_code: selectedCountryArrivalId,
        destination_location_id: idRegionArrival,
        finished_at: new Date(
          `${form.finished_at_d} ${form.finished_at_t}`
        ).toUTCString(),
        client_timezone: "Asia/Jakarta"
      });
    }
  }, [_setForm, _handleConditionErrorPicker]);

  const { control } = useForm();

  const [parentProjectId, setParentProjectId] = useState("");

  const _handleOpenModalConfirm = () => {
    const formModal = tripState.activity.data?.new_activity;
    const listCategory = newTripState.listPopulateCategory;
    const listProject = newTripState.listPopulateProject;
    const listTask = _convertDataTaskForSelect();
    const listRegencies = regionState && regionState.region;
    const listProjectChild = newTripState.listPopulateChildProject;
    let attachments: any = [];
    if (listFileName.length > 0) {
      listFileName.map((fileName: any) => {
        attachments = [...attachments, fileName.name];
      });
    }

    const getProjectName = () => {
      const projectName = listProject.filter(
        (itemProject: any) => itemProject.value === formModal.project_id
      )[0]?.label;

      if (projectName) return projectName;

      const _tempListProjectChild: any = [];

      listProjectChild.map((item: any) => {
        item.map((itemData: any) => _tempListProjectChild.push(itemData));
      });

      const projectNameChild = _tempListProjectChild.filter(
        (itemProject: any) => itemProject.value === formModal.project_id
      )[0]?.label;

      if (projectNameChild) return projectNameChild;

      return "-";
    };
    // disini
    const dataModal = [
      {
        title: formModal.is_project
          ? t("activity.new.projectName")
          : t("activity.new.taskName"),
        value: formModal.is_project
          ? getProjectName()
          : listTask.filter(
              (itemTask: any) => itemTask.value === formModal.task_id
            )[0].label
      },
      {
        title: t("activity.new.categoryName"),
        value: listCategory.filter(
          (itemCategory: any) => itemCategory.value === formModal.category_id
        )[0]?.label
      },
      {
        title: t("activity.new.subject"),
        value: formModal.subject
      },
      {
        title: t("activity.new.originCountry"),
        value: countries.filter(
          (itemCountry: any) =>
            itemCountry.id === formModal.trip.origin_country_code
        )[0]?.title
      },
      {
        title: t("activity.new.originLocation"),
        value:
          formModal.trip.origin_country_code === "ID"
            ? listRegencies.filter(
                (itemRegency: any) =>
                  itemRegency.id === formModal.trip.origin_location_id
              )[0].name
            : departureAirports.filter(
                (departureAirport: any) =>
                  departureAirport.id === formModal.trip.origin_location_id
              )[0].attributes.name
      },
      {
        title: t("activity.new.departureDate"),
        value: moment(formModal.trip.started_at).format("DD MMMM YYYY")
      },
      {
        title: t("activity.new.departureTime"),
        value: moment(formModal.trip.started_at).format("HH:mm")
      },
      {
        title: t("activity.new.destinationCountry"),
        value: countries.filter(
          (itemCountry: any) =>
            itemCountry.id === formModal.trip.destination_country_code
        )[0].title
      },
      {
        title: t("activity.new.destinationLocation"),
        value:
          formModal.trip.destination_country_code === "ID"
            ? listRegencies.filter(
                (itemRegency: any) =>
                  itemRegency.id === formModal.trip.destination_location_id
              )[0].name
            : arrivalAirports.filter(
                (arrivalAirport: any) =>
                  arrivalAirport.id === formModal.trip.destination_location_id
              )[0].attributes.name
      },
      {
        title: t("activity.new.arrivalDate"),
        value: moment(formModal.trip.finished_at).format("DD MMMM YYYY")
      },
      {
        title: t("activity.new.arrivalTime"),
        value: moment(formModal.trip.finished_at).format("HH:mm")
      },
      {
        title: t("activity.new.attachment"),
        value: attachments.length > 0 ? attachments : "-"
      }
    ];
    setValueModalConfirm(dataModal);
    setModalSubmitForm(true);
  };

  const _validateNext = () => {
    let success = false;
    let message = "";
    const subject =
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.subject;
    const categoryId =
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.category_id;
    const projectId =
      tripState.activity.data.new_activity &&
      tripState.activity.data?.new_activity?.project_id;
    const taskId =
      tripState.activity.data.new_activity &&
      tripState.activity.data.new_activity.task_id;
    const today = moment().format("YYYY-MM-DD");
    const todayTime = moment().format("YYYY-MM-DD HH:mm");
    const from = moment(`${form.started_at_d} ${form.started_at_t}`).format(
      "YYYY-MM-DD HH:mm"
    );
    const to = moment(`${form.finished_at_d} ${form.finished_at_t}`).format(
      "YYYY-MM-DD HH:mm"
    );

    if (tripState.activity.data.new_activity.is_project && !projectId) {
      setErrorFormActivity(true);
      success = false;
      message = t("activity.detail.msg.projectReq");
    } else if (!tripState.activity.data.new_activity.is_project && !taskId) {
      setErrorFormActivity(true);
      success = false;
      message = t("activity.detail.msg.taskReq");
    } else if (!categoryId) {
      setErrorFormActivity(true);
      success = false;
      message = t("activity.detail.msg.categoryReq");
    } else if (!subject || form.subject === "") {
      setErrorFormActivity(true);
      success = false;
      message = t("activity.detail.msg.subjectReq");
    } else if (today > from) {
      success = false;
      message = String(t("activity.detail.msg.todayReq"));
    } else if (from > to) {
      success = false;
      message = t("activity.detail.msg.dateFromReq");
    } else if (todayTime > to) {
      success = false;
      message = t("activity.detail.msg.dateReq");
    } else {
      success = true;
    }

    if (success) {
      setErrorFormActivity(false);
      _handleOpenModalConfirm();
    } else {
      toast.error(`${message}`);
    }
  };

  const submitRecord = () => {
    dispatch(
      postTermDataActivity(false, () => {
        dispatch(
          postGenerateAllowance(() =>
            history.push("newtrip/newexpense", { noLoad: true })
          )
        );
      })
    );
  };

  const _regionName = (
    typeRegion: "departure" | "arrival",
    countryId: string,
    regionId: string
  ) => {
    let result = "";
    if (typeRegion === "departure") {
      if (countryId === "ID") {
        result = regionState.region.find(e => e.id === regionId)?.name;
      } else {
        result = departureAirports.find(e => e.id === regionId)?.attributes
          ?.name;
      }
    } else if (typeRegion === "arrival") {
      if (countryId === "ID") {
        result = regionState.region.find(e => e.id === regionId)?.name;
      } else {
        result = arrivalAirports.find(e => e.id === regionId)?.attributes?.name;
      }
    }
    return result;
  };

  return (
    <View>
      <Header
        title={title}
        label={
          history.location.pathname === "/assigntrip"
            ? ""
            : t("activity.status.draft")
        }
        goBack={() => history.push("/trip")}
      />
      <WrapContent
        isLoading={tripState.isLoadingTripById || tripState.activity.isLoading}
      >
        <View style={[styles.newTripContent, { paddingBottom: heightFooter }]}>
          {data.map((_, indexData) => {
            return (
              <View key={indexData.toString()} style={{ zIndex: 1 }}>
                {history.location.pathname === "/assigntrip" ? (
                  <>
                    {showUserPicker ? (
                      <UserPicker
                        value={employees}
                        onBackdropPress={() => setShowUserPicker(false)}
                        onChange={employee => setEmployees(employee)}
                      />
                    ) : (
                      <></>
                    )}
                    {employees.length > 0 ? (
                      <FlatList
                        data={employees}
                        keyExtractor={(item: { id: string }) => item.id}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                          <>
                            <View
                              key={index}
                              style={{ flexDirection: "row", marginBottom: 18 }}
                            >
                              <View style={styles.imgContainer}>
                                <Image
                                  source={
                                    item.photo === null
                                      ? IMAGES.avatar
                                      : item.photo
                                  }
                                  style={styles.newTripFoto}
                                />
                                <Text
                                  style={{
                                    fontSize: 11,
                                    color: "#666666",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {item.name}
                                </Text>
                              </View>
                            </View>
                            {employees.length - 1 === index ? (
                              <View style={{ marginHorizontal: 10 }}>
                                <Touchable
                                  onPress={() => setShowUserPicker(true)}
                                >
                                  <>
                                    <Image
                                      source={IMAGES.plus}
                                      style={{ width: 60, height: 60 }}
                                    />
                                    <View style={{ alignItems: "center" }}>
                                      <Text style={styles.buttonAssign}>
                                        Assign
                                      </Text>
                                    </View>
                                  </>
                                </Touchable>
                              </View>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      />
                    ) : (
                      <View style={{ marginHorizontal: 10, width: 60 }}>
                        <Touchable onPress={() => setShowUserPicker(true)}>
                          <>
                            <Image
                              source={IMAGES.plus}
                              style={{ width: 60, height: 60 }}
                            />
                            <View style={{ alignItems: "center" }}>
                              <Text style={styles.buttonAssign}>Assign</Text>
                            </View>
                          </>
                        </Touchable>
                      </View>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <View style={styles.warningBox}>
                  {_handleShowWarning() ? (
                    <Text>
                      Your start date trip is currently violated our
                      {dateFrom < policyDateMinInTown ||
                      dateFrom < policyDateMinDomestic
                        ? " minimum "
                        : " maximum "}
                      date policy that your company set,
                      <Text style={{ fontWeight: "bold" }}>
                        {`${_handleShowPolicyDate(
                          policyDateMinInTown,
                          policyDateMaxInTown,
                          policyDateMinDomestic,
                          policyDateMaxDomestic,
                          dateFrom
                        )} `}
                      </Text>
                      days from current date as the
                      {dateFrom < policyDateMinInTown ||
                      dateFrom < policyDateMinDomestic
                        ? " minimum "
                        : " maximum "}
                      date you can pick for your
                      {form.origin_location_id === form.destination_location_id
                        ? " in town "
                        : " domestic "}
                      trip, and acording your company regulation, this violation
                      will be recorded
                    </Text>
                  ) : null}
                </View>
                <View style={styles.inputContainer}>
                  <Text style={[styles.labelInput]}>
                    {(
                      tripState.activity.data.new_activity
                        ? tripState.activity.data.new_activity.is_project
                        : true
                    )
                      ? copText.project
                      : copText.task}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 20
                    }}
                  >
                    {/* disini */}
                    <View style={{ width: "55%" }}>
                      {(
                        tripState.activity.data.new_activity
                          ? tripState.activity.data.new_activity.is_project
                          : true
                      ) ? (
                        <>
                          {!newTripState.isLoadingPopulateProject ? (
                            <Controller
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeHolder={t("task.assign.chooseProject")}
                                  fontSize={12}
                                  value={_getValuetOption(
                                    newTripState.listPopulateProject,
                                    value ||
                                      (tripState.activity.data.new_activity
                                        .project_id &&
                                        firstParentProject) ||
                                      tripState.activity.data.new_activity
                                        .project_id
                                  )}
                                  option={newTripState.listPopulateProject}
                                  onValueChange={e => {
                                    _setForm("project_id", e.value);
                                    _onProjectChange(e.value, 0);
                                    onChange(e.value);
                                    setParentProjectId(e.value);
                                    setProjectChild([]);
                                  }}
                                  isLoading={
                                    newTripState.isLoadingPopulateProject
                                  }
                                />
                              )}
                              name="project_id"
                              rules={{ required: true }}
                              defaultValue=""
                            />
                          ) : (
                            <ActivityIndicator color="#344370" />
                          )}

                          {newTripState.listPopulateChildProject.map(
                            (item, i) => (
                              <View key={`childProject-${i}`}>
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                  }}
                                >
                                  <View style={{ flex: 1 }}>
                                    <AutoComplete
                                      placeHolder={t(
                                        "task.assign.chooseProject"
                                      )}
                                      fontSize={12}
                                      value={_getValuetOption(
                                        item,
                                        projectChild[i]
                                      )}
                                      option={item}
                                      onValueChange={e => {
                                        _onProjectChange(e.value, i + 1);
                                        const projectChildTmp = projectChild.map(
                                          v => v
                                        );
                                        projectChildTmp[i] = e.value;
                                        setProjectChild(projectChildTmp);
                                        _setForm("project_id", e.value);
                                      }}
                                      isLoading={
                                        newTripState.isLoadingPopulateProject
                                      }
                                    />
                                  </View>
                                  <TouchableOpacity
                                    onPress={() => {
                                      dispatch(removePopulateChildProject(i));
                                      const projectChildTmp = projectChild.filter(
                                        (itemData, index) => index < i
                                      );
                                      setProjectChild(projectChildTmp);

                                      if (projectChildTmp.length > 0) {
                                        _setForm(
                                          "project_id",
                                          projectChildTmp[
                                            projectChildTmp.length - 1
                                          ]
                                        );
                                      } else {
                                        _setForm("project_id", parentProjectId);
                                      }
                                    }}
                                    style={[
                                      {
                                        height: 25,
                                        width: 25,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: 0
                                      }
                                    ]}
                                  >
                                    <Image
                                      source={IMAGES.trashBin}
                                      style={{ width: 13, height: 16 }}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            )
                          )}

                          {isErrorFormActivity &&
                          (!tripState.activity.data?.new_activity?.project_id ||
                            tripState.activity.data?.new_activity
                              ?.project_id === "") ? (
                            // eslint-disable-next-line react/jsx-indent
                            <Text style={styles.textValidate}>
                              {t("activity.detail.msg.req")}
                            </Text>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <Card cardStyle={styles.cardMultiTrip}>
                            <Select
                              listValue={_convertDataTaskForSelect()}
                              onChange={e =>
                                _setForm("task_id", e.target.value)
                              }
                              style={
                                tripState.activity.data.new_activity &&
                                tripState.activity.data.new_activity.task_id
                                  ? { opacity: 1 }
                                  : { opacity: 0.6 }
                              }
                              value={
                                (tripState.activity.data.new_activity &&
                                  tripState.activity.data.new_activity
                                    .task_id) ||
                                ""
                              }
                            />
                          </Card>
                          {isErrorFormActivity &&
                          (!tripState.activity.data.new_activity.task_id ||
                            tripState.activity.data.new_activity.task_id ===
                              null) ? (
                            // eslint-disable-next-line react/jsx-indent
                            <Text style={styles.textValidate}>
                              {t("activity.detail.msg.req")}
                            </Text>
                          ) : null}
                        </>
                      )}
                    </View>
                    <View>
                      <View style={[styles.wrapSwitch]}>
                        <Switch
                          onChange={() => {
                            _setForm(
                              "is_project",
                              tripState.activity.data.new_activity
                                ? !tripState.activity.data.new_activity
                                    .is_project
                                : false
                            );
                            setProjectChild([]);
                            dispatch(resetPopulateChildProject());
                            setFirstParentProject("");
                          }}
                          checked={
                            tripState.activity.data.new_activity
                              ? tripState.activity.data.new_activity.is_project
                              : true
                          }
                          onColor="#50b1b1"
                          onHandleColor="#fffff7"
                          handleDiameter={19}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                          height={19}
                          width={34}
                        />

                        <Text style={[styles.textSelfBooking, { width: 60 }]}>
                          {(
                            tripState.activity.data.new_activity
                              ? tripState.activity.data.new_activity.is_project
                              : true
                          )
                            ? copText.project
                            : copText.task}
                        </Text>
                      </View>
                      <View style={[styles.wrapSwitch]}>
                        <Switch
                          onChange={() => {
                            _setForm(
                              "self_booking",
                              tripState.activity.data.new_activity
                                ? !tripState.activity.data.new_activity
                                    .self_booking
                                : false
                            );
                          }}
                          checked={
                            tripState.activity.data.new_activity
                              ? tripState.activity.data.new_activity
                                  .self_booking
                              : true
                          }
                          onColor="#50b1b1"
                          onHandleColor="#fffff7"
                          handleDiameter={19}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                          height={19}
                          width={34}
                        />

                        <Text style={[styles.textSelfBooking]}>
                          Self Booking
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "100%", marginBottom: 20 }}>
                    <Text style={[styles.labelInput]}>
                      {t("activity.new.category")}
                    </Text>
                    <Card cardStyle={styles.cardMultiTrip}>
                      {!newTripState.isLoadingPopulateCategory &&
                      newTripState.listPopulateCategory.length > 0 ? (
                        <Select
                          emptyLabel={t("activity.invoice.selectCategory")}
                          listValue={newTripState.listPopulateCategory}
                          style={
                            tripState.activity.data.new_activity &&
                            tripState.activity.data.new_activity.category_id
                              ? { opacity: 1 }
                              : { opacity: 0.6 }
                          }
                          onChange={e => {
                            _setForm("category_id", e.target.value);
                          }}
                          value={
                            (tripState.activity.data.new_activity &&
                              tripState.activity.data.new_activity
                                .category_id) ||
                            ""
                          }
                        />
                      ) : (
                        <ActivityIndicator color="#344370" />
                      )}
                    </Card>
                    {isErrorFormActivity &&
                    (!tripState.activity.data.new_activity.category_id ||
                      tripState.activity.data.new_activity.category_id ===
                        "") ? (
                      // eslint-disable-next-line react/jsx-indent
                      <Text style={styles.textValidate}>
                        {t("activity.detail.msg.req")}
                      </Text>
                    ) : null}
                  </View>
                  <Text style={styles.labelInput}>
                    {t("activity.detail.subject")}
                  </Text>
                  <TextArea
                    value={
                      (tripState.activity.data.new_activity &&
                        tripState.activity.data.new_activity.subject) ||
                      ""
                    }
                    onChange={e => {
                      _setForm("subject", e.target.value);
                    }}
                    bold
                  />
                  {isErrorFormActivity &&
                  (!tripState.activity.data.new_activity.subject ||
                    tripState.activity.data.new_activity.subject === "") ? (
                    // eslint-disable-next-line react/jsx-indent
                    <Text style={styles.textValidate}>
                      {t("activity.detail.msg.req")}
                    </Text>
                  ) : null}
                </View>
                <Text style={styles.titleTrip}>{copText.task}</Text>
                <TouchableOpacity
                  onPress={() => {
                    setShowPickDestination(true);
                    setFormTemp(form);
                    setTempRegionArrival(inputRegionArrival);
                    setCountryDepartureNameTemp(selectedCountryDepartureName);
                    setCountryArrivalNameTemp(selectedCountryArrivalName);
                    setCountryDepartureIdTemp(selectedCountryDepartureId);
                    setCountryArrivalIdTemp(selectedCountryArrivalId);
                  }}
                >
                  {_handleConditionErrorPicker() ? (
                    <View
                      style={[
                        styles.cardChooseDestination,
                        { justifyContent: "center", alignItems: "center" }
                      ]}
                    >
                      <Text
                        style={{
                          color: "red",
                          fontFamily: "Poppins",
                          marginLeft: 10,
                          marginRight: 10,
                          textAlign: "center"
                        }}
                      >
                        {t("activity.detail.msg.chooseDestination")}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.cardChooseDestination}>
                      <View style={{ width: "48%", marginLeft: 12 }}>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            fontSize: 14,
                            color: "#50B1B1"
                          }}
                        >
                          {selectedCountryDepartureName}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            fontSize: 14,
                            color: "#50B1B1"
                          }}
                          numberOfLines={1}
                        >
                          {_regionName(
                            "departure",
                            selectedCountryDepartureId,
                            idRegionDeparture
                          )}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 14,
                            color: "#50B1B1"
                          }}
                        >
                          {moment(form.started_at_d).format("DD MMMM YYYY")}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 14,
                            color: "#666666"
                          }}
                        >
                          {moment(
                            `${form.started_at_d} ${form.started_at_t}`
                          ).format("hh:mm A")}
                        </Text>
                      </View>
                      <View style={{ width: "48%", marginRight: 12 }}>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            fontSize: 14,
                            color: "#ED5A28"
                          }}
                        >
                          {selectedCountryArrivalName}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            fontSize: 14,
                            color: "#ED5A28"
                          }}
                        >
                          {_regionName(
                            "arrival",
                            selectedCountryArrivalId,
                            idRegionArrival
                          )}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 14,
                            color: "#ED5A28"
                          }}
                        >
                          {moment(form.finished_at_d).format("DD MMMM YYYY")}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            fontSize: 14,
                            color: "#666666"
                          }}
                        >
                          {moment(
                            `${form.finished_at_d} ${form.finished_at_t}`
                          ).format("hh:mm A")}
                        </Text>
                      </View>
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            );
          })}

          <View style={styles.buttonMultiTrip}>
            <Touchable hidden>
              <View style={{ justifyContent: "center", height: "100%" }}>
                {history.location.pathname !== "/assigntrip" ? (
                  <Text style={{ color: "#ed5a28", marginLeft: 7 }}>
                    + Multi Trip
                  </Text>
                ) : null}
              </View>
            </Touchable>
          </View>

          <View style={{ marginVertical: 20 }}>
            {tripState.activity.data.new_activity &&
              tripState.activity.data.new_activity.files &&
              tripState.activity.data.new_activity.files.map(
                (item: string, index: number) => (
                  <FileUpload
                    key={index}
                    style={{ marginBottom: 12 }}
                    name={`File ${index + 1}`}
                    onPress={() => openPreviewBase64(item)}
                    deleteFile={e => {
                      e.stopPropagation();
                      _setForm(
                        "files",
                        tripState.activity.data.new_activity.files.filter(
                          (res: any, i: number) => i !== index
                        )
                      );
                    }}
                  />
                )
              )}
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 5
            }}
          >
            <Upload onChange={(e: any) => _uploadDocument(e)} multiple />
          </View>
        </View>
        <Fixed getHeight={e => setHeightFooter(e)} position="bottom">
          <Footer
            onPressNext={() => {
              _validateNext();
            }}
            onPressSave={() =>
              dispatch(postTermDataActivity(false, () => _getParentProject()))
            }
          />
        </Fixed>
      </WrapContent>
      <Modal isVisible={showPickDestination} wrapStyle={{ padding: 10 }}>
        <View style={styles.containerDestinationPicker}>
          <View>
            <Text style={styles.headerPicker}>{t("activity.new.from")}</Text>
            <View>
              <Card cardStyle={styles.inputStyleDestinationPicker}>
                <select
                  className="input-country-new-trip"
                  onChange={e => {
                    const departureCountry = String(e.target.value).split(",");
                    setSelectedCountryDepartureId(departureCountry[0]);
                    setSelectedCountryDepartureName(departureCountry[1]);
                    setIdRegionDeparture("");
                    setNotSet(true);
                  }}
                >
                  {countries.map((country: any, index: number) => (
                    <option
                      key={index}
                      value={`${country.id},${country.title}`}
                      selected={selectedCountryDepartureId === country.id}
                    >
                      {country.title}
                    </option>
                  ))}
                </select>
              </Card>
            </View>
            <View style={styles.inputStyleDestinationPicker}>
              <AutoComplete
                placeHolder={t("activity.detail.msg.enterDeparture")}
                option={populateDeparture()}
                additionalLabel={
                  selectedCountryDepartureId !== "ID" ? "code" : ""
                }
                onValueChange={e => setIdRegionDeparture(e.value)}
                value={_getValuetOption(populateDeparture(), idRegionDeparture)}
              />
              {isErrorActivityPicker && !idRegionDeparture ? (
                <Text style={styles.textValidate}>This field required</Text>
              ) : null}

              <View
                style={[styles.inputStyleDestinationPicker, styles.inputTime]}
              >
                <View
                  style={{
                    width: "48%"
                  }}
                >
                  <Card
                    cardStyle={{
                      height: 40,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666"
                      }}
                      min={moment().format("YYYY-MM-DD")}
                      max={form.finished_at_d || ""}
                      value={form.started_at_d}
                      onChange={e =>
                        setForm({ ...form, started_at_d: e.target.value })
                      }
                      type="date"
                      className="input-date-new-trip"
                    />
                  </Card>
                  {isErrorActivityPicker &&
                  (form.started_at_d === null || form.started_at_d === "") ? (
                    <Text style={styles.textValidate}>
                      {t("activity.detail.msg.req")}
                    </Text>
                  ) : null}
                </View>
                <View style={{ width: "48%" }}>
                  <Card
                    cardStyle={{
                      height: 40,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666"
                      }}
                      value={form.started_at_t}
                      onChange={e => {
                        setForm({ ...form, started_at_t: e.target.value });
                      }}
                      type="time"
                      className="input-date-new-trip"
                    />
                  </Card>
                  {isErrorActivityPicker &&
                  (form.started_at_t === null || form.started_at_t === "") ? (
                    <Text style={styles.textValidate}>
                      {t("activity.detail.msg.req")}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
          <View style={{ zIndex: -1 }}>
            <Text style={[styles.headerPicker, { color: "#ED5A28" }]}>
              {t("activity.new.to")}
            </Text>
            <View>
              <Card cardStyle={styles.inputStyleDestinationPicker}>
                <select
                  className="input-country-new-trip"
                  onChange={e => {
                    const arrivalCountry = String(e.target.value).split(",");
                    setSelectedCountryArrivalId(arrivalCountry[0]);
                    setSelectedCountryArrivalName(arrivalCountry[1]);
                    setInputRegionArrival("");
                    setNotSet(true);
                  }}
                >
                  {countries.map((country: any, index: number) => (
                    <option
                      key={index}
                      value={`${country.id},${country.title}`}
                      selected={selectedCountryArrivalId === country.id}
                    >
                      {country.title}
                    </option>
                  ))}
                </select>
              </Card>
            </View>
            <View style={styles.inputStyleDestinationPicker}>
              <AutoComplete
                placeHolder={t("activity.detail.msg.enterArrival")}
                option={populateArrival()}
                additionalLabel={
                  selectedCountryArrivalId !== "ID" ? "code" : ""
                }
                onValueChange={e => setIdRegionArrival(e.value)}
                value={_getValuetOption(populateArrival(), idRegionArrival)}
              />
              {isErrorActivityPicker && !idRegionArrival ? (
                <Text style={styles.textValidate}>
                  {t("activity.detail.msg.req")}
                </Text>
              ) : null}

              <View
                style={[styles.inputStyleDestinationPicker, styles.inputTime]}
              >
                <View
                  style={{
                    width: "48%"
                  }}
                >
                  <Card
                    cardStyle={{
                      height: 40,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666"
                      }}
                      min={form.started_at_d || moment().format("YYYY-MM-DD")}
                      onChange={e =>
                        setForm({ ...form, finished_at_d: e.target.value })
                      }
                      value={form.finished_at_d}
                      type="date"
                      className="input-date-new-trip"
                    />
                  </Card>
                  {isErrorActivityPicker &&
                  (form.finished_at_d === null || form.finished_at_d === "") ? (
                    <Text style={styles.textValidate}>
                      {t("activity.detail.msg.req")}
                    </Text>
                  ) : null}
                </View>
                <View style={{ width: "48%" }}>
                  <Card
                    cardStyle={{
                      height: 40,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <input
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#666666"
                      }}
                      onChange={e =>
                        setForm({ ...form, finished_at_t: e.target.value })
                      }
                      value={form.finished_at_t}
                      type="time"
                      className="input-date-new-trip"
                    />
                  </Card>
                  {isErrorActivityPicker &&
                  (form.finished_at_t === null || form.finished_at_t === "") ? (
                    <Text style={styles.textValidate}>
                      {t("activity.detail.msg.req")}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginBottom: 40,
              marginTop: 40,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Touchable onPress={() => _handleSubmitPickerActivity()}>
              <View style={styles.saveDestination}>
                <Text
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 18,
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  {t("activity.new.save")}
                </Text>
              </View>
            </Touchable>
            <View style={{ marginTop: 20 }}>
              <Touchable onPress={() => _handleCancelPickerActivity()}>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#666666",
                    fontFamily: "Poppins",
                    fontWeight: "bold"
                  }}
                >
                  {t("activity.new.cancel")}
                </Text>
              </Touchable>
            </View>
          </View>
        </View>
      </Modal>
      <Modal isVisible={modalSubmitForm} backdropColor="rgba(255,255,255, 0.9)">
        <ModalConfirmForm
          data={valueModalConfirm}
          onPress={() => submitRecord()}
          onCancel={() => setModalSubmitForm(false)}
          isLoading={tripState.allowance.isLoading}
        />
      </Modal>
    </View>
  );
};

export default NewTrip;
