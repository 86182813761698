import moment from "moment";
import React, { useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { CustomText, Touchable } from "../../../../components";
import { getLoanDetail } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { maskedMoney } from "../../../../utils";
import styles from "./style";

// screens

export const KasbonContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  useEffect(() => {
    dispatch(getLoanDetail(params.id));
  }, []);

  const { loanDetail, isLoadingLoanDetail } = useSelector(
    (state: Reducers) => state.loan
  );

  const invoiceRequest = loanDetail?.data.included.find(
    (e: any) => e.type === "invoiceRequest"
  );

  const bankAccount = loanDetail?.data.included.find(
    (e: any) => e.type === "employeeAccount"
  );

  const handleStatus = (status: number) => {
    if (status === 2) {
      return { name: "Pengajuan", color: "#B3B3B3" };
    }
    if (status === 3) {
      return { name: "Disetujui", color: "#00A538" };
    }
    if (status === 9) {
      return { name: "Diproses", color: "#FFA629" };
    }
    if (status === 8) {
      return { name: "Ditolak", color: "#FF2929" };
    }
    if (status === 9) {
      return { name: "Lunas", color: "#00A538" };
    }
    return { name: "Dibayarkan", color: "#00A538" };
  };

  const statusKasbon = loanDetail?.data.data.attributes.status;

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        {isLoadingLoanDetail ? (
          <ActivityIndicator color="#344370" />
        ) : (
          <>
            <CustomText style={styles.limitWithdrawal}>
              Detail Penarikan
            </CustomText>
            {invoiceRequest && (
              <>
                <CustomText style={styles.label}>Invoice No.</CustomText>
                <CustomText style={styles.amount}>
                  {invoiceRequest.attributes.number}
                </CustomText>
              </>
            )}
            <CustomText style={styles.label}>Tanggal Pengajuan</CustomText>
            <CustomText style={styles.amount}>
              {moment(loanDetail?.data.data.attributes.created_at).format(
                "dddd, DD MMMM YYYY, HH:mm"
              )}
            </CustomText>
            {statusKasbon !== 2 && (
              <>
                <CustomText style={styles.label}>
                  {`Tanggal ${handleStatus(statusKasbon).name}`}
                </CustomText>

                <CustomText style={styles.amount}>
                  {moment(loanDetail?.data.data.attributes.updated_at).format(
                    "dddd, DD MMMM YYYY, HH:mm"
                  )}
                </CustomText>
              </>
            )}

            <CustomText style={styles.label}>Nominal Penarikan</CustomText>
            <CustomText style={styles.amount}>
              {maskedMoney(loanDetail?.data.data.attributes.amount)}
            </CustomText>
            <CustomText style={styles.label}>Akun Penerima</CustomText>
            <CustomText style={styles.amount}>
              {`${bankAccount?.attributes.bankName} - ${bankAccount?.attributes.number}`}
            </CustomText>
            <CustomText style={[styles.amount, { marginTop: -20 }]}>
              {bankAccount?.attributes.name}
            </CustomText>
            <CustomText style={styles.label}>Status</CustomText>
            <CustomText
              style={[
                styles.amount,
                {
                  fontWeight: "bold",
                  color: handleStatus(statusKasbon).color
                }
              ]}
            >
              {handleStatus(statusKasbon).name}
            </CustomText>
          </>
        )}
      </View>
      <Touchable onPress={() => history.goBack()}>
        <View style={styles.btnSubmit}>
          <View>
            <CustomText style={styles.btnText}>Kembali</CustomText>
          </View>
        </View>
      </Touchable>
    </View>
  );
};
