/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { Fixed, Header } from "../../../components";
import styles from "./styles";
import Expense from "./Expense";
import Trip from "./Trip";
import { copyWritings, heightPercent, queryString } from "../../../utils";
import {
  getAccountableByTripId,
  getTripById,
  getTripModeration
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const Management = () => {
  const { id } = useParams() as any;
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { sprintf } = require("sprintf-js");
  const { t } = useTranslation();
  const tripData = useSelector((state: Reducers) => state.trip.tripData);
  const initialRoute = `${match.path.slice(0, -5)}/${id}`;
  const history = useHistory();
  const { pathname, search } = history.location as any;
  const state = queryString(search);
  const [heightHeader, setHeightHeader] = useState(0);
  const copTeks = {
    trip: copyWritings("trip"),
    expense: copyWritings("expense")
  };

  useEffect(() => {
    dispatch(getTripById(id));
    dispatch(getAccountableByTripId(id));
    dispatch(getTripModeration(id));
  }, [dispatch, id]);

  const listPages = [
    {
      pathname: initialRoute,
      component: <Trip />,
      titleTab: copTeks.trip,
      exact: true,
      accessible: true
    },
    {
      pathname: `${initialRoute}/expense`,
      component: <Expense id={id!} top={heightHeader} />,
      titleTab: copTeks.expense,
      exact: true,
      accessible: true
    }
  ];

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = String(0);

    if (state) {
      status = state.status;
    } else if (tripData) {
      status = tripData.status;
    }

    if (status === "1") {
      desc = t("activity.status.draft");
    } else if (status === "2") {
      desc = t("activity.status.needApproval");
    } else if (status === "3") {
      desc = t("activity.status.approved");
    } else if (status === "4") {
      desc = t("activity.status.revise");
    } else if (status === "5") {
      desc = t("activity.status.ongoing");
    } else if (status === "6") {
      desc = t("activity.status.finished");
    } else if (status === "7") {
      desc = t("activity.status.rejected");
    }
    return desc;
  };

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            textHeader={{ fontSize: 18 }}
            subTitleHeader={{ fontSize: 11 }}
            title={sprintf(
              t("activity.management.activityManagement"),
              copTeks.trip
            )}
            subtitle={`${tripData !== null && tripData.number}`}
            label={_statusDesc()}
            textLabel={{ fontSize: 11 }}
            goBack={() =>
              state && state.type === "approval"
                ? history.push(`/trip/${state.type}`)
                : history.push(
                    `/trip-detail/${id}?type=${state.type}&status=${state.status}`
                  )
            }
          />
          <View style={styles.container}>
            <View style={styles.wrapTaskNav}>
              <View style={styles.taskNav}>
                {listPages.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.btnNav,
                      index === 0 &&
                        item.pathname === pathname &&
                        styles.activeFirst,
                      index === listPages.length - 1 &&
                        item.pathname === pathname &&
                        styles.activeLast,
                      index !== 0 &&
                        pathname.includes(item.pathname) &&
                        styles.active
                    ]}
                  >
                    <TouchableOpacity
                      style={[
                        styles.wrapBtn,
                        item.accessible ? { opacity: 1 } : { opacity: 0.2 }
                      ]}
                      activeOpacity={1}
                      onPress={() => history.push(item.pathname + search)}
                      disabled={!item.accessible}
                    >
                      <Text style={[styles.btnText]}>{item.titleTab}</Text>
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Fixed>
        <View
          style={{
            marginTop: heightHeader + 16,
            minHeight: heightPercent(95) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};

export default Management;
