import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  bookingDetailContainer: {
    flex: 1
  },
  bookingpDetailContent: {
    paddingHorizontal: 28,
    backgroundColor: "#fffff7"
  },
  taskNav: {
    justifyContent: "space-between",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    height: "fit-content",
    width: "fit-content",
    maxWidth: "100%",
    minWidth: "85%",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  btnNav: {
    height: 34,
    flex: 1,
    alignItems: "center",
    lineHeight: 36,
    textAlign: "center"
  },
  btnText: {
    fontWeight: "bold",
    fontFamily: "poppins",
    fontSize: 11,
    color: "#ffffff",
    textAlign: "center"
  },
  active: {
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)",
    flex: 1
  },
  activeFirst: {
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)",
    flex: 1,
    borderBottomLeftRadius: 20
  },
  activeLast: {
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)",
    flex: 1,
    borderBottomRightRadius: 20
  },
  modalStyle: {
    justifyContent: "flex-start"
  }
});

export default styles;
