/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { Fixed } from "../../../components";
import { Reducers } from "../../../redux/types";
import {
  deleteFileTaskReport,
  editDetailTask,
  getDetailTask,
  uploadFileDetailTaskReport
} from "../../../redux/actions";

import styles from "./styles";
import { ICONS } from "../../../configs";
import { permissionPage } from "../../../utils";

interface Props {
  id: string;
  userId: string;
}

const EditReport = ({ id, userId }: Props) => {
  // const history = useHistory();
  const { t } = useTranslation();
  const taskData = useSelector((state: Reducers) => state.task)
    .detailTaskWantEdit;
  const isLoading = useSelector((state: Reducers) => state.task)
    .isLoadingDetailTaskReportData;
  const { isLoadingUploadFileDetailTask } = useSelector(
    (state: Reducers) => state.task
  );
  const { uuid } = useSelector((state: Reducers) => state.auth);
  const auth = useSelector((state: Reducers) => state.auth);
  const dispatch = useDispatch();
  const [errorFileUpload, setErrorFileUpload] = useState(false);
  const [assigner, setAssigner]: any = useState(false);
  const permission = {
    selfEdit: permissionPage("task-self-edit")
  };

  let temp: any[] = [];
  const [files, setFiles]: any[] = useState(taskData.file_collection);
  const [description] = useState(taskData.description);
  const [errorDesc, setErrorDesc] = useState(false);
  const taskDetailData = useSelector((state: Reducers) => state.task);

  const _handleAddFile = (file: any) => {
    const fileSize = file.size;
    if (fileSize > auth.myConfig.max_size) {
      setErrorFileUpload(true);
    } else {
      temp = [...files, { file, isNew: true }];

      dispatch(
        uploadFileDetailTaskReport(taskData.id, file, () => setFiles(temp))
      );
    }
  };

  const _handleDeleteFile = (index: number) => {
    if (!files[index].file.isNew) {
      dispatch(deleteFileTaskReport(taskData.id, files[index].id));
    }
    const halah = [...files];
    halah.splice(index, 1);
    setFiles(halah);
  };

  useEffect(() => {
    dispatch(getDetailTask(id));
    if (taskDetailData.detailTaskData.assigner.uuid === userId) {
      setAssigner(true);
    }
  }, [assigner]);

  const _handleSave = async () => {
    const uploadedFiles = files.filter((file: any) => file.isNew);
    const coba = document.getElementById("halah")?.innerHTML;
    const desc = coba?.replace(/div/g, "p");

    if (desc === null || desc === "") {
      setErrorDesc(true);
    } else {
      setErrorDesc(false);
      dispatch(
        editDetailTask(taskData.id, uploadedFiles, id, uuid, {
          description: desc
        })
      );
    }
  };

  const goToPreview = (index: number) => {
    if (
      files[index].file.extension === "pdf" ||
      files[index].file.extension === "docx" ||
      files[index].file.extension === "doc" ||
      files[index].file.extension === "xlsx"
    ) {
      window.open(files[index].file.url);
    } else if (files[index].isNew) {
      if (
        files[index].file.type === "application/pdf" ||
        files[index].file.type === "application/vnd.ms-excel" ||
        files[index].file.type === ".doc" ||
        files[index].file.type === ".docx"
      ) {
        window.open(URL.createObjectURL(files[index].file));
      } else {
        const newTab = window.open();
        newTab!.document.body.innerHTML = `
      <div
        style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
      >
        <img src="${URL.createObjectURL(files[index].file)}">
      </div>`;
      }
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
      <div  
        style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
      >
        <img src="${files[index].file.url}">
      </div>`;
    }
  };

  return (
    <View style={{ width: "100%" }}>
      {taskData && !isLoading ? (
        <View style={{ width: "100%", alignItems: "center" }}>
          <View style={{ width: "100%" }}>
            <Text
              style={{ fontSize: 11, fontFamily: "Poppins", color: "#666666" }}
            >
              {t("task.report.date")}
            </Text>
            <Text
              style={{
                color: "#666666",
                fontFamily: "Poppins",
                fontWeight: "bold"
              }}
            >
              {moment(taskData.date).format("DD MMMM YYYY")}
            </Text>
            <Text
              style={{
                fontSize: 11,
                marginTop: 12,
                fontFamily: "Poppins",
                color: "#666666"
              }}
            >
              {t("task.report.report")}
            </Text>
            <View>
              <div
                id="halah"
                contentEditable={
                  taskData.status !== 2 &&
                  taskData.creator.employee.id === userId &&
                  permission.selfEdit
                }
                style={{
                  borderColor: "#666666",
                  borderBottomWidth: 1,
                  height: 140,
                  color: "#666666",
                  width: "100%",
                  overflow: "scroll",
                  fontFamily: "Poppins",
                  fontSize: 11
                }}
                dangerouslySetInnerHTML={{
                  __html: `${description}`
                }}
              />
              <div
                style={{ height: 1, width: "100%", backgroundColor: "#666666" }}
              />
            </View>
            {errorDesc ? (
              <Text style={{ fontSize: 11, color: "red" }}>
                {t("task.report.warningRequired")}
              </Text>
            ) : null}

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                marginTop: 12
              }}
            >
              {files.map((file: any, index: number) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.fileAttachment,
                    styles.card,
                    {
                      borderRadius: 100,
                      alignItems: "center",
                      justifyContent: "center"
                    }
                  ]}
                  onPress={() => goToPreview(index)}
                >
                  <View style={{ width: "90%" }}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ width: "85%" }}>
                        <Text numberOfLines={1} style={styles.attributeTask}>
                          {file.file.name || file.file.title}
                        </Text>
                      </View>
                      {taskData.status !== 2 &&
                      taskData.creator.employee.id === userId &&
                      permission.selfEdit ? (
                        <TouchableOpacity
                          style={{
                            width: "15%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                          onPress={() => _handleDeleteFile(index)}
                        >
                          <Image
                            source={ICONS.iconDeleteFile}
                            resizeMode="contain"
                            style={{ width: 12, height: 12 }}
                          />
                        </TouchableOpacity>
                      ) : null}
                    </View>
                  </View>
                </TouchableOpacity>
              ))}

              {taskData.status !== 2 &&
              taskData.creator.employee.id === userId &&
              permission.selfEdit ? (
                <View style={{ width: "48%" }}>
                  {isLoadingUploadFileDetailTask ? (
                    <ActivityIndicator />
                  ) : (
                    <TouchableOpacity
                      style={[styles.button, { width: "100%" }]}
                    >
                      <label
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                          fontSize: 11
                        }}
                      >
                        <input
                          // eslint-disable-next-line max-len
                          accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                    application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          type="file"
                          onChange={(e: any) => {
                            _handleAddFile(e.target.files[0]);
                          }}
                          onClick={(event: any) => {
                            // eslint-disable-next-line no-param-reassign
                            event.target.value = null;
                          }}
                        />
                        {t("task.assign.attachFile")}
                      </label>
                    </TouchableOpacity>
                  )}
                </View>
              ) : null}

              {errorFileUpload ? (
                <View style={{ width: "100%", marginTop: 12 }}>
                  <Text style={{ fontSize: 11, color: "red" }}>
                    {`File size is too big on current upload, please upload another file (Maximum ${
                      auth.myConfig.max_size / 1000000
                    } MB)`}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
          <Fixed position="bottom">
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {taskData.status !== 2 &&
              taskData.creator.employee.id === userId &&
              permission.selfEdit ? (
                <TouchableOpacity
                  onPress={() => _handleSave()}
                  style={[
                    styles.button,
                    { marginBottom: 24, width: 180, height: 54 }
                  ]}
                  disabled={isLoadingUploadFileDetailTask}
                >
                  {!isLoadingUploadFileDetailTask ? (
                    <Text style={[styles.textButton, { fontSize: 18 }]}>
                      {t("task.save")}
                    </Text>
                  ) : (
                    <ActivityIndicator />
                  )}
                </TouchableOpacity>
              ) : null}
            </View>
          </Fixed>
        </View>
      ) : (
        <ActivityIndicator />
      )}
    </View>
  );
};
export default EditReport;
