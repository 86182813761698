import {
  GET_DETAIL_BOOKING_FLIGHT_CLEAR,
  GET_DETAIL_BOOKING_FLIGHT_ERORR,
  GET_DETAIL_BOOKING_FLIGHT_PENDING,
  GET_DETAIL_BOOKING_FLIGHT_SUCCESS,
  GET_DETAIL_BOOKING_HOTEL_ERORR,
  GET_DETAIL_BOOKING_HOTEL_PENDING,
  GET_DETAIL_BOOKING_HOTEL_SUCCESS,
  LOGOUT_SUCCESS,
  PUT_BOOKING_FLIGHT_ERORR,
  PUT_BOOKING_FLIGHT_PENDING,
  PUT_BOOKING_FLIGHT_SUCCESS,
  SET_FORM_DETAIL_BOOKING_FLIGHT
} from "../actions";
import { Action, DetailBookingState } from "../types";

const initialState: DetailBookingState = {
  isLoadingDetailBookingHotel: false,
  detailBookingHotel: {},
  isLoadingPutBookingFlight: false,
  isLoadingDetailBookingFlight: false,
  detailBookingFlight: null,
  formBookingFlight: {
    vendor_id: "",
    from: "",
    to: "",
    started_at_d: "",
    started_at_t: "",
    finished_at_d: "",
    finished_at_t: "",
    class_id: "",
    baggage: 0,
    airline_id: "",
    participants: {},
    remarks: ""
  }
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // hotel
    case GET_DETAIL_BOOKING_HOTEL_PENDING:
      return {
        ...state,
        isLoadingDetailBookingHotel: true
      };
    case GET_DETAIL_BOOKING_HOTEL_SUCCESS:
      return {
        ...state,
        isLoadingDetailBookingHotel: false,
        detailBookingHotel: payload.data
      };
    case GET_DETAIL_BOOKING_HOTEL_ERORR:
      return { ...state, isLoadingDetailBookingHotel: false };

    // flight
    case GET_DETAIL_BOOKING_FLIGHT_PENDING:
      return {
        ...state,
        isLoadingDetailBookingFlight: true,
        detailBookingFlight: null,
        formBookingFlight: {
          vendor_id: "",
          from: "",
          to: "",
          started_at_d: "",
          started_at_t: "",
          finished_at_d: "",
          finished_at_t: "",
          class_id: "",
          baggage: 0,
          airline_id: "",
          participants: {},
          remarks: ""
        }
      };
    case GET_DETAIL_BOOKING_FLIGHT_SUCCESS:
      return {
        ...state,
        isLoadingDetailBookingFlight: false,
        detailBookingFlight: payload.data,
        formBookingFlight: {
          ...state.formBookingFlight,
          from: payload.data.data.origin_regency_id,
          to: payload.data.data.destination_regency_id,
          class_id: payload.data.data.class,
          started_at_d: payload.data.data.departure_date,
          started_at_t: payload.data.data.departure_time_range,
          finished_at_t: payload.data.data.return_time_range,
          finished_at_d: payload.data.data.return_date
        }
      };
    case GET_DETAIL_BOOKING_FLIGHT_ERORR:
      return { ...state, isLoadingDetailBookingFlight: false };
    case GET_DETAIL_BOOKING_FLIGHT_CLEAR:
      return {
        ...state,
        isLoadingDetailBookingFlight: false,
        detailBookingFlight: null,
        formBookingFlight: {
          vendor_id: "",
          from: "",
          to: "",
          started_at_d: "",
          started_at_t: "",
          finished_at_d: "",
          finished_at_t: "",
          class_id: "",
          baggage: 0,
          airline_id: "",
          participants: {},
          remarks: ""
        }
      };

    // put book flight
    case PUT_BOOKING_FLIGHT_PENDING:
      return {
        ...state,
        isLoadingPutBookingFlight: true
      };
    case PUT_BOOKING_FLIGHT_SUCCESS:
      return {
        ...state,
        isLoadingPutBookingFlight: false
      };
    case PUT_BOOKING_FLIGHT_ERORR:
      return { ...state, isLoadingPutBookingFlight: false };

    case SET_FORM_DETAIL_BOOKING_FLIGHT:
      return {
        ...state,
        formBookingFlight: {
          ...state.formBookingFlight,
          [payload.data.formName]: payload.data.value
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
