import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";

const GetPolicies = (policySlug: string) => {
  const { policies } = useSelector((state: Reducers) => state.auth);
  return policies.find(el => el.policy_slug === policySlug).value;
};

export default GetPolicies;
