import { StyleSheet } from "react-native";
import { widthPercent } from "../../utils";

const styles = StyleSheet.create({
  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#666666"
  },
  textArea: {
    fontFamily: "Poppins",
    color: "#666666",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#666666",
    outlineColor: "rgba(255,255,255,0)"
  },
  form: {
    flexDirection: "row",
    width: widthPercent(100) - 68,
    alignSelf: "center",
    justifyContent: "space-between"
  },
  star: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center"
  },
  datePickerNavWrap: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)"
  },
  datePickerNav: {
    width: 168,
    height: 70
  },
  datePickerNavActive: {
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  datePicker: {
    paddingHorizontal: 15,
    height: 20,
    marginVertical: 9
  },
  label: {
    color: "#666666",
    fontFamily: "Poppins",
    fontSize: 14,
    lineHeight: 18,
    paddingBottom: 5
  },
  button: {
    borderRadius: 50,
    marginBottom: 30,
    marginTop: 171.5,
    height: 54,
    width: 180,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 , #db3a26)",
    justifyContent: "center",
    alignItems: "center"
  },
  txtButton: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    color: "#ffffff"
  },
  autocompleteWrap: {
    position: "absolute",
    top: 45
  },
  autocompleteList: {
    backgroundColor: "#fffff7",
    boxShadow: "rgb(221, 221, 221) 0px 2px 5px",
    borderRadius: 10,
    paddingHorizontal: 20
  },
  autocompleteWrapLabel: {
    marginVertical: 5
  },
  autocompleteWrapText: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  pickerWrap: {
    fontFamily: "Poppins",
    color: "#666666",
    fontSize: 14,
    fontWeight: "bold",
    marginHorizontal: 0
  },
  errorAlert: {
    color: "red",
    fontFamily: "Poppins"
  }
});
export default styles;
