import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  cardDescription: {
    marginTop: 15,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 14,
    paddingRight: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  container: {
    paddingTop: 10,
    paddingHorizontal: 25,
    paddingBottom: 10,
    backgroundColor: COLORS.main
  },
  navigation: {
    zIndex: 100,
    marginTop: 25,
    flexDirection: "row",
    height: 70,
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)"
  },
  btnNav: {
    width: "50%",
    height: "100%",
    borderRadius: 50
  },
  active: {
    borderRadius: 50,
    width: "50%",
    height: "100%",
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  saveButtonContainer: {
    paddingTop: 10,
    paddingBottom: 27,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: COLORS.main
  },
  buttonSave: {
    width: 125,
    backgroundImage: "linear-gradient(to bottom, #50b1b1, #262261 168%)",
    borderRadius: 30,
    overflow: "hidden"
  },
  buttonSaveText: {
    color: "#ffffff",
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Poppins",
    textAlign: "center"
  },
  buttonSubmit: {
    width: 125,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  textDescTrip: {
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  }
});

export default styles;
