import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  tripDetailContainer: {
    flex: 1,
    marginTop: 16
  },
  tripDetailContent: {
    paddingHorizontal: 20
  },
  desciption: {
    flexDirection: "row",
    marginTop: 4,
    paddingLeft: 20
  },
  titleDescription: {
    width: "20%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "65%"
  },
  noteStyle: {
    paddingLeft: 20,
    paddingRight: 50,
    marginTop: 13,
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 15,
    borderRadius: 16
  },
  cardAttchmen: {
    overflow: "hidden",
    marginHorizontal: 10,
    marginBottom: 2
  },
  cardAttchmenAdd: {
    justifyContent: "center",
    paddingHorizontal: 25
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 100
  },
  modalStyle: {
    justifyContent: "flex-start"
  },
  font: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666",
    textTransform: "capitalize"
  },
  bold: {
    fontWeight: "bold"
  },
  font14: {
    fontSize: 14
  },
  light: {
    color: "#999999"
  },
  wrapTrashIcon: {
    height: 26,
    width: 26,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center"
  },
  attachmentFile: {
    height: 90,
    width: 90,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default styles;
