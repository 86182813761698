import React, { ChangeEvent, useEffect } from "react";

import "./styles.css";

interface ListValue {
  value: string;
  label: string;
}

interface Props {
  moderation?: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  listValue: ListValue[];
  value?: string;
  defaultValue?: () => void;
  type?: any;
  emptyLabel?: string;
  style?: any;
  disable?: boolean;
}

const Select = ({
  onChange,
  listValue,
  value,
  defaultValue,
  moderation,
  type,
  emptyLabel,
  style,
  disable
}: Props) => {
  useEffect(() => {
    if (defaultValue) {
      defaultValue();
    }
  }, []);

  return (
    <select
      required
      style={style}
      onChange={onChange}
      value={value}
      className={
        moderation
          ? "select-category"
          : `select-wrap ${value === "" && "select-opacity"}`
      }
      defaultValue=""
      disabled={disable}
    >
      {emptyLabel && (
        <option value="" disabled hidden>
          {emptyLabel}
        </option>
      )}
      {listValue.map((item, index) => (
        <option key={index} value={item.value} selected={item.value === type}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
