import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Card, Touchable } from "../../../../../../components";
import {
  emptyListCashAdvanceBill,
  getListCashAdvanceBill,
  putBillable,
  resetDoneListCashAdvanceBill,
  storeExpenseDataTempNewBill
} from "../../../../../../redux/actions";
import { Reducers } from "../../../../../../redux/types";
import { IMAGES } from "../../../../../../configs";
// import EditBills from "../../EditBills";
import styles from "./styles";

// interface ListBill {
//   id: number;
//   name: string;
//   price: number;
//   isBillable: boolean;
// }
interface Props {
  onClose: (visible: boolean) => void;
  onConfirm: (visible: boolean) => void;
  value?: any;
  tripId: string;
  onEditBill: (editBillsId: any, value: any, tripData: any) => void;
}
const ExpenseDetail = ({
  value,
  tripId,
  onConfirm,
  onEditBill,
  onClose
}: Props) => {
  const dispatch = useDispatch();
  emptyListCashAdvanceBill();
  const { tripData } = useSelector((state: Reducers) => state.trip);
  const { listCashAdvanceBill, isDonePutBillable, tripDetail } = useSelector(
    (state: Reducers) => state.cashAdvanceBill
  );

  // const typeListBill: ListBill[] = [];
  // const [listBill, setListBill] = useState(typeListBill);

  const [oneTimeEffect, setOneTimeEffect] = useState(true);
  // const [editBills, setEditBills] = useState(false);
  // const [editBillsId, setEditBillsId] = useState();

  const history = useHistory();

  const onDeleteBill = () => {
    // i;
    // setListBill(listBill.filter((item, index) => index !== i));
  };

  const [oneTimeBillable, setOneTimeBillable] = useState(false);
  const onChangeBillable = (item: any) => {
    if (!oneTimeBillable) {
      setOneTimeBillable(!oneTimeBillable);
      dispatch(putBillable({ billable: !item.billable }, item.uuid));
    }
  };

  const onAddBillable = () => {
    if (tripData) {
      dispatch(storeExpenseDataTempNewBill(value));
      history.push(`/new-bill/${tripData.id}`);
    }
  };

  const formatCurrency = (str: string) => {
    const digit = parseInt(str, 0);
    let additional = "";
    if (digit < 0) {
      additional = "-";
    }
    const digitString = digit.toString().replace("-", "").split("");
    let newDigit = "";
    let j = 1;
    for (let i = digitString.length - 1; i >= 0; i -= 1) {
      newDigit = `${digitString[i]}${newDigit}`;
      if (j % 3 === 0 && j !== digitString.length) {
        newDigit = `.${newDigit}`;
      }
      j += 1;
    }
    return `${additional}${newDigit}`;
  };

  const onCloseBillExpenseDetail = () => {
    // onClose(false);
    onConfirm(true);
  };

  const dateFormat = (date: string) => {
    const mo = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const dt = date.split(" ")[0].split("-");
    return `${parseInt(dt[2], 0)} ${mo[parseInt(dt[1], 0) - 1]} ${dt[0]}`;
  };

  useEffect(() => {
    if (oneTimeEffect) {
      dispatch(getListCashAdvanceBill(tripId, value.category_id));
      setOneTimeEffect(false);
    }
  }, [dispatch, oneTimeEffect, tripId, value.category_id]);

  const [getBillsOnce, setGetBillsOnce] = useState(true);
  const getBills = () => {
    setGetBillsOnce(false);
    dispatch(getListCashAdvanceBill(tripId, value.category_id));
  };

  // const [getTotalAmountOnce, setGetTotalAmountOnce] = useState(true);
  // const [totalAmount, setTotalAmount] = useState(0);

  // const getTotalAmount = () => {
  //   setGetTotalAmountOnce(false);
  //   let total = 0;
  //   listCashAdvanceBill.forEach(el => {
  //     total = parseInt(el.total, 0) + total;
  //   });
  //   setTotalAmount(total);
  // };

  const onOneTimeBillable = () => {
    setOneTimeBillable(false);
    dispatch(resetDoneListCashAdvanceBill());
    setGetBillsOnce(true);
  };
  const diff = (finished_at: string) => {
    return new Date(finished_at).getTime() - new Date().getTime();
  };
  const closeAvailable = () => {
    return tripData.djurneeTrip.expense;
  };

  return (
    <Card>
      {tripData ? (
        <View
          style={{
            width: 335,
            height: 693,
            paddingTop: 24
          }}
        >
          <>{getBillsOnce ? getBills() : <></>}</>
          <>
            {oneTimeBillable && isDonePutBillable ? (
              <>{onOneTimeBillable()}</>
            ) : (
              <></>
            )}
          </>
          <View style={{ paddingHorizontal: 22 }}>
            <View style={styles.row}>
              <Text style={styles.labelText}>Code</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueNumberText}>{tripData.number}</Text>
                <Text style={styles.valueProjectText}>
                  {tripData.project.title}
                </Text>
                <Text style={styles.valueNumberText}>
                  {tripData.category.title}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Team</Text>
              <View style={styles.wrapValue}>
                {tripData.users.map(
                  (
                    user: { user: { name: React.ReactNode } },
                    index: number
                  ) => (
                    <Text style={styles.valueText} key={index}>
                      {`${user.user.name}${
                        tripData.users.length - (index + 1) !== 0 ? "," : ""
                      }`}
                    </Text>
                  )
                )}
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Date</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueText}>
                  {`${dateFormat(tripData.started_at)}${
                    dateFormat(tripData.finished_at) !==
                    dateFormat(tripData.started_at)
                      ? `${
                          tripData.finished_at !== null
                            ? ` - ${dateFormat(tripData.finished_at)}`
                            : ""
                        }`
                      : ""
                  }`}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Method</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueText}>Cash Advance</Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Category</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueCategoryText}>{value.category}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.labelText}>Budget</Text>
              <View style={styles.wrapValue}>
                <Text style={styles.valueCategoryText}>
                  {tripDetail ? `IDR ${formatCurrency(tripDetail.total)}` : ""}
                </Text>
              </View>
            </View>
          </View>
          <div style={{ overflowY: "auto", height: 295 }}>
            <View style={{ paddingTop: 7, paddingHorizontal: 11 }}>
              <>
                {listCashAdvanceBill.length ? (
                  <>
                    {/* <>
                      {getTotalAmountOnce &&
                      listCashAdvanceBill.length > 0 &&
                      tripDetail ? (
                        <>{getTotalAmount()}</>
                      ) : (
                        <></>
                      )}
                    </> */}
                    <FlatList
                      keyExtractor={(item, index) => index.toString()}
                      data={listCashAdvanceBill}
                      renderItem={({ item, index }) => (
                        <Card cardStyle={{ marginBottom: 10 }}>
                          <Touchable
                            onPress={() => {
                              onEditBill(item.uuid, value, tripData);
                            }}
                          >
                            <View style={{ flexDirection: "row", padding: 11 }}>
                              <Text style={styles.billText}>
                                {`Bill ${index + 1}`}
                              </Text>
                              <Text style={styles.billPriceText}>
                                {`IDR ${formatCurrency(item.total)}`}
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  right: 11.9,
                                  position: "absolute"
                                }}
                              >
                                <Text
                                  style={[styles.billText, { marginRight: 7 }]}
                                >
                                  Billable
                                </Text>
                                <>
                                  {closeAvailable() ? (
                                    <>
                                      <Switch
                                        onChange={() => onChangeBillable(item)}
                                        checked={item.billable}
                                        onColor="#50b1b1"
                                        onHandleColor="#fffff7"
                                        handleDiameter={19}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                                        height={19}
                                        width={34}
                                      />
                                      <View style={{ marginLeft: 11.9 }}>
                                        <Touchable
                                          onPress={() => onDeleteBill()}
                                        >
                                          <Image
                                            source={IMAGES.trashBin}
                                            style={{ width: 13, height: 16 }}
                                          />
                                        </Touchable>
                                      </View>
                                    </>
                                  ) : (
                                    <Text
                                      style={{
                                        fontSize: 11,
                                        color: item.billable
                                          ? "#50b1b1"
                                          : "#999999",
                                        fontFamily: "Poppins",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {item.billable ? "Yes" : "No"}
                                    </Text>
                                  )}
                                </>
                              </View>
                            </View>
                          </Touchable>
                        </Card>
                      )}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            </View>
            <View style={{ alignItems: "center", paddingHorizontal: 11 }}>
              {closeAvailable() ? (
                <View style={styles.buttonNewBill}>
                  <Touchable onPress={onAddBillable}>
                    <View
                      style={[
                        styles.buttonNewBillTextWrap,
                        { flexDirection: "row" }
                      ]}
                    >
                      <Text
                        style={[styles.buttonNewBillText, { fontSize: 14.8 }]}
                      >
                        {"+ "}
                      </Text>
                      <Text style={styles.buttonNewBillText}>New Bill</Text>
                    </View>
                  </Touchable>
                </View>
              ) : (
                <></>
              )}
            </View>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              paddingTop: 5,
              width: "100%"
            }}
          >
            <View style={styles.footerOption}>
              <View style={styles.closeBill}>
                <Touchable onPress={() => onClose(false)}>
                  <View style={styles.closeBillTextWrap}>
                    <Text style={styles.closeBillText}>Back</Text>
                  </View>
                </Touchable>
              </View>
            </View>
            {tripDetail ? (
              <>
                {diff(tripData.finished_at) < 0 && closeAvailable() ? (
                  <View style={styles.footerOption}>
                    <View style={styles.closeBill}>
                      <Touchable onPress={onCloseBillExpenseDetail}>
                        <View style={styles.closeBillTextWrap}>
                          <Text style={styles.closeBillText}>CLOSE BILLS</Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
                <View
                  style={
                    parseInt(tripDetail.total, 0) -
                      parseInt(tripDetail.total_expense, 0) <
                    0
                      ? styles.footerRed
                      : styles.footer
                  }
                >
                  <Text style={styles.footerPriceDescription}>
                    Remaining Budget
                  </Text>
                  <View style={styles.footerPrice}>
                    <Text style={styles.footerPriceCurrency}>IDR </Text>
                    <Text style={styles.footerPriceText}>
                      {tripDetail
                        ? formatCurrency(
                            (
                              parseInt(tripDetail.total, 0) -
                              parseInt(tripDetail.total_expense, 0)
                            ).toString()
                          )
                        : "-"}
                    </Text>
                  </View>
                </View>
              </>
            ) : (
              <></>
            )}
          </div>
        </View>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default ExpenseDetail;
