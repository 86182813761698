import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingHorizontal: 25,
    backgroundColor: COLORS.main
  },
  navigation: {
    marginTop: 15,
    marginBottom: 5,
    flexDirection: "row",
    height: 75,
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)"
  },
  btnNav: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    borderRadius: 50
  },
  active: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    flex: 1,
    height: "100%",
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  }
});

export default styles;
