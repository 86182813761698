import { StyleSheet } from "react-native";

import { heightPercent, widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  card: {
    paddingRight: 10,
    marginHorizontal: 1
  },
  row: {
    marginVertical: 5,
    marginHorizontal: 20,
    flexDirection: "row"
  },
  text: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11
  },
  desc: {
    color: "#666666",
    fontFamily: "Poppins",
    fontSize: 11
  },
  columnDesc: {
    width: "70%",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  textStatus: {
    marginLeft: 20,
    fontWeight: "bold",
    fontSize: 11,
    color: "#fffff7",
    fontFamily: "Poppins"
  },
  textDesc: {
    paddingVertical: 10,
    marginRight: 20,
    fontSize: 18,
    fontWeight: "bold",
    color: "#fffff7",
    fontFamily: "Poppins"
  },
  footerCard: {
    width: "100%",
    backgroundColor: "#50b1b1",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  budgetRequest: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 15
  },
  container: {
    width: widthPercent(100) - 40,
    height: heightPercent(100),
    justifyContent: "center"
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 22,
    paddingTop: 35,
    paddingBottom: 12,
    paddingRight: 4,
    borderRadius: 35
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  detailDescriptionCard: {
    width: "75%",
    paddingVertical: 2,
    paddingLeft: 13
  },
  detailDescriptionText: {
    fontSize: 11,
    color: "#666666",
    fontWeight: "bold",
    fontFamily: "Poppins"
  },
  amountContainer: {
    alignItems: "center"
  },
  amountTitle: {
    fontSize: 14,
    color: "#999999",
    fontFamily: "Poppins"
  },
  amount: {
    fontSize: 24,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#666666"
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    marginBottom: 23,
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 23
  }
});

export default styles;
