import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  datePickerNav: {
    flex: 1,
    alignItems: "center",
    height: 50,
    justifyContent: "center"
  },
  datePickerNavWrap: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)"
  },
  datePickerNavActive: {
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  }
});

export default styles;
