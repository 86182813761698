import React from "react";
import { ActivityIndicator, FlatList, Image, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useHistory } from "react-router-dom";
import {
  CustomText,
  LoadingLoadMore,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import styles from "./style";
import "./style.css";
import { ICONS } from "../../../../configs";
import { Reducers } from "../../../../redux/types";
import { maskedMoney } from "../../../../utils";
import { getLoanHistory, setSort } from "../../../../redux/actions";

export const KasbonContent = () => {
  const {
    loanProfile,
    isLoadingLoanProfile,
    isLoadingSubmitedLoan,
    submitedLoan,
    loanHistory,
    params,
    isLoadingLoanHistory
  } = useSelector((state: Reducers) => state.loan);

  const history = useHistory();

  const dispatch = useDispatch();

  const handleStatus = (status: number) => {
    if (status === 2) {
      return { name: "Pengajuan", color: "#B3B3B3" };
    }
    if (status === 3) {
      return { name: "Disetujui", color: "#00A538" };
    }
    if (status === 9) {
      return { name: "Diproses", color: "#FFA629" };
    }
    if (status === 8) {
      return { name: "Ditolak", color: "#FF2929" };
    }
    if (status === 6) {
      return { name: "Lunas", color: "#00A538" };
    }
    return { name: "Dibayarkan", color: "#00A538" };
  };

  return (
    <View style={styles.container}>
      <View style={{ marginHorizontal: 20 }}>
        {loanProfile && loanProfile.length > 0 ? (
          <>
            <View style={styles.content}>
              {isLoadingLoanProfile ? (
                <ActivityIndicator color="#344370" />
              ) : (
                <>
                  <View>
                    <CustomText style={styles.remainingBalance}>
                      {`Sisa Saldo Pagu (${
                        loanProfile && loanProfile[0].currency_code
                      })`}
                    </CustomText>
                    <CustomText style={[styles.balance]} numberOfLines={2}>
                      {maskedMoney(
                        Number(loanProfile && loanProfile[0].amount_max) -
                          Number(loanProfile && loanProfile[0].amount_current)
                      )}
                    </CustomText>
                    <CustomText style={styles.remainingBalance}>
                      {`Masa pagu: ${moment(
                        loanProfile && loanProfile[0].loanSource.finished_at
                      ).format("DD MMMM YYYY")}`}
                    </CustomText>
                  </View>
                  <Touchable onPress={() => history.push("kasbon-add")}>
                    <View style={styles.newLoanBtn}>
                      <Image source={ICONS.addLoan} style={styles.addLoan} />
                      <CustomText style={styles.btnText}>
                        Pinjaman Baru
                      </CustomText>
                    </View>
                  </Touchable>
                </>
              )}
            </View>
            <CustomText style={styles.label}>Pengajuan Pinjaman</CustomText>
            {isLoadingSubmitedLoan ? (
              <ActivityIndicator color="#344370" />
            ) : (
              <View style={styles.scrollContent}>
                {submitedLoan.length > 0 ? (
                  <View style={styles.submitedKasbonContainer}>
                    {submitedLoan.map((item: any, index: number) => (
                      <Touchable
                        key={index}
                        onPress={() => history.push(`kasbon-detail/${item.id}`)}
                      >
                        <View style={styles.kasbonStatus}>
                          <CustomText style={{ width: "40%" }}>
                            {moment(item.attributes.created_at).format(
                              "DD MMMM YYYY"
                            )}
                          </CustomText>
                          <CustomText style={styles.amount}>
                            {maskedMoney(item.attributes.amount)}
                          </CustomText>
                          <CustomText
                            style={[
                              styles.status,
                              {
                                color: handleStatus(item.attributes.status)
                                  .color
                              }
                            ]}
                          >
                            {handleStatus(item.attributes.status).name}
                          </CustomText>
                        </View>
                      </Touchable>
                    ))}
                  </View>
                ) : (
                  <CustomText style={{ textAlign: "center", padding: 40 }}>
                    Belum ada pengajuan pinjaman
                  </CustomText>
                )}
              </View>
            )}
            <View style={styles.containerLabel}>
              <CustomText style={styles.label}>Riwayat</CustomText>
              <select
                onChange={e => {
                  dispatch(
                    setSort(e.target.value, () =>
                      dispatch(getLoanHistory(false, e.target.value))
                    )
                  );
                }}
                className="kasbon-filter-history"
              >
                <option value="asc">Terbaru</option>
                <option value="desc">Terlama</option>
              </select>
            </View>

            {loanHistory.length > 0 ? (
              <WrapContent isLoading={false}>
                <WrapList
                  isEmpty={false}
                  onEndReached={() => {
                    if (
                      params.page < params.lastPage &&
                      !isLoadingLoanHistory
                    ) {
                      dispatch(getLoanHistory(true));
                    }
                  }}
                >
                  <View style={[styles.containerHistory, styles.scrollContent]}>
                    <FlatList
                      ListFooterComponent={
                        <LoadingLoadMore active={isLoadingLoanHistory} />
                      }
                      data={loanHistory}
                      keyExtractor={(_, index) => index.toString()}
                      renderItem={({ item, index }) => (
                        <Touchable
                          key={index}
                          onPress={() =>
                            history.push(`kasbon-detail/${item.id}`)
                          }
                        >
                          <View style={styles.history}>
                            <CustomText style={{ width: "40%" }}>
                              {moment(item.attributes.created_at).format(
                                "DD MMMM YYYY"
                              )}
                            </CustomText>
                            <CustomText style={styles.amount}>
                              {maskedMoney(item.attributes.amount)}
                            </CustomText>
                            <CustomText
                              style={[
                                styles.status,
                                {
                                  color: handleStatus(item.attributes.status)
                                    .color
                                }
                              ]}
                            >
                              {handleStatus(item.attributes.status).name}
                            </CustomText>
                          </View>
                        </Touchable>
                      )}
                    />
                  </View>
                </WrapList>
              </WrapContent>
            ) : (
              <CustomText style={{ textAlign: "center", padding: 40 }}>
                Daftar riwayat peminjaman kosong
              </CustomText>
            )}
          </>
        ) : (
          <CustomText style={styles.emptycontent}>
            Akun anda belum mendukung fitur ini. Silahkan hubungi atasan anda
            untuk mengaktifkan fitur kasbon
          </CustomText>
        )}
      </View>
    </View>
  );
};
