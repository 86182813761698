import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";
import { toast } from "../../utils";

export const GET_LOAN_PENDING = "GET_LOAN_PENDING";
export const GET_LOAN_SUCCESS = "GET_LOAN_SUCCESS";
export const GET_LOAN_ERROR = "GET_LOAN_ERROR";

export const GET_LOAN_PROFILE_PENDING = "GET_LOAN_PROFILE_PENDING";
export const GET_LOAN_PROFILE_SUCCESS = "GET_LOAN_PROFILE_SUCCESS";
export const GET_LOAN_PROFILE_ERROR = "GET_LOAN_PROFILE_ERROR";

export const GET_LOAN_HISTORY_PENDING = "GET_LOAN_HISTORY_PENDING";
export const GET_LOAN_HISTORY_SUCCESS = "GET_LOAN_HISTORY_SUCCESS";
export const GET_LOAN_HISTORY_ERROR = "GET_LOAN_HISTORY_ERROR";

export const GET_LOAN_SUBMITED_PENDING = "GET_LOAN_SUBMITED_PENDING";
export const GET_LOAN_SUBMITED_SUCCESS = "GET_LOAN_SUBMITED_SUCCESS";
export const GET_LOAN_SUBMITED_ERROR = "GET_LOAN_SUBMITED_ERROR";

export const POST_LOAN_PENDING = "POST_LOAN_PENDING";
export const POST_LOAN_SUCCESS = "POST_LOAN_SUCCESS";
export const POST_LOAN_ERROR = "POST_LOAN_ERROR";

export const GET_LOAN_DETAIL_PENDING = "GET_LOAN_DETAIL_PENDING";
export const GET_LOAN_DETAIL_SUCCESS = "GET_LOAN_DETAIL_SUCCESS";
export const GET_LOAN_DETAIL_ERROR = "GET_LOAN_DETAIL_ERROR";

export const GET_KASBON_SETTING_PENDING = "GET_KASBON_SETTING_PENDING";
export const GET_KASBON_SETTING_SUCCESS = "GET_KASBON_SETTING_SUCCESS";
export const GET_KASBON_SETTING_ERROR = "GET_KASBON_SETTING_ERROR";

export const SET_STATUS_LOAN = "SET_STATUS_LOAN";
export const SET_SORT = "SET_SORT";

export const getSubmittedLoan = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LOAN_SUBMITED_PENDING });

    const res = await API.getSubmittedLoan(auth.token);

    dispatch({
      type: GET_LOAN_SUBMITED_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getSubmittedLoan())));
      } else {
        dispatch({
          type: GET_LOAN_SUBMITED_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LOAN_PROFILE_ERROR });
  }
};

export const getListLoan = (status: number, loadMore = false) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, loan } = getState();
    dispatch({ type: GET_LOAN_PENDING, payload: { data: loadMore } });

    const res = await API.getListLoan(
      auth.token,
      !loadMore ? { ...loan.params, page: 1 } : loan.params,
      loan.statusNumber
    );

    const resTotal = await API.getLoanTotal(auth.token);

    dispatch({
      type: GET_LOAN_SUCCESS,
      payload: {
        data: {
          data: res.data,
          totalData: resTotal.data.data.length,
          loadMore
        }
      }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getListLoan(status))));
      } else {
        dispatch({
          type: GET_LOAN_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LOAN_ERROR });
  }
};

export const getLoanHistory = (loadMore = false, sortOrder = "desc") => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { auth, loan } = getState();
  try {
    if (loan.params.page < loan.params.lastPage || !loadMore) {
      dispatch({ type: GET_LOAN_HISTORY_PENDING });
      const res = await API.getLoanHistory(auth.token, {
        ...loan.params,
        sortOrder,
        page: !loadMore ? 1 : loan.params.page + 1
      });

      dispatch({
        type: GET_LOAN_HISTORY_SUCCESS,
        payload: {
          data: {
            data: res.data,
            loadMore
          }
        }
      });
    }
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getLoanHistory())));
      } else {
        dispatch({
          type: GET_LOAN_HISTORY_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LOAN_PROFILE_ERROR });
  }
};

export const getLoanProfile = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LOAN_PROFILE_PENDING });

    const res = await API.getLoanProfile(auth.token);
    dispatch({
      type: GET_LOAN_PROFILE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getLoanProfile())));
      } else {
        dispatch({
          type: GET_LOAN_PROFILE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LOAN_PROFILE_ERROR });
  }
};

export const getKasbonSetting = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_KASBON_SETTING_PENDING });

    const res = await API.getKasbonSetting(auth.token);
    localStorage.setItem("kasbon-setting", JSON.stringify(res.data.data));
    dispatch({
      type: GET_KASBON_SETTING_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getKasbonSetting())));
      } else {
        dispatch({
          type: GET_KASBON_SETTING_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LOAN_DETAIL_ERROR });
  }
};

export const getLoanDetail = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LOAN_DETAIL_PENDING });

    const res = await API.getLoanDetail(auth.token, id);
    dispatch({
      type: GET_LOAN_DETAIL_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getLoanDetail(id))));
      } else {
        dispatch({
          type: GET_LOAN_DETAIL_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_LOAN_DETAIL_ERROR });
  }
};

export const setStatusLoan = (statusNumber: number) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_STATUS_LOAN,
    payload: { data: statusNumber }
  });
};

export const setSort = (sort: string, cb: () => void) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_SORT,
    payload: { data: sort }
  });
  cb();
};

export const postLoan = (
  body: any,
  cb: () => void,
  cbCloseModal: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth, loan } = getState();
    dispatch({ type: POST_LOAN_PENDING });
    const formData = new FormData();

    formData.append("amount", body.amount);
    formData.append("currency_code", body.currency_code);
    formData.append("period", body.period);
    formData.append("source_id", body.source_id);
    if (body.client_timezone) {
      formData.append("client_timezone", body.client_timezone);
    }

    const res = await API.postLoan(auth.token, formData);

    dispatch({
      type: POST_LOAN_SUCCESS,
      payload: {
        data: { data: res.data.data }
      }
    });
    if (loan.statusNumber === 3) {
      cb();
    }
    toast.success("berhasil");
    cbCloseModal();
  } catch (err) {
    const error = err.response;
    toast.error(err.response.data.message);
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postLoan(body, cb, cbCloseModal))));
      } else {
        dispatch({
          type: POST_LOAN_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_LOAN_ERROR });
  }
};
