import React, { useState } from "react";
import { Image, Text, View } from "react-native";

import styles from "./styles";
import { Touchable, WrapContent } from "../../../../components";
import { documentTitle } from "../../../../utils";
import { COLORS, ICONS } from "../../../../configs";

const MyActivity = () => {
  documentTitle("Detail Activity - My Activity");

  const [showMore, setShowMore] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);

  return (
    <WrapContent isLoading={false}>
      <View style={styles.container}>
        <View style={{ paddingHorizontal: 16 }}>
          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>Project</Text>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>TRP/DJN/1/2020/0009</Text>
              <Text style={styles.font14bold}>Revitalisasi Stasiun Juanda</Text>
              <Text style={styles.label}>Meeting (External)</Text>
            </View>
            <View style={styles.wrapButtonTrash}>
              <Touchable>
                <View style={styles.buttonTrash}>
                  <Image
                    source={ICONS.trash}
                    style={{ width: 12, height: 12 }}
                  />
                </View>
              </Touchable>
            </View>
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>Trip</Text>
            <View style={{ flex: 1 }}>
              <Text style={styles.value}>Jakarta – Bali</Text>
              <Text style={styles.value}>
                Mon
                <Text style={{ fontWeight: "bold" }}>
                  {`${" 28 Dec 2020"}`}
                </Text>
                {`${" – Wed"}`}
                <Text style={{ fontWeight: "bold" }}>
                  {`${" 30 Jan 2021"}`}
                </Text>
              </Text>
            </View>
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>Participant</Text>
            <View style={{ flex: 1 }}>
              <Text style={[styles.font14bold, { fontSize: 12 }]}>
                Art Vandelay, Rusty Shackleford
              </Text>
            </View>
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>Assigned by</Text>
            <View style={{ flex: 1 }}>
              <Text style={[styles.font14bold, { fontSize: 12 }]}>
                Celine Espinoza
              </Text>
            </View>
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>Activity</Text>
            <View style={{ flex: 1 }}>
              <Text style={[styles.font14bold, { fontSize: 12 }]}>
                Meeting Kedua
              </Text>
            </View>
          </View>

          <View style={styles.flexDirection}>
            <Text style={[styles.label, styles.widthLabel]}>Self Booking</Text>
            <View style={{ flex: 1 }}>
              <Text
                style={[
                  styles.font14bold,
                  { fontSize: 12, color: COLORS.green }
                ]}
              >
                YES
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 20 }}>
          <View style={styles.card}>
            <View style={styles.flexDirection}>
              <Text style={[styles.label, styles.widthLabel]}>Subject</Text>
              <View style={{ flex: 1 }}>
                <Text
                  style={styles.value}
                  numberOfLines={isShowMore ? undefined : 3}
                  onLayout={e => {
                    if (!showMore && e.nativeEvent.layout.height > 51) {
                      setShowMore(true);
                      setIsShowMore(false);
                    }
                  }}
                >
                  Suspendisse augue ex, sodales ac erat vel, sagittis efficitur
                  magna. Pellentesque facilisis metus quis arcu consectetur
                  sollicitudin
                </Text>
                {showMore && (
                  <Text
                    style={[
                      styles.value,
                      { marginTop: 12, textDecorationLine: "underline" }
                    ]}
                    onPress={() => setIsShowMore(!isShowMore)}
                  >
                    {!isShowMore ? "Show More" : "Show Less"}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </WrapContent>
  );
};

export default MyActivity;
