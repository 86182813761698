import React from "react";
import { View } from "react-native";
import { useHistory } from "react-router-dom";

import { IMAGES } from "../../../configs";
import { Button } from "../../../components";
import { Container, DescImage, DescText } from "../components";
import styles from "./styles";

const EmailRegister = () => {
  const history = useHistory();

  return (
    <Container>
      <DescImage image={IMAGES.successRegister} />
      <DescText
        title={`Hurray !${"\n"}You're registered`}
        desc="Okay calm down, a lot of things to do, we even haven’t done packing! Let’s start the journey, shall we ?"
      />
      <View style={styles.wrapButton}>
        <Button
          title="LET'S GO"
          onPress={() => history.push("/home")}
          size="large"
        />
      </View>
    </Container>
  );
};

export default EmailRegister;
