import { combineReducers } from "redux";

import auth from "./auth";
import home from "./home";
import trip from "./trip";
import region from "./region";
import expense from "./expense";
import detailBooking from "./detailBooking";
import booking from "./booking";
import newExpense from "./newExpense";
import newTrip from "./newTrip";
import newBookingFlight from "./newBookingFlight";
import project from "./project";
import vendor from "./vendor";
import regency from "./regency";
import source from "./source";
import bookingHotel from "./bookinghotel";
import report from "./report";
import policies from "./policies";
import expenseListApproval from "./expenseListApproval";
import newExpenseTrip from "./newExpenseTrip";
import newCashExpense from "./newCashExpense";
import saveRoute from "./saveRoute";
import editTrip from "./editTrip";
import newReimburseExpense from "./newReimburseExpense";
import tempNewBill from "./tempNewBill";
import cashAdvanceBill from "./cashAdvanceBill";
import employee from "./employee";
import task from "./task";
import cash from "./cash";
import invoice from "./invoice";
import notification from "./notification";
import bubbleNotif from "./bubbleNotif";
import loan from "./loan";

export default combineReducers({
  auth,
  home,
  project,
  trip,
  expense,
  detailBooking,
  booking,
  region,
  policies,
  newExpense,
  newTrip,
  newBookingFlight,
  vendor,
  regency,
  source,
  bookingHotel,
  report,
  expenseListApproval,
  newExpenseTrip,
  newCashExpense,
  saveRoute,
  editTrip,
  newReimburseExpense,
  tempNewBill,
  cashAdvanceBill,
  employee,
  task,
  cash,
  invoice,
  notification,
  bubbleNotif,
  loan
});
