import React, { ReactNode } from "react";
import { Text, TextStyle, View, ViewStyle } from "react-native";

import styles from "./styles";
import Touchable from "../Touchable";

export type CardStatusColor =
  | "#50b1b1"
  | "#ed5a28"
  | "#F5AA3F"
  | "#F32013"
  | "#2B94FC"
  | "";

interface Props {
  children: ReactNode;
  statusColor?: CardStatusColor | string;
  statusText?: string;
  statusTextLight?: string;
  statusDesc?: string;
  statusDescLight?: string;
  cardStyle?: ViewStyle | ViewStyle[];
  containerStyle?: ViewStyle | ViewStyle[];
  statusStyle?: ViewStyle | ViewStyle[];
  statusTextStyle?: TextStyle | TextStyle[];
  statusDescStyle?: TextStyle | TextStyle[];
  onPress?: () => void;
  disabled?: boolean;
}

const Card = ({
  children,
  statusColor,
  statusText,
  statusTextLight,
  statusDesc,
  statusDescLight,
  cardStyle,
  onPress,
  disabled,
  statusStyle,
  statusTextStyle,
  statusDescStyle,
  containerStyle
}: Props) => {
  return (
    <View style={containerStyle}>
      <View style={[styles.card, cardStyle]}>
        <Touchable onPress={onPress} disabled={disabled}>
          <View>{children}</View>
        </Touchable>
        {statusColor && (
          <View
            style={[
              styles.status,
              { backgroundColor: statusColor },
              statusStyle
            ]}
          >
            <Text style={[styles.statusText, statusTextStyle]}>
              {statusTextLight && (
                <Text style={{ fontWeight: "normal" }}>{statusTextLight}</Text>
              )}
              {statusText}
            </Text>
            <Text style={[styles.statusDesc, statusDescStyle]}>
              {statusDescLight && (
                <Text style={{ fontWeight: "normal" }}>{statusDescLight}</Text>
              )}
              {statusDesc}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

Card.defaultProps = {
  disabled: false
};

export default Card;
