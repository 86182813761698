import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const PUT_EDIT_TRIPS_PENDING = "PUT_EDIT_TRIPS_PENDING";
export const PUT_EDIT_TRIPS_SUCCESS = "PUT_EDIT_TRIPS_SUCCESS";
export const PUT_EDIT_TRIPS_ERROR = "PUT_EDIT_TRIPS_ERROR";

declare const alert: (param: any) => void;

export const putEditTrips = (form: any, id: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_EDIT_TRIPS_PENDING });
    const formdata = new FormData();
    Object.keys(form).forEach((key: any) => {
      formdata.append(key, form[key]);
    });
    formdata.append("_method", "PUT");

    const res = await API.putEditTrip(formdata, auth.token, id);
    dispatch({
      type: PUT_EDIT_TRIPS_SUCCESS,
      payload: { data: res.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putEditTrips(form, id, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: PUT_EDIT_TRIPS_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: PUT_EDIT_TRIPS_ERROR });
  }
};
