import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  bookingDetailContainer: {
    flex: 1
  },
  bookingpDetailContent: {
    paddingHorizontal: 20,
    marginBottom: 25
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 14,
    paddingRight: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "22%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "78%"
  },
  fabStyle: {
    alignItems: "center",
    justifyContent: "center"
  },
  fabIcon: {
    width: 80,
    height: 80
  },
  footer: {
    flexDirection: "row",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "space-between",
    paddingVertical: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  containAmount: {
    width: "50%",
    alignItems: "center"
  },
  titleAmount: {
    color: "white",
    fontSize: 11,
    marginBottom: 8,
    fontFamily: "Poppins"
  },
  navigation: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    alignSelf: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    width: "100%"
  },
  btnNav: {
    borderRadius: 50,
    width: `${100 / 3}%`
  },
  active: {
    borderRadius: 50,
    backgroundImage:
      "linear-gradient(to bottom, #faaf40 -25%, #f05a28 54%, #db3a26)",
    width: `${100 / 3}%`,
    paddingVertical: 2,
    marginVertical: -2
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  modalStyle: {
    justifyContent: "flex-start"
  }
});

export default styles;
