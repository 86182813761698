/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import "./style.css";
import styles from "./styles";
import {
  AutoComplete,
  Card,
  Modal,
  Space,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import CustomText from "../../../../components/Text";
import { Confirmation } from "../../../../components/ModalComponents";
import {
  deleteCashItem,
  getCashDetail,
  getRequestList,
  getTaxList,
  putReissue,
  setModalConfirmation
  // submitIssue
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import {
  toast as CustomToast,
  formatMoney,
  maskedMoney,
  textToLink
} from "../../../../utils";
import { API, COLORS, IMAGES } from "../../../../configs";

interface Props {
  id: string;
}

const Request = ({ id }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { cashState, homeState, newExpenseState, token } = useSelector(
    (state: Reducers) => ({
      cashState: state.cash,
      homeState: state.home,
      newExpenseState: state.newExpense,
      token: state.auth.token
    }),
    shallowEqual
  );

  const listTax = newExpenseState.listTax.map((e: any) => {
    return { value: e.id, label: e.title };
  });

  const [modalReissue, setModalReissue] = useState(false);
  const [modaAddTax, setModalAddTax] = useState(false);
  const [idDelete, setIdDelete] = useState("");
  const [detailTax, setDetailTax] = useState(null) as any;
  const [permissionList, setPermissionList] = useState({
    status: cashState.cashHistory?.status,
    type: cashState.cashHistory?.djurneeInvoice?.type?.title,
    creatorId: cashState.cashHistory?.creator?.id,
    userID: homeState.myProfile.id,
    recurring: cashState.cashDetail?.djurneeInvoice?.recurring,
    trip: cashState.cashDetail?.djurneeInvoice?.trip
  });

  const [bodyTax, setBodyTax] = useState({
    invoice_id: "",
    tax_id: "",
    amount: null,
    client_timezone: "Asia/Jakarta"
  });

  useEffect(() => {
    if (cashState.cashHistory && homeState.myProfile) {
      dispatch(getRequestList(id));
    }
  }, [dispatch, cashState.cashHistory, homeState.myProfile]);

  useEffect(() => {
    if (modaAddTax) {
      dispatch(getTaxList());
    }
  }, [dispatch, modaAddTax]);

  useEffect(() => {
    setPermissionList({
      ...permissionList,
      status: cashState.cashHistory && cashState.cashHistory.status,
      type:
        cashState.cashHistory &&
        cashState.cashHistory.djurneeInvoice.type.title,
      creatorId: cashState.cashHistory && cashState.cashHistory.creator.id,
      userID: homeState.myProfile && homeState.myProfile.id
    });
  }, [cashState.cashHistory, homeState.myProfile]);

  const _permissionCreate = useCallback(() => {
    if (
      !permissionList.trip &&
      [4].includes(permissionList.status) &&
      permissionList.type !== "Lump Sum" &&
      permissionList.creatorId === permissionList.userID
    ) {
      return true;
    }
    return false;
  }, [
    permissionList.creatorId,
    permissionList.status,
    permissionList.type,
    permissionList.userID,
    permissionList.trip
  ]);

  const _permissionEdit = useCallback(() => {
    if (
      permissionList.recurring &&
      !permissionList.trip &&
      [1, 2, 3, 4].includes(permissionList.status) &&
      permissionList.creatorId === permissionList.userID
    ) {
      return true;
    }
    if (
      !permissionList.recurring &&
      !permissionList.trip &&
      [1, 4].includes(permissionList.status) &&
      permissionList.creatorId === permissionList.userID
    ) {
      return true;
    }
    return false;
  }, [
    permissionList.creatorId,
    permissionList.status,
    permissionList.userID,
    permissionList.recurring,
    permissionList.trip
  ]);

  const _onDelete = () => {
    dispatch(
      deleteCashItem(idDelete, (run, info) => {
        if (run === true) {
          dispatch(setModalConfirmation(false));
          toast.success(info, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
          setTimeout(() => {
            dispatch(getCashDetail(id));
            dispatch(getRequestList(id));
          }, 2100);
        } else {
          toast.error(info, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _onReissue = () => {
    const formIssue = {
      invoice_id: id,
      client_timezone: "Asia/Jakarta"
    };
    dispatch(
      putReissue(formIssue, (run, info) => {
        if (run === true) {
          setModalReissue(false);
          toast.success(info, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });

          dispatch(getCashDetail(id));
          dispatch(getRequestList(id));
        } else {
          toast.error(info, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "cash-item-toast",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _goToPreview = (link: string) => {
    window.open(`${link}`, "_blank");
  };

  const _detailTax = (value: string) => {
    const result = newExpenseState.listTax.find(e => e.id === value);
    setDetailTax(result);
    setBodyTax({ ...bodyTax, tax_id: value });
  };

  const _taxAmount = () => {
    const amountInv = cashState.cashDetail?.amount;
    const rate = detailTax?.rate;
    if (amountInv && rate) {
      return Number((amountInv * rate) / 100);
    }
    return 0;
  };

  const _saveAddTax = () => {
    const body = {
      ...bodyTax,
      amount: _taxAmount(),
      invoice_id: cashState.cashDetail.id
    };

    API.postTaxItem(body, token)
      .then(() => {
        setModalAddTax(false);
        dispatch(getRequestList(id));
        setTimeout(() => {
          CustomToast.success("Data has been stored");
        }, 400);
      })
      .catch(err => {
        setModalAddTax(false);
        setTimeout(() => {
          CustomToast.error(
            err?.response?.data?.message || "Something went wrong!"
          );
        }, 400);
      });
  };

  return (
    <WrapContent isLoading={cashState.isLoadingRequestList}>
      <ToastContainer limit={1} />
      <WrapList
        isEmpty={cashState.requestList.length === 0}
        emptyComponent={() => (
          <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
        )}
      >
        <View style={styles.container}>
          <FlatList
            data={cashState.requestList}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) =>
              _permissionEdit() ? (
                <Card
                  onPress={() =>
                    history.push(
                      `/add-cash-item/${id}?id_item=${
                        item.item.id
                      }&project_id=${
                        cashState.cashHistory &&
                        cashState.cashHistory.djurneeInvoice.project.id
                      }&is_tax=${item.item.tax}`
                    )
                  }
                  cardStyle={styles.cardStyle}
                  statusColor="#50b1b1"
                  statusTextLight={t("cash.new.qty")}
                  statusText={item.item ? ` ${item.item.quantity}` : "-"}
                  statusDescLight="IDR "
                  statusDesc={formatMoney(item.item.total)}
                  statusDescStyle={{ fontSize: 14 }}
                  statusStyle={{ paddingVertical: 4, paddingHorizontal: 16 }}
                >
                  <View style={styles.wrapContentCard}>
                    <View style={styles.wrapCardText}>
                      <Text
                        style={[
                          styles.textLabel,
                          index !== 0 && { width: "25.2%" }
                        ]}
                      >
                        {t("cash.new.type")}
                      </Text>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          styles.bold
                        ]}
                      >
                        {item.category ? item.category.title : "-"}
                      </Text>
                      {item.item.file && (
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            _goToPreview(
                              item.item.file && JSON.parse(item.item.file).url
                            );
                          }}
                        >
                          <View style={styles.wrapTrashIcon}>
                            <Image
                              source={IMAGES.clipAttchment}
                              style={{ width: 17, height: 20 }}
                            />
                          </View>
                        </Touchable>
                      )}
                      {permissionList.recurring ? (
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            dispatch(setModalConfirmation(true));
                            setIdDelete(item.item.id);
                          }}
                        >
                          <View
                            style={[styles.wrapTrashIcon, { marginLeft: 8 }]}
                          >
                            <Image
                              source={IMAGES.trashBin}
                              style={{ width: 12, height: 15 }}
                            />
                          </View>
                        </Touchable>
                      ) : cashState.requestList.length > 1 ? (
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            dispatch(setModalConfirmation(true));
                            setIdDelete(item.item.id);
                          }}
                        >
                          <View
                            style={[styles.wrapTrashIcon, { marginLeft: 8 }]}
                          >
                            <Image
                              source={IMAGES.trashBin}
                              style={{ width: 12, height: 15 }}
                            />
                          </View>
                        </Touchable>
                      ) : (
                        <></>
                      )}
                    </View>

                    <View style={styles.wrapCardText}>
                      <Text style={styles.textLabel}>
                        {t("cash.new.description")}
                      </Text>
                      <View style={{ width: "79%" }}>
                        <Text
                          style={[
                            styles.textLabel,
                            styles.textValue,
                            { width: "100%" }
                          ]}
                        >
                          {item.item ? item.item.title : "-"}
                        </Text>
                        <Text
                          numberOfLines={1}
                          style={[
                            styles.textLabel,
                            styles.textValue,
                            { width: "100%" }
                          ]}
                        >
                          <span
                            dangerouslySetInnerHTML={textToLink(
                              item.item && item.item.description
                                ? item.item.description
                                : "-"
                            )}
                          />
                        </Text>
                      </View>
                    </View>
                  </View>
                </Card>
              ) : (
                <View style={{ marginBottom: 16 }} key={index}>
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>{t("cash.new.type")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.category ? item.category.title : "-"}
                    </Text>
                    {item.item.file && (
                      <Touchable
                        onPress={() =>
                          _goToPreview(
                            item.item.file && JSON.parse(item.item.file).url
                          )
                        }
                      >
                        <View style={styles.wrapTrashIcon}>
                          <Image
                            source={IMAGES.clipAttchment}
                            style={{ width: 17, height: 20 }}
                          />
                        </View>
                      </Touchable>
                    )}
                  </View>
                  <View style={styles.wrapText}>
                    <Text style={styles.textLabel}>
                      {t("cash.new.description")}
                    </Text>
                    <View style={{ width: "77%" }}>
                      <Text
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          { width: "100%" }
                        ]}
                      >
                        {item.item ? item.item.title : "-"}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.textLabel,
                          styles.textValue,
                          { width: "100%" }
                        ]}
                      >
                        <span
                          dangerouslySetInnerHTML={textToLink(
                            item?.item?.description
                              ? item?.item?.description
                              : "-"
                          )}
                        />
                      </Text>
                    </View>
                  </View>
                  <View style={styles.wrapText} key={index}>
                    <Text style={styles.textLabel}>{t("cash.new.qty")}</Text>
                    <Text
                      style={[styles.textLabel, styles.textValue, styles.bold]}
                    >
                      {item.item ? item.item.quantity : "-"}
                    </Text>
                  </View>
                  <View style={styles.wrapAmountText}>
                    <Text
                      style={[
                        styles.amountText,
                        Number(item?.item?.total) < 0 && { color: "red" }
                      ]}
                    >
                      <Text style={{ fontWeight: "normal" }}>IDR </Text>
                      {formatMoney(item.item.total)}
                    </Text>
                  </View>
                  <View style={styles.line} />
                </View>
              )
            }
          />
          {_permissionCreate() && (
            <View style={styles.wrapButton}>
              <Card
                containerStyle={styles.button}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() =>
                  history.push(
                    `/add-cash-item/${id}?project_id=${
                      cashState.cashHistory &&
                      cashState.cashHistory.djurneeInvoice.project.id
                    }`
                  )
                }
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {t("cash.new.addRequest")}
                </Text>
              </Card>
              <Card
                onPress={() => setModalReissue(true)}
                containerStyle={styles.button}
                cardStyle={styles.button2}
              >
                <Text style={styles.buttonText}>
                  {cashState.cashHistory.status === 1
                    ? t("cash.new.issue")
                    : "Reissue"}
                </Text>
              </Card>
            </View>
          )}
          {permissionList.status <= 5 && (
            <View
              style={[
                styles.wrapButton,
                {
                  justifyContent: _permissionCreate()
                    ? "space-between"
                    : "center"
                }
              ]}
            >
              <Card
                containerStyle={{ width: _permissionCreate() ? "100%" : "48%" }}
                cardStyle={[styles.button2, styles.button3]}
                onPress={() => setModalAddTax(true)}
              >
                <Text style={[styles.buttonText, styles.buttonText2]}>
                  {`+ ${t("cash.new.addTax")}`}
                </Text>
              </Card>
            </View>
          )}
          <View style={styles.wrapTotal}>
            <Text style={styles.totalLabel}>{t("cash.new.totalRequest")}</Text>
            <Text style={styles.amountText}>
              <Text style={{ fontWeight: "normal" }}>IDR </Text>
              {maskedMoney(cashState.cashDetail && cashState.cashDetail?.total)}
            </Text>
          </View>
        </View>
      </WrapList>
      <Modal isVisible={modalReissue} backdropColor="rgba(255,255,255, 0.97)">
        <Confirmation
          headerTitle={`${
            cashState.cashHistory.status === 1 ? "ISSUE" : "REISSUE"
          } ${permissionList.type}`}
          actionTitle={cashState.cashHistory.status === 1 ? "ISSUE" : "REISSUE"}
          description="Make sure the item(s) and amount are correct"
          onCancel={() => setModalReissue(false)}
          isLoading={
            cashState.cashHistory.status === 1
              ? cashState.isLoadingSubmitIssue
              : cashState.isLoadingReissue
          }
          onAction={_onReissue}
        />
      </Modal>
      <Modal
        isVisible={homeState.confirmationFlag}
        backdropColor="rgba(255,255,255, 0.9)"
      >
        <Confirmation
          headerTitle="DELETE"
          description="Are you sure you want to delete this item?"
          actionTitle="DELETE"
          onCancel={() => dispatch(setModalConfirmation(false))}
          isLoading={cashState.isLoadingDeleteCashItem}
          onAction={_onDelete}
        />
      </Modal>
      <Modal isVisible={modaAddTax}>
        <View style={styles.addTaxContent}>
          <CustomText
            type="bold"
            color={COLORS.orange}
            size={20}
            textAlign="center"
          >
            Add tax item
          </CustomText>
          <Space height={12} />
          <CustomText size={12}>From Invoice Number</CustomText>
          <Space height={2} />
          <CustomText type="semibold">0002/INV/DJN/X/2022-D</CustomText>
          <Space height={8} />
          <CustomText size={12}>Tax</CustomText>
          <View>
            <AutoComplete
              placeHolder="Pick a tax type"
              option={listTax}
              onValueChange={e => _detailTax(e.value)}
            />
            <Space height={8} />
            {!detailTax && (
              <CustomText size={10}>Pick a tax type to start</CustomText>
            )}
          </View>
          <Space height={8} />
          {detailTax && (
            <>
              <CustomText size={12}>Title</CustomText>
              <Space height={2} />
              <CustomText
                style={{ textTransform: "uppercase" }}
                type="semibold"
              >
                {`${detailTax?.type} ${detailTax?.title}`}
              </CustomText>
              <Space height={8} />
              <CustomText size={12}>Category</CustomText>
              <Space height={2} />
              <CustomText type="semibold">
                {detailTax?.category?.title}
              </CustomText>
              <Space height={8} />
              <CustomText size={12}>Description</CustomText>
              <Space height={2} />
              <CustomText type="semibold">{detailTax?.description}</CustomText>
              <Space height={8} />
              <CustomText size={12}>Rate</CustomText>
              <Space height={2} />
              <CustomText type="semibold">{`${detailTax?.rate}%`}</CustomText>
              <Space height={8} />
              <CustomText size={12}>Invoice Amount</CustomText>
              <Space height={2} />
              <CustomText type="semibold">
                {formatMoney(cashState?.cashDetail?.amount)}
              </CustomText>
              <Space height={8} />
              <CustomText size={12}>Tax Amount</CustomText>
              <Space height={2} />
              <CustomText type="semibold">
                {formatMoney(_taxAmount())}
              </CustomText>
              <Space height={8} />
            </>
          )}
          <Space height={20} />
          <View style={{ alignSelf: "center", alignItems: "center" }}>
            <TouchableOpacity
              style={[styles.submitBtn, { opacity: bodyTax.tax_id ? 1 : 0.5 }]}
              disabled={!bodyTax.tax_id}
              onPress={_saveAddTax}
            >
              <CustomText color={COLORS.white} type="bold">
                {t("cash.new.save")}
              </CustomText>
            </TouchableOpacity>
            <Space height={16} />
            <TouchableOpacity
              onPress={() => {
                setTimeout(() => {
                  setModalAddTax(false);
                  setDetailTax(null);
                }, 400);
              }}
            >
              <CustomText type="bold" color={COLORS.grey}>
                {t("moderation.cancel")}
              </CustomText>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </WrapContent>
  );
};

export default Request;
