/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { Container, Fixed, Header, Modal, Touchable } from "../../components";
import { Confirmation } from "../../components/ModalComponents";
import ClosingTripNotes from "./Trip/components/ClosingTripNotes";

import styles from "./styles";
import { copyWritings, queryString, textToLink } from "../../utils";

import { getTripById, putNewTrip } from "../../redux/actions";
import { Reducers } from "../../redux/types";

import Booking from "./Booking";
import Trip from "./Trip";
import Expense from "./Expense";
import Report from "./Report";
import NoteRevise from "./ModalNoteRevise";
import ModalResolve from "./ModalResolve";
import { IMAGES } from "../../configs";

const TripDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const { id } = useParams() as any;
  const tripState = useSelector((state: Reducers) => state.trip);
  const { isLoadingEditTrip } = useSelector((state: Reducers) => state.newTrip);
  useEffect(() => {
    dispatch(getTripById(id));
  }, [dispatch, id]);

  const copTeks = {
    trip: copyWritings("trip"),
    booking: copyWritings("booking"),
    report: copyWritings("report"),
    expense: copyWritings("expense")
  };

  const match = useRouteMatch();
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;
  const history = useHistory();
  const [heightHeader, setHeightHeader] = useState(0);
  const [heightSubHeader, setHeightSubHeader] = useState(0);
  const [noteReviseOpen, setNoteReviseOpen] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const [showClosingTripNotes, setShowClosingTripNotes] = useState(false);
  const { pathname, search, state } = history.location as any;
  const param = queryString(search);

  const listPages = [
    {
      pathname: initialRoute,
      component: <Trip id={id!} search={search} />,
      exact: true
    },
    // navigation 2
    {
      pathname: `${initialRoute}/expense`,
      component: (
        <Expense
          id={id!}
          search={search}
          top={heightHeader}
          offsetTop={heightSubHeader / 2}
        />
      ),
      exact: false
    },
    {
      pathname: `${initialRoute}/booking`,
      component: (
        <Booking
          id={id!}
          search={search}
          top={heightHeader}
          offsetTop={heightSubHeader / 2}
        />
      ),
      exact: false
    },
    {
      pathname: `${initialRoute}/booked`,
      component: (
        <Booking
          id={id!}
          top={heightHeader}
          offsetTop={heightSubHeader / 2}
          search={search}
        />
      ),
      exact: false
    },
    {
      pathname: `${initialRoute}/report`,
      component: <Report id={id!} top={heightHeader} search={search} />,
      exact: true
    }
  ];

  const showReportNav = !(
    (param && param.type === "ongoing") ||
    (param && param.type === "history") ||
    false
  );

  const _checkTripType = (type: string) => {
    if (type === "ongoing") {
      history.push(listPages[3].pathname + search);
    } else {
      history.push(listPages[2].pathname + search);
    }
  };

  const _reportNav = () => {
    return !showReportNav ? (
      <View
        style={
          listPages[4].pathname === pathname
            ? [styles.active, styles.activeLast, { width: "25%" }]
            : [styles.btnNav, { width: "25%" }]
        }
      >
        <Touchable onPress={() => history.push(listPages[4].pathname + search)}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 32
            }}
          >
            <Text style={styles.textButton}>{copTeks.report}</Text>
          </View>
        </Touchable>
      </View>
    ) : null;
  };

  const _handleGoBack = () => {
    if (param.type === "upcoming") {
      history.push("/trip");
    } else {
      history.push(`/trip/${param.type}`);
    }
  };

  const _titleHeader = () => {
    let title = "Loading...";
    if (tripState.tripData) {
      title = tripState.tripData.title;
    }
    return title;
  };

  const _openResolveModal = () => {
    setNoteReviseOpen(false);
    setResolveOpen(true);
  };

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (state) {
      status = state.labelStatus;
    } else if (tripState.tripData) {
      status = tripState.tripData.status;
    }

    if (status === 1) {
      desc = t("activity.status.draft");
    } else if (status === 2) {
      desc = t("activity.status.needApproval");
    } else if (status === 3) {
      desc = t("activity.status.approved");
    } else if (status === 4) {
      desc = t("activity.status.revise");
    } else if (status === 5) {
      desc = t("activity.status.ongoing");
    } else if (status === 6) {
      desc = t("activity.status.finished");
    } else if (status === 7) {
      desc = t("activity.status.rejected");
    } else if (status === 8) {
      desc = t("cash.status.overdue");
    }

    return desc;
  };

  return (
    <Switch>
      <Container>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            notifPastDueDate={
              tripState.tripData &&
              tripState.tripData.past_the_due_date &&
              param.type === "ongoing"
            }
            notifPastDueDateBtn={() =>
              setShowClosingTripNotes(!showClosingTripNotes)
            }
            title={_titleHeader()}
            textHeader={styles.textHeader}
            goBack={_handleGoBack}
            label={_statusDesc()}
            notif={param.status}
            notifBtn={() => setNoteReviseOpen(true)}
          />
          <View
            style={styles.container}
            onLayout={e => setHeightSubHeader(e.nativeEvent.layout.height)}
          >
            <View style={styles.wrapNavigation}>
              <View style={styles.navigation}>
                <View
                  style={
                    listPages[0].pathname === pathname
                      ? [
                          styles.active,
                          styles.activeFirst,
                          showReportNav
                            ? { width: `${100 / 3}%` }
                            : { width: "25%" }
                        ]
                      : [
                          styles.btnNav,
                          showReportNav
                            ? { width: `${100 / 3}%` }
                            : { width: "25%" }
                        ]
                  }
                >
                  <Touchable
                    onPress={() => history.push(listPages[0].pathname + search)}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: 32
                      }}
                    >
                      <Text style={styles.textButton}>{copTeks.trip}</Text>
                    </View>
                  </Touchable>
                </View>

                <View
                  style={
                    pathname.includes(listPages[1].pathname)
                      ? [
                          styles.active,
                          styles.activeMid,
                          showReportNav
                            ? { width: `${100 / 3}%` }
                            : { width: "25%" }
                        ]
                      : [
                          styles.btnNav,
                          showReportNav
                            ? { width: `${100 / 3}%` }
                            : { width: "25%" }
                        ]
                  }
                >
                  <Touchable
                    onPress={() => history.push(listPages[1].pathname + search)}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: 32
                      }}
                    >
                      <Text style={styles.textButton}>{copTeks.expense}</Text>
                    </View>
                  </Touchable>
                </View>

                <View
                  style={
                    pathname.includes(listPages[2].pathname) ||
                    pathname.includes(listPages[3].pathname)
                      ? [
                          styles.active,
                          showReportNav ? styles.activeLast : styles.activeMid,
                          showReportNav
                            ? { width: `${100 / 3}%` }
                            : { width: "25%" }
                        ]
                      : [
                          styles.btnNav,
                          showReportNav
                            ? { width: `${100 / 3}%` }
                            : { width: "25%" }
                        ]
                  }
                >
                  <Touchable
                    onPress={() => {
                      _checkTripType(param.type);
                    }}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: 32
                      }}
                    >
                      <Text style={styles.textButton}>{copTeks.booking}</Text>
                    </View>
                  </Touchable>
                </View>
                {_reportNav()}
              </View>
            </View>
          </View>
        </Fixed>

        <View style={{ zIndex: -1, marginTop: heightHeader }}>
          {listPages.map((item, index) => (
            <Route path={item.pathname} key={index} exact={item.exact}>
              {item.component}
            </Route>
          ))}
        </View>

        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0
          }}
        >
          <View style={styles.wrapFooter}>
            <TouchableOpacity
              onPress={() =>
                history.push(
                  `/managemet-trip/${id}?type=${param.type}&status=${param.status}`
                )
              }
              activeOpacity={0.95}
              style={{
                marginRight: 6,
                paddingTop: 5
              }}
            >
              <Image
                source={IMAGES.actionPercent}
                style={{ height: 62, width: 62 }}
              />
            </TouchableOpacity>

            {(param.status === "1" || param.status === "4") && (
              <View style={[styles.shadow]}>
                <TouchableOpacity
                  activeOpacity={0.8}
                  style={styles.btn}
                  onPress={() =>
                    history.push(`/edit-activity/${id}`, {
                      status: tripState.tripData.status
                    })
                  }
                >
                  <Text style={styles.btnText}>
                    {sprintf(t("activity.detail.edit"), copTeks.trip)}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {param.status === "1" &&
              moment(
                tripState.tripData && tripState.tripData.finished_at
              ).format("YYYY-MM-DD HH:mm") >
                moment().format("YYYY-MM-DD HH:mm") && (
                <View style={[styles.shadow, styles.orange]}>
                  <TouchableOpacity
                    onPress={() => setModalSubmit(true)}
                    activeOpacity={0.8}
                    style={styles.btn}
                  >
                    <Text style={[styles.btnText, styles.white]}>
                      {t("activity.detail.submit")}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
          </View>
        </div>
        {noteReviseOpen && (
          <Modal
            isVisible={noteReviseOpen}
            wrapStyle={{ justifyContent: "flex-start" }}
            backdropColor="rgba(255,255,255, 0.9)"
          >
            <NoteRevise
              resolveButton
              headerTitle={t("moderation.notes")}
              onPress={() => setNoteReviseOpen(false)}
              onPressRevise={() => _openResolveModal()}
            >
              <Text>
                <span
                  dangerouslySetInnerHTML={textToLink(
                    tripState.tripData &&
                      tripState.tripData.moderation &&
                      tripState.tripData.moderation.remarks !== null
                      ? tripState.tripData.moderation.remarks
                      : ""
                  )}
                />
              </Text>
            </NoteRevise>
          </Modal>
        )}

        {showClosingTripNotes && (
          <Modal
            backdropColor="rgba(240,240,240, 0.5)"
            isVisible
            onBackdropPress={() =>
              setShowClosingTripNotes(!showClosingTripNotes)
            }
          >
            <ClosingTripNotes
              closeBtn={() => setShowClosingTripNotes(!showClosingTripNotes)}
              tripId={id}
            />
          </Modal>
        )}

        <Modal isVisible={modalSubmit} backdropColor="rgba(255,255,255, 0.9)">
          <Confirmation
            headerTitle="Confirm Activity Issue"
            // eslint-disable-next-line max-len
            description="Make sure to correctly register every expense and booking before you continue. You won't be able to change those afterwards."
            actionTitle="SUBMIT"
            onCancel={() => setModalSubmit(false)}
            isLoading={isLoadingEditTrip}
            onAction={() =>
              dispatch(putNewTrip(id, () => history.push("/trip")))
            }
          />
        </Modal>

        {resolveOpen && (
          <Modal isVisible={resolveOpen}>
            <ModalResolve
              cancel={() => setResolveOpen(false)}
              idTrip={`${id}`}
            />
          </Modal>
        )}
      </Container>
    </Switch>
  );
};
export default TripDetail;
