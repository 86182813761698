import React, { useState } from "react";
import { Image, Text, TextInput, View } from "react-native";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { IMAGES } from "../../../configs";
import { putModerationTrip } from "../../../redux/actions";
import styles from "./styles";
import { Touchable } from "../../index";

interface Props {
  notificationType?: string;
  moderationId?: string;
  cancel?: () => void;
}

const Reject = ({ notificationType, moderationId, cancel }: Props) => {
  const [note, onChangeNote] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const handleModerationTrip = async () => {
    await dispatch(putModerationTrip(moderationId, 3, note));
    if (notificationType) {
      history.push("/expense/approval");
      window.location.reload();
    } else {
      history.push("/trip");
    }
  };
  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {`REJECT ${notificationType?.toUpperCase() || "PROJECT"}`}
      </Text>
      <Image
        resizeMode="contain"
        source={IMAGES.rejectModal}
        style={styles.image}
      />
      <Text style={styles.content}>
        {`Write the issues about this ${
          notificationType || "project"
        } that should be`}
        <Text style={{ fontWeight: "bold" }}> rejected</Text>
        <Text> :</Text>
      </Text>
      <TextInput
        value={note}
        onChangeText={text => onChangeNote(text)}
        style={styles.note}
        multiline
        placeholder="Add your notes here"
      />
      <View style={styles.buttonAttachmen}>
        <Touchable onPress={() => handleModerationTrip()}>
          <View
            style={{
              height: 54,
              alignItems: "center",
              justifyContent: "center",
              width: 140
            }}
          >
            <Text style={styles.buttonText}>REJECT</Text>
          </View>
        </Touchable>
      </View>
      <View style={{ marginTop: 12 }}>
        <Touchable onPress={cancel || (() => history.goBack())}>
          <Text style={styles.cancel}>CANCEL</Text>
        </Touchable>
      </View>
    </View>
  );
};

export default Reject;
