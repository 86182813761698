/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { Reducers } from "../../redux/types";

import { Fixed, Header, Input, Message } from "../../components";

import styles from "./styles";
import "./style.css";

import {
  editDetailBills,
  getBill,
  getBudgetType,
  getCashDetail,
  getCurrency,
  getDetailBills,
  getInvoiceDetail,
  getPopulate
} from "../../redux/actions";
import {
  copyWritings,
  getPolicies,
  heightPercent,
  maskedMoney,
  permissionPage,
  widthPercent
} from "../../utils";
import { COLORS, ICONS } from "../../configs";

const EditBill = () => {
  const { invoiceId, billId } = useParams() as any;

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  const copTeks = {
    expense: copyWritings("expense"),
    policy: copyWritings("policy"),
    collectable: copyWritings("collectable"),
    finance_category: copyWritings("finance_category")
  };

  const { invoiceDetail } = useSelector((state: Reducers) => state.invoice);
  const { cashDetail } = useSelector((state: Reducers) => state.cash);
  const { uuid } = useSelector((state: Reducers) => state.auth);
  const { listPopulate, currencies } = useSelector(
    (state: Reducers) => state.newExpense
  );
  const { isLoadingPostBill } = useSelector(
    (state: Reducers) => state.newExpense
  );
  const { budgetType } = useSelector((state: Reducers) => state.expense);
  const cashState = useSelector((state: Reducers) => state.cash);
  const { loadDataDetailBills } = useSelector(
    (state: Reducers) => state.cashAdvanceBill
  );
  const [valueTime, setValueTime] = useState("");
  const [hourDate, setHourDate]: any[] = useState([]);
  const [fileAttachment, setFileAttachment]: any = useState();
  const [isError, setError] = useState(false);
  const myPermission =
    permissionPage("expense-self-edit") ||
    permissionPage("expense-self-view") ||
    permissionPage("expense-self-list");

  const [body, setBody]: any = useState({
    trip_id: null,
    file: null,
    invoice_id: invoiceId,
    expense_id: billId,
    budget_id: "",
    date_now: false,
    date: null,
    date_d: "",
    date_t: "",
    category_id: "",
    currency_code: "",
    title: "Bill - ",
    description: "",
    quantity: 1,
    amount: 0,
    billable: false,
    currency_id: "",
    client_timezone: "Asia/Jakarta"
  });

  useEffect(() => {
    // if(permissionPage("expense-self-edit") && )
    dispatch(getCashDetail(invoiceId));
    dispatch(getInvoiceDetail(invoiceId));
    dispatch(getBill(invoiceId));
    dispatch(getPopulate());
    dispatch(getDetailBills(billId));
    dispatch(getCurrency());
  }, [dispatch]);

  useEffect(() => {
    if (cashState.cashDetail) {
      if (cashState.cashDetail.creator.id !== uuid || !myPermission) {
        history.push("/cash");
      }
    }
  }, [cashState.cashDetail, myPermission]);

  useEffect(() => {
    if (loadDataDetailBills) {
      setValueTime(moment(loadDataDetailBills.date).format("HH:mm"));
      if (loadDataDetailBills.file) {
        setFileAttachment(loadDataDetailBills.file);
      }
      setBody({
        ...body,
        title: loadDataDetailBills.title,
        date_d: moment(loadDataDetailBills.date).format("YYYY-MM-DD"),
        category_id: loadDataDetailBills.category.id,
        date_t: moment(loadDataDetailBills.date).format("YYYY-MM-DD"),
        description: loadDataDetailBills.description,
        budget_id: loadDataDetailBills.budget
          ? loadDataDetailBills.budget.budget.id
          : null,
        currency_id: loadDataDetailBills.currency.id,
        currency_code: loadDataDetailBills.currency.code,
        amount: Number(loadDataDetailBills.amount)
      });
    }
  }, [loadDataDetailBills]);

  useEffect(() => {
    if (cashState.cashDetail) {
      dispatch(getBudgetType(cashState.cashDetail.djurneeInvoice.project.id));
    }
  }, [cashState.cashDetail]);

  const statusDesc = (status: number, total: any, total_paid: any) => {
    let label = "";
    if (status === 1) {
      label = t("cash.status.draft");
    } else if (status === 2) {
      label = t("cash.status.needApproval");
    } else if (status === 3) {
      label = t("cash.status.approved");
    } else if (status === 4) {
      label = t("cash.status.revise");
    } else if (status === 5 && total_paid >= total) {
      label = t("cash.status.notSettled");
    } else if (status === 5) {
      label = t("cash.status.unpaid");
    } else if (status === 6) {
      label = t("cash.status.settled");
    } else if (status === 7) {
      label = t("cash.status.rejected");
    } else if (status === 8) {
      label = t("cash.status.expired");
    } else if (status === 9) {
      label = t("cash.status.rejectedByFinance");
    }
    return label;
  };

  const _totalItem = () => {
    let total = 0;
    if (cashState.billList.length > 0) {
      cashState.billList.map(item => {
        total += Number(item.total);
      });
    }
    total = cashState.cashDetail && cashState.cashDetail.total - total;
    return maskedMoney(total);
  };

  const previewFile = () => {
    if (
      body &&
      (body.file.type === "application/pdf" ||
        body.file.type === "application/vnd.ms-excel" ||
        body.file.type === ".doc" ||
        body.file.type === ".docx")
    ) {
      window.open(URL.createObjectURL(body.file));
    } else {
      const newTab = window.open();
      newTab!.document.body.innerHTML = `
    <div
      style="display:flex;height:100vh;background-color:#666666;align-items:center;justify-content:center"
    >
      <img src="${URL.createObjectURL(body.file)}">
    </div>`;
    }
  };

  const handleCancel = () => {
    setBody({
      trip_id: null,
      file: null,
      invoice_id: invoiceId,
      budget_id: "",
      date_now: true,
      date: "",
      date_d: "",
      date_t: "",
      category_id: "",
      currency_code: "",
      title: "Bill - ",
      description: "",
      quantity: 1,
      amount: 0,
      billable: false,
      currency_id: "",
      client_timezone: "Asia/Jakarta"
    });
    history.push(`/cash-details/${invoiceId}/bill/bill`);
  };

  const handleSubmit = () => {
    if (Number(body.amount) <= 0 || !body.category_id) {
      setError(true);
    } else if (body.date_t) {
      body.date = new Date(body.date_d);
      if (hourDate.length > 0) {
        body.date = moment(String(body.date)).format("YYYY-MM-DD");
        body.date = new Date(body.date).setHours(Number(hourDate[0]));
        body.date = new Date(body.date).setMinutes(Number(hourDate[1]));
      } else {
        body.date = moment(String(body.date)).format("YYYY-MM-DD");
        body.date = new Date(body.date).setHours(
          Number(valueTime.split(":")[0])
        );
        body.date = new Date(body.date).setMinutes(
          Number(valueTime.split(":")[1])
        );
      }

      body.date = new Date(body.date).toUTCString();
      dispatch(
        editDetailBills(body, billId, () =>
          history.push(`/cash-details/${invoiceId}/bill/bill`)
        )
      );
    } else {
      dispatch(
        editDetailBills(body, billId, () =>
          history.push(`/cash-details/${invoiceId}/bill/bill  `)
        )
      );
    }
  };

  return (
    <View>
      <Header
        textHeader={{ fontSize: 18 }}
        subTitleHeader={{ fontSize: 11 }}
        title={sprintf(t("cash.new.reportDetail"), copTeks.expense)}
        subtitle={invoiceDetail && invoiceDetail.number}
        label={statusDesc(
          invoiceDetail && invoiceDetail.status,
          cashDetail && cashDetail.total,
          cashDetail && cashDetail.total_paid
        )}
        goBack={() => history.push(`/cash-details/${invoiceId}/bill/bill`)}
      />

      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <View>
          <View
            style={{
              width: widthPercent(100) - 20,
              height: heightPercent(100) - 50,
              backgroundColor: COLORS.main
            }}
          >
            <View style={{ marginHorizontal: 20, marginBottom: 20 }}>
              {getPolicies("expense-general-ca-receipt") &&
              fileAttachment === undefined &&
              body.file === null ? (
                <View style={{ marginTop: 20 }}>
                  <Message
                    message={sprintf(
                      t("cash.new.msg.policyNewBill"),
                      copTeks.policy
                    )}
                  />
                </View>
              ) : null}
            </View>

            {cashState.cashDetail &&
            cashState.cashDetail.djurneeInvoice.trip !== undefined ? null : (
              <View style={{ marginHorizontal: 20 }}>
                <View style={styles.columns}>
                  <View style={{ width: "48%" }}>
                    <Text style={styles.labelInput}>{copTeks.collectable}</Text>
                  </View>
                  <View style={{ width: "48%" }}>
                    <Text
                      style={[styles.textTypeGeneral, { color: "#ED5A28" }]}
                    >
                      {`${
                        cashState.cashDetail &&
                        cashState.cashDetail.currency.code
                      } ${_totalItem()}`}
                    </Text>
                  </View>
                </View>
                <View style={styles.columns}>
                  <View>
                    <Text style={styles.labelInput}>
                      {t("cash.new.issued")}
                    </Text>
                  </View>
                  <View style={{ width: "48%" }}>
                    <Text style={styles.textTypeGeneral}>
                      {moment(
                        new Date(
                          cashState.cashDetail &&
                            cashState.cashDetail.published_at
                        ).toUTCString()
                      ).format("ddd DD MMM YYYY")}
                    </Text>
                  </View>
                </View>
                <View style={styles.columns}>
                  <View style={{ width: "48%" }}>
                    <Text style={styles.labelInput}>
                      {t("cash.detail.expired")}
                    </Text>
                  </View>
                  <View style={{ width: "48%" }}>
                    <Text style={styles.textTypeGeneral}>
                      {moment(
                        new Date(
                          cashState.cashDetail && cashState.cashDetail.due_at
                        ).toUTCString()
                      ).format("ddd DD MMM YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            <View style={{ margin: 20 }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <View style={{ width: "48%" }}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.labelInput}>
                      {copTeks.finance_category}
                    </Text>
                    <View
                      style={[
                        styles.card,
                        { justifyContent: "center", alignItems: "center" }
                      ]}
                    >
                      <select
                        className="add-bill-dropdown"
                        onChange={(e: any) =>
                          setBody({ ...body, category_id: e.target.value })
                        }
                      >
                        <option selected={!body.category_id} hidden>
                          {sprintf(
                            t("cash.new.select"),
                            copTeks.finance_category
                          )}
                        </option>
                        {listPopulate.map((expenseType: any, index: number) => (
                          <option
                            key={index}
                            selected={expenseType.value === body.category_id}
                            value={expenseType.value}
                          >
                            {expenseType.label}
                          </option>
                        ))}
                      </select>
                    </View>

                    {isError && !body.category_id ? (
                      <Text style={styles.validateText}>
                        {t("cash.new.msg.req")}
                      </Text>
                    ) : null}
                  </View>
                </View>
                <View style={{ width: "48%" }}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.labelInput}>{t("cash.new.date")}</Text>
                    <View style={[styles.wrapSwitch]}>
                      <Switch
                        onChange={() => {
                          setBody({ ...body, date_now: !body.date_now });
                        }}
                        checked={body.date_now}
                        onColor="#50b1b1"
                        onHandleColor="#fffff7"
                        handleDiameter={19}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                        height={19}
                        width={34}
                      />
                      <Text style={styles.textSelfBooking}>
                        {body.date_now
                          ? t("cash.new.today")
                          : t("cash.new.selectDateTime")}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              {body.date_now ? null : (
                <View style={styles.inputContainer}>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <View style={{ width: "48%" }}>
                      <Text style={styles.labelInput}>
                        {t("cash.new.selectDate")}
                      </Text>
                      <View style={styles.card}>
                        <View style={styles.dateContainer}>
                          <input
                            onChange={e =>
                              setBody({
                                ...body,
                                date_d: new Date(e.target.value).toUTCString()
                              })
                            }
                            value={body.date_d}
                            type="date"
                            className="add-bill-date-time"
                          />
                        </View>
                      </View>
                    </View>
                    <View style={{ width: "48%" }}>
                      <Text style={styles.labelInput}>
                        {t("cash.new.selectTime")}
                      </Text>
                      <View style={styles.card}>
                        <View style={styles.dateContainer}>
                          <input
                            value={valueTime}
                            onChange={e => {
                              setValueTime(e.target.value);
                              const hour = e.target.value.split(":")[0];
                              const minute = e.target.value.split(":")[1];
                              setHourDate(e.target.value.split(":"));

                              const date = moment(new Date())
                                .set({
                                  hour: Number(hour),
                                  minute: Number(minute)
                                })
                                .format("DD-MMMM-YYYY HH:mm");
                              setBody({
                                ...body,
                                date_t: new Date(date).toUTCString()
                              });
                            }}
                            type="time"
                            className="add-bill-date-time"
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              )}

              <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>{t("cash.new.subject")}</Text>
                <Input
                  value={body.title}
                  onChangeText={(e: any) => setBody({ ...body, title: e })}
                  placeholder={t("cash.new.subjectPH")}
                  inputStyle={styles.inputStyle}
                />
              </View>

              <View style={styles.inputContainer}>
                <Text style={styles.labelInput}>
                  {t("cash.new.description")}
                </Text>
                <textarea
                  value={body.description}
                  className="add-bill-description"
                  style={{ width: "100%", backgroundColor: COLORS.main }}
                  onChange={e =>
                    setBody({ ...body, description: e.target.value })
                  }
                />
              </View>
              <View />
              <View
                style={[
                  styles.inputContainer,
                  {
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "row"
                  }
                ]}
              >
                <View style={{ width: "48%" }}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.labelInput}>
                      {t("cash.new.budgetAllocation")}
                    </Text>
                    <View style={[styles.card, { marginTop: 10 }]}>
                      <View style={styles.dateContainer}>
                        <select
                          style={{
                            color: "#666666",
                            fontWeight: "bold",
                            width: "90%",
                            borderWidth: 0,
                            backgroundColor: "#fffff7",
                            fontSize: 12,
                            fontFamily: "Poppins"
                          }}
                          onChange={(e: any) =>
                            setBody({ ...body, budget_id: e.target.value })
                          }
                        >
                          <option selected={!body.budget_id} hidden>
                            {t("cash.new.selectBudget")}
                          </option>
                          {budgetType.map(
                            (budgetTypeData: any, index: number) => (
                              <option
                                key={index}
                                selected={budgetTypeData.id === body.budget_id}
                                value={budgetTypeData.id}
                              >
                                {budgetTypeData.attributes.title}
                              </option>
                            )
                          )}
                        </select>
                      </View>
                    </View>
                    {isError && !body.category_id ? (
                      <Text style={styles.validateText}>
                        {t("cash.new.msg.req")}
                      </Text>
                    ) : null}
                  </View>
                </View>
                <View
                  style={[
                    styles.currencyContainer,
                    { width: "48%", marginTop: -20 }
                  ]}
                >
                  <View style={{ width: "30%" }}>
                    <Text style={styles.labelInput}>
                      {t("cash.new.amount")}
                    </Text>
                    <select
                      style={{ color: "#666666", fontWeight: "bold" }}
                      className="container-content-input"
                      onChange={(e: any) =>
                        setBody({
                          ...body,
                          currency_code: e.target.value,
                          currency_id: e.target.value
                        })
                      }
                    >
                      {currencies.map((currency: any, index: number) => (
                        <option key={index} value={currency.attributes.real_id}>
                          {currency.attributes.title}
                        </option>
                      ))}
                    </select>
                  </View>
                  <View style={{ width: "68%" }}>
                    <Text style={styles.labelInput}> </Text>
                    <View style={[{ justifyContent: "center" }]}>
                      <TextInput
                        keyboardType="numeric"
                        value={body.amount}
                        onChange={(e: any) =>
                          setBody({ ...body, amount: e.target.value })
                        }
                        style={{
                          height: 40,
                          width: "100%",
                          paddingHorizontal: 20,
                          textAlign: "right",
                          borderBottomWidth: 1,
                          borderBottomColor: "#666666"
                        }}
                      />
                    </View>
                    {isError && Number(body.amount) <= 0 ? (
                      <Text style={styles.validateText}>
                        {t("cash.new.msg.cannotLowZero")}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </View>
              <View style={[styles.inputContainer, { marginBottom: 150 }]}>
                <Text style={styles.labelInput}>{t("cash.new.receipt")}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  {fileAttachment ? (
                    <TouchableOpacity
                      style={[
                        styles.fileAttachment,
                        styles.card,
                        {
                          width: "48%",
                          height: 28,
                          borderRadius: 100,
                          alignItems: "center",
                          justifyContent: "center"
                        }
                      ]}
                      onPress={() => window.open(fileAttachment)}
                    >
                      <View
                        style={{
                          width: "90%",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <Image
                            source={ICONS.file}
                            style={{ height: 15, width: 15, marginRight: 6 }}
                          />
                          <View style={{ width: "85%" }}>
                            <Text
                              numberOfLines={1}
                              style={styles.attributeTask}
                            >
                              {t("cash.new.attachments")}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  ) : null}
                  {body.file ? (
                    <TouchableOpacity
                      style={[
                        styles.fileAttachment,
                        styles.card,
                        {
                          width: "48%",
                          height: 28,
                          borderRadius: 100,
                          alignItems: "center",
                          justifyContent: "center"
                        }
                      ]}
                      onPress={() => previewFile()}
                    >
                      <View style={{ width: "90%" }}>
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "85%" }}>
                            <Text
                              numberOfLines={1}
                              style={styles.attributeTask}
                            >
                              {body.file.name}
                            </Text>
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              setBody({
                                ...body,
                                file: null
                              });
                            }}
                            style={{
                              width: "15%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Image
                              source={ICONS.iconDeleteFile}
                              resizeMode="contain"
                              style={{ width: 12, height: 12 }}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </TouchableOpacity>
                  ) : null}
                  <TouchableOpacity style={[styles.buttonUpload]}>
                    <label
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        fontSize: 11
                      }}
                    >
                      <input
                        type="file"
                        // eslint-disable-next-line max-len
                        accept="application/pdf, application/vnd.ms-excel, image/jpeg, image/png, image/jpg,.doc,.docx,
                      application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={(e: any) => {
                          setFileAttachment(null);
                          setBody({
                            ...body,
                            file: e.target.files[0]
                          });
                        }}
                        onClick={(event: any) => {
                          // eslint-disable-next-line no-param-reassign
                          event.target.value = null;
                        }}
                      />
                      {t("cash.new.attachment")}
                    </label>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
          <Fixed position="bottom">
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fffff7",
                marginRight: 10
              }}
            >
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    marginBottom: 24,
                    width: 150,
                    marginTop: 10,
                    height: 45,
                    marginRight: 20
                  }
                ]}
                onPress={() => handleSubmit()}
                disabled={isLoadingPostBill}
              >
                {isLoadingPostBill ? (
                  <ActivityIndicator />
                ) : (
                  <Text style={[styles.textButton, { fontSize: 12 }]}>
                    {t("cash.new.editBtn")}
                  </Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity onPress={() => handleCancel()}>
                {!isLoadingPostBill ? (
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      marginBottom: 24,
                      fontFamily: "Poppins",
                      marginRight: 20
                    }}
                  >
                    {t("cash.new.cancel")}
                  </Text>
                ) : null}
              </TouchableOpacity>
            </View>
          </Fixed>
        </View>
      </View>
    </View>
  );
};
export default EditBill;
