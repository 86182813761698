import React from "react";

import { Description } from "../components";
import { IMAGES } from "../../../configs";

const Second = () => (
  <>
    <Description
      image={IMAGES.introSecond}
      title="Start your trip now!"
      desc="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh"
      back
    />
  </>
);

export default Second;
