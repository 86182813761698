import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  listWrapper: {
    width: "100%",
    minHeight: "50vh"
  },
  scrollView: {
    minHeight: "70vh"
  },
  listItem: {
    flexDirection: "row",
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowColor: COLORS.gainsboro,
    elevation: 2
  },
  listItemImage: {
    flex: 1,
    resizeMode: "stretch"
  },
  listItemTextTitle: {
    fontSize: 14,
    color: "#3f7790",
    fontWeight: "bold",
    marginBottom: 7,
    fontFamily: "Poppins"
  },
  listItemTextSubtitle: {
    color: COLORS.greyMedium,
    fontSize: 11,
    fontFamily: "Poppins"
  },
  wrapTitle: {
    paddingRight: 20,
    paddingLeft: 20,
    marginTop: 50,
    width: "55%",
    justifyContent: "space-between",
    paddingBottom: 18
  },
  detailText: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: COLORS.greyMedium
  },
  detailBubble: {
    marginRight: 4,
    width: 23,
    height: 17,
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center"
  },
  detailBubleText: {
    color: COLORS.white,
    fontSize: 11,
    fontWeight: "bold",
    fontFamily: "Poppins"
  }
});

export default styles;
