import React from "react";
import { View } from "react-native";

import styles from "./styles";

interface Props {
  active: number;
  total: number;
}

const RoundBullet = ({ total, active }: Props) => {
  const length = [...Array(total)];
  return (
    <View style={styles.groupRoundBullet}>
      {length.map((item, index) => (
        <View
          key={index}
          style={
            index === active ? styles.roundBulletActive : styles.roundBullet
          }
        />
      ))}
    </View>
  );
};

export default RoundBullet;
