/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, TextInput, TouchableOpacity, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import styles from "./styles";
import "./styles.css";
import {
  getParticipants,
  getPopulateChildProject,
  getPopulateProject,
  postTaskRequest,
  removePopulateChildProject,
  resetParticipants,
  saveAddParticipants
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import {
  AutoComplete,
  Button,
  Card,
  Datepicker,
  FileUpload,
  Header,
  Message,
  Modal,
  Space,
  TextArea,
  Touchable,
  Upload
} from "../../../components";
import {
  AddParticipants,
  ModalConfirmForm
} from "../../../components/ModalComponents";
import { IMAGES } from "../../../configs";
import { copyWritings, getPolicies, permissionPage } from "../../../utils";

const NewRequestTask = () => {
  const dispatch = useDispatch();
  const { sprintf } = require("sprintf-js");
  const history = useHistory();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue
  } = useForm();

  const { newTripState, taskState } = useSelector(
    (state: Reducers) => ({
      newTripState: state.newTrip,
      taskState: state.task
    }),
    shallowEqual
  );

  const permission = {
    otherList: permissionPage("task-others-list")
  };
  const policy = {
    min: getPolicies("task-min-date"),
    max: getPolicies("task-max-date"),
    minAssign: getPolicies("task-assign-min-date"),
    maxAssign: getPolicies("task-assign-max-date")
  };

  const initialBody = {
    recurring: false,
    recurring_period: "",
    recurring_time: "00:00"
  };

  const [body] = useState(initialBody);
  const [file, setFile]: any = useState([]);

  const [modal, setModal] = useState(false);
  const typeDateTime: any = [{ date: "" }];
  const [dateStart, setDateStart] = useState(typeDateTime);
  const [dateEnd, setDateEnd] = useState(typeDateTime);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);

  const [projectChild, setProjectChild] = useState<string[]>([]);

  const policyDate = {
    minDate: moment().add(policy.min, "d").format("YYYY-MM-DD"),
    maxDate: moment().add(policy.max, "d").format("YYYY-MM-DD"),
    minAssignDate: moment().add(policy.minAssign, "d").format("YYYY-MM-DD"),
    maxAssignDate: moment().add(policy.maxAssign, "d").format("YYYY-MM-DD")
  };

  const handleMessage = useCallback(() => {
    if (
      policyDate.minAssignDate > getValues("started_at") &&
      taskState.saveParticipanList.length > 0
    ) {
      return "min-assign";
    }
    if (
      policyDate.maxAssignDate < getValues("due_at") &&
      taskState.saveParticipanList.length > 0
    ) {
      return "max-assign";
    }
    if (
      policyDate.minDate > getValues("started_at") &&
      taskState.saveParticipanList.length === 0
    ) {
      return "min";
    }
    if (
      policyDate.maxDate < getValues("due_at") &&
      taskState.saveParticipanList.length === 0
    ) {
      return "max";
    }
    return false;
  }, [taskState.saveParticipanList]);

  useEffect(() => {
    dispatch(getPopulateProject({ show_top_parent: true }));
    dispatch(resetParticipants());
  }, [dispatch]);

  const _uploadFile = (e: any) => {
    const { files } = e.target;
    Array.from(files).map((item: any) => {
      // eslint-disable-next-line no-param-reassign
      item.id = Math.random().toString(36).substring(7);
      const result = file.findIndex((ed: any) => ed.name === item.name);
      if (result < 0) {
        setFile((currentArray: any) => [...currentArray, item]);
      } else {
        toast.error("Cannot insert file with duplicate name!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "request-toast",
          closeButton: false,
          draggable: false
        });
      }
      return true;
    });
  };

  const deleteFile = (id: any) => {
    const newFile = file.filter((el: any) => el.id !== id);
    setFile(newFile);
  };

  const _getIdParticipant = () => {
    const result = [] as any;
    if (taskState.saveParticipanList.length > 0) {
      taskState.saveParticipanList.map(el => result.push(el.id));
    }
    return result.join(",");
  };

  const _onConfirmSubmitForm = (data: any) => {
    if (_getIdParticipant().length === 0) {
      const message = t("task.assign.chooseParticipantReq");
      toast.error(`${message}`, {
        position: "bottom-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "request-toast",
        closeButton: false,
        draggable: false
      });
    } else {
      const form = {
        ...data,
        files: file,
        participants: _getIdParticipant()
      };

      let tempData: any = [];
      Object.keys(form).map((key: any) => {
        if (form[key]) {
          tempData = [...tempData, { title: key, value: form[key] }];
        }
        return true;
      });

      let files: any = [];
      if (file.length > 0) {
        file.map((e: any) => {
          files = [...files, e.name];
          return true;
        });
      }

      let projecName = "";

      if (projectChild.length) {
        const [project_id] = projectChild.slice(-1);
        form.project_id = project_id;
        projecName =
          newTripState.listPopulateChildProject.find(sub => {
            return sub.find((v: any) => {
              return v.value === form.project_id;
            });
          })?.[0]?.label || "";
      } else {
        projecName =
          newTripState.listPopulateProject.length > 0 &&
          newTripState.listPopulateProject.find(
            e => e.value === getValues("project_id")
          ).label;
      }

      let participants: any = [];
      taskState.saveParticipanList.map(e => {
        participants = [...participants, e.name];
        return true;
      });

      const result: any = [
        { title: t("task.assign.subject"), value: getValues("title") || "-" },
        { title: t("task.card.project"), value: projecName || "-" },
        {
          title: t("task.detail.participants"),
          value: participants.length > 0 ? participants : "-"
        },
        {
          title: t("task.assign.startingDate"),
          value: moment(getValues("started_at")).format("DD MMM YYYY") || "-"
        },
        {
          title: t("task.assign.expectedFinishDate"),
          value: moment(getValues("due_at")).format("DD MMM YYYY") || "-"
        },
        {
          title: t("task.assign.desc"),
          value: getValues("description") || "-"
        },
        {
          title: t("task.assign.attachment"),
          value: files.length > 0 ? files : "-"
        }
      ];

      if (result) {
        setDataConfirmSubmit(result);
        setModalSubmitForm(true);
      }
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file,
      participants: _getIdParticipant(),
      assigned: 1,
      client_timezone: "Asia/Jakarta",
      finished_at: "",
      repeat_at: 1
    };

    if (projectChild.length) {
      const [project_id] = projectChild.slice(-1);
      form.project_id = project_id;
    }

    dispatch(
      postTaskRequest(form, (e: { success: boolean; message: string }) => {
        if (e.success === true) {
          toast.success(t("task.assign.dataStored"), {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "request-toast",
            closeButton: false,
            draggable: false
          });
          dispatch(resetParticipants());
          setTimeout(() => {
            if (permission.otherList) {
              history.push("/task/assigned");
            } else {
              history.push("/task");
            }
          }, 3000);
        } else if (e.success === false) {
          toast.error(`${e.message}`, {
            position: "bottom-center",
            autoClose: 3500,
            hideProgressBar: true,
            transition: Slide,
            className: "request-toast",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _validationDueDate = () => {
    if (getValues("due_at") === "") {
      const dt = [{ date: moment(watch("started_at")).format("YYYY-MM-DD") }];
      setDateEnd(dt);
      setValue("due_at", moment(watch("started_at")).format("YYYY-MM-DD"));
    }
  };

  const _goToPreview = (itemFile: any) => {
    const go = URL.createObjectURL(itemFile);
    window.open(go, "_blank");
  };

  const _onProjectChange = (id: string, index: number) => {
    dispatch(getPopulateChildProject({ parent_id: id }, index));
  };

  const _getValuetOption = useCallback((data, id) => {
    if (id) {
      return { label: data.find((e: any) => e.value === id)?.label || "" };
    }
    return "";
  }, []);

  return (
    <View>
      <ToastContainer limit={1} />
      <Header
        title={String(sprintf(t("task.requestTask"), copyWritings("task")))}
        goBack={() => {
          history.goBack();
          dispatch(resetParticipants());
        }}
      />
      <View style={styles.container}>
        {handleMessage() && (
          <View style={{ marginTop: 16 }}>
            <Message
              message={
                handleMessage() === "min-assign"
                  ? `Your start date for this task is currently violated our minimum date policy that your company set, ${policy.minAssign} day(s) from current date.`
                  : handleMessage() === "max-assign"
                  ? `Your start date for this task is currently violated our maximum date policy that your company set, ${policy.maxAssign} day(s) from current date.`
                  : handleMessage() === "min"
                  ? `Your start date for this task is currently violated our minimum date policy that your company set, ${policy.min} day(s) from current date.`
                  : handleMessage() === "max"
                  ? `Your start date for this task is currently violated our maximum date policy that your company set, ${policy.max} day(s) from current date.`
                  : ""
              }
            />
          </View>
        )}
        <View style={[styles.wrapForm, { marginTop: 12 }]}>
          <View style={styles.wrapPersonList}>
            {taskState.saveParticipanList.length > 0 &&
              taskState.saveParticipanList.map((item, index) => (
                <View key={index} style={{ width: "48%", marginBottom: 8 }}>
                  <Card cardStyle={{ borderRadius: 40 }}>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image
                        source={
                          (item && item.avatar && item.avatar.url) ||
                          IMAGES.avatar
                        }
                        style={styles.imageStyle}
                      />
                      <View style={{ marginLeft: 4, width: "65%" }}>
                        <Text
                          style={[styles.textStyle, { fontWeight: "bold" }]}
                          numberOfLines={1}
                        >
                          {item.name}
                        </Text>
                        <Text style={styles.textStyle} numberOfLines={1}>
                          {item.position_name}
                        </Text>
                      </View>
                    </View>
                  </Card>
                </View>
              ))}
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => {
                dispatch(getParticipants());
                setTimeout(() => {
                  setModal(true);
                }, 70);
              }}
              style={styles.buttonAddParticipant}
            >
              <Text style={styles.buttonTextParticipant}>
                {taskState.saveParticipanList.length === 0
                  ? t("task.assign.addParticipant")
                  : t("task.assign.changeParticipant")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.wrapForm}>
          <Text style={styles.label}>{t("task.assign.subject")}</Text>
          <Controller
            control={control}
            render={({ onChange, value }) => (
              <TextInput
                style={[styles.input, styles.inputLine]}
                onChangeText={text => onChange(text)}
                value={value}
              />
            )}
            name="title"
            rules={{ required: true }}
            defaultValue=""
          />
          {errors.title && (
            <Text style={styles.errorMessages}>
              {t("task.assign.subjectRequired")}
            </Text>
          )}
        </View>
        <View style={styles.wrapForm}>
          <Text style={styles.label}>{t("task.card.project")}</Text>
          <Space height={4} />
          <Controller
            control={control}
            render={({ onChange }) => (
              <AutoComplete
                placeHolder={t("task.assign.chooseProject")}
                option={newTripState.listPopulateProject}
                onValueChange={e => {
                  _onProjectChange(e.value, 0);
                  setProjectChild([]);
                  onChange(e.value);
                }}
                type="card"
                isLoading={newTripState.isLoadingPopulateProject}
              />
            )}
            name="project_id"
            rules={{ required: true }}
            defaultValue=""
          />
          {errors.project_id && (
            <Text style={[styles.errorMessages, { marginLeft: 8 }]}>
              {t("task.assign.projectRequired")}
            </Text>
          )}
        </View>
        {newTripState.listPopulateChildProject.map((item, i) => (
          <View key={`childProject-${i}`}>
            <View
              style={[
                styles.wrapForm,
                styles.wrapRow,
                { alignItems: "center" }
              ]}
            >
              <View style={{ flex: 1 }}>
                <AutoComplete
                  placeHolder={t("task.assign.chooseProject")}
                  value={_getValuetOption(item, projectChild[i])}
                  option={item}
                  onValueChange={e => {
                    _onProjectChange(e.value, i + 1);
                    const projectChildTmp = projectChild.map(v => v);
                    projectChildTmp[i] = e.value;
                    setProjectChild(projectChildTmp);
                  }}
                  type="card"
                  isLoading={newTripState.isLoadingPopulateProject}
                />
              </View>

              <TouchableOpacity
                onPress={() => {
                  dispatch(removePopulateChildProject(i));
                  const projectChildTmp = projectChild.filter(
                    (_, index) => index < i
                  );
                  setProjectChild(projectChildTmp);
                }}
                style={[
                  {
                    height: 25,
                    width: 25,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 0
                  }
                ]}
              >
                <Image
                  source={IMAGES.trashBin}
                  style={{ width: 13, height: 16 }}
                />
              </TouchableOpacity>
            </View>
          </View>
        ))}
        <View style={[styles.wrapForm, styles.wrapRow]}>
          <View style={{ width: "48%" }}>
            <Text style={styles.label}>
              {t("task.assign.expectedFinishDate")}
            </Text>
            <Controller
              control={control}
              render={({ onChange }) => (
                <Datepicker
                  validate
                  onChange={e => {
                    setDateEnd(e);
                    onChange(e[0].date);
                  }}
                  value={dateEnd}
                  minDate={dateStart[0].date || ""}
                >
                  <Card cardStyle={styles.cardDate}>
                    {getValues("due_at") ? (
                      <Text style={styles.input} numberOfLines={1}>
                        <Text style={{ fontWeight: "normal" }}>
                          {`${moment(dateEnd[0].date).format("ddd")} `}
                        </Text>
                        {moment(dateEnd[0].date).format("DD MMM YYYY")}
                      </Text>
                    ) : (
                      <Text style={styles.input} numberOfLines={1}>
                        {t("task.assign.selectDate")}
                      </Text>
                    )}
                  </Card>
                </Datepicker>
              )}
              name="due_at"
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.due_at && (
              <Text style={styles.errorMessages}>
                {t("task.assign.expectedFinishDateReq")}
              </Text>
            )}
          </View>
          <View style={{ width: "48%" }}>
            <Text style={styles.label}>{t("task.assign.startingDate")}</Text>
            <Controller
              control={control}
              render={({ onChange }) => (
                <Datepicker
                  validate
                  onChange={e => {
                    setDateStart(e);
                    onChange(e[0].date);
                    _validationDueDate();
                  }}
                  value={dateStart}
                  maxDate={dateEnd[0].date || ""}
                >
                  <Card cardStyle={styles.cardDate}>
                    <Text style={styles.input} numberOfLines={1}>
                      <Text style={{ fontWeight: "normal" }}>
                        {getValues("started_at")
                          ? `${moment(getValues("started_at")).format("ddd")} `
                          : `${moment().format("ddd")} `}
                      </Text>
                      {getValues("started_at")
                        ? moment(getValues("started_at")).format("DD MMM YYYY")
                        : moment().format("DD MMM YYYY")}
                    </Text>
                  </Card>
                </Datepicker>
              )}
              name="started_at"
              rules={{ required: true }}
              defaultValue={moment().format("YYYY-MM-DD")}
            />
            {errors.started_at && (
              <Text style={styles.errorMessages}>
                {t("task.assign.startingDateReq")}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.wrapForm}>
          <Text style={styles.label}>{t("task.assign.desc")}</Text>
          <Controller
            control={control}
            render={({ onChange, value }) => (
              <View style={styles.textArea}>
                <TextArea
                  onChange={e => onChange(e.target.value)}
                  value={value}
                  withPadding
                  bold
                />
              </View>
            )}
            name="description"
            rules={{ required: true }}
            defaultValue=""
          />
          {errors.description && (
            <Text style={styles.errorMessages}>{t("task.assign.descReq")}</Text>
          )}
        </View>
        <View style={[styles.wrapForm, styles.wrapRow]}>
          <View style={{ width: "48%" }}>
            <View
              style={[
                styles.wrapForm,
                {
                  marginTop: 24,
                  width: "100%",
                  alignSelf: "flex-start"
                }
              ]}
            >
              <View style={{ marginBottom: 12 }}>
                {file.length > 0 &&
                  file.map((el: any, i: number) => {
                    return (
                      <Touchable onPress={() => _goToPreview(el)} key={i}>
                        <FileUpload
                          name={el.name}
                          style={{
                            marginTop: 6,
                            marginBottom: 6,
                            marginRight: 8,
                            width: "80%"
                          }}
                          deleteFile={e => {
                            e.stopPropagation();
                            deleteFile(el.id);
                          }}
                        />
                      </Touchable>
                    );
                  })}
              </View>
              <Upload
                multiple
                style={{
                  textAlign: "center",
                  padding: 0,
                  width: "100%",
                  alignItems: "center",
                  paddingBottom: 5,
                  paddingTop: 5
                }}
                onChange={(el: any) => _uploadFile(el)}
              />
            </View>
          </View>
        </View>

        <View
          style={{
            alignItems: "center",
            marginTop: 67,
            marginBottom: 74
          }}
        >
          <Button
            title={String(t("task.assign.request"))}
            onPress={handleSubmit(_onConfirmSubmitForm)}
            isLoading={taskState.isLoadingPostTaskRequest}
          />
        </View>
      </View>
      <Modal isVisible={modal} backdropColor="rgba(255,255,255, 0.9)">
        <AddParticipants
          isRequest
          onPress={() => {
            setModal(false);
            dispatch(saveAddParticipants());
          }}
          onCancel={() => setModal(false)}
        />
      </Modal>
      <Modal isVisible={modalSubmitForm} backdropColor="rgba(255,255,255, 0.9)">
        <ModalConfirmForm
          data={dataConfirmSubmit}
          onPress={handleSubmit(_onSave)}
          onCancel={() => setModalSubmitForm(false)}
          isLoading={taskState.isLoadingPostTaskRequest}
        />
      </Modal>
    </View>
  );
};

export default NewRequestTask;
