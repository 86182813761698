/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import moment from "moment";
import {
  Button,
  Card,
  FileUpload,
  Header,
  Message,
  Select,
  Space,
  TextArea,
  Touchable,
  Upload,
  WrapContent
} from "../../../../components";

import "./styles.css";
import styles from "./styles";
import {
  getBudgetRemaining,
  getBudgetType,
  getDetailBills,
  getPopulate,
  postBillTrip
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { copyWritings, getPolicies, maskedMoney } from "../../../../utils";

const NewTripBill = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const params = history.location.state as any;
  const initialPolicy = getPolicies("expense-trip-ca-receipt");

  const copTeks = {
    expense: copyWritings("expense"),
    policy: copyWritings("policy"),
    finance_category: copyWritings("finance_category")
  };

  const {
    newExpenseState,
    expenseState,
    expenseTripState,
    billState
  } = useSelector(
    (state: Reducers) => ({
      newExpenseState: state.newExpense,
      expenseState: state.expense,
      expenseTripState: state.newExpenseTrip,
      billState: state.cashAdvanceBill
    }),
    shallowEqual
  );

  const [isError, setIsError] = useState(false);
  const [policy, setPolicy] = useState(initialPolicy);
  const [form, setForm]: any = useState({
    trip_id: params.trip_id,
    invoice_id: params.invoice_id,
    expense_id: params.item_id,
    budget_id: "",
    date_now: true,
    date: "",
    date_d: "",
    date_t: "",
    category_id: "",
    currency_code: "IDR",
    title: "",
    description: "",
    file: null,
    quantity: 1,
    amount: "",
    billable: false,
    currency_id: "",
    client_timezone: "Asia/Jakarta"
  });

  useEffect(() => {
    dispatch(getPopulate());
    if (params.project_id) {
      dispatch(getBudgetType(params.project_id));
      dispatch(getBudgetRemaining(params.project_id));
    }
    if (params.item_id) {
      dispatch(getDetailBills(params.item_id));
    }
  }, [dispatch, params.item_id, params.project_id]);

  useEffect(() => {
    if (form.file || form.file !== null) {
      setPolicy(false);
    } else {
      setPolicy(initialPolicy);
    }
  }, [form.file]);

  useEffect(() => {
    if (params.item_id) {
      const bills = billState.loadDataDetailBills;
      setForm({
        ...form,
        date_now: false,
        title: bills && bills.title,
        description: bills && bills.description,
        amount: bills && bills.amount.split(".")[0],
        category_id: bills && bills.category.id,
        budget_id:
          bills && bills.budget && bills.budget.budget && bills.budget.budget.id
            ? bills.budget.budget.id
            : "",
        date_t: bills && moment(bills.date).format("HH:mm"),
        date_d: bills && moment(bills.date).format("YYYY-MM-DD"),
        file: params.file && JSON.parse(params.file)
      });
    }
  }, [params.item_id, billState.loadDataDetailBills]);

  const populateExpenseType = [
    {
      value: "",
      label: sprintf(t("cash.new.select"), copTeks.finance_category)
    },
    ...newExpenseState.listPopulate
  ];

  const _populateBudget = () => {
    let result: any = [];
    if (expenseState.budgetType.length > 0) {
      expenseState.budgetType.map((e: any) => {
        result = [...result, { value: e.id, label: e.attributes.title }];
      });
    } else {
      expenseState.budgetRemaining.map((e: any) => {
        result = [...result, { value: e.id, label: e.attributes.title }];
      });
    }
    return result;
  };

  const _setForm = useCallback(
    (
      formName:
        | "title"
        | "description"
        | "amount"
        | "budget_id"
        | "category_id"
        | "date_d"
        | "date_t"
        | "file",
      formValue: any
    ) => {
      setForm({ ...form, [formName]: formValue });
      if (isError) {
        setIsError(false);
      }
    },
    [form, isError]
  );

  const _renderHeaderName = () => {
    if (!params.permissionCreate) {
      return sprintf(t("cash.new.reportDetail"), copTeks.expense);
    }
    if (params.item_id) {
      return sprintf(t("cash.new.editReport"), copTeks.expense);
    }
    return sprintf(t("cash.new.addReport"), copTeks.expense);
  };

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (params) {
      status = params.status;
    }

    if (status === 1) {
      desc = t("cash.status.draft");
    } else if (status === 2) {
      desc = t("cash.status.needApproval");
    } else if (status === 3) {
      desc = t("cash.status.approved");
    } else if (status === 4) {
      desc = t("cash.status.revise");
    } else if (status === 5) {
      desc = t("cash.status.ongoing");
    } else if (status === 6) {
      desc = t("cash.status.finished");
    } else if (status === 7) {
      desc = t("cash.status.rejected");
    }

    return desc;
  };

  const _uploadFile = (e: any) => {
    const files = e.target.files[0];
    _setForm("file", files);
  };

  const _goToPreview = () => {
    if (form.file.title) {
      window.open(form.file.url, "_blank");
    } else {
      const go = URL.createObjectURL(form.file);
      window.open(go, "_blank");
    }
  };

  const _deleteFile = () => {
    setForm({ ...form, file: null });
  };

  const _renderAlertError = useCallback(
    (isSHow: boolean) =>
      isSHow && (
        <Text style={[styles.label, styles.labelError]}>
          {t("cash.new.msg.req")}
        </Text>
      ),
    []
  );

  const _onSubmit = () => {
    const body = {
      ...form,
      date_d: !form.date_now
        ? `${new Date(`${form.date_d} ${form.date_t || ""}`).toUTCString()}`
        : "",
      date_t: !form.date_now
        ? `${new Date(`${form.date_d} ${form.date_t || ""}`).toUTCString()}`
        : "",
      date: !form.date_now
        ? `${new Date(`${form.date_d} ${form.date_t || ""}`).toUTCString()}`
        : "",
      file: form.file && form.file.title ? "" : form.file || ""
    };

    let run = false;
    if (!form.date_now && form.date_d !== "") {
      run = true;
    } else if (form.date_now) {
      run = true;
    }

    if (
      form.title !== "" &&
      form.amount !== "" &&
      form.category_id !== "" &&
      form.budget_id !== "" &&
      run
    ) {
      dispatch(postBillTrip(body, () => history.goBack(), body.expense_id));
    } else {
      setIsError(true);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={_renderHeaderName()}
        label={_statusDesc()}
        textHeader={{ fontSize: 18 }}
      />
      <WrapContent isLoading={billState.isloadingGetBills}>
        <View style={styles.formWrap}>
          {params.permissionCreate && policy && (
            <View style={{ marginBottom: 16 }}>
              <Message
                message={sprintf(
                  t("cash.new.msg.policyNewBill"),
                  copTeks.policy
                )}
              />
            </View>
          )}
          <View style={styles.rowStyle}>
            <View style={{ width: "48%", marginBottom: 12 }}>
              <View
                style={[
                  styles.rowSelect,
                  isError && form.category_id === "" && styles.error,
                  !params.permissionCreate && { borderBottomWidth: 0 }
                ]}
              >
                <Text style={styles.label}>{copTeks.finance_category}</Text>
                {params.permissionCreate ? (
                  <Card cardStyle={styles.cardStyle}>
                    <Select
                      listValue={populateExpenseType}
                      onChange={e => _setForm("category_id", e.target.value)}
                      value={form.category_id}
                      style={
                        form.category_id
                          ? { opacity: 1, marginLeft: -4, marginTop: 4 }
                          : { opacity: 0.6, marginLeft: -4, marginTop: 4 }
                      }
                    />
                  </Card>
                ) : (
                  <Text style={styles.disableValueText}>
                    {populateExpenseType.find(e => e.value === form.category_id)
                      ?.label || "-"}
                  </Text>
                )}
              </View>
              <Text
                style={[
                  styles.label,
                  styles.selectError,
                  { textAlign: "center" }
                ]}
              >
                {isError && form.category_id === "" && "*field required"}
              </Text>
            </View>

            <View style={{ width: "48%", marginBottom: 12 }}>
              <View
                style={[
                  {
                    height: "100%",
                    justifyContent: "flex-end"
                  },
                  !params.permissionCreate && { borderBottomWidth: 0 }
                ]}
              >
                {params.permissionCreate ? (
                  <View
                    style={[
                      styles.wrapSwitch,
                      isError && form.category_id === "" && { marginBottom: 21 }
                    ]}
                  >
                    <Switch
                      onChange={() =>
                        setForm({ ...form, date_now: !form.date_now })
                      }
                      checked={form.date_now}
                      onColor="#ff7600"
                      onHandleColor="#fffff7"
                      handleDiameter={16}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={36}
                    />
                    <Text style={[styles.label, { marginLeft: 4 }]}>
                      {form.date_now
                        ? t("cash.new.today")
                        : t("cash.new.selectDate")}
                    </Text>
                  </View>
                ) : (
                  <View style={{ paddingLeft: 12 }}>
                    <Text style={styles.label}>{t("cash.new.date")}</Text>
                    <Text style={styles.disableValueText}>
                      <Text style={{ fontWeight: "normal" }}>
                        {moment(
                          billState.loadDataDetailBills &&
                            billState.loadDataDetailBills.date
                        ).format("ddd")}
                      </Text>
                      {` ${moment(
                        billState.loadDataDetailBills &&
                          billState.loadDataDetailBills.date
                      ).format("DD MMM YYYY")} `}
                      <Text style={{ fontWeight: "normal" }}>
                        {moment(
                          billState.loadDataDetailBills &&
                            billState.loadDataDetailBills.date
                        ).format("HH:mm")}
                      </Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>

          {params.permissionCreate && !form.date_now && (
            <View style={[styles.rowStyle, { marginTop: 4 }]}>
              <View style={{ width: "48%", marginBottom: 12 }}>
                <View style={[styles.rowSelect]}>
                  <Card cardStyle={styles.cardDate}>
                    <input
                      className={`wrap-input-bill ${
                        !form.date_d && "opacity-date"
                      }`}
                      type="date"
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        color: "#666666",
                        fontWeight: "bold",
                        borderRadius: 100,
                        borderBottomWidth: 0
                      }}
                      min={moment().format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      onChange={e => _setForm("date_d", e.target.value)}
                      value={form.date_d}
                    />
                  </Card>
                </View>
                <Text
                  style={[
                    styles.label,
                    styles.selectError,
                    { textAlign: "center" }
                  ]}
                >
                  {isError && form.date_d === "" && t("cash.new.msg.req")}
                </Text>
              </View>

              <View style={{ width: "48%", marginBottom: 12 }}>
                <View>
                  <Card cardStyle={[styles.cardDate, { marginLeft: 0 }]}>
                    <input
                      className="wrap-input-bill"
                      type="time"
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        color: "#666666",
                        fontWeight: "bold",
                        borderRadius: 100,
                        borderBottomWidth: 0
                      }}
                      onChange={e => _setForm("date_t", e.target.value)}
                      value={form.date_t}
                    />
                  </Card>
                </View>
              </View>
            </View>
          )}

          <View
            style={[
              styles.row,
              isError && form.title === "" && styles.error,
              !params.permissionCreate && { borderBottomWidth: 0 }
            ]}
          >
            <Text style={styles.label}>{t("cash.new.subject")}</Text>
            {params.permissionCreate ? (
              <TextInput
                style={styles.textInput}
                onChangeText={e => _setForm("title", e)}
                value={form.title}
              />
            ) : (
              <Text style={styles.disableValueText}>{form.title}</Text>
            )}
            {_renderAlertError(isError && form.title === "")}
          </View>

          <View
            style={[
              styles.row,
              !params.permissionCreate && { borderBottomWidth: 0 }
            ]}
          >
            <Text style={styles.label}>{t("cash.new.description")}</Text>
            {params.permissionCreate ? (
              <TextArea
                bold
                onChange={e => _setForm("description", e.target.value)}
                value={form.description}
                border={false}
              />
            ) : (
              <Text style={styles.disableValueText}>
                {form.description || "-"}
              </Text>
            )}
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ width: "48%", marginBottom: 12 }}>
              <View
                style={[
                  styles.rowSelect,
                  !params.permissionCreate && { borderBottomWidth: 0 }
                ]}
              >
                <Text style={styles.label}>
                  {t("cash.new.budgetAllocation")}
                </Text>
                {params.permissionCreate ? (
                  <>
                    <Card cardStyle={styles.cardStyle}>
                      <Select
                        listValue={_populateBudget()}
                        onChange={e => _setForm("budget_id", e.target.value)}
                        value={form.budget_id}
                        style={
                          form.budget_id
                            ? { opacity: 1, marginLeft: -4, marginTop: 4 }
                            : { opacity: 0.6, marginLeft: -4, marginTop: 4 }
                        }
                        emptyLabel={t("cash.new.selectBudget")}
                      />
                    </Card>
                    <Space height={20} />
                    {_renderAlertError(isError && form.budget_id === "")}
                  </>
                ) : (
                  <Text style={styles.disableValueText}>
                    {_populateBudget().find(
                      (e: any) => e.value === form.budget_id
                    )?.value
                      ? _populateBudget().find(
                          (e: any) => e.value === form.budget_id
                        )?.label
                      : "-"}
                  </Text>
                )}
              </View>
            </View>
            <View style={{ width: "48%", marginBottom: 12 }}>
              <View
                style={[
                  styles.row,
                  isError && form.amount === "" && styles.error,
                  !params.permissionCreate && { borderBottomWidth: 0 }
                ]}
              >
                <Text style={styles.label}>{t("cash.new.amount")}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <Text style={[styles.label, { fontSize: 14, width: "25%" }]}>
                    IDR
                  </Text>
                  {params.permissionCreate ? (
                    <TextInput
                      keyboardType="numeric"
                      style={[
                        styles.textInput,
                        { textAlign: "right", width: "75%" }
                      ]}
                      onChangeText={e => _setForm("amount", e)}
                      value={form.amount}
                    />
                  ) : (
                    <View
                      style={{
                        width: "70%",
                        alignItems: "flex-end"
                      }}
                    >
                      <Text style={styles.disableValueText}>
                        {maskedMoney(form.amount)}
                      </Text>
                    </View>
                  )}
                </View>
                {_renderAlertError(isError && form.amount === "")}
              </View>
            </View>
          </View>

          <View style={{ marginTop: 12 }}>
            <View style={{ marginBottom: 6 }}>
              {form.file && (
                <Touchable onPress={_goToPreview}>
                  <FileUpload
                    name={form.file.title ? form.file.title : form.file.name}
                    style={{
                      marginTop: 6,
                      marginBottom: 6,
                      marginRight: 8,
                      width: "40%"
                    }}
                    hideDelete={params.item_id}
                    deleteFile={e => {
                      e.stopPropagation();
                      _deleteFile();
                    }}
                  />
                </Touchable>
              )}
            </View>
            {params.permissionCreate && (
              <Upload
                style={{
                  textAlign: "center",
                  padding: 0,
                  width: "48%",
                  alignItems: "center",
                  paddingBottom: 6,
                  paddingTop: 6
                }}
                onChange={(e: any) => _uploadFile(e)}
              />
            )}
          </View>

          <View style={styles.saveBtn}>
            {params.permissionCreate && (
              <View style={styles.wrapSaveBtn}>
                <Button
                  title={`${
                    params.itemId ? t("cash.new.save") : t("cash.new.add")
                  }`}
                  isLoading={expenseTripState.isLoadingPostBillTrip}
                  style={{ paddingHorizontal: 10 }}
                  onPress={_onSubmit}
                />
              </View>
            )}
          </View>
        </View>
      </WrapContent>
    </View>
  );
};

export default NewTripBill;
