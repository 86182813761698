import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  assignDetail: {
    marginLeft: 21,
    marginEnd: 1000,
    marginVertical: 26,
    width: "100%"
  },
  title: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11,
    width: "30%"
  },
  textnotes: {
    color: "#999999",
    fontFamily: "Poppins",
    fontSize: 11,
    padding: 10
  },
  column: {
    width: "70%"
  },
  desc: {
    color: "#666666",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 11
  },
  typeTrip: {
    color: "#666666",
    fontFamily: "Poppins",
    fontSize: 11
  },
  button: {
    fontFamily: "Poppins",
    color: "#ed5a28",
    fontSize: 18,
    fontWeight: "bold"
  },
  rowDesc: {
    marginBottom: 14
  },
  attachment: {
    marginBottom: 10,
    marginLeft: 10,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1
  },
  statusText: {
    alignSelf: "center",
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#999999"
  },
  statusDesc: {
    fontWeight: "bold",
    marginTop: 8,
    alignSelf: "center",
    fontFamily: "Poppins",
    fontSize: 18,
    color: "#666666"
  }
});

export default styles;
