import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

interface Props {
  children: ReactNode;
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});

const Container = ({ children }: Props) => (
  <View style={styles.container}>{children}</View>
);

export default Container;
