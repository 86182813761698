const colors = {
  main: "#fffff7",
  grey: "#666666",
  greyLight: "#999999",
  greyMedium: "#707070",
  danger: "rgba(255, 56, 20, 0.9)",
  white: "#ffffff",
  green: "#50b1b1",
  orange: "#ED5A28",
  blue: "#167DF0",
  black: "#000",
  yellow: "#F5AA3F",
  gainsboro: "#DDDDDD"
};

export default colors;
