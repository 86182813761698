import React, { memo } from "react";
import { ActivityIndicator, View } from "react-native";
import Select from "react-select";
import { COLORS } from "../../configs";

import styles from "./styles";

interface Props {
  placeHolder?: string;
  color?: any;
  style?: any;
  type?: "default" | "card";
  option: { value: string; label: string }[];
  onValueChange?: (text: any) => void;
  defaultValue?: any;
  isLoading?: boolean;
  disabled?: boolean;
  value?: any;
  fontSize?: number;
  additionalLabel?: string;
}
const AutoComplete = ({
  option,
  onValueChange,
  color,
  placeHolder,
  style,
  defaultValue,
  type,
  isLoading,
  disabled,
  value,
  fontSize,
  additionalLabel
}: Props) => (
  <View style={[type === "card" ? styles.card : styles.defaultSelect, style]}>
    <Select
      name="form-field-name"
      defaultInputValue={defaultValue}
      options={option}
      isDisabled={isLoading || disabled}
      placeholder={placeHolder}
      menuPortalTarget={document.body}
      value={value}
      getOptionLabel={opt =>
        `${opt.label} ${
          additionalLabel && opt[additionalLabel]
            ? `(${opt[additionalLabel]})`
            : ""
        }`
      }
      styles={{
        control: base => ({
          ...base,
          backgroundColor: "transparent",
          fontSize,
          fontFamily: "Poppins",
          fontWeight: "bold",
          zIndex: 99,
          border: "none",
          boxShadow: "none"
        }),
        option: () => {
          return {
            zIndex: 1,
            margin: 10,
            justifyContent: "",
            borderBottomColor: "#000",
            backgroundColor: "#fff",
            fontSize: 12,
            fontFamily: "Poppins",
            color
          };
        },
        valueContainer: () => {
          return {
            textOverflow: "ellipsis",
            overflow: "hidden"
          };
        }
      }}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: "orangered",
          neutral50: color,
          neutral80: color
        }
      })}
      onChange={onValueChange}
    />
    {isLoading && (
      <ActivityIndicator
        style={{ position: "absolute", top: 0, bottom: 0, right: 12 }}
        color="#344370"
      />
    )}
  </View>
);

AutoComplete.defaultProps = {
  color: COLORS.grey,
  placeHolder: "Please Select...",
  type: "default",
  isLoading: false,
  fontSize: 14
};

export default memo(AutoComplete);
