import {
  CHANGE_FORM_PAYMENT,
  CHANGE_VALUE_INVOICE_DETAIL_LIST,
  GET_CASH_DETAIL_SUCCESS,
  GET_DESC_BANK_ACCOUNT_ERROR,
  GET_DESC_BANK_ACCOUNT_PENDING,
  GET_DESC_BANK_ACCOUNT_SUCCESS,
  GET_DESC_USER_BANK_ACCOUNT_ERROR,
  GET_DESC_USER_BANK_ACCOUNT_PENDING,
  GET_DESC_USER_BANK_ACCOUNT_SUCCESS,
  GET_INVOICE_DETAIL_ERROR,
  GET_INVOICE_DETAIL_LIST_ERROR,
  GET_INVOICE_DETAIL_LIST_PENDING,
  GET_INVOICE_DETAIL_LIST_SUCCESS,
  GET_INVOICE_DETAIL_PENDING,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_LIST_ERROR,
  GET_INVOICE_LIST_PENDING,
  GET_INVOICE_LIST_SUCCESS,
  GET_ITEM_DETAIL_ERROR,
  GET_ITEM_DETAIL_PENDING,
  GET_ITEM_DETAIL_SUCCESS,
  GET_LIST_BANK_ACCOUNT_ERROR,
  GET_LIST_BANK_ACCOUNT_PENDING,
  GET_LIST_BANK_ACCOUNT_SUCCESS,
  GET_LIST_USER_BANK_ACCOUNT_ERROR,
  GET_LIST_USER_BANK_ACCOUNT_PENDING,
  GET_LIST_USER_BANK_ACCOUNT_SUCCESS,
  GET_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHOD_PENDING,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_VENDOR_CONTACT_ERROR,
  GET_VENDOR_CONTACT_PENDING,
  GET_VENDOR_CONTACT_SUCCESS,
  LOGOUT_SUCCESS,
  POST_INVOICE_PAY_ERROR,
  POST_INVOICE_PAY_PENDING,
  POST_INVOICE_PAY_SUCCESS,
  REMOVE_INVOICE_DETAIL_LIST
} from "../actions";
import { Action, InvoiceState } from "../types";

const initialState: InvoiceState = {
  invoiceDetail: null,
  isLoadingInvoiceDetail: false,
  listPaymentMethod: [],
  includedMethod: [],
  listBankAccount: null,
  isLoadingPaymentMethod: false,
  listUserBankAccounts: [],
  isLoadingUserBankAcount: false,
  descUserBankAccounts: null,
  isLoadingDescUserBankAcount: false,
  invoiceList: {
    isLoading: false,
    list: []
  },
  item: null,
  isLoadingItem: false,
  invoiceDetailList: { isLoading: false, list: [] },
  listBank: { isLoading: false },
  descBank: { isLoading: false },
  isLoading: false,
  isLoadingVendorContact: false,
  listVendorContact: []
};

let result: any;
let index: number;

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // post pay
    case POST_INVOICE_PAY_PENDING:
      return { ...state, isLoading: true };
    case POST_INVOICE_PAY_SUCCESS:
      return { ...state, isLoading: false };
    case POST_INVOICE_PAY_ERROR:
      return { ...state, isLoading: false };

    // invoice list
    case GET_INVOICE_LIST_PENDING:
      return {
        ...state,
        invoiceList: { ...state.invoiceList, isLoading: true }
      };
    case GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          isLoading: false,
          list: payload.data
        }
      };
    case GET_INVOICE_LIST_ERROR:
      return {
        ...state,
        invoiceList: { ...state.invoiceList, isLoading: false }
      };

    // invoice detail list
    case GET_INVOICE_DETAIL_LIST_PENDING:
      return {
        ...state,
        invoiceDetailList: { ...state.invoiceDetailList, isLoading: true }
      };
    case GET_INVOICE_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        invoiceDetailList: {
          ...state.invoiceDetailList,
          isLoading: false,
          list: payload.isFirst
            ? [payload.data]
            : [
                ...state.invoiceDetailList.list,
                {
                  ...payload.data,
                  data: {
                    ...payload.data.data,
                    attributes: {
                      ...payload.data.data.attributes,
                      total: payload.data.data.attributes.total_paid
                        ? String(
                            Number(payload.data.data.attributes.total) -
                              Number(payload.data.data.attributes.total_paid)
                          )
                        : payload.data.data.attributes.total
                    }
                  }
                }
              ]
        }
      };
    case GET_INVOICE_DETAIL_LIST_ERROR:
      return {
        ...state,
        invoiceDetailList: { ...state.invoiceDetailList, isLoading: false }
      };

    // invoice detail
    case GET_INVOICE_DETAIL_PENDING:
      return { ...state, isLoadingInvoiceDetail: true };
    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: payload.data,
        isLoadingInvoiceDetail: false
      };
    case GET_INVOICE_DETAIL_ERROR:
      return { ...state, isLoadingInvoiceDetail: false };

    case GET_ITEM_DETAIL_PENDING:
      return { ...state, isLoadingItem: true };
    case GET_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        item: payload.data,
        isLoadingItem: false
      };
    case GET_ITEM_DETAIL_ERROR:
      return { ...state, isLoadingItem: false };

    // payment methods
    case GET_PAYMENT_METHOD_PENDING:
      return { ...state, isLoadingPaymentMethod: true };
    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        listPaymentMethod: payload.data.data,
        includedMethod: payload.data.included,
        listBankAccount: payload.data.included.filter(
          (included: any) => included.type === "bank"
        ),
        isLoadingPaymentMethod: false
      };
    case GET_PAYMENT_METHOD_ERROR:
      return { ...state, isLoadingPaymentMethod: false };

    // list user bank account
    case GET_LIST_USER_BANK_ACCOUNT_PENDING:
      return { ...state, isLoadingUserBankAcount: true };
    case GET_LIST_USER_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        listUserBankAccounts: payload.data,
        isLoadingUserBankAcount: false
      };
    case GET_LIST_USER_BANK_ACCOUNT_ERROR:
      return { ...state, isLoadingUserBankAcount: false };

    // desc user bank account
    case GET_DESC_USER_BANK_ACCOUNT_PENDING:
      return { ...state, isLoadingDescUserBankAcount: true };
    case GET_DESC_USER_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        descUserBankAccounts: payload.data,
        isLoadingDescUserBankAcount: false
      };
    case GET_DESC_USER_BANK_ACCOUNT_ERROR:
      return { ...state, isLoadingDescUserBankAcount: false };

    // list bank
    case GET_LIST_BANK_ACCOUNT_PENDING:
      return { ...state, listBank: { ...state.listBank, isLoading: true } };
    case GET_LIST_BANK_ACCOUNT_SUCCESS:
      result = [...state.invoiceDetailList.list];
      result[result.findIndex((e: any) => e.data.id === payload.id)] = {
        ...result[result.findIndex((e: any) => e.data.id === payload.id)],
        listBank: payload.data,
        activeBank: payload.data[0].id
      };
      return {
        ...state,
        listBank: { ...state.listBank, isLoading: false },
        invoiceDetailList: { ...state.invoiceDetailList, list: result }
      };
    case GET_LIST_BANK_ACCOUNT_ERROR:
      return { ...state, listBank: { ...state.listBank, isLoading: false } };

    // desc bank
    case GET_DESC_BANK_ACCOUNT_PENDING:
      return { ...state, descBank: { ...state.descBank, isLoading: true } };
    case GET_DESC_BANK_ACCOUNT_SUCCESS:
      result = [...state.invoiceDetailList.list];
      result[result.findIndex((e: any) => e.data.id === payload.id)] = {
        ...result[result.findIndex((e: any) => e.data.id === payload.id)],
        descBank: payload.data
      };
      return {
        ...state,
        descBank: { ...state.descBank, isLoading: false },
        invoiceDetailList: { ...state.invoiceDetailList, list: result }
      };
    case GET_DESC_BANK_ACCOUNT_ERROR:
      return { ...state, descBank: { ...state.descBank, isLoading: false } };

    // remove invoice detail list
    case REMOVE_INVOICE_DETAIL_LIST:
      return {
        ...state,
        invoiceDetailList: {
          ...state.invoiceDetailList,
          list: state.invoiceDetailList.list.filter(
            (e, i) => i !== payload.index
          )
        }
      };

    // change value detail list
    case CHANGE_VALUE_INVOICE_DETAIL_LIST:
      result = [...state.invoiceDetailList.list];
      result[payload.index!].data.attributes.total = payload.data;
      return {
        ...state,
        invoiceDetailList: {
          ...state.invoiceDetailList,
          list: result
        }
      };

    // change desc fee
    case CHANGE_FORM_PAYMENT:
      result = [...state.invoiceDetailList.list];
      result[payload.index!][payload.data.formName] = payload.data.formValue;
      return {
        ...state,
        invoiceDetailList: {
          ...state.invoiceDetailList,
          list: result
        }
      };

    // reset
    case GET_CASH_DETAIL_SUCCESS:
      return {
        ...state,
        listPaymentMethod: initialState.listPaymentMethod,
        includedMethod: initialState.includedMethod,
        isLoadingPaymentMethod: initialState.isLoadingPaymentMethod,
        invoiceList: initialState.invoiceList,
        invoiceDetailList: initialState.invoiceDetailList,
        listBank: initialState.listBank,
        descBank: initialState.descBank,
        isLoading: initialState.isLoading
      };

    // vendor contact
    case GET_VENDOR_CONTACT_PENDING:
      return { ...state, isLoadingVendorContact: true };
    case GET_VENDOR_CONTACT_SUCCESS:
      index = state.invoiceDetailList.list.findIndex(
        e => e.data.id === payload.id
      );
      result = [...state.invoiceDetailList.list];
      result[index] = { ...result[index], contacts: payload.data };
      return {
        ...state,
        invoiceDetailList: { ...state.invoiceDetailList, list: result },
        isLoadingVendorContact: false
      };
    case GET_VENDOR_CONTACT_ERROR:
      return {
        ...state,
        isLoadingVendorContact: false
      };

    // logout
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
