import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20,
    marginTop: 26,
    marginBottom: 20
  },
  cardStyle: {
    flexDirection: "row",
    paddingVertical: 12,
    paddingLeft: 20,
    paddingRight: 10,
    marginVertical: 4,
    borderRadius: 26
  },
  textStyle: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: "#707070",
    alignSelf: "center"
  },
  iconStyle: {
    width: 25,
    height: 26,
    marginRight: 15,
    resizeMode: "contain",
    alignSelf: "center"
  }
});

export default styles;
