import { StyleSheet } from "react-native";

import { widthPercent } from "../../utils";
import { COLORS } from "../../configs";

const style = StyleSheet.create({
  wrapPage: {
    backgroundColor: COLORS.main,
    flex: 1
  },
  bottomNavWrapper: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  bottomNavItemWrapper: {
    flexDirection: "column",
    alignItems: "center"
  },
  bottomNavItem: {
    flex: 1,
    paddingVertical: 12,
    alignItems: "center",
    borderRadius: 50
  },
  bottomNavItemActive: {
    borderColor: "#000",
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  bottomNavItemIcon: {
    width: 25,
    height: 25
  },
  bottomNavItemName: {
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 3
  },
  header: {
    backgroundColor: "blue",
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    backgroundImage: "linear-gradient(#63a1a8, #344370)",
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  bubble: {
    width: widthPercent(95),
    height: widthPercent(95),
    borderRadius: widthPercent(80) / 2,
    borderBottomRightRadius: widthPercent(75),
    backgroundImage: "linear-gradient(to left, #faaf40, #f05a28 63%, #db3a26)",
    position: "absolute",
    top: -widthPercent(77),
    left: -widthPercent(5)
  },
  bubbleBottom: {
    width: widthPercent(80),
    height: widthPercent(80),
    borderRadius: widthPercent(80) / 2,
    backgroundImage: "linear-gradient(to left, #faaf40, #f05a28 63%, #db3a26)",
    position: "absolute",
    bottom: -widthPercent(68),
    right: -widthPercent(40) / 2
  },
  wrapButtonIcon: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingHorizontal: 20,
    marginTop: 55,
    alignItems: "center"
  },
  buttonIcon: {
    width: 24,
    height: 24,
    marginHorizontal: 5
  },
  icon: {
    width: "100%",
    height: "100%"
  },
  wrapProfile: {
    paddingHorizontal: 24,
    flexDirection: "row",
    marginBottom: 21
  },
  wrapImageProfile: {
    width: 84,
    height: 84,
    padding: 4,
    borderRadius: 84 / 2,
    backgroundColor: COLORS.main
  },
  imageProfile: {
    width: "100%",
    height: "100%",
    borderRadius: 84 / 2
  },
  wrapDescProfile: {
    marginLeft: 10,
    justifyContent: "center",
    flex: 1
  },
  wrapName: {
    flexDirection: "row"
  },
  textName: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 2
  },
  wrapType: {
    justifyContent: "center",
    paddingLeft: 10
  },
  textType: {
    fontFamily: "Poppins",
    fontSize: 6,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: 10,
    paddingHorizontal: 7,
    paddingVertical: 2,
    borderWidth: 1,
    borderColor: "#fff",
    width: "fit-content",
    marginBottom: 1,
    textTransform: "uppercase"
  },
  textTitle: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#fff"
  },
  wrapLoading: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 20
  },
  languageSwitcher: {
    marginRight: 2
  },
  languageSpacing: {
    margin: 0
  },
  languageActive: {
    textDecorationLine: "underline",
    fontWeight: "bold"
  },
  wrapButtonTranslate: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 80,
    marginBottom: 10
  },
  nonActiveLanguage: {
    fontSize: 16,
    color: "#ffff"
  }
});

export default style;
