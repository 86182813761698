import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  rectangular: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 30,
    flexDirection: "row",
    alignSelf: "center",
    overflow: "hidden",
    marginBottom: 25,
    width: 290
  },
  rectangularItem: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    width: 92
  },
  rectangularItemText: {
    fontFamily: "Poppins",
    color: "#707070",
    fontSize: 10
  },
  rectangularItemRed: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    backgroundColor: "#ed5a28",
    width: 198
  },
  rectangularItemTextRed: {
    fontFamily: "Poppins",
    color: "#fffff7",
    fontSize: 10
  },
  rectangularDisabled: {
    borderRadius: 30,
    flexDirection: "row",
    alignSelf: "center",
    overflow: "hidden",
    marginBottom: 25,
    width: 290,
    borderColor: "#dddddd",
    borderStyle: "solid",
    borderWidth: 1
  },

  rectangularItemDisabled: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    width: 92
  },
  rectangularItemTextDisabled: {
    fontFamily: "Poppins",
    color: "#707070",
    fontSize: 10
  },
  rectangularItemRedDisabled: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 8,
    backgroundColor: "#999999",
    width: 198
  },
  rectangularItemTextRedDisabled: {
    fontFamily: "Poppins",
    color: "#fffff7",
    fontSize: 10
  }
});

export default styles;
