import {
  STORE_EXPENSE_DATA_TEMP_NEW_BILL,
  STORE_LIST_BILL_NEW_BILL,
  STORE_TRIP_DATA_TEMP_NEW_BILL
} from "../actions";
import { Action, TempNewBill } from "../types";

const initialState: TempNewBill = {
  expenseDetail: null,
  tripDetail: null,
  listBill: []
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case STORE_TRIP_DATA_TEMP_NEW_BILL:
      return { ...state, tripDetail: payload };
    case STORE_EXPENSE_DATA_TEMP_NEW_BILL:
      return { ...state, expenseDetail: payload };
    case STORE_LIST_BILL_NEW_BILL:
      return { ...state, listBill: [...state.listBill, payload] };
    default:
      return state;
  }
};
