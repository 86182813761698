import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.main,
    flex: 1
  },
  body: {
    paddingHorizontal: 20,
    paddingVertical: 24
  },
  labelInput: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey,
    marginLeft: 12
  },
  row: {
    marginBottom: 12
  },
  input: {
    marginVertical: 0,
    paddingHorizontal: 12
  },
  inputStyle: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: COLORS.grey,
    textAlign: "left",
    outlineColor: "rgba(255,255,255,0)",
    marginBottom: 2
  },
  labelIdr: {
    fontFamily: "Poppins",
    fontSize: 18,
    color: COLORS.grey,
    marginRight: 12
  },
  wrapButton: {
    width: "100%",
    alignItems: "center",
    paddingVertical: 24
  },
  button: {
    width: 180,
    height: 54,
    borderRadius: 28,
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    justifyContent: "center",
    alignItems: "center"
  },
  textButton: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold",
    color: COLORS.white
  },
  bilable: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginLeft: 8
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    borderRadius: 20,
    width: 180,
    height: 28,
    justifyContent: "center",
    backgroundColor: COLORS.main
  }
});

export default styles;
