import { StyleSheet } from "react-native";

const orange = "#ed5a28";
const styles = StyleSheet.create({
  card: {
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginVertical: 10,
    marginHorizontal: 25
  },
  text: {
    color: "#999999",
    marginBottom: 5,
    fontFamily: "Poppins",
    fontSize: 11
  },
  textDesc: {
    color: "#666666",
    marginBottom: 5,
    fontFamily: "Poppins",
    fontSize: 11
  },
  star: {
    width: 9.5,
    height: 9.5,
    marginRight: 1
  },
  row: {
    paddingHorizontal: 21,
    width: "100%"
  },
  statusBox: {
    flexDirection: "row"
  },
  statusText: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 18,
    fontWeight: "bold"
  },
  orange: {
    fontFamily: "Poppins",
    color: orange,
    width: "50%"
  },
  costBox: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    width: "50%"
  },
  fab: {
    height: 87,
    width: 87
  },
  cardDescription: {
    marginTop: 9,
    paddingTop: 14,
    paddingBottom: 21,
    paddingHorizontal: 21,
    marginBottom: 15,
    marginHorizontal: 4
  },
  desciption: {
    flexDirection: "row"
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  },
  titleHotel: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  descriptionCity: {
    marginVertical: 0
  },
  descDate: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  marginVertical: {
    marginVertical: 16
  }
});

export default styles;
