import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_LIST_BOOKING_HOTEL_PENDING = "GET_LIST_BOOKING_HOTEL_PENDING";
export const GET_LIST_BOOKING_HOTEL_SUCCESS = "GET_LIST_BOOKING_HOTEL_SUCCESS";
export const GET_LIST_BOOKING_HOTEL_ERROR = "GET_LIST_BOOKING_HOTEL_ERROR";

export const POST_INVOICE_BOOKING_PENDING = "POST_INVOICE_BOOKING_PENDING";
export const POST_INVOICE_BOOKING_SUCCESS = "POST_INVOICE_BOOKING_SUCCESS";
export const POST_INVOICE_BOOKING_ERROR = "POST_INVOICE_BOOKING_ERROR";

export const PUT_INVOICE_BOOKING_PENDING = "PUT_INVOICE_BOOKING_PENDING";
export const PUT_INVOICE_BOOKING_SUCCESS = "PUT_INVOICE_BOOKING_SUCCESS";
export const PUT_INVOICE_BOOKING_ERROR = "PUT_INVOICE_BOOKING_ERROR";

export const GET_ITEM_DETAIL_PENDING = "GET_ITEM_DETAIL_PENDING";
export const GET_ITEM_DETAIL_SUCCESS = "GET_ITEM_DETAIL_SUCCESS";
export const GET_ITEM_DETAIL_ERROR = "GET_ITEM_DETAIL_ERROR";

export const GET_LIST_BOOKING_FLIGHT_PENDING =
  "GET_LIST_BOOKING_FLIGHT_PENDING";
export const GET_LIST_BOOKING_FLIGHT_SUCCESS =
  "GET_LIST_BOOKING_FLIGHT_SUCCESS";
export const GET_LIST_BOOKING_FLIGHT_ERROR = "GET_LIST_BOOKING_FLIGHT_ERROR";

export const DELETE_BOOKING_BY_ID_PENDING = "DELETE_BOOKING_BY_ID_PENDING";
export const DELETE_BOOKING_BY_ID_SUCCESS = "DELETE_BOOKING_BY_ID_SUCCESS";
export const DELETE_BOOKING_BY_ID_ERROR = "DELETE_BOOKING_BY_ID_ERROR";

export const DELETE_INVOICE_BOOKING_PENDING = "DELETE_INVOICE_BOOKING_PENDING";
export const DELETE_INVOICE_BOOKING_SUCCESS = "DELETE_INVOICE_BOOKING_SUCCESS";
export const DELETE_INVOICE_BOOKING_ERROR = "DELETE_INVOICE_BOOKING_ERROR";

export const GET_INVOICE_BOOKING_LIST_PENDING =
  "GET_INVOICE_BOOKING_LIST_PENDING";
export const GET_INVOICE_BOOKING_LIST_SUCCESS =
  "GET_INVOICE_BOOKING_LIST_SUCCESS";
export const GET_INVOICE_BOOKING_LIST_ERROR = "GET_INVOICE_BOOKING_LIST_ERROR";

export const getListBookingHotel = (idTrip: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LIST_BOOKING_HOTEL_PENDING });

    const res = await API.getBookingList({ idTrip, type: "hotel" }, auth.token);
    dispatch({
      type: GET_LIST_BOOKING_HOTEL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getListBookingHotel(idTrip))));
      } else {
        dispatch({
          type: GET_LIST_BOOKING_HOTEL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_LIST_BOOKING_HOTEL_ERROR });
  }
};

export const getInvoiceBookingList = (bookingId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_INVOICE_BOOKING_LIST_PENDING });

    const res = await API.getInvoiceBookingList(bookingId, auth.token);
    dispatch({
      type: GET_INVOICE_BOOKING_LIST_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getInvoiceBookingList(bookingId))));
      } else {
        dispatch({
          type: GET_INVOICE_BOOKING_LIST_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_INVOICE_BOOKING_LIST_ERROR });
  }
};

export const deleteInvoiceBooking = (
  bookingId: string,
  cb: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_INVOICE_BOOKING_PENDING });

    const res = await API.deleteCashItem(bookingId, auth.token);
    cb();
    dispatch({
      type: DELETE_INVOICE_BOOKING_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(deleteInvoiceBooking(bookingId, () => cb)))
        );
      } else {
        dispatch({
          type: DELETE_INVOICE_BOOKING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_INVOICE_BOOKING_ERROR });
  }
};

export const getItemDetail = (body: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_ITEM_DETAIL_PENDING });

    const res = await API.getItemDetail(body, auth.token);
    dispatch({
      type: GET_ITEM_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getItemDetail(body))));
      } else {
        dispatch({
          type: GET_ITEM_DETAIL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_LIST_BOOKING_HOTEL_ERROR });
  }
};

export const putInvoiceBooking = (
  body: any,
  itemId: string,
  cb: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_INVOICE_BOOKING_PENDING });

    const formData = new FormData();
    formData.append("amount", body.amount);
    formData.append("billable", body.billable);
    formData.append("booking_id", body.booking_id);
    formData.append("category_id", body.category_id);
    formData.append("client_timezone", body.client_timezone);
    formData.append("currency_code", body.currency_code);
    formData.append("description", body.description);
    if (body.files && body.files.length > 0) {
      formData.append("file", body.files[0]);
    }
    formData.append("quantity", body.quantity);
    formData.append("title", body.title);
    formData.append("invoice_id", body.invoice_id);
    formData.append("item_id", itemId);
    formData.append("_method", "PUT");

    const res = await API.putInvoiceBooking(formData, itemId, auth.token);
    dispatch({
      type: PUT_INVOICE_BOOKING_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(putInvoiceBooking(body, itemId, cb)))
        );
      } else {
        dispatch({
          type: PUT_INVOICE_BOOKING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_LIST_BOOKING_HOTEL_ERROR });
  }
};

export const postInvoiceBooking = (body: any, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_INVOICE_BOOKING_PENDING });

    const formData = new FormData();
    formData.append("amount", body.amount);
    formData.append("billable", body.billable);
    formData.append("booking_id", body.booking_id);
    formData.append("category_id", body.category_id);
    formData.append("client_timezone", body.client_timezone);
    formData.append("currency_code", body.currency_code);
    formData.append("description", body.description);
    if (body.files !== null) {
      formData.append("file", body.files[0]);
    }

    formData.append("quantity", body.quantity);
    formData.append("title", body.title);

    const res = await API.postInvoiceBooking(formData, auth.token);
    dispatch({
      type: POST_INVOICE_BOOKING_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postInvoiceBooking(body, cb))));
      } else {
        dispatch({
          type: POST_INVOICE_BOOKING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_LIST_BOOKING_HOTEL_ERROR });
  }
};

export const getListBookingFlight = (idTrip: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LIST_BOOKING_FLIGHT_PENDING });

    const res = await API.getBookingList(
      { idTrip, type: "flight" },
      auth.token
    );
    dispatch({
      type: GET_LIST_BOOKING_FLIGHT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getListBookingFlight(idTrip))));
      } else {
        dispatch({
          type: GET_LIST_BOOKING_FLIGHT_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_LIST_BOOKING_FLIGHT_ERROR });
  }
};

export const deleteBookingById = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_BOOKING_BY_ID_PENDING });

    await API.deleteBookingById(id, auth.token);
    dispatch({
      type: DELETE_BOOKING_BY_ID_SUCCESS,
      payload: { id }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteBookingById(id))));
      } else {
        dispatch({
          type: DELETE_BOOKING_BY_ID_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_BOOKING_BY_ID_ERROR });
  }
};
