/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import moment from "moment";

import styles from "./styles";
import "./styles.css";
import {
  getDetailTask,
  getEmployeeV2,
  getParticipants,
  getPopulateChildProject,
  getPopulateProject,
  postTask,
  putTaskEdit,
  removePopulateChildProject,
  resetParticipants,
  saveAddParticipants,
  setPopulateChildProject
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import {
  AutoComplete,
  Button,
  Card,
  Datepicker,
  FileUpload,
  Header,
  Message,
  Modal,
  Select,
  Space,
  TextArea,
  Touchable,
  Upload
} from "../../../components";
import {
  AddParticipants,
  Confirmation,
  ModalConfirmForm
} from "../../../components/ModalComponents";
import { API, COLORS, IMAGES } from "../../../configs";
import { copyWritings, getPolicies, permissionPage } from "../../../utils";

const NewAssignTask = () => {
  const { taskUuid, type, projectId } = useParams() as any;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const history = useHistory();

  const copTeks = {
    task: copyWritings("task")
  };

  const {
    control,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue
  } = useForm();

  const { newTripState, taskState, taskDetail, token } = useSelector(
    (state: Reducers) => ({
      newTripState: state.newTrip,
      taskState: state.task,
      taskDetail: state.task.detailTaskData,
      token: state.auth.token
    }),
    shallowEqual
  );
  const [body, setBody] = useState({
    recurring: false,
    recurring_period: "",
    recurring_month: "",
    recurring_day: "",
    recurring_weekday: "",
    recurring_time: "",
    recurring_history: null,
    is_team: false
  });

  useEffect(() => {
    if (taskUuid) {
      dispatch(getDetailTask(String(taskUuid)));
    }
  }, [dispatch]);

  const isRecurring = String(history.location.pathname).includes("recurring");

  const permission = {
    otherList: permissionPage("task-others-list"),
    asign: permissionPage("task-assign"),
    create: permissionPage("task-create")
  };
  const policy = {
    min: getPolicies("task-min-date"),
    max: getPolicies("task-max-date"),
    minAssign: getPolicies("task-assign-min-date"),
    maxAssign: getPolicies("task-assign-max-date")
  };

  const [endPeriod, setEndPeriod] = useState(true);
  const [file, setFile]: any = useState([]);
  const [month, setMonth]: any = useState([]);
  const [day, setDay]: any = useState([]);
  const [weekDay, setWeekDay]: any = useState([]);
  const [dataPeriode] = useState([
    { value: "", label: t("task.detail.none") },
    { value: "4", label: String(t("task.detail.yearly")) },
    { value: "3", label: String(t("task.detail.monthly")) },
    { value: "2", label: t("task.detail.weekly") },
    { value: "1", label: t("task.detail.daily") }
  ]);

  const [modal, setModal] = useState(false);
  const typeDateTime: any = [{ date: "" }];
  const [dateStart, setDateStart] = useState(typeDateTime);
  const [dateEnd, setDateEnd] = useState(typeDateTime);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [directSave, setDirectSave] = useState(false);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);
  const [dataEndPeriod, setDataEndPeriod] = useState(typeDateTime);

  const [projectChild, setProjectChild] = useState<string[]>([]);

  const policyDate = {
    minDate: moment().add(policy.min, "d").format("YYYY-MM-DD"),
    maxDate: moment().add(policy.max, "d").format("YYYY-MM-DD"),
    minAssignDate: moment().add(policy.minAssign, "d").format("YYYY-MM-DD"),
    maxAssignDate: moment().add(policy.maxAssign, "d").format("YYYY-MM-DD")
  };

  const _getParentProject = useCallback(async () => {
    const childId = taskDetail?.project?.id;

    if (childId || projectId) {
      await API.getParentProject(childId || projectId, token).then(
        async res => {
          const arrParent: any[] = res.data.data;
          if (arrParent.length > 0) {
            arrParent.reverse();
            const data = [...arrParent, childId || projectId];
            setValue("project_id", data[0]);

            for (let index = 0; index < data.length; index += 1) {
              /* eslint-disable no-await-in-loop */
              const response = await API.getPopulateProject(token, {
                parent_id: data[index]
              });
              dispatch(setPopulateChildProject(response.data?.data, index));
            }
            data.shift();
            setProjectChild(data);
          } else {
            setValue("project_id", childId || projectId);
            setProjectChild([]);
          }
        }
      );
    }
  }, [setProjectChild]);

  useEffect(() => {
    // disini
    if (taskUuid) {
      _getParentProject();
      setValue("title", taskDetail && taskDetail.title);
      setValue(
        "started_at",
        moment(taskDetail && taskDetail.started_at).format("YYYY-MM-DD")
      );
      setValue(
        "due_at",
        moment(taskDetail && taskDetail.due_at).format("YYYY-MM-DD")
      );
      setValue("description", taskDetail && taskDetail.description);
      if (isRecurring) {
        setBody({
          ...body,
          recurring: true,
          recurring_period:
            String(taskDetail && taskDetail.recurring_period) || "",
          recurring_day: String(taskDetail && taskDetail.recurring_day) || "",
          recurring_month:
            String(taskDetail && taskDetail.recurring_month) || "",
          recurring_weekday:
            String(taskDetail && taskDetail.recurring_weekday) || "",
          recurring_time: String(taskDetail && taskDetail.recurring_time) || "",
          recurring_history:
            (taskDetail && taskDetail.recurring_history) || null,
          is_team: (taskDetail && taskDetail.is_team) || ""
        });
        if (taskDetail && taskDetail.recurring_ended_at) {
          setDataEndPeriod([
            {
              date: moment(taskDetail.recurring_ended_at).format("YYYY-MM-DD")
            }
          ]);
          setValue(
            "recurring_ended_at",
            moment(taskDetail.recurring_ended_at).format("YYYY-MM-DD")
          );
        }
      }
    }
  }, [taskUuid, taskDetail, setValue]);

  const handleMessage = useCallback(() => {
    if (!taskUuid) {
      if (
        policyDate.minAssignDate > getValues("started_at") &&
        taskState.saveParticipanList.length > 0
      ) {
        return "min-assign";
      }
      if (
        policyDate.maxAssignDate < getValues("due_at") &&
        taskState.saveParticipanList.length > 0
      ) {
        return "max-assign";
      }
      if (
        policyDate.minDate > getValues("started_at") &&
        taskState.saveParticipanList.length === 0
      ) {
        return "min";
      }
      if (
        policyDate.maxDate < getValues("due_at") &&
        taskState.saveParticipanList.length === 0
      ) {
        return "max";
      }
      return false;
    }
    return false;
  }, [taskState.saveParticipanList]);

  const generateMonth = () => {
    const months = moment.months();
    let index = 1;
    let result: any[] = [{ value: "", label: t("task.assign.selectMonth") }];
    months.map(el => {
      result = [...result, { value: index, label: el }];
      index += 1;
    });
    setMonth((arr: any) => [...arr, ...result]);
  };

  const generateDay = () => {
    let index = 1;
    let result: any[] = [{ value: "", label: t("task.assign.selectDate") }];
    while (index <= 28) {
      result = [...result, { value: index, label: index }];
      index += 1;
    }
    setDay((arr: any) => [...arr, ...result]);
  };

  const generateWeekDay = () => {
    const weeks = [
      t("task.days.Monday"),
      t("task.days.Tuesday"),
      t("task.days.Wednesday"),
      t("task.days.Thursday"),
      t("task.days.Friday"),
      t("task.days.Saturday"),
      t("task.days.Sunday")
    ];
    let index = 1;
    let result: any[] = [{ value: "", label: t("task.assign.selectDay") }];
    weeks.map(el => {
      result = [...result, { value: index, label: el }];
      index += 1;
    });
    setWeekDay((arr: any) => [...arr, ...result]);
  };

  useEffect(() => {
    generateDay();
    generateMonth();
    generateWeekDay();
    dispatch(getPopulateProject({ show_top_parent: true }));
    dispatch(resetParticipants());

    dispatch(
      getEmployeeV2({
        hidden: false,
        outsource: false,
        disable: false,
        page: 1,
        show_self: !!taskUuid,
        bypass_permission: true
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (taskState.saveParticipanList.length > 0) {
      setDirectSave(true);
    } else {
      setDirectSave(false);
    }
  }, [taskState.saveParticipanList]);

  const _uploadFile = (e: any) => {
    const { files } = e.target;
    Array.from(files).map((item: any) => {
      // eslint-disable-next-line no-param-reassign
      item.id = Math.random().toString(36).substring(7);
      const result = file.findIndex((ed: any) => ed.name === item.name);
      if (result < 0) {
        setFile((currentArray: any) => [...currentArray, item]);
      } else {
        toast.error("Cannot insert file with duplicate name!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
      }
      return true;
    });
  };

  const deleteFile = (id: any) => {
    const newFile = file.filter((el: any) => el.id !== id);
    setFile(newFile);
  };

  const _getIdParticipant = () => {
    const result = [] as any;
    if (taskState.saveParticipanList.length > 0) {
      taskState.saveParticipanList.map(el => result.push(el.id));
    }
    return result.join(",");
  };

  const _openModalSubmit = () => {
    setModalSubmit(true);
  };

  const _onConfirmSubmitForm = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file,
      participants: _getIdParticipant()
    };

    let tempData: any = [];
    Object.keys(form).map((key: any) => {
      if (form[key]) {
        tempData = [...tempData, { title: key, value: form[key] }];
      }
      return true;
    });

    let files: any = [];
    if (file.length > 0) {
      file.map((e: any) => {
        files = [...files, e.name];
        return true;
      });
    }

    let projecName = "";

    if (projectChild.length) {
      const [project_id] = projectChild.slice(-1);
      form.project_id = project_id;
      projecName =
        newTripState.listPopulateChildProject.find(sub => {
          return sub.find((v: any) => {
            return v.value === form.project_id;
          });
        })?.[0]?.label || "";
    } else {
      projecName =
        newTripState.listPopulateProject.length > 0 &&
        newTripState.listPopulateProject.find(
          e => e.value === getValues("project_id")
        )?.label;
    }

    let participants: any = [];
    taskState.saveParticipanList.map(e => {
      participants = [...participants, e.name];
      return true;
    });

    const result: any = [
      { title: t("task.assign.subject"), value: getValues("title") || "-" },
      { title: t("task.card.project"), value: projecName || "-" },
      {
        title: t("task.detail.participants"),
        value: participants.length > 0 ? participants : "-"
      },
      {
        title: t("task.card.type"),
        value: body.is_team
          ? t("task.assign.team")
          : t("task.assign.individual")
      },
      {
        title: t("task.assign.startingDate"),
        value: moment(getValues("started_at")).format("DD MMM YYYY") || "-"
      },
      {
        title: t("task.assign.expectedFinishDate"),
        value: moment(getValues("due_at")).format("DD MMM YYYY") || "-"
      },
      { title: t("task.assign.desc"), value: getValues("description") || "-" },
      {
        title: t("task.assign.attachment"),
        value: files.length > 0 ? files : "-"
      },
      {
        title: t("task.assign.recurring"),
        value: body.recurring ? t("task.detail.yes") : t("task.detail.no")
      },
      {
        title: t("task.assign.recurringPeriod"),
        value:
          body.recurring_period === "1"
            ? t("task.detail.daily")
            : body.recurring_period === "2"
            ? t("task.detail.weekly")
            : body.recurring_period === "3"
            ? t("task.detail.monthly")
            : !body.recurring
            ? "-"
            : t("task.detail.yearly")
      },
      {
        title: t("task.assign.recurringMonth"),
        value:
          body.recurring_period === "4"
            ? moment()
                .month(Number(body.recurring_month) - 1)
                .format("MMMM")
            : "-"
      },
      {
        title: t("task.assign.recurringWeek"),
        value:
          body.recurring_period === "2"
            ? moment().day(body.recurring_weekday).format("dddd")
            : "-"
      },
      {
        title: t("task.assign.recurringDate"),
        value: ["3", "4"].includes(body.recurring_period)
          ? body.recurring_day
          : "-"
      },
      {
        title: t("task.assign.recurringTime"),
        value: body.recurring ? body.recurring_time : "-"
      },
      {
        title: t("task.assign.endPeriod"),
        value:
          body.recurring && endPeriod ? getValues("recurring_ended_at") : "-"
      }
    ];

    if (result) {
      setDataConfirmSubmit(result);
      setModalSubmitForm(true);
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file,
      participants: _getIdParticipant(),
      assigned: 1,
      client_timezone: "Asia/Jakarta",
      finished_at: ""
    };

    let message = "";
    let success = false;

    if (
      form.recurring &&
      form.recurring_period === "1" &&
      !form.recurring_time
    ) {
      message = t("task.assign.recTimeReq");
    } else if (
      form.recurring &&
      form.recurring_period === "2" &&
      !form.recurring_weekday
    ) {
      message = t("task.assign.recWeekReq");
    } else if (
      form.recurring &&
      ["3", "4"].includes(form.recurring_period) &&
      !form.recurring_day
    ) {
      message = t("task.assign.recDayReq");
    } else if (
      form.recurring &&
      form.recurring_period === "4" &&
      !form.recurring_month
    ) {
      message = t("task.assign.recMonthReq");
    } else if (
      form.recurring &&
      ["1", "2", "3", "4"].includes(form.recurring_period) &&
      !form.recurring_time
    ) {
      message = t("task.assign.recTimeReq");
    } else {
      message = t("task.assign.dataStored");
      success = true;
    }

    if (projectChild.length) {
      const [project_id] = projectChild.slice(-1);
      form.project_id = project_id;
    }

    if (success && message) {
      dispatch(
        postTask(form, (e: { success: boolean; message: string }) => {
          if (e.success === true) {
            toast.success(`${e.message.toUpperCase()}`, {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              transition: Slide,
              className: "assign-toast",
              closeButton: false,
              draggable: false
            });
            dispatch(resetParticipants());
            if (permission.otherList) {
              history.push("/task/assigned");
            } else {
              history.push("/task");
            }
          } else if (e.success === false) {
            toast.error(`${e.message}`, {
              position: "bottom-center",
              autoClose: 3500,
              hideProgressBar: true,
              transition: Slide,
              className: "assign-toast",
              closeButton: false,
              draggable: false
            });
          }
        })
      );
    } else {
      toast.error(message, {
        position: "bottom-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      });
    }
  };

  const _onEdit = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file,
      participants: _getIdParticipant(),
      assigned: 0,
      client_timezone: "Asia/Jakarta",
      finished_at: ""
    };

    let message = "";
    let success = false;

    if (
      form.recurring &&
      form.recurring_period === "1" &&
      !form.recurring_time
    ) {
      message = t("task.assign.recTimeReq");
    } else if (
      form.recurring &&
      form.recurring_period === "2" &&
      !form.recurring_weekday
    ) {
      message = t("task.assign.recWeekReq");
    } else if (
      form.recurring &&
      ["3", "4"].includes(form.recurring_period) &&
      !form.recurring_day
    ) {
      message = t("task.assign.recDayReq");
    } else if (
      form.recurring &&
      form.recurring_period === "4" &&
      !form.recurring_month
    ) {
      message = t("task.assign.recMonthReq");
    } else if (
      form.recurring &&
      ["1", "2", "3", "4"].includes(form.recurring_period) &&
      !form.recurring_time
    ) {
      message = t("task.assign.recTimeReq");
    } else {
      message = t("task.assign.dataStored");
      success = true;
    }

    if (projectChild.length) {
      const [project_id] = projectChild.slice(-1);
      form.project_id = project_id;
    }

    if (success && message) {
      dispatch(
        putTaskEdit(
          taskUuid,
          form,
          (e: { success: boolean; message: string }) => {
            if (e.success === true) {
              toast.success(`${e.message.toUpperCase()}`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                transition: Slide,
                className: "assign-toast",
                closeButton: false,
                draggable: false
              });
              dispatch(resetParticipants());
              setTimeout(() => {
                history.push(`/task/detail/${taskUuid}/${type}`);
              }, 600);
            } else if (e.success === false) {
              toast.error(`${e.message}`, {
                position: "bottom-center",
                autoClose: 3500,
                hideProgressBar: true,
                transition: Slide,
                className: "assign-toast",
                closeButton: false,
                draggable: false
              });
            }
          }
        )
      );
    } else {
      toast.error(message, {
        position: "bottom-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      });
    }
  };

  const _validationDueDate = () => {
    if (getValues("due_at") === "") {
      const dt = [{ date: moment(watch("started_at")).format("YYYY-MM-DD") }];
      setDateEnd(dt);
      setValue("due_at", moment(watch("started_at")).format("YYYY-MM-DD"));
    }
  };

  const _goToPreview = (itemFile: any) => {
    const go = URL.createObjectURL(itemFile);
    window.open(go, "_blank");
  };

  const _onChangeRecPeriod = (e: any) => {
    if (e.target.value === "1") {
      const dt = [
        {
          date: moment(watch("started_at")).add(1, "d").format("YYYY-MM-DD")
        }
      ];
      setDataEndPeriod(dt);
      setValue(
        "recurring_ended_at",
        moment(watch("started_at")).local().add(1, "d").format("YYYY-MM-DD")
      );
    } else if (e.target.value === "2") {
      const dt = [
        {
          date: moment(watch("started_at")).add(7, "d").format("YYYY-MM-DD")
        }
      ];
      setDataEndPeriod(dt);
      setValue(
        "recurring_ended_at",
        moment(watch("started_at")).local().add(7, "d").format("YYYY-MM-DD")
      );
    } else if (e.target.value === "3") {
      const dt = [
        {
          date: moment(watch("started_at")).add(1, "M").format("YYYY-MM-DD")
        }
      ];
      setDataEndPeriod(dt);
      setValue(
        "recurring_ended_at",
        moment(watch("started_at")).local().add(1, "M").format("YYYY-MM-DD")
      );
    } else if (e.target.value === "4") {
      const dt = [
        {
          date: moment(watch("started_at"))
            .add(12, "M")
            .add(1, "d")
            .format("YYYY-MM-DD")
        }
      ];
      setDataEndPeriod(dt);
      setValue(
        "recurring_ended_at",
        moment(watch("started_at"))
          .local()
          .add(12, "M")
          .add(1, "d")
          .format("YYYY-MM-DD")
      );
    } else {
      setDataEndPeriod([{ date: null }]);
      setValue("recurring_ended_at", "");
    }
  };

  const _onChangeRecPeriodFromDate = (val: any) => {
    if (body.recurring && endPeriod && body.recurring_period) {
      if (body.recurring_period === "1") {
        const dt = [
          {
            date: moment(val).add(1, "d").format("YYYY-MM-DD")
          }
        ];
        setDataEndPeriod(dt);
        setValue(
          "recurring_ended_at",
          moment(val).local().add(1, "d").format("YYYY-MM-DD")
        );
      } else if (body.recurring_period === "2") {
        const dt = [
          {
            date: moment(val).add(7, "d").format("YYYY-MM-DD")
          }
        ];
        setDataEndPeriod(dt);
        setValue(
          "recurring_ended_at",
          moment(val).local().add(7, "d").format("YYYY-MM-DD")
        );
      } else if (body.recurring_period === "3") {
        const dt = [
          {
            date: moment(val).add(1, "M").format("YYYY-MM-DD")
          }
        ];
        setDataEndPeriod(dt);
        setValue(
          "recurring_ended_at",
          moment(val).local().add(1, "M").format("YYYY-MM-DD")
        );
      } else if (body.recurring_period === "4") {
        const dt = [
          {
            date: moment(val).add(12, "M").add(1, "d").format("YYYY-MM-DD")
          }
        ];
        setDataEndPeriod(dt);
        setValue(
          "recurring_ended_at",
          moment(val).local().add(12, "M").add(1, "d").format("YYYY-MM-DD")
        );
      }
    }
  };

  const _onProjectChange = (id: string, index: number) => {
    dispatch(getPopulateChildProject({ parent_id: id }, index));
  };

  const _getValuetOption = useCallback((data, id) => {
    if (id) {
      return { label: data.find((e: any) => e.value === id)?.label || "" };
    }
    return "";
  }, []);

  return (
    <View>
      <ToastContainer limit={1} />
      <Header
        title={
          !taskUuid
            ? String(sprintf(t("task.assignTask"), copTeks.task))
            : String(sprintf(t("task.editTask"), copTeks.task))
        }
        goBack={() => {
          history.goBack();
          dispatch(resetParticipants());
        }}
      />
      {taskState && !taskState.isLoadingPutTask ? (
        <View style={styles.container}>
          {handleMessage() && (
            <View style={{ marginTop: 16 }}>
              <Message
                message={
                  handleMessage() === "min-assign"
                    ? `Your start date for this task is currently violated our minimum date policy that your company set, ${policy.minAssign} day(s) from current date.`
                    : handleMessage() === "max-assign"
                    ? `Your start date for this task is currently violated our maximum date policy that your company set, ${policy.maxAssign} day(s) from current date.`
                    : handleMessage() === "min"
                    ? `Your start date for this task is currently violated our minimum date policy that your company set, ${policy.min} day(s) from current date.`
                    : handleMessage() === "max"
                    ? `Your start date for this task is currently violated our maximum date policy that your company set, ${policy.max} day(s) from current date.`
                    : ""
                }
              />
            </View>
          )}
          <View style={[styles.wrapForm, { marginTop: 12 }]}>
            {permission.asign && !isRecurring && (
              <View style={styles.wrapSwitch}>
                <Text
                  style={[
                    styles.input,
                    { marginRight: 4 },
                    body.is_team && { opacity: 0.4 }
                  ]}
                >
                  {t("task.assign.individual")}
                </Text>
                <Switch
                  onChange={() => setBody({ ...body, is_team: !body.is_team })}
                  checked={body.is_team}
                  onColor="#ff7600"
                  offColor="#ff7600"
                  onHandleColor="#fffff7"
                  handleDiameter={16}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                  height={22}
                  width={40}
                />
                <Text
                  style={[
                    styles.input,
                    { marginLeft: 4 },
                    !body.is_team && { opacity: 0.4 }
                  ]}
                >
                  {t("task.assign.team")}
                </Text>
              </View>
            )}
            <View style={styles.wrapPersonList}>
              {taskState.saveParticipanList.length > 0 &&
                taskState.saveParticipanList.map((item, index) => (
                  <View key={index} style={{ width: "48%", marginBottom: 8 }}>
                    <Card cardStyle={{ borderRadius: 40 }}>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Image
                          source={
                            (item.avatar && item.avatar.url) || IMAGES.avatar
                          }
                          style={styles.imageStyle}
                        />
                        <View style={{ marginLeft: 4, width: "65%" }}>
                          <Text
                            style={[styles.textStyle, { fontWeight: "bold" }]}
                            numberOfLines={1}
                          >
                            {item.name}
                          </Text>
                          <Text style={styles.textStyle} numberOfLines={1}>
                            {item.position_name}
                          </Text>
                        </View>
                      </View>
                    </Card>
                  </View>
                ))}
              {permission.asign && !isRecurring && (
                <TouchableOpacity
                  activeOpacity={0.8}
                  onPress={() => {
                    dispatch(getParticipants());
                    setTimeout(() => {
                      setModal(true);
                    }, 70);
                  }}
                  style={styles.buttonAddParticipant}
                >
                  <Text style={styles.buttonTextParticipant}>
                    {t("task.assign.addParticipant")}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={styles.wrapForm}>
            <Text style={styles.label}>{t("task.assign.subject")}</Text>
            <Controller
              control={control}
              render={({ onChange, value }) => (
                <TextInput
                  style={[
                    styles.input,
                    styles.inputLine,
                    { color: isRecurring ? COLORS.greyLight : COLORS.grey }
                  ]}
                  onChangeText={text => onChange(text)}
                  value={value}
                  editable={!isRecurring}
                />
              )}
              name="title"
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.title && (
              <Text style={styles.errorMessages}>
                {t("task.assign.subjectRequired")}
              </Text>
            )}
          </View>
          <View style={styles.wrapForm}>
            <Text style={styles.label}>{t("task.detail.project")}</Text>
            <Space height={4} />

            <Controller
              control={control}
              render={({ onChange, value }) => (
                <AutoComplete
                  disabled={isRecurring}
                  placeHolder={t("task.assign.chooseProject")}
                  value={_getValuetOption(
                    newTripState.listPopulateProject,
                    value
                  )}
                  option={newTripState.listPopulateProject}
                  onValueChange={e => {
                    _onProjectChange(e.value, 0);
                    setProjectChild([]);
                    onChange(e.value);
                  }}
                  type="card"
                  isLoading={newTripState.isLoadingPopulateProject}
                />
              )}
              name="project_id"
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.project_id && (
              <Text style={[styles.errorMessages, { marginLeft: 8 }]}>
                {t("task.assign.projectRequired")}
              </Text>
            )}
          </View>
          {newTripState.listPopulateChildProject.map((item, i) => (
            <View key={`childProject-${i}`}>
              <View
                style={[
                  styles.wrapForm,
                  styles.wrapRow,
                  { alignItems: "center" }
                ]}
              >
                <View style={{ flex: 1 }}>
                  <AutoComplete
                    disabled={isRecurring}
                    placeHolder={t("task.assign.chooseProject")}
                    value={_getValuetOption(item, projectChild[i])}
                    option={item}
                    onValueChange={e => {
                      _onProjectChange(e.value, i + 1);
                      const projectChildTmp = projectChild.map(v => v);
                      projectChildTmp[i] = e.value;
                      setProjectChild(projectChildTmp);
                    }}
                    type="card"
                    isLoading={newTripState.isLoadingPopulateProject}
                  />
                </View>

                <TouchableOpacity
                  onPress={() => {
                    dispatch(removePopulateChildProject(i));
                    const projectChildTmp = projectChild.filter(
                      (_, index) => index < i
                    );
                    setProjectChild(projectChildTmp);
                  }}
                  style={[
                    {
                      height: 25,
                      width: 25,
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 0
                    }
                  ]}
                >
                  <Image
                    source={IMAGES.trashBin}
                    style={{ width: 13, height: 16 }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          ))}

          <View style={[styles.wrapForm, styles.wrapRow]}>
            <View style={{ width: "48%" }}>
              <Text style={styles.label}>
                {" "}
                {t("task.assign.expectedFinishDate")}
              </Text>
              <Controller
                control={control}
                render={({ onChange }) => (
                  <Datepicker
                    disable={isRecurring}
                    validate
                    onChange={e => {
                      setDateEnd(e);
                      onChange(e[0].date);
                    }}
                    value={dateEnd}
                    minDate={dateStart[0].date || ""}
                  >
                    <Card cardStyle={styles.cardDate}>
                      {getValues("due_at") ? (
                        <Text style={styles.input} numberOfLines={1}>
                          {!taskUuid ? (
                            <>
                              <Text style={{ fontWeight: "normal" }}>
                                {`${moment(dateEnd[0].date).format("ddd")} `}
                              </Text>
                              {moment(dateEnd[0].date).format("DD MMM YYYY")}
                            </>
                          ) : (
                            <Text
                              style={{
                                color: isRecurring
                                  ? COLORS.greyLight
                                  : COLORS.grey
                              }}
                            >
                              <Text
                                style={{
                                  fontWeight: "normal",
                                  color: isRecurring
                                    ? COLORS.greyLight
                                    : COLORS.grey
                                }}
                              >
                                {getValues("due_at")
                                  ? `${moment(getValues("due_at")).format(
                                      "ddd"
                                    )} `
                                  : `${moment().format("ddd")} `}
                              </Text>
                              {moment(getValues("due_at")).format(
                                "DD MMM YYYY"
                              )}
                            </Text>
                          )}
                        </Text>
                      ) : (
                        <Text style={styles.input} numberOfLines={1}>
                          {t("task.assign.selectDate")}
                        </Text>
                      )}
                    </Card>
                  </Datepicker>
                )}
                name="due_at"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.due_at && (
                <Text style={styles.errorMessages}>
                  {t("task.assign.expectedFinishDateReq")}
                </Text>
              )}
            </View>
            <View style={{ width: "48%" }}>
              <Text style={styles.label}>{t("task.assign.startingDate")}</Text>
              <Controller
                control={control}
                render={({ onChange }) => (
                  <Datepicker
                    validate
                    disable={isRecurring}
                    onChange={e => {
                      setDateStart(e);
                      onChange(e[0].date);
                      _validationDueDate();
                      _onChangeRecPeriodFromDate(e[0].date);
                    }}
                    value={dateStart}
                    maxDate={dateEnd[0].date || ""}
                  >
                    <Card cardStyle={styles.cardDate}>
                      <Text
                        style={[
                          styles.input,
                          {
                            color: isRecurring ? COLORS.greyLight : COLORS.grey
                          }
                        ]}
                        numberOfLines={1}
                      >
                        <Text style={{ fontWeight: "normal" }}>
                          {getValues("started_at")
                            ? `${moment(getValues("started_at")).format(
                                "ddd"
                              )} `
                            : `${moment().format("ddd")} `}
                        </Text>
                        {getValues("started_at")
                          ? moment(getValues("started_at")).format(
                              "DD MMM YYYY"
                            )
                          : moment().format("DD MMM YYYY")}
                      </Text>
                    </Card>
                  </Datepicker>
                )}
                name="started_at"
                rules={{ required: true }}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
              {errors.started_at && (
                <Text style={styles.errorMessages}>
                  {t("task.assign.startingDateReq")}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.wrapForm}>
            <Text style={styles.label}>{t("task.assign.desc")}</Text>
            <Controller
              control={control}
              render={({ onChange, value }) => (
                <View style={styles.textArea}>
                  <TextArea
                    disabled={isRecurring}
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    withPadding
                    bold
                  />
                </View>
              )}
              name="description"
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.description && (
              <Text style={styles.errorMessages}>
                {t("task.assign.descReq")}
              </Text>
            )}
          </View>
          <View style={[styles.wrapForm, styles.wrapRow]}>
            <View style={{ width: "48%" }}>
              <View style={{ width: "100%" }}>
                <View
                  style={{
                    flexDirection: "row",
                    marginLeft: 12,
                    marginTop: 8
                  }}
                >
                  <Switch
                    onChange={() =>
                      setBody({ ...body, recurring: !body.recurring })
                    }
                    checked={body.recurring}
                    onColor="#50b1b1"
                    onHandleColor="#fffff7"
                    handleDiameter={19}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                    height={19}
                    width={34}
                  />
                  <Text style={[styles.input, { marginLeft: 8 }]}>
                    {t("task.detail.repeatTask")}
                  </Text>
                </View>
                {body.recurring ? (
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 12,
                      marginTop: 8
                    }}
                  >
                    <Switch
                      onChange={() => setEndPeriod(!endPeriod)}
                      checked={endPeriod}
                      onColor="#50b1b1"
                      onHandleColor="#fffff7"
                      handleDiameter={19}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                      height={19}
                      width={34}
                    />
                    <Text style={[styles.input, { marginLeft: 8 }]}>
                      {t("task.assign.endTask")}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
                {body.recurring && endPeriod && (
                  <View style={{ marginTop: "1rem", width: "99%" }}>
                    <Text style={styles.label}>
                      {t("task.assign.endPeriod")}
                    </Text>
                    <Controller
                      control={control}
                      render={({ onChange }) => (
                        <Datepicker
                          validate
                          onChange={e => {
                            setDataEndPeriod(e);
                            onChange(e[0].date);
                          }}
                          value={dataEndPeriod}
                          minDate=""
                        >
                          <Card cardStyle={styles.cardDate}>
                            {getValues("recurring_ended_at") ? (
                              <Text style={styles.input} numberOfLines={1}>
                                <Text style={{ fontWeight: "normal" }}>
                                  {`${moment(dataEndPeriod[0].date).format(
                                    "ddd"
                                  )} `}
                                </Text>
                                {moment(dataEndPeriod[0].date).format(
                                  "DD MMM YYYY"
                                )}
                              </Text>
                            ) : (
                              <Text style={styles.input} numberOfLines={1}>
                                {t("task.assign.selectDate")}
                              </Text>
                            )}
                          </Card>
                        </Datepicker>
                      )}
                      name="recurring_ended_at"
                      rules={{ required: true }}
                      defaultValue=""
                    />
                  </View>
                )}
              </View>
              {!taskUuid && (
                <View
                  style={[
                    styles.wrapForm,
                    {
                      marginTop: 24,
                      width: "100%",
                      alignSelf: "flex-start"
                    }
                  ]}
                >
                  <View style={{ marginBottom: 12 }}>
                    {file.length > 0 &&
                      file.map((el: any, i: number) => {
                        return (
                          <Touchable onPress={() => _goToPreview(el)} key={i}>
                            <FileUpload
                              name={el.name}
                              style={{
                                marginTop: 6,
                                marginBottom: 6,
                                marginRight: 8,
                                width: "80%"
                              }}
                              deleteFile={e => {
                                e.stopPropagation();
                                deleteFile(el.id);
                              }}
                            />
                          </Touchable>
                        );
                      })}
                  </View>
                  <Upload
                    multiple
                    style={{
                      textAlign: "center",
                      padding: 0,
                      width: "100%",
                      alignItems: "center",
                      paddingBottom: 5,
                      paddingTop: 5
                    }}
                    onChange={(el: any) => _uploadFile(el)}
                  />
                </View>
              )}
            </View>
            <View style={{ width: "48%" }}>
              {body.recurring && (
                <View style={{ width: "100%", marginBottom: 8 }}>
                  <Card cardStyle={styles.cardDate}>
                    <Select
                      style={
                        body.recurring_period === ""
                          ? { opacity: 0.3 }
                          : { opacity: 1 }
                      }
                      listValue={dataPeriode}
                      onChange={e => {
                        setBody({ ...body, recurring_period: e.target.value });
                        _onChangeRecPeriod(e);
                      }}
                      value={body.recurring_period}
                    />
                  </Card>
                </View>
              )}
              {body.recurring && body.recurring_period === "4" && (
                <View style={{ width: "100%", marginBottom: 8 }}>
                  <Card cardStyle={styles.cardDate}>
                    <Select
                      style={
                        body.recurring_month === ""
                          ? { opacity: 0.3 }
                          : { opacity: 1 }
                      }
                      listValue={month}
                      onChange={e =>
                        setBody({ ...body, recurring_month: e.target.value })
                      }
                      value={body.recurring_month}
                    />
                  </Card>
                </View>
              )}
              {body.recurring && ["3", "4"].includes(body.recurring_period) && (
                <View style={{ width: "100%", marginBottom: 8 }}>
                  <Card cardStyle={styles.cardDate}>
                    <Select
                      style={
                        body.recurring_day === ""
                          ? { opacity: 0.3 }
                          : { opacity: 1 }
                      }
                      listValue={day}
                      onChange={e =>
                        setBody({ ...body, recurring_day: e.target.value })
                      }
                      value={body.recurring_day}
                    />
                  </Card>
                </View>
              )}
              {body.recurring && body.recurring_period === "2" && (
                <View style={{ width: "100%", marginBottom: 8 }}>
                  <Card cardStyle={styles.cardDate}>
                    <Select
                      style={
                        body.recurring_weekday === ""
                          ? { opacity: 0.3 }
                          : { opacity: 1 }
                      }
                      listValue={weekDay}
                      onChange={e =>
                        setBody({ ...body, recurring_weekday: e.target.value })
                      }
                      value={body.recurring_weekday}
                    />
                  </Card>
                </View>
              )}
              {body.recurring &&
                ["4", "3", "2", "1"].includes(body.recurring_period) && (
                  <View>
                    <Card cardStyle={styles.cardDate}>
                      <input
                        type="time"
                        style={{ borderWidth: 0, backgroundColor: "#fffff7" }}
                        onChange={e =>
                          setBody({ ...body, recurring_time: e.target.value })
                        }
                        value={body.recurring_time}
                      />
                    </Card>
                  </View>
                )}
            </View>
          </View>

          <View
            style={{
              alignItems: "center",
              marginTop: 67,
              marginBottom: 74
            }}
          >
            <Button
              title={
                !taskUuid ? t("task.assign.assign") : t("task.assign.update")
              }
              onPress={
                !taskUuid
                  ? handleSubmit(_onConfirmSubmitForm)
                  : handleSubmit(_onEdit)
              }
              isLoading={taskState.isLoadingPostTask}
            />
          </View>
        </View>
      ) : (
        <ActivityIndicator style={{ marginTop: 20 }} />
      )}
      <Modal isVisible={modal} backdropColor="rgba(255,255,255, 0.9)">
        <AddParticipants
          onPress={() => {
            setModal(false);
            dispatch(saveAddParticipants());
          }}
          onCancel={() => setModal(false)}
        />
      </Modal>
      <Modal isVisible={modalSubmitForm} backdropColor="rgba(255,255,255, 0.9)">
        <ModalConfirmForm
          data={dataConfirmSubmit}
          onPress={
            directSave ? handleSubmit(_onSave) : handleSubmit(_openModalSubmit)
          }
          onCancel={() => setModalSubmitForm(false)}
          isLoading={taskState.isLoadingPostTask}
        />
      </Modal>
      <Modal isVisible={modalSubmit} backdropColor="rgba(255,255,255, 0.98)">
        <Confirmation
          headerTitle={String(sprintf(t("task.submitTask"), copTeks.task))}
          description={String(sprintf(t("task.submitMsg"), copTeks.task))}
          actionTitle={String(t("task.detail.yes"))}
          cancelTitle={String(t("task.detail.no"))}
          onCancel={() => {
            setModalSubmit(false);
            setDirectSave(false);
          }}
          isLoading={taskState.isLoadingPostTask}
          onAction={handleSubmit(_onSave)}
        />
      </Modal>
    </View>
  );
};

export default NewAssignTask;
