import { StyleSheet } from "react-native";
import { COLORS } from "../../../../configs";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20
  },
  btnReport: {
    width: 28,
    height: 28,
    backgroundColor: "#fffff7",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  footer: {
    height: 74,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "center",
    alignItems: "center"
  },
  cardStyle: {
    marginBottom: 12
  },
  wrapContentCard: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 12
  },
  wrapCardText: {
    flexDirection: "row",
    marginBottom: 4
  },
  wrapText: {
    flexDirection: "row",
    marginBottom: 4,
    paddingHorizontal: 16
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "22%"
  },
  textValue: {
    width: "75%",
    marginRight: 7,
    color: COLORS.grey
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.grey
  },
  wrapAmountText: {
    paddingHorizontal: 20,
    marginTop: -20
  },
  amountText: {
    color: COLORS.green,
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right"
  },
  wrapTrashIcon: {
    height: 26,
    width: 26,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8
  },
  wrapButtonEmpty: {
    flexDirection: "row",
    justifyContent: "center",
    width: "90%"
  },
  wrapIcon: {
    alignSelf: "flex-end",
    flexDirection: "row",
    width: "20%",
    justifyContent: "flex-end"
  },
  wrapTotal: {
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 12,
    marginBottom: 90
  },
  totalLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11
  },
  button: {
    minWidth: "46%",
    flex: 1,
    marginTop: 10,
    marginHorizontal: 2
  },
  button2: {
    width: "100%",
    alignItems: "center",
    paddingVertical: 4
  },
  button3: {
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  buttonText: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.grey,
    fontWeight: "bold"
  },
  buttonText2: {
    color: COLORS.white
  },
  wrapButtonAdd: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  emptyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  },
  wrapBtnImage: {
    position: "absolute",
    right: 8,
    top: 8,
    zIndex: 1,
    height: 26,
    width: 26,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8
  },
  btn: {
    width: "46%"
  },
  containerModeration: {
    width: widthPercent(100),
    paddingBottom: 20,
    paddingHorizontal: 12,
    paddingTop: 32
  },
  headerTitle: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18,
    color: COLORS.orange,
    textTransform: "uppercase",
    // paddingTop: 4,
    textAlign: "center"
  },
  wrapBtnModeration: {
    flex: 1,
    alignItems: "center"
  },
  btnModeration: {
    borderRadius: 100,
    width: 54,
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginBottom: 24
  },
  stepperContainer: {
    flexDirection: "row",
    overflow: "scroll",
    marginTop: 12,
    paddingBottom: 12
  },
  stepperStatus: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666"
  },
  stepperAssigner: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  stepperItems: {
    flexDirection: "row",
    alignItems: "center"
  },
  stepperCircle: {
    height: 18,
    width: 18,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",
    borderRadius: 100
  },
  stepperLine: {
    height: 3,
    width: 168,
    marginHorizontal: 4,
    backgroundColor: "#50B1B1"
  }
});

export default styles;
