import React from "react";
import { Image, ImageBackground, View } from "react-native";
import { useSelector } from "react-redux";
import { CustomText } from "../../../../components";

import { IMAGES } from "../../../../configs";
import styles from "./style";
import { Reducers } from "../../../../redux/types";

import "./style.css";

// screens

export const KasbonCard = () => {
  const { myProfile } = useSelector((state: Reducers) => state.home);
  const { loanProfile } = useSelector((state: Reducers) => state.loan);

  const loanNumber = loanProfile && loanProfile[0]?.number;

  return (
    <View style={{ justifyContent: "center", alignItems: "center" }}>
      <ImageBackground
        source={IMAGES.kasbonCard}
        resizeMode="cover"
        style={styles.container}
        imageStyle={styles.container}
      >
        <View style={styles.content}>
          <View style={styles.row}>
            <Image
              source={myProfile.avatar || IMAGES.kasbonLogo}
              style={styles.logo}
            />
            {loanNumber && (
              <CustomText style={styles.number}>
                {`${loanNumber?.substring(0, 3)} ${loanNumber?.substring(
                  3,
                  8
                )}`}
              </CustomText>
            )}
          </View>
          <View style={styles.rowSecond}>
            <Image source={IMAGES.avatar} style={styles.profile} />
            <View style={styles.containerProfile}>
              <div className="kasbon-card-name">{myProfile.name}</div>
              <CustomText style={styles.position}>
                {myProfile.employee.position_name}
              </CustomText>
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};
