import React, { useEffect } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import moment from "moment";

import { getNotification } from "../../redux/actions";
import {
  // Card,
  Header,
  LoadingLoadMore,
  Touchable,
  WrapContent,
  WrapList
} from "../../components";
import { Reducers } from "../../redux/types";
import styles from "./styles";
import { IMAGES } from "../../configs";

const ShortcutNotification = () => {
  // const [heightHeader, setHeightHeader] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listNotification, isLoadingNotification, loadMore } = useSelector(
    (state: Reducers) => state.notification
  );

  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);

  const history = useHistory();

  return (
    <Switch>
      <View>
        <Header
          title={t("notification.notification")}
          goBack={() => history.push("/home")}
        />
        <WrapContent isLoading={isLoadingNotification}>
          <WrapList
            isEmpty={listNotification.length === 0}
            emptyComponent={() => (
              <View>
                <Text style={styles.attributeTask}>
                  {t("notification.noNotif")}
                </Text>
              </View>
            )}
            onEndReached={() => loadMore && dispatch(getNotification(true))}
          >
            <FlatList
              style={{ paddingHorizontal: 20 }}
              data={listNotification}
              keyExtractor={(_, index) => index.toString()}
              ListFooterComponent={() => <LoadingLoadMore active={loadMore} />}
              renderItem={({ item, index }) => (
                <View
                  style={{
                    paddingVertical: 16,
                    paddingHorizontal: 16
                  }}
                >
                  <Touchable
                    key={index}
                    disabled={item.type !== "Invoice"}
                    onPress={() =>
                      history.push(
                        `/cash-details/${item.reference.id}/approvable`
                      )
                    }
                  >
                    <View style={styles.containerContent}>
                      <View style={styles.cardImage}>
                        <Image
                          source={IMAGES.moderationNotifIcon}
                          style={{ height: 28, width: 28 }}
                        />
                      </View>
                      <View
                        style={{
                          width: "100%",
                          marginLeft: 20
                        }}
                      >
                        <Text style={styles.attributeTask}>
                          {t("notification.notif1")}
                          <Text style={styles.boldOrange}>
                            {` ${item.type} `}
                          </Text>
                          {t("notification.notif2")}
                          <Text style={styles.boldOrange}>
                            {` ${item.reference.number} `}
                          </Text>
                          .
                        </Text>
                      </View>
                    </View>
                  </Touchable>
                </View>
              )}
            />
          </WrapList>
        </WrapContent>
      </View>
    </Switch>
  );
};

export default ShortcutNotification;
