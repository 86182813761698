import { StyleSheet } from "react-native";
import { widthPercent } from "../../../../utils";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundImage: "linear-gradient(180deg, #FFFFF7 0%, #FAEDE3 100%)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 2
  },

  content: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
    marginBottom: 32
  },

  limitWithdrawal: {
    fontSize: 16,
    fontWeight: "500",
    color: "#343434",
    marginBottom: 20
  },

  label: {
    color: "#939393",
    fontSize: 12,
    fontWeight: "500",
    marginBottom: 8
  },

  amount: {
    color: "#343434",
    fontSize: 16,
    fontWeight: "400",
    marginBottom: 20
  },

  wrapTextInput: {
    borderBottomWidth: 0.5
  },

  textInput: {
    paddingVertical: 5,
    fontFamily: "Poppins",
    color: "#343434",
    fontSize: 32
  },

  containerBtn: {
    position: "absolute",
    bottom: 0,
    margin: 20
  },

  btnSubmit: {
    width: widthPercent(100) - 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FF9500",
    borderRadius: 12,
    padding: 8
  },

  btnText: {
    fontSize: 16,
    color: "white"
  },

  btnBack: {
    width: widthPercent(100) - 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#FF9500",
    borderRadius: 12,
    padding: 8,
    marginTop: 20
  },

  btnBackText: {
    fontSize: 16,
    color: "#FF9500"
  }
});

export default styles;
