import React, { useEffect } from "react";
import { Image, Text, View } from "react-native";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import {
  Card,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../../components";
import { IMAGES } from "../../../../../configs";
import { convertDate, maskedMoney, queryString } from "../../../../../utils";

import { getListBookingHotel } from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";

interface Props {
  id: string;
}

const star = (total: number) => {
  let img = <></>;
  for (let i = 0; i < total; i += 1) {
    img = (
      <>
        {img}
        <Image style={styles.star} source={IMAGES.star} />
      </>
    );
  }
  const sisa = 5 - total;
  for (let j = 0; j < sisa; j += 1) {
    img = (
      <>
        {img}
        <Image style={styles.star} source={IMAGES.stargrey} />
      </>
    );
  }
  return <View style={{ flexDirection: "row" }}>{img}</View>;
};

const Hotel = ({ id }: Props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { search } = history.location;
  const param = queryString(search);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListBookingHotel(id));
  }, [dispatch]);

  const { listBookingHotel, isLoadingListBookingHotel } = useSelector(
    (state: Reducers) => state.booking
  );

  const _splitDate = (date: string) =>
    convertDate(date, "ddd,DD MMM YYYY,hh:mm").split(",");

  return (
    <WrapContent isLoading={isLoadingListBookingHotel}>
      <WrapList
        isEmpty={listBookingHotel.length === 0}
        emptyComponent={() =>
          param.status === "6" || param.status === "7" ? (
            <Text style={styles.textNoData}>No Data</Text>
          ) : (
            <></>
          )
        }
      >
        {listBookingHotel.map((item, index) => {
          return (
            <Card key={index} cardStyle={styles.cardDescription}>
              <View>
                <View style={[styles.desciption, { marginBottom: 2 }]}>
                  <Text style={styles.titleDescription}>Hotel</Text>
                  <View style={styles.detailDescription}>
                    {star(
                      param.status === "6" || param.status === "7"
                        ? item.data.star
                        : item.data.prediction_star
                    )}
                  </View>
                </View>
                <Text style={styles.titleHotel}>
                  {param.status === "6" || param.status === "7"
                    ? item.data.hotel
                    : item.data.prediction_name}
                </Text>

                <Touchable
                  disabled={param.status === "6" || param.status === "7"}
                  onPress={() =>
                    history.push(
                      `${match.path}/hotel/edit/${item.id}?type=${param.type}`
                    )
                  }
                >
                  <View>
                    <View style={[styles.desciption, styles.descriptionCity]}>
                      <Text style={styles.titleDescription}>City</Text>
                      <Text style={styles.detailDescription}>
                        {param.status === "6" || param.status === "7"
                          ? item.data.city || "-"
                          : item.data.location}
                      </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                      <View style={[styles.desciption, styles.descriptionCity]}>
                        <Text style={styles.titleDescription}>Check In</Text>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={styles.descDate}>
                            {`${_splitDate(item.data.check_in)[0]} `}
                          </Text>
                          <Text
                            style={[styles.descDate, { fontWeight: "bold" }]}
                          >
                            {`${_splitDate(item.data.check_in)[1]} `}
                          </Text>
                          <Text style={styles.descDate}>
                            {`${_splitDate(item.data.check_in)[2]} `}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.desciption, styles.descriptionCity]}>
                        <Text style={[styles.titleDescription]}>Check Out</Text>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={styles.descDate}>
                            {`${_splitDate(item.data.check_out)[0]} `}
                          </Text>
                          <Text
                            style={[styles.descDate, { fontWeight: "bold" }]}
                          >
                            {`${_splitDate(item.data.check_out)[1]} `}
                          </Text>
                          <Text style={styles.descDate}>
                            {`${_splitDate(item.data.check_out)[2]} `}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* <View style={styles.desciption}>
                      <Text style={styles.titleDescription}>Facility</Text>
                      <Text style={styles.detailDescription}>
                        {item.facility.map(
                          (itemFacility: string, indexFacility: number) =>
                            `${itemFacility}${
                              indexFacility !== item.facility.length - 1
                                ? ","
                                : ""
                            } `
                        )}
                      </Text>
                    </View> */}
                    <View style={styles.desciption}>
                      <Text style={styles.titleDescription}>Budget</Text>
                      <Text
                        style={[
                          styles.detailDescription,
                          { fontWeight: "bold" }
                        ]}
                      >
                        {`IDR ${maskedMoney(
                          item.djurneeBooking.prediction_total
                        )}`}
                      </Text>
                    </View>
                  </View>
                </Touchable>
              </View>
            </Card>
          );
        })}
      </WrapList>
    </WrapContent>
  );
};

export default Hotel;
