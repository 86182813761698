import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin, putNewTrip } from ".";
import { toast } from "../../utils";

export const GET_TRIP_UPCOMING_PENDING = "GET_TRIP_UPCOMING_PENDING";
export const GET_TRIP_UPCOMING_SUCCESS = "GET_TRIP_UPCOMING_SUCCESS";
export const GET_TRIP_UPCOMING_ERROR = "GET_TRIP_UPCOMING_ERROR";

export const GET_TRIP_ONGOING_PENDING = "GET_TRIP_ONGOING_PENDING";
export const GET_TRIP_ONGOING_SUCCESS = "GET_TRIP_ONGOING_SUCCESS";
export const GET_TRIP_ONGOING_ERROR = "GET_TRIP_ONGOING_ERROR";

export const GET_TRIP_ASSIGN_PENDING = "GET_TRIP_ASSIGN_PENDING";
export const GET_TRIP_ASSIGN_SUCCESS = "GET_TRIP_ASSIGN_SUCCESS";
export const GET_TRIP_ASSIGN_ERROR = "GET_TRIP_ASSIGN_ERROR";

export const GET_TRIP_HISTORY_PENDING = "GET_TRIP_HISTORY_PENDING";
export const GET_TRIP_HISTORY_SUCCESS = "GET_TRIP_HISTORY_SUCCESS";
export const GET_TRIP_HISTORY_ERROR = "GET_TRIP_HISTORY_ERROR";

export const GET_TRIP_APPROVAL_PENDING = "GET_TRIP_APPROVAL_PENDING";
export const GET_TRIP_APPROVAL_SUCCESS = "GET_TRIP_APPROVAL_SUCCESS";
export const GET_TRIP_APPROVAL_ERROR = "GET_TRIP_APPROVAL_ERROR";

export const GET_TRIP_BILL_PENDING = "GET_TRIP_BILL_PENDING";
export const GET_TRIP_BILL_SUCCESS = "GET_TRIP_BILL_SUCCESS";
export const GET_TRIP_BILL_ERROR = "GET_TRIP_BILL_ERROR";

export const GET_TRIP_BY_ID_PENDING = "GET_TRIP_BY_ID_PENDING";
export const GET_TRIP_BY_ID_SUCCESS = "GET_TRIP_BY_ID_SUCCESS";
export const GET_TRIP_BY_ID_ERROR = "GET_TRIP_BY_ID_ERROR";

export const GET_TRIP_REPORT_PENDING = "GET_TRIP_REPORT_PENDING";
export const GET_TRIP_REPORT_SUCCESS = "GET_TRIP_REPORT_SUCCESS";
export const GET_TRIP_REPORT_ERROR = "GET_TRIP_REPORT_ERROR";

export const PUT_MODERATION_PENDING = "PUT_MODERATION_PENDING";
export const PUT_MODERATION_SUCCESS = "PUT_MODERATION_SUCCESS";
export const PUT_MODERATION_ERROR = "PUT_MODERATION_ERROR";

export const DELETE_REIMBURSE_PENDING = "DELETE_REIMBURSE_PENDING";
export const DELETE_REIMBURSE_SUCCESS = "DELETE_REIMBURSE_SUCCESS";
export const DELETE_REIMBURSE_ERROR = "DELETE_REIMBURSE_ERROR";

export const PUT_FILTER_HISTORY_TRIP_SUCCESS =
  "PUT_FILTER_HISTORY_TRIP_SUCCESS";

export const GET_TRIP_APPROVAL_ADDITIONAL_PENDING =
  "GET_TRIP_APPROVAL_ADDITIONAL_PENDING";
export const GET_TRIP_APPROVAL_ADDITIONAL_SUCCESS =
  "GET_TRIP_APPROVAL_ADDITIONAL_SUCCESS";
export const GET_TRIP_APPROVAL_ADDITIONAL_ERROR =
  "GET_TRIP_APPROVAL_ADDITIONAL_ERROR";

export const PUT_RESOLVE_TRIP_PENDING = "PUT_RESOLVE_TRIP_PENDING";
export const PUT_RESOLVE_TRIP_SUCCESS = "PUT_RESOLVE_TRIP_SUCCESS";
export const PUT_RESOLVE_TRIP_ERROR = "PUT_RESOLVE_TRIP_ERROR";

export const DELETE_TRIP_ATTACHMENT_PENDING = "DELETE_TRIP_ATTACHMENT_PENDING";
export const DELETE_TRIP_ATTACHMENT_SUCCESS = "DELETE_TRIP_ATTACHMENT_SUCCESS";
export const DELETE_TRIP_ATTACHMENT_ERROR = "DELETE_TRIP_ATTACHMENT_ERROR";

export const GET_CLOSE_TRIP_SUCCESS = "GET_CLOSE_TRIP_SUCCESS";
export const GET_CLOSE_TRIP_PENDING = "GET_CLOSE_TRIP_PENDING";
export const GET_CLOSE_TRIP_ERROR = "GET_CLOSE_TRIP_ERROR";
export const RESET_CLOSE_TRIP_ERROR = "RESET_CLOSE_TRIP_ERROR";

export const GET_TERM_DATA_ACTIVITY_PENDING = "GET_TERM_DATA_ACTIVITY_PENDING";
export const GET_TERM_DATA_ACTIVITY_SUCCESS = "GET_TERM_DATA_ACTIVITY_SUCCESS";
export const GET_TERM_DATA_ACTIVITY_ERROR = "GET_TERM_DATA_ACTIVITY_ERROR";

export const POST_TERM_DATA_ACTIVITY_PENDING =
  "POST_TERM_DATA_ACTIVITY_PENDING";
export const POST_TERM_DATA_ACTIVITY_SUCCESS =
  "POST_TERM_DATA_ACTIVITY_SUCCESS";
export const POST_TERM_DATA_ACTIVITY_ERROR = "POST_TERM_DATA_ACTIVITY_ERROR";

export const POST_GENERATE_ALLOWANCE_PENDING =
  "POST_GENERATE_ALLOWANCE_PENDING";
export const POST_GENERATE_ALLOWANCE_SUCCESS =
  "POST_GENERATE_ALLOWANCE_SUCCESS";
export const POST_GENERATE_ALLOWANCE_ERROR = "POST_GENERATE_ALLOWANCE_ERROR";

export const POST_SUBMIT_ACTIVITY_PENDING = "POST_SUBMIT_ACTIVITY_PENDING";
export const POST_SUBMIT_ACTIVITY_SUCCESS = "POST_SUBMIT_ACTIVITY_SUCCESS";
export const POST_SUBMIT_ACTIVITY_ERROR = "POST_SUBMIT_ACTIVITY_ERROR";

export const DELETE_TRIP_PENDING = "DELETE_TRIP_PENDING";
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS";
export const DELETE_TRIP_ERROR = "DELETE_TRIP_ERROR";

export const GET_ACCOUNTABLE_BY_TRIP_ID_PENDING =
  "GET_ACCOUNTABLE_BY_TRIP_ID_PENDING";
export const GET_ACCOUNTABLE_BY_TRIP_ID_SUCCESS =
  "GET_ACCOUNTABLE_BY_TRIP_ID_SUCCESS";
export const GET_ACCOUNTABLE_BY_TRIP_ID_ERROR =
  "GET_ACCOUNTABLE_BY_TRIP_ID_ERROR";

export const FORM_DATA_ACTIVITY = "FORM_DATA_ACTIVITY";

export const GET_TRIP_MODERATION_PENDING = "GET_TRIP_MODERATION_PENDING";
export const GET_TRIP_MODERATION_SUCCESS = "GET_TRIP_MODERATION_SUCCESS";
export const GET_TRIP_MODERATION_ERROR = "GET_TRIP_MODERATION_ERROR";

export const GET_TRIP_BILL_MODERATION_PENDING =
  "GET_TRIP_BILL_MODERATION_PENDING";
export const GET_TRIP_BILL_MODERATION_SUCCESS =
  "GET_TRIP_BILL_MODERATION_SUCCESS";
export const GET_TRIP_BILL_MODERATION_ERROR = "GET_TRIP_BILL_MODERATION_ERROR";

export const SET_SEARCH_TRIP_VALUE = "SET_SEARCH_TRIP_VALUE";
export const RESET_SEARCH_TRIP_VALUE = "RESET_SEARCH_TRIP_VALUE";

export const getTripUpcoming = (loadMore = false, q?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: GET_TRIP_UPCOMING_PENDING, payload: { loadMore } });

    const res = await API.getTrip(
      !loadMore ? { ...trip.paramUpcoming, page: 1 } : trip.paramUpcoming,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TRIP_UPCOMING_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripUpcoming(loadMore))));
      } else {
        dispatch({
          type: GET_TRIP_UPCOMING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_UPCOMING_ERROR });
  }
};

export const getTripOngoing = (loadMore = false, q?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: GET_TRIP_ONGOING_PENDING, payload: { loadMore } });

    const res = await API.getTrip(
      !loadMore ? { ...trip.paramOngoing, page: 1 } : trip.paramOngoing,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TRIP_ONGOING_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripOngoing(loadMore))));
      } else {
        dispatch({
          type: GET_TRIP_ONGOING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_ONGOING_ERROR });
  }
};

export const getTripAssign = (loadMore = false, q?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: GET_TRIP_ASSIGN_PENDING, payload: { loadMore } });

    const res = await API.getTrip(
      !loadMore ? { ...trip.paramAssign, page: 1 } : trip.paramAssign,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TRIP_ASSIGN_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripAssign(loadMore))));
      } else {
        dispatch({
          type: GET_TRIP_ASSIGN_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_ASSIGN_ERROR });
  }
};

export const getTripHistory = (loadMore = false, q?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: GET_TRIP_HISTORY_PENDING, payload: { loadMore } });

    const res = await API.getTrip(
      !loadMore ? { ...trip.paramHistory, page: 1 } : trip.paramHistory,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TRIP_HISTORY_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripHistory(loadMore))));
      } else {
        dispatch({
          type: GET_TRIP_HISTORY_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_HISTORY_ERROR });
  }
};

export const getTripApproval = (loadMore = false, q?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: GET_TRIP_APPROVAL_PENDING, payload: { loadMore } });

    const res = await API.getTrip(
      !loadMore ? { ...trip.paramApproval, page: 1 } : trip.paramApproval,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TRIP_APPROVAL_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripApproval(loadMore))));
      } else {
        dispatch({
          type: GET_TRIP_APPROVAL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_APPROVAL_ERROR });
  }
};

export const getTripBill = (loadMore = false, q?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: GET_TRIP_BILL_PENDING, payload: { loadMore } });

    const res = await API.getTrip(
      !loadMore ? { ...trip.paramBill, page: 1 } : trip.paramBill,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_TRIP_BILL_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripBill(loadMore))));
      } else {
        dispatch({
          type: GET_TRIP_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_BILL_ERROR });
  }
};

export const getTripById = (tripId: any, cb?: (res: any) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TRIP_BY_ID_PENDING });

    const res = await API.getTripById(tripId, auth.token);
    dispatch({
      type: GET_TRIP_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb(res.data.data);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripById(tripId))));
      } else {
        dispatch({
          type: GET_TRIP_BY_ID_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_BY_ID_ERROR });
  }
};

export const getTripReport = (tripId: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TRIP_REPORT_PENDING });

    const res = await API.getTripReport(tripId, auth.token);
    dispatch({
      type: GET_TRIP_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripReport(tripId))));
      } else {
        dispatch({
          type: GET_TRIP_REPORT_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_REPORT_ERROR });
  }
};

export const putModerationTrip = (
  tripId: any,
  answer: any,
  message: any
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_MODERATION_PENDING });

    const res = await API.putModerationTrip(
      tripId,
      answer,
      message,
      auth.token
    );
    dispatch({
      type: PUT_MODERATION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(putModerationTrip(tripId, answer, message))
          )
        );
      } else {
        dispatch({
          type: PUT_MODERATION_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_MODERATION_ERROR });
  }
};

export const getTripApprovalAdditional = (
  query: any,
  loadMore = false
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({
      type: GET_TRIP_APPROVAL_ADDITIONAL_PENDING,
      payload: { loadMore }
    });

    const res = await API.getTripApprovalAdditonal(query, auth.token);
    dispatch({
      type: GET_TRIP_APPROVAL_ADDITIONAL_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(getTripApprovalAdditional(query, loadMore))
          )
        );
      } else {
        dispatch({
          type: GET_TRIP_APPROVAL_ADDITIONAL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_APPROVAL_ADDITIONAL_ERROR });
  }
};

export const deleteReimburse = (id: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_REIMBURSE_PENDING });

    await API.deleteReimburse(id, auth.token);
    dispatch({ type: DELETE_REIMBURSE_SUCCESS, payload: { id } });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteReimburse(id, cb))));
      } else {
        dispatch({
          type: DELETE_REIMBURSE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: DELETE_REIMBURSE_ERROR });
  }
};

export const putResolveTrip = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_RESOLVE_TRIP_PENDING });

    const res = await API.putResolveTrip(id, auth.token);
    dispatch({ type: PUT_RESOLVE_TRIP_SUCCESS, payload: { data: res.data } });
    toast.success(i18n.t("activity.invoice.activitySubmit"));
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putResolveTrip(id))));
      } else {
        dispatch({
          type: PUT_RESOLVE_TRIP_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_RESOLVE_TRIP_ERROR });
  }
};

export const putFilterHistoryTrip = (filter: string) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: PUT_FILTER_HISTORY_TRIP_SUCCESS,
    payload: {
      data: {
        filter,
        type: "finished",
        per_page: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: "desc",
        include:
          "project,category,project.client,users,users.user,moderation,djurneeBooking,djurneeBooking.booking"
      }
    }
  });
};

export const deleteTripAttachment = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_TRIP_ATTACHMENT_PENDING });

    const res = await API.deleteTripAttachmentById(id, auth.token);
    dispatch({
      type: DELETE_TRIP_ATTACHMENT_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteTripAttachment(id))));
      } else {
        dispatch({
          type: DELETE_TRIP_ATTACHMENT_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_TRIP_ATTACHMENT_ERROR });
  }
};

export const getCloseTrip = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_CLOSE_TRIP_PENDING });

    const res = await API.getCloseTrip(id, auth.token);
    dispatch({
      type: GET_CLOSE_TRIP_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCloseTrip(id))));
      } else {
        dispatch({
          type: GET_CLOSE_TRIP_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_CLOSE_TRIP_ERROR });
  }
};

export const resetCloseTrip = () => async (dispatch: Dispatch) => {
  dispatch({ type: RESET_CLOSE_TRIP_ERROR });
};

export const getTermDataActivity = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TERM_DATA_ACTIVITY_PENDING });

    const res = await API.getTermDataActivity(auth.token);
    dispatch({
      type: GET_TERM_DATA_ACTIVITY_SUCCESS,
      payload: {
        data:
          res.data.data && res.data.data.data !== ""
            ? JSON.parse(res.data.data.data)
            : {
                new_activity: { self_booking: true, is_project: true }
              }
      }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTermDataActivity())));
      } else {
        dispatch({
          type: GET_TERM_DATA_ACTIVITY_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TERM_DATA_ACTIVITY_ERROR });
  }
};

export const handleFormAcitivy = (
  formName: string,
  formValue: any,
  id?: any,
  cb?: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  if (id) {
    const { auth } = getState();
    if (formName === "bookingFlights") {
      await API.postNewBookingFlight(formValue[0], auth.token);
    } else {
      await API.postNewBookingHotel(formValue[0], auth.token);
    }
  } else {
    dispatch({ type: FORM_DATA_ACTIVITY, payload: { formName, formValue } });
  }
  cb && cb();
};

export const postTermDataActivity = (
  isReset = false,
  cb?: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: POST_TERM_DATA_ACTIVITY_PENDING });

    await API.postTermDataActivity(
      { data: isReset ? "" : JSON.stringify(trip.activity.data) },
      auth.token
    );
    dispatch({
      type: POST_TERM_DATA_ACTIVITY_SUCCESS,
      payload: { data: isReset }
    });
    if (cb) {
      cb();
    } else {
      !isReset && toast.success(i18n.t("activity.saveSuccess"));
    }
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postTermDataActivity())));
      } else {
        !isReset && toast.error(error.data.message);
        dispatch({
          type: POST_TERM_DATA_ACTIVITY_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_TERM_DATA_ACTIVITY_ERROR });
  }
};

export const postGenerateAllowance = (cb?: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: POST_GENERATE_ALLOWANCE_PENDING });

    const res = await API.postGenerateAllowance(
      trip.activity.data.new_activity.trip,
      auth.uuid,
      auth.token
    );
    dispatch({
      type: POST_GENERATE_ALLOWANCE_SUCCESS,
      payload: { data: res.data.data[0] }
    });
    cb && cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postGenerateAllowance())));
      } else {
        toast.error(error.data.message);
        dispatch({
          type: POST_GENERATE_ALLOWANCE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_GENERATE_ALLOWANCE_ERROR });
  }
};

export const postSubmitActivity = (cb: (e: boolean) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, trip } = getState();
    dispatch({ type: POST_SUBMIT_ACTIVITY_PENDING });
    let bookings: any[] = [];

    if (trip.activity.data.bookingHotels) {
      bookings = [...bookings, ...trip.activity.data.bookingHotels];
    }
    if (trip.activity.data.bookingFlights) {
      bookings = [...bookings, ...trip.activity.data.bookingFlights];
    }
    let filesm = "";
    if (bookings.length > 1 && bookings[0].invoices) {
      filesm = bookings[0].invoices[0].files;
    }

    const res = await API.postSubmitActivity(
      {
        new_activity: trip.activity.data.new_activity,
        cash_advance: trip.activity.data.cash_advance,
        allowance: trip.allowance.list,
        bookings,
        files: filesm
      },
      auth.token
    );

    dispatch({
      type: POST_SUBMIT_ACTIVITY_SUCCESS,
      payload: { data: res.data.data }
    });
    dispatch(
      putNewTrip(res.data.data.id, () => {
        toast.success(i18n.t("activity.invoice.addSuccess"));
        cb(true);
      })
    );
    dispatch(postTermDataActivity(true));
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postSubmitActivity(cb))));
      } else {
        cb(false);
        toast.error(
          typeof error.data.message === "string" ? error.data.message : "Error"
        );
        dispatch({
          type: POST_SUBMIT_ACTIVITY_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_SUBMIT_ACTIVITY_ERROR });
  }
};

export const deleteTrip = (id: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_TRIP_PENDING });

    const res = await API.deleteTrip(id, auth.token);
    dispatch({ type: DELETE_TRIP_SUCCESS, payload: { data: res.data } });
    toast.success(i18n.t("activity.deleteToast"));
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteTrip(id, cb))));
      } else {
        toast.error("Delete activity failed");
        dispatch({
          type: DELETE_TRIP_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_TRIP_ERROR });
  }
};

export const getAccountableByTripId = (
  tripId: any,
  cb?: (res: any) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_ACCOUNTABLE_BY_TRIP_ID_PENDING });

    const res = await API.getAccountableByTripId(tripId, auth.token);
    dispatch({
      type: GET_ACCOUNTABLE_BY_TRIP_ID_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb(res.data.data);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getAccountableByTripId(tripId))));
      } else {
        dispatch({
          type: GET_ACCOUNTABLE_BY_TRIP_ID_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_ACCOUNTABLE_BY_TRIP_ID_ERROR });
  }
};

export const getTripModeration = (tripId: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TRIP_MODERATION_PENDING });

    const res = await API.getTripModeration(tripId, auth.token);
    dispatch({
      type: GET_TRIP_MODERATION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripModeration(tripId))));
      } else {
        dispatch({
          type: GET_TRIP_MODERATION_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_MODERATION_ERROR });
  }
};

export const getTripBillModeration = (tripUserId: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_TRIP_BILL_MODERATION_PENDING });

    const res = await API.getTripBillModeration(tripUserId, auth.token);
    dispatch({
      type: GET_TRIP_BILL_MODERATION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getTripModeration(tripUserId))));
      } else {
        dispatch({
          type: GET_TRIP_BILL_MODERATION_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_TRIP_BILL_MODERATION_ERROR });
  }
};

export const setSearchTriphValue = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_TRIP_VALUE,
    payload: { data: value }
  });
};

export const resetSearchTripValue = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_SEARCH_TRIP_VALUE
  });
};
