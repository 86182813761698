/* eslint-disable no-alert */
import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";
import { toast } from "../../utils";

export const POST_NEW_CASH_EXPENSE_PENDING = "POST_NEW_CASH_EXPENSE_PENDING";
export const POST_NEW_CASH_EXPENSE_SUCCESS = "POST_NEW_CASH_EXPENSE_SUCCESS";
export const POST_NEW_CASH_EXPENSE_ERROR = "POST_NEW_CASH_EXPENSE_ERROR";

export const postNewCashExpense = (
  body: any,
  cb: () => void,
  id?: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_CASH_EXPENSE_PENDING });

    const formData = new FormData();

    Object.keys(body).map(attribute => {
      if (body[attribute] !== "") {
        formData.append(attribute, body[attribute]);
      } else {
        formData.append(attribute, "");
      }
    });
    id && formData.append("_method", "PUT");

    const res = id
      ? await API.putTripCA(formData, id, auth.token)
      : await API.postnewCashExpense(formData, auth.token);
    dispatch({
      type: POST_NEW_CASH_EXPENSE_SUCCESS,
      payload: { data: res }
    });
    cb();
    toast.success(i18n.t("cash.new.msg.toastSuccess"));
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewCashExpense(body, cb))));
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_CASH_EXPENSE_ERROR,
          payload: { data: err.response.data }
        });
      }
      dispatch({ type: POST_NEW_CASH_EXPENSE_ERROR });
    }
  }
};
