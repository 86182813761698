import {
  DELETE_CASH_ADVANCE_ERROR,
  DELETE_CASH_ADVANCE_PENDING,
  DELETE_CASH_ADVANCE_SUCCESS,
  DELETE_REIMBURSE_ERROR,
  DELETE_REIMBURSE_PENDING,
  DELETE_REIMBURSE_SUCCESS,
  GET_BILL_TRIP_ERROR,
  GET_BILL_TRIP_PENDING,
  GET_BILL_TRIP_SUCCESS,
  GET_BUDGET_REQ_ERROR,
  GET_BUDGET_REQ_PENDING,
  GET_BUDGET_REQ_SUCCESS,
  GET_CASH_ADVANCE_ERROR,
  GET_CASH_ADVANCE_PENDING,
  GET_CASH_ADVANCE_SUCCESS,
  GET_EXPENSE_CASH_ADVANCE_ERROR,
  GET_EXPENSE_CASH_ADVANCE_PENDING,
  GET_EXPENSE_CASH_ADVANCE_SUCCESS,
  GET_PER_DIEM_ERROR,
  GET_PER_DIEM_PENDING,
  GET_PER_DIEM_SUCCESS,
  GET_REIMBURSE_ERROR,
  GET_REIMBURSE_PENDING,
  GET_REIMBURSE_SUCCESS,
  GET_TRIP_EXPENSE_ERROR,
  GET_TRIP_EXPENSE_PENDING,
  GET_TRIP_EXPENSE_SUCCESS,
  LOGOUT_SUCCESS,
  POST_BILL_TRIP_ERROR,
  POST_BILL_TRIP_PENDING,
  POST_BILL_TRIP_SUCCESS
} from "../actions";
import { Action, NewExpenseTripState } from "../types";

const initialState: NewExpenseTripState = {
  isLoadingListPerDiem: false,
  listPerDiem: [],
  isLoadingListCashAdvance: false,
  listCashAdvance: [],
  isLoadingGetBudgetReq: false,
  listBudgetReq: {},
  isLoadingListReimburse: false,
  listReimburse: [],
  isLoadingListExpenseCashAdvance: false,
  listExpenseCashAdvance: [],
  isLoadingDeleteCA: false,
  isLoadingListTripExpense: false,
  listTripExpense: [],
  isLoadingDeleteReimburse: false,
  isLoadingListBillTrip: false,
  listBillTrip: [],
  isLoadingPostBillTrip: false,
  dataInvoiceTrip: null
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // get per diem
    case GET_PER_DIEM_PENDING:
      return {
        ...state,
        isLoadingListPerDiem: true,
        listPerDiem: []
      };
    case GET_PER_DIEM_SUCCESS:
      return {
        ...state,
        isLoadingListPerDiem: false,
        listPerDiem: payload.data
      };
    case GET_PER_DIEM_ERROR:
      return { ...state, isLoadingListPerDiem: false };

    // get cash advance
    case GET_CASH_ADVANCE_PENDING:
      return {
        ...state,
        isLoadingListCashAdvance: true,
        listCashAdvance: []
      };
    case GET_CASH_ADVANCE_SUCCESS:
      return {
        ...state,
        isLoadingListCashAdvance: false,
        listCashAdvance: payload.data
      };
    case GET_CASH_ADVANCE_ERROR:
      return { ...state, isLoadingListCashAdvance: false };

    // get budget req
    case GET_BUDGET_REQ_PENDING:
      return {
        ...state,
        isLoadingGetBudgetReq: true,
        listBudgetReq: {}
      };
    case GET_BUDGET_REQ_SUCCESS:
      return {
        ...state,
        isLoadingGetBudgetReq: false,
        listBudgetReq: payload.data
      };
    case GET_BUDGET_REQ_ERROR:
      return { ...state, isLoadingGetBudgetReq: false };

    case DELETE_REIMBURSE_PENDING:
      return { ...state, isLoadingDeleteReimburse: true };
    case DELETE_REIMBURSE_SUCCESS:
      return {
        ...state,
        isLoadingDeleteReimburse: false,
        listReimburse: state.listReimburse.filter(e => e.id !== payload.id)
      };
    case DELETE_REIMBURSE_ERROR:
      return { ...state, isLoadingDeleteReimburse: false };

    // get budget reimburse
    case GET_REIMBURSE_PENDING:
      return {
        ...state,
        isLoadingListReimburse: true,
        listReimburse: []
      };
    case GET_REIMBURSE_SUCCESS:
      return {
        ...state,
        isLoadingListReimburse: false,
        listReimburse: payload.data
      };
    case GET_REIMBURSE_ERROR:
      return { ...state, isLoadingListReimburse: false };

    // get expense cash advance
    case GET_EXPENSE_CASH_ADVANCE_PENDING:
      return {
        ...state,
        isLoadingListExpenseCashAdvance: true,
        listExpenseCashAdvance: []
      };
    case GET_EXPENSE_CASH_ADVANCE_SUCCESS:
      return {
        ...state,
        isLoadingListExpenseCashAdvance: false,
        listExpenseCashAdvance: payload.data.items,
        dataInvoiceTrip: payload.data
      };
    case GET_EXPENSE_CASH_ADVANCE_ERROR:
      return { ...state, isLoadingListExpenseCashAdvance: false };

    // Delete CA
    case DELETE_CASH_ADVANCE_PENDING:
      return { ...state, isLoadingDeleteCA: true };
    case DELETE_CASH_ADVANCE_SUCCESS:
      return {
        ...state,
        isLoadingDeleteCA: false,
        listCashAdvance: state.listCashAdvance.filter(
          item => item.id !== payload.id
        ),
        listExpenseCashAdvance: state.listExpenseCashAdvance.filter(
          item => item.id !== payload.id
        )
      };
    case DELETE_CASH_ADVANCE_ERROR:
      return { ...state, isLoadingDeleteCA: false };

    // get trip expense
    case GET_TRIP_EXPENSE_PENDING:
      return {
        ...state,
        isLoadingListTripExpense: true,
        listTripExpense: []
      };
    case GET_TRIP_EXPENSE_SUCCESS:
      return {
        ...state,
        isLoadingListTripExpense: false,
        listTripExpense: payload.data
      };
    case GET_TRIP_EXPENSE_ERROR:
      return { ...state, isLoadingListTripExpense: false };

    // get bill trip
    case GET_BILL_TRIP_PENDING:
      return {
        ...state,
        isLoadingListBillTrip: true,
        listBillTrip: []
      };
    case GET_BILL_TRIP_SUCCESS:
      return {
        ...state,
        isLoadingListBillTrip: false,
        listBillTrip: payload.data
      };
    case GET_BILL_TRIP_ERROR:
      return { ...state, isLoadingListBillTrip: false };

    // post bill trip
    case POST_BILL_TRIP_PENDING:
      return { ...state, isLoadingPostBillTrip: true };
    case POST_BILL_TRIP_SUCCESS:
      return { ...state, isLoadingPostBillTrip: false };
    case POST_BILL_TRIP_ERROR:
      return { ...state, isLoadingPostBillTrip: false };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
