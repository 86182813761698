import React, { useEffect, useState } from "react";

import { Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import moment from "moment";
import moment from "moment";
import { Card, Fixed, Header, Touchable } from "../../../components";
import { heightPercent, maskedMoney, widthPercent } from "../../../utils";
import styles from "./styles";
import { getLoanDetail } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { IMAGES } from "../../../configs";

const LoanDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeNav, setActiveNav] = useState(1);
  const [heightHeader, setHeightHeader] = useState(0);
  const _getHeighHeader = () => {
    return heightHeader;
  };

  const loanState = useSelector((state: Reducers) => state.loan);
  const { id } = useParams() as any;

  useEffect(() => {
    dispatch(getLoanDetail(id));
  }, [dispatch]);

  const renderStatus = (statusNumber: number) => {
    if (statusNumber === 3) {
      return t("loan.status.approved");
    }
    if (statusNumber === 5) {
      return t("loan.status.disbursed");
    }
    if (statusNumber === 6) {
      return t("loan.status.paid");
    }
    return t("loan.status.finished");
  };

  const _renderDetail = () => {
    return (
      <View style={styles.containerDetail}>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.loanSource")}
          </Text>
          <Text
            style={[styles.txtInfoLoan, { fontWeight: "bold", width: "65%" }]}
          >
            {loanState.loanDetail &&
              loanState.loanDetail.invoiceRequest.purpose}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.loanAmount")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {`IDR ${maskedMoney(
              loanState.loanDetail && loanState.loanDetail.amount
            )}`}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.feeAdmin")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {`IDR ${
              (loanState.loanDetail &&
                maskedMoney(
                  loanState.loanDetail.loanSource.fee_administration
                )) ||
              "0"
            }`}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.feeProv")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {`IDR ${maskedMoney(
              Number(loanState.loanDetail && loanState.loanDetail.amount) -
                Number(
                  loanState.loanDetail &&
                    loanState.loanDetail.invoiceRequest &&
                    loanState.loanDetail.invoiceRequest.amount
                )
            )}`}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.whtYouGet")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {`IDR ${maskedMoney(
              loanState.loanDetail &&
                loanState.loanDetail.invoiceRequest &&
                loanState.loanDetail.invoiceRequest.amount
            )}`}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.whtYouPay")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {`IDR ${
              loanState.loanDetail &&
              maskedMoney(
                Number(loanState.loanDetail.amount) +
                  Number(loanState.loanDetail.loanSource.fee_administration)
              )
            }`}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.requested")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {moment(
              loanState.loanDetail && loanState.loanDetail.created_at
            ).format("DD MMMM YYYY")}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.fullyDisbursed")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {loanState.loanDetail &&
            loanState.loanDetail.invoiceRequest.status === 6
              ? moment(loanState.loanDetail.invoiceRequest.updated_at).format(
                  "DD MMMM YYYY"
                )
              : "-"}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.fullyPaid")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {loanState.loanDetail &&
            loanState.loanDetail.invoiceRepayment &&
            loanState.loanDetail.invoiceRepayment.status === 6
              ? moment(loanState.loanDetail.invoiceRepayment.updated_at).format(
                  "DD MMMM YYYY"
                )
              : "-"}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.completed")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {(loanState.loanDetail &&
              loanState.loanDetail.paid_at &&
              moment(loanState.loanDetail.paid_at).format("DD MMMM YYYY")) ||
              "-"}
          </Text>
        </View>
        <View style={styles.infoLoanTextWrap}>
          <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
            {t("loan.repaymentCount")}
          </Text>
          <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
            {(loanState.loanDetail &&
              loanState.loanDetail.invoiceRepayment &&
              loanState.loanDetail.invoiceRepayment.payments.length) ||
              "-"}
          </Text>
        </View>
      </View>
    );
  };

  const _renderDisburse = () => {
    return (
      <View style={[styles.containerDetail, { width: widthPercent(90) }]}>
        {loanState.loanDetail &&
        loanState.loanDetail.invoiceRequest &&
        loanState.loanDetail.invoiceRequest.payments.length > 0 ? (
          loanState.loanDetail &&
          loanState.loanDetail.invoiceRequest &&
          loanState.loanDetail.invoiceRequest.payments.map(
            (item: any, index: any) => (
              <Card key={index}>
                <View
                  style={{
                    width: "100%",
                    paddingVertical: 10,
                    alignItems: "center"
                  }}
                >
                  {item.payment.file && (
                    <View
                      style={[
                        styles.infoLoanTextWrap,
                        {
                          flexDirection: "row-reverse"
                        }
                      ]}
                    >
                      <Touchable
                        onPress={() =>
                          window.open(JSON.parse(item.payment.file).url)
                        }
                      >
                        <View>
                          <Card>
                            <Image
                              source={IMAGES.document}
                              style={{ width: 16, height: 16, margin: 4 }}
                            />
                          </Card>
                        </View>
                      </Touchable>
                    </View>
                  )}

                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.date")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {moment(item.date).format("DD MMMM YYYY")}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.invoiceNumber")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {item.payment.number}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.due")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(loanState.loanDetail.amount)}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.feeProv")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(
                        Number(loanState.loanDetail.amount) -
                          Number(loanState.loanDetail.invoiceRequest.amount)
                      )}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.fullyPaid")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(
                        loanState.loanDetail.invoiceRequest.amount
                      )}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.remainder")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(
                        Number(loanState.loanDetail.invoiceRequest.total) -
                          Number(
                            loanState.loanDetail.invoiceRequest.total_paid
                          ),
                        true
                      )}`}
                    </Text>
                  </View>
                </View>
              </Card>
            )
          )
        ) : (
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text style={{ alignSelf: "center", color: "grey" }}>
              {t("loan.empty")}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const _renderRepayment = () => {
    return (
      <View style={[styles.containerDetail, { width: widthPercent(90) }]}>
        {loanState.loanDetail &&
        loanState.loanDetail.invoiceRepayment &&
        loanState.loanDetail.invoiceRepayment.items.length > 0 ? (
          loanState.loanDetail &&
          loanState.loanDetail.invoiceRepayment &&
          loanState.loanDetail.invoiceRepayment.items.map(
            (item: any, index: any) => (
              <Card key={index} cardStyle={{ marginBottom: 4 }}>
                <View
                  style={{
                    width: "100%",
                    paddingVertical: 10,
                    alignItems: "center"
                  }}
                >
                  {loanState.loanDetail.invoiceRepayment.payments[index].payment
                    .file && (
                    <View
                      style={[
                        styles.infoLoanTextWrap,
                        {
                          flexDirection: "row-reverse"
                        }
                      ]}
                    >
                      <Touchable
                        onPress={() =>
                          window.open(
                            JSON.parse(
                              loanState.loanDetail.invoiceRepayment.payments[
                                index
                              ].payment.file
                            ).url
                          )
                        }
                      >
                        <View>
                          <Card>
                            <Image
                              source={IMAGES.document}
                              style={{ width: 16, height: 16, margin: 4 }}
                            />
                          </Card>
                        </View>
                      </Touchable>
                    </View>
                  )}
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.date")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {moment(item.created_at).format("DD MMMM YYYY")}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.invoiceNumber")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {
                        loanState.loanDetail.invoiceRepayment.payments[index]
                          .payment.number
                      }
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.due")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${
                        index === 0
                          ? maskedMoney(
                              loanState.loanDetail.invoiceRepayment.amount,
                              true
                            )
                          : maskedMoney(
                              Number(loanState.loanDetail.amount) -
                                Number(loanState.loanDetail.invoiceRepayment),
                              true
                            )
                      }`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.totalPaid")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {loanState.loanDetail.invoiceRepayment.payments[index]
                        .payment.status === 2
                        ? `IDR ${maskedMoney(0)}`
                        : `IDR ${maskedMoney(
                            loanState.loanDetail.invoiceRepayment.payments[
                              index
                            ].total,
                            true
                          )}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.feePaid")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {loanState.loanDetail.invoiceRepayment.payments[index]
                        .payment.status === 2
                        ? `IDR ${maskedMoney(0)}`
                        : `IDR ${maskedMoney(
                            loanState.loanDetail.invoiceRequest.amount
                          )}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "35%" }]}>
                      {t("loan.feePaid")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {loanState.loanDetail.invoiceRepayment.payments[index]
                        .payment.status === 2
                        ? `IDR ${maskedMoney(0)}`
                        : `IDR ${maskedMoney(
                            index === 0
                              ? loanState.loanDetail.invoiceRepayment.amount -
                                  loanState.loanDetail.invoiceRepayment
                                    .payments[index].total
                              : Number(
                                  loanState.loanDetail.invoiceRepayment.total
                                ) -
                                  Number(
                                    loanState.loanDetail.invoiceRepayment
                                      .total_paid
                                  ),
                            true
                          )}`}
                    </Text>
                  </View>
                </View>
              </Card>
            )
          )
        ) : (
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text style={{ alignSelf: "center", color: "grey" }}>
              {t("loan.empty")}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const _renderContent = () => {
    if (activeNav === 1) {
      return _renderDetail();
    }
    if (activeNav === 2) {
      return _renderDisburse();
    }
    return _renderRepayment();
  };

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header
            title={t("loan.loanDetail")}
            subtitle={
              loanState.loanDetail && loanState.loanDetail.invoiceRequest.number
            }
            label={renderStatus(
              loanState.loanDetail && loanState.loanDetail.status
            )}
            goBack={() => history.push("/loan/user")}
          />
          <View style={{ alignItems: "center" }}>
            <View style={styles.wrapLoanNav}>
              <View style={styles.loanNav}>
                <View
                  style={[
                    { width: "33%" },
                    activeNav === 1 ? styles.active : null
                  ]}
                >
                  <Touchable onPress={() => setActiveNav(1)}>
                    <View style={{ width: "100%", alignItems: "center" }}>
                      <Text style={styles.btnNav}>Detail</Text>
                    </View>
                  </Touchable>
                </View>
                <View
                  style={[
                    { width: "33%" },
                    activeNav === 2 ? styles.active : null
                  ]}
                >
                  <Touchable onPress={() => setActiveNav(2)}>
                    <View style={{ width: "100%", alignItems: "center" }}>
                      <Text style={styles.btnNav}>{t("loan.disburse")}</Text>
                    </View>
                  </Touchable>
                </View>
                <View
                  style={[
                    { width: "33%" },
                    activeNav === 3 ? styles.active : null
                  ]}
                >
                  <Touchable onPress={() => setActiveNav(3)}>
                    <View style={{ width: "100%", alignItems: "center" }}>
                      <Text style={styles.btnNav}>
                        {t("loan.dataRepaymentHistory")}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
          </View>
        </Fixed>
        <View
          style={{
            marginTop: _getHeighHeader(),
            minHeight: heightPercent(90) - heightHeader
          }}
        >
          <View style={{ alignSelf: "center" }}>{_renderContent()}</View>
        </View>
      </View>
    </Switch>
  );
};

export default LoanDetail;
