import React, { useState } from "react";
import { FlatList, Text, View } from "react-native";

import styles from "./styles";
import Card from "../../../pages/NewTrip/components/Card";
import { Touchable } from "../../index";

const FilterPrice = () => {
  const [prices, setPrices] = useState([
    { price: "Price Low - High", active: false },
    { price: "Price High - Low", active: false }
  ]);
  const [inputPrice, setInputPrice] = useState([]);
  const handlePriceInput = (index: number, price: string) => {
    const priceData = prices;

    if (priceData[index].active) {
      setInputPrice(inputPrice.filter(item => item !== price));
    } else {
      const input: any = [...inputPrice, price];
      setInputPrice(input);
    }
    priceData[index].active = !priceData[index].active;
    setPrices(priceData);
  };

  const render = (item: any, index: number) => {
    return (
      <View
        style={[styles.cardItem, item.active && { backgroundColor: "#50b1b1" }]}
      >
        <Touchable onPress={() => handlePriceInput(index, item.price)}>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Text
              style={
                item.active
                  ? [styles.textItem, { color: "#fff" }]
                  : styles.textItem
              }
            >
              {item.price}
            </Text>
          </View>
        </Touchable>
      </View>
    );
  };
  return (
    <View style={styles.content}>
      <View>
        <Card cardStyle={styles.cardStyle}>
          <>
            <Text style={styles.title}>Filter</Text>
            <FlatList
              style={{}}
              data={prices}
              renderItem={({ item, index }) => render(item, index)}
            />
          </>
        </Card>
      </View>
      <View style={styles.buttonClose}>
        <Touchable>
          <Text style={styles.textClose}>CLOSE</Text>
        </Touchable>
      </View>
    </View>
  );
};
export default FilterPrice;
