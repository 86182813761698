import React, { useCallback, useEffect, useState } from "react";
import { Text, View } from "react-native";
import Switch from "react-switch";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  copyWritings,
  documentTitle,
  encodeBase64,
  openPreviewBase64,
  queryString,
  toast
} from "../../../../utils";
import {
  FileUpload,
  Fixed,
  Header,
  Input,
  Select,
  TextArea,
  Touchable,
  Upload,
  WrapContent
} from "../../../../components";
import styles from "./styles";
import { Reducers } from "../../../../redux/types";
import {
  getFinanceCategory,
  getInvoiceDetail,
  getItemDetail,
  getListBookingFlight,
  getListBookingHotel,
  getTermDataActivity,
  handleFormAcitivy,
  postInvoiceBooking,
  putInvoiceBooking
} from "../../../../redux/actions";

declare const alert: (param: any) => void;

interface InitForm {
  title: string;
  category_id: string;
  description: string;
  amount: string;
  billable: boolean;
  files: any;
}

const initForm: InitForm = {
  title: "",
  category_id: "",
  description: "",
  amount: "",
  billable: false,
  files: null
};

interface Props {
  type: "bookingHotels" | "bookingFlights";
}

const NewInvoice = ({ type }: Props) => {
  documentTitle("Detail Booking - New Invoice");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams() as any;
  const { search } = history.location;
  const [listFileName, setListFileName]: any = useState([]);

  let idInvoice: any;
  if (history.location.search) {
    idInvoice = queryString(history.location.search).idInvoice;
  }

  const [heightFooter, setHeightFooter] = useState(0);
  const {
    authState,
    tripState,
    newTripState,
    bookingState,
    invoiceState
  } = useSelector(
    (state: Reducers) => ({
      authState: state.auth,
      tripState: state.trip,
      newTripState: state.newTrip,
      bookingState: state.booking,
      invoiceState: state.invoice
    }),
    shallowEqual
  );

  const [form, setForm]: any = useState(initForm);

  useEffect(() => {
    if (!search && tripState.activity.getData) {
      dispatch(getTermDataActivity());
    }
  }, [dispatch, tripState.activity.getData]);

  useEffect(() => {
    dispatch(getFinanceCategory());
    if (idInvoice !== "0" && idInvoice !== undefined) {
      dispatch(getInvoiceDetail(idInvoice));
    }
    if (
      bookingState.listBookingHotel.length === 0 &&
      type === "bookingHotels" &&
      search &&
      queryString(search).tripId
    ) {
      dispatch(getListBookingHotel(queryString(search).tripId));
    }
    if (
      bookingState.listBookingFlight.length === 0 &&
      type === "bookingFlights" &&
      search &&
      queryString(search).tripId
    ) {
      dispatch(getListBookingFlight(queryString(search).tripId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (invoiceState.invoiceDetail && invoiceState.invoiceDetail.items[0].id) {
      dispatch(getItemDetail(invoiceState.invoiceDetail.items[0].id));
    }
  }, [invoiceState.invoiceDetail]);

  useEffect(() => {
    if (invoiceState.item && idInvoice) {
      setForm({
        ...form,
        title: invoiceState.item.item.title,
        category_id: invoiceState.item.category.id,
        description: invoiceState.item.item.description,
        amount: invoiceState.item.item.amount,
        files: JSON.parse(invoiceState.item.item.file) || null,
        billable: invoiceState.item.billable
      });
    }
  }, [invoiceState.item]);

  useEffect(() => {
    if (!search && idInvoice && !tripState.activity.getData) {
      const data = tripState.activity.data[type][id].invoices[idInvoice];
      setForm({
        title: data.title,
        category_id: data.category_id,
        description: data.description,
        amount: data.amount,
        billable: data.billable,
        files: data.files
      });
    }
  }, [
    idInvoice,
    tripState.activity.data,
    type,
    id,
    tripState.activity.getData
  ]);

  const _setForm = useCallback(
    (
      formName:
        | "title"
        | "category_id"
        | "description"
        | "amount"
        | "billable"
        | "files",
      formValue: any
    ) => {
      setForm({ ...form, [formName]: formValue });
    },
    [form]
  );

  const _uploadDocument = useCallback(
    async (e: any) => {
      const files = [...e.target.files];
      let result: any = [];
      setListFileName([...listFileName, ...files]);
      if (files[0].size > authState.myConfig.max_size) {
        alert(
          `file to big! maximum size allowed ${
            authState.myConfig.max_size * 0.000001
          }MB.`
        );
      } else {
        files.map(async (item, index) => {
          const base64 = await encodeBase64(item);
          result = [...result, base64];

          if (index === files.length - 1) {
            const mergeData = [
              ...result,
              ...((tripState.activity.data.new_activity &&
                tripState.activity.data.new_activity.files) ||
                [])
            ];
            _setForm(
              "files",
              mergeData.filter(
                (res, i) => mergeData.findIndex(es => es === res) === i
              )
            );
          }
          return true;
        });
      }
    },
    [_setForm, form, authState.myConfig.max_size]
  );

  const _handleSubmit = useCallback(() => {
    if (
      search &&
      bookingState.listBookingHotel.length > 0 &&
      type === "bookingHotels"
    ) {
      const body = {
        ...form,
        invoice_id: idInvoice,
        booking_id: bookingState.listBookingHotel[id].id,
        currency_code: "IDR",
        quantity: 1,
        client_timezone: "Asia/Jakarta"
      };

      if (idInvoice && idInvoice !== 0) {
        dispatch(
          putInvoiceBooking(body, invoiceState.invoiceDetail.items[0].id, () =>
            history.goBack()
          )
        );
      } else {
        dispatch(postInvoiceBooking(body, () => history.goBack()));
      }
    } else if (
      search &&
      bookingState.listBookingFlight.length > 0 &&
      type === "bookingFlights"
    ) {
      const body = {
        ...form,
        invoice_id: idInvoice,
        booking_id: bookingState.listBookingFlight[id].id,
        currency_code: "IDR",
        quantity: 1,
        client_timezone: "Asia/Jakarta"
      };
      if (idInvoice && idInvoice !== 0) {
        dispatch(
          putInvoiceBooking(body, invoiceState.invoiceDetail.items[0].id, () =>
            history.goBack()
          )
        );
      } else {
        dispatch(postInvoiceBooking(body, () => history.goBack()));
      }
    } else if (idInvoice) {
      const result = [...tripState.activity.data[type]];
      result[id].invoices[idInvoice] = {
        ...form,
        currency_code: "IDR",
        quantity: 1,
        client_timezone: "Asia/Jakarta"
      };
      dispatch(handleFormAcitivy(type, result, "", () => history.goBack()));
    } else {
      const result = [...tripState.activity.data[type]];
      result[Number(id.charAt(0))] = {
        ...result[Number(id.charAt(0))],
        invoices: [
          ...result[Number(id.charAt(0))].invoices,
          {
            ...form,
            currency_code: "IDR",
            quantity: 1,
            client_timezone: "Asia/Jakarta"
          }
        ]
      };

      dispatch(handleFormAcitivy(type, result, "", () => history.goBack()));
    }
    toast.success(t("activity.invoice.addSuccess"));
  }, [dispatch, tripState.activity.data, type, form, history, id, idInvoice]);

  return (
    <View style={styles.container}>
      <Header
        title={
          idInvoice
            ? t("activity.invoice.detailInvoice")
            : t("activity.invoice.addInvoice")
        }
      />

      <WrapContent isLoading={tripState.activity.isLoading}>
        <View style={[styles.body, { marginBottom: heightFooter }]}>
          <View style={styles.row}>
            <Text style={styles.labelInput}>
              {t("activity.detail.subject")}
            </Text>
            <Input
              style={styles.input}
              inputStyle={styles.inputStyle}
              value={form.title}
              onChangeText={e => _setForm("title", e)}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.labelInput}>
              {copyWritings("finance_category")}
            </Text>
            <View style={styles.card}>
              {newTripState.isLoadingPopulateCategory ? (
                <Text style={styles.labelInput}>Loading...</Text>
              ) : (
                <Select
                  listValue={newTripState.listPopulateCategory}
                  style={{ width: "100%", opacity: form.category_id ? 1 : 0.5 }}
                  value={form.category_id}
                  onChange={e => _setForm("category_id", e.target.value)}
                />
              )}
            </View>
          </View>
          <View style={styles.row}>
            <Text style={styles.labelInput}>
              {t("activity.new.description")}
            </Text>
            <TextArea
              bold
              value={form.description}
              onChange={e => _setForm("description", e.target.value)}
              withPadding
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.labelInput}>{t("activity.new.amount")}</Text>
            <Input
              style={[
                styles.input,
                { flexDirection: "row", alignItems: "center" }
              ]}
              inputStyle={[styles.inputStyle, { textAlign: "right", flex: 1 }]}
              leftComponent={<Text style={styles.labelIdr}>IDR</Text>}
              value={form.amount}
              onChangeText={e => _setForm("amount", e)}
            />
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              {form.files ? (
                <FileUpload
                  name={form.files.title || form.files[0].name}
                  style={{ marginBottom: 12, width: "80%" }}
                  onPress={async () => {
                    if (form.files.url) {
                      window.open(`${form.files.url}`, "_blank");
                    } else {
                      const encodedFile: any = await encodeBase64(
                        form.files[0]
                      );
                      openPreviewBase64(encodedFile);
                    }
                  }}
                />
              ) : null}
              <Upload onChange={_uploadDocument} />
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                flexDirection: "row"
              }}
            >
              <Switch
                onChange={() => _setForm("billable", !form.billable)}
                checked={form.billable}
                onColor="#50b1b1"
                onHandleColor="#fffff7"
                handleDiameter={19}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                height={19}
                width={34}
              />

              <Text
                style={[styles.bilable, { opacity: form.billable ? 1 : 0.25 }]}
              >
                {t("activity.invoice.billable")}
              </Text>
            </View>
          </View>
        </View>

        <Fixed getHeight={e => setHeightFooter(e)} position="bottom">
          <View style={styles.wrapButton}>
            <Touchable onPress={_handleSubmit}>
              <View style={styles.button}>
                <Text style={styles.textButton}>
                  {idInvoice
                    ? t("activity.booking.save")
                    : t("activity.new.add")}
                </Text>
              </View>
            </Touchable>
          </View>
        </Fixed>
      </WrapContent>
    </View>
  );
};

export default NewInvoice;
