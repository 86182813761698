import React, { useCallback, useEffect } from "react";
import { Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Touchable, WrapContent } from "../../../../../components";
import styles from "./styles";
import { Reducers } from "../../../../../redux/types";
import {
  deleteInvoiceBooking,
  getInvoiceBookingList,
  getTripById,
  handleFormAcitivy
} from "../../../../../redux/actions";
import {
  convertDate,
  documentTitle,
  maskedMoney,
  toast
} from "../../../../../utils";
import { COLORS, ICONS, IMAGES } from "../../../../../configs";

interface Props {
  id: number;
  type: "bookingHotels" | "bookingFlights";
  tripId?: string | null;
}

const Invoice = ({ id, type, tripId }: Props) => {
  documentTitle("Detail Booking - Invoice");
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = history.location;

  const tripState = useSelector((state: Reducers) => state.trip);
  const bookingState = useSelector((state: Reducers) => state.booking);
  const data =
    tripState.activity.data[type] && tripState.activity.data[type][id];

  const _handleDelete = useCallback(
    (index: number) => {
      if (tripState.activity.data[type]) {
        const result = [...tripState.activity.data[type]];
        result[id].invoices = result[id].invoices.filter(
          (e: any, i: number) => i !== index
        );
        dispatch(handleFormAcitivy(type, result));
        toast.error(t("activity.booking.deleted"));
      }
    },
    [tripState.activity.data, dispatch, id, type]
  );

  useEffect(() => {
    if (
      !tripState.tripData &&
      tripId !== "undefined" &&
      tripId !== "hotel-detail"
    ) {
      dispatch(getTripById(tripId));
    }
  }, [tripState.tripData]);

  useEffect(() => {
    if (
      type === "bookingFlights" &&
      search &&
      tripId &&
      tripId !== "hotel-detail" &&
      tripId !== "flight-detail" &&
      bookingState.listBookingFlight.length > 0
    ) {
      dispatch(getInvoiceBookingList(bookingState.listBookingFlight[id].id));
    } else if (
      type === "bookingHotels" &&
      search &&
      tripId &&
      tripId !== "hotel-detail" &&
      tripId !== "flight-detail" &&
      bookingState.listBookingHotel.length > 0
    ) {
      dispatch(getInvoiceBookingList(bookingState.listBookingHotel[id].id));
    }
  }, [dispatch, bookingState.listBookingHotel, bookingState.listBookingFlight]);

  return (
    <WrapContent isLoading={tripState.activity.isLoading}>
      <View style={{ alignItems: "center" }}>
        {!data && bookingState.listInvoiceBooking.length > 0 && (
          <Text style={styles.invoiceNumber}>
            {bookingState.listInvoiceBooking[0].invoice.purpose}
          </Text>
        )}

        <View
          style={[
            styles.container,
            (!data || data.invoices.length === 0) && {
              justifyContent: "center",
              alignItems: "center"
            }
          ]}
        >
          {!data &&
            bookingState.listInvoiceBooking.length > 0 &&
            bookingState.listInvoiceBooking.map((item: any, index: number) => (
              <View key={index} style={{ marginBottom: 12, width: "100%" }}>
                {((tripState.tripData && tripState.tripData.status === 1) ||
                  tripState.tripData.status === 4) &&
                tripState.tripData &&
                new Date(tripState.tripData.finished_at) > new Date() ? (
                  <Touchable
                    onPress={() =>
                      history.push(
                        `/newtrip/newinvoice/${
                          type === "bookingHotels" ? "hotel" : "flight"
                        }/${id}?idInvoice=${item.invoice.id}&tripId=${tripId}`,
                        { data: item }
                      )
                    }
                  >
                    <View style={styles.card}>
                      <View style={styles.cardDesc}>
                        <View style={styles.row}>
                          <View style={styles.col1}>
                            <Text style={styles.title}>Date</Text>
                          </View>
                          <View style={styles.col2}>
                            <Text
                              style={[styles.desc, { fontWeight: "bold" }]}
                              numberOfLines={1}
                            >
                              {convertDate(
                                item.invoice.created_at,
                                "ddd, DD MMM YYYY"
                              )}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.col1}>
                            <Text style={styles.title}>
                              {t("activity.detail.subject")}
                            </Text>
                          </View>
                          <View style={styles.col2}>
                            <Text
                              style={[styles.desc, { fontWeight: "bold" }]}
                              numberOfLines={1}
                            >
                              {item.djurneeItems[0].item.title}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.col1}>
                            <Text style={styles.title}>
                              {t("activity.new.description")}
                            </Text>
                          </View>
                          <View style={styles.col2}>
                            <Text style={styles.desc} numberOfLines={1}>
                              {item.djurneeItems[0].item.description || "-"}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.row}>
                          <View style={styles.col1}>
                            <Text style={styles.title}>
                              {t("activity.invoice.billable")}
                            </Text>
                          </View>
                          <View style={styles.col2}>
                            <Text
                              style={[
                                styles.desc,
                                {
                                  color: item.djurneeItems[0].billable
                                    ? COLORS.green
                                    : COLORS.orange,
                                  fontWeight: "bold"
                                }
                              ]}
                              numberOfLines={1}
                            >
                              {item.djurneeItems[0].billable
                                ? t("activity.invoice.yes")
                                : t("activity.invoice.no")}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View style={styles.footerCard}>
                        <Text
                          style={[
                            styles.textNom,
                            {
                              color:
                                (tripState.tripData &&
                                  tripState.tripData.status === 1) ||
                                tripState.tripData.status === 4
                                  ? COLORS.white
                                  : COLORS.green
                            }
                          ]}
                        >
                          IDR
                          <Text style={{ fontWeight: "bold", color: "white" }}>
                            {` ${maskedMoney(item.invoice.total)}`}
                          </Text>
                        </Text>
                      </View>
                      <>
                        <View style={styles.wrapButtonTrash}>
                          <Touchable
                            onPress={e => {
                              e.stopPropagation();
                              dispatch(
                                deleteInvoiceBooking(
                                  item.djurneeItems[0].item.id,
                                  () =>
                                    dispatch(
                                      getInvoiceBookingList(
                                        type === "bookingFlights"
                                          ? bookingState.listBookingFlight[id]
                                              .id
                                          : bookingState.listBookingHotel[id].id
                                      )
                                    )
                                )
                              );
                              toast.error(t("activity.booking.deleted"));
                            }}
                          >
                            <View style={styles.buttonTrash}>
                              <Image
                                source={ICONS.trash}
                                style={{ width: 12, height: 12 }}
                              />
                            </View>
                          </Touchable>
                        </View>
                        {item.djurneeItems[0].item.file && (
                          <View style={[styles.wrapButtonTrash, { right: 40 }]}>
                            <Touchable
                              onPress={e => {
                                e.stopPropagation();
                                window.open(
                                  `${
                                    JSON.parse(item.djurneeItems[0].item.file)
                                      .url
                                  }`,
                                  "_blank"
                                );
                              }}
                            >
                              <View style={styles.buttonTrash}>
                                <Image
                                  source={IMAGES.picAttach}
                                  style={{ width: 12, height: 12 }}
                                />
                              </View>
                            </Touchable>
                          </View>
                        )}
                      </>
                    </View>
                  </Touchable>
                ) : (
                  <View style={styles.line}>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.booking.date")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text
                          style={[styles.desc, { fontWeight: "bold" }]}
                          numberOfLines={1}
                        >
                          {convertDate(
                            item.invoice.created_at,
                            "ddd, DD MMM YYYY"
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.new.subject")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text
                          style={[styles.desc, { fontWeight: "bold" }]}
                          numberOfLines={1}
                        >
                          {item.djurneeItems[0].item.title}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.new.description")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text style={styles.desc} numberOfLines={1}>
                          {item.djurneeItems[0].item.description || "-"}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.col1}>
                        <Text style={styles.title}>
                          {t("activity.invoice.billable")}
                        </Text>
                      </View>
                      <View style={styles.col2}>
                        <Text
                          style={[
                            styles.desc,
                            {
                              color: item.djurneeItems[0].billable
                                ? COLORS.green
                                : COLORS.orange,
                              fontWeight: "bold"
                            }
                          ]}
                          numberOfLines={1}
                        >
                          {item.djurneeItems[0].billable
                            ? t("activity.invoice.yes")
                            : t("activity.invoice.no")}
                        </Text>
                      </View>
                    </View>
                    <Text style={[styles.textNom, styles.textSettled]}>
                      IDR
                      <Text style={{ fontWeight: "bold", color: COLORS.green }}>
                        {` ${maskedMoney(item.invoice.total)}`}
                      </Text>
                    </Text>
                    {item.djurneeItems[0].item.file && (
                      <View style={[styles.wrapButtonTrash, { top: 0 }]}>
                        <Touchable
                          onPress={e => {
                            e.stopPropagation();
                            window.open(
                              `${
                                JSON.parse(item.djurneeItems[0].item.file).url
                              }`,
                              "_blank"
                            );
                          }}
                        >
                          <View style={styles.buttonTrash}>
                            <Image
                              source={IMAGES.picAttach}
                              style={{ width: 12, height: 12 }}
                            />
                          </View>
                        </Touchable>
                      </View>
                    )}
                  </View>
                )}
              </View>
            ))}
          {data &&
            data.invoices.map((item: any, index: number) => (
              <View key={index} style={{ marginBottom: 12, width: "100%" }}>
                <Touchable
                  onPress={() =>
                    history.push(
                      `/newtrip/newinvoice/${
                        type === "bookingHotels" ? "hotel" : "flight"
                      }/${id}?idInvoice=${index}`
                    )
                  }
                >
                  <View style={styles.card}>
                    <View style={styles.cardDesc}>
                      <View style={styles.row}>
                        <View style={styles.col1}>
                          <Text style={styles.title}>
                            {t("activity.new.subject")}
                          </Text>
                        </View>
                        <View style={styles.col2}>
                          <Text
                            style={[styles.desc, { fontWeight: "bold" }]}
                            numberOfLines={1}
                          >
                            {item.title}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.row}>
                        <View style={styles.col1}>
                          <Text style={styles.title}>
                            {t("activity.new.description")}
                          </Text>
                        </View>
                        <View style={styles.col2}>
                          <Text style={styles.desc} numberOfLines={1}>
                            {item.description}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.row}>
                        <View style={styles.col1}>
                          <Text style={styles.title}>
                            {t("activity.invoice.billable")}
                          </Text>
                        </View>
                        <View style={styles.col2}>
                          <Text
                            style={[
                              styles.desc,
                              {
                                color: item.billable
                                  ? COLORS.green
                                  : COLORS.orange,
                                fontWeight: "bold"
                              }
                            ]}
                            numberOfLines={1}
                          >
                            {item.billable
                              ? t("activity.invoice.yes")
                              : t("activity.invoice.no")}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.footerCard}>
                      <Text style={styles.textNom}>
                        IDR
                        <Text style={{ fontWeight: "bold", color: "white" }}>
                          {` ${maskedMoney(item.amount)}`}
                        </Text>
                      </Text>
                    </View>

                    <View style={styles.wrapButtonTrash}>
                      <Touchable
                        onPress={e => {
                          e.stopPropagation();
                          _handleDelete(index);
                        }}
                      >
                        <View style={styles.buttonTrash}>
                          <Image
                            source={ICONS.trash}
                            style={{ width: 12, height: 12 }}
                          />
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </Touchable>
              </View>
            ))}

          <View style={{ alignItems: "center" }}>
            <View style={styles.wrapButtonAdd}>
              {(!data &&
                tripState.tripData &&
                (tripState.tripData.status === 1 ||
                  tripState.tripData.status === 4) &&
                tripState.tripData &&
                new Date(tripState.tripData.finished_at) > new Date()) ||
              tripId === "undefined" ||
              tripId === "hotel-detail" ||
              tripId === "flight-detail" ? (
                <Touchable
                  onPress={() =>
                    history.push(
                      `/newtrip/newinvoice/${
                        type === "bookingHotels" ? "hotel" : "flight"
                      }/${id}${search}&tripId=${tripId}`
                    )
                  }
                >
                  <View style={styles.buttonAdd}>
                    <Image source={ICONS.plus} style={styles.iconPlus} />
                    <Text style={styles.textAddAdd}>
                      {t("activity.invoice.addInvoice")}
                    </Text>
                  </View>
                </Touchable>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </WrapContent>
  );
};

export default Invoice;
