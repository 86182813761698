import React from "react";
import { Text, View } from "react-native";

const Booking = () => {
  return (
    <View>
      <Text>Booking</Text>
    </View>
  );
};

export default Booking;
