import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_DETAIL_BOOKING_HOTEL_PENDING =
  "GET_DETAIL_BOOKING_HOTEL_PENDING";
export const GET_DETAIL_BOOKING_HOTEL_SUCCESS =
  "GET_DETAIL_BOOKING_HOTEL_SUCCESS";
export const GET_DETAIL_BOOKING_HOTEL_ERORR = "GET_DETAIL_BOOKING_HOTEL_ERORR";
export const GET_DETAIL_BOOKING_FLIGHT_CLEAR =
  "GET_DETAIL_BOOKING_FLIGHT_CLEAR";

export const GET_DETAIL_BOOKING_FLIGHT_PENDING =
  "GET_DETAIL_BOOKING_FLIGHT_PENDING";
export const GET_DETAIL_BOOKING_FLIGHT_SUCCESS =
  "GET_DETAIL_BOOKING_FLIGHT_SUCCESS";
export const GET_DETAIL_BOOKING_FLIGHT_ERORR =
  "GET_DETAIL_BOOKING_FLIGHT_ERORR";

export const PUT_BOOKING_FLIGHT_PENDING = "PUT_BOOKING_FLIGHT_PENDING";
export const PUT_BOOKING_FLIGHT_SUCCESS = "PUT_BOOKING_FLIGHT_SUCCESS";
export const PUT_BOOKING_FLIGHT_ERORR = "GET_DETAIL_BOOKING_FLIGHT_ERORR";

export const SET_FORM_DETAIL_BOOKING_FLIGHT = "SET_FORM_DETAIL_BOOKING_FLIGHT";

export const getDetailBookingHotel = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DETAIL_BOOKING_HOTEL_PENDING });

    const res = await API.getDetailBookingHotel(id, auth.token);
    dispatch({
      type: GET_DETAIL_BOOKING_HOTEL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDetailBookingHotel(id))));
      } else {
        dispatch({
          type: GET_DETAIL_BOOKING_HOTEL_ERORR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_DETAIL_BOOKING_HOTEL_ERORR });
  }
};

export const getDetailBookingFlight = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DETAIL_BOOKING_FLIGHT_PENDING });

    const res = await API.getDetailBookingFlight(id, auth.token);
    dispatch({
      type: GET_DETAIL_BOOKING_FLIGHT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDetailBookingFlight(id))));
      } else {
        dispatch({
          type: GET_DETAIL_BOOKING_FLIGHT_ERORR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_DETAIL_BOOKING_FLIGHT_ERORR });
  }
};

export const clearGetDetailBookingFlight = () => async (dispatch: Dispatch) =>
  dispatch({ type: GET_DETAIL_BOOKING_FLIGHT_CLEAR });

interface DataSetForm {
  formName: string;
  value: string | number;
}

export const setFormDetailBookingFlight = (data: DataSetForm) => (
  dispatch: Dispatch
) => {
  dispatch({ type: SET_FORM_DETAIL_BOOKING_FLIGHT, payload: { data } });
};

export const putBookingFlight = (id: string, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth, detailBooking } = getState();
    dispatch({ type: PUT_BOOKING_FLIGHT_PENDING });

    const res = await API.putBookingFlight(
      id,
      detailBooking.formBookingFlight,
      auth.token
    );
    dispatch({
      type: PUT_BOOKING_FLIGHT_SUCCESS,
      payload: { data: res.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putBookingFlight(id, cb))));
      } else {
        dispatch({
          type: PUT_BOOKING_FLIGHT_ERORR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_BOOKING_FLIGHT_ERORR });
  }
};
