import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  formWrap: {
    flex: 1,
    paddingTop: 24,
    paddingLeft: 20,
    paddingRight: 20
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  labelError: {
    color: COLORS.orange,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  row: {
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.greyLight,
    marginBottom: 12
  },
  selectError: {
    color: COLORS.orange,
    textAlign: "right"
  },
  rowSelect: {
    paddingLeft: 12
  },
  error: {
    borderBottomColor: COLORS.orange
  },
  textInput: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: COLORS.grey,
    marginTop: 4,
    marginBottom: 2,
    outlineColor: "rgba(255,255,255,0)"
  },
  wrapTwoInput: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  wrapSaveBtn: {
    alignItems: "center",
    marginBottom: 20
  },
  disableValueText: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: "#666666"
  },
  cardStyle: {
    marginLeft: -12,
    paddingLeft: 12,
    paddingRight: 6,
    paddingBottom: 4,
    borderRadius: 25
  },
  wrapSwitch: {
    flexDirection: "row",
    marginBottom: 4,
    alignItems: "center"
  },
  rowStyle: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cardDate: {
    marginLeft: -12,
    paddingRight: 6,
    borderRadius: 25
  },
  saveBtn: {
    paddingTop: 12,
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-end"
  }
});

export default styles;
