import React, { useEffect } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Card, Touchable, WrapContent, WrapList } from "../../../components";
import { IMAGES } from "../../../configs";
import { Reducers } from "../../../redux/types";
import {
  deleteTripReport,
  getDateAvailableReport,
  getTripReport,
  putSubmitTripReport
} from "../../../redux/actions";
import styles from "./styles";
import {
  convertDate,
  documentTitle,
  heightPercent,
  queryString
} from "../../../utils";

interface Props {
  id: string;
  top: number;
  search: string;
}

const Report = ({ id, top, search }: Props) => {
  const dataTrip = queryString(search);
  documentTitle("Trip Detail - Report");
  const history = useHistory();
  const { tripData, isLoadingTripById, listTripReport } = useSelector(
    (state: Reducers) => state.trip
  );
  const editReportState = useSelector((state: Reducers) => state.report);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTripReport(id));
    dispatch(getDateAvailableReport(id));
  }, [dispatch, id]);

  const _submitReport = (reportId: string) => {
    dispatch(putSubmitTripReport(reportId));
  };

  const _deleteReport = (item: any) => {
    dispatch(deleteTripReport(item.id, convertDate(item.date, "YYYY-MM-DD")));
  };

  const formatDate = (date: string) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const d = new Date(date);
    const formattedDay = days[d.getDay()];
    const formattedDate = d.getDate();
    const formattedMonth = monthNames[d.getMonth()];
    const formattedYear = d.getFullYear();
    return `${formattedDay}, ${formattedDate} ${formattedMonth} ${formattedYear}`;
  };

  return (
    <View style={{ minHeight: heightPercent(100) - top }}>
      <WrapContent isLoading={isLoadingTripById}>
        {tripData !== null && (
          <View style={styles.container}>
            <View style={styles.tripDescBox}>
              <View style={styles.desciption}>
                <Text style={styles.titleDescription}>Trip</Text>
                <View style={styles.detailDescription}>
                  <Text style={styles.titleDescription}>{tripData.number}</Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#666666",
                      fontWeight: "bold"
                    }}
                  >
                    {tripData.title}
                  </Text>
                  <Text
                    style={{ fontSize: 11, color: "#999999", marginTop: 4 }}
                  >
                    {tripData.type}
                  </Text>
                </View>
              </View>
              <View style={styles.desciption}>
                <Text style={styles.titleDescription}>Team</Text>
                <View
                  style={[styles.detailDescription, { flexDirection: "row" }]}
                >
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 11,
                      color: "#666666",
                      fontWeight: "bold"
                    }}
                  >
                    {tripData.users &&
                      tripData.users.map((item: any) => `${item.user.name}, `)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        <WrapList
          isEmpty={listTripReport.length === 0}
          emptyComponent={() => (
            <>
              {dataTrip.type !== "history" ? (
                <Touchable
                  onPress={() => history.push(`/tripdetail/newreport/${id}`)}
                >
                  <Image
                    style={{ width: 62.6, height: 75.6 }}
                    resizeMode="contain"
                    source={IMAGES.newReport}
                  />
                </Touchable>
              ) : (
                <></>
              )}
            </>
          )}
        >
          {listTripReport.map((item, index) => {
            return (
              <Card cardStyle={styles.card} key={index}>
                <View>
                  <View
                    style={[
                      styles.header,
                      item.status === 2
                        ? { backgroundColor: "#50b1b1" }
                        : { backgroundColor: "#f5aa3f" }
                    ]}
                  >
                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row"
                      }}
                    >
                      <Text style={styles.textHeader}>
                        {formatDate(item.date)}
                      </Text>
                      {item.status === 1 ? (
                        <View style={{ flexDirection: "row" }}>
                          {dataTrip.type !== "history" ? (
                            <>
                              <TouchableOpacity
                                style={styles.btnReport}
                                onPress={() => _deleteReport(item)}
                              >
                                <Image
                                  style={{ width: 13, height: 16 }}
                                  source={IMAGES.trashBin}
                                />
                              </TouchableOpacity>

                              <TouchableOpacity
                                style={styles.btnReport}
                                onPress={() => _submitReport(item.id)}
                              >
                                <Image
                                  style={{ width: 13, height: 16 }}
                                  source={IMAGES.checkList}
                                />
                              </TouchableOpacity>
                            </>
                          ) : (
                            <></>
                          )}
                        </View>
                      ) : null}
                    </View>
                  </View>

                  <Touchable
                    onPress={() => {
                      if (dataTrip.type !== "history") {
                        if (item.status === 1) {
                          history.push(
                            `/tripdetail/editreport/${id}?report=${item.id}`,
                            {
                              idReport: item.id,
                              dateReport: item.date
                            }
                          );
                        }
                      }
                    }}
                  >
                    <View style={{ marginTop: 15 }}>
                      <Text style={[styles.text, { fontWeight: "bold" }]}>
                        {item.title || "-"}
                      </Text>
                      <Text
                        numberOfLines={5}
                        style={[styles.text, styles.posDesc]}
                      >
                        {item.description || "-"}
                      </Text>
                      <Text
                        style={[
                          styles.text,
                          {
                            fontWeight: "bold",
                            fontSize: 14,
                            marginBottom: 16
                          }
                        ]}
                      >
                        {item.file ? "1 files attached" : "0 files attached"}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </Card>
            );
          })}
        </WrapList>

        {listTripReport.length > 0 && (
          <div
            style={{
              position: "sticky",
              bottom: 0,
              padding: 20,
              paddingTop: 5,
              alignSelf: "flex-end"
            }}
          >
            {!editReportState.isLoadingDateAvailableReport &&
            editReportState.listDateAvailableReport.length > 0 ? (
              <Touchable
                onPress={() => history.push(`/tripdetail/newreport/${id}`)}
              >
                <Image
                  style={{ width: 62.6, height: 75.6 }}
                  resizeMode="contain"
                  source={IMAGES.newReport}
                />
              </Touchable>
            ) : null}
          </div>
        )}
      </WrapContent>
    </View>
  );
};

export default Report;
