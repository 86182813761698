import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  header: {
    backgroundColor: COLORS.main
  },
  containerNav: {
    paddingHorizontal: 20,
    marginTop: 16
  },
  wrapNav: {
    flexDirection: "row",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    borderRadius: 16,
    overflow: "hidden",
    shadowColor: COLORS.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  buttonTab: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 8
  },
  activeButton: {
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    flex: 1
  },
  labelTab: {
    fontFamily: "poppins",
    fontWeight: "bold",
    fontSize: 11,
    color: COLORS.white
  },
  content: {
    flex: 1,
    backgroundColor: COLORS.main
  }
});

export default styles;
