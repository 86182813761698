import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  wrapForm: {
    marginVertical: 6
  },
  wrapRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  label: {
    fontSize: 11,
    color: COLORS.grey,
    fontFamily: "Poppins",
    marginLeft: 12
  },
  input: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
    color: COLORS.grey
  },
  buttonAddParticipant: {
    height: 28,
    justifyContent: "center",
    width: "48%",
    backgroundImage:
      "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)",
    alignItems: "center",
    borderRadius: 40
  },
  buttonTextParticipant: {
    fontSize: 14,
    fontWeight: "bold",
    color: COLORS.white,
    fontFamily: "Poppins"
  },
  desc: {
    paddingLeft: 14,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    minHeight: 42,
    maxHeight: 210,
    outlineColor: "rgba(255,255,255,0)",
    paddingHorizontal: 0
  },
  inputLine: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 30,
    paddingLeft: 12
  },
  cardSelect: {
    // width: "48%",
    paddingVertical: 5,
    paddingHorizontal: 12,
    marginTop: 2
  },
  cardSelectChild: {
    width: "100%",
    flex: 2
  },
  cardDate: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    marginTop: 2
  },
  errorMessages: {
    marginTop: 4,
    fontSize: 11,
    fontFamily: "Poppins",
    color: COLORS.danger
  },
  wrapPersonList: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  imageStyle: {
    height: 36,
    width: 36,
    borderRadius: 40,
    margin: 4
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  wrapSwitch: {
    marginBottom: 12,
    paddingLeft: 4,
    flexDirection: "row",
    alignItems: "center"
  },
  textArea: {
    paddingRight: 10
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginVertical: 2,
    height: 35,
    width: "100%",
    borderRadius: 100
  }
});

export default styles;
