import { StyleSheet } from "react-native";

const orange = "#ed5a28";
const styles = StyleSheet.create({
  card: {
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    marginVertical: 10,
    marginHorizontal: 25
  },
  text: {
    color: "#999999",
    marginBottom: 5,
    fontFamily: "Poppins",
    fontSize: 11
  },
  textDesc: {
    color: "#666666",
    marginBottom: 5,
    fontFamily: "Poppins",
    fontSize: 11
  },
  row: {
    flexDirection: "row"
  },
  statusBox: {
    flexDirection: "row"
  },
  statusText: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 18,
    fontWeight: "bold"
  },
  orange: {
    fontFamily: "Poppins",
    color: orange,
    width: "50%"
  },
  costBox: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    width: "50%"
  },
  fab: {
    height: 87,
    width: 87
  }
});

export default styles;
