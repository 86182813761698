import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20
  },
  wrapText: {
    flexDirection: "row",
    marginBottom: 4,
    paddingHorizontal: 16
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "23%"
  },
  textValue: {
    width: "77%",
    marginRight: 7
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.grey
  },
  wrapAmountText: {
    paddingHorizontal: 20,
    marginTop: -22
  },
  amountText: {
    color: COLORS.green,
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right"
  },
  line: {
    borderBottomWidth: 1,
    borderColor: COLORS.greyLight,
    marginTop: 8
  },
  text: {
    fontSize: 11,
    width: "22%",
    color: "#999999",
    fontFamily: "Poppins"
  },
  wrapTotal: {
    paddingLeft: 35,
    paddingRight: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 90
  },
  totalLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11
  },
  emptyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  }
});

export default styles;
