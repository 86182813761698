import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const GET_BOOKING_SOURCE_FLIGHT_PENDING =
  "GET_BOOKING_SOURCE_FLIGHT_PENDING";
export const GET_BOOKING_SOURCE_FLIGHT_SUCCESS =
  "GET_BOOKING_SOURCE_FLIGHT_SUCCESS";
export const GET_BOOKING_SOURCE_FLIGHT_ERROR =
  "GET_BOOKING_SOURCE_FLIGHT_ERROR";

export const GET_AIRLINE_PENDING = "GET_AIRLINE_PENDING";
export const GET_AIRLINE_SUCCESS = "GET_AIRLINE_SUCCESS";
export const GET_AIRLINE_ERROR = "GET_AIRLINE_ERROR";

export const SET_FORM_NEW_BOOKING_FLIGHT = "SET_FORM_NEW_BOOKING_FLIGHT";

export const POST_NEW_BOOKING_FLIGHT_PENDING =
  "POST_NEW_BOOKING_FLIGHT_PENDING";
export const POST_NEW_BOOKING_FLIGHT_SUCCESS =
  "POST_NEW_BOOKING_FLIGHT_SUCCESS";
export const POST_NEW_BOOKING_FLIGHT_ERROR = "POST_NEW_BOOKING_FLIGHT_ERROR";

export const RESET_FORM_FLIGHT_SUCCESS = "RESET_FORM_FLIGHT_SUCCESS";

declare const alert: (param: any) => void;

export const getBookingSourceFlight = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BOOKING_SOURCE_FLIGHT_PENDING });

    const res = await API.getBookingSourceFlight(auth.token);
    dispatch({
      type: GET_BOOKING_SOURCE_FLIGHT_SUCCESS,
      payload: { data: res.data.data.data[0].id }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBookingSourceFlight())));
      } else {
        dispatch({
          type: GET_BOOKING_SOURCE_FLIGHT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_BOOKING_SOURCE_FLIGHT_ERROR });
  }
};

export const getAirline = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_AIRLINE_PENDING });

    const res = await API.getAirline(auth.token);
    dispatch({
      type: GET_AIRLINE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getAirline())));
      } else {
        dispatch({
          type: GET_AIRLINE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_AIRLINE_ERROR });
  }
};

interface DataForm {
  formName: string;
  value: string | number | boolean;
}

export const setFormNewBookingFlight = (
  data: DataForm,
  index: number
) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_FORM_NEW_BOOKING_FLIGHT,
    payload: {
      data,
      index
    }
  });
};

export const postNewBookingFlight = (
  id: string,
  cb: () => void,
  round: boolean
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth, newBookingFlight, trip } = getState();
    dispatch({ type: POST_NEW_BOOKING_FLIGHT_PENDING });

    const body = {
      ...newBookingFlight.form,
      participants: trip.tripData.users[0],
      trip_id: id,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    await API.postNewBookingFlight(body, auth.token);
    dispatch({
      type: POST_NEW_BOOKING_FLIGHT_SUCCESS
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(postNewBookingFlight(id, cb, round)))
        );
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_BOOKING_FLIGHT_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    alert("Try Again!");
    dispatch({ type: POST_NEW_BOOKING_FLIGHT_ERROR });
  }
};

export const handleResetFlight = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_FORM_FLIGHT_SUCCESS });
};
