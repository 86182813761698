import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 49
  },
  cardDescription: {
    paddingLeft: 21,
    paddingTop: 28,
    paddingBottom: 12,
    paddingRight: 20
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  descriptionCity: {
    marginBottom: 0,
    marginTop: 0
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999",
    fontFamily: "Poppins"
  },
  detailDescription: {
    width: "75%",
    fontSize: 11,
    color: "#666666"
  },
  titleHotel: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    marginBottom: 16
  },
  descDate: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666",
    marginRight: 10
  },
  star: {
    width: 9.5,
    height: 9.5,
    marginRight: 5
  },
  titleFlight: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    marginBottom: 6
  },
  detailDescriptionText: {
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  },
  totalContainer: {
    marginTop: 33,
    alignSelf: "center",
    alignItems: "center"
  },
  totalText: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: "#999999",
    marginBottom: 8
  },
  totalDetail: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#707070",
    marginBottom: 90
  },
  emptyData: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    flex: 1
  },
  emptyDataText: {
    fontSize: 16,
    fontWeight: "bold"
  }
});

export default styles;
