import React from "react";
import { Text, View } from "react-native";

import { Button, Header, Input } from "../../components";
import styles from "./styles";

const Settings = () => {
  return (
    <View>
      <Header title="Settings" />
      <View style={styles.formContainer}>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Name</Text>
          <Input placeholder="Name" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Company</Text>
          <Input placeholder="Company" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Branch</Text>
          <Input placeholder="Branch" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Email</Text>
          <Input placeholder="email@gmail.com" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Phone Number</Text>
          <Input placeholder="Phone Number" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Birth of Date</Text>
          <Input placeholder="Birth of Date" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>KTP</Text>
          <Input placeholder="KTP number" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>NPWP</Text>
          <Input placeholder="NPWP number" inputStyle={styles.inputStyle} />
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.labelInput}>Position</Text>
          <Input placeholder="Position" inputStyle={styles.inputStyle} />
        </View>
      </View>
      <View style={styles.saveButton}>
        <Button title="SAVE" />
      </View>
    </View>
  );
};

export default Settings;
