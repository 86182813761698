import React, { useEffect, useState } from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Flight from "./Flight";
import Hotel from "./Hotel";
import { Card, Fixed, Header, Touchable, WrapContent } from "../../components";
import styles from "./styles";
import { IMAGES } from "../../configs";
import { heightPercent } from "../../utils";

import { Reducers } from "../../redux/types";
import { getTripById } from "../../redux/actions";

const Booking = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const { id } = useParams();
  const initialRoute = `${match.path.slice(0, -4)}/${id}`;
  const [heighHeader, setHeightHeader] = useState(0);
  const [heighBottom, setHeightBottom] = useState(0);
  const { pathname } = history.location;
  const newTripState = useSelector((state: Reducers) => state.newTrip);
  const listPages = [
    {
      pathname: initialRoute,
      component: <Hotel bottom={heighBottom} id={id!} />
    },
    {
      pathname: `${initialRoute}/flight`,
      component: <Flight bottom={heighBottom} id={id!} />
    }
  ];

  useEffect(() => {
    dispatch(getTripById(id));
  }, [dispatch, id]);

  const { tripData, isLoadingTripById } = useSelector(
    (state: Reducers) => state.trip
  );

  const _handleSave = () => {
    history.push("/trip");
  };

  return (
    <Switch>
      <WrapContent isLoading={isLoadingTripById}>
        <View>
          <Fixed getHeight={value => setHeightHeader(value)}>
            <Header
              title="Booking"
              subtitle="Meeting Presentasi Blueprint"
              goBack={() => history.push(`/newexpense/trip/${id}`)}
              label="Draft"
            />
            <View style={styles.container}>
              <View style={styles.navigation}>
                <View
                  style={
                    listPages[0].pathname === pathname
                      ? styles.active
                      : styles.btnNav
                  }
                >
                  <Touchable
                    onPress={() => history.push(listPages[0].pathname)}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <Image
                        style={{ height: 25, width: 30, marginBottom: 5 }}
                        source={IMAGES.hotel}
                      />
                      <Text style={styles.textButton}>Hotel</Text>
                    </View>
                  </Touchable>
                </View>
                <View
                  style={
                    listPages[1].pathname === pathname
                      ? styles.active
                      : styles.btnNav
                  }
                >
                  <Touchable
                    onPress={() => history.push(listPages[1].pathname)}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <Image
                        style={{ height: 25, width: 25, marginBottom: 5 }}
                        source={IMAGES.flight}
                      />
                      <Text style={styles.textButton}>Flight</Text>
                    </View>
                  </Touchable>
                </View>
              </View>
              <Card cardStyle={[styles.cardDescription, { marginTop: -20 }]}>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View style={{ width: "25%" }}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#999999",
                        fontFamily: "Poppins"
                      }}
                    >
                      Trip
                    </Text>
                  </View>
                  <View style={{ width: "75%" }}>
                    <Text style={styles.textDescTrip}>
                      {tripData ? tripData.number : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.textDescTrip,
                        { fontWeight: "bold", fontSize: 14 }
                      ]}
                    >
                      {tripData ? tripData.title : "-"}
                    </Text>
                    <Text style={styles.textDescTrip}>
                      {tripData ? tripData.category.title : "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View style={{ width: "25%" }}>
                    <Text
                      style={{
                        fontSize: 11,
                        color: "#999999",
                        fontFamily: "Poppins"
                      }}
                    >
                      Participant
                    </Text>
                  </View>
                  <View style={{ width: "75%" }}>
                    <Text style={[styles.textDescTrip, { fontWeight: "bold" }]}>
                      {tripData
                        ? tripData.users.map((item: any, index: number) => {
                            return `${item.user.name}${
                              index !== tripData.users.length - 1 ? ", " : ""
                            }`;
                          })
                        : "-"}
                    </Text>
                  </View>
                </View>
              </Card>
            </View>
          </Fixed>

          <View
            style={{
              marginTop: heighHeader,
              minHeight: heightPercent(100) - (heighHeader + heighBottom)
            }}
          >
            {listPages.map((item, index) => (
              <Route path={item.pathname} exact key={index}>
                {item.component}
              </Route>
            ))}
          </View>
          <Fixed position="bottom" getHeight={value => setHeightBottom(value)}>
            <View style={styles.saveButtonContainer}>
              <View style={styles.buttonSave}>
                <Touchable
                  onPress={_handleSave}
                  disabled={newTripState.isLoadingEditTrip}
                >
                  <View
                    style={{
                      paddingVertical: 15
                    }}
                  >
                    {newTripState.isLoadingEditTrip ? (
                      <ActivityIndicator color="#FFF" />
                    ) : (
                      <Text style={styles.buttonSaveText}>SAVE</Text>
                    )}
                  </View>
                </Touchable>
              </View>
            </View>
          </Fixed>
        </View>
      </WrapContent>
    </Switch>
  );
};
export default Booking;
