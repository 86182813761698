import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    paddingHorizontal: 20,
    flex: 1,
    marginTop: 18
  },
  row: {
    marginBottom: 16
  },
  font: {
    fontFamily: "Poppins",
    color: "#666666",
    fontSize: 12
  },
  label: {
    paddingLeft: 8
  },
  inputLine: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 30,
    paddingLeft: 8,
    fontWeight: "bold"
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.main,
    marginVertical: 2,
    borderRadius: 10,
    marginTop: 6
  },
  textArea: {
    paddingLeft: 8,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.greyMedium,
    borderStyle: "solid",
    height: 170,
    outlineColor: "rgba(255,255,255,0)",
    fontWeight: "bold"
  },
  validateMessages: {
    marginTop: 4,
    fontSize: 11,
    fontFamily: "Poppins",
    color: COLORS.danger
  },
  wrapAttch: {
    width: "100%"
  }
});

export default styles;
