import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./styles";
import { IMAGES } from "../../../configs";
import { Button, Input } from "../../../components";
import {
  Container,
  DescImage,
  DescText,
  WrapButton,
  WrapForm
} from "../components";

interface Props {
  next: string;
}

const EmailRegister = ({ next }: Props) => {
  const history = useHistory();

  return (
    <Container>
      <DescImage image={IMAGES.iconAt} />
      <DescText
        title="Email register"
        desc="Enter your email, and set your djurnee account password"
      />
      <WrapForm>
        <Input style={styles.input} placeholder="email" />
        <Input style={styles.input} placeholder="password" />
        <Input style={styles.input} placeholder="repeat password" />
        <WrapButton>
          <Button title="CONTINUE" onPress={() => history.push(next)} />
        </WrapButton>
      </WrapForm>
    </Container>
  );
};

export default EmailRegister;
