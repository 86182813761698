import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Cookies from "js-cookie";
import {
  emptyDetailBills,
  getBudgetRequest,
  getExpenseCashAdvance
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import {
  Card,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../../components";
import styles from "./styles";
import { maskedMoney, queryString } from "../../../../utils";
import ExpenseDetail from "./Components/ExpenseDetail";
import CloseBill from "./Components/CloseBill";
import { IMAGES } from "../../../../configs";
import EditBills from "./EditBills";

interface Props {
  id: string;
}

const CashAdvance = ({ id }: Props) => {
  const dispatch = useDispatch();
  const ExpenseState = useSelector((state: Reducers) => state.newExpenseTrip);
  const history = useHistory();

  const { search, pathname } = history.location;
  const param = queryString(search);
  const [oneTimeEffect, setOntimeEffect] = useState(true);
  const [showEditBill, setShowEditBill] = useState(false);
  const typeDataEditBill: any = {};
  const [dataEditBill, setDataEditBill] = useState(typeDataEditBill);

  const lang = Cookies.get("locale") || "en";

  useEffect(() => {
    dispatch(getBudgetRequest(id, lang));
    dispatch(getExpenseCashAdvance(id));
  }, [dispatch]);

  const [showDetail, setShowDetail] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const typeExpenseDetail: any = {};
  const [expenseDetail, setExpenseDetail] = useState(typeExpenseDetail);

  const _addCashExpense = () => {
    history.push("/new-cash-expense", {
      id,
      invoice_id:
        ExpenseState.listExpenseCashAdvance.length === 0
          ? null
          : ExpenseState.listExpenseCashAdvance[0].invoice_id
    });
  };
  const checkCategory = () => {
    if (oneTimeEffect) {
      setOntimeEffect(false);
      Object.keys(param).forEach(key => {
        if (key === "category_id") {
          const data = ExpenseState.listExpenseCashAdvance.filter(
            a => a.category_id === param[key]
          );
          if (data.length > 0) {
            let qryparam = "";
            let i = 1;
            Object.keys(param).forEach((keyparam: string) => {
              if (keyparam !== "category_id") {
                qryparam = `${qryparam}${keyparam}=${param[keyparam]}`;
                if (Object.keys(param).length - 1 !== i) {
                  qryparam = `${qryparam}&`;
                }
              }
              i += 1;
            });
            window.history.pushState(
              "page2",
              "Title",
              `${pathname}?${qryparam}`
            );
            setExpenseDetail(data[0]);
            setShowDetail(true);
          }
        }
      });
    }
  };
  const _totalCA = () => {
    let total: any = "";
    if (ExpenseState.listExpenseCashAdvance.length > 0) {
      total = ExpenseState.listExpenseCashAdvance.reduce(
        (a: any, b: any) => a + (Number(b.total_expense) || 0),
        0
      );
    }
    return total;
  };

  return (
    <WrapContent isLoading={ExpenseState.isLoadingListExpenseCashAdvance}>
      <WrapList
        isEmpty={ExpenseState.listExpenseCashAdvance.length === 0}
        emptyComponent={() =>
          param.status === "4" ? (
            <Touchable onPress={_addCashExpense}>
              <Image style={{ width: 72, height: 72 }} source={IMAGES.plus} />
            </Touchable>
          ) : (
            <Text style={styles.text}>Kosong</Text>
          )
        }
      >
        <>
          {ExpenseState.listExpenseCashAdvance.length > 0 ? (
            <>{checkCategory()}</>
          ) : (
            <></>
          )}
        </>
        <FlatList
          style={{ paddingHorizontal: 20 }}
          data={ExpenseState.listExpenseCashAdvance}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Card
              key={index}
              cardStyle={{
                marginBottom: 15,
                marginTop: 20
              }}
            >
              <Touchable
                onPress={() => {
                  setShowDetail(true);
                  setExpenseDetail(item);
                }}
              >
                <>
                  <View style={{ marginLeft: 21, marginVertical: 17 }}>
                    <View style={{ flexDirection: "row", marginBottom: 10 }}>
                      <Text style={styles.text}>Category</Text>
                      <Text style={styles.textDesc}>{item.category}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={styles.text}>Desc.</Text>
                      <Text style={styles.textDesc}>
                        {item.description || " - "}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.wrapStatus}>
                    <View style={styles.wrapDetailStatus}>
                      <Text style={styles.textStatus}>Ongoing Expense</Text>
                      <View style={{ flexDirection: "row", marginTop: 2 }}>
                        <Text style={styles.priceIdr}>IDR</Text>
                        <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                          {` ${maskedMoney(item.total_expense)}`}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.wrapDetailStatus}>
                      <Text style={styles.textStatus}>Budget Request</Text>
                      <View style={{ flexDirection: "row", marginTop: 2 }}>
                        <Text style={styles.priceIdr}>IDR</Text>
                        <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                          {` ${maskedMoney(item.total)}`}
                        </Text>
                      </View>
                    </View>
                  </View>
                </>
              </Touchable>
            </Card>
          )}
        />
      </WrapList>
      {ExpenseState.listExpenseCashAdvance.length > 0 &&
      param.status === "4" ? (
        <div
          style={{
            position: "sticky",
            bottom: 65,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          <Touchable onPress={_addCashExpense}>
            <Image style={{ width: 58, height: 58 }} source={IMAGES.plus} />
          </Touchable>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          position: "sticky",
          bottom: 0,
          paddingTop: 5
        }}
      >
        <View style={styles.footer}>
          <View style={{ width: "50%", alignItems: "center" }}>
            <Text style={styles.budgetReq}>Ongoing Expense</Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.priceIdr}>IDR</Text>
              {typeof ExpenseState.listBudgetReq.Total !== "undefined" &&
              typeof ExpenseState.listBudgetReq.Total.IDR !== "undefined" ? (
                // eslint-disable-next-line dot-notation
                <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                  {` ${maskedMoney(_totalCA() || "-")}`}
                </Text>
              ) : (
                <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                  {" "}
                  -
                </Text>
              )}
            </View>
          </View>
          <View style={{ width: "50%", alignItems: "center" }}>
            <Text style={styles.budgetReq}>Budget Request</Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.priceIdr}>IDR</Text>
              {typeof ExpenseState.listBudgetReq.Total !== "undefined" &&
              typeof ExpenseState.listBudgetReq.Total.IDR !== "undefined" ? (
                // eslint-disable-next-line dot-notation
                <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                  {` ${maskedMoney(
                    ExpenseState.listBudgetReq["Cash Advance"].IDR || "-"
                  )}`}
                </Text>
              ) : (
                <Text style={[styles.priceIdr, { fontWeight: "bold" }]}>
                  {" "}
                  -
                </Text>
              )}
            </View>
          </View>
        </View>
      </div>

      {showDetail ? (
        <Modal isVisible onBackdropPress={() => setShowDetail(false)}>
          <ExpenseDetail
            tripId={id}
            onClose={visible => setShowDetail(visible)}
            onConfirm={visible => {
              setShowConfirm(visible);
              setShowDetail(false);
            }}
            value={expenseDetail}
            onEditBill={(editBillsId, value, tripData) => {
              setShowEditBill(true);
              setDataEditBill({ editBillsId, value, tripData });
              setShowDetail(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {showConfirm ? (
        <Modal
          isVisible
          onBackdropPress={() => {
            setShowConfirm(false);
            setShowDetail(true);
          }}
        >
          <CloseBill
            setShowConfirm={bool => setShowConfirm(bool)}
            setShowDetail={bool => setShowDetail(bool)}
          />
        </Modal>
      ) : (
        <></>
      )}
      {showEditBill ? (
        <Modal
          isVisible
          onBackdropPress={() => {
            setExpenseDetail(dataEditBill.value);
            setShowDetail(true);
            setShowEditBill(false);
            dispatch(emptyDetailBills());
          }}
        >
          <EditBills
            billsId={dataEditBill.editBillsId}
            value={dataEditBill.value}
            detailExpense={dataEditBill.tripData}
            closeModal={() => {
              setExpenseDetail(dataEditBill.value);
              setShowDetail(true);
              setShowEditBill(false);
              dispatch(emptyDetailBills());
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
    </WrapContent>
  );
};

export default CashAdvance;
