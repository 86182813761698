import React, { useCallback, useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { sprintf } from "sprintf-js";
import {
  Button,
  Card,
  FileUpload,
  Header,
  Select,
  TextArea,
  Upload,
  WrapContent
} from "../../../components";
import styles from "./styles";
import { Reducers } from "../../../redux/types";
import {
  getPopulateCategory,
  getPopulateProject,
  getTripById,
  putEditTrip
  // putEditTrip
} from "../../../redux/actions";
import { convertDate, copyWritings } from "../../../utils";

const initForm = {
  trip_id: "",
  started_at: "",
  finished_at: "",
  category_id: "",
  project_id: "",
  title: "",
  description: "",
  origin_location: "",
  destination_location: "",
  self_booking: null,
  file: null,
  client_timezone: "Asia/Jakarta"
};

const EditTrip = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { tripState, newTripState } = useSelector(
    (state: Reducers) => ({
      tripState: state.trip,
      newTripState: state.newTrip
    }),
    shallowEqual
  );
  const { state } = history.location as any;
  const { id } = useParams() as any;
  const { t } = useTranslation();

  const [form, setForm]: any = useState(initForm);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(getTripById(id));
    dispatch(getPopulateCategory());
    dispatch(getPopulateProject());
  }, [dispatch, id]);

  const _setForm = useCallback(
    (
      formName: "title" | "description" | "project_id" | "category_id" | "file",
      formValue: any
    ) => {
      setForm({ ...form, [formName]: formValue });
      if (isError) {
        setIsError(false);
      }
    },
    [form, isError]
  );

  useEffect(() => {
    if (tripState.tripData) {
      setForm({
        ...form,
        trip_id: id,
        title: tripState.tripData?.title,
        description: tripState.tripData?.description,
        origin_location: tripState.tripData?.origin_location,
        destination_location: tripState.tripData?.destination_location,
        started_at: tripState.tripData?.started_at,
        finished_at: tripState.tripData?.finished_at,
        category_id: tripState.tripData?.category?.id || "",
        project_id:
          tripState.tripData?.project?.id ||
          tripState.tripData?.project?.uuid ||
          "",
        file: tripState?.tripData?.file
          ? JSON.parse(tripState?.tripData?.file)
          : null
      });
    }
  }, [tripState.tripData, id]);

  const _renderAlertError = useCallback(
    (isSHow: boolean, bottom?: boolean) =>
      isSHow && (
        <Text
          style={[styles.font, bottom ? styles.bottomError : styles.labelError]}
        >
          *field required
        </Text>
      ),
    []
  );

  const _uploadFile = (e: any) => {
    const files = e.target.files[0];
    _setForm("file", files);
  };

  const _goToPreview = () => {
    if (form.file.title) {
      window.open(form.file.url, "_blank");
    } else {
      const go = URL.createObjectURL(form.file);
      window.open(go, "_blank");
    }
  };

  const _statusDesc = () => {
    let desc = "LOADING...";
    let status = 0;

    if (state) {
      status = state.status;
    } else if (tripState.tripData) {
      status = tripState.tripData?.status;
    }

    if (status === 1) {
      desc = t("cash.status.draft");
    } else if (status === 2) {
      desc = t("cash.status.needApproval");
    } else if (status === 3) {
      desc = t("cash.status.approved");
    } else if (status === 4) {
      desc = t("cash.status.revise");
    } else if (status === 5) {
      desc = t("cash.status.ongoing");
    } else if (status === 6) {
      desc = t("cash.status.finished");
    } else if (status === 7) {
      desc = t("cash.status.rejected");
    }
    return desc;
  };

  const copText = {
    project: copyWritings("project"),
    trip: copyWritings("trip")
  };

  const _handleSave = () => {
    const body = {
      ...form,
      file: form.file && form.file.title ? "" || "" : form.file || ""
    };

    if (form.title !== "" && form.category_id !== "") {
      dispatch(
        putEditTrip(body, form.trip_id, () =>
          history.push(
            `/trip-detail/${id}?type=upcoming&status=${state.status}`
          )
        )
      );
    } else {
      setIsError(true);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={sprintf(t("activity.editActivity"), copText.trip)}
        label={`${_statusDesc()}`}
        textHeader={{ fontSize: 18 }}
      />
      <WrapContent isLoading={tripState.isLoadingTripById}>
        <View style={styles.container}>
          <View style={styles.wrapFormRow}>
            <View style={{ width: "48%" }}>
              <Text style={[styles.font, styles.label]}>{copText.project}</Text>
              <Card cardStyle={styles.cardForm}>
                <Select
                  listValue={newTripState.listPopulateProject}
                  value={form.project_id}
                  onChange={e => _setForm("project_id", e.target.value)}
                  emptyLabel={t("task.assign.chooseProject")}
                />
              </Card>
            </View>
            <View style={{ width: "48%" }}>
              <Text style={[styles.font, styles.label]}>{copText.trip}</Text>
              <Card cardStyle={styles.cardForm}>
                <Select
                  style={form.category_id ? { opacity: 1 } : { opacity: 0.6 }}
                  listValue={newTripState.listPopulateCategory}
                  value={form.category_id}
                  onChange={e => _setForm("category_id", e.target.value)}
                  emptyLabel={t("activity.invoice.selectCategory")}
                />
              </Card>
              {_renderAlertError(isError && form.category_id === "", true)}
            </View>
          </View>

          <View style={styles.wrapForm}>
            <Text style={[styles.font, styles.label]}>
              {t("cash.new.subject")}
            </Text>
            <View
              style={[styles.row, isError && form.title === "" && styles.error]}
            >
              <TextInput
                style={styles.textInput}
                onChangeText={text => _setForm("title", text)}
                value={form.title}
              />
              {_renderAlertError(isError && form.title === "")}
            </View>
          </View>

          <View style={styles.wrapFormRow}>
            <View style={{ width: "48%" }}>
              <Text style={[styles.font, styles.label, { marginBottom: 5 }]}>
                {t("cash.new.from")}
              </Text>
              <Text
                numberOfLines={1}
                style={[styles.fontMedium, styles.label, styles.green]}
              >
                {form.origin_location || "-"}
              </Text>
              <Text style={[styles.fontMedium, styles.label, styles.green]}>
                {convertDate(form.started_at)}
              </Text>
              <Text style={[styles.font, styles.label, { marginBottom: 2 }]}>
                {convertDate(form.started_at, "hh:mm")}
              </Text>
            </View>
            <View style={{ width: "48%" }}>
              <Text style={[styles.font, styles.label, { marginBottom: 5 }]}>
                {t("cash.new.to")}
              </Text>
              <Text
                numberOfLines={1}
                style={[styles.fontMedium, styles.label, styles.orange]}
              >
                {form.destination_location || "-"}
              </Text>
              <Text style={[styles.fontMedium, styles.label, styles.orange]}>
                {convertDate(form.finished_at)}
              </Text>
              <Text style={[styles.font, styles.label, { marginBottom: 2 }]}>
                {convertDate(form.finished_at, "hh:mm")}
              </Text>
            </View>
          </View>

          <View style={styles.wrapForm}>
            <Text style={[styles.font, styles.label]}>
              {t("cash.new.description")}
            </Text>
            <View style={styles.row}>
              <TextArea
                bold
                onChange={e => _setForm("description", e.target.value)}
                value={form.description}
                border={false}
              />
            </View>
          </View>

          <View style={styles.wrapAttch}>
            <View style={{ marginBottom: 6 }}>
              {form.file && (
                <FileUpload
                  onPress={_goToPreview}
                  name={form.file.title ? form.file.title : form.file.name}
                  style={{
                    marginTop: 6,
                    marginBottom: 6,
                    marginRight: 8,
                    width: "40%"
                  }}
                  hideDelete={form.file?.title}
                  deleteFile={e => {
                    e.stopPropagation();
                    _setForm("file", null);
                  }}
                />
              )}
            </View>
            <Upload
              style={{
                textAlign: "center",
                padding: 0,
                width: "48%",
                alignItems: "center",
                paddingBottom: 6,
                paddingTop: 6
              }}
              onChange={(el: any) => _uploadFile(el)}
            />
          </View>

          <View style={{ marginTop: 22, flexDirection: "row" }}>
            <View style={styles.wrapWarnSymbol}>
              <Text style={[styles.font, styles.symbol]}>!</Text>
            </View>
            <Text style={[styles.font, styles.light]}>
              {t("cash.new.msg.wontbeAble")}
              <Text style={[styles.font, styles.lightBold]}>
                {` ${t("cash.new.msg.dateAndLoct")} `}
              </Text>
              {t("cash.new.msg.asThose")}
              <Text style={[styles.font, styles.lightBold]}>
                {` ${t("cash.new.msg.calculateAllowance")} `}
              </Text>
              {t("cash.new.msg.nextLine")}
            </Text>
          </View>

          <View style={styles.wrapSaveBtn}>
            <Button
              title={t("activity.new.save")}
              isLoading={newTripState.isLoadingPutEditTrip}
              onPress={_handleSave}
            />
          </View>
        </View>
      </WrapContent>
    </View>
  );
};

export default EditTrip;
