import { StyleSheet } from "react-native";

import { COLORS } from "../../../configs";
import { heightPercent, widthPercent } from "../../../utils";

const styles = StyleSheet.create({
  containerContent: {
    width: widthPercent(100) - 80,
    height: heightPercent(100) - 60,
    backgroundColor: COLORS.main,
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2
  },
  card: {
    backgroundColor: COLORS.main,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 2
  },
  containerDropdown: {
    height: 28,
    width: 180,
    borderRadius: 100
  },
  capsulesContainer: {
    width: "95%",
    height: "60%",
    backgroundColor: "red"
  },
  title: {
    marginTop: 32,
    fontWeight: "bold",
    color: "#666666",
    fontFamily: "Poppins",
    fontSize: 18,
    marginBottom: 20
  },
  moderationText: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#666666",
    marginLeft: 8
  },
  button: {
    marginBottom: 24,
    height: 54,
    width: 180,
    borderRadius: 100,
    backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",
    justifyContent: "center",
    alignItems: "center"
  },

  buttonCancel: {
    marginBottom: 24,
    height: 54,
    width: 180,
    borderRadius: 100,
    backgroundColor: "#666666",
    // backgroundImage: "linear-gradient(to bottom, #FAAF40, #F05A28, #DB3A26)",

    justifyContent: "center",
    alignItems: "center"
  },
  buttonFont: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#ffffff"
  },
  wrapPersonList: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  imageStyle: {
    height: 36,
    width: 36,
    borderRadius: 40,
    margin: 4
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyMedium
  },
  error: {
    color: COLORS.danger,
    fontSize: 11,
    fontFamily: "Poppins",
    marginTop: 4
  }
});

export default styles;
