import React, { useEffect } from "react";
import { FlatList, Image, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  CardStatusColor,
  LoadingLoadMore,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";
import { documentTitle } from "../../../utils";
import { IMAGES } from "../../../configs";
import { Card } from "../components";

// redux action & types
import { getTripUpcoming, resetSearchTripValue } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const Upcoming = () => {
  documentTitle("Trip - Upcoming");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const tripState = useSelector((state: Reducers) => state.trip);

  useEffect(() => {
    dispatch(getTripUpcoming(false, tripState.searchValue));
  }, [dispatch, tripState.searchValue]);

  const _goToDetailTrip = (id: string, labelStatus: number) => {
    history.push(`/trip-detail/${id}?type=upcoming&status=${labelStatus}`, {
      labelStatus
    });
    dispatch(resetSearchTripValue());
  };

  const _statusColor = (status: number) => {
    let color: CardStatusColor = "#50b1b1";
    if (status === 1 || status === 2) {
      color = "#F5AA3F";
    } else if (status === 4) {
      color = "#ed5a28";
    }
    return color;
  };

  const _statusDesc = (data: any) => {
    let desc = t("activity.status.draft");

    if (data.status === 2) {
      desc = t("activity.status.onVerification");
    } else if (data.status === 3) {
      desc = t("activity.status.approved");
    } else if (data.status === 4) {
      desc = t("activity.status.revise");
    } else if (data.status === 5) {
      desc = t("activity.status.ongoing");
    } else if (data.status === 6) {
      desc = t("activity.status.finished");
    } else if (data.status === 7) {
      desc = t("activity.status.rejected");
    } else if (data.status === 8) {
      desc = t("activity.status.overdue");
    }
    return desc;
  };

  return (
    <WrapContent isLoading={tripState.isLoadingListUpcoming}>
      <WrapList
        isEmpty={tripState.listUpcoming.length === 0}
        emptyComponent={() => (
          <View>
            <Touchable
              onPress={() => {
                history.push("/newtrip");
                dispatch(resetSearchTripValue());
              }}
            >
              <Image
                style={{ width: 62.6, height: 75.6 }}
                source={IMAGES.newTrip}
              />
            </Touchable>
          </View>
        )}
        onEndReached={() =>
          tripState.loadMoreUpcoming &&
          dispatch(getTripUpcoming(true, tripState.searchValue))
        }
      >
        <FlatList
          style={{ paddingBottom: 10, paddingHorizontal: 25 }}
          data={tripState.listUpcoming}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={() => (
            <LoadingLoadMore active={tripState.loadMoreUpcoming} />
          )}
          renderItem={({ item, index }) => (
            <Card
              item={item}
              index={index}
              onPress={() => _goToDetailTrip(item.id, item.status)}
              statusColor={_statusColor(item.status)}
              withStatus
              statusDesc={_statusDesc(item)}
            />
          )}
        />
      </WrapList>

      {tripState.listUpcoming.length > 0 && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            padding: 20,
            paddingTop: 5,
            alignSelf: "flex-end"
          }}
        >
          <Touchable
            onPress={() => {
              history.push("/newtrip");
              dispatch(resetSearchTripValue());
            }}
            disabled={tripState.activity.isLoading}
          >
            <>
              <Image
                style={{
                  width: 62.6,
                  height: 75.6,
                  opacity: tripState.activity.isLoading ? 0.5 : 1
                }}
                source={IMAGES.newTrip}
              />
            </>
          </Touchable>
        </div>
      )}
    </WrapContent>
  );
};
export default Upcoming;
