import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const style = StyleSheet.create({
  container: {
    flex: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
    backgroundColor: COLORS.main,
    borderBottomRightRadius: 35,
    borderBottomLeftRadius: 35,
    overflow: "hidden"
  },
  textHeader: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 24,
    color: "#fff"
  },
  textSubHeader: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 14,
    color: "#fff"
  },
  iconBack: {
    width: 30,
    height: 30,
    tintColor: "#ffffff"
  },
  bgHeader: {
    paddingTop: 39,
    paddingBottom: 15,
    flexDirection: "row",
    alignItems: "center"
  },
  buttonBack: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    height: "100%"
  },
  wrapLabel: {
    paddingRight: 20
  },
  textLabel: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 2,
    borderWidth: 1,
    borderColor: "#fff"
  },
  wrapTextHeader: {
    flex: 1,
    paddingRight: 20
  },
  wrapWarnIcon: {
    height: 25,
    width: 25,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowRadius: 2,
    elevation: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  }
});

export default style;
