import { Slide, toast } from "react-toastify";
import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from "./auth";

export const GET_CASH_LIST_PENDING = "GET_CASH_LIST_PENDING";
export const GET_CASH_LIST_SUCCESS = "GET_CASH_LIST_SUCCESS";
export const GET_CASH_LIST_ERROR = "GET_CASH_LIST_ERROR";

export const GET_CASH_DETAIL_PENDING = "GET_CASH_DETAIL_PENDING";
export const GET_CASH_DETAIL_SUCCESS = "GET_CASH_DETAIL_SUCCESS";
export const GET_CASH_DETAIL_ERROR = "GET_CASH_DETAIL_ERROR";

export const GET_MODERATION_REFERENCE_PENDING =
  "GET_MODERATION_REFERENCE_PENDING";
export const GET_MODERATION_REFERENCE_SUCCESS =
  "GET_MODERATION_REFERENCE_SUCCESS";
export const GET_MODERATION_REFERENCE_ERROR = "GET_MODERATION_REFERENCE_ERROR";

export const PUT_MODERATE_CASH_PENDING = "PUT_MODERATE_CASH_PENDING";
export const PUT_MODERATE_CASH_SUCCESS = "PUT_MODERATE_CASH_SUCCESS";
export const PUT_MODERATE_CASH_ERROR = "PUT_MODERATE_CASH_ERROR";

export const PUT_RECURRING_PENDING = "PUT_RECURRING_PENDING";
export const PUT_RECURRING_SUCCESS = "PUT_RECURRING_SUCCESS";
export const PUT_RECURRING_ERROR = "PUT_RECURRING_ERROR";

export const SET_MODAL_RECURRING = "SET_MODAL_RECURRING";
export const SET_MODAL_CASH_ITEM = "SET_MODAL_CASH_ITEM";
export const RESET_ITEM_CASH = "RESET_ITEM_CASH";

export const GET_REQUEST_LIST_PENDING = "GET_REQUEST_LIST_PENDING";
export const GET_REQUEST_LIST_SUCCESS = "GET_REQUEST_LIST_SUCCESS";
export const GET_REQUEST_LIST_ERROR = "GET_REQUEST_LIST_ERROR";

export const GET_BILL_PENDING = "GET_BILL_PENDING";
export const GET_BILL_SUCCESS = "GET_BILL_SUCCESS";
export const GET_BILL_ERROR = "GET_BILL_ERROR";

export const ADD_CASH_ITEM_PENDING = "ADD_CASH_ITEM_PENDING";
export const ADD_CASH_ITEM_SUCCESS = "ADD_CASH_ITEM_SUCCESS";
export const ADD_CASH_ITEM_ERROR = "ADD_CASH_ITEM_ERROR";

export const GET_CASH_ITEM_BY_ID_PENDING = "GET_CASH_ITEM_BY_ID_PENDING";
export const GET_CASH_ITEM_BY_ID_SUCCESS = "GET_CASH_ITEM_BY_ID_SUCCESS";
export const GET_CASH_ITEM_BY_ID_ERROR = "GET_CASH_ITEM_BY_ID_ERROR";

export const DELETE_CASH_ITEM_PENDING = "DELETE_CASH_ITEM_PENDING";
export const DELETE_CASH_ITEM_SUCCESS = "DELETE_CASH_ITEM_SUCCESS";
export const DELETE_CASH_ITEM_ERROR = "DELETE_CASH_ITEM_ERROR";

export const PUT_REISSUE_PENDING = "PUT_REISSUE_PENDING";
export const PUT_REISSUE_SUCCESS = "PUT_REISSUE_SUCCESS";
export const PUT_REISSUE_ERROR = "PUT_REISSUE_ERROR";

export const DELETE_CASH_BILL_PENDING = "DELETE_CASH_BILL_PENDING";
export const DELETE_CASH_BILL_SUCCESS = "DELETE_CASH_BILL_SUCCESS";
export const DELETE_CASH_BILL_ERROR = "DELETE_CASH_BILL_ERROR";

export const PUT_SETTLE_PENDING = "PUT_SETTLE_PENDING";
export const PUT_SETTLE_SUCCESS = "PUT_SETTLE_SUCCESS";
export const PUT_SETTLE_ERROR = "PUT_SETTLE_ERROR";

export const PUT_SUBMIT_BILL_PENDING = "PUT_SUBMIT_BILL_PENDING";
export const PUT_SUBMIT_BILL_SUCCESS = "PUT_SUBMIT_BILL_SUCCESS";
export const PUT_SUBMIT_BILL_ERROR = "PUT_SUBMIT_BILL_ERROR";

export const SUBMIT_ISSUE_PENDING = "SUBMIT_ISSUE_PENDING";
export const SUBMIT_ISSUE_SUCCESS = "SUBMIT_ISSUE_SUCCESS";
export const SUBMIT_ISSUE_ERROR = "SUBMIT_ISSUE_ERROR";

export const SET_SEARCH_CASH_VALUE = "SET_SEARCH_CASH_VALUE";
export const RESET_SEARCH_CASH_VALUE = "RESET_SEARCH_CASH_VALUE";

export const getCashList = (
  status: string,
  loadMore = false,
  q?: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth, cash } = getState();
    dispatch({ type: GET_CASH_LIST_PENDING, payload: { loadMore } });

    const res = await API.getCashList(
      status,
      !loadMore ? { ...cash.paramCashList, page: 1 } : cash.paramCashList,
      auth.token,
      q || ""
    );
    dispatch({
      type: GET_CASH_LIST_SUCCESS,
      payload: { data: res.data.data, loadMore }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCashList(status, loadMore))));
      } else {
        dispatch({
          type: GET_CASH_LIST_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: GET_CASH_LIST_ERROR });
  }
};

export const getCashDetail = (id: string, cb?: (el: string) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_CASH_DETAIL_PENDING });

    const res = await API.getCashDetail(id, auth.token);
    dispatch({
      type: GET_CASH_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb(res.data.data.djurneeInvoice.type.title);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCashDetail(id))));
      } else {
        dispatch({
          type: GET_CASH_DETAIL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_CASH_DETAIL_ERROR });
  }
};

export const getModerationReference = (invoiceId: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MODERATION_REFERENCE_PENDING });

    const res = await API.getModerationReference(invoiceId, auth.token);
    dispatch({
      type: GET_MODERATION_REFERENCE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(getModerationReference(invoiceId)))
        );
      } else {
        dispatch({
          type: GET_MODERATION_REFERENCE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_CASH_DETAIL_ERROR });
  }
};

export const putRecurring = (
  body: any,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_RECURRING_PENDING });

    const res = await API.putRecurring(body, auth.token);
    dispatch({
      type: PUT_RECURRING_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putRecurring(body))));
      } else {
        dispatch({
          type: PUT_RECURRING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_RECURRING_ERROR });
  }
};

export const putModerateCash = (
  body: any,
  invoiceId: string,
  cb?: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_MODERATE_CASH_PENDING });

    const res = await API.putModerateCash(body, auth.token);
    const { status } = res.data.data;
    let label = "";
    if (status === 3) {
      label = i18n.t("moderation.msg.revise");
    } else if (status === 2) {
      label = i18n.t("moderation.msg.approved");
    } else {
      label = i18n.t("moderation.msg.reject");
    }
    dispatch({
      type: PUT_MODERATE_CASH_SUCCESS,
      payload: { data: res.data.data }
    });
    toast.success(label, {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb ? cb() : window.location.reload();
    }, 2600);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putModerateCash(body, invoiceId))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: PUT_MODERATE_CASH_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_MODERATE_CASH_ERROR });
  }
};

export const setModalRecurring = (flag: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_RECURRING,
    payload: { data: flag }
  });
};

export const getRequestList = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_REQUEST_LIST_PENDING });

    const res = await API.getRequestList(id, auth.token);
    dispatch({
      type: GET_REQUEST_LIST_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getRequestList(id))));
      } else {
        dispatch({
          type: GET_REQUEST_LIST_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_REQUEST_LIST_ERROR });
  }
};

export const getBill = (id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_BILL_PENDING });

    const res = await API.getBill(id, auth.token);
    dispatch({
      type: GET_BILL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getBill(id))));
      } else {
        dispatch({
          type: GET_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_BILL_ERROR });
  }
};

export const addCashItem = (
  body: any,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_RECURRING_PENDING });
    const formData = new FormData();

    Object.keys(body).map(attribute => {
      if (body[attribute] !== "") {
        formData.append(attribute, body[attribute]);
      } else {
        formData.append(attribute, "");
      }
    });
    body.item_id && formData.append("_method", "PUT");
    const res = body.item_id
      ? await API.putCashItem(formData, body.item_id, auth.token)
      : await API.postCashItem(formData, auth.token);
    dispatch({
      type: PUT_RECURRING_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(addCashItem(body))));
      } else {
        dispatch({
          type: PUT_RECURRING_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_RECURRING_ERROR });
  }
};

export const getCashItemById = (id: string, cb?: (e: any) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_CASH_ITEM_BY_ID_PENDING });

    const res = await API.getCashItemById(id, auth.token);
    dispatch({
      type: GET_CASH_ITEM_BY_ID_SUCCESS,
      payload: {
        data: res.data.data
      }
    });
    cb && cb(res.data.data);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getCashItemById(id))));
      } else {
        dispatch({
          type: GET_CASH_ITEM_BY_ID_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_CASH_ITEM_BY_ID_ERROR });
  }
};

export const resetItemCash = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_ITEM_CASH
  });
};

export const deleteCashItem = (
  id: string,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_CASH_ITEM_PENDING });

    const res = await API.deleteCashItem(id, auth.token);
    dispatch({
      type: DELETE_CASH_ITEM_SUCCESS
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteCashItem(id))));
      } else {
        dispatch({
          type: DELETE_CASH_ITEM_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_CASH_ITEM_ERROR });
  }
};

export const putReissue = (
  body: any,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_REISSUE_PENDING });
    const formData = new FormData();
    formData.append("invoice_id", body.invoice_id);
    formData.append("client_timezone", body.client_timezone);
    formData.append("_method", "PUT");

    const res = await API.putReissue(body.invoice_id, formData, auth.token);
    dispatch({
      type: PUT_REISSUE_SUCCESS
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putReissue(body))));
      } else {
        dispatch({
          type: PUT_REISSUE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_REISSUE_ERROR });
  }
};

export const deleteCashBill = (
  id: string,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_CASH_BILL_PENDING });

    const res = await API.deleteBill(id, auth.token);
    dispatch({
      type: DELETE_CASH_BILL_SUCCESS
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteCashItem(id))));
      } else {
        dispatch({
          type: DELETE_CASH_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_CASH_BILL_ERROR });
  }
};

export const putSettle = (
  body: any,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_SETTLE_PENDING });

    const res = await API.putSettle(body, auth.token);
    dispatch({
      type: PUT_SETTLE_SUCCESS
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putSettle(body))));
      } else {
        dispatch({
          type: PUT_SETTLE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_SETTLE_ERROR });
  }
};

export const putSubmitBill = (
  body: any,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_SUBMIT_BILL_PENDING });

    const res = await API.putSubmitBill(body, auth.token);
    dispatch({
      type: PUT_SUBMIT_BILL_SUCCESS
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putSubmitBill(body))));
      } else {
        dispatch({
          type: PUT_SUBMIT_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_SUBMIT_BILL_ERROR });
  }
};

export const submitIssue = (
  id: string,
  cb?: (run: boolean, info: string) => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: SUBMIT_ISSUE_PENDING });

    const res = await API.submitIssue(id, auth.token);
    dispatch({
      type: SUBMIT_ISSUE_SUCCESS
    });
    cb && cb(res.data.success, res.data.message);
  } catch (err) {
    cb && cb(err.response.data.success, err.response.data.message);
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(submitIssue(id))));
      } else {
        dispatch({
          type: SUBMIT_ISSUE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: SUBMIT_ISSUE_ERROR });
  }
};

export const setSearchCashValue = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_CASH_VALUE,
    payload: { data: value }
  });
};

export const resetSearchCashValue = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_SEARCH_CASH_VALUE
  });
};
