import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  groupRoundBullet: {
    flexDirection: "row",
    justifyContent: "center"
  },
  roundBullet: {
    width: 5,
    height: 5,
    borderRadius: 100,
    backgroundColor: "#4c768e",
    margin: 10
  },
  roundBulletActive: {
    borderRadius: 50,
    width: 13,
    height: 13,
    backgroundImage: "linear-gradient(to top, #ed5a28,  #f5aa3f)",
    margin: 5
  }
});

export default styles;
