import React, { useEffect } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import styles from "./styles";

import { Container, Header } from "../../components";
import { Reducers } from "../../redux/types";

import Report from "./Report";
import Detail from "./Detail";
import EditReport from "./EditReport";
import AddReport from "./AddReport";

import {
  getConfigUploadFile,
  getDetailTask,
  getDetailTaskRemarks,
  resetDetailTask
} from "../../redux/actions";

const TaskDetail = () => {
  const history = useHistory();
  const { id, type } = useParams() as any;
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialRoute = `${match.url}`;
  const detailTask = useSelector((state: Reducers) => state.task)
    .detailTaskData;
  const myProfile = useSelector((state: Reducers) => state.auth);

  useEffect(() => {
    dispatch(getDetailTask(id));
    dispatch(getDetailTaskRemarks(id));
    dispatch(getConfigUploadFile());
  }, [dispatch]);

  const _handleRenderForm = () => {
    if (history.location.pathname === `${initialRoute}/report/edit`) {
      return <EditReport id={id || ""} userId={myProfile.userId} />;
    }
    if (history.location.pathname === `${initialRoute}/report`) {
      return (
        <Report
          id={id || ""}
          pic={detailTask.pic.employee.id}
          userId={myProfile.userId}
        />
      );
    }
    if (history.location.pathname === `${initialRoute}/report/add`) {
      return <AddReport id={id || ""} />;
    }
    return (
      <Detail
        id={id || ""}
        pic={detailTask.pic.employee.id}
        userId={myProfile.userId}
        typeList={type}
      />
    );
  };

  const _handleRenderTitleHeader = () => {
    if (history.location.pathname === `${initialRoute}/report/edit`) {
      return t("task.detail.editReport");
    }
    if (history.location.pathname === `${initialRoute}/report/add`) {
      return t("task.detail.addReport");
    }
    return t("task.detail.taskDetail");
  };

  const _renderLabel = (status: number) => {
    if (status === 1) {
      return t("task.status.draft");
    }
    if (status === 2) {
      return t("task.status.issued");
    }
    if (status === 3) {
      return t("task.status.assigned");
    }
    if (status === 4) {
      return t("task.status.rejected");
    }
    if (status === 5) {
      return t("task.status.ongoing");
    }
    if (status === 6) {
      return t("task.status.finished");
    }
    if (status === 7) {
      return t("task.status.cancelled");
    }
    return "";
  };

  const _handleBack = () => {
    if (
      history.location.pathname.includes("edit") ||
      history.location.pathname.includes("add")
    ) {
      history.push(`/task/detail/${id}/${type}/report`);
    } else if (type === "assigned") {
      history.push("/task/assigned");
    } else if (type === "finished") {
      history.push("/task/finished");
    } else if (type === "ongoing") {
      history.push("/task/ongoing");
    } else if (type === "myrequest") {
      history.push("/task/myrequest");
    } else if (type === "moderation") {
      history.push("/task/moderation");
    } else {
      history.push("/task");
      dispatch(resetDetailTask());
    }
  };

  return (
    <Switch>
      <Container>
        {detailTask ? (
          <View>
            <Header
              textHeader={{ fontSize: 18 }}
              subTitleHeader={{ fontSize: 11 }}
              title={_handleRenderTitleHeader()}
              subtitle={detailTask ? detailTask.number : ""}
              label={_renderLabel(detailTask.status)}
              goBack={_handleBack}
            />
            <View
              style={{
                flex: 1,
                alignItems: "center",
                width: "100%"
              }}
            >
              {history.location.pathname === `${initialRoute}/report/edit` ||
              history.location.pathname === `${initialRoute}/report/add` ? (
                <></>
              ) : (
                <View style={styles.containerContent}>
                  <TouchableOpacity
                    style={[
                      history.location.pathname === `${initialRoute}/report`
                        ? null
                        : styles.active,
                      detailTask.status !== 2 && detailTask.status !== 1
                        ? styles.buttonNav
                        : styles.buttonNavFull
                    ]}
                    onPress={() => {
                      history.push(initialRoute);
                    }}
                  >
                    <Text style={styles.textButton}>
                      {t("task.detail.detailTab")}
                    </Text>
                  </TouchableOpacity>
                  {detailTask.status !== 2 && (
                    <TouchableOpacity
                      style={[
                        styles.buttonNav,
                        history.location.pathname === `${initialRoute}/report`
                          ? styles.active
                          : null
                      ]}
                      onPress={() => {
                        history.push(`${initialRoute}/report`);
                      }}
                    >
                      <Text style={styles.textButton}>
                        {" "}
                        {t("task.detail.reportTab")}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              <View style={styles.content}>{_handleRenderForm()}</View>
            </View>
          </View>
        ) : null}
      </Container>
    </Switch>
  );
};
export default TaskDetail;
