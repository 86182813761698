import { Slide, toast } from "react-toastify";
import i18n from "i18next";
import { Dispatch, GetState } from "../types";
import { API, BodyPutCloseBill } from "../../configs";
import { handleLogin } from ".";

export const GET_LIST_CASH_ADVANCE_BILL_PENDING =
  "GET_LIST_CASH_ADVANCE_BILL_PENDING";
export const GET_LIST_CASH_ADVANCE_BILL_SUCCESS =
  "GET_LIST_CASH_ADVANCE_BILL_SUCCESS";
export const GET_LIST_CASH_ADVANCE_BILL_ERROR =
  "GET_LIST_CASH_ADVANCE_BILL_ERROR";
export const RESET_DONE_LIST_CASH_ADVANCE_BILL =
  "RESET_DONE_LIST_CASH_ADVANCE_BILL";

export const POST_NEW_BILL_PENDING = "POST_NEW_BILL_PENDING";
export const POST_NEW_BILL_SUCCESS = "POST_NEW_BILL_SUCCESS";
export const POST_NEW_BILL_ERROR = "POST_NEW_BILL_ERROR";

export const PUT_BILLABLE_PENDING = "PUT_BILLABLE_PENDING";
export const PUT_BILLABLE_SUCCESS = "PUT_BILLABLE_SUCCESS";
export const PUT_BILLABLE_ERROR = "PUT_BILLABLE_ERROR";

export const PUT_CLOSEBILL_PENDING = "PUT_CLOSEBILL_PENDING";
export const PUT_CLOSEBILL_SUCCESS = "PUT_CLOSEBILL_SUCCESS";
export const PUT_CLOSEBILL_ERROR = "PUT_CLOSEBILL_ERROR";

export const EMPTY_LIST_CASH_ADVANCE_BILL = "EMPTY_LIST_CASH_ADVANCE_BILL";

export const GET_DETAIL_BILLS_PENDING = "GET_DETAIL_BILLS_PENDING";
export const GET_DETAIL_BILLS_SUCCESS = "GET_DETAIL_BILLS_SUCCESS";
export const GET_DETAIL_BILLS_FAILED = "GET_DETAIL_BILLS_FAILED";

export const EDIT_DETAIL_BILLS_PENDING = "EDIT_DETAIL_BILLS_PENDING";
export const EDIT_DETAIL_BILLS_SUCCESS = "EDIT_DETAIL_BILLS_SUCCESS";
export const EDIT_DETAIL_BILLS_FAILED = "EDIT_DETAIL_BILLS_FAILED";

export const DELETE_BILL_PENDING = "DELETE_BILL_PENDING";
export const DELETE_BILL_SUCCESS = "DELETE_BILL_SUCCESS";
export const DELETE_BILL_FAILED = "DELETE_BILL_FAILED";

export const EMPTY_DETAIL_BILLS = "EMPTY_DETAIL_BILLS";
export const RESET_BILL = "RESET_BILL";

export const emptyDetailBills = () => (dispatch: any) => {
  dispatch({ type: EMPTY_DETAIL_BILLS });
};
export const getListCashAdvanceBill = (
  tripId: string,
  categoryId: string
) => async (dispatch: any, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_LIST_CASH_ADVANCE_BILL_PENDING });

    const res = await API.getBillsByTripId(tripId, categoryId, auth.token);
    dispatch({
      type: GET_LIST_CASH_ADVANCE_BILL_SUCCESS,
      payload: { data: res.data.data[0].expense, mainData: res.data.data[0] }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() =>
            dispatch(getListCashAdvanceBill(tripId, categoryId))
          )
        );
      } else {
        dispatch({
          type: GET_LIST_CASH_ADVANCE_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_LIST_CASH_ADVANCE_BILL_ERROR });
  }
};

export const postNewBill = (form: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_BILL_PENDING });

    const _form = new FormData();
    Object.keys(form).forEach(el => {
      _form.append(el, form[el]);
    });

    const res = await API.postNewBill(_form, auth.token);
    dispatch({
      type: POST_NEW_BILL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postNewBill(form))));
      } else {
        dispatch({
          type: POST_NEW_BILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_NEW_BILL_ERROR });
  }
};

export const putBillable = (form: any, id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_BILLABLE_PENDING });

    let _form: any = form;
    _form = { ..._form, _method: "PUT" };

    const res = await API.postBillable(_form, id, auth.token);
    dispatch({
      type: PUT_BILLABLE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putBillable(form, id))));
      } else {
        dispatch({
          type: PUT_BILLABLE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_BILLABLE_ERROR });
  }
};

export const putCloseBill = (form: BodyPutCloseBill) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: PUT_CLOSEBILL_PENDING });

    const res = await API.putCloseBill(form, auth.token);
    dispatch({
      type: PUT_CLOSEBILL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(putCloseBill(form))));
      } else {
        dispatch({
          type: PUT_CLOSEBILL_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: PUT_CLOSEBILL_ERROR });
  }
};

export const resetDoneListCashAdvanceBill = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_DONE_LIST_CASH_ADVANCE_BILL });
};

export const emptyListCashAdvanceBill = () => (dispatch: Dispatch) => {
  dispatch({ type: EMPTY_LIST_CASH_ADVANCE_BILL });
};

export const resetBill = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_BILL });
};

export const getDetailBills = (params: any) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_DETAIL_BILLS_PENDING });

    const res = await API.getDetailBills(params, auth.token);
    dispatch({
      type: GET_DETAIL_BILLS_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getDetailBills(params))));
      } else {
        dispatch({
          type: GET_DETAIL_BILLS_FAILED,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_DETAIL_BILLS_FAILED });
  }
};

export const editDetailBills = (
  body: any,
  params: any,
  cb?: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { auth } = getState();
    dispatch({ type: EDIT_DETAIL_BILLS_PENDING });

    const res = await API.editDetailBills(body, params, auth.token);

    dispatch({
      type: EDIT_DETAIL_BILLS_SUCCESS,
      payload: { data: res.data.data }
    });
    cb && cb();
    toast.success(i18n.t("cash.new.msg.toastSuccess"), {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(editDetailBills(body, params))));
      } else {
        toast.error(i18n.t("cash.new.msg.toastFailed"), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: EDIT_DETAIL_BILLS_FAILED,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: EDIT_DETAIL_BILLS_FAILED });
  }
};

export const deleteBill = (uuid: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: DELETE_BILL_PENDING });

    const res = await API.deleteBill(uuid, auth.token);
    dispatch({
      type: DELETE_BILL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(deleteBill(uuid))));
      } else {
        dispatch({
          type: DELETE_BILL_FAILED,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: DELETE_BILL_FAILED });
  }
};
