import { Slide, toast } from "react-toastify";

import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { getKasbonSetting, handleLogin } from ".";

export const GET_MY_PROFILE_PENDING = "GET_MY_PROFILE_PENDING";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_ERROR = "GET_MY_PROFILE_ERROR";

export const GET_MY_DASHBOARD_PENDING = "GET_MY_DASHBOARD_PENDING";
export const GET_MY_DASHBOARD_SUCCESS = "GET_MY_DASHBOARD_SUCCESS";
export const GET_MY_DASHBOARD_ERROR = "GET_MY_DASHBOARD_ERROR";

export const GET_MENU_PENDING = "GET_MENU_PENDING";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_ERROR = "GET_MENU_ERROR";

export const POST_FEEDBACK_PENDING = "POST_FEEDBACK_PENDING";
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS";
export const POST_FEEDBACK_ERROR = "POST_FEEDBACK_ERROR";

export const SET_CONFIRMATION_MODAL = "SET_CONFIRMATION_MODAL";

export const getMyProfile = (cb?: (el: boolean) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MY_PROFILE_PENDING });

    const res = await API.getMyProfile(auth.token);
    dispatch({
      type: GET_MY_PROFILE_SUCCESS,
      payload: { data: res.data.data }
    });

    const isLoanUser = res.data.data.employee.roles.find(
      (item: any) => item.name === "personal-loan-user"
    );

    if (isLoanUser) {
      dispatch(getKasbonSetting());
    }

    cb && cb(res.data.data.employee.position.has_children);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getMyProfile())));
      } else {
        dispatch({
          type: GET_MY_PROFILE_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_MY_PROFILE_ERROR });
  }
};

export const getMyDashboard = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MY_DASHBOARD_PENDING });

    const res = await API.getMyDashboard(auth.token);
    dispatch({
      type: GET_MY_DASHBOARD_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getMyDashboard())));
      } else {
        dispatch({
          type: GET_MY_DASHBOARD_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_MY_DASHBOARD_ERROR });
  }
};

export const getMenu = (mod: "dashboard" | "general" | "report") => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: GET_MENU_PENDING });

    const res = await API.getMenu(mod, auth.token);
    dispatch({
      type: GET_MENU_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(getMenu(mod))));
      } else {
        dispatch({
          type: GET_MENU_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: GET_MENU_ERROR });
  }
};

export const setModalConfirmation = (flag: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CONFIRMATION_MODAL,
    payload: { data: flag }
  });
};

export const postFeedBack = (body: any, cb?: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_FEEDBACK_PENDING });
    const formData = new FormData();

    Object.keys(body).map(attribute => {
      if (body[attribute] !== "") {
        formData.append(attribute, body[attribute]);
      } else {
        formData.append(attribute, "");
      }
    });
    const res = await API.postFeedBack(formData, auth.token);
    dispatch({
      type: POST_FEEDBACK_SUCCESS,
      payload: { data: res.data.data }
    });
    toast.success(
      "Thank you for sending us a message. We will review your inquiry and will reply directly to your email address",
      {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-toast",
        closeButton: false,
        draggable: false
      }
    );
    setTimeout(() => {
      cb && cb();
    }, 4200);
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(handleLogin(() => dispatch(postFeedBack(body))));
      } else {
        toast.error(error.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-toast",
          closeButton: false,
          draggable: false
        });
        dispatch({
          type: POST_FEEDBACK_ERROR,
          payload: { data: err.response }
        });
      }
    }
    dispatch({ type: POST_FEEDBACK_ERROR });
  }
};
