import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import "./style.css";
import { Datepicker, Select, Touchable } from "../../../components";
import { convertDate, maskedMoney, utcDate } from "../../../utils";
import { NotifError } from "../components";

// redux action & types
import {
  handleResetFlight,
  setFormNewBookingFlight
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const listFacility = [
  { label: "No Luggage", value: "0" },
  { label: "Luggage 10Kg", value: "10" },
  { label: "Luggage 20Kg", value: "20" }
];

const selectClass = [
  { label: "Economy", value: "economy" },
  { label: "Any", value: "any" }
];

interface Props {
  isError: boolean;
}

const Round = ({ isError }: Props) => {
  const dispatch = useDispatch();
  const { dataState, regionState } = useSelector(
    (state: Reducers) => ({
      dataState: state.newBookingFlight,
      regionState: state.region
    }),
    shallowEqual
  );

  const regionPopulate = regionState.region.map((e: any) => {
    return { value: e.id, label: e.name };
  });

  const [flight, setFlight] = useState([
    {
      title: "Flight 1",
      cityFrom: "",
      cityFromId: "",
      cityTo: "",
      cityToId: "",
      regionFrom: "Soekarno Hatta",
      regionTo: "Ngurah Rai",
      dateFrom: convertDate(undefined, "YYYY-MM-DD"),
      dateTo: "",
      timeFrom: "any",
      timeTo: "any"
    },
    {
      title: "Flight 2",
      cityFrom: "",
      cityFromId: "",
      cityTo: "",
      cityToId: "",
      regionFrom: "Soekarno Hatta",
      regionTo: "Kualanamu",
      dateFrom: convertDate(undefined, "YYYY-MM-DD"),
      dateTo: "",
      timeFrom: "any",
      timeTo: "any"
    }
  ]);

  const setForm = (
    formName: string,
    value: string | boolean,
    index: number
  ) => {
    dispatch(setFormNewBookingFlight({ formName, value }, index));
  };

  const conv = (f: any) => {
    let n: any = [];
    f.forEach(
      (item: {
        dateFrom: string;
        cityFrom: string;
        cityFromId: string;
        timeFrom: string;
        cityTo: string;
        cityToId: string;
        timeTo: string;
      }) => {
        n = [
          ...n,
          {
            date: item.dateFrom,
            departure: {
              location: item.cityFrom,
              time: item.timeFrom,
              id: item.cityFromId
            },
            arrival: {
              location: item.cityTo,
              time: item.timeTo,
              id: item.cityFromId
            }
          }
        ];
      }
    );
    return n;
  };

  useEffect(() => {
    dispatch(handleResetFlight());
  }, [dispatch]);

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [showDatepickerNav, setShowDatepickerNav] = useState(0);

  const getMonth = (m: number) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[m];
  };

  const dateFormated = (date: string) => {
    const dt = new Date(date);
    return `${dt.getDate()} ${getMonth(dt.getMonth())}`;
  };

  const handleEllipsisText = (text: string) => {
    let res = "";
    if (text.length > 10) {
      res = text.slice(0, 9);
      res += "...";
    } else {
      res = text;
    }
    return res;
  };

  const renderHeaderDatepicker = (
    nav: number,
    setNav: (number: number) => void
  ) => (
    <View style={styles.datePickerNavWrap}>
      <View
        style={[
          styles.datePickerNav,
          nav === 0 ? styles.datePickerNavActive : {}
        ]}
      >
        <Touchable onPress={() => (setNav ? setNav(0) : null)}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "bold" }}>Flight 1</Text>
          </View>
        </Touchable>
      </View>
      <View
        style={[
          styles.datePickerNav,
          nav === 1 ? styles.datePickerNavActive : {}
        ]}
      >
        <Touchable onPress={() => (setNav ? setNav(1) : null)}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "bold" }}>Flight 2</Text>
          </View>
        </Touchable>
      </View>
    </View>
  );

  const onChangeDatePicker = (value: any) => {
    // eslint-disable-next-line prefer-const
    let f = flight;

    f[0].cityFrom = value[0].departure.location;
    f[0].cityFromId = value[0].departure.id;
    f[0].cityTo = value[0].arrival.location;
    f[0].cityToId = value[0].arrival.id;
    f[1].cityFrom = value[0].departure.location;
    f[1].cityFromId = value[0].departure.id;
    f[1].cityTo = value[0].arrival.location;
    f[1].cityToId = value[0].arrival.id;
    f[0].timeFrom = value[0].departure.time || "any";
    f[0].timeTo = value[0].arrival.time || "any";
    f[1].timeFrom = value[1].departure.time || "any";
    f[1].timeTo = value[1].arrival.time || "any";
    f[0].dateFrom = value[0].date;
    f[1].dateFrom = value[1].date;

    f.forEach((item, index) => {
      dispatch(
        setFormNewBookingFlight(
          {
            formName: "started_at_t",
            value: value[0].departure.time || "any"
          },
          0
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "finished_at_t",
            value: value[0].arrival.time || "any"
          },
          0
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "started_at_t",
            value: value[0].departure.time || "any"
          },
          1
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "finished_at_t",
            value: value[0].arrival.time || "any"
          },
          1
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "from",
            value: value[0].departure.id
          },
          0
        )
      );

      dispatch(
        setFormNewBookingFlight(
          { formName: "to", value: value[0].arrival.id },
          0
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "to",
            value: value[0].arrival.id
          },
          1
        )
      );

      dispatch(
        setFormNewBookingFlight(
          { formName: "from", value: value[0].departure.id },
          1
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "started_at_d",
            value: utcDate(`${value[0].date} 00:00`)
          },
          index
        )
      );

      dispatch(
        setFormNewBookingFlight(
          {
            formName: "finished_at_d",
            value: utcDate(`${value[1].date} 00:00`)
          },
          index
        )
      );
    });

    dispatch(
      setFormNewBookingFlight(
        {
          formName: "is_round_trip",
          value: true
        },
        0
      )
    );

    dispatch(
      setFormNewBookingFlight(
        {
          formName: "started_at",
          value: utcDate(`${value[0].date} 00:00`)
        },
        0
      )
    );
    dispatch(
      setFormNewBookingFlight(
        {
          formName: "finished_at",
          value: utcDate(`${value[1].date} 00:00`)
        },
        0
      )
    );

    setFlight(f);
    setFlight(f);
  };

  const _setLuggage = (value: string, i: number) => {
    if (value === "0") {
      setForm("baggage", value, i);
      setForm("has_baggage", false, i);
    } else if (value !== "0") {
      setForm("baggage", value, i);
      setForm("has_baggage", true, i);
    }
  };

  const _notifError = (showError: boolean, textError: string) => (
    <NotifError showError={isError && showError} textError={textError} />
  );

  return (
    <View>
      <View>
        <Datepicker
          validate
          value={conv(flight)}
          style={{
            zIndex: 10
          }}
          travelingV2
          region={regionPopulate}
          visible={showDatepicker}
          onChangeVisible={e => {
            setShowDatepicker(e);
          }}
          showNav={showDatepickerNav}
          head={renderHeaderDatepicker}
          onChange={onChangeDatePicker}
          flight
        />
        <View
          style={{
            zIndex: 1
          }}
        >
          <View style={styles.wrapFormFlight}>
            <Text style={styles.titleTrip}>{flight[0].title}</Text>
            <View style={styles.formFlight}>
              <View style={styles.rowFormFlight}>
                <View style={styles.wrapInput}>
                  <Text style={styles.textTitleInput}>Airlines</Text>
                  <View style={styles.wrapSelect}>
                    {!dataState.isLoadingAirline &&
                    dataState.listAirline.length > 0 ? (
                      <Select
                        listValue={dataState.listAirline}
                        defaultValue={() =>
                          setForm(
                            "airline_id",
                            dataState.listAirline[0].value,
                            0
                          )
                        }
                        value={dataState.form.airline_id}
                        onChange={e => setForm("airline_id", e.target.value, 0)}
                      />
                    ) : (
                      <ActivityIndicator color="#344370" />
                    )}
                  </View>
                </View>
                <View style={[styles.wrapInput, { marginLeft: 30 }]}>
                  <Text style={styles.textTitleInput}>Class</Text>
                  <View style={styles.wrapSelect}>
                    <Select
                      listValue={selectClass}
                      value={dataState.form.class_id}
                      onChange={e => setForm("class_id", e.target.value, 0)}
                    />
                  </View>
                </View>
              </View>
              <View style={[styles.rowFormFlight, { marginTop: 14 }]}>
                <View style={styles.wrapInput}>
                  <Text style={styles.textTitleInput}>
                    Luggage
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 11,
                        fontStyle: "italic"
                      }}
                    >
                      {`${" "}(optional)`}
                    </Text>
                  </Text>
                  <View style={styles.wrapSelect}>
                    <Select
                      listValue={listFacility}
                      onChange={e => _setLuggage(e.target.value, 0)}
                    />
                  </View>
                </View>
                <View style={[styles.wrapInput, { marginLeft: 30 }]}>
                  <Text style={styles.textTitleInput}>Budget Allocation</Text>
                  <View style={{ marginTop: 3 }}>
                    <Text style={styles.textTitleInput}>
                      IDR
                      <Text
                        style={[
                          styles.textTitleInput,
                          { fontFamily: "Poppins", fontWeight: "bold" }
                        ]}
                      >
                        {` ${maskedMoney(3500000)}`}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginBottom: 27 }}>
            <Touchable
              onPress={() => {
                setShowDatepickerNav(0);
                setShowDatepicker(!showDatepicker);
              }}
            >
              <>
                <div className="box-one">
                  <div>
                    <div>
                      <p className="city-from-one">
                        {handleEllipsisText(flight[0].cityFrom) || "-"}
                      </p>
                      <p className="region-one">
                        {handleEllipsisText(flight[0].cityFrom) || "-"}
                      </p>
                    </div>
                    <div>
                      <p className="city-from-one">
                        {flight[0].dateFrom !== ""
                          ? dateFormated(flight[0].dateFrom)
                          : "-"}
                      </p>
                      <p className="region-one">{flight[0].timeFrom}</p>
                    </div>
                  </div>

                  <div>
                    <div>
                      <p className="city-from-one city-to-one">
                        {handleEllipsisText(flight[0].cityTo) || "-"}
                      </p>
                      <p className="region-one">
                        {handleEllipsisText(flight[0].cityTo) || "-"}
                      </p>
                    </div>
                    <div>
                      <p className="city-from-one city-to-one">
                        {flight[0].dateFrom !== ""
                          ? dateFormated(flight[1].dateFrom)
                          : "-"}
                      </p>
                      <p className="region-one">{flight[0].timeTo}</p>
                    </div>
                  </div>
                </div>
              </>
            </Touchable>
            {_notifError(dataState.form.from === "", "From is Required")}
            {_notifError(dataState.form.to === "", "To is Required")}
            {_notifError(
              dataState.form.started_at_d === "",
              "Date is Required"
            )}
          </View>
        </View>
      </View>

      {/* <View>
        <Text style={styles.flightTitle}>Facility</Text>
        <FlatList
          style={{}}
          numColumns={2}
          data={listFacility}
          keyExtractor={(item, 0) => index.toString()}
          extraData={dataState}
          renderItem={({ item }) => (
            <View
              style={[
                styles.buttonFacility,
                item.value === dataState.form.baggage && {
                  backgroundColor: "#50b1b1"
                }
              ]}
            >
              <Touchable onPress={() => setForm("baggage", item.value)}>
                <View
                  style={{
                    paddingVertical: 10,
                    paddingLeft: 15
                  }}
                >
                  <Text
                    style={
                      item.value === dataState.form.baggage
                        ? { color: "white" }
                        : { color: "#666666" }
                    }
                  >
                    {item.facility}
                  </Text>
                </View>
              </Touchable>
            </View>
          )}
        />
      </View> */}
    </View>
  );
};

export default Round;
