import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  bookingDetailContainer: {
    flex: 1
  },
  bookingpDetailContent: {
    paddingHorizontal: 20
  },
  cardDescription: {
    marginTop: 15,
    paddingLeft: 21,
    paddingTop: 8,
    paddingBottom: 12,
    paddingRight: 20
  },
  desciption: {
    flexDirection: "row",
    marginBottom: 8,
    marginTop: 8
  },
  titleDescription: {
    width: "25%",
    fontSize: 11,
    color: "#999999"
  },
  detailDescription: {
    width: "75%"
  },
  fabStyle: {
    alignItems: "center",
    justifyContent: "center"
  },
  fabIcon: {
    width: 80,
    height: 80
  },
  footer: {
    flexDirection: "row",
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "space-between",
    paddingVertical: 15
  },
  containAmount: {
    width: "50%",
    alignItems: "center"
  },
  titleAmount: {
    color: "white",
    fontSize: 11,
    marginBottom: 8
  },
  descriptionCity: {
    marginBottom: 0,
    marginTop: 0
  },
  detailDescriptionText: {
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins"
  },
  titleFlight: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    marginBottom: 6
  },
  descDate: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#666666"
  },
  cardButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: 11,
    borderRadius: 50,
    marginHorizontal: 6
  },
  cardButtonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 30
  },
  titleHotel: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: "#666666",
    marginBottom: 16
  },

  star: {
    width: 9.5,
    height: 9.5,
    marginRight: 5
  }
});

export default styles;
