import { StyleSheet } from "react-native";

import { COLORS } from "../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  wrapNavigation: {
    paddingTop: 15,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: COLORS.main,
    marginHorizontal: -10,
    paddingHorizontal: 10
  },
  navigation: {
    flexDirection: "row",
    borderRadius: 50,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2
  },
  btnNav: {
    height: "100%",
    borderRadius: 50
  },
  active: {
    borderRadius: 50,
    height: "100%",
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)"
  },
  textButton: {
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11
  },
  textHeader: {
    fontSize: 20,
    marginTop: 5
  },
  activeFirst: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  activeMid: {
    borderRadius: 0
  },
  activeLast: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  wrapFooter: {
    backgroundImage: "linear-gradient(to top, #fffff7 88%,  transparent)",
    paddingBottom: 16,
    paddingTop: 10,
    flexDirection: "row",
    paddingLeft: 28,
    alignItems: "center"
  },
  shadow: {
    borderRadius: 50,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 5,
    elevation: 2,
    marginHorizontal: 1,
    backgroundColor: COLORS.main,
    height: "fit-content",
    marginRight: 14
  },
  btn: {
    height: 48,
    paddingHorizontal: 20,
    justifyContent: "center"
  },
  btnText: {
    color: "#707077",
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "bold"
  },
  orange: {
    backgroundColor: "none",
    backgroundImage: "linear-gradient(to top, #DB3A26,  #F05A28, #FAAF40)"
  },
  white: {
    color: "#fff"
  }
});

export default styles;
