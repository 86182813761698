import { StyleSheet } from "react-native";

import { COLORS } from "../../../../configs";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20
  },
  text: {
    fontSize: 11,
    width: "22%",
    color: "#999999",
    fontFamily: "Poppins"
  },
  textDesc: {
    width: "65%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  btnReport: {
    width: 28,
    height: 28,
    backgroundColor: "#fffff7",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  footer: {
    height: 74,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  budgetReq: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#fffff7"
  },
  priceIdr: {
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#FFFFF7",
    marginTop: 2
  },
  wrapStatus: {
    backgroundColor: "#50b1b1",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 12
  },
  wrapDetailStatus: {
    width: "50%",
    alignItems: "center"
  },
  textStatus: {
    color: "#fffff7",
    fontSize: 11,
    fontFamily: "Poppins"
  },
  submit: {
    backgroundImage:
      "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
    borderRadius: 100,
    width: 180,
    height: 54,
    marginBottom: 20,
    alignSelf: "center"
  },
  submitTextWrap: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  submitText: {
    color: "#FFF",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18
  },
  cardStyle: {
    marginBottom: 12
  },
  wrapContentCard: {
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 12
  },
  wrapCardText: {
    flexDirection: "row",
    marginBottom: 4
  },
  wrapText: {
    flexDirection: "row",
    marginBottom: 4,
    paddingHorizontal: 16
  },
  textLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11,
    width: "22%"
  },
  textValue: {
    width: "55%",
    marginRight: 7,
    color: COLORS.grey
  },
  bold: {
    fontWeight: "bold",
    color: COLORS.grey
  },
  wrapAmountText: {
    paddingHorizontal: 20,
    marginTop: -20
  },
  amountText: {
    color: COLORS.green,
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right"
  },
  wrapTrashIcon: {
    height: 26,
    width: 26,
    borderRadius: 20,
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8
  },
  wrapButtonEmpty: {
    flexDirection: "row",
    justifyContent: "center",
    width: "90%"
  },
  wrapIcon: {
    alignSelf: "flex-end",
    flexDirection: "row",
    width: "20%",
    justifyContent: "flex-end"
  },
  wrapTotal: {
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 12,
    marginBottom: 90
  },
  totalLabel: {
    color: COLORS.greyLight,
    fontFamily: "Poppins",
    fontSize: 11
  },
  button: {
    width: "48%"
  },
  button2: {
    width: "100%",
    alignItems: "center",
    paddingVertical: 4
  },
  button3: {
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #faaf40, #f05a28 63%, #db3a26)"
  },
  buttonText: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: COLORS.grey,
    fontWeight: "bold"
  },
  buttonText2: {
    color: COLORS.white
  },
  wrapButtonAdd: {
    alignItems: "center"
  },
  emptyData: {
    color: "#999999",
    fontSize: 16,
    fontFamily: "Poppins"
  },
  line: {
    borderBottomWidth: 1,
    borderColor: COLORS.greyLight,
    marginTop: 8
  }
});

export default styles;
