/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Cookies from "js-cookie";
import { getBudgetRequest, getPerDiem } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

import { WrapContent, WrapList } from "../../../../components";
import styles from "./styles";
import { copyWritings, maskedMoney } from "../../../../utils";

interface Props {
  id: string;
}

const Perdiem = ({ id }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const ExpenseState = useSelector((state: Reducers) => state.newExpenseTrip);
  const lang = Cookies.get("locale") || "en";

  useEffect(() => {
    dispatch(getPerDiem(id));
    dispatch(getBudgetRequest(id, lang));
  }, [dispatch]);

  const _totalItem = () => {
    if (ExpenseState.listPerDiem.length > 0) {
      return ExpenseState.listPerDiem.reduce((acc, current) => ({
        total: Number(acc.total) + Number(current.total)
      })).total;
    }
    return 0;
  };

  const copTeks = {
    lump_sum: copyWritings("lump_sum")
  };

  return (
    <WrapContent isLoading={ExpenseState.isLoadingListPerDiem}>
      <WrapList
        isEmpty={ExpenseState.listPerDiem.length === 0}
        emptyComponent={() => (
          <Text style={styles.emptyData}>{t("cash.new.noData")}</Text>
        )}
      >
        <FlatList
          style={{ paddingHorizontal: 20 }}
          data={ExpenseState.listPerDiem}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <>
              <View
                style={[{ marginBottom: 16 }, index === 0 && { marginTop: 16 }]}
                key={index}
              >
                <View style={styles.wrapText}>
                  <Text style={styles.textLabel}>
                    {t("activity.detail.date")}
                  </Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    {item ? moment(item.started_at).format("DD MMM YYYY") : "-"}
                  </Text>
                </View>
                <View style={styles.wrapText}>
                  <Text style={styles.textLabel}>
                    {t("activity.detail.type")}
                  </Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    {item ? item.category : "-"}
                  </Text>
                </View>
                <View style={styles.wrapText}>
                  <Text style={styles.textLabel}>
                    {t("activity.detail.desc")}
                  </Text>
                  <Text
                    style={[styles.textLabel, styles.textValue, styles.bold]}
                  >
                    {item ? item.title : "-"}
                  </Text>
                </View>
                <View style={styles.wrapAmountText}>
                  <Text style={styles.amountText}>
                    <Text style={{ fontWeight: "normal" }}>IDR </Text>
                    {maskedMoney(item.total)}
                  </Text>
                </View>
                <View style={styles.line} />
              </View>
            </>
          )}
        />
        <View style={styles.wrapTotal}>
          <Text style={styles.totalLabel}>
            {sprintf(t("activity.total"), copTeks.lump_sum)}
          </Text>
          <Text style={styles.amountText}>
            <Text style={{ fontWeight: "normal" }}>IDR </Text>
            {maskedMoney(_totalItem())}
          </Text>
        </View>
      </WrapList>
    </WrapContent>
  );
};

export default Perdiem;
