import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import "./style.css";
import {
  Datepicker,
  Select,
  Touchable,
  WrapContent
} from "../../../../../components";
import { maskedMoney } from "../../../../../utils";

// redux action & types
import {
  getAirline,
  getBookingSourceFlight,
  getDetailBookingFlight,
  getRegion,
  getVendor,
  putBookingFlight,
  setFormDetailBookingFlight
} from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";

const listFacility = [
  { label: "Luggage 10Kg", value: "10" },
  { label: "Luggage 20Kg", value: "20" }
];

const selectClass = [
  { label: "Economy", value: "economy" },
  { label: "Any", value: "any" }
];

interface Props {
  id: string;
  search: string;
}

const Edit = ({ id, search }: Props) => {
  const history = useHistory();
  const { idBooking } = useParams();
  const dispatch = useDispatch();
  const {
    dataState,
    regionState,
    vendorState,
    detailBookingState
  } = useSelector(
    (state: Reducers) => ({
      dataState: state.newBookingFlight,
      regionState: state.region,
      vendorState: state.vendor,
      detailBookingState: state.detailBooking
    }),
    shallowEqual
  );
  const [widthTextButton, setWidthTextButton] = useState(0);
  const [heightTextButton, setHeightTextButton] = useState(0);

  const regionPopulate = regionState.region.map((e: any) => {
    return { value: e.id, label: e.name };
  });

  const [flight, setFlight] = useState([
    {
      title: "Flight 1",
      cityFrom: "",
      cityTo: "",
      regionFrom: "Soekarno Hatta",
      regionTo: "Ngurah Rai",
      dateFrom: "",
      dateTo: "",
      timeFrom: "",
      timeTo: ""
    }
  ]);

  useEffect(() => {
    dispatch(getBookingSourceFlight());
    dispatch(getAirline());
    dispatch(getVendor());
    dispatch(getRegion());
    dispatch(getDetailBookingFlight(idBooking!));
  }, []);

  const setForm = (formName: string, value: number | string) => {
    dispatch(setFormDetailBookingFlight({ formName, value }));
  };

  const vendorSelect = vendorState.vendors.map((e: any) => {
    return { value: e.id, label: e.title };
  });

  const [showDatepicker, setShowDatepicker] = useState([false, false]);
  const conv = (f: any) => {
    let n: any = [];
    f.forEach(
      (item: {
        dateFrom: string;
        cityFrom: string;
        timeFrom: string;
        cityTo: string;
        timeTo: string;
      }) => {
        n = [
          ...n,
          {
            date: item.dateFrom,
            departure: {
              location: item.cityFrom,
              id: ""
            },
            arrival: {
              location: item.cityTo,
              id: ""
            },
            time: item.timeFrom
          }
        ];
      }
    );
    return n;
  };

  const onChangeDatePicker = (value: any) => {
    const f = flight;
    f.forEach((item, index) => {
      f[index].dateFrom = value[index].date;
      f[index].cityFrom = value[index].departure.location;
      f[index].cityTo = value[index].arrival.location;
      f[index].timeFrom = value[index].time;
    });
    setFlight(f);
    setFlight(f);
    setForm("started_at_d", value[0].date);
    setForm("started_at_t", value[0].time);
    setForm("finished_at_d", value[0].date);
    setForm("finished_at_t", value[0].time);
    setForm("from", value[0].departure.id);
    setForm("to", value[0].arrival.id);
  };

  const renderHeaderDatepicker = (
    nav: number,
    setNav: (number: number) => void
  ) => (
    <View style={styles.datePickerNavWrap}>
      <View style={[styles.datePickerNav]}>
        <Touchable onPress={() => (setNav ? setNav(0) : null)}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "bold" }}>Flight 1</Text>
          </View>
        </Touchable>
      </View>
    </View>
  );

  const _regionName = (idRegion: string) => {
    if (regionState.region.length > 0) {
      const index = regionState.region.findIndex(e => e.id === idRegion);
      return regionState.region[index].name;
    }

    return "";
  };

  const _airlines = (name: string) => {
    const index = dataState.listAirline.findIndex(e => e.label.includes(name));
    return dataState.listAirline[index].value;
  };

  return (
    <WrapContent isLoading={detailBookingState.isLoadingDetailBookingFlight}>
      <View style={styles.wrapVendor}>
        <Text style={styles.textTitleInput}>Vendor</Text>
        <View style={styles.wrapSelectVendor}>
          {!vendorState.isLoadingVendor && vendorState.vendors.length !== 0 ? (
            <Select
              listValue={vendorSelect}
              defaultValue={() => setForm("vendor_id", vendorSelect[0].value)}
            />
          ) : (
            <ActivityIndicator color="#344370" />
          )}
        </View>
      </View>
      {flight.map((item, index) => {
        return (
          <View
            style={{ marginBottom: 27, zIndex: flight.length - index }}
            key={index}
          >
            <Datepicker
              flightOneWay
              validate
              value={conv(flight)}
              style={{ zIndex: 1 }}
              travelingV2
              region={regionPopulate}
              visible={showDatepicker[index]}
              onChangeVisible={e => {
                const val = showDatepicker;
                val[index] = e;
                setShowDatepicker([...showDatepicker]);
              }}
              showNav={index}
              head={renderHeaderDatepicker}
              onChange={onChangeDatePicker}
              flight
            />
            <View style={styles.wrapFormFlight}>
              <Text style={styles.titleTrip}>{item.title}</Text>
              <View style={styles.formFlight}>
                <View style={styles.rowFormFlight}>
                  <View style={styles.wrapInput}>
                    <Text style={styles.textTitleInput}>Airlines</Text>
                    <View style={styles.wrapSelect}>
                      {!dataState.isLoadingAirline &&
                      dataState.listAirline.length > 0 &&
                      detailBookingState.detailBookingFlight.data ? (
                        <Select
                          listValue={dataState.listAirline}
                          defaultValue={() =>
                            setForm(
                              "airline_id",
                              _airlines(
                                detailBookingState.detailBookingFlight.data.name
                              )
                            )
                          }
                          value={
                            detailBookingState.formBookingFlight.airline_id
                          }
                          onChange={e => setForm("airline_id", e.target.value)}
                        />
                      ) : (
                        <ActivityIndicator color="#344370" />
                      )}
                    </View>
                  </View>
                  <View style={[styles.wrapInput, { marginLeft: 30 }]}>
                    <Text style={styles.textTitleInput}>Class</Text>
                    <View style={styles.wrapSelect}>
                      <Select
                        listValue={selectClass}
                        value={detailBookingState.formBookingFlight.class_id}
                        onChange={e => setForm("class_id", e.target.value)}
                      />
                    </View>
                  </View>
                </View>
                <View style={[styles.rowFormFlight, { marginTop: 14 }]}>
                  <View style={styles.wrapInput}>
                    <Text style={styles.textTitleInput}>
                      Luggage
                      <Text
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 11,
                          fontStyle: "italic"
                        }}
                      >
                        {`${" "}(optional)`}
                      </Text>
                    </Text>
                    <View style={styles.wrapSelect}>
                      <Select
                        listValue={listFacility}
                        onChange={e => setForm("baggage", e.target.value)}
                        defaultValue={() =>
                          setForm(
                            "baggage",
                            detailBookingState.formBookingFlight.baggage.toString()
                          )
                        }
                        value={detailBookingState.formBookingFlight.baggage.toString()}
                      />
                    </View>
                  </View>
                  <View style={[styles.wrapInput, { marginLeft: 30 }]}>
                    <Text style={styles.textTitleInput}>Budget Allocation</Text>
                    <View style={{ marginTop: 3 }}>
                      <Text style={styles.textTitleInput}>
                        IDR
                        <Text
                          style={[
                            styles.textTitleInput,
                            { fontFamily: "Poppins", fontWeight: "bold" }
                          ]}
                        >
                          {` ${maskedMoney(3500000)}`}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <Touchable
              onPress={() => {
                const val = showDatepicker;
                val[index] = !val[index];
                setShowDatepicker([...showDatepicker]);
              }}
            >
              <>
                <div className="box-one">
                  <div>
                    <div>
                      <p className="city-from-one">
                        {_regionName(detailBookingState.formBookingFlight.from)}
                      </p>
                      <p className="region-one">
                        {_regionName(detailBookingState.formBookingFlight.from)}
                      </p>
                    </div>
                    <div>
                      <p className="city-from-one">
                        {detailBookingState.formBookingFlight.started_at_d}
                      </p>
                      <p className="region-one">
                        {detailBookingState.formBookingFlight.started_at_t}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div>
                      <p className="city-from-one city-to-one">
                        {_regionName(detailBookingState.formBookingFlight.to)}
                      </p>
                      <p className="region-one">
                        {_regionName(detailBookingState.formBookingFlight.to)}
                      </p>
                    </div>
                    <div>
                      <p className="city-from-one city-to-one">-</p>
                      <p className="region-one">-</p>
                    </div>
                  </div>
                </div>
              </>
            </Touchable>

            <View
              style={{
                width: "100%",
                alignItems: "center"
              }}
            >
              <View style={styles.cardButton}>
                <Touchable
                  disabled={detailBookingState.isLoadingPutBookingFlight}
                  onPress={() =>
                    dispatch(
                      putBookingFlight(idBooking!, () =>
                        history.push(
                          `/trip-detail/${id}/booking/flight${search}`
                        )
                      )
                    )
                  }
                >
                  <View style={{ paddingVertical: 15, paddingHorizontal: 58 }}>
                    {!detailBookingState.isLoadingPutBookingFlight ? (
                      <Text
                        style={styles.textButtonSubmit}
                        onLayout={e => {
                          setWidthTextButton(e.nativeEvent.layout.width);
                          setHeightTextButton(e.nativeEvent.layout.height);
                        }}
                      >
                        SUBMIT
                      </Text>
                    ) : (
                      <ActivityIndicator
                        color="#FFFFFF"
                        style={{
                          width: widthTextButton,
                          height: heightTextButton
                        }}
                      />
                    )}
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        );
      })}
    </WrapContent>
  );
};

export default Edit;
