import React, { useEffect, useState } from "react";
import { ActivityIndicator, Image, Text, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import styles from "./styles";
import { ICONS, IMAGES } from "../../configs";
import { Container, Fixed, Touchable } from "../../components";

// redux action & types
import { getMyProfile, getPolicies, handleLogout } from "../../redux/actions";
import { Reducers } from "../../redux/types";

// screens
import Home from "./Home";

const HomeRoute = () => {
  const dispatch = useDispatch();
  const homeState = useSelector((state: Reducers) => state.home);
  const [headerHeight, setHeaderHight] = useState(0);

  // const match = useRouteMatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [language, setLanguage] = useState(Cookies.get("locale") || "en");
  const _changeLanguage = (e: any) => {
    const code = e;

    if (i18n.language !== code) {
      i18n.changeLanguage(code);
      Cookies.set("locale", code);
      setLanguage(code);
    }
  };

  useEffect(() => {
    dispatch(getMyProfile());
    dispatch(getPolicies());
  }, [dispatch]);

  return (
    <Container>
      <Fixed getHeight={value => setHeaderHight(value)}>
        <View style={styles.header}>
          <View style={styles.bubble} />
          <View style={styles.bubbleBottom} />
          <View style={styles.wrapButtonIcon}>
            <View style={styles.buttonIcon}>
              <Touchable onPress={() => history.push("/feedback")}>
                <Image
                  source={IMAGES.feedback}
                  resizeMode="contain"
                  style={styles.icon}
                />
              </Touchable>
            </View>
            <View style={styles.buttonIcon}>
              <Touchable onPress={() => history.push("/editprofile")}>
                <Image
                  source={IMAGES.avatars}
                  resizeMode="contain"
                  style={styles.icon}
                />
              </Touchable>
            </View>
            <View style={styles.buttonIcon}>
              <Touchable onPress={() => history.push("/shortcut")}>
                <Image
                  source={ICONS.iconNotification}
                  resizeMode="contain"
                  style={styles.icon}
                />
              </Touchable>
            </View>
            <View style={{ marginLeft: 8 }}>
              <Touchable onPress={() => dispatch(handleLogout())}>
                <Text style={[styles.textTitle, { fontSize: 16 }]}>
                  {t("logout")}
                </Text>
              </Touchable>
            </View>
          </View>

          <View style={styles.wrapProfile}>
            {!homeState.isLoadingMyProfile && homeState.myProfile?.employee ? (
              <>
                <View style={styles.wrapImageProfile}>
                  <Image
                    source={{
                      uri: homeState.myProfile.avatar
                        ? homeState.myProfile.avatar.url
                        : // eslint-disable-next-line max-len
                          "https://png.pngtree.com/png-clipart/20190924/original/pngtree-vector-user-young-boy-avatar-icon-png-image_4827810.jpg"
                    }}
                    style={styles.imageProfile}
                  />
                </View>
                <View style={{ justifyContent: "center", marginLeft: 15 }}>
                  <Text style={styles.textType}>{t("employee")}</Text>
                  <Text style={styles.textName}>
                    {homeState.myProfile.employee.name}
                  </Text>
                  <Text style={styles.textTitle}>
                    {homeState.myProfile.employee.position_name}
                  </Text>
                </View>
              </>
            ) : (
              <View style={styles.wrapLoading}>
                <ActivityIndicator color="#fff" size="large" />
              </View>
            )}
          </View>
          <View style={styles.wrapButtonTranslate}>
            <Touchable onPress={() => _changeLanguage("en")}>
              <Text
                style={[
                  styles.nonActiveLanguage,
                  language === "en" ? styles.languageActive : null
                ]}
              >
                EN
              </Text>
            </Touchable>
            <Text
              style={
                (styles.languageSpacing,
                {
                  fontSize: 16,
                  color: "#ffff",
                  marginLeft: 5,
                  marginRight: 5
                })
              }
            >
              |
            </Text>
            <Touchable onPress={() => _changeLanguage("id")}>
              <Text
                style={[
                  styles.nonActiveLanguage,
                  language === "id" ? styles.languageActive : null
                ]}
              >
                ID
              </Text>
            </Touchable>
          </View>
        </View>
      </Fixed>

      <View style={[styles.wrapPage, { marginTop: headerHeight - 47 }]}>
        <Home />
      </View>
    </Container>
  );
};

export default HomeRoute;
