import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapButtonBack: {
    position: "absolute",
    padding: 24,
    left: 0,
    top: 0
  },
  buttonBack: {
    padding: 20,
    width: 10,
    height: 20
  }
});

export default styles;
