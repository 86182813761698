/* eslint-disable no-alert */
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogin } from ".";

export const POST_NEW_REIMBURSE_PENDING = "POST_NEW_REIMBURSE_PENDING";
export const POST_NEW_REIMBURSE_SUCCESS = "POST_NEW_REIMBURSE_SUCCESS";
export const POST_NEW_REIMBURSE_ERROR = "POST_NEW_REIMBURSE_ERROR";

export const postNewReimburseExpense = (body: any, cb: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { auth } = getState();
    dispatch({ type: POST_NEW_REIMBURSE_PENDING });

    const formData = new FormData();
    Object.keys(body).forEach((key: any) => {
      formData.append(key, body[key]);
    });

    const res = await API.postNewExpenseReimburse(formData, auth.token);
    dispatch({
      type: POST_NEW_REIMBURSE_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
  } catch (err) {
    const error = err.response;
    if (error) {
      if (error.status === 401) {
        dispatch(
          handleLogin(() => dispatch(postNewReimburseExpense(body, cb)))
        );
      } else {
        alert(err.response.data.message);
        dispatch({
          type: POST_NEW_REIMBURSE_ERROR,
          payload: { data: err.response.data }
        });
      }
    }
    dispatch({ type: POST_NEW_REIMBURSE_ERROR });
  }
};
