import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20
  },
  text: {
    fontSize: 11,
    width: "22%",
    color: "#999999",
    fontFamily: "Poppins"
  },
  textDesc: {
    width: "78%",
    fontSize: 11,
    color: "#666666",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  btnReport: {
    width: 28,
    height: 28,
    backgroundColor: "#fffff7",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2
  },
  footer: {
    height: 74,
    backgroundImage: "linear-gradient(to bottom, #50b1b1 -47%,  #262261)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  budgetReq: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: "#fffff7"
  },
  priceIdr: {
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#FFFFF7",
    marginTop: 2
  },
  wrapStatus: {
    backgroundColor: "#50b1b1",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 12
  },
  wrapDetailStatus: {
    width: "50%",
    alignItems: "center"
  },
  textStatus: {
    color: "#fffff7",
    fontSize: 11,
    fontFamily: "Poppins"
  },
  submit: {
    backgroundImage:
      "linear-gradient(rgb(250, 175, 64), rgb(240, 90, 40) 63%, rgb(219, 58, 38))",
    borderRadius: 100,
    width: 180,
    height: 54,
    marginBottom: 20,
    alignSelf: "center"
  },
  submitTextWrap: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  submitText: {
    color: "#FFF",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: 18
  }
});

export default styles;
