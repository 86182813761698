import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, TextInput, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles";
import { convertDate, documentTitle, maskedMoney } from "../../../utils";
import Cards from "../../NewTrip/components/Card";
import Revise from "../../../components/ModalComponents/Revise";
import Reject from "../../../components/ModalComponents/Reject";
import Approve from "../../../components/ModalComponents/Approve";
import {
  Card,
  Input,
  LoadingLoadMore,
  Modal,
  Touchable,
  WrapContent,
  WrapList
} from "../../../components";
import { IMAGES } from "../../../configs";

// redux action & types
import { getExpenseListApproval } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

const initExpenseData: any = {};

const ApproveExpense = () => {
  documentTitle("Expense - Trip");
  const history = useHistory();
  const [modalIsOpen, setModalOpen] = useState(false);
  const [notificationIsOpen, setNotificationOpen] = useState(false);
  const [expenseData, setExpense] = useState(initExpenseData);
  const [notificationType, setNotificationType] = useState();
  const dispatch = useDispatch();
  const expenseState = useSelector(
    (state: Reducers) => state.expenseListApproval
  );

  const dataButton = [
    {
      image: IMAGES.cross,
      answer: 3
    },
    {
      image: IMAGES.pencil,
      answer: 2
    },
    {
      image: IMAGES.check,
      answer: 1
    }
  ];

  useEffect(() => {
    dispatch(getExpenseListApproval(false));
  }, [dispatch, history]);

  const dateFormat = (start: string, end: string) => {
    return `${convertDate(start)} - ${convertDate(end)}`;
  };

  const handleNotification = (answer: any) => {
    setModalOpen(false);
    setNotificationOpen(true);
    setNotificationType(answer);
  };

  const handleModalOpen = (expenseDetail: any) => {
    setExpense(expenseDetail);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const closeNotification = () => {
    setNotificationOpen(false);
  };
  const showNotification = () => {
    let notification = null;
    if (notificationType === 1) {
      notification = (
        <Approve
          notificationType="expense"
          moderationId={expenseData.moderation.id}
          cancel={() => closeNotification()}
        />
      );
    } else if (notificationType === 2) {
      notification = (
        <Revise
          notificationType="expense"
          moderationId={expenseData.moderation.id}
          cancel={() => closeNotification()}
        />
      );
    } else if (notificationType === 3) {
      notification = (
        <Reject
          notificationType="expense"
          moderationId={expenseData.moderation.id}
          cancel={() => closeNotification()}
        />
      );
    } else {
      notification = <Text>failed</Text>;
    }

    return notification;
  };

  return (
    <WrapContent isLoading={expenseState.isLoadingGeneral}>
      <WrapList
        isEmpty={expenseState.listGeneral.length === 0}
        emptyComponent={() => <Text style={styles.text}>Kosong</Text>}
        onEndReached={() => dispatch(getExpenseListApproval(true))}
      >
        <FlatList
          style={{ marginHorizontal: 20 }}
          data={expenseState.listGeneral}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={
            <LoadingLoadMore active={expenseState.loadMoreGeneral} />
          }
          renderItem={({ item, index }) => (
            // <Touchable
            //   onPress={() => history.push(`/expensedetail/${item.id}`)}
            // >
            <Touchable onPress={() => handleModalOpen(item)}>
              <>
                <View style={[styles.card, index === 0 && { marginTop: 10 }]}>
                  <View style={[styles.row, { marginTop: 18 }]}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>No</Text>
                    </View>
                    <View style={{ width: "60%" }}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.number}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Subject</Text>
                    </View>
                    <View style={{ width: "60%" }}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.purpose}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Type</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.type.djurneeItems.map(
                          (itemType: any, indexType: number) =>
                            indexType === item.type.djurneeItems.length - 1
                              ? itemType.category.title
                              : `${itemType.category.title}, `
                        )}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Date</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {dateFormat(item.started_at, item.finished_at)}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.row, { marginBottom: 18 }]}>
                    <View style={{ width: "30%" }}>
                      <Text style={styles.text}>Method</Text>
                    </View>
                    <View style={styles.columnDesc}>
                      <Text style={[styles.desc, { fontWeight: "bold" }]}>
                        {item.djurneeInvoice.type.title}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.footerCard, styles.budgetRequest]}>
                  <Text style={styles.textStatus}>Budget request</Text>
                  <Text style={styles.textDesc}>
                    <Text
                      style={[
                        styles.textDesc,
                        { fontWeight: "normal", marginRight: 0 }
                      ]}
                    >
                      IDR
                    </Text>
                    {` ${item.total ? maskedMoney(item.total) : ""}`}
                  </Text>
                </View>
              </>
            </Touchable>
          )}
        />
      </WrapList>
      <Modal isVisible={modalIsOpen}>
        <View style={styles.container}>
          <Card
            cardStyle={{
              marginBottom: 23,
              justifyContent: "center"
            }}
          >
            <View>
              <View
                style={{
                  marginLeft: 22,
                  marginRight: 37
                }}
              >
                <View style={{ marginBottom: 34 }}>
                  <View
                    style={[
                      styles.desciption,
                      { marginBottom: 18.5, marginTop: 50 }
                    ]}
                  >
                    <Text style={styles.titleDescription}>Subject</Text>
                    <TextInput
                      value={
                        expenseData && expenseData.moderation
                          ? expenseData.moderation.title
                          : "-"
                      }
                      style={{
                        paddingHorizontal: 13,
                        fontSize: 11,
                        color: "#666666",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        borderBottomWidth: 1,
                        borderColor: "#707070",
                        width: "75%"
                      }}
                    />
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Type</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseData &&
                          expenseData.type &&
                          expenseData.type.djurneeItems.map(
                            (itemType: any, indexType: number) =>
                              indexType ===
                              expenseData.type.djurneeItems.length - 1
                                ? itemType.category.title
                                : `${itemType.category.title}, `
                          )}
                      </Text>
                    </Cards>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Date</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseData && expenseData.moderation
                          ? convertDate(expenseData.moderation.created_at)
                          : "-"}
                      </Text>
                    </Cards>
                  </View>
                  <View style={styles.desciption}>
                    <Text style={styles.titleDescription}>Method</Text>
                    <Cards cardStyle={styles.detailDescriptionCard}>
                      <Text style={styles.detailDescriptionText}>
                        {expenseData &&
                        expenseData.moderation &&
                        expenseData.moderation.type
                          ? expenseData.moderation.type.title
                          : "-"}
                      </Text>
                    </Cards>
                  </View>
                </View>
              </View>
              <View style={styles.amountContainer}>
                <Text style={styles.amountTitle}>
                  {expenseData && expenseData.currency
                    ? `Amount (${expenseData.currency.code})`
                    : "Amount -"}
                </Text>
                <Input
                  inputStyle={styles.amount}
                  style={{ width: 150 }}
                  value={expenseData ? maskedMoney(expenseData.amount) : "-"}
                />
              </View>
              <View
                style={{
                  alignItems: "center",
                  marginTop: 67,
                  marginBottom: 74
                }}
              />
            </View>
          </Card>
          <View>
            <View style={styles.cardButtonContainer}>
              {dataButton.map((item, index) => {
                return (
                  <Card cardStyle={styles.cardButton} key={index}>
                    <Touchable onPress={() => handleNotification(item.answer)}>
                      <Image
                        source={item.image}
                        style={{ height: 24, width: 24 }}
                      />
                    </Touchable>
                  </Card>
                );
              })}
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row"
              }}
            >
              <Touchable onPress={() => handleModalClose()}>
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "#666666"
                  }}
                >
                  CLOSE
                </Text>
              </Touchable>
              {/* <View style={{ flexDirection: "row" }}>
                <Touchable>
                  <Image
                    resizeMode="contain"
                    source={IMAGES.share}
                    style={{ height: 22, width: 21, marginRight: 15.5 }}
                  />
                </Touchable>
                <Touchable>
                  <Image
                    resizeMode="contain"
                    source={IMAGES.download}
                    style={{ height: 22, width: 21 }}
                  />
                </Touchable>
              </View> */}
            </View>
          </View>
        </View>
      </Modal>
      {notificationIsOpen && (
        <Modal isVisible={notificationIsOpen}>{showNotification()}</Modal>
      )}
    </WrapContent>
  );
};

export default ApproveExpense;
