import { StyleSheet } from "react-native";

import { COLORS } from "../../../../../configs";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 16
  },
  label: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.greyLight
  },
  font14bold: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "bold",
    color: COLORS.grey
  },
  flexDirection: {
    flexDirection: "row",
    marginBottom: 4
  },
  widthLabel: {
    width: "30%"
  },
  iconStar: {
    height: 16,
    width: 16
  },
  value: {
    fontFamily: "Poppins",
    fontSize: 11,
    color: COLORS.grey
  },
  card: {
    shadowColor: "#ddd",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 2,
    borderRadius: 12,
    paddingHorizontal: 16,
    paddingVertical: 8
  }
});

export default styles;
