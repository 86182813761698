/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, TextInput, View } from "react-native";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";
import styles from "./styles";
import UserLoan from "./UserLoan";
import { Reducers } from "../../redux/types";
import { getGeneralSetting, heightPercent, maskedMoney } from "../../utils";
import { Card, Fixed, Header, Modal, Touchable } from "../../components";
import { COLORS, IMAGES } from "../../configs";
import {
  getListLoan,
  getLoanProfile,
  postLoan,
  setStatusLoan
} from "../../redux/actions";

const Loan = () => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const homeState = useSelector((state: Reducers) => state.home);
  const loanState = useSelector((state: Reducers) => state.loan);
  const match = useRouteMatch();
  const history = useHistory();
  const [valueAmount, setValueAmount]: any = useState();
  const [selectedIndexLoanProfile, setSelectedIndexLoanProfile] = useState(0);

  // const { pathname } = history.location;
  const [heightHeader, setHeightHeader] = useState(0);

  const [listPages, setListPages] = useState([
    {
      pathname: `${match.path}/user`,
      component: <UserLoan />,
      iconTab: IMAGES.cashRequest,
      titleTab: "My Loan",
      exact: true,
      accessible: true,
      bubbleData: false
    },
    {
      pathname: `${match.path}/finance`,
      component: <Text> FINANCE LOAN PAGE</Text>,
      iconTab: IMAGES.cashApprove,
      titleTab: "Finance",
      exact: true,
      accessible: true,
      bubbleData: false
    },
    {
      pathname: `${match.path}/loan`,
      component: <Text>LOAN - LOAN PAGE</Text>,
      iconTab: IMAGES.cashDisburse,
      titleTab: "Loan",
      exact: true,
      accessible: true,
      bubbleData: false
    }
  ]);

  useEffect(() => {
    dispatch(getListLoan(loanState.statusNumber));
    dispatch(getLoanProfile());
  }, [dispatch, loanState.statusNumber]);

  useEffect(() => {
    setListPages([
      {
        pathname: `${match.path}/user`,
        component: <UserLoan />,
        iconTab: IMAGES.cashRequest,
        titleTab: "My Loan",
        exact: true,
        accessible: true,
        bubbleData: false
      },
      {
        pathname: `${match.path}/finance`,
        component: <Text> FINANCE LOAN PAGE</Text>,
        iconTab: IMAGES.cashApprove,
        titleTab: "Finance",
        exact: true,
        accessible: true,
        bubbleData: false
      },
      {
        pathname: `${match.path}/loan`,
        component: <Text> LOAN - LOAN PAGE</Text>,
        iconTab: IMAGES.cashDisburse,
        titleTab: "Loan",
        exact: true,
        accessible: true,
        bubbleData: false
      }
    ]);
    dispatch(setStatusLoan(3));
  }, []);

  const _getHeighHeader = () => {
    return heightHeader + 16;
  };

  const _submit = () => {
    dispatch(
      postLoan(
        {
          source_id:
            loanState.loanProfile[selectedIndexLoanProfile].loanSource.id,
          period: moment().format("YYYY-MM-DD"),
          currency_code: "IDR",
          amount: valueAmount
        },
        () => window.location.reload(),
        () => setOpenModal(false)
      )
    );
  };

  const canAddLoan = () => {
    // return (
    //   getGeneralSetting("loan-max-concurrent") > loanState.totalData &&
    //   Number(
    //     loanState.loanProfile &&
    //       loanState.loanProfile.length > 0 &&
    //       loanState.loanProfile[0].amount_max
    //   ) -
    //     Number(
    //       loanState.loanProfile &&
    //         loanState.loanProfile.length > 0 &&
    //         loanState.loanProfile[0].amount_current
    //     ) >
    //     0
    // );
    return (
      getGeneralSetting("loan-max-concurrent") > loanState.totalData &&
      loanState.loanProfile &&
      loanState.loanProfile.length > 0
    );
  };

  return (
    <Switch>
      <View>
        <Fixed getHeight={value => setHeightHeader(value)}>
          <Header title={t("home.loan")} goBack={() => history.push("/home")} />
          <View
            style={[
              styles.container,
              homeState.isLoadingMyProfile && { opacity: 0 }
            ]}
          >
            <View style={{ backgroundColor: COLORS.white }}>
              <View style={styles.wrapLoanNav}>
                <View style={styles.loanNav}>
                  <View
                    style={[
                      { width: "25%" },
                      loanState.statusNumber === 3 ? styles.active : null
                    ]}
                  >
                    <Touchable onPress={() => dispatch(setStatusLoan(3))}>
                      <View style={{ width: "100%", alignItems: "center" }}>
                        <Text style={styles.btnNav}>{t("loan.requested")}</Text>
                      </View>
                    </Touchable>
                  </View>
                  <View
                    style={[
                      { width: "25%" },
                      loanState.statusNumber === 5 ? styles.active : null
                    ]}
                  >
                    <Touchable onPress={() => dispatch(setStatusLoan(5))}>
                      <View style={{ width: "100%", alignItems: "center" }}>
                        <Text style={styles.btnNav}>{t("loan.disburse")}</Text>
                      </View>
                    </Touchable>
                  </View>
                  <View
                    style={[
                      { width: "25%" },
                      loanState.statusNumber === 6 ? styles.active : null
                    ]}
                  >
                    <Touchable onPress={() => dispatch(setStatusLoan(6))}>
                      <View style={{ width: "100%", alignItems: "center" }}>
                        <Text style={styles.btnNav}>{t("loan.repayment")}</Text>
                      </View>
                    </Touchable>
                  </View>
                  <View
                    style={[
                      { width: "25%" },
                      loanState.statusNumber === 7 ? styles.active : null
                    ]}
                  >
                    <Touchable onPress={() => dispatch(setStatusLoan(7))}>
                      <View style={{ width: "100%", alignItems: "center" }}>
                        <Text style={styles.btnNav}>{t("loan.finished")}</Text>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
              <View style={styles.infoLoan}>
                <View style={{ width: "90%", marginVertical: 12 }}>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "50%" }]}>
                      {t("loan.plafond")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(
                        loanState.loanProfile &&
                          loanState.loanProfile[selectedIndexLoanProfile]
                            .amount_max
                      )}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "50%" }]}>
                      {t("loan.used")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(
                        loanState.loanProfile &&
                          loanState.loanProfile.amount_current
                      )}`}
                    </Text>
                  </View>
                  <View style={styles.infoLoanTextWrap}>
                    <Text style={[styles.txtInfoLoan, { width: "50%" }]}>
                      {t("loan.remPlafond")}
                    </Text>
                    <Text style={[styles.txtInfoLoan, { fontWeight: "bold" }]}>
                      {`IDR ${maskedMoney(
                        Number(
                          loanState.loanProfile &&
                            loanState.loanProfile[selectedIndexLoanProfile]
                              .amount_max
                        ) -
                          Number(
                            loanState.loanProfile &&
                              loanState.loanProfile[selectedIndexLoanProfile]
                                .amount_current
                          )
                      )}`}
                    </Text>
                  </View>
                </View>
                <View style={{ marginBottom: 20 }}>
                  <Touchable
                    disabled={!canAddLoan()}
                    onPress={() => setOpenModal(true)}
                  >
                    <View
                      style={[
                        canAddLoan() ? styles.active : styles.disabled,
                        { borderRadius: 100 }
                      ]}
                    >
                      <Text style={styles.btnNew}>
                        {`+ ${t("loan.newLoan")}`}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            {/* <View style={styles.wrapLoanNav}>
              <View style={styles.loanNav}>
                {listPages.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.btnNav,
                      index === 0 &&
                        item.pathname === pathname &&
                        styles.activeFirst,
                      index === 4 &&
                        item.pathname === pathname &&
                        styles.activeLast,
                      index !== 0 &&
                        pathname.includes(item.pathname) &&
                        styles.active
                    ]}
                  >
                    <Touchable
                      onPress={() => history.push(item.pathname)}
                      disabled={!item.accessible}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          flex: 1
                        }}
                      >
                        <Image
                          resizeMode="contain"
                          style={[
                            styles.image,
                            index === listPages.length - 1 && {
                              marginRight: 8
                            }
                          ]}
                          source={item.iconTab}
                        />
                        <Text
                          style={[
                            styles.btnText,
                            { color: item.accessible ? "white" : "#666666" }
                          ]}
                        >
                          {item.titleTab}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                ))}
              </View>
            </View> */}
          </View>
        </Fixed>
        <Modal isVisible={openModal} backdropColor="rgba(0,0,0, 0.2)">
          <Card cardStyle={styles.containerModalContent}>
            <View>
              <View style={styles.containerModal}>
                <Text style={styles.titleModal}>{t("loan.reqLoan")}</Text>
                <View style={{ width: "90%" }}>
                  <Text style={[styles.txtInfoLoan, { fontSize: 12 }]}>
                    {t("loan.period")}
                  </Text>
                  <Card cardStyle={{ backgroundColor: "white", marginTop: 4 }}>
                    <Text style={[styles.txtInfoLoan, styles.monthText]}>
                      {moment().format("MMMM")}
                    </Text>
                  </Card>
                  <View>
                    <Text style={[styles.txtInfoLoan, { textAlign: "right" }]}>
                      {t("loan.descPeriod")}
                    </Text>
                  </View>
                  <Text
                    style={[styles.txtInfoLoan, { fontSize: 12, marginTop: 8 }]}
                  >
                    Loan Source
                  </Text>
                  {loanState.loanProfile && loanState.loanProfile.length > 1 ? (
                    <select
                      className="container-content-input"
                      style={{
                        backgroundColor: "white",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "grey"
                      }}
                      onChange={(e: any) =>
                        setSelectedIndexLoanProfile(e.target.value)
                      }
                    >
                      {loanState.loanProfile &&
                        loanState.loanProfile.map(
                          (loanProfileItem: any, indexLoanProfile: number) => (
                            <option
                              key={indexLoanProfile}
                              value={indexLoanProfile}
                            >
                              {loanProfileItem.loanSource.title}
                            </option>
                          )
                        )}
                    </select>
                  ) : (
                    <Card
                      cardStyle={{ backgroundColor: "white", marginTop: 4 }}
                    >
                      <Text style={[styles.txtInfoLoan, styles.monthText]}>
                        {loanState.loanProfile &&
                          loanState.loanProfile[selectedIndexLoanProfile]
                            .loanSource.title}
                      </Text>
                    </Card>
                  )}

                  <View style={styles.containerInput}>
                    <View style={{ width: "28%" }}>
                      <Text style={[styles.txtInfoLoan, { fontSize: 12 }]}>
                        {t("loan.currency")}
                      </Text>
                      <Card
                        cardStyle={{ backgroundColor: "white", marginTop: 4 }}
                      >
                        <Text style={[styles.txtInfoLoan, styles.monthText]}>
                          IDR
                        </Text>
                      </Card>
                    </View>
                    <View style={{ width: "70%" }}>
                      <Text style={[styles.txtInfoLoan, { fontSize: 12 }]}>
                        {t("loan.loanAmount")}
                      </Text>
                      <TextInput
                        value={valueAmount || ""}
                        onChangeText={e => setValueAmount(e)}
                        keyboardType="numeric"
                        style={[
                          styles.input,
                          styles.inputLine,
                          { textAlign: "right", marginTop: 6 }
                        ]}
                      />
                      {valueAmount >
                      Number(
                        loanState.loanProfile &&
                          loanState.loanProfile[selectedIndexLoanProfile]
                            .amount_max
                      ) ? (
                        <Text style={styles.errText}>
                          {t("loan.errAmount")}
                        </Text>
                      ) : null}
                    </View>
                  </View>
                  <Text
                    style={[
                      styles.txtInfoLoan,
                      { textAlign: "right", marginTop: 6 }
                    ]}
                  >
                    {t("loan.maxLoanAmount")}
                  </Text>
                  <Text
                    style={[
                      styles.txtInfoLoan,
                      { textAlign: "right", fontWeight: "bold" }
                    ]}
                  >
                    {`IDR ${maskedMoney(
                      loanState.loanProfile &&
                        loanState.loanProfile[selectedIndexLoanProfile]
                          .amount_max
                    )}`}
                  </Text>
                </View>
              </View>
              <View style={styles.containerBtn}>
                <Touchable
                  disabled={loanState.isLoadingPostLoan}
                  onPress={() => _submit()}
                >
                  {loanState.isLoadingPostLoan ? (
                    <ActivityIndicator />
                  ) : (
                    <View
                      style={[
                        styles.btnSubmitModal,
                        {
                          borderRadius: 100
                        }
                      ]}
                    >
                      <Text style={styles.txtSubmit}>{t("loan.submit")}</Text>
                    </View>
                  )}
                </Touchable>
                {loanState.isLoadingPostLoan ? null : (
                  <Touchable onPress={() => setOpenModal(false)}>
                    <View
                      style={[
                        {
                          borderRadius: 100
                        }
                      ]}
                    >
                      <Text style={styles.txtCancel}>{t("loan.cancel")}</Text>
                    </View>
                  </Touchable>
                )}
              </View>
            </View>
          </Card>
        </Modal>
        <View
          style={{
            marginTop: _getHeighHeader(),
            minHeight: heightPercent(90) - heightHeader
          }}
        >
          {listPages.map((item, index) => (
            <Route path={item.pathname} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </View>
      </View>
    </Switch>
  );
};

export default Loan;
