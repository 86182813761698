import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    marginTop: 60,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textKasbon: {
    color: "#ffffff",
    fontSize: 20,
    fontWeight: "700",
    flex: 1
  },
  logo: {
    height: 32,
    width: 64,
    resizeMode: "cover"
  },
  containerNavbtn: {
    width: "40%",
    flexDirection: "row-reverse"
  },
  imageNotification: {
    height: 40,
    width: 40
  }
});

export default styles;
